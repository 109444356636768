<template>
    <div>
        <el-card class="box-card">
          <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
              <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="费用名称">
                    <f-select
                        :dict="'fee_price_fee'"
                        v-model="queryForm.feeId"
                        :is-need="queryForm.feeId"
                    >
                    </f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="目的地类型">
                    <f-select
                        :dict="'end_type'"
                        v-model="queryForm.endType"
                        :is-need="queryForm.endType"
                    >
                    </f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="fbaCode">
                    <f-select
                        :dict="'fba_code_list'"
                        v-model="queryForm.fbaCode"
                        :is-need="queryForm.fbaCode"
                    >
                    </f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="提柜港口/拆柜仓库">
                    <el-input v-model="queryForm.endPlace"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="单位">
                    <el-input v-model="queryForm.unit"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label-width="30px">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button plain @click="handleReset">重置</el-button>
                  </el-form-item>
                </el-col>
              </el-row>


            </el-card>
          </el-form>
        <div class="bbb">
            <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleAdd"
                    class="margin_right_10"
                    v-if="buttonShowList.add"
            >新增</el-button>
          <el-button
              type="primary"
              size="medium"
              @click="handleDelDuo"
              class="margin_right_10"
              :disabled="selectionRows.length===0"
              v-if="buttonShowList.plsc"
          >批量删除</el-button>
            <el-upload
                    class="upload-demo margin_right_10"
                    action=""
                    :http-request="importOperation"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    accept=".xls,.xlsx"
            >
                <el-button type="primary" size="medium"
                           v-if="buttonShowList.drfymb"
                >导入</el-button>
            </el-upload>
            <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.downloadExcel, '预估费用模板')"
            >模板
            </el-button>
          <el-button type="primary" size="medium" @click="handleExportXls('预估费用')"
                     v-if="buttonShowList.dc"
          >导出</el-button>
        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)"
                               v-if="buttonShowList.bj"
                    >编辑</el-button>
                    <el-button type="text" @click="handleDel(scope.row)"
                               v-if="buttonShowList.sc"
                    >删除</el-button>
                </template>
                <template v-else-if="scope.column.property === 'endType'">
                  <span>{{parseDict(scope.row.endType,'end_type')}}</span>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑' : '新增'"
                v-if="dialogFormVisible"
                @close="dialogFormVisible = false"
                :visible.sync="dialogFormVisible"
                width="30%"
                center
                :close-on-click-modal="false">
          <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="费用名称"
                        prop="feeId"
                        :label-width="formLabelWidth"
                ><f-select
                            :dict="'fee_price_fee'"
                            v-model="form.feeId"
                            :is-need="form.feeId"
                    ></f-select>
                </el-form-item>
              <el-form-item
                  label="目的地类型"
                  prop="endType"
                  :label-width="formLabelWidth"
              ><f-select
                    :dict="'end_type'"
                    v-model="form.endType"
                    :isNeed="form.endType"></f-select>
              </el-form-item>
            <el-form-item
                label="提柜港口/拆柜仓库"
                prop="endPlaceId"
                :label-width="formLabelWidth"
            ><f-select
                v-if="form.endType==='1'"
                :dict="'cg_ck'"
                v-model="form.endPlaceId"
                :isNeed="form.endPlaceId"></f-select>
              <f-select
                  v-if="form.endType==='2'"
                  :dict="'tg_port'"
                  v-model="form.endPlaceId"
                  :isNeed="form.endPlaceId"></f-select>
            </el-form-item>
            <el-form-item
                label="FBA CODE"
                prop="fbaCode"
                :label-width="formLabelWidth"
            ><f-select
                :dict="'fba_code_list'"
                v-model="form.fbaCode"
                :isNeed="form.fbaCode"
            ></f-select>
            </el-form-item>
            <el-form-item
                label="单位"
                prop="unit"
                :label-width="formLabelWidth"
            ><el-input v-model="form.unit" show-word-limit maxlength="20"></el-input>
            </el-form-item>
            <el-form-item
                label="最小值"
                prop="min"
                :label-width="formLabelWidth"
            ><el-input v-model="form.min" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item
                label="最大值"
                prop="max"
                :label-width="formLabelWidth"
            ><el-input v-model="form.max" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item
                label="成本"
                prop="minTuo"
                :label-width="formLabelWidth"
            ><el-input v-model="form.minTuo" type="number" @change="caculatePrice"></el-input>
            </el-form-item>
            <el-form-item
                label="基数"
                prop="baseNum"
                :label-width="formLabelWidth"
            ><el-input v-model="form.baseNum" type="number" @change="caculatePrice"></el-input>
            </el-form-item>
            <el-form-item
                label="体重比"
                prop="cbmKg"
                :label-width="formLabelWidth"
            ><el-input v-model="form.cbmKg" type="number" @change="caculatePrice"></el-input>
            </el-form-item>
            <el-form-item
                label="汇率(美元转人民币)"
                prop="rate"
                :label-width="formLabelWidth"
            ><el-input v-model="form.rate" type="number" @change="caculatePrice"></el-input>
            </el-form-item>
            <el-form-item
                label="单价（CBM）"
                prop="priceCbm"
                :label-width="formLabelWidth"
            ><el-input v-model="form.priceCbm" type="number"></el-input>
            </el-form-item>
            <el-form-item
                label="单价（KG）"
                prop="priceKg"
                :label-width="formLabelWidth"
            ><el-input v-model="form.priceKg" type="number"></el-input>
            </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveImporter" :disabled="btnDisable">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,getAction} from "@/api/manage";

    export default {
        name: "OperationBindModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
              dictCodes:['end_type'],
                deliveryList: [],
                btnDisable: false,
                row: {},
                form: {},
                rate:1,
                dialogFormVisible: false,
                formLabelWidth: "120px",
                countrylist: [],

                //表单校验
                rules: {
                    feeId: [
                        {required: true, message: "请选择费用", trigger: "change"},
                    ],

                  min: [
                    {required: true, message: "请输入最小值", trigger: "change"},
                  ],
                  max: [
                    {required: true, message: "请输入最大值", trigger: "change"},
                  ],
                  minTuo: [
                    {required: true, message: "请输入成本", trigger: "change"},
                  ],
                  baseNum: [
                    {required: true, message: "请输入基数", trigger: "change"},
                  ],
                  cbmKg: [
                    {required: true, message: "请输入体重比", trigger: "change"},
                  ],
                },
                visible: false,
              //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                  {
                    type: "selection",

                  },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "feename",
                        label: "费用名称",
                    },
                  {
                    prop: "feenameUs",
                    label: "费用英文名",
                  },
                  {
                    prop: "fbaCode",
                    label: "仓库代码",
                  },
                  {
                    prop: "endType",
                    label: "目的地类型",
                  },
                  {
                    prop: "endPlace",
                    label: "提柜港口/拆柜仓库",
                  },
                  {
                    prop: "unit",
                    label: "单位",
                  },

                  {
                    prop: "min",
                    label: "最小值",
                  },
                  {
                    prop: "max",
                    label: "最大值",
                  },
                  {
                    prop: "minTuo",
                    label: "成本",
                  },
                  {
                    prop: "baseNum",
                    label: "基数",
                  },
                  {
                    prop: "cbmKg",
                    label: "体重比",
                  },
                  {
                    prop: "rate",
                    label: "汇率(美元转人民币)",
                  },
                  {
                    prop: "priceCbm",
                    label: "单价（CBM）",
                    headerType:'remark',
                    detail:'计算公式为（成本*汇率）/基数',
                  },
                  {
                    prop: "priceKg",
                    label: "单价（KG）",
                    headerType:'remark',
                    detail: '计算公式为（成本*汇率）/基数/体重比',
                  },
                  {
                    prop: "createTime",
                    label: "创建时间",

                  },

                ],
                url: {
                    list: "/customer/feePrice/getPricePage",
                    delOne: "/customer/feePrice/deleteOne",
                    delDuo: "/customer/feePrice/deleteDuo",
                    addOrUpdate: "/customer/feePrice/addOrupdate",
                  import :'/customer/feePrice/importFee',
                  getRate:'/customer/feePrice/getNewRate',
                  downloadExcel:'/customer/feePrice/downloadExcel',
                  exportXlsUrl:'/customer/feePrice/export',

                },


            };
        },
        created() {
            getAction(this.url.getRate).then(res=>{
              if (res.result){
                this.rate = res.result;
              }
            })


        },
        methods: {
          caculatePrice(){
            if (this.form.unit && this.form.baseNum && this.form.minTuo ) {

                let price = ((parseFloat(this.form.minTuo)*parseFloat(this.rate))/this.form.baseNum).toFixed(2);
                this.$set(this.form,'priceCbm',price);
                console.log('计算出来的价格',this.form.priceCbm)

              if (this.form.cbmKg) {
                let price = ((parseFloat(this.form.minTuo)*parseFloat(this.rate))/this.form.baseNum/this.form.cbmKg).toFixed(2);
                this.$set(this.form,'priceKg',price);
                console.log('计算出来的价格',this.form.priceKg)
              }
            }

           },
            //导入操作绑定
            importOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.import, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            handleDel(row) {
                this.$confirm(`确定删除吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delOne, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    });
            },
          handleDelDuo() {
            this.$confirm(`确定删除吗`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            })
                .then(() => {
                  postAction(this.url.delDuo, this.selectionRows.map(s=>s.id)).then((res) => {
                    this.$message({
                      type: "success",
                      message: res.message,
                    });
                    this.handleSearch();
                  });
                });
          },
            handleAdd() {
                this.form = {};
                console.log("供应商表单对象", this.form);
                this.$set(this.form,'rate',this.rate);
                this.dialogFormVisible = true;
                this.btnDisable = false;

            },
            handleEdit(row) {
                this.btnDisable = false;
                for (var name in row) {
                    this.$set(this.form, name, row[name]);
                    if (name === "transportStatus") {
                        if (row[name]){
                            this.$set(this.form, "transportStatusList", row[name].split("/"));
                        }else {
                            this.$set(this.form, "transportStatusList", []);
                        }
                    }
                    if (name === "zipRule") {
                        if (row[name]){
                            this.$set(this.form, "zipRuleList", row[name].split(","));
                        }else {
                            this.$set(this.form, "zipRuleList", []);
                        }
                    }
                    if (name === "stateRule") {
                        if (row[name]){
                            this.$set(this.form, "stateRuleList", row[name].split(","));
                        }else {
                            this.$set(this.form, "stateRuleList", []);
                        }
                    }
                }
                this.dialogFormVisible = true;
            },
            saveImporter() {
                console.log("进口商对象", this.form);
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                      postAction(this.url.addOrUpdate, this.form)
                          .then((res) => {
                            if (res.success) {
                              this.$message.success(res.message);
                              this.dialogFormVisible = false;
                              this.handleQuery();
                            } else {
                              this.btnDisable = false;
                            }
                          });
                    }else {
                      this.btnDisable = false;
                    }
                });
            },
        },
      computed : {
        buttonShowList() {
          return {
            'add':this.$btnIsShow('docpage/FeePriceModule','0','新增'),
            'plsc':this.$btnIsShow('docpage/FeePriceModule','0','批量删除'),
            'drfymb':this.$btnIsShow('docpage/FeePriceModule','0','导入费用模板'),
            'dc':this.$btnIsShow('docpage/FeePriceModule','0','导出'),
            'bj':this.$btnIsShow('docpage/FeePriceModule','1','编辑'),
            'sc':this.$btnIsShow('docpage/FeePriceModule','1','删除'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }
    .margin_right_10 {
      margin-right: 10px;
    }
</style>
