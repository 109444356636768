
<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="auto" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :span="5">
            <el-form-item label="规则名称">
              <el-input  v-model="queryForm.ruleName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="规则英文">
              <el-input  v-model="queryForm.ruleNameEn">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="是否启用">
              <f-select  v-model="queryForm.isEnable" :is-need="queryForm.isEnable" :dict="'unit_whether'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="6" :sm="24">
            <el-form-item label="有效时间" label-width="100px">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.efeTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.efeTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
          v-if="buttonShowList.add">新增</el-button>
        <el-button size="medium" type="primary" class="c-theme-error-button" @click="handleDele"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.sc">批量删除
        </el-button>
        <el-button size="medium" type="primary" icon="el-icon-open" @click="handleEn"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.en">批量启用
        </el-button>
        <el-button size="medium" type="primary" icon="el-icon-turn-off" @click="handleDis"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.dis">批量禁用
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="66vh"
        @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row,'编辑')"
              v-if="buttonShowList.bj"
            >编辑</el-button>
            <el-button type="text" @click="handleEdit(scope.row,'复制')"
                       v-if="buttonShowList.copy"
            >复制</el-button>
          </template>

          <template v-else-if="scope.column.property === 'isEnable'">
            <span>{{ scope.row.isEnable === '1' ? '启用' : '禁用' }}</span>
          </template>

        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <sales-per-config-dialog
        :visible="activeVisible"
        :row="row"
        :select-form="selectForm"
        :select-base-vo="selectBaseVo"
        :edit-type="editType"
        @update:visible="(value) => { activeVisible = value; }"
      @ok="handleQuery()"
    ></sales-per-config-dialog>
  </div>
</template>

<script>
import { postAction,getAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";
import salesPerConfigDialog from "./modules/salesPerConfigDialog.vue";

export default {
  name: 'salesPerConfig',
  mixins: [QueryMixins],
  components: {
    salesPerConfigDialog
  },
  data() {
    return {
      isUsePost: true,
      row: {},
      editType:'',
      // 传递给编辑框使用
      selectForm:{},
      selectBaseVo:{},
      formLabelWidth: '110px',
      //控制弹框显示
      activeVisible: false,
      columns: [
        {
          type: 'selection',
          width : 50,
        },
        {
          prop: 'action',
          label: '操作',
        },
        {
          prop: 'useLevel',
          label: '优先级',
        },
        {
          prop: 'ruleName',
          label: '规则名称',
        },
        {
          prop: 'ruleNameEn',
          label: '规则英文',
        },
        {
          prop: 'isEnable',
          label: '是否启用',
        },
        {
          prop: 'efeTimeStart',
          label: '有效开始时间',
        },
        {
          prop: 'efeTimeEnd',
          label: '有效结束时间',
        },
        {
          prop: 'createTime',
          label: '创建时间',
        },
        {
          prop: 'createBy',
          label: '创建人',
        },
        {
          prop: 'commissionRate',
          label: '提成比例',
        },
        {
          prop: 'personRespRatio',
          label: '个人承担比例',
        },
        {
          prop: 'subsidy',
          label: '补助',
        },
        {
          prop: 'lastRatio',
          label: '最终提成比例',
        },

      ],
      url: {
        list: '/shipping/sales/per/config/page',
        delete: '/shipping/sales/per/config/del',
        en: '/shipping/sales/per/config/en',
        dis: '/shipping/sales/per/config/dis',

        getDetailById : '/shipping/sales/per/config/getVOByConfigId'
      },

    }
  },
  methods: {
    handleAdd() {
      this.row = {};
      this.selectForm = {};
      this.activeVisible = true;
      this.editType = '新增';
    },
    handleEdit(row,type) {
      this.editType = type;
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.row = JSON.parse(JSON.stringify(row));
      let params = { configId : row.id };
      getAction(this.url.getDetailById , params)
          .then(res => {
            this.selectForm = res.result.vo;
            this.selectBaseVo = res.result.baseVo;
            this.activeVisible = true;
      })
    },
    handleDele() {
      this.$confirm(`确定删除`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.delete, this.selectedRowKeys).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleEn() {
      this.$confirm(`确定启用`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.en, this.selectedRowKeys).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleDis() {
      this.$confirm(`确定禁用`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.dis, this.selectedRowKeys).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
  }, created() {

  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('salePerConfig/salesPerConfig', '0', '新增'),
        'bj':this.$btnIsShow('salePerConfig/salesPerConfig', '1', '编辑'),
        'copy':this.$btnIsShow('salePerConfig/salesPerConfig', '1', '复制'),
        'sc':this.$btnIsShow('salePerConfig/salesPerConfig', '1', '删除'),
        'en':this.$btnIsShow('salePerConfig/salesPerConfig', '1', '启用'),
        'dis':this.$btnIsShow('salePerConfig/salesPerConfig', '1', '禁用'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
