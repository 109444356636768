<template>
  <el-dialog :title="type == 'log' ? '日志' : (row.id ? '编辑海运费设置' : '新增海运费设置')" :visible="visible" width="560px"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type == 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
      class="c-th-has-bgcolor" :columns="columns" :data="tableData"
      :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
      @sort-change="handleTableChange">
    </f-table>
    <el-form v-else :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="startPortId" label="起运港">
            <f-select

                v-model="addForm.startPortId" :is-need="addForm.startPortId" :data-list="startPortDictList"  placeholder="请选择起运港"></f-select>

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="startPortId" label="目的港">
            <f-select

                v-model="addForm.endPortId" :is-need="addForm.endPortId" :data-list="endPortDictList"  placeholder="请选择起运港"></f-select>

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="cabinetTypeId" label="柜型">
            <f-select

                v-model="addForm.cabinetTypeId" :is-need="addForm.cabinetTypeId" :data-list="cabinetTypeDictList"  placeholder="请选择起运港"></f-select>

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="routeName" label="航线">
            <el-input v-model.trim="addForm.routeName" placeholder="请输入航线"  maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="shipSi" label="船司">
            <el-input v-model.trim="addForm.shipSi" placeholder="请输入船司"  maxlength="50"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="moneyType" label="币种">
            <f-select v-model="addForm.moneyType" :is-need="addForm.moneyType" :dict="'bd_cur'"  placeholder="请选择币种"></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="price" label="价格">
            <el-input v-model.trim="addForm.price" type="number" placeholder="请输入价格"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="startTime" label="有效开始时间">
            <el-date-picker
                class="f-flex-1"
                v-model="addForm.startTime"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="endTime" label="有效结束时间">
            <el-date-picker
                class="f-flex-1"
                v-model="addForm.endTime"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="remark" label="备注">
            <el-input v-model.trim="addForm.remark" placeholder="请输入备注" type="textarea" maxlength="350"></el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction,getAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";
import {validateTrailerFee} from "../../../utils/validator";
import ca from "element-ui/src/locale/lang/ca";



export default {
  name: 'EditOceanFeeSetDialog',
  computed: {
    ca() {
      return ca
    }
  },
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    // 弹窗类型
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,
      columns: [
        {
          prop: "detail",
          label: "详情",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],
      addForm: {},
      rules: {

        startPortId: [
          { required: true, message: '请选择起运港', trigger: 'change' },
        ],
        endPortId: [
          { required: true, message: '请选择目的港', trigger: 'change' },
        ],
        cabinetTypeId: [
          { required: true, message: '请选择柜型', trigger: 'change' },
        ],
        routeName: [
          { required: true, message: '请输入航线', trigger: 'blur' },
        ],
        moneyType: [
          { required: true, message: '请选择币种', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
          {validator:validateTrailerFee,trigger: 'change'}
        ],
        startTime: [
          { required: true, message: '请选择有效开始时间', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '请选择有效结束时间', trigger: 'blur' },
        ],
      },
      url: {
        add: '/sys/ocean/fee/set/addOne',
        edit: '/sys/ocean/fee/set/editOne',
        list: '/sys/ocean/fee/set/queryLogsById',
        getOneOceanFeeSetVoById: '/sys/ocean/fee/set/getOneOceanFeeSetVoById'
      },
      startPortDictList:[],
      endPortDictList:[],
      cabinetTypeDictList:[],
    }
  },
  watch: {
    visible(val) {
      if (val && this.row.id) {
        if (this.type == 'log') {
          this.initData = {
            id: this.row.id,
          }
          this.handleReset();
        } else {

          getAction(this.url.getOneOceanFeeSetVoById,{id:this.row.id})
              .then(res=>{
                this.addForm = res.result;
                this.startPortDictList = res.result.startPortDictList || [];
                this.endPortDictList = res.result.endPortDictList || [];
                this.cabinetTypeDictList = res.result.cabinetTypeDictList || [];
              })
        }
      } else {
        getAction(this.url.getOneOceanFeeSetVoById,{id:"没有iud"})
            .then(res=>{
              this.addForm = {};
              this.startPortDictList = res.result.startPortDictList || [];
              this.endPortDictList = res.result.endPortDictList || [];
              this.cabinetTypeDictList = res.result.cabinetTypeDictList || [];
            })
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
      let url = this.url.add
      if (this.row.id) {
        url = this.url.edit
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          httpAction(url, 'POST', this.addForm).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
