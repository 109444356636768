<template>
    <el-card style="height: 100%;" class="f-flex-col" shadow="never" :body-style="{flex: 1}">
      <el-form class="no_margin" :form="form" :inline="true">
                  <el-form-item label="客户名">
                          {{form.customerName}}
                  </el-form-item>
                  <el-form-item label="订单号">
                          {{form.orderNo}}
                  </el-form-item>
                  <el-form-item label="订单类型">
                          {{form.containerType === '0' ? 'LCL' : 'FCL'}}
                  </el-form-item>
                  <el-form-item label="件数">
                          {{form.ctn}}
                  </el-form-item>
                  <el-form-item label="重量">
                          {{form.kg}}
                  </el-form-item>
                  <el-form-item label="体积">
                          {{form.cmb}}
                  </el-form-item>
                  <el-form-item label="渠道名称">
                          {{form.transportStatus}}
                  </el-form-item>
                  <el-form-item label="运输方式">
                          {{form.sendType}}
                  </el-form-item>
                  <el-form-item label="航线名称">
<!--                          {{parseDict(form.solicitation,'fba_solicitation')}}-->
                          {{form.solicitationText}}
                  </el-form-item>
      </el-form>
      <receive-list style="height: calc(100% - 25px)" ref="receiveList" @updataDemo="initDictConfig" @selectKeys="nextOrderRecord" @listen="getMsgAgain"></receive-list>
    </el-card>
</template>

<script>
    import ImageModal from "./modules/ImageModal";
    import ReceiveList from "./ReceiveList";
    import QueryMixins from "@/mixins/QueryMixins";

    export default {
        name: 'VolumeInfo',
        mixins: [QueryMixins],
        components: {
            ImageModal,
            ReceiveList,
        },
        props: {
            data: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                dictCodes: [
                    'fba_solicitation'
                ],
                form:{},
                fromSon:'112',
                checkedKeys: [],
                row: {},
                visible: false,
                flag: true,
                filterText: '',
                currentNode: null,
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                url: {
                }
            }
        },
        watch: {

        },
        created() {
            this.disableMixinMounted = true;
        },
        beforeDestroy() {

        },
        methods: {
            getMsgAgain(obj){
                // console.log('待录材积',obj);
                this.form = obj;
                if (this.form.containerType == '0'){
                    // console.log('海运')
                    this.form.orderType = 'LCL'
                }else if (this.form.containerType == '1'){
                    // console.log('空运')
                    this.form.orderType = 'FCL'
                }else if (this.form.orderType == '2'){
                    // console.log('空运')
                    this.form.orderType = '空运'
                }else{
                    this.form.orderType = '未定义的订单类型'
                }

                if (this.form.sendType == '1'){
                    this.form.sendType = '海运'
                }else if (this.form.sendType == '3'){
                    this.form.sendType = '铁运'
                }else if (this.form.sendType == '2'){
                    this.form.sendType = '陆运'
                }else{
                    this.form.sendType = '海运'
                }
            },
            handleSearch() {
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            initDictConfig(){

            },
            nextOrderRecord(){
                //查询未录材积有图片的订单，收货单id，收货单信息
                getAction(this.url.queryOne,{}).then(res =>{
                    if (res.success) {
                        const obg = res.result;
                        this.$refs.receiveList.getOrderMain(obg.id,obg);
                    }
                })

            },
        }
    }
</script>

<style lang="scss">
    .menu-popover {
        padding: 4px;
        margin-top: 0;
    }
</style>

<style lang="scss" scoped>
    .tree-wrap {
        margin-top: 10px;
    }


        .el-card__body {
            overflow: auto;
        }
        .el-card__header {
            padding: 14px 20px;
        }
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
            background-color: #e0efff;
        }
        .el-tree-node__content {
            height: 28px;
        }
        .el-button {
            padding: 8px 16px;
        }

</style>
