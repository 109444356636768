<template>
  <pc-client :isDefault="true" />
</template>

<script>
import PcClient from "@/views/content/modules/PcClient";
export default {
  components: {
    PcClient,
  },
};
</script>

<style>
</style>