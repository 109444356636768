import { render, staticRenderFns } from "./Frozen.vue?vue&type=template&id=876c2d7e&scoped=true&"
import script from "./Frozen.vue?vue&type=script&lang=js&"
export * from "./Frozen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "876c2d7e",
  null
  
)

export default component.exports