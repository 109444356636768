<template>
  <el-dialog v-if="type !== 'view'" :title="row.id ? '编辑大森林海外仓产品' : '新增大森林海外仓产品'" :visible="visible" custom-class="c-custom-dialog width_1200_dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文配置" name="zh" v-loading="loading">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="标题" prop="titleCn">
                <el-input v-model.trim="form.titleCn" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="封面" prop="coverFile">
                <f-upload v-model="form.coverFile" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 391*192</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="loading == false" label="内容" prop="contentCn">
                <vue-ueditor-wrap class="ueditor_forest_box" v-model="form.contentCn"
                  :config="editorConfig"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="英文配置" name="en" v-loading="loading">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="标题" prop="titleEn">
                <el-input v-model.trim="form.titleEn" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="封面" prop="coverEnFile">
                <f-upload v-model="form.coverEnFile" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 391*192</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="loading == false" label="内容" prop="contentEn">
                <vue-ueditor-wrap class="ueditor_forest_box" v-model="form.contentEn"
                  :config="editorConfig"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
  <el-dialog v-else-if="type == 'view'" title="预览" :visible="visible" custom-class="c-custom-dialog width_1200_dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-row>
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文内容" name="zh">
          <el-col :span="24">
            <div v-html="form.contentCn"></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="英文内容" name="en">
          <el-col :span="24">
            <div v-html="form.contentEn"></div>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: 'OverseasProductDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    productType:{
      type: String,
      default:'',
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      loading: true,
      activeName: 'zh',
      form: {},
      rules: {
        titleCn: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        titleEn: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        coverFile: [
          { required: true, message: '请选择封面', trigger: 'change' },
        ],
        coverEnFile: [
          { required: true, message: '请选择封面', trigger: 'change' },
        ],
        contentCn: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        contentEn: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      checkKey: {
        zh: ['titleCn', 'coverFile', 'contentCn'],
        en: ['titleEn', 'coverEnFile', 'contentEn']
      },
      url: {
        add: '/sys/owForestProduct',
        edit: '/sys/owForestProduct',
        detail: '/sys/owForestProduct/'
      },
      editorConfig: {
        UEDITOR_HOME_URL: "/static/UEditor/",
        serverUrl: "/static/UEditor/jsp/config.json"
      },
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if(this.row.id) {
          this.getDetail(this.row)
        } else {
          this.loading = false;
        }
      }
    }
  },
  methods: {
    getDetail(row) {
      this.$store.commit("setFullscreenLoading", true);
      httpAction(this.url.detail + row.id, 'GET').then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.form = res.result;
        this.form.coverEnFile && JSON.stringify(this.form.coverEnFile) !== '{}' ? this.form.coverEnFile = [this.form.coverEnFile] : '';
        this.form.coverFile && JSON.stringify(this.form.coverFile) !== '{}' ? this.form.coverFile = [this.form.coverFile] : '';
        this.loading = false;
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
        this.loading = false;
      })
    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if (this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.coverEnFile;
      delete params.coverFile;
      params.coverUrlEn = (this.form.coverEnFile && this.form.coverEnFile.length && this.form.coverEnFile[0].url) || '';
      params.coverUrlEnId = (this.form.coverEnFile && this.form.coverEnFile.length && this.form.coverEnFile[0].id) || '';
      params.coverUrlCn = (this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0].url) || '';
      params.coverUrlCnId = (this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0].id) || '';
      params.productType = this.productType
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写对应的英文配置';
            this.$message.warning(message);
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.loading = true;
      this.form = {}
      this.activeName = 'zh'
      this.$refs.form && this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
