<template>
    <el-dialog
        :title="row.id ? '编辑字典' : '新增字典'"
        :visible="visible"
        appen-to-body
        width="560px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="ruleName" label="名称">
                        <el-input v-model="form.ruleName" maxlength="50" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="ruleCode" label="编码">
                  <el-input v-model="form.ruleCode" maxlength="50" show-word-limit></el-input>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="isEnable" label="是否启用">
                        <f-select v-model="form.isEnable" :is-need="form.isEnable" :dict="'unit_whether'"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="ruleType" label="类型">
                        <f-select v-model="form.ruleType" :is-need="form.ruleType" :dict="'sale_config_dict_rule_type'"></f-select>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="orderType" label="订单类型">
                  <f-select v-model="form.orderType" :is-need="form.orderType" :dict="'sale_config_dict_order_type'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="form.ruleType === '2'">
                <el-form-item prop="dictCode" label="字典编码">
                  <el-input v-model="form.dictCode" maxlength="50" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
export default {
    name: 'salesPerConfigDictDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {},
            rules: {
                ruleName: [
                    { required: true, message: '请输入名称', trigger: 'change' },
                ],
              ruleCode: [
                { required: true, message: '请输入编码', trigger: 'change' },
              ],
              isEnable: [
                { required: true, message: '请选择是否启用', trigger: 'change' },
              ],
              ruleType: [
                { required: true, message: '请选择类型', trigger: 'change' },
              ],
              orderType: [
                { required: true, message: '请选择订单类型', trigger: 'change' },
              ],
                dictCode: [
                    { required: true, message: '请输入字典编码', trigger: 'change' },
                ],
            },
            url: {
                add: '/shipping/sales/per/config/dict/add',
                edit: '/shipping/sales/per/config/dict/edit'
            }
        }
    },
    watch: {
        visible(val) {
            if(val) {
               this.row.id && (this.form = { ...this.row })
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.form = {}
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
