<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item>
              <f-select placeholder="时间" slot="label" :dataList="dateTypeList" v-model="dateType" :isNeed="dateType" @changet="() => {this.$set(this.queryForm, 'salesMouth', '');}">
              </f-select>
              <el-date-picker class="width_date_per" v-model="queryForm.salesMouth" :type="dateType" placeholder="选择时间" :value-format="dateType == 'year' ? 'yyyy' : 'yyyy-MM'">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="buttonShowList.saleman">
            <el-form-item label="业务员" label-width="60px">
              <f-select ref="salesmanIds" v-model="queryForm.salesmanIds" :isNeed="queryForm.salesmanIds" :dict="'salesmanId'" :isAll="true"
                :multiple="true" :collapseTags="true" @keyup.enter.native="handleSearch" @selectAll="handleSelectAll"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="拼柜类型">
              <f-select v-model="queryForm.containerType" :isNeed="queryForm.containerType" :dataList="containerTypeList">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportSalesProfit, '销售利润报表')"
          v-if="buttonShowList.dc">导出
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { deleteAction, postAction } from "@/api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";

  export default {
    name: "SaleReport",
    mixins: [QueryMixins],
    components: {
    },
    props: {
      salesMouth: {
        type: String,
        default: ''
      },
      containerTypeList: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        dateType: 'month',
        dateTypeList: [{ label: '年', value: 'year' }, { label: '月', value: 'month' }],
        initData: {
          "isCache": "true",
          "isSales": "true",
        },
        disableMixinMounted: true,
        isUsePost: true,
        columns: [
          {
            type: "selection",
            width: 50,
          },
          {
            prop: "name",
            label: "部门",
          },
          {
            prop: "salesman",
            label: "业务员",
          },
          {
            prop: "salesMouth",
            label: "年月",
          },
          {
            prop: "volumeCmb",
            label: "货量CBM/KG",
          },
          {
            prop: "volumeCmbRank",
            label: "货量排名",
            sortable: "custom",
          },
          {
            prop: "totalProfit",
            label: "毛利汇总CNY",
          },
          {
            prop: "totalProfitRank",
            label: "总毛利排名",
            sortable: "custom",
          },
          {
            prop: "profitPerCubicMeter",
            label: "每方毛利CNY",
          },
          {
            prop: "profitPerCubicMeterRank",
            label: "每方毛利排名",
            sortable: "custom",
          },
          {
            prop: "totalOrder",
            label: "订单票数",
            sortable: "custom",
          },
        ],
        url: {
          list: "/shipping/report/getProfit",
          exportSalesProfit: '/shipping/report/exportSalesProfit '
        },
      };
    },
    created() {
      this.$set(this.queryForm, 'salesMouth', this.salesMouth);
    },
    methods: {
      getTopMouth() {
        // 获取当前时间对象
        let currentDate = new Date();
        // 设置为上一个月
        currentDate.setMonth(currentDate.getMonth() - 1);
        // 格式化输出年、月
        const year = currentDate.getFullYear(); // 获取年份
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（需要加1）
        this.queryForm.salesMouth = year + '-' + month;
      },
      handleReset() {
        this.queryForm = {};
        this.$refs.salesmanIds && this.$refs.salesmanIds.handleResetAll && this.$refs.salesmanIds.handleResetAll();
        this.$set(this.queryForm, 'salesMouth', this.salesMouth);
        this.filterForm = {};
        this.ipagination.currentPage = 1
        this.handleQuery()
      },
      handleSelectAll(val) {
        let salesmanList = [];
        if(val.checked) {
          for (const key in val.dictMap) {
            salesmanList.push(val.dictMap[key].value)
          }
          this.$set(this.queryForm, 'salesmanIds', salesmanList)
        } else {
          this.$set(this.queryForm, 'salesmanIds', [])
        }
      }
    },
    computed:{
      buttonShowList() {
        return {
          'saleman':this.$btnIsShow('sale/SalesReport/SaleReport','0','业务员'),
          'dc':this.$btnIsShow('sale/SalesReport/SaleReport', '0', '导出'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>