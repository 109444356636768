<!--潜在合作-->
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="供应商中文名:">
                            <el-input v-model="queryForm.chinaseName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="供应商英文名:" width="80px">
                            <el-input v-model="queryForm.englishName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="供应商类型:">
                            <f-select
                                    v-model="queryForm.supplierType"
                                    :is-need="queryForm.supplierType"
                                    :dict="'sup_type'"
                                    placeholder="请选择" filterable clearable>
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="添加人">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'sys_user'"
                                      v-model="queryForm.createBy"
                                      :is-need="queryForm.createBy"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button type="primary" size="medium" icon="el-icon-search" @click="handleSearch">查询
                            </el-button>
                            <el-button plain size="medium" @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-dialog title="审核" :visible.sync="dialogFormVisibleShenhe" :close-on-click-modal="false">
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="拒绝原因" :label-width="formLabelWidth" prop="remarks">
                    <el-input v-model="form.remarks" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleShenhe = false">取 消</el-button>
                <el-button type="primary" @click="refuse">拒 绝</el-button>
                <el-button type="primary" @click="potentialVerify">通 过</el-button>
            </div>
        </el-dialog>


        <el-dialog title="全局搜索供应商" :visible.sync="dialogFormVisibleSelect" :close-on-click-modal="false">
            <div style="width: 300px;display: flex;align-items: center">
                <span style="width: 160px;font-size: 60px">供应商中文名:</span>
                <el-input v-model="chinaseName"></el-input>
                <el-button type="primary" size="medium" style="margin-left: 20px" @click="sureSelect()">查询
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columnSelect"
                    :data="tableDataSelect"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    v-if="this.isSelect"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'cooperationType'">
                        <span>{{parseDict(scope.row.cooperationType,'cooperation_type')}}</span>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipaginationSelect"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChangeSelect"
                        @current-change="handleCurrentChangeSelect"
                        v-if="this.isSelect"
                >
                </el-pagination>
            </el-row>
        </el-dialog>


        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" icon="el-icon-plus" type="primary" @click="handleAdd"
                           v-if="buttonShowList.add"

                >新增
                </el-button>
                <el-button size="medium" icon="el-icon-plus" type="primary" @click="handleSelect"
                           v-if="buttonShowList.search"
                >全局搜索供应商
                </el-button>

            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="65vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj"

                        >编辑
                        </el-button>
                        <el-button type="text" @click="handleDele(scope.row)"
                                   v-if="buttonShowList.sc"

                        >删除
                        </el-button>
                        <el-button type="text" @click="handleShenhe(scope.row)"
                                   v-if="buttonShowList.sh"

                        >审核
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'settlementType'">
                        <span>{{parseDict(scope.row.settlementType,'sup_settle')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supplierType'">
                        <span>{{parseDict(scope.row.supplierType,'sup_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supplierNature'">
                        <span>{{parseDict(scope.row.supplierNature,'supplier_nature')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'salesmanId'">
                        <span>{{parseDict(scope.row.salesmanId,'sys_user_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'chinaseName'">
                        <a style="color: #00a0e9" @click.prevent="handleEdit(scope.row)">{{scope.row.chinaseName}}</a>
                    </template>
                </template>

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>

<script>
    import {setUpRouteUtil} from '@/utils/util';
    import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
    import {zhognwenPattern} from "@/utils/pattern";
    import QueryMixins from "../../mixins/QueryMixins";
    import {getcityList, getstateList} from "../../api/manage";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                ipaginationSelect: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                dictCodes: ['sup_type', 'supplier_nature', 'sup_settle', 'sys_user_id','cooperation_type'],
                ids: [],
                row: {},
                form: {},
                countrylist: [],
                statelist: [],
                citylist: [],
                userlist: [],
                tableDataSelect: [],
                formLabelWidth: '120px',
                dialogFormVisibleShenhe: false,
                dialogFormVisibleSelect: false,
                chinaseName:'',
                isSelect:false,
                rules: {
                    chinaseName: [
                        {required: true, message: '请输入中文名', trigger: 'blur'},
                        {pattern: zhognwenPattern, message: '请输入中文', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    englishName: [
                        {required: true, message: '请输入英文名', trigger: 'blur'},
                        // {pattern: yingwenPattern, message: '请输入英文', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    settlementType: [
                        {required: true, message: '请选择结算方式', trigger: 'blur'}
                    ],
                    supplierType: [
                        {required: true, message: '请选择供应商类型', trigger: 'blur'}
                    ],
                    contact: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    tel: [
                        {required: true, message: '请输入电话', trigger: 'blur'},
                        // {pattern: mobilePattern, message: '手机号格式有误', trigger: 'change'},
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'blur'},
                        // {pattern: emailPattern, message: '邮箱格式有误', trigger: 'change'},
                        {type: "email", message: "请输入正确的邮箱", trigger: "change"},
                    ],
                    country: [
                        {required: true, message: '请选择国家', trigger: 'blur'}
                    ],
                    state: [
                        {required: true, message: '请选择州', trigger: 'blur'}
                    ],
                    city: [
                        {required: true, message: '请选择城市', trigger: 'blur'}
                    ],
                    address: [
                        {required: true, message: '请输入地址', trigger: 'blur'}
                    ],
                    registerAddress: [
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    remarks: [
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                },
                columns: [
                    {
                        type: 'selection',
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'supplierNature',
                        label: '性质',
                        sortable: 'custom',
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'chinaseName',
                        label: '供应商中文名',
                        sortable: 'custom',
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'englishName',
                        label: '供应商英文名',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'settlementType',
                        label: '结算方式',
                        sortable: 'custom',
                        width: 130,
                    },
                    {
                        prop: 'supplierType',
                        label: '供应商类型',
                        sortable: 'custom',
                        width: 140,
                    },
                    {
                        prop: 'contact',
                        label: '联系人',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'tel',
                        label: '联系电话',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'landline',
                        label: '座机',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'email',
                        label: '邮箱',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: 'country',
                        label: '国家',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'registerAddress',
                        label: '详细地址',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'advantageRoute',
                        label: '优势航线',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'remarks',
                        label: '备注',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'createTime',
                        label: '创建时间',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'salesmanId',
                        label: '跟进人',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'recommendBy',
                        label: '推荐人',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'sort',
                        label: '排序',
                        sortable: 'custom',
                        width: 140
                    },
                ],
                columnSelect: [
                    {
                        prop: 'chinaseName',
                        label: '供应商中文名',
                        sortable: 'custom',
                        width: 140,
                    },
                    {
                        prop: 'englishName',
                        label: '供应商英文名',
                        sortable: 'custom',
                        width: 140,
                    },
                    {
                        prop: 'cooperationType',
                        label: '供应商状态',
                        sortable: 'custom',
                        width: 140,
                    },
                ],
                url: {
                    list: '/sys/supplier/list',
                    add: '/sys/supplier/add',
                    edit: '/sys/supplier/edit',
                    getById: '/sys/supplier/queryById',
                    delete: '/sys/supplier/delete',
                    potentialVerify: '/sys/supplier/potentialVerify',  //潜在合作审核
                    refuse: '/sys/supplierOperatorHis/add',
                },
            }
        },
        methods: {
            initStateList(val) {
                this.statelist = [];
                this.citylist = [];
                if (val) {
                    getstateList({cnName: val})
                        .then((res) => {

                            if (res.success) {
                                for (var i = 0; i < res.result.records.length; i++) {
                                    let obj = {};
                                    obj.value = res.result.records[i].cname;
                                    obj.label = res.result.records[i].code + "-" + res.result.records[i].cname;
                                    this.statelist.push(obj);
                                }
                            }
                        })
                } else {
                    // this.form.city = '';
                    // this.form.state = '';
                    // this.form.bdState = '';
                    // this.form.bdCity = '';
                }

            },
            initCityList(val) {
                this.citylist = [];
                if (val) {
                    getcityList({cname: val})
                        .then((res) => {
                            if (res.success) {
                                for (var i = 0; i < res.result.records.length; i++) {
                                    let obj = {};
                                    obj.value = res.result.records[i].cname;
                                    obj.label = res.result.records[i].code + "-" + res.result.records[i].cname;
                                    this.citylist.push(obj);
                                }
                            }
                        })
                } else {

                }

            },
            //点击新增跳转页面
            handleAdd() {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/supplier/Edit');
                this.$router.push(`/supplier/Edit`)
            },
            init() {
                this.queryForm.cooperationType = "0";
                this.queryForm.initUser = 1;
            },
            handleEdit(row) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/supplier/Edit');
                this.$router.push(`/supplier/Edit?id=${row.id}`)
            },
            saveSupplier() {
                //保存供应商
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.handleQuery();
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.handleQuery();
                                    }
                                })
                            //清除
                            // this.$refs['form'].resetFields();
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除供应商：${row.chinaseName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            //点击审核，显示弹窗
            handleShenhe(row) {
                getAction(this.url.getById, {id: row.id})
                    .then(res => {
                        this.form = res.result;
                        this.form.remarks = "";
                    })
                this.dialogFormVisibleShenhe = true;

            },
            //潜在合作审核通过
            potentialVerify() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.potentialVerify, this.form)
                            .then(res => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisibleShenhe = false;
                                    this.handleQuery()
                                }
                            })
                    }
                })
            },
            //审核拒绝
            refuse() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        //添加操作历史记录
                        let remarks = this.form.remarks;
                        if (!remarks) {
                            this.$message.error('请输入原因');
                        } else if (remarks.length > 255) {
                            this.$message.error('长度不能超过255')
                        } else {
                            postAction(this.url.refuse, {
                                supplierId: this.form.id,
                                remarks: this.form.remarks,
                                operatorType: '11'
                            })
                                .then(res => {
                                    this.$message.success(res.message);
                                })
                            this.dialogFormVisibleShenhe = false;
                            this.handleQuery();
                        }
                        //关闭弹窗
                    }
                })
            },
            handleSelect() {
                this.isSelect=false;
                this.tableDataSelect = [];
                this.chinaseName='';
                this.dialogFormVisibleSelect = true;
            },
            sureSelect(){
                this.form.chinaseName = this.chinaseName;
                this.form.cooperationType='';
                this.pageSelect();
            },
            pageSelect() {
                if (this.ipaginationSelect) {
                    this.form.pageNo = this.ipaginationSelect.currentPage
                    this.form.pageSize = this.ipaginationSelect.pageSize
                }
                getAction(this.url.list, this.form).then((res) => {
                    console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result
                            this.tableDataSelect = records || res.result;

                            this.ipaginationSelect && (this.ipaginationSelect.total = total || res.result.length)
                            this.isSelect=true;
                        } else {
                            this.tableDataSelect = [];
                            this.ipaginationSelect = 0;
                        }
                    }
                })


            },

            handleSizeChangeSelect(val) {
                this.ipaginationSelect.currentPage = 1
                this.ipaginationSelect.pageSize = val
                this.pageSelect()
            },
            handleCurrentChangeSelect(val) {
                this.ipaginationSelect.currentPage = val
                this.pageSelect()
            },
            handleReset() {
                this.queryForm = {};
                this.init();
                this.ipagination.currentPage = 1
                this.handleQuery()
            },

        }, created() {
            this.init();

        },
      computed:{
        buttonShowList() {
          return {
            'sh':this.$btnIsShow('supplier/Potential','1','审核'),
            'sc':this.$btnIsShow('supplier/Potential','1','删除'),
            'bj':this.$btnIsShow('supplier/Potential','1','编辑'),
            'search':this.$btnIsShow('supplier/Potential','0','全局搜索供应商'),
            'add':this.$btnIsShow('supplier/Potential','0','新增'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
