<template>
    <el-dialog

        :visible="visible"
        width="50%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-checkbox
                v-model="checkAll"
                @change="isAll"
        >全选
        </el-checkbox
        >
        <div v-for="(item,index) in vueVoList" :key="index" style="margin-left: 30px">
            <el-checkbox
                    v-model="item.check"
                    :indeterminate="item.isIndeterminate"
                    @change="checkTitle(item.check, index,vueVoList)"
            >{{item.vueName}}
            </el-checkbox>
            <div  style="margin-left: 30px" v-if="item.btnVoList && item.btnVoList.length>0">
                <el-checkbox-group
                        v-model="item.btnIdList"
                        @change="checkItem(item.btnIdList,index,vueVoList)"
                >
                    <el-checkbox
                            v-for="(a, j) in item.btnVoList"
                            :label="a.id"
                            :key="j"
                    >{{ a.btnName }}
                    </el-checkbox
                    >
                </el-checkbox-group>
            </div>
        </div>

        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit" :loading="bbb">确定</el-button>
<!--            <el-button type="primary" size="medium" @click="makePinyin">确定2</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
import { httpAction,getAction } from '@/api/manage'
import pinyin from 'js-pinyin'
import {postAction} from "../../../api/manage";
export default {
    name: 'RoleBtnPermissionDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            bbb :false,
            checkAll:false,
            form: {},
            vueVoList:[],
            url: {
                add: '/sys/role/add',
                edit: '/sys/role/edit',
                getVueVoList:'/sys/button/auth/getVueVoListByRoleId',
                getVueVoListWithUser:'/sys/button/auth/getVueVoListByUserId',
                updateBtnAutnByRoleId:'/sys/button/auth/updateBtnAutnByRoleId',
                updateBtnAutnByUserId:'/sys/button/auth/updateBtnAutnByUserId',
            }
        }
    },
    watch: {

        visible(val) {
            this.bbb = false;
            if(val && this.row.id) {
                this.initVueVoList(this.row);
            } else {
            }
        },
        'form.roleName': {
            handler:function(name) {
                if (name !== '' && name !== undefined){
                    this.form.roleCode = this.makePinyin(name);
                }else{
                    this.form.roleCode = ''
                }

            },
            deep: true,
            // immediate: true
        }
    },
    methods: {
        isAllSel() {
            let count = 0;
            this.vueVoList.map(ff => {
                if (ff.isCheck) {
                    count++;
                }
            })
            this.$set(this, 'checkAll', this.vueVoList.length === count);
        },
        checkItem(val,index,list){
            console.log('c勾选单个', val, list[index].children);
            let checkedCount = val.length;
            this.$set(list[index], 'isCheck', checkedCount === list[index].btnVoList.length);
            // list[index].isCheck =
            //   checkedCount === list[index].children.length;
            this.$set(list[index], 'isIndeterminate', checkedCount > 0 && checkedCount < list[index].btnVoList.length);
            // list[index].isIndeterminate =
            //   checkedCount > 0 && checkedCount < list[index].children.length;
            this.isAllSel();
        },
        checkTitle(val,index,list){
            let arr =[];
            const re = list[index].btnVoList;
            //全选
            if (val){
                for (let i=0;i<re.length;i++) {
                    arr[i] = re[i].id;
                }
            }
                list[index].btnIdList = arr;
                list[index].isIndeterminate = false;

        },
        isAll(val){
                for (var i = 0; i < this.vueVoList.length; i++) {
                        this.$set(this.vueVoList[i], 'check', val);
                    this.checkTitle(val, i, this.vueVoList);
                }

        },
        initVueVoList(row){
            if (row.gg ==='user'){
                getAction(this.url.getVueVoListWithUser,{userId:row.id,bp:row.bp})
                    .then(res=>{
                        this.vueVoList = res.result;
                        console.log('this.vueVoList',this.vueVoList);
                    })
            }else {
                getAction(this.url.getVueVoList,{roleId:row.id,bp:row.bp})
                    .then(res=>{
                        this.vueVoList = res.result;
                        console.log('this.vueVoList',this.vueVoList);
                    })
            }

        },
        handleSubmit() {
            this.bbb = true;
            let btnIdList = [];
            let allBtnIdList = [];
            for (let i=0;i<this.vueVoList.length;i++){
                let bb = this.vueVoList[i];
                let list = bb.btnIdList;
                if (list && list.length>0 && Array.isArray(list)){
                    for (var j=0;j<list.length;j++){
                        if (list[j]){
                            btnIdList.push(list[j]);
                        }

                    }
                }
                let list2 = bb.btnVoList;
                if (list2 && list2.length>0 && Array.isArray(list2)){
                    for (var j=0;j<list2.length;j++){
                        if (list2[j]){
                            allBtnIdList.push(list2[j].id);
                        }

                    }
                }
            }
            if (this.row.gg ==='user'){
                postAction(this.url.updateBtnAutnByUserId+"?bp="+this.row.bp+"&userId="+
                    this.row.id,{btnIdList:allBtnIdList,selectIdList:btnIdList}).then(res=>{
                    if (res.success){
                        this.$message.success(res.message);

                        this.handleClose();
                        this.bbb = false;
                    }else {
                        this.bbb = false;
                    }
                })
            }else {
                postAction(this.url.updateBtnAutnByRoleId+"?bp="+this.row.bp+"&roleId="+
                    this.row.id,{btnIdList:allBtnIdList,selectIdList:btnIdList}).then(res=>{
                    if (res.success){
                        this.$message.success(res.message);

                        this.handleClose();
                        this.bbb = false;
                    }else {
                        this.bbb = false;
                    }
                })
            }

        },
        handleClose() {
            this.$emit('okVisbles');

        },
    }
}
</script>

<style lang="scss" scoped>

</style>
