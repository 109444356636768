<template>
  <el-dialog
    :title="title"
    width="1800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">

      <el-row :gutter="24">
        <el-col :span="8">
          <el-button @click="handleAdds" type="primary" icon="plus">添加</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-table rowKey="id" :columns="columns"  :data-source="data"  bordered ref="myTable">
          <template
            v-for="col in ['settlement', 'feename', 'feenameUs', 'price','quantity','moneyType','total','remark']"
            :slot="col"
            slot-scope="text, record, index"
          >
            <div :key="col">
              <el-input
                v-if="record.locker === '0' || record.payLock===''"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, record.key, col,index)"
                @keydown.enter.native="nextFocus(index,index,$event)"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record, index">
            <div class="editable-row-operations">
              <a v-if="record.locker !== '1'" @click="() => handleDel(index,record)">删除</a>
              <a-divider type="vertical" />
              <a v-if="record.locker === '0'"  @click="() => lockSingleFee(record)"><el-icon type="unlock" />点击锁定费用</a>
              <a v-if="record.locker === '1'"  @click="() => unLockSingleFee(record)"><el-icon type="lock" />解除锁定费用</a>
            </div>
          </template>
          <template slot="moneyType" slot-scope="text, record, index" >
          </template>

          <template slot="feename" slot-scope="text, record, index">
          </template>

          <template slot="settlement" slot-scope="text, record, index">
            <forest-select
              v-model="record.settleid"
              :list="dataList"
              labelField="shortname"
              @change="(val, text) => pushsettlement(text, index)"></forest-select>
          </template>
        </el-table>
      </el-row>

    <div>
      <el-tabs default-active-key="1">
        <el-tab-pane key="1" tab="操作记录" forceRender>
            <MoneyInOperaRecordModal ref="moneyInoperaRecord"></MoneyInOperaRecordModal>
        </el-tab-pane>

      </el-tabs>
    </div>
  </el-dialog>
</template>
<script>

  import { getAction,httpAction,postAction } from '@/api/manage'
  import pick from 'lodash.pick'

  // import ForestSelect from '@/components/common/ForestSelect/ForestSelect'
  import funHelper from 'lodash/function'
  import MoneyInOperaRecordModal from "./MoneyInOperaRecordModal";

  export default {
    name: "FbaWarehouseOrderReceiveModal",
    components: {
      MoneyInOperaRecordModal
    },
    data () {
      return {
        ett:'',
        form: {},
        title:"操作",
        width:1800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        validatorRules: {
        },
        url: {
          add:'fbaWarehouseOrderReceive/addSettles',
          list:'fbaWarehouseOrderReceive/getSettles',
          del:'fbaWarehouseOrderReceive/delSettles',
          getFeeen:'fbaWarehouseOrderReceive/getFeeen',
          getMoneyIn:'fbaWarehouseOrderReceive/getMoneyIn',
          lockFee:'fbaWarehouseOrderReceive/lockFee',
          unLockFee:'fbaWarehouseOrderReceive/unLockFee',
        },
        orderInput: '',
        columns:[
          {
            title: '结算对象',
            dataIndex: 'settlement',
            width: '15%',
            align:"center",
            scopedSlots: { customRender: 'settlement' },

          },
          {
            title:'费用中文',
            align:"center",
            dataIndex: 'feename',
            width: '10%',
            scopedSlots: { customRender: 'feename' },
          },
          {
            title:'费用英文',
            align:"center",
            dataIndex: 'feenameUs',
            width: '10%',
            scopedSlots: { customRender: 'feenameUs' },
          },
          {
            title:'价格',
            align:"center",
            dataIndex: 'price',
            width: '6%',
            scopedSlots: { customRender: 'price' },
          },
          {
            title:'数量',
            align:"center",
            dataIndex: 'quantity',
            width: '6%',
            scopedSlots: { customRender: 'quantity' },
          },
          {
            title:'交易币种',
            align:"center",
            dataIndex: 'moneyType',
            width: '8%',
            scopedSlots: { customRender: 'moneyType' },
          },
          {
            title:'合计',
            align:"center",
            dataIndex: 'total',
            width: '6%',
            scopedSlots: { customRender: 'total' },
          },
          {
            title:'备注',
            align:"center",
            dataIndex: 'remark',
            width: '10%',
            scopedSlots: { customRender: 'remark' },
          },
          {
            title:'录入人',
            align:"center",
            dataIndex: 'creator',
            width: '6%',
            scopedSlots: { customRender: 'creator' },
          },
          {
            title:'录入时间',
            align:"center",
            dataIndex: 'createTime',
            width: '9%',
            scopedSlots: { customRender: 'createTime' },
          },
          {
            title: '操作',
            dataIndex: 'operation',
            align:"center",
            width: '10%',
            scopedSlots: { customRender: 'operation' },
          },
        ],
        data:[],
        settlements:[],

        moneyType:'',
        settlement:'',
        feename:'',
        feenameUs:'',

        inputIndex:0,

        pageSize: 50,
        pageNum: 1,
        dataList: [],
        timer: null,
        data_: {},
        orderNo:'',
        money: 'RMB',
        settname:'',


      }
    },
    created () {

    },
    methods: {

      editHsPay (record) {
        this.orderInput='';
        this.model = Object.assign({}, record);
        this.visible = true;
        this.data=[]
        if (JSON.stringify(this.model) !== '{}'){
          this.orderNo=this.model.orderNo
          this.settname=this.model.customerName
          //查询结算对象
          let that=this
          getAction(this.url.list, {"orderNo":this.model.orderNo,"pageNum":this.pageNum,"pageSize":this.pageSize}).then((res) => {
            if (res.code === 200){
              that.data_=res.result
              if (that.dataList.length <= that.data_.totalCount){
                that.dataList.push(...that.data_.agents)
              }
            }
          })
          //查询应收记录
          this.getMoneyInDetail()
        }
     },
      getMoneyInDetail(){
        //查询应收记录
        getAction(this.url.getMoneyIn, {"orderNo":this.model.orderNo}).then((res) => {
          if (res.code === 200){
            this.data=res.result.moneyInList
            setTimeout(()=> {
              this.$refs.moneyInoperaRecord.showMoneyInDatas(this.model)
            }, 500)
          }
        })
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      pushfeename(text,index){
        this.data[index].feename=text
        getAction(this.url.getFeeen, {"feename":text}).then((res) => {
          if (res.code === 200){
            this.data[index].feenameUs=res.result.enname
            this.data[index].feecode=res.result.feeCode
          }
        })
      },


      pushmoneyType(text,index){

        this.data[index].moneyType=text
      },
      pushsettlement(text, index){
        this.data[index].settlement = text
      },

      getsettls:funHelper.debounce(function(){
        if (this.pageNum < this.data_.totalPage){
          this.pageNum+=1;
          this.getDataList()
        }
      }, 500),

      getDataList(){
        let that=this
        getAction(this.url.list, {"orderNo":that.orderNo,"pageNum":this.pageNum,"pageSize":this.pageSize}).then((res) => {
          if (res.code === 200){
            that.data_=res.result
            if (that.dataList.length <= that.data_.totalCount){
              that.dataList.push(...that.data_.agents)
            }
          }else {

          }
        })
      },

      handleOk () {
        const that = this;
        this.form.validateFields((err, values) => {
          if (!err) {
            that.confirmLoading = true;
            let httpurl = that.url.add;
            let method = 'post';
            let mod={
              orderNo:that.model.orderNo,
              settles: that.data,
              type:'0'
            }
            console.log("表单提交数据",mod)
            httpAction(httpurl,mod,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                // that.close()
                this.getMoneyInDetail()
              }

            }).finally(() => {
              that.confirmLoading = false;

            })
          }

        })
      },
      handleDel(key,record) {
        let that = this
        if (record.hasOwnProperty('id')){
          //调用删除方法
          let httpurl = that.url.del;
          let method = 'post';
          let mod = {
            id: record.id,
            orderNo: record.orderNo,
            type: '0'
          }
          httpAction(httpurl, mod, method).then((res) => {
            if (res.success) {

              that.$message.success(res.message);
            }

          }).finally(() => {
            that.confirmLoading = false;
            that.data.splice(key, 1)
          })
        }else {
          that.data.splice(key, 1)
        }

      },
      handleCancel () {
        this.close()
      },
      handleChange(value, key, column,index) {
        this.data[index][column]=value
        const obj=this.data[index]
        if (obj.price !== '' && obj.quantity !== '') {
          obj.total= (Number(obj.price) * Number(obj.quantity)).toFixed(2)
        }else {
          obj.total=total.toFixed(2)
        }


      },

      handleAdds(){
        if (this.data.length === 0){
          let nkey=1
          this.data.push({
            key: nkey,
            settlement: this.model.customerName,
            settleid: this.model.customerId,
            feename: ``,
            feenameUs: ``,
            price: ``,
            quantity: 1,
            moneyType:this.money,
            total: ``,
            remark: ``,
            payLock:'',
            feecode:'',
          });

        }else {
          let num=this.data.length
          let nextKey=num+1
          this.data.push({
            key: nextKey,
            settlement: this.model.customerName,
            settleid: this.model.customerId,
            feename: ``,
            feenameUs: ``,
            price: ``,
            quantity: 1,
            moneyType: this.money,
            total: ``,
            remark: ``,
            payLock:'',
            feecode:'',
          });

        }

      },
      lockSingleFee(record){
        let obg = Object.assign({}, record);
        httpAction(this.url.lockFee,obg,'post').then(res => {
          if(res.success){
            this.$message.success(res.message);
            this.getMoneyInDetail()
          }
        })
      },
      unLockSingleFee(record){
        let obg = Object.assign({}, record);
        httpAction(this.url.unLockFee,obg,'post').then(res => {
          if(res.success){
            this.$message.success(res.message);
            this.getMoneyInDetail()
          }
        })
      },

    }
  }
</script>
<style>
.el-big-data-select {
  width: 300px;
}
</style>
