<template>
    <div>
      <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
        <el-card class="box-card">
          <el-row style="display: flex; flex-wrap: wrap">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="国家">
                <el-input
                    v-model="queryForm.country"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="FBA code">
                <el-input
                    v-model="queryForm.fbaCode"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="渠道">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.channelId"
                    :is-need="queryForm.channelId"
                    :dict="'channel_set'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="州">
                <el-input
                    v-model="queryForm.state"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="邮编">
                <el-input
                    v-model="queryForm.code"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="操作员">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.operateId"
                    :is-need="queryForm.operateId"
                    :dict="'operationsId'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="拼柜仓库">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.lclPlaceId"
                    :is-need="queryForm.lclPlaceId"
                    :dict="'lclPlace'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="航线名称">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.solicitation"
                    :is-need="queryForm.solicitation"
                    :dict="'fba_solicitation'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="录入人">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.createBy"
                    :is-need="queryForm.createBy"
                    :dict="'sys_user_id'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-card>
      </el-form>
        <el-card class="box-card">
        <div class="bbb">
            <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleAdd"
                    class="margin_right_10"
            >新增
            </el-button
            >
            <el-upload
                    class="upload-demo margin_right_10"
                    action=""
                    :http-request="importOperation"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    accept=".xls,.xlsx"
            >
                <el-button type="primary" size="medium"

                >导入操作绑定</el-button>
            </el-upload>
            <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.download, '操作绑定模板')"

            >操作绑定模板</el-button>
            <el-button
                    type="info"
                    size="medium"
                    @click="downloadExcel(url.exportOperation, '操作页面数据',queryForm)"
            >导出操作数据</el-button>
          <el-button type="primary" @click="handleDelDuo" size="medium" class="c-theme-error-button"
                     :disabled="selectionRows.length === 0">批量删除</el-button>

        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)"
                    >编辑</el-button>
                    <el-button type="text" @click="handleDel(scope.row)"
                    >删除</el-button>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑绑定' : '新增绑定'"
                v-if="dialogFormVisible"
                @close="dialogFormVisible = false"
                :visible.sync="dialogFormVisible"
                width="30%"
                center
                :close-on-click-modal="false"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="拼柜仓库"
                        prop="lclPlaceId"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'lclPlace'"
                            v-model="form.lclPlaceId"
                            :is-need="form.lclPlaceId"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="FBA Code"
                        prop="fbaCode"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'bd_fba_addr'"
                            v-model="form.fbaCode"
                            :is-need="form.fbaCode"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="渠道名称"
                        prop="channelId"
                        :label-width="formLabelWidth">
                  <f-select
                      :dict="'channel_set'"
                      v-model="form.channelId"
                      :is-need="form.channelId"
                  ></f-select>
                </el-form-item>
                <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
                   <el-input v-model="form.country" maxlength="50" show-word-limit></el-input>
                </el-form-item>
              <el-form-item label="州" prop="state" :label-width="formLabelWidth">
                <el-input v-model="form.state" maxlength="20" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="邮编" prop="code" :label-width="formLabelWidth">
                <el-input v-model="form.code" maxlength="20" show-word-limit></el-input>
              </el-form-item>
                <el-form-item
                        label="航线名称"
                        prop="solicitation"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'fba_solicitation'"
                            v-model="form.solicitation"
                            :is-need="form.solicitation"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="操作员"
                        prop="operateId"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'operationsId'"
                            v-model="form.operateId"
                            :is-need="form.operateId"
                    ></f-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveImporter" :disabled="btnDisable"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction, putAction} from "@/api/manage";

    export default {
        name: "OperationBindModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                deliveryList: [],
                btnDisable: false,
                row: {},
                form: {},
                dialogFormVisible: false,
                formLabelWidth: "120px",
                countrylist: [],

                //表单校验
                rules: {
                    lclPlaceId: [
                        {required: true, message: "请选择拼柜仓库", trigger: "change"},
                    ],
                    channelId: [
                        {required: true, message: "请选择渠道名称", trigger: "change"},
                    ],
                    operateId: [
                        {required: true, message: "请选择操作员", trigger: "change"},
                    ]
                },
                visible: false,
                columns: [
                  {
                    type: 'selection',
                    width: 33
                  },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "country",
                        label: "国家",
                    },
                  {
                    prop: "state",
                    label: "州",
                  },
                    {
                        prop: "code",
                        label: "邮编",
                        over: true,
                    },
                    {
                        prop: "fbaCode",
                        label: "FBA Code",
                        over: true,
                    },

                  {
                    prop: "lclPlace",
                    label: "拼柜仓库",
                  },
                    {
                        prop: "solicitationText",
                        label: "航线名称",
                    },
                    {
                        prop: "operator",
                        label: "操作人员",
                    },

                  {
                    prop: "createTime",
                    label: "录入时间",
                  },
                  {
                    prop: "createByStr",
                    label: "录入人",
                  },
                  {
                    prop: "updateTime",
                    label: "修改时间",
                  },
                  // {
                  //   prop: "updateByStr",
                  //   label: "修改人",
                  // },
                ],
                url: {
                    list: "/sys/operationBind/list",
                    delByid: "/sys/operationBind/delete/",
                    delDuo: "/sys/operationBind/deleteDuo",
                    add: "/sys/operationBind/add",
                    edit: "/sys/operationBind/edit",
                    import: "/sys/operationBind/import",
                    download: "/sys/operationBind/downloadExcel",
                    exportOperation: "/sys/operationBind/exportOperation"
                },

            };
        },
        created() {
        },
        methods: {
            handleClose() {
                if (this.$refs["form"]) {
                    this.$refs["form"].clearValidate();
                }
            },
            //导入操作绑定
            importOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.import, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            handleDel(row) {
                this.$confirm(`确定删除吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delByid+row.id, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    });
            },
          handleDelDuo() {
            this.$confirm(`确定批量删除吗`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            })
                .then(() => {
                  postAction(this.url.delDuo, this.selectedRowKeys).then((res) => {
                    this.$message({
                      type: "success",
                      message: res.message,
                    });
                    this.handleSearch();
                  });
                });
          },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
                this.btnDisable = false;
                this.handleClose();
            },
            handleEdit(row) {
                this.btnDisable = false;
                for (var name in row) {
                    this.$set(this.form, name, row[name]);
                }
                this.dialogFormVisible = true;
            },
            saveImporter() {
                this.btnDisable = true;
                console.log("进口商对象", this.form);
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisable = false;
                                    }
                                });
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisable = false;
                                    }
                                });
                        }
                    } else {
                        this.btnDisable = false;
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }
    .margin_right_10 {
      margin-right: 10px;
    }
</style>
