<template>
  <el-dialog
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    top="0px"
    class="right_dialog"
  >
    <div class="logNo">{{ row.logNo }}</div>
    <div class="title">事件信息</div>
    <div class="info">
      <div
        v-for="(item, index) in row.trackIng"
        :key="index"
        class="box flex_center"
      >
        <i class="el-icon-refresh" v-if="index == 0"></i>
        <i class="el-icon-crop" v-else></i>
        <div :class="['info_item', index == 0 ? 'bg' : '']">
          <span>{{ item.description }}</span>
          <span>{{ item.eventTime }}</span>
        </div>
        <!-- <div class="info_item bg">
          <span>{{ item.description }}</span>
          <span>{{ item.eventTime }}</span>
        </div> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style  lang="scss" scoped>
.logNo {
  width: 100%;
  color: #212121;
  font-size: 18px !important;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding-left: 10px;
}
.title {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
  opacity: 1;
  color: #212121;
  font-size: 20px !important;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  line-height: 26px;
  padding-left: 10px;
}
.info {
  height: calc(100vh - 160px);
  overflow: scroll;
}
.info_item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  word-break: break-all;
  margin-left: 10px;
  margin-top: 10px;

  color: #212121;
  font-weight: 700;
  width: 573px;
  min-height: 78px;
  // margin-bottom: 20px;
  &.bg {
    background-color: #f4fafe !important;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
}

::v-deep {
  .el-icon-refresh:before {
    font-size: 22px;
    color: #2d8cf0;
  }
  .el-icon-crop:before {
    font-size: 17px;
  }
}
</style>
