import { render, staticRenderFns } from "./PcBackstage.vue?vue&type=template&id=133602e3&scoped=true&"
import script from "./PcBackstage.vue?vue&type=script&lang=js&"
export * from "./PcBackstage.vue?vue&type=script&lang=js&"
import style0 from "./PcBackstage.vue?vue&type=style&index=0&id=133602e3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133602e3",
  null
  
)

export default component.exports