<template>
    <div class="main-full-content">
        <el-form
                class="multiline_select"
                ref="form"
                :model="queryForm"
                label-width="80px"
                size="medium"
        >
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户名称">
                            <el-input
                                    v-model="queryForm.username"
                                    @keyup.enter.native="handleSearch"
                                    type="textarea"
                                    placeholder="多个用,分隔"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="邮箱">
                            <el-input
                                    v-model="queryForm.email"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="电话">
                            <el-input
                                    v-model="queryForm.phone"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="海运业务员">
                            <f-select
                                    v-model="queryForm.salesmanId"
                                    :isNeed="queryForm.salesmanId"
                                    :dict="'sys_user_id'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="快递业务员">
                            <f-select
                                    :dict="'sys_user_id'"
                                    v-model="queryForm.kdSalesmanId"
                                    :isNeed="queryForm.kdSalesmanId"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="source" label="客户来源">
                            <f-select
                                    clearable
                                    filterable
                                    class="f-full-width"
                                    v-model="queryForm.source"
                                    :isNeed="queryForm.source"
                                    :dict="'cus_source'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="跟进人">
                            <f-select
                                    v-model="queryForm.follow"
                                    :isNeed="queryForm.follow"
                                    :dict="'sys_user_id'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="注册性质">
                            <f-select
                                    v-model="queryForm.regType"
                                    :isNeed="queryForm.regType"
                                    :dict="'register_type'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户等级">
                            <f-select
                                    v-model="queryForm.grade"
                                    :isNeed="queryForm.grade"
                                    :dict="'customer_type2'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label="注册时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="createBy" label="添加人">
                            <!--                      <el-input v-model="queryForm.createBy" placeholder="请输入创建人" maxlength="20" show-word-limit></el-input>-->
                            <f-select
                                    :dict="'sys_user'"
                                    v-model="queryForm.createBy"
                                    :is-need="queryForm.createBy"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label="审核时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.passTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.passTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label="合同时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.contractBegin"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.contractEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客服">
                            <f-select
                                    v-model="queryForm.docId"
                                    :isNeed="queryForm.docId"
                                    :dict="'sys_user_id'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="收款人">
                            <f-select
                                    v-model="queryForm.billmanId"
                                    :isNeed="queryForm.billmanId"
                                    :dict="'sys_user_id'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="提成性质">
                            <f-select
                                    v-model="queryForm.commission"
                                    :isNeed="queryForm.commission"
                                    :dict="'commission'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="haveContract" label="有无合同">
                            <el-radio-group v-model="queryForm.haveContract">
                                <el-radio :label="'0'">无合同</el-radio>
                                <el-radio :label="'1'">有合同</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <!--                    <el-col :span="6">-->
                    <!--                        <el-form-item prop="isShiqu" label="是否属于流失客户" label-width="120px">-->
                    <!--                            <f-select class="f-full-width" v-model="queryForm.isShiqu" :isNeed="queryForm.isShiqu" :dict="'is_default'"></f-select>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <!--                    <el-col :span="6">-->
                    <!--                        <el-form-item prop="isPushStr" label="是否属于推广注册" label-width="120px">-->
                    <!--                            <f-select class="f-full-width" v-model="queryForm.isPushStr" :isNeed="queryForm.isPushStr" :dict="'is_default'"></f-select>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label="第一次下单时间" label-width="120px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.firstOrderTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.firstOrderTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="firstOrderCountryList" label="第一次下单国家"  label-width="120px">
                            <!--<el-input-->
                                    <!--v-model="queryForm.firstOrderCountry"-->
                                    <!--@keyup.enter.native="handleSearch"-->
                            <!--&gt;</el-input>-->
                            <f-select
                                    :multiple="true"
                                    v-model="queryForm.firstOrderCountryList"
                                    :isNeed="queryForm.firstOrderCountryList"
                                    :dict="'bd_country'">
                            </f-select>
                        </el-form-item>
                    </el-col>

                   <el-col :span="6">
                     <el-form-item label="客户需求">
                        <f-select :dict="'customer_need'"
                                 v-model="queryForm.need"
                                 :isNeed="queryForm.need"
                       > </f-select>
                     </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleBatBack"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.backB"
                >批量退回
                </el-button
                >

                <el-button
                        type="primary"
                        size="medium"
                        @click="handleBatTransfer"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.zrB"
                >批量转让
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleBatSetDoc"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.szkfB"
                >批量设置客服
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleBatAddFollower"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.szgjrB"
                >批量设置跟进人
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleResetPassBatch"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.rePB"
                >批量重置密码
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        class="c-theme-error-button"
                        @click="handleBatDel"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.delB"
                >批量删除
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleBatGenerateCard"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.sckqB"
                >批量生成卡券
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleSendEmail"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.fsyj"
                >发送邮件
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleUpdateCustomerCommission"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.xgkhxzB"
                >批量修改客户提成性质
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleSendMsg"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.fsdx"
                >发送短信
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-download"
                        v-if="buttonShowList.dc"
                        @click="handleExportXls"
                >导出
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        icon="c-theme-error-button"
                        v-if="buttonShowList.setV"
                        @click="handleBatAddVIP"
                >设置VIP客户
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        icon="c-theme-error-button"
                        @click="automaticCostEntry"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.zdlrfy"
                >自动录入费用
                </el-button>
              <el-button
                  type="primary"
                  size="medium"
                  icon="c-theme-error-button"
                  v-if="buttonShowList.jclzdcB"
                  :disabled="selectionRows.length === 0"
                  @click="handleBatCancelIsExcep"
              >批量解除拦截订舱
              </el-button>
              <el-button
                  type="primary"
                  size="medium"
                  icon="c-theme-error-button"
                  v-if="buttonShowList.szlzdcB"
                  :disabled="selectionRows.length === 0"
                  @click="handleBatCancelIsExcep2"
              >批量设置拦截订舱
              </el-button>
              <el-button
                  type="primary"
                  size="medium"
                  icon="c-theme-error-button"
                  v-if="buttonShowList.qxVB"
                  :disabled="selectionRows.length === 0"
                  @click="handleBatCancelVIPCustomer"
              >取消VIP客户
              </el-button>
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
                <span
                        class="stress f-m-l-12 f-cursor-pointer"
                        @click="handleClearSelection"
                >清空</span
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="58vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                                type="text"
                                @click="handleEdit(scope.row)"
                                v-if="buttonShowList.edit"
                        >编辑
                        </el-button>
                        <el-button
                                type="text"
                                @click="handleFrozen(scope.row)"
                                v-if="buttonShowList.dj"
                        >冻结
                        </el-button>
                        <el-button
                                type="text"
                                @click="handlSetDoc(scope.row)"
                                v-if="buttonShowList.szkf"
                        >设置客服
                        </el-button>
                        <el-button
                                type="text"
                                @click="handlTransfer(scope.row)"
                                v-if="buttonShowList.zr"
                        >转让
                        </el-button>
                        <el-button
                                type="text"
                                @click="handlAddRecord(scope.row)"
                                v-if="buttonShowList.tjbf"
                        >添加拜访
                        </el-button>
                        <el-button
                                type="text"
                                @click="handlAddFollower(scope.row)"
                                v-if="buttonShowList.szgjr"
                        >设置跟进人
                        </el-button>
                        <el-button
                                type="text"
                                @click="handleResetPass(scope.row)"
                                v-if="buttonShowList.reP"
                        >重置密码
                        </el-button>
                        <el-button
                                type="text"
                                @click="loginClient(scope.row.id)"
                                v-if="buttonShowList.loginC"
                        >客户端登录
                        </el-button>
                        <!--                      <el-button type="text" @click="xiazai(scope.row)"-->

                        <!--                                 v-if="$btnIsShow('customer/Normal','1','批量下载报关单+电放提形章')"-->
                        <!--                      >批量下载报关单+电放提形章-->
                        <!--                      </el-button>-->

                        <div>
                            <el-switch
                                    v-model="scope.row.isExpensesBasic"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="isExpensesBasicChange(scope.row)"
                                    v-if="buttonShowList.fyzdlr"
                            >
                            </el-switch>
                            <span style="color: #2d8cf0; margin-left: 5px">费用自动录入</span>
                        </div>
                    </template>
                    <template v-else-if="scope.column.property === 'source'">
                        <span>{{ parseDict(scope.row.source, "cus_source") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'grade'">
                        <span>{{ parseDict(scope.row.grade, "customer_type2") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'commission'">
                        <span>{{ parseDict(scope.row.commission, "commission") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'intent'">
                        <span>{{ parseDict(scope.row.intent, "cus_intent") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'regType'">
                        <span v-if="scope.row.regType == '0'">企业</span>
                        <span v-else-if="scope.row.regType == '1'">个人</span>
                        <span v-else></span>
                    </template>
                    <template v-else-if="scope.column.property === 'username'">
                        <a
                                type="text"
                                @click.prevent="handleEdit(scope.row)"
                                style="color: #57a3f3"
                        >{{ scope.row.username }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'salesmanId'">
                        <span>{{ parseDict(scope.row.salesmanId, "sys_user_id") }}</span>
                    </template>

                    <!--                    <template v-else-if="scope.column.property === 'hasSendEmail'">-->
                    <!--                        <span v-if="scope.row.sendEmailSixMonthNum>0" style="color: red">属于</span>-->
                    <!--                        <span v-else>不属于</span>-->
                    <!--                    </template>-->

                    <!--                    <template v-else-if="scope.column.property === 'isPush'">-->
                    <!--                        <span v-if="scope.row.isPush===1" style="color: red">属于</span>-->
                    <!--                        <span v-else>不属于</span>-->
                    <!--                    </template>-->
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <!--      <el-dialog title="发送邮件" :visible.sync="dialogFormVisibleEmail" :close-on-click-modal="false" v-if="dialogFormVisibleEmail">-->
        <!--        <el-form :model="form">-->
        <!--          <el-form-item label="抬头" :label-width="formLabelWidth">-->
        <!--            <el-input v-model="form.title" autocomplete="off"></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="内容" :label-width="formLabelWidth">-->
        <!--            <el-input v-model="form.content" autocomplete="off"></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="邮箱" :label-width="formLabelWidth">-->
        <!--            <el-input v-model="form.emails" autocomplete="off" ></el-input>-->
        <!--          </el-form-item>-->
        <!--          <el-form-item label="名字" :label-width="formLabelWidth">-->
        <!--            <el-input v-model="form.usernames" autocomplete="off"></el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-form>-->
        <!--        <div slot="footer" class="dialog-footer">-->
        <!--          <el-button @click="dialogFormVisibleEmail = false">取 消</el-button>-->
        <!--          <el-button type="primary" @click="sendEmail">发 送</el-button>-->
        <!--        </div>-->
        <!--      </el-dialog>-->
        <el-dialog
                title="拜访"
                :visible.sync="dialogFormVisible"
                width="40%"
                center
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col>
                        <el-form-item label="是否是异地" :label-width="formLabelWidth">
                            <el-radio-group v-model="form.isYidi">
                                <el-radio label="0">否</el-radio>
                                <el-radio label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="类型"
                                prop="type"
                                :label-width="formLabelWidth"
                        >
                            <f-select
                                    v-model="form.type"
                                    :dict="'customer_order_type'"
                                    :isNeed="form.type"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="客户名"
                                :label-width="formLabelWidth"
                                :rules="rules.contact"
                        >
                            <el-input
                                    v-model="form.username"
                                    autocomplete="off"
                                    disabled
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="拜访/沟通方式"
                                prop="visitType"
                                :label-width="formLabelWidth"
                        >
                            <f-select
                                    v-model="form.visitType"
                                    :isNeed="form.visitType"
                                    :dict="'visit_type'"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="陪同拜访人"
                                prop="visitor"
                                :label-width="formLabelWidth"
                        >
                            <f-select
                                    :multiple="true"
                                    v-model="form.visitorList"
                                    :isNeed="form.visitorList"
                                    :dict="'sys_user_id'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.type != 3">
                        <el-form-item
                                label="货量情况"
                                prop="volume"
                                :label-width="formLabelWidth"
                        >
                            <el-input
                                    type="textarea"
                                    v-model="form.volume"
                                    auto-complete="off"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.type != 3">
                        <el-form-item
                                label="发货周期"
                                prop="shippingCycle"
                                :rules="form.grade === '新客户' ? rules.shippingCycle : []"
                                :label-width="formLabelWidth"
                        >
                            <el-input
                                    v-model="form.shippingCycle"
                                    autocomplete="off"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="竞争对手"
                                prop="competitor"
                                :label-width="formLabelWidth"
                        >
                            <el-input v-model="form.competitor" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="痛点和需求"
                                prop="attentionPoint"
                                :label-width="formLabelWidth"
                        >
                            <el-input
                                    v-model="form.attentionPoint"
                                    autocomplete="off"
                                    type="textarea"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.type == '3'">
                        <el-form-item
                                label="目的国家地区"
                                label-width="110px"
                                prop="destAddr"
                        >
                            <el-input
                                    v-model="form.destAddr"
                                    type="textarea"
                                    maxlength="255"
                                    show-word-limit
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!--          业务类型-->
                    <el-col v-if="form.type == '3'">
                        <el-form-item label="业务类型" label-width="110px">
                            <el-select
                                    multiple
                                    filterabel
                                    clearable
                                    v-model="form.bussinessTypeList"
                                    @click.native="initBussinessTypeList"
                            >
                                <el-option
                                        v-for="(item, index) in bussinessTypeList"
                                        :key="index"
                                        :value="item.value"
                                        :label="item.text"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!--            <el-col >-->
                    <!--              <el-form-item prop="isNormalShipping" label="是否正常发货" :label-width="formLabelWidth">-->
                    <!--                <el-radio-group v-model="form.isNormalShipping">-->
                    <!--                  <el-radio :label='"1"'>是</el-radio>-->
                    <!--                  <el-radio :label='"0"'>否</el-radio>-->
                    <!--                </el-radio-group>-->
                    <!--              </el-form-item>-->
                    <!--            </el-col>-->
                    <el-col>
                        <el-form-item
                                label="沟通事项"
                                prop="communicate"
                                :label-width="formLabelWidth"
                        >
                            <el-input
                                    v-model="form.communicate"
                                    autocomplete="off"
                                    type="textarea"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="沟通结果"
                                prop="communicationResult"
                                :label-width="formLabelWidth"
                        >
                            <el-input
                                    v-model="form.communicationResult"
                                    autocomplete="off"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="工作/收款计划"
                                prop="workPlan"
                                :label-width="formLabelWidth"
                        >
                            <el-input v-model="form.workPlan" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item prop="url" label="附件" :label-width="formLabelWidth">
                            <f-upload
                                    v-model="form.url"
                                    :baifang="form"
                                    :dir="'customer'"
                            ></f-upload>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="货物属性"
                                v-if="form.type === '3'"
                                :label-width="formLabelWidth"
                        >
                            <el-checkbox-group v-model="form.goodsPropList">
                                <el-checkbox label="0">普货</el-checkbox>
                                <el-checkbox label="1">带电</el-checkbox>
                                <el-checkbox label="2">纯电</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item
                                label="物流规格"
                                v-if="form.type === '3'"
                                :label-width="formLabelWidth"
                        >
                            <el-checkbox-group v-model="form.circuStageList">
                                <el-checkbox label="0">整柜</el-checkbox>
                                <el-checkbox label="1">拼箱</el-checkbox>
                                <el-checkbox label="2">电商物流双清到门</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="货量及发货频次" :label-width="formLabelWidth">
                            <el-input
                                    v-model="form.goodsHz"
                                    show-word-limit
                                    maxlength="500"
                                    type="textarea"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveVisit" :disabled="btnDisable"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
        <msg-dialog :visible.sync="msgVisible" :row="row"></msg-dialog>
        <assign-dialog
                :visible.sync="assignVisible"
                :ids="ids"
                :row="row"
                :title="assignTitle"
        ></assign-dialog>
        <set-doc-dialog
                :visible.sync="setDocVisible"
                :ids="ids"
                :row="row"
                :title="setDocTitle"
                :multiple="setDocMultiple"
        ></set-doc-dialog>
        <update-commission-dialog
                :visible.sync="dialogFormVisibleCommission"
                :ids="ids"
                :title="UpdateCommissionTitle"
        ></update-commission-dialog>
        <add-follower-dialog
                :visible.sync="visible"
                :ids="ids"
                :row="row"
                :title="followTitle"
        ></add-follower-dialog>
        <email-group-dialog
                :visible.sync="dialogFormVisibleEmail"
                :row="form"
                @ok="dialogFormVisible = false"
        ></email-group-dialog>

        <el-dialog
                title="自动录入费用"
                :visible="dialogVisible"
                width="30%"
                :before-close="handleClose"
        >
            <el-checkbox-group v-model="checkList" class="flex_center" :max="1">
                <el-checkbox :label="1" border>自动录入费用</el-checkbox>
                <el-checkbox :label="0" border>不自动录入</el-checkbox>
            </el-checkbox-group>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="recordAutoFeeSubmit"
          >确 定</el-button
          >
        </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins.js";
    import MsgDialog from "./modules/MsgDialog";
    import AssignDialog from "@/views/customer/modules/AssignDialog";
    import AddFollowerDialog from "./modules/AddFollowerDialog";
    import EmailGroupDialog from "./modules/EmailGroupDialog";
    import {setUpRouteUtil} from "@/utils/util";
    import SetDocDialog from "./modules/SetDocDialog";
    import UpdateCommissionDialog from "./modules/UpdateCommissionDialog";
    import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";

    export default {
        name: "Normal",
        mixins: [QueryMixins],
        components: {
            UpdateCommissionDialog,
            MsgDialog,
            AssignDialog,
            AddFollowerDialog,
            EmailGroupDialog,
            SetDocDialog,
        },
        data() {
            return {
                setDocVisible: false,
                setDocMultiple: false,
                setDocTitle: "",
                UpdateCommissionTitle: "",
                bussinessTypeList: [],
                dictCodes: [
                    "customer_type2",
                    "cus_intent",
                    "cus_source",
                    "commission",
                    "sys_user_id",
                ],
                ids: [],
                row: {},
                visible: false,
                assignVisible: false,
                msgVisible: false,
                emailVisible: false,
                btnDisabled: false,
                formLabelWidth: "120px",
                rules: {
                    type: [{required: true, message: "请选择类型", trigger: "change"}],
                    visitType: [
                        {required: true, message: "请选择拜访沟通方式", trigger: "change"},
                    ],
                    // visitor: [
                    //   { required: true, message: '请选择业务人员', trigger: 'change' },
                    // ],
                    shippingCycle: [
                        {required: true, message: "请输入发货周期", trigger: "blur"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    communicationResult: [
                        {required: true, message: "请输入沟通结果", trigger: "blur"},
                        {max: 255, message: "长度不能超过255", trigger: "change"},
                    ],
                    attentionPoint: [
                        {required: false, message: "请输入关心的点", trigger: "blur"},
                        {max: 255, message: "长度不能超过255", trigger: "change"},
                    ],
                    workPlan: [
                        {required: true, message: "请输入工作/收款计划", trigger: "blur"},
                    ],
                },
                caozuolist: [
                    {value: "0", label: "上门拜访"},
                    {value: "1", label: "扫楼"},
                    {value: "2", label: "电话"},
                    {value: "3", label: "微信"},
                    {value: "4", label: "QQ"},
                ],
                dialogFormVisibleEmail: false,
                dialogFormVisibleCommission: false,
                followTitle: "",
                assignTitle: "",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },

                    {
                        prop: "username",
                        label: "客户名称",
                        sortable: "custom",
                    },
                    // {
                    //     prop: 'hasSendEmail',
                    //     label: '是否属于流失客户',
                    //     headerType:'remark',
                    //     detail:'超过六个月未下单的客户',
                    // },
                    // {
                    //     prop: 'isPush',
                    //     label: '是否属于推广注册',
                    //     headerType:'remark',
                    //     detail:'通过推广注册新增的客户',
                    // },
                    {
                        prop: "name",
                        label: "联系人",
                        sortable: "custom",
                    },
                    //   {
                    //       prop: "email",
                    //       label: "邮箱",
                    //
                    //   },
                    // {
                    //   prop: "phone",
                    //   label: "电话",
                    //
                    // },
                    {
                        prop: "grade",
                        label: "客户等级",
                        sortable: "custom",
                    },
                    {
                        prop: "source",
                        label: "客户来源",
                        sortable: "custom",
                    },
                    {
                        prop: "commission",
                        label: "提成性质",
                        sortable: "custom",
                    },
                    {
                        prop: "follow",
                        label: "跟进人",
                        sortable: "custom",
                    },
                    {
                        prop: "doc",
                        label: "客服",
                    },
                    {
                        prop: "billman",
                        label: "收款人",
                    },
                    {
                        prop: "salesman",
                        label: "业务员",
                        sortable: "custom",
                    },
                    {
                        prop: "payType",
                        label: "付款方式",
                    },
                    {
                        prop: "regType",
                        label: "注册性质",
                    },
                    {
                        prop: "createTime",
                        label: "注册时间",
                        sortable: "custom",
                        width: 160,
                    },
                    {
                        prop: "createBy",
                        label: "添加人",
                    },
                    {
                        prop: "allotTime",
                        label: "分配时间",
                        sortable: "custom",
                        width: 160,
                    },
                    {
                        prop: "passTimeStart",
                        label: "审核时间",
                    },
                    {
                        prop: "contractBegin",
                        label: "合同开始时间",
                    },
                    {
                        prop: "contractEnd",
                        label: "合同结束时间",
                    },
                    {
                        prop: "firstOrderCountry",
                        label: "第一次下单国家",
                    },
                    {
                        prop: "firstOrderTime",
                        label: "第一次下单时间",
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 145,
                    },
                ],
                url: {
                    resetOne: "/customer/resetOne",
                    resetOneBatch: "/customer/resetOneBatch",
                    list: "/customer/normalList",
                    exportXlsUrl: "/customer/exportZcXls",
                    deleteBatch: "/customer/deleteBatch",
                    approvalBatch: "",
                    getsalesmanlist: "/sys/user/salesman/list",
                    getuserlist: "/sys/user/list",
                    fronzenUrl: "/customer/forbidUserById/",
                    sendEmail: "/sys/supplier/sendEmail",
                    addVisit: "/customer/visitLog/add",
                    addVIPBatch: "/customer/addVIPBatch",
                    getBussinessTypeList: "/sys/dict/getDictItems/ocean_bussiness_type",
                    //    循环生成指定客户的卡券
                    generateCustomerCard: "/customer/coupon/generateCustomerCard",
                    batbatch: "/customer/tuihuiBatch",
                    xiazai: "/file/jasper/downloadCusAllBaoguanAndHBLDian",
                    recordAutoFee: "/customer/recordAutoFee",
                    checkCustomerHaveCooperation:'/customer/checkCustomerHaveCooperation',
                  cancelIsExcep:'/customer/cus/cancelIsExcep',
                  cancelIsExcep2:'/customer/cus/cancelIsExcep2',
                  cancelVIP:'/customer/cancelVIP '
                },
                salesmanlist: [],
                userlist: [],
                //注册性质 0公司 1个人
                reglist: [
                    {value: "0", label: "公司"},
                    {value: "1", label: "个人"},
                ],
                gradelist: [
                    {value: "0", label: "VIP客户"},
                    {value: "1", label: "重点客户"},
                    {value: "2", label: "一般客户"},
                    {value: "3", label: "潜在客户"},
                    {value: "4", label: "新客户"},
                ],

                form: {
                    goodsPropList: [],
                    circuStageList: [],
                },
                emailList: [],
                dialogFormVisible: false,
                btnDisable: false,
                nameList: [],
                isUsePost: true,
                dialogVisible: false,
                checkList: [],
                customerFind:true
            };
        },
        created() {
            this.queryForm.initUser = 1;
            this.queryForm.initSalesman = 1;
        },
        methods: {
            xiazai({id, username}) {
                downloadJasperExcelByMethodAndByUrlAndParamsAndFileName(
                    "GET",
                    this.url.xiazai,
                    {cusId: id},
                    username + "报关单+电放提形章.zip",
                    this
                );
            },
            loginClient(id) {
                let api = "http://120.25.172.133:9800";
                let devApi = "http://localhost:8088";
                let url = process.env.VUE_APP_API_URL;
                if (url === "http://8.135.121.105:9000") {
                    api = "https://do.do2do.com";
                    console.log("线上环境，跳转线上的客户端");
                } else if (url === "http://127.0.0.1:10000") {
                    api = devApi;
                }
                let loginId = JSON.parse(localStorage.getItem("USERINFO")).id;
                let params = "?cusId=" + id + "&userId=" + loginId;
                console.log("正常客户登录跳转链接", api + params);
                window.open(api + params);
            },
            initBussinessTypeList() {
                getAction(this.url.getBussinessTypeList).then((res) => {
                    this.bussinessTypeList = res.result;
                });
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                this.nameList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                    this.nameList.push(selection[i]["username"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                console.log("获取的邮箱列表", this.emailList);
            },
            saveVisit() {
                //保存拜访记录
                this.btnDisable = true;
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        if (this.form.goodsPropList) {
                            this.form.goodsProp = this.form.goodsPropList.join(",");
                        }
                        if (this.form.circuStageList) {
                            this.form.circuStage = this.form.circuStageList.join(",");
                        }
                        postAction(this.url.addVisit, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                } else {
                                    this.btnDisable = false;
                                }
                            })
                            .catch((err) => {
                                this.btnDisable = false;
                            });
                    } else {
                        this.btnDisable = false;
                    }
                });
            },

            //发送邮件
            sendEmail() {
                this.dialogFormVisibleEmail = true;
                //给邮箱赋值
                //使用$set
                this.$set(this.form, "receiver", this.emailList.join(";"));
                // this.form.emails = this.emailList.join(',');
                console.log("发送邮件", this.form);
            },
          handleBatCancelIsExcep() {
            this.$confirm(`确定解除${this.nameList.join(",")}拦截订舱？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            }).then(() => {
              postAction(
                  this.url.cancelIsExcep,this.selectedRowKeys
              ).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleSearch();
              });
            });
          },
          handleBatCancelIsExcep2() {
            this.$confirm(`确定${this.nameList.join(",")}拦截订舱？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            }).then(() => {
              postAction(
                  this.url.cancelIsExcep2,this.selectedRowKeys
              ).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleSearch();
              });
            });
          },
            handleBatAddVIP() {
                this.$confirm(`确定设置${this.nameList.join(",")}为VIP客户？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(
                        this.url.addVIPBatch + "?ids=" + this.selectedRowKeys.join(",")
                    ).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
            },

            handleEdit({id}) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, "/customer/Edit");
                this.$router.push(`/customer/Edit?id=${id}`);
            },
            handleBatTransfer() {
                this.ids = this.selectedRowKeys;
                this.assignTitle = "批量转让";
                this.assignVisible = true;
            },
            handleBatSetDoc() {
                this.ids = this.selectedRowKeys;
                this.setDocTitle = "批量设置客服";
                this.setDocMultiple = true;
                this.setDocVisible = true;
            },
            handleBatBack() {
                console.log("批量操作对象：", this.selectedRowKeys);
                let isArrears = false, ArrearsUserName = [];
                this.selectionRows.forEach(item => {
                    if(item.haveDebt == '1') {
                        isArrears = true;
                        ArrearsUserName.push(item.username);
                    }
                });
                if(isArrears) {
                    this.$message.warning( `【${ArrearsUserName.join(",")}】存在欠款的情况，无法退回公海`);
                    return;
                }
                var cname = this.selectedRowKeys;
                this.$confirm(`确定将【${this.nameList.join(",")}】退回到公海?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(
                            this.url.batbatch + "?ids=" + this.selectedRowKeys.join(","),
                            {ids: this.selectedRowKeys.join(",")}
                        ).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleBatAddFollower() {
                this.ids = this.selectedRowKeys;
                this.followTitle = "批量添加跟进人";
                this.visible = true;
            },
            handleBatDel() {
                this.$confirm(`确定删除${this.nameList.join(",")}？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteBatch, {
                        ids: this.selectedRowKeys.join(","),
                    }).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
            },
            //给客户生成卡券
            handleBatGenerateCard() {
                let flag = true;
                let ss = this.selectionRows
                    .filter((s) => {
                        if (s.parentId && s.parentId.length > 0) {
                            return true;
                        }
                        return false;
                    })
                    .map((r) => r.username)
                    .join(",");
                if (ss && ss.length > 0) {
                    this.$message.warning("以下客户是子账户，不允许生成卡券：" + ss);
                    flag = false;
                    return false;
                }
                if (flag) {
                    this.$confirm(`确定生成卡券吗？`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "error",
                        closeOnClickModal: false,
                    }).then(() => {
                        postAction(this.url.generateCustomerCard, {
                            cusIdList: this.selectedRowKeys,
                        }).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.success
                                    ? res.message + "请在系统管理-卡券管理查看"
                                    : res.message,
                            });
                            this.handleSearch();
                        });
                    });
                }
            },
            handleSendEmail() {
                this.dialogFormVisibleEmail = true;
                //给邮箱赋值
                //使用$set
                this.$set(this.form, "receiver", this.emailList.join(";"));
                // this.form.emails = this.emailList.join(',');
                console.log("发送邮件", this.form);
            },
            // 批量修改客户提成性质
            handleUpdateCustomerCommission() {
                this.ids = this.selectedRowKeys;
                this.UpdateCommissionTitle = "批量修改客户提成性质";
                this.dialogFormVisibleCommission = true;
            },
            handleSendMsg() {
                this.row = this.selectionRows[0];
                this.msgVisible = true;
            },
            handleResetPass(row) {
                this.$confirm(`确定将【${row.username}】重置密码?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(this.url.resetOne, row).then((res) => {
                            // this.$message({
                            //     type: 'success',
                            //     message: res.message,
                            //   duration:5000
                            // })
                            this.$alert(res.message, "重置密码结果", {
                                dangerouslyUseHTMLString: true,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleResetPassBatch() {
                this.$confirm(`确定重置密码?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(this.url.resetOneBatch, this.selectionRows).then((res) => {
                            // this.$message({
                            //     type: 'success',
                            //     message: res.message,
                            //   duration:5000
                            // })
                            this.$alert(res.message, "重置密码结果", {
                                dangerouslyUseHTMLString: true,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleFrozen(row) {
                this.$confirm(`确定将【${row.username}】冻结?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(this.url.fronzenUrl, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handlTransfer(row) {
                this.row = row;
                this.ids = [row.id];
                this.assignTitle = "转让";
                this.assignVisible = true;
            },
            handlSetDoc(row) {
                this.row = row;
                this.ids = [row.id];
                this.setDocTitle = "设置客服";
                this.setDocMultiple = false;
                this.setDocVisible = true;
            },
            handlAddRecord(row) {
                //给拜访记录赋值
                this.form = {circuStageList: [], goodsPropList: []};
                this.btnDisable = false;
                this.form.username = row.username;
                this.form.cusId = row.id;
                this.form.grade = row.grade;
                this.dialogFormVisible = true;
            },
            handlAddFollower(row) {
                this.row = row;
                this.ids = [row.id];
                this.followTitle = "添加跟进人";
                this.visible = true;
            },
            automaticCostEntry() {
                this.dialogVisible = true;
            },
            isExpensesBasicChange(row) {
                const params = {
                    ids: row.id,
                    isExpensesBasic: row.isExpensesBasic,
                };
                postAction(this.url.recordAutoFee, params)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
                    .catch((err) => {
                    });
            },
            handleClose() {
                this.dialogVisible = false;
                this.checkList = [];
            },
            recordAutoFeeSubmit() {
                const params = {
                    isExpensesBasic: this.checkList[0],
                    ids: this.selectedRowKeys.join(),
                };
                postAction(this.url.recordAutoFee, params)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleClose();
                            this.handleQuery();
                        }
                    })
                    .catch((err) => {
                    });
            },
            handleBatCancelVIPCustomer() {
            this.$confirm(`确定取消${this.nameList.join(",")}的VIP级别？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            }).then(() => {
              postAction(
                  this.url.cancelVIP,this.selectedRowKeys
              ).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleSearch();
              });
            });
          },
        },
      computed : {
        buttonShowList() {
          return {
            'backB':this.$btnIsShow('customer/Normal', '0', '批量退回'),
            'zrB':this.$btnIsShow('customer/Normal', '0', '批量转让'),
            'szkfB':this.$btnIsShow('customer/Normal', '0', '批量设置客服'),
            'szgjrB':this.$btnIsShow('customer/Normal', '0', '批量设置跟进人'),
            'rePB':this.$btnIsShow('customer/Normal', '0', '批量重置密码'),
            'delB':this.$btnIsShow('customer/Normal', '0', '批量删除'),
            'sckqB':this.$btnIsShow('customer/Normal', '0', '批量生成卡券'),
            'fsyj':this.$btnIsShow('customer/Normal', '0', '发送邮件'),
            'xgkhxzB':this.$btnIsShow('customer/Normal', '0', '批量修改客户提成性质'),
            'fsdx':this.$btnIsShow('customer/Normal', '0', '发送短信'),
            'dc':this.$btnIsShow('customer/Normal', '0', '导出'),
            'setV':this.$btnIsShow('customer/Normal', '0', '设置VIP客户'),
            'zdlrfy':this.$btnIsShow('customer/Normal', '0', '自动录入费用'),
            'jclzdcB':this.$btnIsShow('customer/Normal', '0', '批量解除拦截订舱'),
            'szlzdcB':this.$btnIsShow('customer/Normal', '0', '批量设置拦截订舱'),
            'qxVB':this.$btnIsShow('customer/Normal', '0', '取消VIP客户'),
            'edit':this.$btnIsShow('customer/Normal', '1', '编辑'),
            'dj':this.$btnIsShow('customer/Normal', '1', '冻结'),
            'szkf':this.$btnIsShow('customer/Normal', '1', '设置客服'),
            'zr':this.$btnIsShow('customer/Normal', '1', '转让'),
            'tjbf':this.$btnIsShow('customer/Normal', '1', '添加拜访'),
            'szgjr':this.$btnIsShow('customer/Normal', '1', '设置跟进人'),
            'reP':this.$btnIsShow('customer/Normal', '1', '重置密码'),
            'loginC':this.$btnIsShow('customer/Normal', '1', '客户端登录'),
            'fyzdlr':this.$btnIsShow('customer/Normal', '1', '费用自动录入'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
