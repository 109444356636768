<template>
  <!-- 新增编辑活动配置弹窗 -->
  <div>
    <el-dialog :title="editType + '配置'" :visible.sync="visible" :close-on-click-modal="false"
               :destroy-on-close="destroyOnClose" :before-close="handleClose" append-to-body>
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="height: 100%;">
        <el-card class="box-card">
          <div slot="header">
            <span>配置信息</span>
          </div>
          <el-row :gutter="20" class="inputs_row">
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="ruleName" label="名称">
                <el-input v-model="form.ruleName" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="isEnable" label="是否启用">
                <f-select v-model="form.isEnable" :is-need="form.isEnable" :dict="'unit_whether'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">

              <el-form-item prop="useLevel" label-width="60px" label="级别"
                            :rules="rules.useLevel">
                <el-input
                    maxlength="4" show-word-limit type="number" v-model="form.useLevel"
                    placeholder="1 最低 9999 最高"
                    onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                >
                </el-input>

              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="startTime" label="有效开始">
                <el-date-picker v-model="form.efeTimeStart" type="datetime" size="small" placeholder="请选择时间"
                                default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="endTime" label="有效结束">
                <el-date-picker v-model="form.efeTimeEnd" type="datetime" size="small" placeholder="请选择时间"
                                default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card">
          <div slot="header">
            <span>规则详情</span>
            <el-button type="primary" class="f-m-l-10" @click="handleAdd">添加一行</el-button>

          </div>
          <f-table :columns="columns" :data="form.fieldRuleList">
            <template #default="scope">
              <template v-if="scope.column.property === 'action'">
                <el-button type="text" @click="handleDelete(scope.row, scope.$index)" icon="el-icon-delete"></el-button>

              </template>
              <template v-else-if="scope.column.property === 'dictId'">
                <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictId'" label-width="0px"
                              :rules="rules.dictId">
                  <el-select v-model="scope.row.dictId" placeholder="请选择" :loading="loadingRule" clearable filterable
                             @change="(val) => { getOptionList(val, scope.row, scope.$index) }"
                  >
                    <el-option v-for="item in salesPerConfigDictBaseVOList" :key="item.dictId" :label="item.dictName"
                               :value="item.dictId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'operatorSymbol'">
                <el-form-item :prop="'fieldRuleList.' + scope.$index + '.operatorSymbol'" label-width="0px"
                              :rules="rules.operatorSymbol">
                  <f-select :dataList="scope.row.operatorSymbolList" v-model="scope.row.operatorSymbol"
                            :isNeed="scope.row.operatorSymbol"
                            @input="(val) => { getOptionMultiple(val, scope.row, scope.$index) }">
                  </f-select>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'dictValue'">
                <!-- 包含不包含多选 -->
                <template
                    v-if="((scope.row.operatorSymbol === 'in' || scope.row.operatorSymbol === 'not in') && scope.row.ruleType !== '6' && scope.row.show)">
                  <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictValueList'" label-width="0px"
                                :rules="rules.dictValue">
                    <f-select
                        v-model="scope.row.dictValueList" :data-list="scope.row.dictModelList"
                        :is-need="scope.row.dictValueList" :multiple="true" placeholder="请选择"
                        @click.native="handleOption(scope.row, scope.$index)">

                    </f-select>
                  </el-form-item>
                </template>
                <!-- 其他单选 -->
                <template v-else-if="scope.row.show && ['1','2'].indexOf(scope.row.ruleType) !== -1">
                  <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictValue'" label-width="0px"
                                :rules="rules.dictValue">
                    <f-select
                        :data-list="scope.row.dictModelList"
                        v-model="scope.row.dictValue" :is-need="scope.row.dictValue" placeholder="请选择"
                        @click.native="handleOption(scope.row, scope.$index)">

                    </f-select>
                  </el-form-item>
                </template>
                <!--                手动输入 字符串类型-->
                <template v-else-if="scope.row.show && scope.row.ruleType === '3'">
                  <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictValue'" label-width="0px"
                                :rules="rules.dictValue">
                    <el-input v-model="scope.row.dictValue" maxlength="50" show-word-limit>

                    </el-input>

                  </el-form-item>
                </template>

                <!--                手动输入 整数类型-->
                <template v-else-if="scope.row.show && scope.row.ruleType === '4'">
                  <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictValueInt'" label-width="0px"
                                :rules="rules.dictValue">
                    <el-input
                        maxlength="5" show-word-limit type="number" v-model="scope.row.dictValueInt"
                        onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    ></el-input>

                  </el-form-item>
                </template>
                <!--                手动输入 数字类型-->
                <template v-else-if="scope.row.show && scope.row.ruleType === '5'">
                  <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictValueDouble'" label-width="0px"
                                :rules="rules.shuzi">
                    <el-input
                        v-model="scope.row.dictValueDouble" maxlength="5" show-word-limit type="number"
                    ></el-input>
                  </el-form-item>
                </template>

                <!--                手动输入 订单集合类型-->
                <template v-else-if="scope.row.show && scope.row.ruleType === '6'">
                  <el-form-item :prop="'fieldRuleList.' + scope.$index + '.dictValue'" label-width="0px"
                                :rules="rules.ouhao">
                    <el-input
                        v-model="scope.row.dictValue" maxlength="150" show-word-limit type="textarea"
                    ></el-input>
                  </el-form-item>
                </template>
            </template>
              <template v-else-if="scope.column.property === 'combineSymbol'">
                <el-form-item :prop="'fieldRuleList.' + scope.$index + '.combineSymbol'" label-width="0px"
                              :rules="rules.combineSymbol">
                  <f-select
                      :data-list="combineDictModelList"
                      v-model="scope.row.combineSymbol" :is-need="scope.row.combineSymbol" placeholder="请选择"
                      @click.native="handleOption(scope.row, scope.$index)">

                  </f-select>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'useLevel'">
                <el-form-item :prop="'fieldRuleList.' + scope.$index + '.useLevel'" label-width="0px"
                              :rules="rules.useLevel">
                  <el-input
                      maxlength="4" show-word-limit type="number" v-model="scope.row.useLevel"
                      placeholder="1 最低 9999 最高"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  ></el-input>

                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'isEnable'">
                <el-form-item :prop="'fieldRuleList.' + scope.$index + '.isEnable'" label-width="0px"
                              :rules="rules.isEnable">
                  <f-select
                      v-model="scope.row.isEnable"
                      :dict="'unit_whether'"
                      :is-need="scope.row.isEnable"
                  ></f-select>

                </el-form-item>
              </template>
            </template>
          </f-table>
        </el-card>
        <el-card class="box-card">
          <div slot="header">
            <span>规则匹配结果</span>
          </div>
          <el-row :gutter="20" class="inputs_row">
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="commissionRate" label="提成比例" :rules="rules.commissionRate">
                <el-input v-model="form.commissionRate" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="personRespRatio"  :rules="rules.personRespRatio">
                <template slot="label">
                  <span>个人承担比例（%）</span>
                  <el-tooltip
                      class="item"
                      effect="dark"
                      content="该比例值*利润得到利润/亏本提成"
                      placement="top"
                  >

                    <i class="el-icon-question" style="color:#2c68ff"></i>
                  </el-tooltip>
                </template>
                <el-input v-model="form.personRespRatio" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="subsidy" label="补助" :rules="rules.subsidy">
                <el-input v-model="form.subsidy" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="flex_form_item" prop="lastRatio" label="最终提成比例（%）" :rules="rules.lastRatio">
                <template slot="label">
                  <span>最终提成比例（%）</span>
                  <el-tooltip
                      class="item"
                      effect="dark"
                      content="该比例值将会在利润率大于30%使用"
                      placement="top"
                  >

                    <i class="el-icon-question" style="color:#2c68ff"></i>
                  </el-tooltip>
                </template>
                <el-input v-model="form.lastRatio" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>

      <template #footer>
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="handleSubmit">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {postAction} from '@/api/manage';
import {getAction} from '@/api/manage';
import {validateUnitValue4 , validateOuhao} from "../../../utils/validator";

export default {
  components: {},
  props: {
    row: {
      type: Object,
      default: () => {
      }
    },
    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },

    selectForm: {
      type: Object,
      default: () => {
      }
    },
    selectBaseVo: {
      type: Object,
      default: () => {
      }
    },
    editType:{
      type : String,
    }
  },
  data() {
    return {
      orderNo: [],
      generalOrderFlag: false,
      generalOrderList: [],
      queryForm: {},
      rules: {
        ruleName: [
          {required: true, message: '请输入名称', trigger: "change"},
        ],
        isEnable: [
          {required: true, message: '请选择是否启用', trigger: "change"},
        ],
        dictId: [
          {required: true, message: '请选择规则名', trigger: "change"},
        ],
        operatorSymbol: [
          {required: true, message: '请选择运算符', trigger: "change"},
        ],
        dictValue: [
          {required: true, message: '请选择选项', trigger: "change"},
        ],
        shuzi: [
          {required: true, message: '请选择选项', trigger: "change"},
          {validator: validateUnitValue4, trigger: 'change'}
        ],
        ouhao: [
          {required: true, message: '请选择选项', trigger: "change"},
          {validator: validateOuhao, trigger: 'change'}
        ],
        combineSymbol: [
          {required: true, message: '请选择连接符', trigger: "change"},
        ],
        useLevel: [
          {required: true, message: '请输入级别', trigger: "change"},
        ],
        commissionRate: [
          {required: true, message: '请输入提成比例', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        personRespRatio: [
          {required: true, message: '请输入个人承担比例', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        subsidy: [
          {required: true, message: '请输入补助', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
        lastRatio: [
          {required: true, message: '请输入最终比例', trigger: "change"},
          {validator: validateUnitValue4, trigger: "change"},
        ],
      },
      form: {
        baseInfo: {},
        fieldRuleList: []
      },
      salesPerConfigDictBaseVOMap: {},
      salesPerConfigDictBaseVOList: [],
      operatorDictModelList: [],
      combineDictModelList: [],
      defaultOperatorSymbolList:[
        { value : 'in' , text : '在集合中' },
        { value : 'not in' , text : '不在集合中' },
      ],
      url: {

        save: '/shipping/sales/per/config/save',
        getBaseInfo: '/shipping/sales/per/config/getBaseModel',
      },

      columns: [
        {
          prop: "dictId",
          label: '规则名称',
          headerType: 'require',
          width : 100
        },
        {
          prop: "operatorSymbol",
          label: '运算符',
          headerType: 'require',
          width : 100
        },
        {
          prop: "dictValue",
          label: '选项',
          headerType: 'require',
        },
        {
          prop: "combineSymbol",
          label: '连接符',
          headerType: 'require',
          width : 100
        },
        {
          prop: "useLevel",
          label: '等级',
          headerType: 'require',
          width : 120
        },
        {
          prop: "isEnable",
          label: '启用',
          headerType: 'require',
          width : 60
        },
        {
          prop: "action",
          label: '操作',
          width:30
        },
      ],
      ruleNameList: [], //规则名列表
      optionList: [], //选项列表

      loadingRule: false,

      tips: '',
    }
  },
  watch: {
    visible(val) {
      if (this.selectForm && this.selectForm.id) {
        this.form = JSON.parse(JSON.stringify(this.selectForm));
        this.buildBaseData(this.selectBaseVo);
        this.buildFieldRuleList();
      } else {
        this.form = {fieldRuleList: []};
        getAction(this.url.getBaseInfo)
            .then(res => {
              this.buildBaseData(res.result);
            })
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    buildFieldRuleList(){
      this.form.fieldRuleList.forEach((item, index) => {
        this.$set(this.form.fieldRuleList[index], 'show', true);
        this.getOptionList(item.dictId, item, index);
      })
      console.log(this.form.fieldRuleList, 'this.form.fieldRuleList')
    },
    buildBaseData(baseVo) {
      this.salesPerConfigDictBaseVOList = baseVo.salesPerConfigDictBaseVOList || [];
      this.salesPerConfigDictBaseVOMap = this.listToMap(this.salesPerConfigDictBaseVOList, 'dictId');
      this.operatorDictModelList = baseVo.operatorDictModelList;
      this.combineDictModelList = baseVo.combineDictModelList;
    },
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleRuleName() {
      if (!this.form.type) {
        this.$message.warning('请先选择活动类型');
      }
    },
    handleOption(row, index) {
      if (!row.dictId) {
        this.$message.warning('请先选择规则名');
      }
    },
    // 获取规则列表
    async getRuleNameList() {
      this.ruleNameList = [];
      this.loadingRule = true;
      await getAction(this.url.getOptionByType, {type: this.form.type}).then(res => {
        this.loadingRule = false;
        this.ruleNameList = res.result;
      }).catch(err => {
        this.loadingRule = false;
      })
    },
    // 获取规则列表
    getOptionList(val, row, index) {

      let obj = this.salesPerConfigDictBaseVOMap[val];
      console.log(val, 'val');
      console.log(obj, '选中的规则对象');
      this.$set(this.form.fieldRuleList[index], 'dictName', obj.dictName);
      this.$set(this.form.fieldRuleList[index], 'ruleType', obj.ruleType);
      this.$set(this.form.fieldRuleList[index], 'ruleCode', obj.ruleCode);
      this.$set(this.form.fieldRuleList[index], 'dictModelList', obj.sysDictModelList);
      //这个可选的选项集合为
      let operatorList = this.operatorDictModelList.filter(item => item['ruleTypes'].indexOf(obj.ruleType) !== -1);
      if (operatorList.length > 0) {
        this.$set(this.form.fieldRuleList[index], 'operatorSymbolList', operatorList);
      }else {
        this.$set(this.form.fieldRuleList[index], 'operatorSymbolList', this.defaultOperatorSymbolList);
      }

    },
    // 获取选项是否多选
    getOptionMultiple(val, row, index) {
      this.$set(this.form.fieldRuleList[index], 'operatorSymbol', val);
      if (val === 'in' || val === 'not in') {
        this.$set(this.form.fieldRuleList[index], 'dictValue', '');
        this.$set(this.form.fieldRuleList[index], 'dictValueInt', '');
        this.$set(this.form.fieldRuleList[index], 'dictValueDouble', '');
      } else {
        this.$set(this.form.fieldRuleList[index], 'dictValueList', []);
      }
      this.$set(this.form.fieldRuleList[index], 'show', false);
      console.log("不显示选项")
      setTimeout(() => {
        this.$set(this.form.fieldRuleList[index], 'show', true);
        console.log("显示选项")
      });
    },
    handleAdd() {
      this.form.fieldRuleList.push({dictValueList: [], show: true});
    },
    handleDelete(row, index) {
      if (this.form.fieldRuleList.length <= 1) {
        this.$message.warning('列表数据至少保留一项');
        return
      }
      this.form.fieldRuleList.splice(index, 1);
    },

    // 获取基础信息模板

    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!this.form.fieldRuleList || this.form.fieldRuleList.length === 0) {
          this.$message.warning('规则列表不能为空');
          return false;
        }
        if (valid) {
          console.log(this.form)
          let params = JSON.parse(JSON.stringify(this.form));
          // params.fieldRuleList.forEach((item, index) => {
          //   delete item.optionList;
          //   delete item.show;
          // })
          this.$store.commit("setFullscreenLoading", true);
          // 复制的时候清空ID
          if (this.editType === '复制') {
            delete params.id;
          }
          postAction(this.url.save, params).then((res) => {
            this.$store.commit("setFullscreenLoading", false);
            this.$message.success(res.message);
            this.handleClose();
            this.$emit("ok");
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          });

        }
      });
    },
    handleClose() {
      this.form = {
        fieldRuleList: []
      };
      this.$emit("update:visible", false);
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
