<template>
    <el-dialog
        center
        width="60%"
        :close-on-click-modal="false"
        :visible.sync="visible"
        :title="title"
        :before-close="handleClose"
        top="50px"
        >
        <div class="content-wrap c-slim-scrollbar addEditBox">
            <menu-form ref="form" :data="data" :row="row" @ok="handleOk"></menu-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button plain size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { postAction } from '@/api/manage'
import MenuForm from './MenuForm'
export default {
    name: 'AddMenuDrawer',
    components: {
        MenuForm
    },
    props: {
        visible: {
            type: Boolean
        },
        data: {
            type: Array,
            default: () => []
        },
        row: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            title: '新增菜单',
            url: {
                add: '/sys/permission/add',
                addButtonWithMenu: '/sys/button/auth/addButtonWithMenu',
            }
        }
    },
    methods: {
        handleOk(form) {
            if (form.menuType !=2){
                postAction(this.url.add, form).then(res => {
                    this.handleClose()
                    this.$bus.$emit('isys-menu-data-refresh')
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                })
            }else {
                postAction(this.url.addButtonWithMenu, form).then(res => {
                    this.handleClose()
                    this.$bus.$emit('isys-menu-data-refresh')
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                })
            }

        },
        handleSubmit() {
            this.$refs.form.handleSubmit()
        },
        handleClose() {
            this.$refs.form.handleClear()
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.addEditBox {
  height: 725px;
  overflow: scroll;
}
</style>
