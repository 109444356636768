<template>
    <el-card class="f-flex-col" shadow="never" :body-style="{flex: 1}">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <el-dropdown trigger="click" placement="bottom-start" @command="handleCommand">
                <span class="el-dropdown-link">
                    <el-button class="c-theme-button" size="medium" type="primary"

                               v-if="buttonShowList.add"
                    >添加部门<i class="el-icon-arrow-down"

                    ></i></el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="TOP">添加公司</el-dropdown-item>
                    <el-dropdown-item :disabled="currentNode === null" command="SUB">添加部门</el-dropdown-item>
                    <el-dropdown-item :disabled="currentNode === null" command="JOB">添加职位</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button class="c-theme-button f-m-l-10" size="medium" type="primary" @click="handleCollapsed">全部{{flag ? '展开' : '折叠'}}</el-button>
<!--            <el-button class="c-theme-button f-m-l-10" size="medium" type="primary" @click="handleAuth" :disabled="!currentNode">授权</el-button>-->
            <el-button
                class="c-theme-error-button"
                size="medium"
                icon="el-icon-delete"
                type="primary"

                :disabled="!checkedKeys.length"
                v-if="buttonShowList.plsc"
                @click="handleBatchDel">批量删除</el-button>
        </div>
        </el-card>

        <el-card class="box-card last_card">
            <div class="f-full-height f-flex-col">
            <el-input v-model="filterText" suffix-icon="el-icon-search" size="medium" placeholder="输入部门名称搜索"></el-input>
            <div class="tree-wrap f-overflow-auto f-flex-1">
                <el-tree
                    show-checkbox
                    ref="tree"
                    node-key="id"
                    :data="data"
                    :props="defaultProps"
                    :check-strictly="true"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    :filter-node-method="filterNode"
                    @current-change="handleCurrentChange"
                    @check="handleCheck">
                     <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span>
            <el-button
                    type="text"
                    size="mini"
                    @click="() => getWarehouseListByDepartId(data.id,data.deliveryPointId)"

            >绑定仓库</el-button>
<!--          <el-button-->
<!--                  type="text"-->
<!--                  size="mini"-->
<!--                  @click="() => rel(node,data)"-->

<!--          >关联</el-button>-->
<!--          <el-button-->
<!--                  type="text"-->
<!--                  size="mini"-->
<!--                  @click="() => relReverse(node, data)"-->

<!--          >反向关联</el-button>-->
        </span>
      </span>
                </el-tree>
            </div>
        </div>
        </el-card>
        <el-dialog :title="'关联部门'" :visible.sync="relVis" v-if="relVis" append-to-body
                   :close-on-click-modal="false">
            <el-form :model="re" ref="re" :rules="rules">
<!--                <el-form-item prop="selectIds" label="部门">-->
<!--                    <el-select clearable filterable multiple v-model="re.selectIds">-->
<!--                        <el-option v-for="(item,index) in depList" :key="index" :label="item.text"-->
<!--                                   :value="item.value"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item prop="ids" label="需要关联的部门">
                    <el-select clearable filterable multiple v-model="re.ids">
                        <el-option v-for="(item,index) in depList" :key="index" :label="item.text"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="relVis = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveRelation()" :loading="bbLoading">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="'绑定仓库'" :visible.sync="bangdingVis" v-if="bangdingVis" append-to-body
                   :close-on-click-modal="false">
            <el-form :model="bangding" ref="bangding" :rules="rules">
                <el-form-item prop="deliveryPointId" label="交货仓库">
                    <el-select filterable clearable v-model="bangding.deliveryPointId">
                        <el-option v-for="(i,d) in warehouseList" :key="d" :value="i.id" :label="i.content"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="bangdingVis = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="updateBingWarehouse()">确 定</el-button>
            </div>
        </el-dialog>
        <add-department-drawer :visible.sync="visible" :row="row" :data="data" :titleStr="titleStr"></add-department-drawer>
        <edit-tree-dialog :visible.sync="authVisible" title="授权" saveIdField="departId" :id="currentNode && currentNode.id || ''" :url="authUrl"></edit-tree-dialog>
    </el-card>
</template>

<script>
import { deleteAction,getAction, postAction } from '@/api/manage'
import AddDepartmentDrawer from './AddDepartmentDrawer'
import EditTreeDialog from './EditTreeDialog'
export default {
    name: 'DepartmentTreeModule',
    components: {
       EditTreeDialog,
       AddDepartmentDrawer
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },

    },
    data() {
        return {
            re:{},
            bangding:{},
            bbLoading:false,
            warehouseList:[],
            depList:[],
            titleStr:'',
            checkedKeys: [],
            row: {},
            visible: false,
            authVisible: false,
            flag: true,
            filterText: '',
            currentNode: null,
            relVis:false,
            bangdingVis:false,
            defaultProps: {
                children: 'children',
                label: 'titleWithWarehouse'
            },
            authUrl: {
                list: '/sys/permission/list',
                auth: '/sys/sysDepartPermission/queryById',
                ok: '/sys/permission/saveDepartPermission',
                getRelDepList:'/sys/dep/rel/getRelDepList',
                getDepIdList:'/sys/dep/rel/getDepIdList',
                addDepRel:'/sys/dep/rel/addDepRel',
                addDepRelReverse:'/sys/dep/rel/addDepRelReverse',
                addDelBatch:'/sys/dep/rel/addDelBatch',
                second_depart:'/sys/dict/getDictItems/sys_depart',

            },
            url: {
                delete: '/sys/sysDepart/deleteBatch',
                updateBingWarehouse:'/sys/sysDepart/updateBingWarehouse',
                getWarehouseList:'/sys/sysDepart/getWarehouseListWithDepartId',
            },
            rules:{
                ids:[
                    {required:true,message:'请选择部门',trigger:'change'}
                ],
                deliveryPointId:[
                    {required:true,message:'请选择仓库',trigger:'change'}
                ]
            },
        }
    },
    watch: {
        filterText(val) {
             // this.$refs.tree.filter(val);
              this.$refs.tree.filter(val);
        }
    },
    mounted() {
        this.$bus.$on('department-data-reset', () => {
            this.flag = true
            this.currentNode = null
            this.checkedKeys= []
        })
    },
    beforeDestroy() {
        this.$bus.$off('department-data-reset')
    },
    methods: {
        getWarehouseListByDepartId(id,deliveryPointId){
            getAction(this.url.getWarehouseList,{id:id})
                .then(res=>{
                    this.warehouseList = res.result;
                    this.bangdingVis = true;
                    this.$set(this.bangding,'id',id);
                    this.$set(this.bangding,'deliveryPointId',deliveryPointId);
                })
        },
        updateBingWarehouse(){
            this.$refs['bangding'].validate(valid=>{
                if (valid) {
                    postAction(`${this.url.updateBingWarehouse}?id=${this.bangding.id}&deliveryPointId=${this.bangding.deliveryPointId}`)
                        .then(res=>{
                            this.$message.success(res.message);
                            this.bangdingVis = false;
                            this.bangding = {};
                            this.$emit('ok');
                        })
                }
            })
        },
        // bangding(node,data){
        //
        // },
        rel(node,data){
            this.$set(this.re,'type','re');
            this.$set(this.re,'depId',data.id);
            this.initSecondDep();
            getAction(this.authUrl.getRelDepList,{depId:data.id})
            .then(res=>{
                this.$set(this.re,'ids',res.result);
                this.relVis = true;
            })
            // console.log('关联的参数，node,data',node,data);
        },
        relReverse(node,data){
            this.$set(this.re,'type','reverse');
            this.$set(this.re,'relationDepId',data.id);
            this.initSecondDep();
            // console.log('反向关联的参数，node,data',node,data);
            getAction(this.authUrl.getDepIdList,{relation_dep_id:data.id})
                .then(res=>{
                    this.$set(this.re,'ids',res.result);
                    this.relVis = true;
                })
        },
        saveRelation(){
            // postAction(this.authUrl.addDelBatch,this.re).then(res=>{
            //     this.$message.success(res.message);
            //     this.relVis = false;
            //     this.bbLoading = false;
            // }).catch(err=>{
            //     this.bbLoading = false;
            // })
            this.bbLoading = true;
            this.$refs['re'].validate(valid=>{
                if (valid){
                    let url = this.authUrl.addDepRel;
                    if (this.re.type === 'reverse') {
                        url = this.authUrl.addDepRelReverse;
                    }
                    postAction(url,this.re).then(res=>{
                        this.$message.success(res.message);
                        this.relVis = false;
                        this.bbLoading = false;
                    }).catch(err=>{
                        this.bbLoading = false;
                    })
                }
            })
        },
        async initSecondDep(){
            await getAction(this.authUrl.second_depart)
            .then(res=>{
                this.depList = res.result;
            })
        },
//     treeFilter(nodes, query) {
//         console.log(nodes)
//         // 条件就是节点的title过滤关键字
//         let predicate = function (node) {
//             if (node.departName.indexOf(query) > -1) {
//                 return true;
//             } else {
//                 return false;
//             }
//         };
//         if (!(nodes && nodes.length)) {
//             return [];
//         }
//         let newChildren = [];
//         for (let node of nodes) {
//             // 以下两个条件任何一个成立，当前节点都应该加入到新子节点集中
//             // 1. 子孙节点中存在符合条件的，即 subs 数组中有值
//             // 2. 自己本身符合条件
//             // let subs = this.filter(node.children, query);
//             // if (predicate(node)) {
//             //   newChildren.push(node);
//             // } else if (subs && subs.length) {
//             //   node.children = subs;
//             //   newChildren.push(node);
//             // }
//
//             // 以下只需要考虑自身的节点满足条件即可,不用带上父节点
//             if (predicate(node)) {
//                 newChildren.push(node);
//                 node.children = this.treeFilter(node.children, query);
//             } else {
//                 newChildren.push(...this.treeFilter(node.children, query));
//             }
//         }
//         return newChildren.length ? newChildren : [];
// },
        handleCommand(cmd) {
            if(cmd === 'TOP') {
                this.row = {
                    orgType: '1',

                }
                this.titleStr='新增企业'
            } else if(cmd === 'SUB') {
                this.row = {
                    orgType: '2',
                    parentId: this.currentNode.id
                }
                this.titleStr='新增部门'
            } else if(cmd === 'JOB') {
                this.row = {
                    orgType: '3',
                    parentId: this.currentNode.id
                }
                this.titleStr='新增职位'
            }
            this.visible = true
        },
        handleAuth() {
            this.authVisible = true
        },
        handleBatchDel() {
            let msg;let job = 0;let sub = 0;let com = 0;
            for (var i=0;i < this.$refs.tree.getCheckedNodes().length;i++){
                if (this.$refs.tree.getCheckedNodes()[i].orgType === '3'){job = job +1}
                if (this.$refs.tree.getCheckedNodes()[i].orgType === '2'){sub = sub +1}
                if (this.$refs.tree.getCheckedNodes()[i].orgType === '1'){
                    com = com +1;
                    break;
                }
            }
            if (com !== 0){
                msg = '确定删除选中的企业以及相应的子部门?'
            }else if (sub !== 0){
                msg = '确定删除选择的部门?'
            }else {
                msg = '确定删除选择的职位?'
            }


            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                closeOnClickModal: false,
            }).then(() => {
                const params = { ids: this.$refs.tree.getCheckedKeys().join(',')}
                deleteAction(this.url.delete, params).then(res => {
                    this.$bus.$emit('department-data-refresh')
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                })
            }).catch(() => {

            })
        },
        filterNode(value, data) {

            if (!value) return true;
            return data.title.indexOf(value) !== -1
        },
        handleCheck(node, { checkedKeys }) {
            this.checkedKeys = checkedKeys
        },
        handleCurrentChange(node) {
            if(!this.currentNode || this.currentNode.id !== node.id) {
                this.currentNode = node
                this.$emit('current-change', node.id)
            }
        },
        handleCollapsed() {
            const expandFn = ($list) =>  {
                $list.map($el => {
                    $el.expanded = this.flag
                    if($el.childNodes && $el.childNodes.length) {
                        expandFn($el.childNodes)
                    }
                })
            }
            this.$refs.tree.$children.map($el => {
                $el.node.expanded = this.flag
                $el.node.childNodes && expandFn($el.node.childNodes)
            })
            this.flag = !this.flag
        }
    },
  computed:{
    buttonShowList() {
      return {
        'plsc':this.$btnIsShow('isystem/DepartmentManage','0','批量删除'),
        'add':this.$btnIsShow('isystem/DepartmentManage','0','添加部门'),
      }
    }
  }
}
</script>

<style lang="scss">
.menu-popover {
    padding: 4px;
    margin-top: 0;
}
</style>

<style lang="scss" scoped>
.tree-wrap {
    margin-top: 10px;
}
::v-deep
    {
        .el-card__body {
            overflow: auto;
        }
        .el-card__header {
            padding: 14px 20px;
        }
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
            background-color: #e0efff;
        }
        .el-tree-node__content {
            height: 28px;
        }
        .el-button {
            padding: 8px 16px;
        }
    }
</style>
