<template>
    <el-dialog
        :title="row.id ? '编辑角色' : '新增角色'"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="roleName" label="角色名称">
                        <el-input v-model="form.roleName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="roleCode" label="角色编码">
                        <el-input v-model="form.roleCode" placeholder="默认根据角色名称中文名首字母大写生成"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="description" label="角色描述">
                        <el-input v-model="form.description" type="textarea" rows="5" :maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
<!--            <el-button type="primary" size="medium" @click="makePinyin">确定2</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import pinyin from 'js-pinyin'
export default {
    name: 'AddRoleDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {},
            rules: {
                roleName: [
                    { required: true, message: '请输入角色名称', trigger: 'change' },
                    { pattern: /^[\u0391-\uFFE5A-Za-z]+$/ , message: '角色名称只能由中文、英文字母组成', trigger: 'change'}
                ],
                roleCode: [
                    { required: true, message: '请输入角色编码', trigger: 'change' },
                ],
                description: [
                    { required: true, message: '请输入描述', trigger: 'change' },
                    { max:100,message: "描述最多填写50个字符！", trigger: 'change'}
                ],
            },
            url: {
                add: '/sys/role/add',
                edit: '/sys/role/edit'
            }
        }
    },
    watch: {
        visible(val) {
            if(val && this.row.id) {
                const { id, roleName, roleCode, description } = this.row 
                this.form = { id, roleName, roleCode, description }
            } else {
                this.form = {}
            }
        },
        'form.roleName': {
            handler:function(name) {
                if (name !== '' && name !== undefined){
                    this.form.roleCode = this.makePinyin(name);
                }else{
                    this.form.roleCode = ''
                }

            },
            deep: true,
            // immediate: true
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
                method = 'PUT'
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.$refs.form.resetFields()
        },
        makePinyin(name){
                let char = ''
                pinyin.setOptions({checkPolyphone:false,charCase:0});
                char = pinyin.getCamelChars(name)
                return char.toUpperCase()
        }

    }
}
</script>

<style lang="scss" scoped>

</style>