<template>
    <el-dialog
            title="编辑提货"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <!--:disabled="selectedRowKeys.length === 0"-->
        <el-button
                type="primary"
                size="medium"
                @click="handleAddOrder"
                class="margin_right_10"
                :disabled="selectionRows.length===0"
                v-if="buttonShowList.add">加货
        </el-button>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
            <template v-slot="scope">
                                    <template v-if="scope.column.property === 'action'">
                                        <el-button type="text" @click="hanleKnee(scope.row)"
                                                   v-if="buttonShowList.tih && scope.row.canAdd==='0'"
                                        >踢货</el-button>
                                    </template>

                <template v-else-if="scope.column.property === 'orderNo'">
                    <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                </template>


            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>


    </el-dialog>
</template>

<script>
    import {postAction,getAction, putAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";

    export default {
        name: 'editTakeOrderModule',
        components: {},
        mixins: [QueryMixins],
        comments:{},
        props: {
            visible: {
                type: Boolean
            },
            takeIds:{
                type:String,
                default:''
            },
        },
        data() {
            return {
                dialogFormVisible:false,
                disableMixinMounted:true,
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "contact",
                        label: "提货联系人",
                    },
                    {
                        prop: "contactPhone",
                        label: "手机号",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "ctnStr",
                        label: "件重体",
                    },
                    {
                        prop: "address",
                        label: "提货地址",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },

                ],
                form: {},
                formLabelWidth:'100px',
                rules: {
                    takeFee: [
                        {required: true, message: '请输入提货费', trigger: 'change'},
                    ],
                },
                url: {
                    tihuo:'/warehouse/takeDelivery/tihuo',
                    addGoods:'/warehouse/takeDelivery/addGoods',
                    list:'/warehouse/takeDelivery/getNeedAddGoodsData',
                },


            }
        },
        watch: {
            visible(val) {
                if (val && this.takeIds) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'takeIds',this.takeIds);
                    this.queryForm.takeIds = this.takeIds;
                    this.handleQuery();
                }
            },
        },
        methods: {
            handleAddOrder(){
                let canAdd = true;

                this.selectionRows.forEach(s=>{
                    if (s.canAdd==='0') {
                        canAdd = false;
                    }
                });
                if (!canAdd) {
                    this.$message.warning("请勾选可以加货的订单数据进行加货");
                    return false;
                }else {
                    //加货
                    putAction(`${this.url.addGoods}`,this.selectionRows)
                        .then(res=>{
                            this.$message.success(res.message);
                            this.handleQuery();
                        })
                }

            },

            hanleKnee({id,orderId}){
                putAction(`${this.url.tihuo}?id=${id}&orderId=${orderId}`)
                .then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                })
            },
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','0','加货'),
            'tih':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','踢货'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
