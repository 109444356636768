<template>
    <el-dialog
            :title="type === 'RELATION' ? '关联' : '反向关联'"
            :visible="visible"
            width="680px"
            top="8vh"
            custom-class="c-custom-dialog"
            :destroy-on-close="true"
            :before-close="handleClose">
        <div class="content c-slim-scrollbar">


            <el-tree
                    :data="data"
                    ref="tree"
                    :props="defaultProps"
                    @check-change="upper_lowerChange"
                    :default-expanded-keys="['']"
                    default-expand-all
                    :filter-node-method="filterNode"
                    show-checkbox
                    :indent="16"
                    v-loading="upLowLoading"
                    node-key="id"
                    @check="clickDeal"
                    :current-node-key="currentNodeKey"
                    @getCurrentNode="getCurrentNode"
            ></el-tree>
            <!--            <el-tree-->
            <!--                show-checkbox-->
            <!--                ref="tree"-->
            <!--                node-key="id"-->
            <!--                :data="data"-->
            <!--                :props="defaultProps"-->
            <!--                :check-strictly="true"-->
            <!--                :highlight-current="true"-->
            <!--                :default-expand-all="true"-->
            <!--                :check-on-click-node="true"-->
            <!--                :expand-on-click-node="false">-->
            <!--            </el-tree>-->
        </div>

        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="选中人：">
                <el-checkbox-group v-model="form.relationPeople">
                    <el-checkbox
                            v-if="data.checked == '0'"
                            v-model="form.relationPeople"
                            v-for="data in selectPeople" @change="handleChange(data)"
                            :label="data.id"
                            :key="data.name"
                    >
                        {{data.name}}
                    </el-checkbox>
                    <el-checkbox
                            v-if="data.checked == '1'"
                            v-model="form.relationPeople"
                            v-for="data in selectPeople" @change="handleChange(data)"
                            :label="data.id"
                            :key="data.name"
                            checked
                    >
                        {{data.name}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button class="c-theme-button" size="medium" plain @click="handleSetChecked">{{isChecked ? '全部' : '取消' }}选中</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'

    export default {
        name: 'RelationDialog',
        props: {
            visible: {
                type: Boolean
            },
            id: {
                type: String
            },
            type: {
                type: String,
                default: 'RELATION', // REVERSE
            }
        },
        data() {
            return {
                selectPeople: [],
                currentNodeKey: "",
                checkedMenuIds: [],
                form: {
                    relationPeople: [],
                },
                upLowVisble: false, // 上下架显隐开关
                filterText: "", // 过滤字段
                channels: [], // 栏目初始数据
                channelProps: {
                    label: "name",
                    children: "children",
                    isLeaf: "isChild",
                    id: "id"
                },
                flag: false, // 属性是否选中标识
                isCheck: false,
                upLowLoading: true, // 遮罩层
                upLowTitle: "", // 上下架面板标题
                isChecked: true,
                allKeys: [],
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                url: {
                    // list: '/sys/user/list',
                    ok: '/sys/user/setRelation',
                    auth: '/sys/user/getRelation',
                    okreserve: '/sys/user/setRelationReverse',
                    authreserve: '/sys/user/getRelationReverse'
                }
            }
        },
        watch: {
            visible(val) {
                if (val) {
                    this.handleGetData()
                    // this.handleGetAuth()
                }
            },
            currentNodeKey(id) {
                // Tree 内部使用了 Node 类型的对象来包装用户传入的数据，用来保存目前节点的状态。可以用 $refs 获取 Tree 实例
                console.log(id)
                if (id.toString()) {
                    this.$refs["tree"].setCurrentKey(id);
                } else {
                    this.$refs["tree"].setCurrentKey(null);
                }
            }
        },
        methods: {
            handleCheckChange() {
                console.log("1")
            },
            getCurrentNode(a, b) {
            },
            clickDeal(currentObj, treeStatus) {
                let that = this;
                that.selectPeople = [];
                let ids = this.$refs.tree.getCheckedKeys()


                // console.log(this.$refs.tree.getCurrentNode());
                for (let i = 0; i < ids.length; i++) {

                    let data = this.$refs.tree.getNode(ids[i]).data
                    if (data != null && data != undefined) {
                        if (data.checkUser != null) {
                            console.log(data.checkUser)
                            for (let i = 0; i < data.checkUser.length; i++) {
                                if (data.checkUser[i].id != null) {
                                    that.selectPeople.push(data.checkUser[i])
                                }
                            }
                        }
                    }
                }
                // // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
                // let selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未选中
                // // 选中
                // if (selected !== -1) {
                //     // 子节点只要被选中父节点就被选中
                //     this.selectedParent(currentObj)
                //     // 统一处理子节点为相同的勾选状态
                //     this.uniteChildSame(currentObj, true)
                // } else {
                //     // 未选中 处理子节点全部未选中
                //     if (currentObj.childs.length !== 0) {
                //         this.uniteChildSame(currentObj, false)
                //     }
                // }

            },
            handleChange(data) {
                console.log(data)
                // console.log(index)
                console.log(this.form.relationPeople)
            },
            // 渲染树
            upLowLoad() {
                let that = this;
                this.upLowLoading = true;
                axios
                    .post(this.$api.allChannelList)
                    .then(res => {
                        this.upLowLoading = false;
                        that.channels = res.body;
                    })
                    .catch(err => {
                        this.upLowLoading = false;
                    });
            },

            // 上下架树加载
            upper_lowerChange(node, checkStatus, childStatus) {

                let that = this;
                if (checkStatus) {
                    this.currentCheckChannelId = node.id;
                    if (node != null) {
                        // eslint-disable-next-line no-empty
                        if (node.checkUser != null) {
                            for (let i = 0; i < node.checkUser.length; i++) {
                                if (node.checkUser[i].id != null) {
                                    that.selectPeople.push(node.checkUser[i])
                                }
                            }
                        }
                    }
                }
            },
            // 上下架节点过滤
            filterNode(value, data) {

                if (!value) return true;
                return data.title.indexOf(value) !== -1
            },
            // 全选或反选
            checkAll() {
                console.log(this.channels)
                this.flag = true;
                this.$refs.channelTree.setCheckedNodes(this.channels);
            },
            // 全选处理方法
            batchSelect(nodes, refs, flag, seleteds) {
                if (typeof nodes != "undefined") {
                    nodes.forEach(element => {
                        refs.setChecked(element, flag, true);
                    });
                }

                if (typeof seleteds != "undefined") {
                    seleteds.forEach(node => {
                        refs.setChecked(node, !flag, true);
                    });
                }
            },
            // 反选
            inverse() {
                let res = this.$refs.channelTree;
                let nodes = res.getCheckedNodes(true, true);
                this.batchSelect(this.channels, res, true, nodes);
            },
            handleSubmit() {
                const url = this.type === 'RELATION' ? this.url.ok : this.url.okreserve

                let relationIds = "";
                if (this.form.relationPeople.length > 0) {
                    for (let i = 0; i < this.form.relationPeople.length; i++) {
                        relationIds = relationIds + this.form.relationPeople[i] + ",";

                    }
                }
                const params = {
                    id: this.id,
                    relationIds: relationIds
                }
                postAction(url, params).then(res => {
                    console.log(res)
                    if (res.success) {
                        //this.handleClose()
                        this.handleClose()
                        this.$message({
                            type: 'success',
                            message: res.message
                        })

                    }

                })
            },
            handleGetData() {

                this.form.relationPeople = [];
                //  this.$refs.tree.setCheckedNodes([]);
                this.data = []
                this.selectPeople = []
                const url = this.type === 'RELATION' ? this.url.auth : this.url.authreserve
                console.log(url)
                postAction(url, {userId: this.id}).then((res) => {
                    this.data = res.result
                    console.log(this.data)
                    //勾选节点
                    this.$nextTick(() => {
                        this.getRoleMenu(res.result)
                    });
                    this.upLowLoading = false;
                    console.log(this.data)
                })
            },
            handleSetChecked() {
                console.log(this.data)
                this.$refs.tree.setCheckedNodes(this.isChecked ? this.data : []);
                this.selectPeople = []
                if (!this.isChecked) {
                    this.form.type = []
                }
                // this.selectPeople(this.isChecked ? [] : [])
                // this.$refs.tree.setCheckedKeys(this.isChecked ? this.data : [])
                this.isChecked = !this.isChecked
            },
            //递归遍历树
            setName(menuList) {
                for (var i in menuList) {
                    if (menuList[i].checkUser.length > 0) {
                        for (let j = 0; j < menuList[i].checkUser.length; j++) {
                            if (menuList[i].checkUser[j].checked == '1') {
                                this.checkedMenuIds.push(menuList[i].id)
                                break;
                            }
                        }
                    }
                    // this.checkedMenuIds.push(menuList[i].id)
                    if (menuList[i].children) {
                        this.setName(menuList[i].children);
                    }
                }
            },
            handleClose() {
                this.form.relationPeople = [];
                this.$refs.tree.setCheckedNodes([]);
                this.data = []
                this.selectPeople = []
                this.$emit('update:visible', false)
            }
            ,
            //勾选节点
            getRoleMenu(menuList) {

                if (menuList != null && menuList.length > 0) {
                    this.setName(menuList);
                }
                this.$refs.tree.setCheckedKeys(this.checkedMenuIds); //设置目前勾选的节点
            }

        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

    ::v-deep {
        .el-dialog__body {
            padding: 0 0 0 20px;
        }

        .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
            background-color: #e0efff;
        }
    }
</style>
