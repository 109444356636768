<template>
    <el-card class="f-flex-col" shadow="never">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="icon-grid-wrap"><i class="el-icon-s-grid"></i></span> 编辑菜单 <span v-if="row.id">{{': ' + row.title}}</span>
        </div>
        </el-card>
        <el-card class="box-card last_card">
            <div class="f-full-height f-flex-col f-overflow-auto">
            <div class="form-wrap" v-if="row.id">
                <menu-form show-button :row="row" :data="data" @ok="handleSubmit" ></menu-form>
            </div>
            <div v-else class="c-alert">从菜单列表选择一项后，进行编辑</div>
        </div>
        </el-card>

    </el-card>
</template>

<script>
import { postAction } from '@/api/manage'
import MenuForm from './MenuForm'
export default {
    name: 'MenuEditModule',
    components: {
        MenuForm
    },
    props: {
        row: {
            type: Object,
            default: () => {}
        },
        data: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
          url: {
              edit: '/sys/permission/edit'
          }
        }
    },
    methods: {
        handleSubmit(form) {
            if (form.menuType ==2){
                return false;
            }
            postAction(this.url.edit, { id: this.row.id ,...form }).then(res => {
                this.$message({
                    type: 'success',
                    message: res.message
                })
                this.$bus.$emit('isys-menu-data-refresh')
           })
        },
    }
}
</script>

<style lang="scss" scoped>
.form-wrap {
    padding: 0 6px;
}
.icon-grid-wrap {
    width: 28px;
    height: 28px;
    display: inline-block;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    background: #e6f7ff;
    .el-icon-s-grid {
        color: rgb(24, 144, 255);
    }
}
::v-deep {
    .el-card__body {
        overflow: scroll;
    }
}
</style>
