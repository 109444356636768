<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="680px"
        top="8vh"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="destroyOnClose"
        :before-close="handleClose">
        <el-form :model="stateForm" :rules="rules" ref="stateForm" >
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="name" label="省/州英文名称" >
                        <el-input v-model="stateForm.name" autocomplete="off" :maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="cname" label="省/州中文名称">
                        <el-input v-model="stateForm.cname" autocomplete="off" :maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="code" label="代码">
                        <el-input v-model="stateForm.code" autocomplete="off" :maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
    name: 'addStateDialog',
    props: {
        title: {
            type: String,
            default: '请输入标题'
        },
        visible: {
            type: Boolean
        },
        destroyOnClose: {
            type: Boolean,
            default: true
        },
        id: {
            type: String
        },
        // idField: {
        //     type: String,
        //     default: 'id'
        // },
        // saveIdField: {
        //     type: String,
        //     default: 'id'
        // },
        // url: {
        //     type: Object,
        //     default: () => {
        //         return {
        //             list: '/sys/permission/list', // treeData
        //             auth: '', // 权限
        //             ok: '' // 确认保存请求的接口
        //         }
        //     }
        // }
    },
    data() {
        return {
            // id:'',
            flag: false,
            // visible:'',
            isChecked: true,
            ifDis: false,
            allKeys: [],
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            stateForm:{
                countryId:'',
                code:'',
                name:'',
                cname:''
            },
            url: {
                list: '/sys/bdCountry/list',
                deleteBatch: '/sys/bdCountry/deleteBatch',
                addState:'/sys/bdCountry/addState',
            },
            rules: {
                name: [
                    { required: true, message: '请输入省/州的英文名称', trigger: 'change' },
                    { pattern: /^[A-Za-z\s]+$/ , message: '英文名称只能由英文组成', trigger: 'change'}
                ],
                cname: [
                    { required: true, message: '请输入省/州的中文名称', trigger: 'change' },
                    { pattern: /^[\u4e00-\u9fa5]{0,}$/ , message: '中文名称只能由中文组成', trigger: 'change'}
                ],
         /*       code: [
                    { required: true, message: '请输入编码', trigger: 'change' },
                    { pattern: /^[A-Za-z0-9]+$/ ,message: "编码只能由英文或数字组成！", trigger: 'change'}
                ],*/
            },
        }
    },
    watch: {
        visible(val){
                this.stateForm.name = '';
                this.stateForm.cname = '';
                this.stateForm.code = '';
        }

    },
    methods: {
        handleSubmit(){
            this.$refs.stateForm.validate((valid) => {
                if (valid){
                    this.ifDis = true
                    this.enabled()
                    this.stateForm.countryId = this.id
                    postAction(this.url.addState,this.stateForm).then((res) => {
                        if(res.success){
                            this.$message({
                                type: 'success',
                                message: res.message

                            })
                            this.$emit('update:visible', false)
                        }
                    })
                }
            })
        },
        handleClose(){
            this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            // this.visible = false;
            this.$refs.stateForm.resetFields();
        },
        enabled(){
            setTimeout(()=>{
                this.ifDis = false;
            },2000)
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}
.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}
::v-deep {
    .el-dialog__body {
        padding: 0 0 0 20px;
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #e0efff;
    }
}
</style>
