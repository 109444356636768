<template>
    <el-dialog
            :title="form.id ? '销账单绑定流水号生成凭证' : '查看销账单绑定的流水号'"
            :visible="visible"
            width="30%"
            v-if="visible"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="流水号" prop="billNo" :label-width="formLabelWidth">
                <el-input v-model="form.billNo" autocomplete="off" maxlength="50" show-word-limit v-if="!hasVol"></el-input>
                <el-select v-model="form.billNo" filterable  v-if="hasVol"  @change="handleChangeVol" clearable="">
                    <el-option v-for="(i,d) in volList" :key="d" :value="i" :label="i"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定的销账单号" prop="applyNos" :label-width="formLabelWidth">
                <el-input v-model="form.applyNos" autocomplete="off" maxlength="500" type="textarea" show-word-limit></el-input>
            </el-form-item>
<!--            <el-form-item label="是否共用流水号" :label-width="formLabelWidth">-->
<!--                <el-radio-group v-model="form.isMerage">-->
<!--                    <el-radio :label='"0"'>否</el-radio>-->
<!--                    <el-radio :label='"1"'>是</el-radio>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->
            <el-form-item label="凭证类型" :label-width="formLabelWidth" prop="voucherType">
                <el-radio-group v-model="form.voucherType">
                    <el-radio :label='"3"' :disabled="form.voucherType !== ''">收款凭证</el-radio>
                    <el-radio :label='"4"' :disabled="form.voucherType !== ''">付款凭证</el-radio>
                </el-radio-group>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="handleClose">取 消</el-button>
            <el-button size="medium" type="primary" @click="bindBillNo" :disabled="hasVol" >确 定 </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {httpAction,getAction,postAction} from '@/api/manage'

    export default {
        name: 'AddBillNoDialog',
        props: { //传参
            visible: {
                type: Boolean
            },
            applyNos: {
                type: Array,
                default: ()=>[]
            },
            voucherType: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                fullscreen: false,
                url: {
                    bindBill: '/finance/voucher/saveReceivAndPayData',
                    query: '/finance/voucher/getBillNoByApplyNo'
                },
                //表单 提交的对象
                form:{},
                //表单 元素的校验规则
                rules: {},
                //标签字段长度
                formLabelWidth:'120px',
                //提交按钮是否禁用
                btnDisable:false,
                //定义一个标识 标识 是否有流水号记录
                hasVol:false,
                //定一个 存放 获取流水号记录
                resMap:{},
                //流水号集合
                volList:[],
                isMerage: '',
            }
        },
        watch: {
            visible: {
                immediate: true,
                handler(val) {

                    this.hasVol = false;
                    val && this.applyNos && this.applyNos.length > 0 && postAction(this.url.query,this.applyNos)
                        .then(res=>{
                            //请求成功
                            if (res && res.result) {

                                let billnos = [];
                                for (var key in res.result) {
                                    billnos.push(key);
                                }

                                //判断 返回的结果 有销账编号
                                if (billnos &&  billnos.length > 0) {
                                    //默认选中第一个流水号

                                    this.$set(this.form,'billNo',billnos[0]);
                                    this.$set(this.form,'applyNos',res.result[billnos[0]]);
                                    this.hasVol = true; //标识为有流水号
                                    this.volList = billnos;
                                    this.resMap = res.result;
                                }

                            } else {
                                this.form = {};
                                this.form.applyNos = this.applyNos.join(',');

                            }
                            this.isMerge = '0';
                            if(this.form.applyNos.includes(",")) {
                                this.isMerge = '1';
                            }
                            this.form.isMerge = this.isMerge;
                            this.form.voucherType = this.voucherType;

                        }).catch(e=>{

                        })
                }
            }
        },
        methods: {
            //变化的时候 修改 销账编号
            handleChangeVol(val) {
                if (this.resMap[val]){
                    let applyNostr =  this.resMap[val];
                    this.form.applyNos = applyNostr;
                    this.form.isMerage = "0";
                    if(applyNostr.includes(",")){
                        this.form.isMerage = "1";
                    }
                }
                if(this.form.billNo == '未绑定流水号') {
                    this.hasVol = false;
                }
                this.form.isMerage = this.isMerage;
                this.form.voucherType = this.voucherType;

            },
            // handleSubmit() {
            //     this.form = {};
            // },
            handleClose() {
                this.form = {};
                this.$emit('update:visible', false)
            },
            //新增或修改
            bindBillNo() {
                /*
                    isReApply: false,
                    map: {
                        billno : this.applyNos  error
                            "billNo1": ["BTSP001","BTSP002"],
                            "billNo2": ["BTSP003","BTSP004"],
                    },
                 */
                let billno = this.form.billNo;
                let param =  {
                    isMerge: this.form.isMerge == 1 ? true : false,
                    voucherType: this.form.voucherType,
                }
                param['map'] = {};
                param['map'][billno] = this.applyNos;
                this.btnDisabled = true;
                this.$refs['form'].validate((valid) => {
                    postAction(this.url.bindBill, param)
                        .then(res => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisible = false;
                                this.handleClose();
                            }else {
                                this.btnDisabled = false;
                            }
                        }).catch(err=>{
                        this.btnDisabled = false;
                    })
                })
            },
        },

    }
</script>

<style lang="scss" scoped>
    .title {
        line-height: 24px;
        font-size: 18px;
        color: #303133;

        i {
            cursor: pointer;
        }
    }

    ::v-deep
    .el-dialog__body {
        padding: 20px 30px 0px 26px;
        height: calc(100% - 130px);
        overflow: auto;
    }
</style>
