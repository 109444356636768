<template>
  <div class="sales_tab_box">
    <div class="button_right_sale">
      <el-button type="primary" size="medium" :disabled="disabledBut" @click="handleUpdateData"
        v-if="buttonShowList.update">{{disabledBut ? '更新中' : '更新数据'}}
      </el-button>
      <div class="f-m-t-2">{{countdown}}</div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="销售利润报表" name="sale">
        <sale-report ref="sale" :salesMouth="salesMouth" :containerTypeList="containerTypeList"></sale-report>
      </el-tab-pane>
      <el-tab-pane label="客户利润报表" name="customer">
        <customer-report ref="customer" :salesMouth="salesMouth" :containerTypeList="containerTypeList"></customer-report>
      </el-tab-pane>
      <el-tab-pane label="亏本订单分类" name="lose">
        <lose-order ref="lose" :salesMouth="salesMouth" :containerTypeList="containerTypeList"></lose-order>
      </el-tab-pane>
      <el-tab-pane label="利润报表" name="profit">
        <profit-report ref="profit" :salesMouth="salesMouth" :containerTypeList="containerTypeList"></profit-report>
      </el-tab-pane>
      <el-tab-pane label="周汇报" name="week">
        <week-hb ref="week"></week-hb>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import { postAction } from "@/api/manage";
  import SaleReport from "./report/SaleReport";
  import CustomerReport from "./report/CustomerReport";
  import LoseOrder from "./report/LoseOrder";
  import ProfitReport from "./report/ProfitReport";
  import WeekHb from "./WeekHb";
  export default {
    components: {
      SaleReport,
      CustomerReport,
      LoseOrder,
      ProfitReport,
      WeekHb
    },
    data() {
      return {
        activeName: 'sale',
        salesMouth: '',
        url: {
          getTTL: '/shipping/report/getTTL',
          createProfit: '/shipping/report/createProfit'
        },
        disabledBut: false,
        updateDate: null,
        countdown: null,
        containerTypeList:[
            {
                label:'自拼',
                value:"0"
            },
            {
                label:'整柜',
                value:"1"
            },
            {
                label:'非拼',
                value:"2"
            }
        ],
      };
    },
    watch: {
      activeName: {
        deep: true,
        immediate: true,
        handler(val) {
          this.$refs[this.activeName] && this.$refs[this.activeName].handleQuery();
        },
      },
    },
    created() {
      this.getTopMouth();
      this.getTTL();
    },
    mounted() {
      this.$refs[this.activeName] && this.$refs[this.activeName].handleQuery();
    },
    methods: {
      getTTL() {
        let timer = null;
        postAction(this.url.getTTL).then(res => {
          if (res.result == -2) {
            this.disabledBut = false;
          } else {
            this.updateDate = res.result;
            this.disabledBut = true;
            const timer = setInterval(() => {
              if (this.updateDate > 0) {
                this.countdown = `${this.updateDate}s`; // 更新显示的倒计时文本
                this.updateDate--; // 每次递减1秒
              } else {
                clearInterval(timer); // 当倒计时结束时清除定时器
                this.countdown = null;
                this.disabledBut = false;
              }
            }, 1000); // 每隔1秒执行一次
          }
        })
      },
      handleUpdateData() {
        postAction(this.url.createProfit).then(res => {
          this.getTTL();
        })
      },
      getTopMouth() {
        // 获取当前时间对象
        let currentDate = new Date();
        // 设置为上一个月
        currentDate.setMonth(currentDate.getMonth() - 1);
        // 格式化输出年、月
        const year = currentDate.getFullYear(); // 获取年份
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（需要加1）
        this.salesMouth = year + '-' + month;
      },
      handleClick(tab, event) {
        console.log(tab, event);
      }
    },
    computed:{
      buttonShowList() {
        return {
          'update':this.$btnIsShow('sale/SalesReport','0','更新数据'),

        }
      }
    }
  };
</script>
<style lang="scss">
  .sales_tab_box {
    .el-tabs--top>.is-top {
      left: 150px;
    }
  }

  .button_right_sale {
    position: absolute;
    right: 50px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
<style lang="scss" scoped>
</style>