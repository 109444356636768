<template>
  <problem-module></problem-module>
</template>
<script>
import ProblemModule from "@/views/docpage/ProblemModule";
export default {
  components: {
    ProblemModule,
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
