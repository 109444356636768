<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="680px"
        top="8vh"
        custom-class="c-custom-dialog"
        :destroy-on-close="destroyOnClose"
        :before-close="handleClose">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText">
        </el-input>
        <div class="content c-slim-scrollbar">
            <el-tree
                check-strictly
                show-checkbox
                ref="tree"
                node-key="id"
                :data="treeData"
                :props="defaultProps"
                :highlight-current="true"
                :default-expand-all="true"
                :check-on-click-node="true"
                :expand-on-click-node="false"
                :default-expanded-keys="idArr"
                :filter-node-method="filterNode"
                @check="handleCheck"
            >
            </el-tree>
        </div>
        <span slot="footer" >
            <el-button class="c-theme-button" size="medium" type="primary" @click="handleSetCheckedNodes">{{isChecked ? '全部' : '取消' }}选中</el-button>
            <el-button class="c-theme-button" size="medium" type="primary" @click="handleCollapsed">全部{{flag ? '展开' : '折叠'}}</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
export default {
    name: 'EditTreeDialog',
    props: {
        title: {
            type: String,
            default: '请输入标题'
        },
        visible: {
            type: Boolean
        },
        destroyOnClose: {
            type: Boolean,
            default: true
        },
        id: {
            type: String
        },
        idField: {
            type: String,
            default: 'id'
        },
        saveIdField: {
            type: String,
            default: 'id'
        },
        url: {
            type: Object,
            default: () => {
                return {
                    list: '/sys/permission/list', // treeData
                    auth: '', // 权限
                    ok: '' // 确认保存请求的接口
                }
            }
        }
    },
    data() {
        return {
            flag: false,
            isChecked: true,
            allKeys: [],

            idArr:[],
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            checkKeyMap:{},
            filterText: '',
        }
    },
    watch: {
        visible(val) {
            if(val) {
                this.filterText = ''
                this.handleGetAuth()
                this.handleGetTreeData()
            }
        },
        filterText(val) {
          this.$refs.tree.filter(val);
        }
    },
    methods: {
      filterNode(value, data) {
        if (!value) return true;
        return data.title.indexOf(value) !== -1
      },
        //        handleCheck(node, {checkedKeys,checkedNodes、checkedKeys、halfCheckedNodes、halfCheckedKeys}) {

        handleCheck(node, {checkedKeys,checkedNodes,halfCheckedNodes}) {
            let menuType = node.menuType;
            console.log("是什么被选中了",menuType,node)
           //先判断 当前的节点是按钮 还是菜单
            let id = node.id;

            //先判断 当前id 是被选中 还是没有被选中
            if (checkedKeys.indexOf(id)!==-1 ){
                //按钮
                if (menuType==2){

                    checkedKeys.push(node.parentId);
                    let pid = node.parentId;
                    if (pid && pid.length>0){
                        //说明不是第一级菜单
                        let father = this.$refs.tree.getNode(pid);
                        console.log('第一个父亲',father);
                        if (father) {
                            let grand = father.parent;
                            console.log('第2个父亲',grand);
                            if (grand && grand.data) {
                                checkedKeys.push(grand.data.id);
                                let sss = grand.parent;
                                console.log('第3个父亲',sss);
                                if (sss && sss.data) {
                                    checkedKeys.push(sss.data.id);
                                }
                            }
                        }

                    }
                    //一级菜单
                }else if (menuType==0){
                    console.log("是一级菜单呀被选中了",menuType)
                    let list1 = node.children;
                        if (list1 && list1.length>0 && Array.isArray(list1)){
                            for (let i=0;i<list1.length;i++){
                                checkedKeys.push(list1[i].id);
                                //判断这货有没有儿子
                                let list2 = list1[i].children;
                                if (list2 && list2.length>0 && Array.isArray(list2)){
                                    for (let j=0;j<list2.length;j++){
                                        checkedKeys.push(list2[j].id);
                                        //判断这货有没有儿子
                                        let list3 = list2[j].children;
                                        if (list3 && list3.length>0 && Array.isArray(list3)){
                                            for (let k=0;k<list3.length;k++){
                                                checkedKeys.push(list3[k].id);

                                            }
                                        }
                                    }
                                }
                            }
                        }
                        //二级菜单
                }else if (menuType==1){
                    let list1 = node.children;
                    if (list1 && list1.length>0 && Array.isArray(list1)){
                        for (let i=0;i<list1.length;i++){
                            checkedKeys.push(list1[i].id);
                            //判断这货有没有儿子
                        }
                    }
                    let pid = node.parentId;

                    if (pid && pid.length>0){
                        //说明不是第一级菜单
                        let father = this.$refs.tree.getNode(pid);
                        checkedKeys.push(pid);
                        console.log('第一个父亲',father);
                        if (father) {
                            let grand = father.parent;
                            console.log('第2个父亲',grand);
                            if (grand && grand.data) {
                                checkedKeys.push(grand.data.id);
                                let sss = grand.parent;
                                console.log('第3个父亲',sss);
                                if (sss && sss.data) {
                                    checkedKeys.push(sss.data.id);
                                }
                            }
                        }

                    }
                }
                //选中了 在判断 如果是按钮 他的父亲也被选上
            }else {
                //判断当前id 不被选中 如果是按钮
                if (2==menuType){
                    //如果是按钮 不被选中 不搞事情
                }else {
                    //如果是菜单 不被选中 并且这货下面有按钮  就把他干掉
                    let hasButton = node.hasButton;

                        let children = node.children;
                        if(children && children.length>0 && Array.isArray(children)){
                            for (let g=0;g<children.length;g++){
                                checkedKeys.splice(checkedKeys.indexOf(children[g].id),1);
                                let children2 = children[g].children;
                                if(children2 && children2.length>0 && Array.isArray(children2)){
                                    for (let x=0;x<children2.length;x++){
                                        checkedKeys.splice(checkedKeys.indexOf(children2[x].id),1);
                                        let children3 = children2[x].children;
                                        if(children3 && children3.length>0 && Array.isArray(children3)){
                                            for (let y=0;y<children3.length;y++){
                                                checkedKeys.splice(checkedKeys.indexOf(children3[y].id),1);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                }
            }

            this.$refs.tree.setCheckedKeys(checkedKeys);
            // this.checkedKeys = checkedKeys
            // console.log('this.$refs.tree.getCheckedKeys()',this.$refs.tree.getCheckedKeys())
        },
        handleSubmit() {
            const checked = this.$refs.tree.getCheckedKeys()
            const halfChecked = this.$refs.tree.getHalfCheckedKeys()
            const perIds = checked.concat(halfChecked).join(',');
            if (!perIds || perIds.length===0) {
                this.$message.warning("请至少勾选一条数据");
                return false;
            }
            const params = {
                [this.saveIdField]: this.id,
                // permissionIds: this.$refs.tree.getCheckedKeys().join(','),
                permissionIds: perIds,
                lastpermissionIds: this.allKeys.filter(item => perIds.indexOf(item) === -1).join(',')
            }
             console.log(params)
            postAction(this.url.ok, params).then(res => {
                this.handleClose()
                this.$message({
                    type: 'success',
                    message: res.message
                })
            })
        },
        handleGetAuth() {
            getAction(this.url.auth, { [this.idField]: this.id }).then((res) => {
                console.log('this.$refs.tree',this.$refs.tree)
                // for (let i=0;i<res.result;i++){
                //     //一个一个节点赋值
                //     this.$refs.tree.setChecked(res.result[i],true,true);
                // }
                this.$refs.tree.setCheckedKeys(res.result,true)
            })
        },
        handleSetCheckedNodes() {

            this.$refs.tree.setCheckedKeys(this.isChecked ? this.allKeys : [])
            this.isChecked = !this.isChecked
        },
        handleGetTreeData() {
            getAction(this.url.list).then((res) => {
                this.treeData = res.result;
                res.result.forEach(m=>this.idArr.push(m.id));
                for (var i=0;i<res.result.length;i++){


                    if (res.result[i].children){
                        res.result[i].children.forEach(m=>this.idArr.push(m.id));
                    }
                }
                // res.result.forEach(m=>this.idArr.push(m.id));
                // console.log('所有的节点',this.idArr);
                this.allKeys = [];
                this.handleGetkeys(this.treeData);
            })
        },
        handleGetkeys(data) {
            for (let i = 0; i < data.length; i++) {
                this.allKeys.push(data[i]['id'])
                if (data[i]["children"] && data[i]["children"].length) {
                    this.handleGetkeys(data[i]["children"]);
                }
            }
        },
        handleCollapsed() {
            const expandFn = ($list) =>  {
                $list.map($el => {
                    $el.expanded = this.flag
                    if($el.childNodes && $el.childNodes.length) {
                        expandFn($el.childNodes)
                    }
                })
            }
            this.$refs.tree.$children.map($el => {
                $el.node.expanded = this.flag
                $el.node.childNodes && expandFn($el.node.childNodes)
            })
            this.flag = !this.flag
        },
        handleClose() {
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}
.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}
::v-deep {
    .el-dialog__body {
        padding: 0 0 0 20px;
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #e0efff;
    }
    .custom-tree-node {
        flex: 1;
        display: flex; //这里的display属性不用修改（使树节点末尾的按钮右对齐）
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        padding-right: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%; //宽度必须是这个，不能使用px或者min-width或者min-width等，因为外层使用了el-card包裹是可以拉动的，换言之树节点的宽度随时可变
        & span.em-tree-text {
            display: inline-block; //block一样
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
        }
    }
}

</style>
