<!--云当船期查询-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :rules="rules" :model="queryForm" label-width="90px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港" prop="polcd">
                            <f-select v-model="queryForm.polcd" :dict="'bd_port_un'"
                                      :isNeed="queryForm.polcd"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港" prop="podcd">
                            <f-select v-model="queryForm.podcd" :dict="'bd_port_un'"
                                      :isNeed="queryForm.podcd"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="离港日期" prop="etd">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.etd"
                                    type="date"
                                    size="small"
                                    placeholder="离港日期"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="船公司代码">
                            <el-input v-model="queryForm.carriercd" @keyup.enter.native="handleSearchInit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="直达/中转">
                            <el-radio-group v-model="queryForm.isTransit" @keyup.enter.native="handleSearchInit">
                                <el-radio :label='"0"'>直达</el-radio>
                                <el-radio :label='"1"'>中转</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearchInit">查询</el-button>
                            <el-button plain @click="handleResetInit">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'polCode'">
                        <span>{{parseDict(scope.row.polCode,'bd_port_un')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podCode'">
                        <span>{{parseDict(scope.row.podCode,'bd_port_un')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isTransit'">
                        <span v-if="scope.row.isTransit === '0'">直达</span>
                        <span v-if="scope.row.isTransit === '1'">中转</span>
                    </template>
                    <template v-else-if="scope.column.property === 'polCountry'">
                        <span>{{parseDict(scope.row.polCountry,'bd_country_en')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podCountry'">
                        <span>{{parseDict(scope.row.podCountry,'bd_country_en')}}</span>
                    </template>




                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                ids: [],
                row: {},
                form: {},
                dictCodes: ['bd_port_un','bd_country_en'],
                //控制弹框显示
                dialogFormVisible: false,
                rules: {
                  polcd: [
                    { required: true, message: "请选择起运港", trigger: "change" },
                  ],
                  podcd: [
                    { required: true, message: "请选择目的港", trigger: "change" },
                  ],
                  etd: [
                    { required: true, message: "请选择离港日期", trigger: "change" },
                  ],
                },
                columns: [
                    {
                        prop: 'scac',
                        label: '船司代码',
                        width: 90,
                    },
                    {
                        prop: 'carriercd',
                        label: '船司简称',
                        width: 90,
                    },
                    {
                        prop: 'service',
                        label: '航线名称',
                        width: 100
                    },
                    {
                        prop: 'routeCode',
                        label: '航线代码',
                        width: 80
                    },
                    {
                        prop: 'vessel',
                        label: '船名',
                        width: 100
                    },
                    {
                        prop: 'voyage',
                        label: '航次',
                        width: 60
                    },
                    {
                        prop: 'polCode',
                        label: '起运港',
                        width: 90
                    },
                    {
                        prop: 'polCountry',
                        label: '起运国',
                        width: 60
                    },
                    {
                        prop: 'polTerninal',
                        label: '起运港码头',
                        minWidth: 150
                    },
                    {
                        prop: 'podCode',
                        label: '目的港',
                        minWidth: 80
                    },
                    {
                        prop: 'podCountry',
                        label: '目的国',
                        minWidth: 60
                    },
                    {
                        prop: 'podTerninal',
                        label: '目的港码头',
                        minWidth: 150,
                    },
                    {
                        prop: 'etd',
                        label: 'ETD',
                        minWidth: 100
                    },
                    {
                        prop: 'eta',
                        label: 'ETA',
                        minWidth: 100
                    },
                    {
                        prop: 'totalDuration',
                        label: '航程时间(天)',
                        minWidth: 60
                    },
                    {
                        prop: 'isTransit',
                        label: '中转/直达',
                        minWidth: 50
                    },
                ],
                url: {
                    list: '/interface/fbaJob/shipList',
                },
                disableMixinMounted: true,

            }
        },
        methods: {
          
          handleSearchInit() {
            this.$refs.form.validate((valid) => {
              if (valid) {
                this.handleSearch();
              }
            });
          },
          handleResetInit() {
            this.$refs.form.validate((valid) => {
              if (valid) {
                this.handleReset();
              }
            });
          }
        }, created() {

        }
    }
</script>
<style lang="scss" scoped>
    .addEditBox {
        max-height: 625px;
        overflow: scroll;
    }
</style>
