<!--带入仓-->
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div style="display:flex;">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="销售">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.salesman"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="客户名">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.customer"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="销售组别">
                <f-select
                    v-model="queryForm.deptIdList"
                    :dict="'sys_depart'"
                    :isNeed="queryForm.deptIdList"
                    :multiple="true"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="是否锁单">
                    <el-select v-model="queryForm.isLockOrder">
                        <el-option value="1" label="是"></el-option>
                        <el-option value="0" label="否"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="是否亏本">
                    <el-select v-model="queryForm.loseMoney">
                        <el-option value="1" label="是"></el-option>
                        <el-option value="0" label="否"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="下单时间">
                  <div class="f-flex">
                      <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.createTimeStart"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss">
                      </el-date-picker>
                      <span class="f-p-h-2">~</span>
                      <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.createTimeEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss">
                      </el-date-picker>
                  </div>
              </el-form-item>
              </el-col>
            <el-col :span="4">
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="handleReset"
                  icon="reload"
                  style="margin-left: 8px"
              >重置</el-button
              >
              <el-button
                    type="primary"
                    size="medium"
                    style="margin-left: 8px"
                    @click="downloadExcelSale(url.download, '订单核对数据',queryForm)"
              >导出</el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="65vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
          </template>
          <template v-else-if="scope.row.recBill && scope.column.property === 'recBill'">
            <div style="display: flex;flex-direction: column;justify-content:  center" v-if="scope.row.recBill && scope.row.recBill.length > 0" >
              <span v-for="item in scope.row.recBill.split(',')">{{item}}</span>
            </div>
          </template>
          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">
            <div style="display: flex;flex-direction: column;justify-content: center" v-if="scope.row.costBill && scope.row.costBill.length > 0">
              <span v-for="item in scope.row.costBill.split(',')">{{item}}</span>
            </div>
          </template>
          <template v-else-if="scope.column.property === 'deptId'">
            <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
          </template>
<!--          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">-->
<!--            <div style="display: flex;flex-direction: column;justify-content:  center">-->
<!--              <span v-for="item in scope.row.costBill.split(',')">{{item}}</span>-->
<!--            </div>-->
<!--          </template>-->
<!--          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">-->
<!--            <div style="display: flex;flex-direction: column;justify-content: center">-->
<!--              <span v-for="item in scope.row.costBill.split(',')">{{item}}}</span>-->
<!--            </div>-->
<!--          </template>-->
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

  </div>

</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import {getAction, postAction} from '@/api/manage'
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "saleProfitCheck",
  components: {},
  mixins: [QueryMixins],
  data() {
    return {
      editStartOptions: {
                    disabledDate: time => {
                        if (!this.queryForm.createTimeEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.queryForm.createTimeEnd);
                        }
                    }
                },
      dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'pay_type','pglx','sys_depart'],
      ids: [],
      row: {},
      form: {},
      dialogFormVisibleBatchAudit: false,
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "salesman",
          label: "销售名",
        },
        {
          prop: 'deptId',
          label: '销售组别',
        },
        {
          prop: "recBill",
          label: "应收账单",
        },
        {
          prop: "costBill",
          label: "成本账单",
        },
        {
          prop: "squareNumber",
          label: "方数",
        },
        {
          prop: "squareNumberAverageProfit",
          label: "方数平均利润",
        },
        {
          prop: "isLockOrder",
          label: "是否锁单",
        },
        {
          prop: "profit",
          label: "利润",
        },
        {
          prop: "createTime",
          label: "下单时间",
        },

      ],
      url: {
        list: "file/sale3/getOrderFeecheckListPage",
        // download: "finance/fee/settleCommission/downloadExcel",
        download: "/file/sale3/dcOrderHedui",
      },
      isUsePost:true,

    };
  },
  methods: {
    getUserIsSales() {
      let flag = this.buttonShowList.seeYf;
      console.log('是否可以查看应付页面',flag);
      if (flag) {
          return true;
      } else {
        this.tabs = this.tabs2;
        return false;
      }
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$intoDocPage(orderNo);
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether='0'
      this.queryForm.profitRecheck='0'
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.isWhether='0'
    this.queryForm.profitRecheck='0'
  },
  computed:{
    buttonShowList() {
      return {
        'seeYf':this.$btnIsShow('docpage/modules/BasicForm', '0', '是否可以查看应付页面'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
