<template>
    <el-dialog
            title="修改排柜备注"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


                    <el-form-item prop="paiguiRemark" label="排柜备注" :label-width="formLabelWidth">
                        <el-input v-model="form.paiguiRemark" type="textarea" maxlength="500" show-word-limit></el-input>
                    </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {putAction} from '@/api/manage'

    export default {
        name: 'editPaiguiRemarkDialog',
        props: {
            visible: {
                type: Boolean
            },
            id:{
                type:String,
                default:''
            },
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    paiguiRemark: [
                        {required: true, message: '请输入排柜备注', trigger: 'change'},
                    ],
                },
                url: {
                    //排柜备注
                    updateWarehouseOutPaiguiRemark:'/warehouse/stock/updateWarehouseOutPaiguiRemark',
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.id) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'id',this.id);
                }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        putAction(this.url.updateWarehouseOutPaiguiRemark, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.form.resetFields()
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
