import { render, staticRenderFns } from "./WarehouseContainerLoadAndforkliftWork.vue?vue&type=template&id=33ce21c1&scoped=true&"
import script from "./WarehouseContainerLoadAndforkliftWork.vue?vue&type=script&lang=js&"
export * from "./WarehouseContainerLoadAndforkliftWork.vue?vue&type=script&lang=js&"
import style0 from "./WarehouseContainerLoadAndforkliftWork.vue?vue&type=style&index=0&id=33ce21c1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ce21c1",
  null
  
)

export default component.exports