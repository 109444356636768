<!--带入仓-->
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div style="display:flex;">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="销售">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.salesmanList"
                    :is-need="queryForm.salesmanList"
                    :multiple="true"
                    dict="sys_user_id"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="客户">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.customer"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="收款情况">
                <el-select v-model="queryForm.status" clearable filterable>
                  <el-option value="0" label="未收款"></el-option>
                  <el-option value="1" label="已收款"></el-option>
                  <el-option value="2" label="部分收款"></el-option>
                  <el-option value="9" label="流程中"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="handleReset"
                  icon="reload"
                  style="margin-left: 8px"
              >重置</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="exportExcelPlus(
                      url.downloadExitSalesOceanShipping,
                      '离职销售订单-海运'
                  )"
                  icon="reload"
                  style="margin-left: 8px"
              >导出</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">
        <el-button
                type="primary"
                size="medium"
                @click="updateOrderSalesmanBatch"
                :disabled="this.selectionRows.length ===0 "
                v-if="buttonShowList.plch"
        >批量销售转让
        </el-button>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="65vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="changSales(scope.row)" v-if="buttonShowList.ch"
            >销售转让</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
          </template>
            <template v-else-if="scope.column.property === 'salesmanChangeList' ">
                <div v-if="scope.row.salesmanChangeList">
                    <!-- <span>{{ scope.row.demand }}</span> -->
                    <el-tooltip placement="top">
                        <div slot="content">销售转让明细：<br />
                            <span v-for="item in scope.row.salesmanChangeList">{{item.oldSalesman}} --> {{item.newSalesman}}<br/></span>
                        </div>
                        <el-button class="f-m-l-10" type="text"
                                   @click="handleDetail(scope.row.salesmanChangeList)">详情</el-button>
                    </el-tooltip>
                </div>
            </template>
            <template v-else-if="scope.column.property === 'salesman' ">
                <span>{{scope.row.salesman}}</span>
                <span v-if="scope.row.oldSalesman && scope.row.oldSalesman != null && scope.row.oldSalesman !=''"><br/>{{scope.row.oldSalesman}}(离职)</span>
            </template>

          <template v-else-if="scope.row.recBill && scope.column.property === 'recBill'">
            <div style="display: flex;flex-direction: column;justify-content:  center" v-if="scope.row.recBill && scope.row.recBill.length > 0" >
              <span v-for="item in scope.row.recBill.split(',')">{{item}}</span>
            </div>
          </template>
          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">
            <div style="display: flex;flex-direction: column;justify-content: center" v-if="scope.row.costBill && scope.row.costBill.length > 0">
              <span v-for="item in scope.row.costBill.split(',')">{{item}}</span>
            </div>
          </template>
          <!--          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">-->
          <!--            <div style="display: flex;flex-direction: column;justify-content:  center">-->
          <!--              <span v-for="item in scope.row.costBill.split(',')">{{item}}</span>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">-->
          <!--            <div style="display: flex;flex-direction: column;justify-content: center">-->
          <!--              <span v-for="item in scope.row.costBill.split(',')">{{item}}}</span>-->
          <!--            </div>-->
          <!--          </template>-->
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog title="销售离职转让"
               :visible="visible"
               width="30vw"
               custom-class="c-custom-dialog"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               :before-close="handleClose"
               >
        <el-form ref="salesForm"  label-width="60px" :model="salesForm"   size="medium">
            <el-form-item label="业务员">
                <f-select
                        v-model="salesForm.salesmanId"
                        :isNeed="salesForm.salesmanId"
                        :dict="'salesmanId'" @changet="e=> salesForm.salesman = e">
                </f-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="visible = false; salesForm={}"
            >取 消
            </el-button
            >
            <el-button size="medium" type="primary" @click="updateOrderSalesman"
            >确 定
            </el-button>
        </div>
    </el-dialog>
      <el-dialog title="销售离职转让明细" :visible.sync="dialogTableVisible">
          <el-table :data="salesChangeData">
              <el-table-column property="orderNo" label="订单号" width="200"></el-table-column>
              <el-table-column property="createTime" label="转让日期" width="150"></el-table-column>
              <el-table-column property="oldSalesman" label="原销售名" width="200"></el-table-column>
              <el-table-column property="newSalesman" label="新销售名" width="200"></el-table-column>
          </el-table>
      </el-dialog>
  </div>

</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import {getAction, postAction} from '@/api/manage'
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "Potential",
  components: {},
  mixins: [QueryMixins],
  data() {
    return {
      disableMixinMounted : true,
      salesChangeData:[],
      dialogTableVisible:false,
      visible:false,
      salesmanList:[],
      dictCodes: ["salesmanId"],
      ids: [],
      salesForm:{},
      row: {},
      form: {},
      dialogFormVisibleBatchAudit: false,
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
              {
          prop: "action",
          label: "操作",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
              prop: "salesmanChangeList",
              label: "销售转让明细",
        },
        {
          prop: "salesman",
          label: "业务",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "solicitation",
          label: "揽货方式",
        },
        {
          prop: "salesmanCommission",
          label: "是否发放",
        },
        {
          prop: "createTime",
          label: "下单时间",
        },
        {
          prop: "commission",
          label: "提成性质",
        },
        {
          prop: "channelName",
          label: "业务性质",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "cmb",
          label: "收款数量(体积)",
        },
        {
          prop: "realCmb",
          label: "实际收款数量(体积)",
        },
        {
          prop: "kg",
          label: "收款数量(重量)",
        },
        {
          prop: "realKg",
          label: "实际收款数量(重量)",
        },
        {
          prop: "feename",
          label: "费用名",
        },
        {
          prop: "quantity",
          label: "费用数量",
        },
        {
          prop: "status",
          label: "收款情况",
        },

      ],
      url: {
        list: "finance/fee/settleCommission/getOceanShippingListPage",
        salesmanChange: "/interface/ttOrder/salesmanChange",
        downloadExitSalesOceanShipping: '/file/sale3/dcOceanShipping'
      },
      isUsePost:true,

    };
  },
  methods: {
      handleDetail(row){
          this.salesChangeData = row
          this.dialogTableVisible = true
      },
      // 订单销售批量转让
      updateOrderSalesmanBatch(){
          this.visible = true
          this.salesForm = {}
          this.salesForm.orderNoList = this.selectedRowOrderNos
      },
      updateOrderSalesman(){
          console.log(this.salesForm)
          postAction(this.url.salesmanChange,this.salesForm).then(res=>{
              this.visible = false
              this.$message.success(res.message);
              this.handleQuery();
          })
      },
      handleClose() {
          // this.$refs.form.resetFields()
          this.visible = false;

      },
    changSales(row){
        this.salesForm = {}
        this.salesForm.orderNoList = [row.orderNo]
        this.visible = true
    },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$intoDocPage(orderNo);
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether='0'
      this.queryForm.profitRecheck='0'
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.isWhether='0'
    this.queryForm.profitRecheck='0'
  },
  computed:{
    buttonShowList() {
      return {
        'plch':this.$btnIsShow('sale/exitSales/OceanShipping','0','批量销售转让'),
        'ch':this.$btnIsShow('sale/exitSales/OceanShipping','0','销售转让'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
