<template>
  <!-- 统计弹框 -->
    <el-dialog title="统计数据显示"
               :visible="visible"
               :close-on-click-modal="false"
               :before-close="handleClose"
               >
        <f-table
                ref="kdCountTb"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columnsCount"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="60vh">
        </f-table>
    </el-dialog>

<!--  <el-dialog-->
<!--    :title="title"-->
<!--    :visible="visible"-->
<!--    width="38%"-->
<!--    center-->
<!--    :before-close="handleClose"-->
<!--    :close-on-click-modal="false"-->
<!--  >-->
<!--    <f-table-->
<!--      v-loading="loading"-->
<!--      ref="GTable"-->
<!--      border-->
<!--      size="medium"-->
<!--      row-key="id"-->
<!--      class="c-th-has-bgcolor"-->
<!--      :columns="columns"-->
<!--      :data="tableData"-->
<!--      :cell-style="{ 'text-align': 'center' }"-->
<!--      @selection-change="handleSelectionChange"-->
<!--      @sort-change="handleTableChange"-->
<!--    >-->
<!--      <template v-slot="scope">-->
<!--        <template v-if="scope.column.property === 'cooperationType'">-->
<!--          <span>{{-->
<!--            parseDict(scope.row.cooperationType, "cooperation_type")-->
<!--          }}</span>-->
<!--        </template>-->
<!--        <template v-else-if="scope.column.property === 'moneyType'">-->
<!--          {{-->
<!--            scope.row.moneyType == "total" ? "折合汇总CNY" : scope.row.moneyType-->
<!--          }}-->
<!--        </template>-->
<!--        <template v-else-if="scope.column.property === 'claimMoney'">-->
<!--          {{-->
<!--            scope.row.includesE-->
<!--              ? toNonExponential(Number(scope.row.claimMoney))-->
<!--              : scope.row.claimMoney-->
<!--          }}-->
<!--        </template>-->
<!--        <template v-else-if="scope.column.property === 'realClaimMoney'">-->
<!--        </template>-->
<!--        <template v-else-if="scope.column.property === 'settledMoney'">-->
<!--        </template>-->
<!--        <template v-else-if="scope.column.property === 'unSettleMoney'">-->
<!--        </template>-->
<!--      </template>-->
<!--    </f-table>-->
<!--    &lt;!&ndash; <el-row class="f-text-right f-p-v-8">-->
<!--        <el-pagination-->
<!--          v-bind="ipaginationSelect"-->
<!--          layout="total, sizes, prev, pager, next, jumper"-->
<!--          @size-change="handleSizeChangeSelect"-->
<!--          @current-change="handleCurrentChangeSelect"-->
<!--        >-->
<!--        </el-pagination>-->
<!--      </el-row> &ndash;&gt;-->
<!--    &lt;!&ndash; <div slot="footer" class="dialog-footer">-->
<!--      <el-button size="medium" @click="handleClose">取 消</el-button>-->
<!--      <el-button size="medium" type="primary">确 定 </el-button>-->
<!--    </div> &ndash;&gt;-->
<!--  </el-dialog>-->
</template>

<script>
import {getAction, postAction} from "@/api/manage";
import it from "element-ui/src/locale/lang/it";
export default {
  name: "StatisticsDialog",
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
      params:{
        type:Object,
          default:()=>{}
      }
  },

  watch: {
    visible(val) {
      if (val) {
        this.init();
      }
    },
  },
  data() {
    return {
      form: {},
      rules: {},
      tableData: [],
      formLabelWidth: "150px",
      countNumList: [],
      loading: false,
      columns: [
        {
          prop: "moneyType",
          label: "币种",
          width: 139,
          fixed: "left",
        },
        {
          prop: "claimMoney",
          label: "索赔金额",
          width: 140,
          fixed: "left",
        },
        {
          prop: "realClaimMoney",
          label: "实际赔付金额",
          width: 140,
          fixed: "left",
        },
        {
          prop: "settledMoney",
          label: "已销账金额",
          width: 140,
          fixed: "left",
        },
        {
          prop: "unSettleMoney",
          label: "未销账金额",
          width: 140,
          fixed: "left",
        },
      ],
      url: {
        getStaticsResult: "/file/claim/getStaticsResult",
      },
        columnsCount: [
            {
                prop: 'suopei',
                label: '索赔金额',
            },
            {
                prop: 'shijipeifu',
                label: '实际赔付金额',
            },
            {
                prop: 'weixiaozhang',
                label: '未销账金额',
            },

            {
                prop: 'yixiaozhang',
                label: '已销账金额',
            },
        ],
    };
  },
  methods: {
    handleTableChange() {},
    handleSelectionChange() {},
    handleClose() {
      this.tableData = [];
      // this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
      qaq(obj,itemName,itemVal){
          if (obj[itemName] && obj[itemName].length) {
              obj[itemName].forEach((item, index) => {
                  if (this.tableData[index]) {
                      this.tableData[index][itemVal] = item || '';
                  } else {
                      let o = {};
                      o[itemVal] = item || '';
                      this.tableData.push(o);
                  }
              })
          }
      },
    init() {
      this.loading = true;
      if (this.params) {
          this.params.type = this.type;
      }
      let form = JSON.parse(JSON.stringify(this.params))
        if (form) {
            for (let item in form) {
                if (typeof form[item] === 'string') {
                    form[item] = form[item].trim()
                    if (form[item] === '') {
                        form[item] = null
                    }
                }
            }
        }
      postAction(this.url.getStaticsResult, form).then((res) => {
        console.log(res, "ress");
        if (res.success) {
          this.loading = false;
          if (res.result) {
            // this.tableData = res.result;
            // this.tableData.forEach((item) => {
            //   if (item.claimMoney.includes("E")) {
            //     console.log(666);
            //     item.includesE = true;
            //   }
            // });
            // this.$message.success(res.message);
            // console.log(this.tableData, "this.tableData");
            // console.log(res, "res");
            // // this.form.claimNo = res.result;
              this.qaq(res.result,'索赔金额','suopei');
              this.qaq(res.result,'实际赔付金额','shijipeifu');
              this.qaq(res.result,'未销账金额','weixiaozhang');
              this.qaq(res.result,'已销账金额','yixiaozhang');


          } else {
          }
        } else {
          this.loading = false;
          this.$message.error(res.message);
        }
      }).catch(err=>{
          this.loading = false;
          this.handleClose()
      });
    },
    toNonExponential(num) {
      let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
      return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    width: 67px !important;
  }
  .el-form-item__content {
    margin-left: 67px !important;
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }
  .el-table__fixed::before,
  .el-table__fixed-right::before {
    width: 0;
  }
  // .el-table__fixed,
  // .el-table__fixed-right {
  //   overflow-y: scroll !important;
  // }
  .el-table__fixed-body-wrapper {
    height: 240px !important;
    overflow: scroll !important;
  }
  .el-table {
    height: 300px !important;
    // overflow-x: scroll !important;

    // min-width: 55px !important;

    // &::before {
    //   height: 0;
    // }
  }
  // .el-table {
  //   height: 400px !important;
  //   // overflow-x: scroll !important;

  //   // min-width: 55px !important;
  // }
  // .el-table__fixed-body-wrapper {
  //   height: 200px;
  //   // overflow-x: hidden;
  //   // overflow-y: scroll;
  //   overflow: scroll;
  //   // overflow-y: scroll !important;
  // }
}
</style>
