<template>
  <el-dialog
    title="操作"
    :visible="visible"
    :before-close="handleCancel"
    :close-on-click-modal="false"
  >
    <template slot="footer">
      <el-button type="primary" size="medium" @click="checkAddress"
        >校验地址</el-button
      >
      <el-button @click="handleCancel" size="medium" style="margin-left: 15px"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="medium"
        style="margin-left: 15px"
        @click="handleOk"
        >确定
      </el-button>
    </template>

    <el-form
      :model="form"
      label-width="100px"
      ref="form"
      :rules="validatorRules"
      v-loading="confirmLoading"
    >
      <div class="title_box">发货国家</div>

      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="国家"
        prop="fromCountry"
      >
        <f-select
          placeholder="请选择国家"
          :dict="'ups_country'"
          filterable
          v-model="form.fromCountry"
          :isNeed="form.fromCountry"
          @change="changeCountry"
        >
        </f-select>
      </el-form-item>

      <div class="title_box">收货地址</div>
      <!-- 主表单区域 -->
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="fba地址"
      >
        <el-input
          type="textarea"
          autosize
          v-model="form.fbaAddress"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </el-form-item>
      <!-- <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="公司或名字"
        prop="receiveName"
      >
        <el-input v-model="form.receiveName" />
      </el-form-item> -->
      <el-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="姓名">
        <el-input placeholder="请输入接收方姓名" v-model="form.customerName" />
      </el-form-item>
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="地址行1"
        prop="address1"
      >
        <el-input
          placeholder="请输入地址行1"
          v-model="form.address1"
        ></el-input>
      </el-form-item>
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="地址行2"
      >
        <el-input placeholder="请输入地址行2" v-model="form.address2" />
      </el-form-item>
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="电话"
        prop="telephone"
      >
        <el-input placeholder="请输入电话" v-model="form.phone" />
      </el-form-item>
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="国家"
        prop="country"
      >
        <f-select
          placeholder="请选择国家"
          :dict="'ups_country'"
          @change="handleChange1"
          filterable
          v-model="form.country"
          :fatherVal="form.country"
          :isNeed="form.country"
        >
        </f-select>
      </el-form-item>
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="邮编"
        prop="zip"
      >
        <el-select clearable filterable allow-create v-model="form.zip">
          <el-option
            v-for="(item, index) in zipMapList"
            :index="item"
            :label="item"
            :value="item"
            :key="index"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="城市"
        prop="city"
      >
        <el-select clearable filterable allow-create v-model="form.city">
          <el-option
            v-for="(item, index) in cityMapList"
            :index="item"
            :label="item"
            :value="item"
            :key="index"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="州/县"
        prop="state"
      >
        <el-select clearable filterable allow-create v-model="form.state">
          <el-option
            v-for="(item, index) in stateMapList"
            :index="item"
            :label="item"
            :value="item"
            :key="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="建议地址"
        v-if="deftshipStatus == 1"
      >
        <el-input
          type="textarea"
          v-model="deftshipContent"
          :auto-size="{ minRows: 2, maxRows: 4 }"
        />
      </el-form-item>
      <el-form-item :labelCol="labelCol" :wrapperCol="wrapperCol">
        <el-button
          type="primary"
          v-if="deftshipStatus == 1"
          @click="useDeftshipAddress"
          >使用建议地址
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import { addressEnum } from "../api/Ups";
// import pick from "lodash/pick";
// import { setUpRouteUtil } from "@/utils/util";

export default {
  name: "upsPushAddress",
  components: {},
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      orderMainModel: {
        jeecgOrderCustomerList: [{}],
        jeecgOrderTicketList: [{}],
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      // dataSource: [],
      zipDataSource: [],
      cityDataSource: [],
      stateDataSource: [],
      // usernameValue: "",
      confirmLoading: false,
      form: {
        zip: "",
        country: "",
        cargoArrivedType: "",
        orderNo: "",
        addressId: "",
        address1: "",
        address2: "",
        transportStatus: "",
        city: "",
        operateId: "",
        solicitation: "",
        solicitation_dictText: "",
        enLogno: "",
        phone: "",
        lb: "",
        fbaAddress: "",
        // id: "",
        state: "",
        ctn: "",
        operateName: null,
        orderNoList: null,
        upsShipStatus: null,
        transportStatus_dictText: "",
        customerName: "",
        // receiveName: "",
        fbaCode: "",
      },
      // address: [],
      // selectData: [],
      // Data: [],
      // email: String,
      // dict: String,
      // orderNo: String,
      // dictOptions: Array,
      // selectedValue: String,
      // fbaAddress: "",
      // CheckAddressMap: [],
      // CheckAddressList: [],
      zipMapList: [], //zip筛选后的数据
      cityMapList: [],
      stateMapList: [],
      deftshipStatus: 0,
      useDeftshipAddressData: [],
      deftshipContent: null,
      validatorRules: {
        receiveName: [
          { required: true, message: "公司或名字不可为空", trigger: "blur" },
        ],
        country: [
          { required: true, message: "国家不可为空", trigger: "blur" },
          {
            pattern: /^[a-zA-Z]+$/,
            message: "国家只能输入二字码，请重新输入",
            trigger: "change",
          },
        ],
        state: [{ required: true, message: "请选择州/省", trigger: "blur" }],
        city: [{ required: true, message: "城市不可为空", trigger: "blur" }],
        zip: [{ required: true, message: "邮编不可为空", trigger: "blur" }],
        address1: [
          { required: true, message: "地址栏1不可为空", trigger: "blur" },
        ],
      },
      url: {
        add: "/test/order/add",
        edit: "/test/order/edit",
        orderCustomerList: "/test/order/listOrderCustomerByMainId",
        orderTicketList: "/test/order/listOrderTicketByMainId",
        // saveOrderAddress: "/interface/label/saveOrderAddress",
        //保存校验地址
        saveOrderAddress: "/interface/labelCenter/saveOrderAddress",
        // selecAddressList: "/interface/label/selecAddressList",
        //查询校验地址
        selecAddressList: "/interface/labelCenter/selectOrderAddress",
        // selecCheckAddress: "/interface/label/selecCheckAddress",
        // DeftShipCheckAddress: "/interface/label/DeftShipCheckAddress2",
        //校验地址
        DeftShipCheckAddress: "/interface/labelCenter/checkAddress",
      },
      tempAddress: "",
    };
  },
  watch: {
    visible(val) {
      console.log(val);
      if (val) {
        console.log(this.row, "row");
        this.edit();
      }

      // if (this.row && JSON.stringify(this.row) !== "{}") {
      //   for (var name in this.row) {
      //     this.stateForm[name] = this.row[name];
      //   }
      // } else {
      //   this.stateForm.countryId = "";
      //   this.stateForm.name = "";
      //   this.stateForm.cname = "";
      //   this.stateForm.code = "";
      // }
    },
  },
  created() {},
  methods: {
    // intoDocpage(orderNo) {
    //   // 对于路由不在左侧菜单中的选中菜单处理
    //   setUpRouteUtil(this.$route.path, "/docpage/Edit");
    //   this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    // },
    // validateCountry(rule, value, callback) {
    //   if (!value || new RegExp(/^[a-zA-Z]+$/).test(value)) {
    //     callback();
    //   } else {
    //     callback("国家只能输入二字码，请重新输入。");
    //   }
    // },
    // initCheckAddressList() {
    //   let that = this;
    //   let url = this.url.selecCheckAddress,
    //     method = "post".toString();
    //   postAction(url.toString(), {}).then((res) => {
    //     if (res.success) {
    //       var map = new Map();
    //       for (var i = 0; i < res.result.list.length; i++) {
    //         that.dataSource.push(res.result.list[i].userName);
    //         map.set(res.result.list[i].userName, res.result.list[i]);
    //       }
    //       that.CheckAddressMap = map;
    //       that.CheckAddressList = res.result.list;
    //     }
    //   });
    // },
    // handleSearch(value) {
    //   let that = this;
    //   that.dataSource = [];
    //   for (let i = 0; i < that.CheckAddressList.length; i++) {
    //     if (that.CheckAddressList[i].userName.indexOf(value) != -1) {
    //       that.dataSource.push(that.CheckAddressList[i].userName);
    //     }
    //   }
    // },
    handleChange1(country) {
      let that = this;
      //如果字典项集合有数据
      addressEnum(country).then((res) => {
        if (res.success) {
          var zipMap = new Map();
          var cityMap = new Map();
          var stateMap = new Map();
          for (var i = 0; i < res.result.list.length; i++) {
            if (!zipMap.has(res.result.list[i].zipText)) {
              that.zipDataSource.push(res.result.list[i].zipText);
              // 邮编
              that.zipMapList.push(res.result.list[i].zipText);
              zipMap.set(
                res.result.list[i].zipText,
                res.result.list[i].zipText
              );
            }

            if (!cityMap.has(res.result.list[i].cityText)) {
              that.cityDataSource.push(res.result.list[i].cityText);
              //城市
              that.cityMapList.push(res.result.list[i].cityText);
              cityMap.set(
                res.result.list[i].cityText,
                res.result.list[i].cityText
              );
            }
            //州、县
            if (!stateMap.has(res.result.list[i].stateText)) {
              that.stateDataSource.push(res.result.list[i].stateText);
              //城市
              that.stateMapList.push(res.result.list[i].stateText);
              stateMap.set(
                res.result.list[i].stateText,
                res.result.list[i].stateText
              );
            }
          }
          // console.log(that.zipDataSource.length, "that.zipDataSource");
          // console.log(that.zipMapList.length, "that.zipMapList");
          // this.Data = res.result.list;
        }
      });
    },
    // add() {
    //   this.edit({});
    // },
    edit() {
      let that = this;
      if (this.row.shipmentType === "0") {
        let str =
          "Amazon.com," +
          (this.row.address2 === null ? "" : this.row.address2 + ",") +
          (this.row.address1 === null ? "" : this.row.address1 + ",") +
          (this.row.city === null ? "" : this.row.city) +
          "," +
          (this.row.state === null ? "" : this.row.state) +
          "," +
          (this.row.zip === null ? "" : this.row.zip) +
          "," +
          (this.row.country === null ? "" : this.row.country) +
          "(" +
          (this.row.fbaCode === null ? "" : this.row.fbaCode) +
          ")";
        that.$set(that.form, "fbaAddress", str );
      } else {
        that.$set(that.form, "fbaAddress",this.row.fbaAddress);
      }

      that.form.orderNo = that.row.orderNo;
      let url = that.url.selecAddressList,
        method = "POST";
      postAction(url, { orderNo: that.row.orderNo }, { ClosePrompt: true })
        .then((res) => {
          if (res.success) {
            that.orderMainModel = Object.assign({}, res.result);
            that.orderMainModel.fbaCode = that.row.fbaCode;
            // that.orderMainModel.address = res.result.fbaAddress;
            that.$set(that.form, "address1",that.orderMainModel.address1);
            that.$set(that.form, "address2",that.orderMainModel.address2);
            // that.form.country = that.orderMainModel.country;
            // that.form.country = that.orderMainModel.twoCode;
            if (that.orderMainModel.fbaCode) {
              // that.form.customerName = that.orderMainModel.fbaCode;
              that.$set(that.form, "customerName",that.orderMainModel.fbaCode);
            } else {
              // that.form.customerName = that.orderMainModel.receiveName;
              that.$set(that.form, "customerName",that.orderMainModel.receiveName);
            }
            // that.form.city = that.orderMainModel.city;
            that.$set(that.form, "city",that.orderMainModel.city);
            // that.form.zip = that.orderMainModel.zip;
            that.$set(that.form, "zip",that.orderMainModel.zip);
            // that.form.state = that.orderMainModel.state;
            that.$set(that.form, "state",that.orderMainModel.state);
            // that.form.phone = that.orderMainModel.phone;
            that.$set(that.form, "phone",that.orderMainModel.phone);
          }
          // this.initCheckAddressList();
        })
        .catch(() => {
          if (that.row.fbaCode) {
            that.$set(that.form, "customerName",that.row.fbaCode);
          } else {
            that.$set(that.form, "customerName",that.row.customerName);
          }
          that.$set(that.form, "address1",that.row.address1);
          that.$set(that.form, "address2",that.row.address2);
          that.$set(that.form, "phone",that.row.phone);
          that.$set(that.form, "city",that.row.city);
          that.$set(that.form, "zip",that.row.zip);
          that.$set(that.form, "state",that.row.state);
        })
        .finally(() => {
          if (that.orderMainModel.twoCode) {
            that.$set(that.form, "country",that.orderMainModel.twoCode);
          } else if (that.row.twoCode) {
            that.$set(that.form, "country",that.row.twoCode);
          }
        });
    },
    //页面关闭
    close() {
      // this.$emit("close");
      this.useDeftshipAddressData = [];
      this.deftshipStatus = 0;
      this.$emit("update:visible", false);
      // this.visible = false;
      this.$refs.form.clearValidate();
      // console.log(this.$refs.form.clearValidate, "this.$refs.form");
    },
    //使用建议地址
    useDeftshipAddress() {
      if (this.useDeftshipAddressData) {
        this.orderMainModel.address1 = this.useDeftshipAddressData.street_1;
        this.orderMainModel.address2 = this.useDeftshipAddressData.street_2;
        this.orderMainModel.zip = this.useDeftshipAddressData.zip;
        this.orderMainModel.country = this.useDeftshipAddressData.country;
        this.orderMainModel.state = this.useDeftshipAddressData.state;
        this.orderMainModel.city = this.useDeftshipAddressData.city;

        this.form.country = this.orderMainModel.country;
        this.form.address1 = this.orderMainModel.address1;
        this.form.address2 = this.orderMainModel.address2;
        this.form.zip = this.orderMainModel.zip;
        this.form.city = this.orderMainModel.city;
        this.form.state = this.orderMainModel.state;
      }
    },
    //校验地址
    checkAddress() {
      this.$refs["form"].validate((err, values) => {
        if (err) {
          if (this.form.zip.indexOf(",") != -1) {
            this.form.zip = this.form.zip.substring(
              this.form.zip.indexOf(",") + 1,
              this.form.zip.length
            );
          }
          if (this.form.city.indexOf(",") != -1) {
            this.form.city = this.form.city.replace(",", "");
          }
          if (this.form.state.indexOf(",") != -1) {
            this.form.state = this.form.state.substring(
              this.form.state.indexOf(",") + 1,
              this.form.state.length
            );
          }
          let url = this.url.DeftShipCheckAddress,
            method = "post";
          this.form.receiveName = this.form.customerName;
          // delete this.form.customerName;
          this.confirmLoading = true;
          console.log(this.form, "this.form");

          postAction(url, this.form, { timeout: 1000 * 60 * 2 })
            .then((res) => {
              if (res.success) {
                console.log(res);
                this.useDeftshipAddressData = res.result;
                let ob = res.result;
                console.log(ob);
                if (ob.type == "no_candidates") {
                  this.$message.success("该地址暂无推荐。");
                } else {
                  if (ob.street_1 != null) {
                    this.deftshipStatus = 1;
                    //this.deftshipContent = ob.address.addressLine + "， " + ob.address.region + "，  " + ob.address.countryCode
                    this.deftshipContent =
                      ob.street_1 +
                      ", " +
                      ob.street_2 +
                      ", " +
                      ob.zip +
                      ", " +
                      ob.city +
                      ", " +
                      ob.state +
                      ", " +
                      ob.country;
                  }
                }
                this.$message.success("操作成功");
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },

    handleOk() {
      const that = this;
      // 触发表单验证
      this.$refs["form"].validate((err, values) => {
        if (err) {
          if (that.form.zip.indexOf(",") != -1) {
            that.form.zip = that.form.zip.substring(
              that.form.zip.indexOf(",") + 1,
              that.form.zip.length
            );
          }
          if (that.form.city.indexOf(",") != -1) {
            that.form.city = that.form.city.substring(
              that.form.city.indexOf(",") + 1,
              that.form.city.length
            );
          }
          if (that.form.state.indexOf(",") != -1) {
            that.form.state = that.form.state.substring(
              that.form.state.indexOf(",") + 1,
              that.form.state.length
            );
          }

          that.confirmLoading = true;
          // let orderMainData = this.form;

          let url = this.url.saveOrderAddress,
            method = "post";
          this.confirmLoading = true;
          values.orderNo = this.orderNo;
          values.checkType = "2";
          that.form.receiveName = that.form.customerName;
          // delete that.form.customerName;
          postAction(url, that.form)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.$emit("ok");
                this.close();
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.form = {}
      this.close();
    },
    changeCountry(e){
      if(e&&e==='DE'){
        this.validatorRules.state[0].required=false
      }else{
        this.validatorRules.state[0].required=true
      }
    }
    // updateAddress(username) {
    //   let address = this.CheckAddressList.filter(
    //     (item) => item.customerName === this.form.username
    //   )[0];
    //   if (address !== undefined) {
    //     this.form.state = address.state;
    //     this.form.city = address.city;
    //     this.form.zip = address.postCode;
    //     this.form.phone = address.telephone;
    //     this.form.address1 = address.address1;
    //   }
    // },
  },
};
</script>

<style scoped>
.ant-btn {
  padding: 0 10px;
  margin-left: 3px;
}

.title_box {
  padding-left: 46px;
  font-size: 13px !important;
  font-weight: bold;
  margin-bottom: 15px;
}

.ant-form-item-control {
  line-height: 0px;
}

/** 主表单行间距 */
.ant-form .ant-form-item {
  margin-bottom: 10px;
}

/** Tab页面行间距 */
.ant-tabs-content .ant-form-item {
  margin-bottom: 0px;
}
</style>
