import { render, staticRenderFns } from "./EditCabinetTypeDialog.vue?vue&type=template&id=53d6df24&scoped=true&"
import script from "./EditCabinetTypeDialog.vue?vue&type=script&lang=js&"
export * from "./EditCabinetTypeDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d6df24",
  null
  
)

export default component.exports