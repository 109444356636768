<template>
  <already-cabinet></already-cabinet>
</template>

<script>
import AlreadyCabinet from "@/views/operation/cabinet/AlreadyCabinet";
export default {
  name: "docAlreadyCabinet",

  components: {AlreadyCabinet},

};
</script>

<style lang="scss" scoped>
</style>
