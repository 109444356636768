<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row type="flex" align="middle">
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="客户名称" label-width="120px">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch" type="textarea"
                                      placeholder="多个用,分隔"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="邮箱" label-width="120px">
                            <el-input v-model="queryForm.email" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="联系电话" label-width="120px">
                            <el-input v-model="queryForm.phone" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="添加人" label-width="120px">
                            <f-select v-model="queryForm.createBy" :isNeed="queryForm.createBy" :dict="'sys_user'"></f-select></el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="source" label="客户来源" label-width="120px">
                            <f-select class="f-full-width" v-model="queryForm.source" :isNeed="queryForm.source" :dict="'cus_source'"></f-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item prop="isShiqu" label="是否属于流失客户" label-width="120px">
                            <f-select class="f-full-width" v-model="queryForm.isShiqu" :isNeed="queryForm.isShiqu" :dict="'is_default'"></f-select>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="6">-->
<!--                        <el-form-item prop="isPushStr" label="是否属于推广注册" label-width="120px">-->
<!--                            <f-select class="f-full-width" v-model="queryForm.isPushStr" :isNeed="queryForm.isPushStr" :dict="'is_default'"></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleAdd"
                    v-if="buttonShowList.add"
                >新增</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleAddPush"
                    v-if="buttonShowList.tgzc"
                >推广注册</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    @click="handleBatAssign"
                    :disabled="selectionRows.length === 0"
                    v-if="buttonShowList.fpB"
                >批量分配</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    class="c-theme-error-button"
                    @click="handleBatchDel"
                    :disabled="selectionRows.length === 0"
                    v-if="buttonShowList.delB"
                >批量删除</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-download"
                    @click="handleExportXls"
                    v-if="buttonShowList.dc"
                >导出</el-button>
              <el-button
                  type="primary"
                  size="medium"
                  @click="downloadModel(url.downloadCustomerModel, '下载推广账户模板')"
                  style="margin-left: 15px"
              >推广账户模板</el-button>
              <el-upload
                  class="upload-demo"
                  style="margin-left: 15px"
                  action=""
                  :http-request="handleImport"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  accept=".xls,.xlsx"
              >
                <el-button
                    type="primary"
                    size="medium"
                    v-if="buttonShowList.drtgzhB"
                >批量导入推广账户</el-button>
              </el-upload>

            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="63vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                            type="text"
                            @click="handleEdit(scope.row)"
                            v-if="buttonShowList.edit"
                        >编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleDel(scope.row)"
                            v-if="buttonShowList.del"
                        >删除
                        </el-button>
                        <el-button
                            type="text"
                            @click="handlAssign(scope.row)"
                            v-if="buttonShowList.fp"
                        >分配
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'source'">
                        <span>{{parseDict(scope.row.source,'cus_source')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'username'">
                        <a type="text" @click.prevent="handleEdit(scope.row)" style="color:#57a3f3;">{{scope.row.username}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'hasSendEmail'">
                        <span v-if="scope.row.sendEmailSixMonthNum>0" style="color: red">属于</span>
                        <span v-else>不属于</span>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'isPush'">-->
<!--                        <span v-if="scope.row.isPush===1" style="color: red">属于</span>-->
<!--                        <span v-else>不属于</span>-->
<!--                    </template>-->
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


        <assign-dialog :visible.sync="assignVisible" :ids="ids" :title="assignTitle"></assign-dialog>
        <add-customer-dialog :visible.sync="visible" @ok="handleSearch"></add-customer-dialog>
    </div>
</template>

<script>
import {deleteAction, postAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"
    import AddCustomerDialog from './modules/AddCustomerDialog'
    import AssignDialog from './modules/AssignDialog'
    import {getAction} from "../../api/manage";
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        name: 'Undetermined',
        mixins: [QueryMixins],
        components: {
            AssignDialog,
            AddCustomerDialog,
        },
        data() {
            return {
                dictCodes: ['cus_source'],
                namelist: [],
                row: {},
                ids: [],
                visible: false,
                assignVisible: false,
                assignTitle: '',
                salesmanlist: [],
                userlist: [],

                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'username',
                        label: '客户名称',
                        sortable: 'custom',
                    },
                    {
                        prop: 'hasSendEmail',
                        label: '是否属于流失客户',
                        headerType:'remark',
                        detail:'超过六个月未下单的客户',
                    },
                    // {
                    //     prop: 'isPush',
                    //     label: '是否属于推广注册',
                    //     headerType:'remark',
                    //     detail:'通过推广注册新增的客户',
                    // },
                    {
                        prop: 'name',
                        label: '联系人',
                        sortable: 'custom',
                    },
                    {
                        prop: 'email',
                        label: '邮箱',
                        sortable: 'custom',
                    },
                    {
                        prop: 'phone',
                        label: '联系电话',
                        sortable: 'custom',
                    },
                    {
                        prop: 'need',
                        label: '需求',
                        sortable: 'custom',
                    },
                    {
                        prop: 'source',
                        label: '客户来源',
                        sortable: 'custom',
                    },
                    {
                        prop: 'createBy',
                        label: '添加人',
                        sortable: 'custom',
                    },
                    {
                        prop: 'workName',
                        label: '邀请人',
                        sortable: 'custom',
                    },
                    {
                        prop: 'createTime',
                        label: '注册时间',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: "remark",
                        label: "备注",
                        overFlow: true
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 135,
                    },
                ],
                url: {
                    list: '/customer/customerList',
                    exportXlsUrl: '/customer/exportGhXls',
                    deleteBatch: '/customer/deleteBatch',
                    delete: '/customer/delete',
                    getbyid: '/customer/getById',
                    importPushCustomer:'/file/read/excel/importPushCustomer',
                    downloadCustomerModel:'/file/read/excel/pushCustomerExcel',

                }
            }
        },
        created() {


        },
        methods: {
          handleImport(data){
            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("dir", 'customer');
            postAction(this.url.importPushCustomer, formData).then((res) => {
              this.$message(res.message,"success");
              this.handleQuery();
            });
          },
          beforeUpload(file) {
            //对文件大小进行限制
            const isLt2M = file.size / 1024 / 1024 < 15;
            if (!isLt2M) {
              this.$message.warning("文件大小不能超过15MB");
            }
            const isFile = file.name.indexOf(".") !== -1;
            if (!isFile) {
              this.$message.warning("该文件名不合法");
            }
            return isLt2M && isFile;
          },
            handleSelectionChange(selection) { // 多选
                let arr = [];
                this.namelist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.namelist.push(selection[i]['username'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit`);
            },
            //点击推广注册
            handleAddPush() {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?source=33`);
            },
            handleEdit({id}) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?id=${id}`)
            },
            handlAssign({id}) {
                //校验客户信息是否完善
                //     let flag = await this.checkCus(id);
                //     console.log('校验结果',flag);
                //     if (!flag){
                //         this.$message.warning('该客户信息不完善，请完善之后再来');
                //         return false;
                //     }
                this.ids = [id]
                this.assignTitle = '分配'
                this.assignVisible = true
            },
            async checkCus(id) {
                let flag = null;
                let res = await getAction(this.url.getbyid, {id})
                console.log('获取await结果', res)
                if (res.success) {
                    let obj = res.result;
                    console.log('客户对象', obj);
                    if (!obj.source || !obj.address || !obj.username || !obj.phone || !obj.email
                        || !obj.regType || !obj.grade || !obj.userType || !obj.commission || !obj.haveContract
                        || !obj.bdCountry || !obj.bdCity || !obj.bdState) {
                        flag = false;
                    } else {
                        flag = true;
                    }
                }
                console.log('checkus校验结果', flag);
                return flag;

            },
            handleDel({id, username}) {
                this.$confirm(`确定删除${username}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleBatchDel() {
                this.$confirm(`确定批量删除${this.namelist.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteBatch + "?ids=" + this.selectedRowKeys.join(','), {ids: this.selectedRowKeys}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleBatAssign() {
                this.assignTitle = '批量分配'
                this.ids = this.selectedRowKeys
                // for (var i=0;i<this.ids.length;i++){
                //     let id = this.ids[i];
                //     let flag = await this.checkCus(id);
                //     console.log('校验结果',flag);
                //     if (!flag){
                //         this.$message.warning('该客户信息不完善，请完善之后再来');
                //         return false;
                //     }
                // }
                this.assignVisible = true
            },

        },
      computed : {
        buttonShowList() {
          return {
            'add':this.$btnIsShow('customer/Undetermined','0','新增'),
            'tgzc':this.$btnIsShow('customer/Undetermined','0','推广注册'),
            'fpB':this.$btnIsShow('customer/Undetermined','0','批量分配'),
            'delB':this.$btnIsShow('customer/Undetermined','0','批量删除'),
            'dc':this.$btnIsShow('customer/Undetermined','0','导出'),
            'drtgzhB':this.$btnIsShow('customer/Undetermined','0','批量导入推广账户'),
            'edit':this.$btnIsShow('customer/Undetermined','1','编辑'),
            'del':this.$btnIsShow('customer/Undetermined','1','删除'),
            'fp':this.$btnIsShow('customer/Undetermined','1','分配'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
