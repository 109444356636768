<template>
    <el-dialog
            title="顺延"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


            <el-form-item prop="loadingTime" label="装柜时间" :label-width="formLabelWidth">
                <el-date-picker
                        class="f-flex-1"
                        v-model="form.loadingTime"
                        type="datetime"
                        size="small"
                        placeholder="装柜时间"
                        default-time="09:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {putAction} from '@/api/manage'

    export default {
        name: 'chooseTimeModule',
        props: {
            visible: {
                type: Boolean
            },

            row:{
                type:Object,
                default:()=>{}
            },
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    loadingTime: [
                        {required: true, message: '请选择装柜时间', trigger: 'change'},
                    ],
                },
                url: {
                    batchUpdateUrl:"/warehouse/takeDelivery/batchUpdate",
                    //顺延
                    updateWarehouseOutOrderlyToNextLy:'/warehouse/stock/updateWarehouseOutOrderlyToNextLy',
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.row) {
                    //勾选的订单号进行赋值
                    this.form = JSON.parse(JSON.stringify(this.row));

                }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('确定顺延？此操作将会改变装柜时间', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'info',
                            closeOnClickModal: false,
                        }).then(()=>{
                            putAction(this.url.updateWarehouseOutOrderlyToNextLy, this.form).then((res) => {
                                this.$message({
                                    type: 'success',
                                    message: res.message
                                })
                                this.form = {};
                                this.handleClose();
                                this.$emit('ok');
                            })
                        })

                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
