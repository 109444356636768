<template>
    <div>
        <el-dialog
                title="修改币种及添加账户"
                border
                :visible="changeCurVisible"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="curBankDialogClose">
            <el-form :rules="rules">
                <el-row>
                    <el-col :span="23">
                        <el-form-item prop="cur" label="折合币种">
                            <f-select
                                    v-model="billForm.cur"
                                    :isNeed="billForm.cur"
                                      :dict="'bd_cur'"
                                      placeholder="请选择折合币种"
                                      clearable
                                      filterable
                                      :disabled="curDis"
                                      @change="curChangeBank"
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23">
                        <el-form-item prop="bank" label="银行账户">
                            <el-select v-model="billForm.bankId"
                                       placeholder="根据币种选择银行"
                                       clearable
                                       filterable
                                       class="f-full-width">
                                <el-option v-for="(item) in banks" :value="item.id" :label="item.bankName"
                                           :key="item.id">
                                    {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="changeCurSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
                title="添加费用"
                border
                :visible="addFeeDialogVisible"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleAddFeeDialogClose">
            <el-form label-width="50px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="单号">
                            <el-input v-model="feeOrderNo"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12"><el-button type="primary" @click="addFeesAgain">查找</el-button></el-col>
                </el-row>
            </el-form>
            <f-table
                    :columns="addColumns"
                    :cell-style="{ 'text-align': 'center' }"
                    :data="feesAgain"
                    @selection-change="addSelectionChange"
            ></f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="addSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
                :title="title"
                :visible="visible"
                width="70%"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleClose"
        >
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="right"
                     label-width="109px">
                <el-row>
                    <el-col>
                        <el-form-item label="上传水单" v-if="showOnly" prop="fileList" :rules="type == 'os' ? rules.fileList : []" >
                            <el-upload
                                    class="upload-demo"
                                    :action="url.uploadInvoice"
                                    accept=".jpg,.png,.xls,.xlsx"
                                    :http-request="handleUpload"
                                    :on-preview="handlePicPreview"
                                    :before-upload="beforeUpload"
                                    :on-remove="handleRemove"
                                    :file-list="billForm.fileList"
                                    multiple
                                    list-type="picture">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item prop="payMan" label="付款人">
                            <el-input v-model="billForm.payMan" style="width: 300px" maxlength="50" show-word-limit @input="updatePayMen"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item prop="amount" label="账单金额合计">
                            <el-input v-model="billForm.amount" style="width: 300px"
                                      @blur="changeAmount" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item prop="billMoney" label="水单金额" :rules="rules.billMoney">
                            <el-input v-model="billForm.billMoney" style="width: 300px"
                                      oninput="value=value.match(/[-]?\d*\.?\d{0,3}/)"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col>
                        <el-form-item label="银行流水号">
                            <el-input v-model="billForm.billBankNo" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="水单付款人">
                            <el-input v-model="billForm.billPayerName" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="水单开户银行">
                            <el-input v-model="billForm.billPayerBank" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="水单交易日期">
                            <el-input v-model="billForm.billTradingDate" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="银行交易日期">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="billForm.fVoucherDate"
                                type="datetime"
                                size="small"
                                placeholder="请选择时间"
                                default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="备ㅤ注">
                            <el-input v-model="billForm.remark" style="width: 300px"></el-input>

                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col>
                        <el-button plain @click="addFee" v-if="showOnly">添加费用</el-button>
                        <el-button plain @click="changeCurBank" v-if="showOnly && billForm.bankId ===''" style="border:1px solid #F00">修改币种及添加账户</el-button>
                        <el-button plain @click="changeCurBank" v-if="showOnly && billForm.bankId !==''" style="border:1px solid green">修改币种及添加账户</el-button>
                    </el-col>
                </el-row>

                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor no_margin"
                        :columns="columns"
                        :data="billForm.settleList"
                        :cell-style="{ 'text-align': 'center' }"
                        style="margin-top: 15px"
                        @selection-change="handleSelectionChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">

                            <el-tooltip class="item" effect="dark" content="点击从列表中删除" placement="top">
                                <el-button type="text" @click="delFee(scope.row)" v-if="billForm.settleList.length > 1">删除</el-button>
                            </el-tooltip>
                        </template>
                        <template v-else-if="scope.column.property === 'billMoney'">
                            <el-form-item
                                    :rules="[{required: true, message: '实收不能为空', trigger: 'blur'}]">
                                <el-input type="text" v-model="scope.row.billMoney"
                                          @input="changeAmount" :placeholder="scope.row.waitPay===null?scope.row.exchangeTotal:scope.row.waitPay" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                            </el-form-item>

                        </template>
<!--                        <template v-else-if="scope.column.property === 'payed'">-->
<!--                            <span v-if="scope.row.waitPay !== null">{{ (Number(scope.row.exchangeTotal)-Number(scope.row.waitPay===null?0:scope.row.waitPay)).toFixed(3)}}</span>-->
<!--                            <span v-if="scope.row.waitPay === null">0</span>-->
<!--                        </template>-->
<!--                        <template v-else-if="scope.column.property === 'payed'">-->
<!--                            <span-->
<!--                                v-if="scope.row.partialWriteOff === null || scope.row.partialWriteOff === '0'">0.000</span>-->
<!--                            <span v-if="scope.row.partialWriteOff === '1'">{{(Number(scope.row.exchangeTotal)-Number(scope.row.waitPay===null?0:scope.row.waitPay)).toFixed(3)}}</span>-->
<!--                        </template>-->
                        <template v-else-if="scope.column.property === 'partialWriteOff'">
                            <span
                                v-if="scope.row.partialWriteOff === null || scope.row.partialWriteOff === '0'">否</span>
                            <span v-if="scope.row.partialWriteOff === '1'">是</span>
                        </template>

                      <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="$intoDocPage(scope.row.orderNo); addFeeDialogVisible = false; visible = false">{{ scope.row.orderNo }}</a>
                      </template>

                      <template v-else-if="scope.column.property === 'realAgent'">
                        <a style="color: #00a0e9"
                           @click.prevent="$intoCustomerEditPage(scope.row.cusId); addFeeDialogVisible = false">{{ scope.row.realAgent }}</a>
                      </template>
                    </template>

                </f-table>
            </el-form>

            <span slot="footer">
            <el-button v-if="showOnly" type="primary" size="medium" @click="handleSubmit"
                       :disabled="ifDis">确定申请</el-button>
        </span>
        </el-dialog>
        <!-- 图片预览 -->
        <preview-img :visible="visibleFile" :imgUrl="imgUrl" @setImgVisible="(val) => { visibleFile = val }"></preview-img>
    </div>
</template>

<script>
import {deleteAction, getAction, postAction} from '@/api/manage'
    import {setUpRouteUtil} from "@/utils/util";
    // import QueryMixins from "../../../../mixins/QueryMixins";

    export default {
        name: 'receiveApply',
        // mixins: [QueryMixins],
        props: {
            // 类型，os海运
            type: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
              visibleFile:false,
              imgUrl: '',
                // id:'',
                addColumns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "orderNo",
                        label: "工作单号",
                    },
                    {
                        prop: "realAgent",
                        label: "客户",
                    },
                    {
                        prop: "agent",
                        label: "结算对象",
                    },
                    {
                        prop: "feename",
                        label: "费用中文",
                    },
                    {
                        prop: "feenameUs",
                        label: "费用英文",
                    },
                    {
                        prop: "price",
                        label: "价格",
                    },
                    {
                        prop: "quantity",
                        label: "数量",
                    },
                    {
                        prop: "moneyType",
                        label: "费用币种",
                    },
                    {
                        prop: "total",
                        label: "合计",
                    },
                    {
                        prop: "realMoneyType",
                        label: "折合币种",
                    },
                    {
                        prop: "exchangeRate",
                        label: "汇率",
                    },
                    {
                        prop: "exchangeTotal",
                        label: "折合金额",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "screateTime",
                        label: "录入时间",
                    },
                    {
                        prop: "createTime",
                        label: "下单时间",
                    },
                ],
                addFeeDialogVisible: false,
                loading: false,
                right: 'right',
                remark: '',
                labelPosition: 'right',
                flag: false,
                visible: false,
                changeCurVisible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                fileList: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                billForm: {
                    billType: 'noConvert',
                    billTitle: '',
                    payMan: '',
                    amount: '',//实付
                    remark: '',
                    ids: [],
                    orderNos: [],
                    cur: '',//更改的币种
                    bankId: '',
                    settleList: [],
                    uploadFileIds:[],//上传文件的id，保存时增加申请单号
                    fileList: [],   //水单文件
                },
                url: {
                    list: "/shipping/hySettleReceive/billedList",
                    deleteBatch: '/sys/bdCountry/deleteBatch',
                    openBill: '/file/jasper/openReceiveBill',
                    checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                    curGetBank: '/shipping/hySettleReceive/curGetBank',
                    addApply: '/shipping/hySettleReceive/addApply',
                    getSettlesByApplyNo: '/shipping/hySettleReceive/getSettlesByApplyNo',
                    uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                    readBillPic: '/shipping/hySettlePay/readBillPic',
                    delFile: '/shipping/hySettlePay/delFile',
                    getRateMoney: '/shipping/hySettleReceive/getRateMoney',
                    delOss: '/sys/oss/file/delete',
                    delOssBatch: '/sys/oss/file/deleteBatch',
                },
                rules: {
                    payMan: [
                        {required: true, message: '请输入付款人', trigger: 'blur'},
                    ],
                    amount: [
                        {required: true, message: '请输入金额', trigger: 'blur'},
                    ],
                    billMoney: [
                        {required: true, message: '请输入金额', trigger: 'change'},
                    ],
                    realMoneyType: [
                        {required: true, message: '请选择币种', trigger: 'blur'},
                    ],
                    bankId: [
                        {required: true, message: '请选择银行', trigger: 'blur'},
                    ],
                    fileList: [
                        {required: true, message: '请上传水单', trigger: 'change'},
                    ],


                },
                title: '收款申请',
                settleIds: [],
                showOnly: true,
                noChoose: [],
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 70
                    },
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                    },
                    {
                        prop: 'realAgent',
                        label: '客户',
                    },
                    {
                        prop: 'agent',
                        label: '结算对象',
                        width: 120
                    },
                    {
                        prop: 'partialWriteOff',
                        label: '是否部分销账',
                    },
                    {
                        prop: 'feename',
                        label: '费用中文',
                    },
                    {
                        prop: 'moneyType',
                        label: '应收币种',
                    },
                    {
                        prop: 'total',
                        label: '应收金额',
                    },
                    {
                        prop: 'realMoneyType',
                        label: '折合币种',
                    },
                    {
                        prop: 'exchangeTotal',
                        label: '折合金额',
                    },
                    {
                        prop: 'payed',
                        label: '已销账金额',
                    },
                    {
                        prop: 'billMoney',
                        label: '账单金额',
                        headerType:'remark',
                        detail:'实收金额 = 已部分销账的金额 + 此次实付的金额<br>'+
                            '只需输入此次实收的金额，点击【确认修改】后自动计算总实收',
                        width: 150
                    },
                    {
                        prop: 'billBy',
                        label: '开账单人员',
                    },
                    {
                        prop: 'billTime',
                        label: '账单时间',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'operator',
                        label: '操作人员',
                    },

                ],
                selectionRows: [],
                addSelectRows: [],
                banks: [],
                currentSettlementId:'',//当前在页面上的结算对象id
                currentSettleIds:[],//当前在页面显示的费用id
                feesAgain: [],
                ipagination: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                feeOrderNo:'',//添加费用时的查询条件
                curDis:false,
                curNow:'',//当前币种
                tempFilesId:[],//新上传的文件，离开页面没有点保存时删除
                orderType:'',
            }
        },
        methods: {
            updatePayMen(e){
                this.billForm.payMan  = e.trim()
            },
            //进入客服页
            intoDocpage(orderNo) {
              this.visible = false;
              if (orderNo.indexOf("KD") !== -1) {
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
              } else {
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
              }

            },
            initCusPage({cusId}) {
              if (cusId){
                // 对于路由不在左侧菜单中的选中菜单处理
                this.$router.push(`/customer/Edit?id=${cusId}`)
              }
            },

            //上传文件到oss
            handleUpload(data) {
                // if (data.file.name.length> 20){
                //     this.$message.warning("文件名过长，自动截取前50个字符");
                //     let index = data.file.name.lastIndexOf(".");
                //     let suffix = data.file.name.substr(index+1);
                //     console.log(suffix)
                //     data.file = new File([data.file], data.file.name.substring(0, 10) + '...' + suffix)
                // }

                const formData = new FormData();
                formData.append("file", data.file);
                formData.append("isNeedToText", 1);

                postAction(this.url.uploadInvoice, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        this.billForm.uploadFileIds.push(res.result.osOrderFile.id);
                        this.tempFilesId.push(res.result.osOrderFile.id)//上传为临时文件后但未提交的文件，需要删除
                        // console.log(this.billForm.uploadFileIds)
                        this.billForm.fileList.push(res.result.osOrderFile);
                        this.billForm.payMan = res.result.PayerName;
                        this.$set(this.billForm,'billMoney',res.result.Amount);
                        this.$message.success('上传成功');
                    }else{
                        this.$message.error('上传失败');
                    }
                })
                //写 另外一个 异步接口
                postAction(this.url.readBillPic, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        if (this.billForm.billMoney == null) {
                            this.$set(this.billForm, 'billMoney', res.result.Amount);
                        }
                        this.$set(this.billForm, 'billBankNo', res.result.BankSlipNo);
                        this.$set(this.billForm, 'billPayerName', res.result.PayerName);
                        this.$set(this.billForm, 'billPayerBank', res.result.PayerBank);
                        this.$set(this.billForm, 'billTradingDate', res.result.ReferenceRemitDate);

                    }
                })
            },
            delFile(){
                deleteAction(this.url.delOss, {id: val.id})
                    .then((res) => {
                        if (res.success) {
                            this.form.fileList.splice(index, 1);
                            //同时清空文件的url
                            this.$message.success("删除文件成功");
                        } else {
                            //清空filelist
                            this.form.fileList.splice(index, 1);
                        }
                    })
                    .catch((err) => {
                        this.form.fileList.splice(index, 1);
                    });
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                const len = file.name.length < 100;
                if (!len) {
                    this.$message.warning("该文件名太长，请修改文件名最多100个字符");
                }
                return isLt2M && isFile && len;
            },
            changeAmount() {
                let sumPay = 0;
                this.billForm.settleList.forEach(item => {
                    sumPay = Number(sumPay) + Number(item.billMoney)
                    // item.billMoney = Number(item.exchangeTotal) + Number(item.billMoney) - Number(item.waitPay)
                });
                this.billForm.amount = sumPay.toFixed(3);
            },
            toS(v) {
                return String(v)
            },
            delFee(row) {
                // console.log(row)
                this.billForm.settleList.splice(this.billForm.settleList.indexOf(row),1);
                this.currentSettleIds.splice(this.currentSettleIds.indexOf(row.id),1);

                if(!this.curDis){
                    this.getRateMoney(this.billForm.settleList, this.billForm.cur)
                }
                // this.doSum(this.billForm.settleList)
            },
            changeCurBank() {
                this.changeCurVisible = true;
            },
            curChangeBank(val) {
                // console.log(val)
                if(val!==this.curNow){
                    this.banks = []
                    // console.log('开始币种银行')
                    getAction(this.url.curGetBank, {cur: val, useType: 1}).then((res) => {
                        // console.log(res)
                        // console.log(res)
                        if (res.result.length === 0) {
                            this.$message.warning("该币种下无相应账号！")
                            this.$set(this.billForm, 'bankId', '');
                        } else {
                            this.banks = res.result
                            // let aaa = this.billForm.bankId
                            // if(currentBankId!== ''||currentBankId!==undefined){
                            //     this.$set(this.billForm, 'bankId', currentBankId);
                            // }else {
                            //     this.$set(this.billForm, 'bankId', '');
                            // }
                            // this.curNow = val
                        }
                    })
                }

            },
            addSelectionChange(selection) { // 多选
                // console.log(selection)
                this.addSelectRows = selection;
            },
            addFee() {
                //获取当前结算对象的相关费用
                this.feesAgain = [];
                getAction(this.url.list,{
                    settlementId:this.currentSettlementId,
                    currentSettleIds:this.currentSettleIds.toString(),
                    realMoneyType:this.billForm.cur,
                    orderType:this.orderType
                }).then((res)=>{
                    if (res.result.records.length > 0){
                        this.feesAgain = res.result.records
                        this.addFeeDialogVisible = true;
                        this.ipagination.currentPage = res.result.current;
                        this.ipagination.pageSize = res.result.size;
                        this.ipagination.total = res.result.total;

                    }else{
                        this.$message.warning("无相同结算对象的费用")
                        this.addFeeDialogVisible = false;
                    }
                })

                // this.addFeeDialogVisible = true;
            },
            addSubmit() {
                // this.settleList.concat(this.addSelectRows)
                // this.addSelectRows = []
                for (let i = 0; i < this.addSelectRows.length; i++) {
                    // this.addSelectRows[i].billMoney = JSON.parse(JSON.stringify(this.addSelectRows[i].waitPay))
                    if(this.addSelectRows[i].waitPay==='' || this.addSelectRows[i].waitPay===undefined || this.addSelectRows[i].waitPay === null){
                        this.addSelectRows[i].billMoney = JSON.parse(JSON.stringify(this.addSelectRows[i].exchangeTotal))
                    }else{
                        this.addSelectRows[i].billMoney = JSON.parse(JSON.stringify(this.addSelectRows[i].waitPay))
                    }
                    this.billForm.settleList.push(this.addSelectRows[i])
                    this.currentSettleIds.push(this.addSelectRows[i].id)//判断当前申请费用的id 用作排除添加费用时已用的费用
                    this.noChoose.splice(this.noChoose.indexOf(this.addSelectRows[i]))
                }
                //如果是折合金额就不用修改
                if(!this.curDis){
                    this.getRateMoney(this.billForm.settleList, this.billForm.cur)
                }
                // this.getRateMoney(this.billForm.settleList,this.billForm.cur)
                this.addFeeDialogVisible = false;
                this.addSelectRows = []

            },
            changeCurSubmit() {
                // console.log('币种',this.billForm.cur)
                // console.log(this.billForm.settleList)
                //TODO 修改原tableData中的币种和银行
                this.billForm.settleList.forEach(data => {
                    //此处不修改折合币种，切换币种获取汇率时在后台会更新币种
                    // if(this.billForm.cur!==this.curNow){
                    //     data.realMoneyType = this.billForm.cur
                    // }
                    data.bank = this.billForm.bank
                })
                // console.log('修改银行币种',this.billForm.cur+"-"+this.curNow)
                // if(this.billForm.cur!==this.curNow){
                // 如果是部分销账不可修改币种则不需要获取折合金额和修改
                if(!this.curDis){
                    this.getRateMoney(this.billForm.settleList, this.billForm.cur)
                }
                this.curNow = {...this.billForm.cur}
                this.changeAmount()
                this.curBankDialogClose()
            },
            handleSubmit() {
                let flag = false
                // if (Number(this.billForm.amount) === 0) {
                //     this.$message.warning("金额不能为零！")
                //     return;
                // }
                if (this.billForm.bankId=== '' || this.billForm.bankId === undefined){
                    this.$message.warning("请选择收款银行！")
                    return;
                }
                this.changeAmount();
                let sumPayed = 0;
                this.billForm.settleList.forEach(item => {
                    if(Number(item.waitPay)!==0 && item.waitPay!==null){
                        sumPayed = Number(sumPayed) + Number(item.payed)
                    }
                });
                sumPayed = sumPayed.toFixed(3);
                // 收款人与结算对象不一致标识
                if((this.billForm.payMan && this.billForm.payMan.trim()) === this.billForm.settleList[0].agent){
                    flag = true
                }
                let html = '</br><span style="color: red">付款人与结算对象不一致，请提供付款委托书</span>';
                let message = '此次收款金额为：'+ this.billForm.amount + '，加上已销账部分金额：'+ sumPayed + '，合计总实收：' +
                    (Number(this.billForm.amount)+Number(sumPayed)).toFixed(3) + '。请确认是否无误？' + (flag ==false?html:"")
                this.$confirm(message, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    // console.log("yesyesyes")
                    // this.billForm.amount = (Number(this.billForm.amount) + Number(sumPayed)).toFixed(3);
                    this.$refs.billForm.validate((valid) => {
                        if (valid) {
                            postAction(this.url.addApply, this.billForm).then((res) => {
                                // console.log(res)
                                if (res.success === true) {
                                    this.visible = false
                                    this.$emit('ok')
                                }
                            })
                        }
                    })
                }).catch(() => {

                })
                // this.changeAmount();
                // if(Number(this.billForm.amount)===0){
                //     this.$message.warning("金额不能为零！")
                //     return;
                // }
                // if (this.billForm.bankId=== '' || this.billForm.bankId === undefined){
                //     this.$message.warning("请选择收款银行！")
                //     return;
                // }
                // this.$refs.billForm.validate((valid) => {
                //     if (valid) {
                //         postAction(this.url.addApply, this.billForm).then((res) => {
                //             console.log(res)
                //             if (res.success === true) {
                //                 this.visible = false
                //                 this.$emit('ok')
                //             }
                //         })
                //     }
                // })
            },
            getRateMoney(settleList,cur){

                const obj = {
                    settleList:settleList,
                    cur:cur}
                // console.log('折合币种',obj)
                postAction(this.url.getRateMoney,obj).then((res)=>{
                    // console.log(res)
                    if(res.success===true){

                        this.billForm.settleList=res.result
                        //添加费用的时候将实收金额赋值为待收金额
                        this.billForm.settleList.forEach(data => {
                            data.billMoney = data.waitPay
                        })
                        this.changeAmount()
                    }else{
                        this.$message.warning("请求失败，请联系管理员")
                    }

                })
            },
            handleClose() {
                // this.$refs.form.resetFields()
                //批量删除上传的文件
                this.handleDelOssFileBatch()
                this.$nextTick(() => {
                    this.visible = false;
                })


            },
            curBankDialogClose() {
                // this.$refs.form.resetFields()
                this.changeCurVisible = false;
                // console.log(this.changeCurVisible,'changeCurVisible')

            },
            handleAddFeeDialogClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.addFeeDialogVisible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(selectionRows, arr, columns) {
                this.tempFilesId = []
                this.billForm.uploadFileIds = []
                this.$set(this.billForm,'billMoney','');
                this.billForm.fileList = []
                // console.log("申请收款的selectionRows", selectionRows)
                this.currentSettlementId = JSON.parse(JSON.stringify(selectionRows[0].settleId));//当前的settlementId
                this.currentSettleIds = [];
                this.feeOrderNo = '';
                let feeRows = JSON.parse(JSON.stringify(selectionRows))
                //判断是否存在部分销账 部分销账不允许更改折合币种
                let curStatus = [];
                let orderType = [];
                this.curDis = false;
                this.billForm.cur = feeRows[0].realMoneyType
                // this.curNow = feeRows[0].realMoneyType
                feeRows.forEach(fee=>{
                    this.currentSettleIds.push(fee.id)
                    if(fee.status==='2'){
                        curStatus.push(fee.id)
                    }
                    if(fee.orderType==='2'){
                        orderType.push(fee.id)
                    }
                })
                if(curStatus.length > 0 || orderType.length > 0){
                    this.curDis = true;
                    // console.log('部分销账',feeRows[0])
                    // this.curNow = JSON.parse(JSON.stringify(this.billForm.cur))
                }



                this.billForm.settleList = JSON.parse(JSON.stringify(selectionRows))
                this.orderType = this.billForm.settleList[0].orderType
                this.billForm.settleList.forEach(settle =>{
                    if(settle.waitPay==='' || settle.waitPay===undefined || settle.waitPay === null){
                        settle.billMoney = JSON.parse(JSON.stringify(settle.exchangeTotal))
                    }else{
                        settle.billMoney = JSON.parse(JSON.stringify(settle.waitPay))
                    }


                })
                // this.noChoose = JSON.parse(JSON.stringify(arr));
                // this.addColumns = columns;
                this.showOnly = true;
                this.title = '收款申请'
                this.changeAmount();
                this.curChangeBank(this.billForm.cur)
                this.billForm.payMan = '';
                // this.curNow = res.result.payCur;
                // this.billForm.amount = '';
                this.billForm.remark = '';
                this.visible = true
            },
            showDetail(row) {
                // console.log('申请对象->', row);
                getAction(this.url.getSettlesByApplyNo, {applyNo: row.applyNo}).then((res) => {
                    // console.log(res)
                    this.billForm.settleList = res.result.settleList;
                    this.billForm.payMan = res.result.payMan;
                    this.billForm.amount = res.result.amount;
                    this.billForm.remark = res.result.remark;
                    this.billForm.billBankNo = res.result.billBankNo;
                    this.billForm.billPayerName = res.result.billPayerName;
                    this.billForm.billPayerBank = res.result.billPayerBank;
                    this.billForm.billTradingDate = res.result.billTradingDate;
                    this.billForm.fVoucherDate = res.result.fVoucherDate;

                })
                this.title = '收款申请详情'
                this.showOnly = false;
                this.visible = true
            },
            handleRemove(file, fileList) {
                this.billForm.fileList.map((item, index) => {
                    if (item.uid == file.uid) {
                        //向服务器发送要删除的文件的路径，删除oss上的文件
                        // console.log(item)
                        postAction(this.url.delFile,{osOrderFileId:item.id, url:item.url}).then((res)=>{
                            // console.log(res)
                            //页面的上的文件也要删除
                            this.billForm.fileList.splice(index, 1)
                            this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                        })

                    }
                })
            },
            handleDelOssFileBatch(){
                let delIdsStr = this.tempFilesId.join(",");
                // console.log('删除的文件id',delIdsStr)
                if(delIdsStr) {
                    deleteAction(this.url.deleteBatch,{ids:delIdsStr}).then((res)=>{
                        // console.log(res)
                        //页面的上的文件也要删除
                        // this.fileList.splice(index, 1)
                        // this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id));
                        this.billForm.fileList = []
                        this.billForm.uploadFileIds = []
                    })
                }
            },
            handlePicPreview(file) {
                // console.log(file)
                if (!file.url) {
                  this.$message.error('下载失败')
                  return
                }

                const type = file.url.substring(file.url.lastIndexOf('.')+1);
                if (type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg'){
                    this.imgUrl = file.url
                    this.visibleFile = true
                }
                // 判断文件类型
                else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
                  // 在当前浏览器直接下载
                  document.location.href = file.url
                } else {
                  // 图片在浏览器打开 新的页面展示
                  window.open(file.url, 'hello')
                }
            },
            handleSelectionChange(selection) { // 多选
                // console.log(selection)
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1
                this.ipagination.pageSize = val
                this.addFeesAgain()
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val
                this.addFeesAgain()
            },
            addFeesAgain(){
                let orderNo = JSON.parse(JSON.stringify(this.feeOrderNo));
                if (orderNo.trim()===''){
                    orderNo = null
                }
                getAction(this.url.list,{
                    orderNo:orderNo,
                    settlementId:this.currentSettlementId,
                    currentSettleIds:this.currentSettleIds.toString(),
                    realMoneyType:this.billForm.cur,
                    orderType:this.orderType,
                    pageNo:this.ipagination.currentPage,
                    pageSize:this.ipagination.pageSize,
                }).then((res)=>{
                    // console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result
                            this.feesAgain = records || res.result;

                            this.ipagination && (this.ipagination.total = total || res.result.length)
                        } else {

                            this.feesAgain = [];

                            this.ipagination = 0;
                        }

                    }
                    // if (res.result.records.length > 0){
                    //     this.feesAgain = res.result.records
                    //
                    // }else{
                    //     this.$message.warning("无相同结算对象的费用")
                    //
                    // }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
