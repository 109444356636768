<template>
  <div>
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="订单号">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="柜号">
                    <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
                  </el-form-item>
                </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="客户">
                  <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch" type="textarea"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="下单时间" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="业务类型">
                  <f-select
                      :dict="'container_type'"
                      v-model="queryForm.containerType"
                      :is-need="queryForm.containerType"
                      :father-val="queryForm.containerType"
                  ></f-select>
                </el-form-item>
              </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                  <el-form-item label="客服">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.docId"
                      :is-need="queryForm.docId"
                      :father-val="queryForm.docId"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                  <el-form-item label="业务员">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                      :father-val="queryForm.salesmanId"
                    ></f-select>
                  </el-form-item>
                </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="操作员">
                  <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.operateId"
                      :is-need="queryForm.operateId"
                      :father-val="queryForm.operateId"
                  ></f-select>
                </el-form-item>
              </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                  <el-form-item label="订舱号">
                    <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                  <el-form-item label="收款状态">
                    <el-select v-model="queryForm.recStatus">
                      <el-option value="0" label="无应收"></el-option>
                      <el-option value="1" label="未收款"></el-option>
                      <el-option value="2" label="部分收款"></el-option>
                      <el-option value="3" label="已收款"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="付款状态">
                  <el-select v-model="queryForm.payStatus">
                    <el-option value="0" label="无应付"></el-option>
                    <el-option value="1" label="未付款"></el-option>
                    <el-option value="2" label="部分付款"></el-option>
                    <el-option value="3" label="已付款"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                        >查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <div>
        <el-button
            :disabled="selectedRowKeys.length===0"
            type="primary"
            size="small"
            v-if="buttonShowList.sc"
            @click="shanchu"
        >删除订单</el-button>
        <el-button
            :disabled="selectedRowKeys.length===0"
            type="primary" size="small"
            v-if="buttonShowList.hf"
            @click="huifu"
        >恢复订单</el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="orderNo"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :options="typelist"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
        @handleKeyup="handleSearch"
        :tableHeight="showLoadingSearch ? '61vh' : '68vh'"
        :isRecordTableScroll="true"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
              <a
                 style="color: #00a0e9"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a
               style="color: #00a0e9"
               @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
            >{{ scope.row.customer }}</a>
          </template>
          <template v-else-if="scope.column.property === 'payStatus'">
            <span v-if="scope.row.payStauts==='3'">已付款</span>
            <span v-else-if="scope.row.payStauts==='2'">部分付款</span>
            <span v-else-if="scope.row.payStauts==='1'">未付款</span>
            <span v-else>无应付</span>
          </template>
          <template v-else-if="scope.column.property === 'recStatus'">
            <span v-if="scope.row.recStatus==='3'">已收款</span>
            <span v-else-if="scope.row.recStatus==='2'">部分收款</span>
            <span v-else-if="scope.row.recStatus==='1'">未收款</span>
            <span v-else>无应收</span>
          </template>
          <template v-else-if="scope.column.property === 'containerType'">
            <span v-if="scope.row.containerType==='1'">FCL整柜</span>
            <span v-else>LCL拼柜</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <add-problem-dialog
      :visible.sync="proVisible"
      :row="row"
      @ok="handleQuery"
      v-if="proVisible"
    ></add-problem-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import AddProblemDialog from "./modules/AddProblemDialog";
import { getAction } from "@/api/manage";
import { setUpRouteUtil } from '@/utils/util';
import {putAction} from "../../api/manage";


export default {
  name: "ContactModule",
  mixins: [QueryMixins],
  components: {
    AddProblemDialog,
  },
  data() {
    return {
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      typelist: [
        { value: "0", label: "公司负责人" },
        { value: "1", label: "报价对接人" },
        { value: "2", label: "操作联系人" },
        { value: "3", label: "财务联系人" },
      ],

      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      columns: [
        {
          type: "selection",
          width: 33,
        },
        {
          prop: "orderNo",
          label: "工作号",
        },
        {
          prop: "bookNo",
          label: "订舱单号",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "recStatus",
          label: "收款状态",
        },
        {
          prop: "payStatus",
          label: "付款状态",
        },
        {
          prop: "createTime",
          label: "工作单日期",
        },
        {
          prop: "country",
          label: "目的地",
        },
        {
          prop: "ctn",
          label: "实件/卡板",
        },
        {
          prop: "realCtn",
          label: "实件",
        },
        {
          prop: "realCmb",
          label: "实体",
        },
        {
          prop: "shipCompany",
          label: "船公司",
        },
        {
          prop: "containerType",
          label: "业务类型",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "operator",
          label: "操作员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "polName",
          label: "起运港",
        },
        {
          prop: "podEndName",
          label: "目的港",
        },
        {
          prop: "etd",
          label: "ETD",
        },
        {
          prop: "eta",
          label: "ETA",
        },
        {
          prop: "remark",
          label: "内部备注",
        },

      ],
      proVisible: false,
      url: {
        list: "/interface/ttOrder/getCancelPage",
        huifu: "/interface/ttOrder/updateTtOrderCancel0Batch",
        shanchu: "/interface/ttOrder/updateTtOrderIsDel1Batch",

      },
      issalesman: "0",

    };
  },
  created() {

  },
  methods: {
    shanchu(){
      this.$confirm(`确定删除订单${this.selectedRowKeys.join(',')}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(()=>{
        putAction(this.url.shanchu,this.selectedRowKeys)
        .then(res=>{
          this.$message.success(res.message);
          this.handleQuery();
        })
      }).catch(()=>{

      })
    },
    huifu(){
      this.$confirm(`确定恢复订单${this.selectedRowKeys.join(',')}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(()=>{
        putAction(this.url.huifu,this.selectedRowKeys)
            .then(res=>{
              this.$message.success(res.message);
              this.handleQuery();
            })
      }).catch(()=>{

      })
    },
    handleSelectionChange(selection) { // 多选
      let arr = []
      this.nameList = []
      this.cnamelist = []
      this.emailList = []
      this.selectedRowOrderNos=[]
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['orderNo'])
        this.nameList.push(selection[i]['username'])
        this.cnamelist.push(selection[i]['cname'])
        this.emailList.push(selection[i]['email'])
        this.selectedRowOrderNos.push(selection[i]['orderNo'])
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr];
      console.log('this.selection', selection)

    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
  computed : {
    buttonShowList() {
      return {
        'sc':this.$btnIsShow('docpage/CancelOsOrder','0','删除订单'),
        'hf':this.$btnIsShow('docpage/CancelOsOrder','0','恢复订单'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
