
<template>
  <!-- 敏感产品库 -->
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="敏感产品类别">
              <el-input
                v-model="queryForm.sensitiveType"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="具体中文">
              <el-input
                v-model="queryForm.chineseName"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="具体英文">
              <el-input
                v-model="queryForm.englishName"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd(null)"
          v-if="
            $btnIsShow('isystem/BaseData/sensitiveProductLibrary', '0', '新增')
          "
          >新增</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="downloadModel(url.sensitiveDownload, '敏感产品库导入模板')"
          v-if="
            $btnIsShow(
              'isystem/BaseData/sensitiveProductLibrary',
              '0',
              '导出模版'
            )
          "
          >导出模版</el-button
        >
        <el-upload
          action="/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          accept=".xls,.xlsx"
          style="margin: 0 10px; display: inline"
          v-if="
            $btnIsShow('isystem/BaseData/sensitiveProductLibrary', '0', '导入')
          "
        >
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              v-if="
                $btnIsShow(
                  'isystem/BaseData/sensitiveProductLibrary',
                  '1',
                  '编辑'
                )
              "
              @click="handleAdd(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="
                $btnIsShow(
                  'isystem/BaseData/sensitiveProductLibrary',
                  '1',
                  '删除'
                )
              "
              @click="handleDele(scope.row)"
              >删除</el-button
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑敏感词汇' : '新增敏感词汇'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="敏感产品类别"
          :label-width="formLabelWidth"
          prop="sensitiveType"
        >
          <el-input
            v-model="form.sensitiveType"
            autocomplete="off"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="敏感词"
          :label-width="formLabelWidth"
          prop="sensitiveWord"
        >
          <el-input
            v-model="form.sensitiveWord"
            autocomplete="off"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="国家"
          :label-width="formLabelWidth"
          prop="shipmentCountry"
        >
          <f-select
            clearable
            v-model="form.shipmentCountry"
            :isNeed="form.shipmentCountry"
            :dict="'bd_country'"
            placeholder="请选择"
          >
          </f-select>
        </el-form-item>
        <el-form-item
          label="敏感编码"
          :label-width="formLabelWidth"
          prop="code"
        >
          <el-input
            v-model="form.code"
            autocomplete="off"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="具体中文"
          :label-width="formLabelWidth"
          prop="chineseName"
        >
          <el-input
            v-model="form.chineseName"
            autocomplete="off"
            maxlength="50"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="具体英文"
          :label-width="formLabelWidth"
          prop="englishName"
        >
          <el-input
            v-model="form.englishName"
            autocomplete="off"
            maxlength="50"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="提醒处理方案"
          :label-width="formLabelWidth"
          prop="dealMethod"
        >
          <el-input
            v-model="form.dealMethod"
            autocomplete="off"
            maxlength="100"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="执行类别"
          :label-width="formLabelWidth"
          prop="executeType"
        >
          <el-input
            v-model="form.executeType"
            autocomplete="off"
            maxlength="100"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="medium"
          @click="
            () => {
              dialogFormVisible = false;
            }
          "
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="handleSave"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postAction } from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";

export default {
  name: "sensitiveProductLibrary",
  mixins: [QueryMixins],
  data() {
    return {
      rules: {
        sensitiveType: [
          { required: true, message: "请输入敏感产品类别", trigger: "change" },
        ],
        shipmentCountry: [
          { required: true, message: "请选择国家", trigger: "change" },
        ],
        dealMethod: [
          { required: true, message: "请输入提醒处理方案", trigger: "change" },
        ],
        executeType: [
          { required: true, message: "请输入执行类别", trigger: "change" },
        ],
      },
      form: {},
      formLabelWidth: "110px",
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "sensitiveType",
          label: "敏感产品类别",
        },
        {
          prop: "shipmentCountry",
          label: "国家",
        },
        {
          prop: "sensitiveWord",
          label: "敏感词",
        },
        {
          prop: "chineseName",
          label: "具体中文",
        },
        {
          prop: "englishName",
          label: "具体英文",
        },
        {
          prop: "code",
          label: "敏感编码",
        },
        {
          prop: "dealMethod",
          label: "提醒处理方案",
        },
        {
          prop: "executeType",
          label: "执行类别",
        },
      ],
      url: {
        list: "/sys/bdSensitives/getList",
        insert: "/sys/bdSensitives/insert",
        update: "/sys/bdSensitives/update",
        sensitiveDownload: "/file/download/model/sensitiveDownload",
        importSensitive: "/file/read/excel/importSensitive",
        delete: "/sys/bdSensitives/delete",
      },
      isUsePost: true,
    };
  },
  methods: {
    handleAdd(row) {
      this.$refs.form && this.$refs.form.resetFields();
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
      } else {
        this.form = {};
      }
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            !this.form.code &&
            !this.form.chineseName &&
            !this.form.englishName
          ) {
            this.$message.warning("具体中文、具体英文、敏感编码不能都为空");
            return;
          }
          let url = this.form.id ? this.url.update : this.url.insert;
          postAction(url, this.form).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              //关闭弹窗
              this.dialogFormVisible = false;
              this.handleQuery();
            }
          });
        }
      });
    },
    handleDele(row) {
      this.$confirm(`确定删除该敏感产品库吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.delete, [row.id]).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      postAction(this.url.importSensitive, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
        }
        this.handleQuery();
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>