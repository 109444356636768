<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" :label-width="labelWidth" size="medium"
                 @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="中文名" >
                            <el-input v-model="queryForm.chineseName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="英文名" >
                            <el-input v-model="queryForm.englishName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="charge code" >
                      <f-select v-model="queryForm.amazonSendChargeCode"
                                :is-need="queryForm.amazonSendChargeCode"
                                dict="amazon_send_charge_code"
                                @keyup.enter.native="handleSearch"></f-select>
                    </el-form-item>
                  </el-col>



                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="类型" >
                            <el-select v-model="queryForm.type" clearable filterable @keyup.enter.native="handleSearch">
                                <el-option value='"1"' label="海运"></el-option>
                                <el-option value='"2"' label="快递"></el-option>
                                <el-option value='"3"' label="海外仓"></el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="是否预估">
                      <el-select v-model="queryForm.isPre" clearable filterable @keyup.enter.native="handleSearch">
                        <el-option label="否" value="0" >否</el-option>
                        <el-option label="1" value="1">是</el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                          <el-button
                              type="primary"
                              size="medium"
                              @click="downloadModel(url.downloadFba, '系统费用模板')">系统费用模板
                          </el-button>
                          <el-upload
                              action="/"
                              :show-file-list="false"
                              :http-request="uploadSectionFile"
                              accept=".xls,.xlsx"
                              style="margin:0 10px;display:inline">
                            <el-button size="medium" type="primary">导入</el-button>
                          </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>


        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"

                           v-if="buttonShowList.add"
                >新增
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button>
                        <el-button type="text" @click="handleDele(scope.row)"
                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'apportionType'">
                        <span>{{parseDict(scope.row.apportionType,'apportion_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'type'">
                        <span v-if="scope.row.type === '1'">海运</span>
                        <span v-else-if="scope.row.type === '2'">快递</span>
                        <span v-else>海外仓</span>
                    </template>
                  <template v-else-if="scope.column.property === 'isPre'">
                    <span v-if="scope.row.isPre === '1'">是</span>

                    <span v-else>否</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑费用':'新增费用'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" append-to-body
                   v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form" :label-width="formLabelWidth">
                <el-form-item label="类型"  prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio :label='"1"'>海运</el-radio>
                        <el-radio :label='"2"'>快递</el-radio>
                        <el-radio :label='"3"'>海外仓</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="中文名"  prop="chineseName">
                    <el-input v-model="form.chineseName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="费用编码"  prop="code">
                    <el-input v-model="form.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="费用编码(金蝶)"  prop="kingdeeCode">
                    <el-input v-model="form.kingdeeCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="费用英文名" prop="englishName">
                    <el-input v-model="form.englishName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="税率"  prop="tax">
                    <el-input v-model="form.tax" autocomplete="off"
                              placeholder="固定值：0，0.01，0.015，0.03，0.04，0.05，0.06，0.09，0.10，0.11，0.13，0.16，0.17"></el-input>
                </el-form-item>
                <el-form-item label="税收分类编码"  prop="taxCode">
                    <el-input v-model="form.taxCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="费用分摊方式"  prop="apportionType">
                    <f-select :is-need="form.apportionType"
                              v-model="form.apportionType"
                              :dict="'apportion_type'"></f-select>
                </el-form-item>
                <el-form-item label="解释与定义"  prop="remark">
                    <el-input type="textarea" v-model="form.remark" autocomplete="off" :autosize="{ minRows: 4 }"></el-input>
                </el-form-item>
                <el-form-item label="产生情景"  prop="scene">
                    <el-input type="textarea" v-model="form.scene" autocomplete="off" :autosize="{ minRows: 4 }"></el-input>
                </el-form-item>
                <el-form-item label="排序"  prop="sortNum">
                  <el-input v-model="form.sortNum" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="isPre" label="是否是预估" >
                  <el-select v-model="form.isPre" filterable clearable>
                    <el-option value="0" label="否"></el-option>
                    <el-option value="1" label="是"></el-option>
                  </el-select>
                </el-form-item>
              <el-form-item prop="preId" label="预估费用对应的实际费用"  v-if="form.isPre==='1'">
                <f-select :is-need="form.preId"
                          v-model="form.preId"
                          :dict="'no_pre_fee'"></f-select>
              </el-form-item>
              <el-form-item prop="amazonSendChargeCode" label="charge code"  >
                <f-select :is-need="form.amazonSendChargeCode"
                          v-model="form.amazonSendChargeCode"
                          dict="amazon_send_charge_code"></f-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {zhengshuPattern} from "@/utils/pattern";
    import {updatedDict} from "../../../utils/util";

    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        data() {
            return {
              labelWidth:'140px',
                dictCodes: ['apportion_type'],
                rules: {
                    chineseName: [
                        {required: true, message: '请输入中文名', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},

                    ],
                    code: [
                        {required: true, message: '请输入编码', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    kingdeeCode: [
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    englishName: [
                        {required: true, message: '请输入英文名', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                        // {pattern: yingwenPattern, message: '英文名格式有误', trigger: 'change'},
                    ],
                    type: [
                        {required: true, message: '请选择费用类别', trigger: 'change'},
                    ],
                  isPre: [
                    {required: true, message: '请选择是否预估', trigger: 'change'},
                  ],
                  preId: [
                    {required: true, message: '请选择对应的实际费用', trigger: 'change'},
                  ],
                    tax: [
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    taxCode: [
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    sortNum: [
                      {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'},
                    ],
                },
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'chineseName',
                        label: '中文名',
                        sortable: 'custom',
                    },
                    {
                        prop: 'code',
                        label: '费用编码',
                        sortable: 'custom',
                    },
                  {
                    prop: 'amazonSendChargeCode',
                    label: 'charge code',

                  },
                    {
                        prop: 'kingdeeCode',
                        label: '费用编码(金蝶)',
                        sortable: 'custom',
                    },
                    {
                        prop: 'englishName',
                        label: '费用英文名',
                        sortable: 'custom',
                    },
                    {
                        prop: 'apportionType',
                        label: '费用分摊方式',
                        sortable: 'custom',
                    },
                    {
                        prop: 'type',
                        label: '费用类型',
                        sortable: 'custom',
                    },
                  {
                    prop: 'isPre',
                    label: '是否是预估',
                    sortable: 'custom',
                  },
                    {
                        prop: 'tax',
                        label: '税率',
                    },
                    {
                        prop: 'taxCode',
                        label: '税收分类编码',
                    },
                    {
                        prop: 'remark',
                        label: '解释与定义',
                        overFlow:true
                    },
                    {
                        prop: 'scene',
                        label: '产生情景',
                        overFlow:true
                    },
                    {
                      prop: 'sortNum',
                      label: '排序',
                      overFlow: true
                    },

                ],
                url: {
                    list: '/sys/feeType/pageList',
                    add: '/sys/feeType/add',
                    edit: '/sys/feeType/edit',
                    getById: '/sys/feeType/queryById',
                    delete: '/sys/feeType/delete',
                  importFba:'/sys/feeType/importFee',
                  downloadFba:'/sys/feeType/tax/out/dwonloadExcel',
                },
            }
        },
        methods: {
          uploadSectionFile(param) {
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importFba, form)
                .then((res) => {
                  //这里做上传之后的操作
                  if (res.success) {
                    this.$message.success(res.message);
                    this.handleQuery();
                  } else {
                    this.handleQuery();
                  }
                })
          },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                      this.handleQuery()
                                        updatedDict('bd_fee_type')

                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                      this.handleQuery()
                                      updatedDict('bd_fee_type')
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除该系统费用类别`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            }
        }, created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/FeeType','0','新增'),
            'bj':this.$btnIsShow('isystem/BaseData/FeeType','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/FeeType','1','删除'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
