<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium"
                 @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="国家" :label-width="labelWidth">
                            <el-input v-model="queryForm.countryName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名称" :label-width="labelWidth">
                            <el-input v-model="queryForm.userName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>


        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddView">新增</el-button>
                <el-button type="primary" size="medium" class="c-theme-error-button" @click="handleBatchDel"
                           :disabled="selectionRows.length === 0">批量删除</el-button>
                           <el-button type="primary" size="medium"  @click="synchronization" v-if="buttonShowList.tb"
                           >同步</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="openEditView(scope.row)"

                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button>
                        <el-button type="text" @click="handleDel(scope.row)"
                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog :title="addForm.id?'编辑':'新增'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   append-to-body
                   v-if="dialogFormVisible">
            <el-form :model="addForm" :rules="addRules" ref="form" label-position="left">
                <el-row >
                    <el-form-item label="国家" :label-width="formLabelWidth">
                        <f-select
                                v-model="addForm.countryName"
                                :isNeed="addForm.countryName"
                                :dict="'bd_country'"
                                placeholder="请选择">
                        </f-select>
                    </el-form-item>
                    <el-col :span="22">
                        <el-form-item prop="usernameSearch" label="客户名" :label-width="formLabelWidth">
                            <el-input style="width: 90%" v-model="usernameSearch" placeholder="支持模糊查找"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <el-button type="primary" @click="getUserName">查找</el-button>
                    </el-col>
                    <el-col>
                        <el-form-item prop="userName" label="客户" :label-width="formLabelWidth">
                            <el-select v-model="addForm.userName" clearable placeholder="请输入客户名查找后再进行选择">
                                <el-option v-for="item in cusList" :value="item.cusId"
                                           :label="item.username"
                                           :key="item.cusId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item prop="volumeWeightScale" label="重量体比例" :label-width="formLabelWidth">
                            <el-input v-model="addForm.volumeWeightScale" autocomplete="off" :maxlength="10"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item prop="volumeMinNum" label="单件最低质量" :label-width="formLabelWidth">
                            <el-input v-model="addForm.volumeMinNum" autocomplete="off" :maxlength="10"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSubmit">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {postAction, getAction} from '@/api/manage';
    import QueryMixins from "@/mixins/QueryMixins.js";

    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        data() {
            return {
                isUsePost: true,
                labelWidth: '120px',
                dictCodes: ['apportion_type'],
                rules: {},
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'countryName',
                        label: '国家名',
                    },
                    {
                        prop: 'userName',
                        label: '客户',
                    },
                    {
                        prop: 'volumeWeightScale',
                        label: '重量体比例',
                    },
                    {
                        prop: 'volumeMinNum',
                        label: '单件最低质量',
                    },

                ],
                url: {
                    list: '/warehouse/countryCoefficient/list',
                    detail: '/warehouse/countryCoefficient/selectOne/',
                    save: '/warehouse/countryCoefficient/save',
                    del: '/warehouse/countryCoefficient/del',
                    searchUser:"/sys/user/getCustomerByName",
                    syncAllData:"/warehouse/countryCoefficient/syncAllData"
                },
                addForm: {},
                usernameSearch: '',
                cusList:[],
                custMap:{},
                addRules:{
                    countryName: [
                        {required: true, message: '请选择国家', trigger: 'change'}
                    ],

                },
            }
        },


        methods: {
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },

            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.addForm.userId != null && this.addForm.userId != "" && this.addForm.userId != undefined) {
                            if(this.addForm.userId != this.addForm.countryName){
                                this.addForm['userName'] = this.cusMap[this.addForm.userId]['username']
                            }

                        }
                        postAction(this.url.save, this.addForm).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                        })
                    }
                })
            },
            openAddView() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            openEditView(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                getAction(this.url.detail + row.id).then((res) =>{
                    this.addForm = res.result;
                })
                this.dialogFormVisible = true;
            },

            handleDel(row) {
                this.$confirm(`确定删除该系数`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.del, [row.id]).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },

            handleBatchDel() {
                this.$confirm(`确定删除该系数`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.del, this.selectedRowKeys).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleClose() {
                this.addForm = {};
                this.form = {};
                this.usernameSearch = '';
                this.dialogFormVisible = false;
                this.handleQuery()
            },

            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            getUserName() {
                if (this.usernameSearch === "") {
                    this.$message.warning("请输入客户名再进行查找")
                    return;
                }
                this.addForm.userName = "";
                this.cusList = []
                getAction(this.url.searchUser, {username: this.usernameSearch}).then((res) => {
                    if (res.result.length > 0) {
                        this.cusList = res.result
                        this.cusMap = this.listToMap(this.cusList,'cusId')

                    }

                })
            },
            synchronization(){
                  getAction(this.url.syncAllData).then((res) => {
                    if (res.success) {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })

                    }
                })
            }

        }, created() {
        },
      computed:{
        buttonShowList() {
          return {
            'bj':this.$btnIsShow('isystem/BaseData/FeeType','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/FeeType','1','删除'),
            'tb':this.$btnIsShow('isystem/BaseData/FeeType','0','同步'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
