<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="70px" size="medium">
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
<!--          <el-form-item label="业务员">-->
<!--            <f-select-->
<!--              v-model="queryForm.salesId"-->

<!--              :is-need="queryForm.salesId"-->
<!--              filterable-->
<!--              :dict="'salesman'"-->
<!--            >-->
<!--            </f-select>-->
<!--          </el-form-item>-->
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="客服">
            <f-select
              v-model="queryForm.docId"

              :is-need="queryForm.docId"
              :dict="'sys_user_id'"
            >
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="收款人">
            <f-select
              v-model="queryForm.billerId"

              :is-need="queryForm.billerId"
              :dict="'sys_user_id'"
            >
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label-width="30px">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              >查询</el-button
            >
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
    <div class="f-p-b-15">
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        @click="handleAdd()"

        v-if="buttonShowList.add"
        >新增</el-button
      >
      <el-button
        class="c-theme-error-button"
        size="medium"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.plsc"
        @click="handleBatDel"
        >批量删除
      </el-button>
    </div>
    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
      <span
        class="stress f-m-l-12 f-cursor-pointer"
        @click="handleClearSelection"
        >清空</span
      >
    </div>
    <f-table
      v-loading="loading"
      ref="GTable"
      border
      size="medium"
      row-key="id"
      class="c-th-has-bgcolor"
      :columns="columns"
      :data="tableData"
      :show-operation="true"
      :cell-style="{ 'text-align': 'center' }"
      tableHeight="62vh"
      @selection-change="handleSelectionChange"
      @sort-change="handleTableChange"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)"

                     v-if="buttonShowList.bj"
          >编辑</el-button>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
</el-card>
    <add-binding-drawer
      :visible.sync="visible"
      :row="row"
      @ok="handleQuery"
      :bOptions.sync="bOptions"
    ></add-binding-drawer>
  </div>
</template>

<script>
import { deleteAction, getAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import AddBindingDrawer from "./modules/AddBindingDrawer";

export default {
  name: "BindingManage",
  mixins: [QueryMixins],
  components: {
    AddBindingDrawer,
  },
  created() {

    this.getCharges();
  },
  data() {
    return {
      id: "",
      type: "",
      authVisible: false,
      relateVisible: false,
      visible: false,
      bOptions: [],
      row: {},

      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        // {
        //   prop: "sales",
        //   label: "业务员",
        //   sortable: true,
        // },
        // {
        //   prop: "oceanSalesman",
        //   label: "海外业务员",
        //   sortable: true,
        // },
        {
          prop: "doc",
          label: "客服",
          sortable: true,
        },
        {
          prop: "biller",
          label: "收款人",
          sortable: true,
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: true,

          width: 160,
        },
        {
          prop: "createBy",
          label: "创建人",
          sortable: true,
          show: false,
        },
        {
          prop: "updateTime",
          label: "修改时间",
          show: false,
          sortable: true,
        },
        {
          prop: "updateBy",
          label: "修改人",
          sortable: "custom",
          show: false,
        },
      ],
      url: {
        list: "/sys/sysWorkBinding/list",
        deleteBatch: "/sys/sysWorkBinding/deleteBatch",
        add: "/sys/sysWorkBinding/addBinding",
        getCharges: "/sys/user/getCharges",
      },
    };
  },
  methods: {
    handleBatDel() {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            localStorage.removeItem("dict:salesman");
            localStorage.removeItem("dict:salesmanId");
            localStorage.removeItem("dict:sys_work_binding");

            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    handleEdit(row) {
      if (row) {
        for (var name in row) {
          this.$set(this.row,name,row[name])
        }
      }

      this.visible = true;
    },
    handleAdd(){
      this.row={};
      this.visible=true;
    },
    getCharges() {
      getAction(this.url.getCharges).then((res) => {
        this.bOptions = res;
      });
    },
  },
  computed:{
    buttonShowList(){
      return {
        'add':this.$btnIsShow('isystem/BindingManage','0','新增'),
        'plsc':this.$btnIsShow('isystem/BindingManage','0','批量删除'),
        'bj':this.$btnIsShow('isystem/BindingManage','1','编辑'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dot-badge {
  position: relative;

  &::before {
    content: "";
    width: 6px;
    height: 6px;
    left: -10px;
    top: calc(50% - 3px);
    position: absolute;
    background: #ec1c1c;
    border-radius: 50%;
  }
}
</style>
