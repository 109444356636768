<template>
  <el-dialog :title="row.id ? '编辑联系我们' : '新增联系我们'" :visible="visible" custom-class="c-custom-dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文配置" name="zh">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="地址名称" prop="addressName">
                <el-input v-model.trim="form.addressName" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contactPhone">
                <el-input v-model.trim="form.contactPhone" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作时间">
                <el-input v-model.trim="form.workTime"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址详情" prop="addressDetail">
                <el-input v-model.trim="form.addressDetail" type="textarea" maxlength="500" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="英文配置" name="en">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="地址名称" prop="addressNameEn">
                <el-input v-model.trim="form.addressNameEn" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contactPhoneEn">
                <el-input v-model.trim="form.contactPhoneEn" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作时间">
                <el-input v-model.trim="form.workTimeEn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址详情" prop="addressDetailEn">
                <el-input v-model.trim="form.addressDetailEn" type="textarea" maxlength="500" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-col :span="24">
          <el-card header="公共部分">
            <el-row class="inputs_row">
              <el-col :span="8">
                <el-form-item label="成立时间" prop="establishmentTime">
                  <el-date-picker v-model="form.establishmentTime" type="date" placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经度" prop="workLongitude">
                  <el-input v-model.trim="form.workLongitude" maxlength="255" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="纬度" prop="workLatitude">
                  <el-input v-model.trim="form.workLatitude" maxlength="255" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="图片" prop="companyFile">
                  <f-upload v-model="form.companyFile" :isUseOss="true" list-type="picture-card" accept="image/*"
                    :limit="1"></f-upload>
                  <div>建议尺寸： 290*220</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
export default {
  name: 'AddRoleDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      activeName: 'zh',
      form: {},
      rules: {
        addressName: [
          { required: true, message: '请输入地址名称', trigger: 'blur' },
        ],
        addressNameEn: [
          { required: true, message: '请输入地址名称', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        contactPhoneEn: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        addressDetail: [
          { required: true, message: '请输入地址详情', trigger: 'blur' },
        ],
        addressDetailEn: [
          { required: true, message: '请输入地址详情', trigger: 'blur' },
        ],
        establishmentTime: [
          { required: true, message: '请选择成立时间', trigger: 'change' },
        ],
        companyFile: [
          { required: true, message: '请选择图片', trigger: 'change' },
        ],
      },
      checkKey: {
        zh: ['addressName', 'contactPhone', 'addressDetail'],
        en: ['addressNameEn', 'contactPhoneEn', 'addressDetailEn']
      },
      url: {
        add: '/sys/owContactUs',
        edit: '/sys/owContactUs',
        detail: '/sys/owContactUs/'
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.row.id && (this.getDetail(this.row.id))
      }
    }
  },
  methods: {
    getDetail(id) {
      this.$store.commit("setFullscreenLoading", true);
      httpAction(this.url.detail + id, 'GET').then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.form = res.result;
        this.form.companyFile && JSON.stringify(this.form.companyFile) !== '{}' ? this.form.companyFile = [this.form.companyFile] : '';
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
      })
    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if (this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.companyFile;
      params.companyUrl = (this.form.companyFile && this.form.companyFile.length && this.form.companyFile[0].url) || '';
      params.companyUrlId = (this.form.companyFile && this.form.companyFile.length && this.form.companyFile[0].id) || '';
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写对应的英文配置';
            this.$message.warning(message);
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.form = {}
      this.activeName = 'zh'
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
