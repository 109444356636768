<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :span="5">
            <el-form-item label="子单号">
              <el-input
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="跟踪单号">
              <el-input
                v-model="queryForm.trackingNumbers"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="订单状态">
              <f-select
                v-model="queryForm.status"
                :is-need="queryForm.status"
                :dict="'order_newstatus'"
                placeholder="请选择"
                filterable
                clearable
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="9">
            <el-form-item label="下单时间">
              <el-date-picker
                v-model="queryForm.createTimeStart"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 180px"
              >
              </el-date-picker>
              <span class="f-p-h-2">~</span>
              <el-date-picker
                v-model="queryForm.createTimeEnd"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 180px"
              >
              </el-date-picker>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                style="margin-left: 25px"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <div>
        <el-button
          type="primary"
          size="small"
          @click="derivedCheck"
          v-if="buttonShowList.dc"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="batchDownload"
          :disabled="selectedRowKeys.length === 0"
          v-if="buttonShowList.plxz"
          >批量下载</el-button
        >
        <el-button
          type="primary"
          size="small"
          :disabled="selectedRowKeys.length === 0"
          @click="batchCancellation"
          v-if="buttonShowList.plqx"
          >批量取消</el-button
        >

        <el-button
          type="primary"
          size="small"
          @click="screenVisible = true"
          v-if="buttonShowList.dhsx"
          >单号筛选</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="72vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              v-if="buttonShowList.cxxd && scope.row.status == '5'"
              @click="orderAgain(scope.row)"
              >重新下单</el-button
            >
            <el-button
              type="text"
              v-if="buttonShowList.qx &&
                scope.row.status == '2' &&
                scope.row.trackingNumbers
              "
              @click="cancelOrder(scope.row)"
              >取消</el-button
            >
            <el-button
              type="text"
              v-if="buttonShowList.xz &&
                scope.row.status == '2' &&
                scope.row.trackingNumbers
              "
              @click="handlePreview(scope.row)"
              >下载</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'toAddress'">
            <el-tooltip
              :content="filterAddress(scope.row.toAddress)"
              placement="bottom"
              effect="light"
            >
              <!-- <div style="width: 100px"> -->
              <span
                style="
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                >{{ filterAddress(scope.row.toAddress) }}</span
              >
              <!-- </div> -->
            </el-tooltip>
          </template>
          <template v-else-if="scope.column.property === 'status'">
            <el-tooltip
              v-if="scope.row.status == '5'"
              :content="scope.row.failReason"
              placement="bottom"
              effect="light"
            >
              <!-- <div> -->
              <span
                :class="
                  scope.row.status == '0'
                    ? 'await_sign_box'
                    : scope.row.status == '1'
                    ? 'success_sign_box'
                    : scope.row.status == '2'
                    ? 'toPrint_sign_box'
                    : scope.row.status == '3'
                    ? 'off_sign_box'
                    : scope.row.status == '4'
                    ? 'cancel_sign_box'
                    : 'error_sign_box'
                "
                >{{ parseDict(scope.row.status, "order_newstatus") }}</span
              >
              <!-- </div> -->
            </el-tooltip>
            <span
              v-else
              :class="
                scope.row.status == '0'
                  ? 'await_sign_box'
                  : scope.row.status == '1'
                  ? 'success_sign_box'
                  : scope.row.status == '2'
                  ? 'toPrint_sign_box'
                  : scope.row.status == '3'
                  ? 'off_sign_box'
                  : scope.row.status == '4'
                  ? 'cancel_sign_box'
                  : 'error_sign_box'
              "
            >
              {{ parseDict(scope.row.status, "order_newstatus") }}
            </span>
            <!-- <span>{{ parseDict(scope.row.status, "order_newstatus") }}</span> -->
          </template>
          <template v-else-if="scope.column.property === 'trackingNumbers'">
            {{ scope.row.trackingNumbers }}
            <el-button
              v-if="scope.row.status !== '5'"
              type="text"
              @click="showTrackingNumbers(scope.row)"
              >子单号</el-button
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <!-- 子单号弹框 -->
    <el-dialog
      title="子单号"
      :visible="dialogVisible"
      width="20%"
      :before-close="handleClose"
      center
      :close-on-click-modal="false"
    >
      <div class="big_box">
        <div v-if="tableDataTr.length == 0"><span>暂无子单</span></div>
        <template v-else>
          <div
            class="box"
            style="text-align: center"
            v-for="(item, index) in tableDataTr"
            :key="index"
          >
            <span style="margin-right: 10px; font-size: 13px !important">{{
              (index += 1)
            }}</span>
            <span style="font-size: 13px !important">{{ item }}</span>
          </div>
        </template>
      </div>
    </el-dialog>

    <!-- 单号筛选 -->
    <el-dialog
      title="单号筛选"
      :visible="screenVisible"
      :before-close="handleCloseScreen"
      :close-on-click-modal="false"
    >
      <el-form
        ref="screenForm"
        :model="screenForm"
        label-width="120px"
        size="medium"
      >
        <el-card class="box-card">
          <el-row>
            <el-col :span="10">
              <el-form-item label="单号">
                <el-input
                  v-model="screenForm.orderNo"
                  @keyup.enter.native="queryOrderNoPost"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item label="柜号">
                <el-input
                  v-model="screenForm.containerNo"
                  @keyup.enter.native="queryOrderNoPost"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                type="primary"
                size="small"
                @click="queryOrderNoPost"
                style="margin-left: 20px"
                >查询</el-button
              >
            </el-col>
          </el-row>
        </el-card></el-form
      >

      <f-table
        ref="GTable"
        border
        size="medium"
        class="c-th-has-bgcolor"
        :columns="columnsScreen"
        :data="tableDataScreen"
        tableHeight="42vh"
      ></f-table>
    </el-dialog>
  </div>
</template>

<script>
import { postAction } from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";

export default {
  name: "AlreadyPrint",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      dictCodes: ["order_newstatus"],
      loading: false,
      tableData: [],
      tableDataTr: [],
      dialogVisible: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        //操作
        {
          prop: "action",
          label: "操作",
          width: 100,
        },

        //客户订单号
        {
          prop: "customerOrderNo",
          label: "子单号",
        },
        //客户代码
        // {
        //   prop: "customerCode",
        //   label: "客户代码",
        // },
        // 下单时间

        // 发货地址
        // {
        //   prop: "",
        //   label: this.$t("supplier.shipAddress"),
        // },
        // 收货地址
        {
          prop: "toAddress",
          label: "收货地址",
        },
        // 跟踪号
        {
          prop: "trackingNumbers",
          label: "跟踪号",
          width: 200,
        },
        // 渠道
        {
          prop: "channelCode",
          label: "渠道",
        },
        // 价格
        {
          prop: "finalPrice",
          label: "价格",
        },
        // 件数
        {
          prop: "totalParcelNum",
          label: "件数",
        },
        {
          prop: "status",
          label: "状态",
          // width: 120,
        },
        {
          prop: "customerCreate",
          label: "操作人",
        },
        {
          prop: "placeOrderTime",
          label: "下单时间",
        },
        {
          prop: "cancelOrderTime",
          label: "取消时间",
        },
      ],
      url: {
        list: "/interface/labelCenter/orderSuccList",
        // 重新下单
        placeOrderAgain: "/interface/labelCenter/placeOrderAgain",
        // 取消
        cancelOrder: "/interface/labelCenter/cancelOrder",
        downLoadLabelZip: "/interface/labelCenter/downLoadLabelZip", //勾选批量下载面单
        exportXlsUrl: "/interface/labelCenter/exportExcelLabelOrder",
        queryOrderNo: "/interface/labelCenter/queryOrderNo", //单号筛选
      },
      screenVisible: false,
      screenForm: {},
      columnsScreen: [
        //未打单单号
        {
          prop: "customerOrderNo",
          label: "未打单单号",
        },
      ],
      tableDataScreen: [],
    };
  },
  methods: {
    // handleSearch() {
    //   this.ipagination.currentPage = 1;
    //   this.handleQuery();
    // },
    //重新下单
    orderAgain(row) {
      this.loading = true;
      postAction(this.url.placeOrderAgain, row)
        .then((res) => {
          if (res.success) {
            if (res.result) {
              this.$message.success(res.message);
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.handleQuery();
          this.loading = false;
        });
    },
    // 取消
    cancelOrder(row) {
      this.loading = true;
      postAction(this.url.cancelOrder, { orderNoList: [row.orderNo] })
        .then((res) => {
          if (res.success) {
            if (res.result && res.result[0].success) {
              this.$message.success(
                res.result[0].failReason === null
                  ? "取消成功"
                  : res.result[0].failReason
              );
            } else {
              this.$message.error(res.result[0].failReason);
            }
          }
        })
        .finally(() => {
          this.handleQuery();
          this.loading = false;
        });
    },
    handlePreview(row) {
      if (!row.labelUrl) {
        this.$message("下载失败", "error");
        return;
      }
      if (
        row.labelUrl.startsWith(
          "https://oss-label.oss-cn-shenzhen.aliyuncs.com"
        )
      ) {
        postAction("interface/labelCenter/convertOssUrl", {
          labelUrl: row.labelUrl,
        })
          .then((res) => {
            window.open(res.result);
          })
          .catch((err) => {
            console.log(err, "下载错误");
          });
      } else {
        // 图片在浏览器打开 新的页面展示
        window.open(row.labelUrl);
      }
    },
    filterAddress(row) {
      if (!row) return;
      let showAddress = `${row.address1 ? row.address1 + "," : ""}${
        row.address2 ? row.address2 + "," : ""
      }${row.city},${row.state},${row.country},${row.zip},${
        row.fbaCode ? row.fbaCode : ""
      }`;
      return showAddress;
    },
    handleClose() {
      this.dialogVisible = false;
      this.tableDataTr = [];
    },
    showTrackingNumbers(row) {
      this.dialogVisible = true;
      this.tableDataTr = row.sonTrackingnumbersList;
    },
    // 批量下载
    batchDownload() {
      let params = {};
      if (!this.selectedRowKeys.length) {
        params = {
          ids: this.selectedRowKeys,
          ...this.queryForm,
        };
      } else {
        params = {
          ids: this.selectedRowKeys,
        };
      }
      postAction(this.url.downLoadLabelZip, params, {
        responseType: "blob",
      }).then((response) => {
        let u = window.URL.createObjectURL(response);
        let aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = u;
        aLink.setAttribute("download", "标签文件.zip");
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        window.URL.revokeObjectURL(u);
      });
    },
    // 批量取消
    batchCancellation() {
      let flag = this.selectionRows.some(
        (item) => item.status === "4" || item.status === "5"
      );
      if (flag) {
        this.$message.warning("已取消、打单失败状态下订单不能取消");
        return;
      }
      this.loading = true;
      const paramsList = this.selectionRows.map((item) => item.orderNo);
      postAction(this.url.cancelOrder, { orderNoList: paramsList })
        .then((res) => {
          if (res.success) {
            // 如果全部成功就提示成功 但凡有一条失败 要告诉哪一条失败把原因打出来 单号字段：orderNo
            if (res.result && res.result.length) {
              let errorIndex = res.result.findIndex((item) => !item.success);
              if (errorIndex === -1) {
                this.$message.success(res.message);
              } else {
                this.$message.error(
                  res.result[errorIndex].failReason +
                    ",单号：" +
                    res.result[errorIndex].orderNo
                );
              }
            } else {
              this.$message.success(res.message);
            }
          } else {
            this.$message.success(res.message);
          }
          this.handleQuery();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    queryOrderNoPost() {
      this.tableDataScreen = [];
      if (!this.screenForm.orderNo && !this.screenForm.containerNo) {
        this.$message.warning("请输入单号或者柜号查询");
        return;
      }
      postAction(this.url.queryOrderNo, this.screenForm).then((res) => {
        if (res.success) {
          if (res.result) {
            res.result.forEach((item) => {
              this.tableDataScreen.push({
                customerOrderNo: item,
              });
            });
          }
          this.$message.success(res.message);
        }
      });
    },
    handleCloseScreen() {
      this.screenForm = {};
      this.tableDataScreen = [];
      this.screenVisible = false;
    },
    derivedCheck() {
      if (
        !this.queryForm.orderNo &&
        !this.queryForm.trackingNumbers &&
        !this.queryForm.createTimeStart &&
        !this.queryForm.createTimeEnd
      ) {
        this.$message.warning("请输入单号或跟踪单号或下单时间后再操作导出");
        return;
      }
      if (this.queryForm.createTimeStart && this.queryForm.createTimeEnd) {
        // 获取element-ui日期选择器所选的开始日期和结束日期
        const startDate = new Date(this.queryForm.createTimeStart);
        const endDate = new Date(this.queryForm.createTimeEnd);
        // 计算开始日期和结束日期之间的月份数差
        const diffMonths =
          (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          endDate.getMonth() -
          startDate.getMonth();

        // 判断月份数差是否大于六
        if (diffMonths > 6) {
          // 日期间隔超过六个月
          this.$message.warning("日期间隔不能超过六个月");
          return;
        }
      }
      this.downloadExcelPost(this.url.exportXlsUrl, "已打单列表", {
        ids: this.selectedRowKeys,
        ...this.queryForm,
      });
    },
  },
  computed:{
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '0', '导出'),
        'plxz':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '0', '批量下载'),
        'plqx':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '0', '批量取消'),
        'dhsx':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '0', '单号筛选'),
        'cxxd':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '1', '重新下单'),
        'qx':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '1', '取消'),
        'xz':this.$btnIsShow('operation/PrintLabelNew/AlreadyPrint', '1', '下载'),
      }
    }
  }
};
</script>
<style scoped>
.box {
  /* font-size: 24px !important; */
  margin-bottom: 10px;
}
.big_box {
  text-align: center;
  margin-left: 19.6px;
  max-height: 400px;
  overflow: scroll;
}
</style>
