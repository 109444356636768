<template>
  <div class="main-full-content">
    <el-dialog
      :title="form.id ? '编辑类型' : '新增类型'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="类型名称"
          :label-width="formLabelWidth"
          prop="articleType"
        >
          <el-input v-model="form.articleType" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="发布端口"
          :label-width="formLabelWidth"
          prop="pushPort"
        >
          <el-select
            filterable
            class="f-full-width"
            v-model="form.pushPort"
            placeholder="请选择"
          >
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="排序"
          :label-width="formLabelWidth"
          prop="sort"
        >
          <el-input
            v-model="form.sort"
            autocomplete="off"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="handleSave"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="类型名称">
                <el-input v-model="queryForm.articleType" @keydown.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <!-- 页面分四个，根据不同的端口类型，查询条件暂时去掉端口类型 -->
            <!-- <el-col :xs="24" :sm="12" :md="12" :lg="6">
                              <el-form-item label="发布端口">
                                  <el-select
                                          filterable
                                          class="f-full-width"
                                          v-model="queryForm.pushPort"
                                          placeholder="请选择">
                                      <el-option
                                              v-for="item in optionsType"
                                              :key="item.value"
                                              :label="item.label"
                                              :value="item.value">
                                      </el-option>
                                  </el-select>
                              </el-form-item>
                          </el-col> -->
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label-width="30px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
                  >查询
                </el-button>
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <!-- 文章类型页面 -->
      <el-card class="box-card last_card">
        <div class="f-p-b-15">
          <el-button type="primary" size="medium"
            icon="el-icon-plus" @click="handleAdd"
            >新增
          </el-button>
        </div>
        <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="56vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'action'">
              <el-button type="text" @click="handleEdit(scope.row)"
                >编辑
              </el-button>
              <el-button type="text" @click="handleDele(scope.row)"
                >删除
              </el-button>
            </template>
            <template v-else-if="scope.column.property === 'pushPort'">
              <span v-if="scope.row.pushPort === '0'">PC后台</span>
              <span v-if="scope.row.pushPort === '1'">PC客户端</span>
              <span v-if="scope.row.pushPort === '2'">Applets后台</span>
              <span v-if="scope.row.pushPort === '3'">Applets客户端</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
            v-if="tableData.length"
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { deleteAction, postAction, putAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "Frozen",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      isorter: {
        prop: "sort",
        order: "asc",
      },
      tabs: [
        { label: "PC后台", component: "PcBackstage", show: true },
        { label: "PC客户端", component: "PcClient", show: true },
        { label: "Applets后台", component: "AppBackstage", show: true },
        { label: "Applets客户端", component: "AppClient", show: true },
      ],
      optionsType: [
        {
          value: "0",
          label: "PC后台",
        },
        {
          value: "1",
          label: "PC客户端",
        },
        {
          value: "2",
          label: "Applets后台",
        },
        {
          value: "3",
          label: "Applets客户端",
        },
      ],
      rules: {
        articleType: [
          { required: true, message: "请输入类型名称", trigger: "change" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        pushPort: [
          { required: true, message: "请选择发布端口", trigger: "change" },
        ],
        sort: [
          { required: true, message: "请输入排序", trigger: "change" }
        ]
      },
      emailList: [],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "articleType",
          label: "类型名称",
          sortable: "custom",
        },
        {
          prop: "sort",
          label: "排序",
          sortable: "custom",
        },
      ],
      url: {
        list: "/sys/managerType/list", // 获取表单数据
        add: "/sys/managerType/add", // 新增
        edit: "/sys/managerType/edit", // 编辑
        getById: "/sys/managerType/queryById",
        delete: "/sys/managerType/delete", // 删除
      },
    };
  },
  methods: {
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleAdd() {
      this.form = {};
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          } else {
            postAction(this.url.add, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          }
        }
      });
    },
    handleDele(row) {
      this.$confirm(`确定删除${row.articleType}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.pushPort = "2"; // 该端口类型下所有数据
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  created() {
    //赋值端口类型
    this.queryForm.pushPort = "2";
  },
};
</script>

<style lang="scss" scoped>
// .el-row {
//     position: relative;
//     left: -135px;
// }
.input_top .el-form-item {
    margin-bottom: 2px;
}
</style>
