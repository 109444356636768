import { render, staticRenderFns } from "./SaleReport.vue?vue&type=template&id=1da4ee2a&scoped=true&"
import script from "./SaleReport.vue?vue&type=script&lang=js&"
export * from "./SaleReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da4ee2a",
  null
  
)

export default component.exports