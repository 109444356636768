<template>
  <div>
    <!-- 编辑SEO -->
    <el-dialog
      :title="row.id ? '编辑SEO' : '新增SEO'"
      :visible="visible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        size="medium"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item prop="seoTitle" label="自定义标题">
              <el-input
                v-model="form.seoTitle"
                placeholder="请输入标题"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="seoKeyword" label="自定义关键词">
              <el-input
                v-model="form.seoKeyword"
                placeholder="请输入关键词"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="seoDescription" label="自定义描述">
              <el-input
                v-model="form.seoDescription"
                placeholder="请输入自定义描述"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="selectedIdList" label="配置页面">
              <el-cascader
                v-model="form.selectedIdList"
                :options="pageList"
                filterable
                @change="changePage"
                clearable
              ></el-cascader>
              <el-button type="text" @click="handlechange" v-show="showBtn"
                >选择新闻
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="seoUrl" label="页面路径">
              <el-input v-model="form.seoUrl" disabled> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="选择新闻"
      :visible="tableVisible"
      width="60%"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="() => (tableVisible = false)"
    >
      <el-form
        class="input_top"
        ref="form"
        :model="queryForm"
        label-width="80px"
        size="medium"
      >
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="标题">
                <el-input
                  v-model.trim="queryForm.title"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="标题英文">
                <el-input
                  v-model.trim="queryForm.titleEn"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label-width="30px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
                  >查询</el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <f-table
        v-loading="loading"
        border
        ref="GTable"
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :show-operation="true"
        :showSlot="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot:showSlot="{ item }">
          <template v-if="item.prop == 'chinese'">
            <el-table-column prop="title" label="标题"> </el-table-column>
            <el-table-column prop="coverUrl" label="封面图片">
              <template #default="scope">
                <img
                  class="company_img"
                  v-if="scope.row.coverUrl"
                  :src="scope.row.coverUrl"
                  alt=""
                />
              </template>
            </el-table-column>
          </template>
          <template v-if="item.prop == 'english'">
            <el-table-column prop="titleEn" label="标题"> </el-table-column>
            <el-table-column prop="coverUrlEn" label="封面图片">
              <template #default="scope">
                <img
                  class="company_img"
                  v-if="scope.row.coverUrlEn"
                  :src="scope.row.coverUrlEn"
                  alt=""
                />
              </template>
            </el-table-column>
          </template>
        </template>
        <template #default="scope">
          <template v-if="scope.column.property === 'isTop'">
            <span>{{ scope.row.isTop == "1" ? "是" : "否" }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { postAction, putAction } from "../../../api/manage";

export default {
  name: "AddSeoDialog",
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      rules: {
        seoTitle: [{ required: true, message: "请输入标题", trigger: "blur" }],
        seoKeyword: [
          { required: true, message: "请输入关键词", trigger: "change" },
        ],
        seoDescription: [
          { required: true, message: "请输入描述", trigger: "change" },
        ],
        selectedIdList: [
          { required: true, message: "请选择配置页面", trigger: "change" },
        ],
      },
      url: {
        owSeoConfig: "/sys/owSeoConfig",
        list: "/sys/managerFind/queryList",
      },
      pageList: [
        {
          value: "/home",
          label: "主页",
        },
        {
          value: "/product",
          label: "产品中心",
          children: [
            // {
            //   value: "/product/all",
            //   label: "",
            // },
            {
              value: "/fbahy",
              label: "海运服务",
            },
            {
              value: "/Projects/fbaqgfw",
              label: "清关服务",
            },
            {
              value: "/fbaky",
              label: "快空服务",
            },
            {
              value: "/product/overseas",
              label: "海外仓服务",
            },
            {
              value: "/product/productDetail",
              label: "产品详情",
            },
          ],
        },
        {
          value: "/aboutUs",
          label: "关于我们",
          children: [
            {
              value: "/aboutUs/companyProfile",
              label: "公司简介",
            },
            {
              value: "/aboutUs/teamIntroduction",
              label: "团队介绍",
            },
            {
              value: "/aboutUs/globalForest",
              label: "全球森仓",
            },
            {
              value: "/aboutUs/talentRecruitment",
              label: "人才招聘",
            },
            {
              value: "/lxdsl",
              label: "联系我们",
            },
          ],
        },
        {
          value: "/",
          label: "新闻资讯",
          children: [
            {
              value: "/new",
              label: "新闻",
            },
            {
              value: "/newDetail",
              label: "新闻详情",
            },
          ],
        },
        {
          value: "/cooperation",
          label: "合伙流程",
        },
        {
          value: "/wjxz2",
          label: "下载中心",
        },
        {
          value: "/Projects/ckwlcywz",
          label: "常用网站",
        },
      ],
      disableMixinMounted: true,
      tableVisible: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "chinese",
          label: "中文",
        },
        {
          prop: "english",
          label: "英文",
        },
        {
          prop: "isTop",
          label: "是否置顶",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      selectionId: "",
      showBtn: false,
      rememberUrl: "",
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.form = { ...this.row };
      }
    },
  },
  created() {},
  methods: {
    changePage(val) {
      if (!val) return;
      let api = "http://192.168.2.8:8080"; //本地
      //  let api = "http://120.25.172.133:9800"  //测试环境
      if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
        // 线上环境
        api = "https://do.do2do.com";
      }
      if (val && val.length == 1) {
        this.form.seoUrl = `${api}${val[0]}`;
      } else if (val && val.length > 1) {
        if (["/newDetail"].indexOf(val[1]) !== -1) {
          this.showBtn = true;
          this.rememberUrl = val[1];
          return;
        }
        this.form.seoUrl = `${api}${val[1]}`;
      }
    },
    handlechange() {
      this.tableVisible = true;
      this.handleQuery();
    },
    handleSelectionChange(selection) {
      this.form.seoUrl = "";
      if (selection && selection.length) {
        this.selectionId = selection[0].id;
      }
      if (selection && selection.length > 1) {
        this.$refs.GTable.$refs.table.clearSelection();
        this.$refs.GTable.$refs.table.toggleRowSelection(selection.pop());
      }
      let api = "http://192.168.2.8:8080"; //本地
      //  let api = "http://120.25.172.133:9800"  //测试环境
      if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
        // 线上环境
        api = "https://do.do2do.com";
      }
      this.form.seoUrl = `${api}${this.rememberUrl}?id=${this.selectionId}`;
      this.tableData = [];
      this.queryForm = {};
      this.tableVisible = false;
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.form.selectedIdList.indexOf("/newDetail") !== -1 &&
            !this.selectionId
          ) {
            this.$message({
              type: "warning",
              message: "选择新闻详情必须选择具体新闻",
            });
            return;
          }
          let httpAction = this.form.id ? putAction : postAction;
          httpAction(this.url.owSeoConfig, this.form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.rememberUrl = "";
      this.selectionId = "";
      this.showBtn = false;
      this.form = {};
      this.$emit("update:visible", false);
      this.$refs.form && this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.company_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}
</style>
