<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="560px"
            custom-class="c-custom-dialog"
            :before-close="handleClose"
            :close-on-click-modal="false"
            v-if="visible">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="提成性质">
                        <f-select
                                v-model="form.commission"
                                :isNeed="form.commission"
                                :dict="'commission'">
                        </f-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { putAction,getAction,getallsalesmans,getallusers,getallrealtion } from '@/api/manage'


    export default {
        name: 'UpdateCommissionDialog',
        props: {
            visible: {
                type: Boolean
            },
            title: {
                type: String,
                default: '分配'
            },
            ids: {
                type: Array,
                default: () => []
            },
            userName:{
                type :String
            }
        },
        data() {

            return {

                form: {},
                rules: {
                    // salesmanId: [
                    //     {required: true, message: '请选择业务员', trigger: 'change' },
                    // ],
                    commission: [
                        { required: true, message: '请选择客户提成性质', trigger: 'change' },
                    ],
                },
                url: {
                    updateCustomerCommission: '/customer/detail/updateCustomerCommission',
                },


            }
        },
        methods: {
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let url = this.url.updateCustomerCommission
                        let params = { cusIds: this.ids }

                        this.form.cusIds = params.cusIds;
                        putAction(url, this.form).then((res)=>{
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$parent.handleSearch();
                        })
                    }
                })
            },
            handleClose() {
                this.form = {}
                this.$emit('update:visible', false)
                this.$refs.form.resetFields()
            },

        },
        created(){

        }
    }
</script>

<style lang="scss" scoped>

</style>
