<template>
  <el-card style="min-width: 1000px;">

  </el-card>
</template>

<script>
    import { getAction,httpAction } from '@/api/manage'
    import pick from 'lodash.pick'

    export default {
      name: "orderDoc",
      components: {
      },
      data() {
        return {
          form: {},
          model: {},
          order: {},
          shipments:[],
          orderGoods:[],
          osorder:{},
          dpoints:[],
          countrys:[],
          shipmentList:[],
          ctn: 0,
          kg:0,
          cmb:0,
          key: '',
          trailerAgent: null,
          checked: false,
          labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
          },
          columns:[
            {
              title:'shipmentid',
              align:"center",
              width: 70,
              dataIndex: 'shipmentid',
              ellipsis: true,
            },
            {
              title:'referenceid',
              align:"center",
              width: 70,
              dataIndex: 'referenceid',
              ellipsis: true,
            },
            {
              title:'PCS(欧日线填)',
              align:"center",
              width: 70,
              dataIndex: 'pcs'
            },

            {
              title:'件',
              align:"center",
              width: 70,
              dataIndex: 'ctn'
            },
            {
              title:'重',
              align:"center",
              ellipsis: true,
              width: 70,
              dataIndex: 'kg'
            },
            {
              title:'体',
              align:"center",
              width: 70,
              dataIndex: 'cmb'
            },
            {
              title:'派送备注',
              align:"center",
              width: 100,
              dataIndex: 'remark',

            }
          ],
          columns1:[
            {
              title:'品名',
              align:"center",
              width: 70,
              dataIndex: 'name',
              ellipsis: true,
            },
            {
              title:'国家',
              align:"center",
              width: 70,
              dataIndex: 'country',
              ellipsis: true,
            },
            {
              title:'税号',
              align:"center",
              width: 70,
              dataIndex: 'hscode'
            },

            {
              title:'税率',
              align:"center",
              width: 70,
              dataIndex: 'taxRate'
            },
            {
              title:'数量',
              align:"center",
              ellipsis: true,
              width: 70,
              dataIndex: 'qty'
            },
            {
              title:'单价($)',
              align:"center",
              width: 70,
              dataIndex: 'unitVlaue'
            },
            {
              title:'总价($)',
              align:"center",
              width: 70,
              dataIndex: 'totalValue',

            },
            {
              title:'件数',
              align:"center",
              width: 70,
              dataIndex: 'ctn',

            },
            {
              title:'毛重',
              align:"center",
              width: 70,
              dataIndex: 'kg',

            },
            {
              title:'体积',
              align:"center",
              width: 70,
              dataIndex: 'cmb',

            },
            {
              title:'混装',
              align:"center",
              width: 70,
              dataIndex: 'mixed',

            },
            {
              title:'备注',
              align:"center",
              width: 70,
              dataIndex: 'remark',

            },
            {
              title:'预算关税',
              align:"center",
              width: 100,
              dataIndex: 'preTariff',

            },
            {
              title:'包税价格',
              align:"center",
              width: 100,
              dataIndex: 'involveTax',

            }
          ],
          url: {
            add:'fbaWarehouseOrderReceive/addSettles',
            list:'fbaOsOrder/getOrderDocDetail',

          },

        };
      },
      methods:{
          getOsOrderDetail(record){
            this.model=Object.assign({}, record);
            let that=this
            getAction(this.url.list, {'orderNo':this.model.orderNo}).then((res) => {
              if (res.success) {
                console.log(res.result.orderGoods)
                that.order=res.result.order
                that.osorder=res.result.orderDetail
                that.dpoints=res.result.dpoint
                that.countrys=res.result.countries
                that.shipments=res.result.shipments
                that.orderGoods=res.result.orderGoods
                if (that.osorder.trailerList.length > 0){
                  that.trailerAgent=that.osorder.trailerList[0].trailerAgent
                }
                if (that.osorder.needTrailer === '1'){
                  that.checked=true
                }

                if (that.osorder.shipmentType === '1' ){
                    that.key='2'
                }else {
                  that.key='1'
                }
                that.shipmentList=that.osorder.shipmentList
                let endctn = 0;
                let endkg = 0;
                let endcmb = 0;
                that.shipmentList.forEach((obj, index) => {
                  endctn = parseInt(endctn) + parseInt(obj.ctn);
                  endkg = parseFloat(endkg) + parseFloat(obj.kg);
                  endcmb = parseFloat(endcmb) + parseFloat(obj.cmb);
                })
                that.ctn=endctn
                that.kg=endkg.toFixed(2)
                that.cmb=endcmb.toFixed(3)
              }
              that.loading = false;
            })
          },
        onChange(e) {
        },
        handleChange(value) {
        },
        callback(key) {
        },
      }
    }
</script>

<style scoped>
  a:link {
    color: #808080;
    text-decoration:none;
  }
  a:visited {
    color:#808080;
    text-decoration:none;
  }
  a:hover {
    color:black;
    text-decoration:none;
  }
  a:active {
    color:#808080;
    text-decoration:none;
  }
</style>
