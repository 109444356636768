<template>
  <!-- 活动配置-费用类的基础信息 -->
  <el-form :model="baseInfo" :rules="rules" ref="baseInfo" label-width="auto">
    <el-card class="box-card">
      <div slot="header">
        <span>基础信息</span>
      </div>
      <el-row :gutter="20">
          <el-col :span="8">
              <el-form-item class="flex_form_item" prop="feeUse" :rules="rules.feeUse" label="费用用途">
                  <f-select :dataList="useList" v-model="baseInfo.feeUse"
                            :isNeed="baseInfo.feeUse">
                  </f-select>
              </el-form-item>
          </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="feeId" :rules="rules.feeId" label="费用名称">
            <f-select :dict="'bd_fee_type'" :father-val="baseInfo.feeId" :is-need="baseInfo.feeId" v-model="baseInfo.feeId" autocomplete="off" @changet="changeFee"></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="feeText" :rules="rules.feeText" label="费用描述">
            <el-input v-model.trim="baseInfo.feeText"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="price" :rules="rules.price" label="成本">
            <el-input v-model.trim="baseInfo.price" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" :rules="rules.minPrice" label="红线报价">
            <el-input v-model.trim="baseInfo.minPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="maxPrice" :rules="rules.maxPrice" label="最高消费">
            <el-input v-model.trim="baseInfo.maxPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="maxDiscounts" :rules="rules.maxDiscounts" label="指导报价">
            <el-input v-model.trim="baseInfo.maxDiscounts" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="cbmPrice" label="CBM报价">
            <el-input v-model.trim="baseInfo.cbmPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="cbmMaxDiscounts" label="CBM最低价">
            <el-input v-model.trim="baseInfo.cbmRealMinPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="cbmMaxDiscounts" label="CBM成本红线">
            <el-input v-model.trim="baseInfo.cbmMinPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="cbmMaxDiscounts" label="CBM指导报价">
            <el-input v-model.trim="baseInfo.cbmMaxDiscounts" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="kgPrice" label="KG报价">
            <el-input v-model.trim="baseInfo.kgPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="kgPrice" label="KG成本红线">
            <el-input v-model.trim="baseInfo.kgMinPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="kgMaxDiscounts" label="KG指导报价">
            <el-input v-model.trim="baseInfo.kgMaxDiscounts" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="moneyType" :rules="rules.moneyType" label="币种">
            <f-select dict="bd_cur" v-model="baseInfo.moneyType" :isNeed="baseInfo.moneyType">
            </f-select>
          </el-form-item>
        </el-col>


        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="unit" :rules="rules.unit" label="单位">
            <!--<el-input v-model.trim="baseInfo.unit"></el-input>-->
            <f-select :dataList="unitList" v-model="baseInfo.unit"
                      :isNeed="baseInfo.unit">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="writeDay" :rules="rules.writeDay" label="录入节点">
            <span>开船</span>
            <el-input class="f-m-h-10" style="width: 40%" v-model.trim="baseInfo.writeDay" type="number">
            </el-input>
            <span>天后</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="remark" :rules="rules.remark" label="备注">
            <el-input v-model.trim="baseInfo.remark" type="textarea" show-word-limit maxlength="255"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="isForceChoose" :rules="rules.isForceChoose" label="是否强制计算费用">
            <el-switch v-model="baseInfo.isForceChoose" active-value="1" inactive-value="0">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="isBatch" :rules="rules.isBatch" label="是否分批">
            <el-switch v-model="baseInfo.isBatch" active-value="1" inactive-value="0">
            </el-switch>
          </el-form-item>
        </el-col>
          <el-col :span="8">
              <el-form-item class="flex_form_item" prop="startTime" label="下单时间开始">
                  <el-date-picker v-model="baseInfo.orderTimeS" type="datetime" size="small" placeholder="请选择时间"
                                  default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" style="width: 100%;">
                  </el-date-picker>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item class="flex_form_item" prop="endTime" label="下单时间截止">
                  <el-date-picker v-model="baseInfo.orderTimeE" type="datetime" size="small" placeholder="请选择时间"
                                  default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" style="width: 100%;">
                  </el-date-picker>
              </el-form-item>
          </el-col>
      </el-row>
    </el-card>
  </el-form>
</template>
<script>
import { getAction } from '@/api/manage';
export default {
  components: {
  },
  props: {
    baseInfoInit: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      baseInfo: {},
      rules: {
        feeId: [
          { required: true, message: '请选择费用名称', trigger: "blur" },
        ],
        feeUse: [
          { required: true, message: '请选择费用用途', trigger: "blur" },
        ],
        price: [
          { required: true, message: '请输入费用报价', trigger: "blur" },
        ],
        moneyType: [
          { required: true, message: '请选择币种', trigger: "change" },
        ],
        unit: [
          { required: true, message: '请输入单位', trigger: "blur" },
        ],
        writeDay: [
          { required: true, message: '请输入录入节点', trigger: "blur" },
        ],
        isBatch: [
          { required: true, message: '请选择是否分批', trigger: "change" },
        ],
        isForceChoose: [
          { required: true, message: '请选择是否强制计算费用', trigger: "change" },
        ],
      },
      unitList: [
        { label: '根据计费单位', value: 'auto'},
        { label: '手动勾选的暂不确定数量', value: 'manual'},
        { label: '/bl', value: '/bl'},
        { label: '/ctn', value: '/ctn'},
        { label: '/kg', value: '/kg'},
        { label: '/cbm', value: '/cbm'},
        { label: '票', value: '票'},
        { label: '/cbm*天', value: '/cbm*天'},
        { label: '吨', value: '吨'},
        { label: '/小时*票', value: '/小时*票'},
        { label: '次', value: '次'},
        { label: '托', value: '托'},
        { label: '个', value: '个'},
        { label: '/次*天', value: '/次*天'},
        { label: 'pcs', value: 'pcs'},
        { label: '天', value: '天'},
        { label: '退税份数', value: '退税份数'},
      ], //单位列表
        useList: [
            { label: '成本', value: 'cost'},
            { label: '应收', value: 'receiv'},
            { label: '应付', value: 'pay'},
        ], //费用用途列表
    }
  },
  watch: {
    baseInfoInit: {
      deep: true,
      immediate: true,
      handler(val) {
        this.baseInfo = val;
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    changeFee(val) {
      this.baseInfo.feeName = val;
    },
    handleSubmit() {
      const that = this;
      return new Promise(function (resolve, reject) {
        that.$refs.baseInfo.validate((valid) => {
          if (valid) {
            resolve(that.baseInfo);
          } else {
            reject();
          }
        })
      });
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
