<template>
  <div>
    <el-dialog
        :title="title"
        :visible="visible"
        width="680px"
        top="8vh"
        custom-class="c-custom-dialog"
        :destroy-on-close="destroyOnClose"
        :close-on-click-modal="false"
        :before-close="handleClose">
      <el-form class="input_top" label-width="70px" size="medium">
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="代码">
                <el-input v-model="queryForm.code" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="中文名称">
                <el-input v-model="queryForm.cname" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="英文名称">
                <el-input v-model="queryForm.name" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询</el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button @click="addCity(scope.row)" type="text" size="small">添加城市</el-button>
            <el-button @click="lookCity(scope.row)" type="text" size="small">查看城市</el-button>
          </template>
        </template>

      </f-table>


        <span slot="footer">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </span>
    </el-dialog>


    <el-dialog
        :title="addTitle"
        :visible="visible1"
        width="680px"
        top="8vh"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="destroyOnClose"
        :before-close="handleClose1">
      <el-form :model="cityForm" ref="cityForm" label-position="left" >
        <el-row :gutter="20">
          <el-col :span="23">
              <el-form-item prop="ciyyEnName" label="城市英文" >
                <el-input v-model="cityForm.name" autocomplete="off" :maxlength="100" show-word-limit></el-input>
              </el-form-item>
            <el-form-item prop="cityName" label="城市中文" >
              <el-input v-model="cityForm.cname" autocomplete="off" :maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="code" label="代码" >
              <el-input v-model="cityForm.code" autocomplete="off" :maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
            <el-button type="primary" size="medium" @click="handleCitySubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>


    <el-dialog
        :title="lookTitel"
        :visible="visible2"
        width="680px"
        top="8vh"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="destroyOnClose"
        :before-close="handleClose2"
    >
      <el-table :data="citylist">
        <el-table-column property="code" label="代码" ></el-table-column>
        <el-table-column property="name" label="城市英文" width="200"></el-table-column>
        <el-table-column property="cname" label="城市中文" width="150"></el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="deleteCity(scope.row)" type="text" size="small">删除城市</el-button>
          </template>
        </el-table-column>

      </el-table>
      <span slot="footer">
            <el-button type="primary" size="medium" @click="handletuichuSubmit" :disabled="tuichu">退出</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getAction, postAction ,deleteAction} from '@/api/manage'
import QueryMixins from "../../../mixins/QueryMixins";
export default {
    name: 'addStateDialog',
    mixins: [QueryMixins],
    props: {
        title: {
            type: String,
            default: '请输入标题'
        },
        visible: {
            type: Boolean
        },
        destroyOnClose: {
            type: Boolean,
            default: true
        },
        id: {
            type: String
        },
        cnName: {
            type: String
        },
        // statesData:{
        //     type:Array ,
        //     default: () =>[]
        // },
        // idField: {
        //     type: String,
        //     default: 'id'
        // },
        // saveIdField: {
        //     type: String,
        //     default: 'id'
        // },
        // url: {
        //     type: Object,
        //     default: () => {
        //         return {
        //             list: '/sys/permission/list', // treeData
        //             auth: '', // 权限
        //             ok: '' // 确认保存请求的接口
        //         }
        //     }
        // }
    },
    data() {
        return {
            addTitle:'添加城市',
            lookTitel:'查看城市',
            rowId:"",
            delId:"",
            ifDis: false,
            tuichu:false,
            // id:'',
            visible1:false,
            visible2:false,
            flag: false,
            // visible:'',
            isChecked: true,
            allKeys: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            stateForm:{
                countryId:'',
                code:'',
                enName:'',
                cnName:''
            },
            cityForm:{
              name:'',
              cname:'',
              stateId:'',

            },
            citylist:[],
            statesData:[],
            dataSize:0,
            pageNo: 1,// 默认显示第几页
            totalCount: 1,// 总条数，根据接口获取数据长度(注意：这里不能为空)
            pageSizes: [10, 20, 30, 50],// 个数选择器（可修改）
            pageSize: 10,// 默认每页显示的条数（可修改）
            url: {
                list: '/sys/bdCountry/getStateList',
                deleteBatch: '/sys/bdCountry/deleteBatch',
                addState:'/sys/bdCountry/addState',
                addCity:'/sys/bdCountry/addCity',
                showState:'/sys/bdCountry/getStateList',
                showCity:'/sys/bdCountry/getCityListByStateId',
                delCity:'/sys/bdCountry/delCity',
            },
            initData:{
              // cnName : this.cnName
            },
            columns:[
              {
                prop: "code",
                label: "代码",
              },
              {
                prop: "name",
                label: "英文名称",
              },
              {
                prop: "cname",
                label: "中文名称",
              },
              {
                prop: "action",
                label: "操作",
              },
            ],
        }
    },
    watch: {
        visible(val) {
          console.log("国家名",this.cnName);
            if (val && this.cnName){
                this.queryForm.cnName = this.cnName;
                console.log(this.cnName)
                this.handleSearch();
            }
        }
    },
    methods: {

        addCity(row) {
          this.rowId=row.id
          this.visible1=true;
        },
        async lookCity(row){
          this.rowId=row.id
          this.visible2=true;
          await getAction(this.url.showCity,{
            stateId:this.rowId,
          }).then((res)=>{
            this.citylist = res.result;
            console.log(this.citylist);
          })
        },

        deleteCity(row){
          this.$confirm("确定删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
            closeOnClickModal: false,
          })
              .then(() => {
                this.delId=row.id
                //console.log(this.delId);
                deleteAction(this.url.delCity+"?stateId="+this.delId).then((res) => {
                  if(res.success){
                    this.$message({
                      type: 'success',
                      message: res.message
                    })
                    this.visible2 =false;
                  }

                })

              }).catch(() => {});

        },
        handleSubmit(){
            this.stateForm.countryId = this.id
            postAction(this.url.addState,this.stateForm).then((res) => {
                if(res.success){
                    this.$message({
                        type: 'success',
                        message: res.message

                    })
                    this.$emit('update:visible', false)
                }
            })
        },

      handleCitySubmit(){
        this.ifDis = true;
        this.cityForm.stateId = this.rowId
        postAction(this.url.addCity,this.cityForm).then((res) => {
          if(res.success){
            this.$message({
              type: 'success',
              message: res.message

            })
          this.visible1 =false;
          this.rowId ='';
          this.cityForm={};
          this.ifDis = false;
          } else {
            this.ifDis = false;
          }
        }).catch((err) => {
            this.ifDis = false;
        })
      },

      handletuichuSubmit(){
        this.visible2=false;
        this.citylist =[];
      },


      handleClose(){
            this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            // this.visible = false;

        },

      handleClose1(){
        this.visible1 = false;
        this.cityForm ={};
        //this.$refs.cityForm.resetFields();
      },
      handleClose2(){
        this.visible1 = false;
        this.visible2 = false;
        this.citylist =[];

      },
        handleSizeChange(val){
            // 改变每页显示的条数
            this.pageSize = val;
            // 点击每页显示的条数时，显示第一页
            getAction(this.url.showState,{
                cnName:this.cnName,
                pageNo:1,
                pageSize:this.pageSize,
            }).then((res)=>{
                this.statesData = res.result.records;
                this.dataSize = res.result.total;
            })
            // 注意：在改变每页显示的条数时，要将页码显示到第一页
            this.pageNo = 1
        },
        handleCurrentChange(val){
            // 改变默认的页数
            this.pageNo = val;
            // 切换页码时，要获取每页显示的条数
            getAction(this.url.showState,{
                cnName:this.cnName,
                pageNo:val,
                pageSize:this.pageSize,
            }).then((res)=>{
                this.statesData = res.result.records;
                this.dataSize = res.result.total;
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}
.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}
::v-deep {
    .el-dialog__body {
        padding: 0 0 0 20px;
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #e0efff;
    }
}
</style>
