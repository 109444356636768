<template>
    <el-dialog
            :title="billForm.id ? '编辑' : '新增'"
            :visible="visible"
            width="580px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="'right'"
                 label-width="109px">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="warehouseLocationCode" label="库位编码">
                        <el-input v-model="billForm.warehouseLocationCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="warehouseId" label="所属仓库">
                        <f-select
                                clearable
                                filterable
                                v-model="billForm.warehouseId"
                                :is-need="billForm.warehouseId"
                                dict="warehouse_list"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="库位容量" prop="warehouseLocationCapacity">
                        <el-input type="text" v-model="billForm.warehouseLocationCapacity"
                                  style="width: 250px" oninput="value=value.match(/\d+\.?\d{0,3}/)"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="locationDesc" label="库位描述">
                        <el-input type="text" v-model="billForm.locationDesc" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                <el-col :span="23">
                    <el-form-item prop="checkStatus" label="是否启用">
                        <el-select v-model="billForm.isUse">
                            <el-option value="0" label="启用"></el-option>
                            <el-option value="1" label="未启用"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                    <el-form-item prop="phone" label="联系电话">
                        <el-input type="text" v-model="billForm.phone" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction,httpAction} from '@/api/manage'
    import moment from 'moment'
    import {mobilePattern} from "@/utils/pattern";

    export default {
        name: 'noConvertBill',


        data() {
            return {
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                unusualInfoVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                lang: [
                    {required: true, message: '请选择账单语言', trigger: 'change'},
                ],
                billForm: {},
                url: {
                    add: '/interface/warehouse/addFbaWarehouseLocation',
                    edit: '/interface/warehouse/updateFbaWarehouseLocation',
                },
                rules: {
                    content: [
                        {required: true, message: '请输入库位编码', trigger: 'blur'},
                    ],
                    warehouseId: [
                        {required: true, message: '请选择所属仓库', trigger: 'change'},
                    ],
                    warehouseLocationCapacity: [
                        {required: true, message: '请输入库位量', trigger: 'change'},
                    ],
                    contact: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请驶输入联系人电话', trigger: 'blur'},
                        // {pattern: mobilePattern, message: '手机号格式有误', trigger: 'change'},
                    ],
                    isUser: [
                        {required: true, message: '请选择是否启用', trigger: 'blur'},
                    ],
                },

                settleIds: [],

            }
        },
        methods: {
            handleChange(val) {
                this.$refs["billForm"].clearValidate();//重置校验
                if (val == '1') {
                    this.unusualInfoVisible = false;
                } else {
                    this.unusualInfoVisible = true;
                }


            },
            // 提交表单
            handleSubmit() {
                let url = this.url.add;
                let method = "POST";
                console.log("父亲的parnet", this.$parent.row);
                if (this.billForm.id) {
                    url = this.url.edit;
                    method = "PUT";
                }
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        console.log('上传获取数据', this.billForm)
                        httpAction(url, method, this.billForm).then((res) => {
                            console.log(res)
                            if (res.success===true){
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.handleClose();
                                this.$emit("ok");
                            }

                        });
                    }
                });
            },
            handleClose() {
                this.$refs.billForm.resetFields();
                this.visible = false
            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(row) {
                this.billForm = {...row}
                this.visible = true
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
