<template>
  <!-- 新增编辑活动配置弹窗 -->
  <div>
    <el-dialog title="待生成订单" :visible.sync="generalOrderFlag">
      <el-form :model="queryForm" label-width="auto" style="height: 100%;">
          <el-row :gutter="20" class="inputs_row">
            <el-col :span="8">
              <el-form-item label="订单号">
                <el-input v-model="orderNo" @keyup.enter.native="handleSearch" type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" class="f-m-l-10" @click="getPreviewNoCreateList">查询</el-button>
              <!--<el-button type="primary" class="f-m-l-10" @click="handleReset">重置</el-button>-->
              <el-button type="primary" class="f-m-l-10" @click="createFeeByFeeInfoId">触发单条规则订单生成</el-button>
            </el-col>
          </el-row>
      </el-form>
      <f-table :columns="generalOrderColumns" :data="generalOrderList">
        <template #default="scope">
          <template v-if="scope.column.property === 'num'">
            {{scope.$index + 1}}
          </template>
        </template>
      </f-table>
    </el-dialog>

    <el-dialog :title="row.id ? '编辑活动配置' : '新增活动配置'" :visible.sync="visible" :close-on-click-modal="false"
      :destroy-on-close="destroyOnClose" :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="height: 100%;">
        <el-card class="box-card">
          <div slot="header">
            <span>活动信息</span>
          </div>
          <el-row :gutter="20" class="inputs_row">
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="type" label="活动类型">
                <f-select :dataList="$store.state.activityLists" v-model="form.type" :isNeed="form.type"
                  @changet="getRuleNameList">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="isEnable" label="是否启用">
                <el-switch v-model="form.isEnable" active-value="1" inactive-value="0">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="startTime" label="活动开始">
                <el-date-picker v-model="form.startTime" type="datetime" size="small" placeholder="请选择时间"
                  default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="endTime" label="活动截止">
                <el-date-picker v-model="form.endTime" type="datetime" size="small" placeholder="请选择时间"
                  default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card">
          <div slot="header">
            <span>活动规则</span>
            <el-button type="primary" class="f-m-l-10" @click="handleAdd">添加一行</el-button>
            <div>
              <el-card >
                <div style="display: flex; flex-direction: column;align-items: flex-start">
                  <el-button type="text" size="small" @click="previewRules" style="float: left;margin-right: 50px">规则预览</el-button>
                  <span style="float: left"><span style="color: green">{{tips}}</span></span>
                  <el-button type="text" size="small" @click="previewNoCreateList" style="float: left;margin-right: 50px">可生成订单</el-button>
                </div>
              </el-card>
            </div>

          </div>
          <f-table :columns="columns" :data="form.fieldRulesList">
            <template #default="scope">
              <template v-if="scope.column.property === 'action'">
                <el-button type="text" @click="handleDelete(scope.row, scope.$index)">删除</el-button>
              </template>
              <template v-else-if="scope.column.property === 'fieldDictId'">
                <el-form-item :prop="'fieldRulesList.' + scope.$index + '.fieldDictId'" label-width="0px"
                  :rules="rules.fieldDictId">
                  <el-select v-model="scope.row.fieldDictId" placeholder="请选择" :loading="loadingRule" filterable clearable
                    @change="(val) => { getOptionList(val, scope.row, scope.$index,'refresh') }" @click.native="handleRuleName">
                    <el-option v-for="item in ruleNameList" :key="item.id" :label="item.fieldName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'assignmentOperators'">
                <el-form-item :prop="'fieldRulesList.' + scope.$index + '.assignmentOperators'" label-width="0px"
                  :rules="rules.assignmentOperators" v-if="scope.row.type !== '2'">
                  <f-select :dataList="assignmentList" v-model="scope.row.assignmentOperators"
                    :isNeed="scope.row.assignmentOperators"
                    @input="(val) => { getOptionMultiple(val, scope.row, scope.$index) }">
                  </f-select>
                </el-form-item>
                <el-form-item :prop="'fieldRulesList.' + scope.$index + '.assignmentOperators'" label-width="0px" v-else>
                  <el-select v-model="scope.row.assignmentOperators" placeholder="请选择" clearable>
                    <el-option label="大于等于" value=">=">
                    </el-option>
                     <el-option label="大于" value=">">
                    </el-option>
                  </el-select>
                </el-form-item>
               
              </template>
              <template v-else-if="scope.column.property === 'fieldValue'">
                <template v-if="scope.row.type == '2'">
                   <el-form-item :prop="'fieldRulesList.' + scope.$index + '.fieldValue'" label-width="0px"
                    :rules="rules.fieldValue">
                    <el-date-picker
                        v-model="scope.row.fieldValue"
                        type="datetime"
                        size="small"
                        placeholder="请选择时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%;"
                    >
                    </el-date-picker>
                    </el-form-item>
                </template>
                <!-- 包含不包含多选 -->
                <template
                  v-else-if="(scope.row.type !== '2' && (scope.row.assignmentOperators == 'in' || scope.row.assignmentOperators == 'not in') && scope.row.show)">
                  <el-form-item :prop="'fieldRulesList.' + scope.$index + '.fieldValueList'" label-width="0px"
                    :rules="rules.fieldValue">
                    <el-select v-model="scope.row.fieldValueList" multiple placeholder="请选择" filterable clearable
                      @click.native="handleOption(scope.row, scope.$index)">
                      <el-option v-for="item in scope.row.optionList" :key="item.option" :label="item.option"
                        :value="item.option">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <!-- 其他单选 -->
                <template v-else-if="scope.row.type !== '2' && scope.row.show">
                  <el-form-item :prop="'fieldRulesList.' + scope.$index + '.fieldValue'" label-width="0px"
                    :rules="rules.fieldValue">
                    <el-select v-model="scope.row.fieldValue" placeholder="请选择" filterable clearable
                      @click.native="handleOption(scope.row, scope.$index)">
                      <el-option v-for="item in scope.row.optionList" :key="item.option" :label="item.option"
                        :value="item.option">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </template>
              <template v-else-if="scope.column.property === 'lazyEvaluation'">
                <el-form-item :prop="'fieldRulesList.' + scope.$index + '.lazyEvaluation'" label-width="0px"
                  :rules="scope.$index == form.fieldRulesList.length - 1 ? {} : rules.lazyEvaluation">
                  <f-select :dataList="logicalList" v-model="scope.row.lazyEvaluation"
                    :isNeed="scope.row.lazyEvaluation">
                  </f-select>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'evaluationLevel'">
                <el-form-item :prop="'fieldRulesList.' + scope.$index + '.evaluationLevel'" label-width="0px"
                  :rules="rules.evaluationLevel">
                  <el-input v-model.trim="scope.row.evaluationLevel" type="number"></el-input>
                </el-form-item>
              </template>
            </template>
          </f-table>
        </el-card>
      </el-form>
      <component ref="baseInfoComponents" :is="dynamic" :baseInfoInit="form.baseInfo"></component>
      <template #footer>
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="handleSubmit">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { postAction } from '@/api/manage';
import { getAction } from '@/api/manage';
export default {
  components: {
  },
  props: {
    row: {
      type: Object,
      default: () => { }
    },
    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      orderNo:[],
      generalOrderFlag:false,
      generalOrderList:[],
      queryForm:{},
      rules: {
        type: [
          { required: true, message: '请选择活动类型', trigger: "change" },
        ],
        isEnable: [
          { required: true, message: '请选择是否启用', trigger: "change" },
        ],
        fieldDictId: [
          { required: true, message: '请选择规则名', trigger: "change" },
        ],
        assignmentOperators: [
          { required: true, message: '请选择赋值', trigger: "change" },
        ],
        fieldValue: [
          { required: true, message: '请选择选项', trigger: "change" },
        ],
        lazyEvaluation: [
          { required: true, message: '请选择逻辑', trigger: "change" },
        ],
        evaluationLevel: [
          { required: true, message: '请输入并列级别', trigger: "change" },
        ],
      },
      form: {
        baseInfo: {},
        fieldRulesList: []
      },
      url: {
        detail: '/finance/activitiesInfo/',
        save: '/finance/activitiesInfo/save',
        previewRules: '/finance/fee/order/previewRules',
        getOptionByType: '/finance/fieldDict/getOptionByType',
        generalOrder:'/finance/fee/order/previewNoCreateList',
        createFeeByFeeInfoId:'/finance/fee/order/createOrderFeeByNo',
      },
      generalOrderColumns:[
        {
          prop: "num",
          label: "序号",
          sortable: "custom",

        },
        {
          prop: "orderNo",
          label: "订单号",
          sortable: "custom",

        },
        {
          prop: "shipmentCountry",
          label: "国家",
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "hasTax",
          label: "是否包税",

        },
        {
          prop: "hyNumStr",
          label: "计费数量",
        },
        {
          prop: "lclPlace",
          label: "拼柜仓库",
        },
        {
          prop: "deliveryPointName",
          label: "交货仓库",
        },
        {
          prop: "deliveryType",
          label: "派送方式",

        },
        {
          prop: "declareType",
          label: "报关方式",

        },
        {
          prop: "blNumber",
          label: "退税份数",

        }
      ],
      columns: [
        {
          prop: "fieldDictId",
          label: '规则名',
          headerType: 'require',
        },
        {
          prop: "assignmentOperators",
          label: '赋值',
          headerType: 'require',
        },
        {
          prop: "fieldValue",
          label: '选项',
          headerType: 'require',
        },
        {
          prop: "lazyEvaluation",
          label: '逻辑',
          headerType: 'require',
        },
        {
          prop: "evaluationLevel",
          label: '并列级别',
          headerType: 'require',
        },
        {
          prop: "action",
          label: '操作',
        },
      ],
      ruleNameList: [], //规则名列表
      optionList: [], //选项列表
      assignmentList: [
        { label: '等于', value: '=' },
        { label: '不等于', value: '!=' },
        { label: '包含', value: 'in' },
        { label: '不包含', value: 'not in' }
      ], //赋值列表
      logicalList: [
        { label: '且', value: 'and' },
        { label: '或', value: 'or' },
      ], //逻辑列表
      loadingRule: false,
      dynamic: '', //动态组件
      tips: '',
    }
  },
  watch: {
    visible(val) {
        this.tips = '';
      if (val) {
        this.row && this.row.id && this.getDetail();
        if(!this.row.id) {
           this.form.fieldRulesList.push({ fieldValueList: [], optionList: [], show: true });
        }
      }
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {
    // 动态加载对应的组件
    loaderDynamic() {
      return () => import('../modules/' + this.form.type + '.vue')
    }
  },
  methods: {
    previewNoCreateList(){
      this.generalOrderFlag =true
      this.getPreviewNoCreateList()
    },
    getPreviewNoCreateList(){
      postAction(this.url.generalOrder+"?feeInfoId=" + this.form.infoId + "&orderNo=" + this.orderNo).then(res=>{
        this.generalOrderList = res.result
      })
    },
    createFeeByFeeInfoId(){
      let idList = [];
      let orderNos = [];
      let orderNo ;

      idList.push(this.form.infoId)
      if( this.generalOrderList && this.generalOrderList.length === 1){
        orderNo = this.generalOrderList[0].orderNo;
      }else if(this.generalOrderList.length>0){
        orderNos = this.generalOrderList.map(order => order.orderNo);
      }
      let feeConfigVo = {
          idList: idList,
          orderNos: orderNos,
          orderNo: orderNo
      };

      if (orderNo) {
          feeConfigVo.orderNo = orderNo;
      }
      console.log("feeConfigVo",feeConfigVo)
      postAction(this.url.createFeeByFeeInfoId, feeConfigVo).then(res=>{
          this.$message.success(res.message);
      }).catch(err=>{
         this.$message.error(err.message);
      })
    },
    handleReset(){
      this.queryForm = {}
    },
    previewRules(){
      postAction(this.url.previewRules, this.form.fieldRulesList)
              .then(res=>{
                if (res.result) {
                  this.tips = res.result
                }


              })
    },
    handleRuleName() {
      if (!this.form.type) {
        this.$message.warning('请先选择活动类型');
      }
    },
    handleOption(row, index) {
      if (!row.fieldDictId) {
        this.$message.warning('请先选择规则名');
      }
    },
    // 获取规则列表
    async getRuleNameList() {
      this.ruleNameList = [];
      this.loadingRule = true;
      await getAction(this.url.getOptionByType, { type: this.form.type }).then(res => {
        this.loadingRule = false;
        this.ruleNameList = res.result;
      }).catch(err => {
        this.loadingRule = false;
      })
      this.getBaseInfo();
    },
    // 获取规则列表
    getOptionList(val, row, index,flag) {
      let obj = this.ruleNameList.find(function (item) {
        return item.id == val;
      });
      if(obj.type == '2') {
        this.form.fieldRulesList[index].type = '2'
         
      }else {
          this.form.fieldRulesList[index].type='1'
      }
      if(flag == 'refresh') {
      this.form.fieldRulesList[index].fieldValue = ''
      this.form.fieldRulesList[index].assignmentOperators = ''
      }
      this.$set(this.form.fieldRulesList[index], 'fieldName', obj.fieldName);
      this.$set(this.form.fieldRulesList[index], 'fieldId', obj.fieldId);
      this.$set(this.form.fieldRulesList[index], 'optionList', obj.options || []);
    },
    // 获取选项是否多选
    getOptionMultiple(val, row, index) {
      this.$set(this.form.fieldRulesList[index], 'assignmentOperators', val);
      if (val == 'in' || val == 'not in') {
        this.$set(this.form.fieldRulesList[index], 'fieldValue', '');
      } else {
        this.$set(this.form.fieldRulesList[index], 'fieldValueList', []);
      }
      this.$set(this.form.fieldRulesList[index], 'show', false);
      setTimeout(() => {
        this.$set(this.form.fieldRulesList[index], 'show', true);
      });
    },
    handleAdd() {
      this.form.fieldRulesList.push({ fieldValueList: [], optionList: [], show: true });
    },
    handleDelete(row, index) {
      if(this.form.fieldRulesList.length<=1){
         this.$message.warning('列表数据至少保留一项');
         return
      }
      this.form.fieldRulesList.splice(index, 1);
    },
    getDetail() {
      getAction(this.url.detail + this.row.id).then(res => {
        this.form = res.result;
        this.form.type && this.getRuleNameList().then(res => {
          this.form.fieldRulesList.forEach((item, index) => {
            this.$set(this.form.fieldRulesList[index], 'show', true);
            this.getOptionList(item.fieldDictId, item, index);
          })
          console.log(this.form.fieldRulesList, 'this.form.fieldRulesList')
        });
        this.getBaseInfo();
      })
    },
    // 获取基础信息模板
    getBaseInfo() {
      const that = this;
      if (this.form.type) {
        this.loaderDynamic().then(() => {
          // 动态加载模板
          that.dynamic = () => that.loaderDynamic();
          console.log('模板组件存在')
        }).catch((err) => {
          this.$message.warning('模板组件不存在');
          console.log('模板不存在', err)
        })
      } else {
        this.dynamic = '';
      }
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.baseInfoComponents && this.$refs.baseInfoComponents.handleSubmit().then(resa => {
            this.form.baseInfo = resa;
            console.log(this.form)
            let params = JSON.parse(JSON.stringify(this.form));
            params.fieldRulesList.forEach((item, index) => {
              delete item.optionList;
              delete item.show;
            })
            this.$store.commit("setFullscreenLoading", true);
            postAction(this.url.save, params).then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message.success(res.message);
              this.handleClose();
              this.$emit("ok");
            }).catch(err => {
              this.$store.commit("setFullscreenLoading", false);
            });
          }).catch(err => {

          })
        }
      });
    },
    handleClose() {
      this.$emit("update:visible", false);
      this.form = {
        baseInfo: {},
        fieldRulesList: []
      };
      this.dynamic = '';
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
