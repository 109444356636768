<template>
    <el-dialog
            title="修改提货费"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-button @click="addFee" type="primary" size="medium">新增一行</el-button>
        <el-table :data="tableData" class="c-th-has-bgcolor" style="width: 100%">
            <el-table-column
                prop="action"
                label="操作"
                minWidth="120"
            >
                <template slot-scope="scope">
                    <el-button type="text" @click="delTakeFee(scope.$index)">删除</el-button>
                </template>
            </el-table-column>

            <el-table-column label="供应商" width="180px">
                <template slot-scope="scope">
                    <f-select v-model="scope.row.supplierId" :is-need="scope.row.supplierId" dict="supplier_id"></f-select>
                </template>
            </el-table-column>
            <el-table-column label="提货费" width="">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.takeFee" type="number"></el-input>
                </template>
            </el-table-column>
        </el-table>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'

    export default {
        name: 'editTakeFeeModule',
        props: {
            visible: {
                type: Boolean
            },
            takeIds:{
                type:String,
                default:''
            },
            takeFee:{
                type:Number,
                default:0
            },
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    takeFee: [
                        {required: true, message: '请输入提货费', trigger: 'change'},
                    ],
                },
                url: {
                    batchUpdateUrl:"/warehouse/takeDelivery/addOrUpdateTakeFee",
                    delTakeFeeById:"/warehouse/takeDelivery/delTakeFeeById",
                    getTakeFeeByTakeId:"/warehouse/takeDelivery/getTakeFeeByTakeId",
                },
                tableData: [],

            }
        },
        watch: {
            visible(val) {
                if (val && this.takeIds) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'takeIds',this.takeIds);
                    this.$set(this.form,'takeFee',this.takeFee);
                    getAction(this.url.getTakeFeeByTakeId + "?takeId=" + this.takeIds).then((res) => {
                        this.tableData = res.result;
                    })
                }
            },
        },
        methods: {
            delTakeFee(index) {
                this.$confirm(`确定删除第${index + 1}行吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        let id = this.tableData[index].id;
                        if (id) {
                            postAction(this.url.delTakeFeeById + "?id=" + id)
                                .then(res => {
                                    this.$message.success(res.message);
                                })
                        }
                        this.tableData.splice(index, 1);
                    }).catch(() => {

                })
            },
            addFee() {
                this.tableData.push({
                    id: '',
                    takeFee: '',
                    cur: '',
                    supplierId: '',
                    supplierName: '',

                })
            },
            handleSubmit() {
                postAction(this.url.batchUpdateUrl + "?takeId=" + this.takeIds, this.tableData).then((res) => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.tableData = {};
                    this.handleClose();
                    this.$emit('ok');
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
