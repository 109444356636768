<template>
    <el-dialog
            title="开单记录详情"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" label-width="100px" size="medium">

            <el-col :span="11">
                <el-form-item label="开单编号：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.receiptsNo"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="排队号：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.queueNo"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item  label="车辆性质：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.useCarTypeDictText"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="付款方式：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.payTypeDictText"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="是否加班：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.isOvertimeDictText"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="打单份数：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.printNum"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="是否混货：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.isMixgoodsDictText"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="车型：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.carTypeDictText"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="车牌号：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.licencePlate"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="手机号：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.driverPhone"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="入仓费：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.arriveFee"></span>&nbsp;CNY
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="打单费：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.printFee"></span>&nbsp;CNY
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="加班费：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.overtimeFee"></span>&nbsp;CNY
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="混货分货费：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.mixGoodsFee"></span>&nbsp;CNY
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="卸货月台：" :label-width="formLabelWidth">
                    <span v-if="selectedRow.carType==='1'">4-5号档口</span>
                    <span v-if="selectedRow.carType==='2'">57-59号档口</span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="订单号：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.orderNos"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="开单人：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.createBy"></span>
                </el-form-item>
            </el-col>

            <el-col :span="11">
                <el-form-item label="开单时间：" :label-width="formLabelWidth">
                    <span v-text="selectedRow.createTime"></span>
                </el-form-item>
            </el-col>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">返回</el-button>

        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'

    export default {
        name: 'warehouseReceiptsDetail',
        props: {
            visible: {
                type: Boolean
            },
            selectedRow:{
                type:Object,
                default:()=>{}
            },
        },
        data() {
            return {

                formLabelWidth:'100px',
                url: {
                    add: '/warehouse/receipt/addReceiptOrder',
                },

            }
        },
        watch: {
            visible(val) {

            },
        },
        methods: {
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.form.resetFields()
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
