<template>
  <div class="main-full-content">
    <el-card class="input_top">
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        label-position="right"
        size="medium"
      >
        <el-form-item label="订单号">
          <el-input
            v-model="queryForm.orderNo"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="业务员">
          <el-select
            filterable
            v-model="queryForm.salesmanIds"
            multiple
            @change="changeSelect"
            collapse-tags
            style="margin-left: 0px; width: 200px"
            placeholder="请选择"
          >
            <el-checkbox v-model="checked" @change="selectAll"
              >全选</el-checkbox
            >
            <el-option
              v-for="item in this.dictMap.salesmanId"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客服">
          <f-select
            :dict="'docId'"
            v-model="queryForm.docId"
            :is-need="queryForm.docId"
          ></f-select>
        </el-form-item>

        <el-form-item label="操作员">
          <f-select
            :dict="'operationsId'"
            v-model="queryForm.operatorId"
            :is-need="queryForm.operatorId"
          ></f-select>
        </el-form-item>

        <el-form-item label="客户">
          <el-input
            v-model="queryForm.customer"
          ></el-input>
        </el-form-item>

        <el-form-item label="销售支持审核时间">
          <el-date-picker
            v-model="queryForm.recheckTime"
            value-format="yyyy-MM"
            format="yyyy-MM"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="业务类型">
          <f-select
            v-model="queryForm.containerType"
            :isNeed="queryForm.containerType"
            :clearable="false"
            :dataList="containerTypeList"
          >
          </f-select>
        </el-form-item>

        <el-form-item label="是否发提成">
          <el-select
            v-model="queryForm.salesmanCommission"
            filterable
            clearable
          >
            <el-option value="0" label="未发"></el-option>
            <el-option value="1" label="已发"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="核对状态">
          <f-select
            v-model="queryForm.performanceCheckStatus"
            :isNeed="queryForm.performanceCheckStatus"
            clearable
            :dataList="performanceCheckStatusList"
          >
          </f-select>
        </el-form-item>

        <el-form-item label="收款类型">
            <el-select v-model="queryForm.thirdPartyCollection"  clearable filterable>
                <el-option value="0" label="风控催收"></el-option>
                <el-option value="1" label="诉讼催收"></el-option>
                <el-option value="2" label="其他"></el-option>
            </el-select>
<!--          <f-select-->
<!--            :dict="'payment_type'"-->
<!--            v-model="queryForm.paymentType"-->
<!--            :is-need="queryForm.paymentType"-->
<!--          >-->
<!--          </f-select>-->
        </el-form-item>

        <el-form-item label="提成发放时间">
          <el-date-picker
            v-model="queryForm.salesmanCommissionTimeStr"
            value-format="yyyy-MM-dd"
            format="yyyy-MM"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="是否亏本订单">
          <el-select v-model="queryForm.isLoseMoney" clearable>
            <el-option value="1" label="是"></el-option>
            <el-option value="0" label="否"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="下单时间">
          <el-date-picker
            class="f-flex-1"
            v-model="queryForm.orderCreateTimes"
            type="monthrange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM"
            format="yyyy-MM"
            unlink-panels
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="亏本订单处理类型">
          <el-select v-model="queryForm.handleType" clearable>
            <el-option value="个人原因" label="个人原因"></el-option>
            <el-option value="非个人原因" label="非个人原因"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="亏本订单审核原因">
          <f-select
            :dict="'lose_money_reason'"
            v-model="queryForm.auditReason"
            :is-need="queryForm.auditReason"
          >
          </f-select>
        </el-form-item>

        <el-form-item label="绩效核对时间">
          <el-date-picker
            class="f-flex-1"
            v-model="queryForm.performanceCheckTimeStr"
             value-format="yyyy-MM-dd"
                                format="yyyy-MM"
                                type="month"
                                placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label-width="30px">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            >查询
          </el-button>
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div class="bbb">
        <el-button
          type="primary"
          size="medium"
          @click="exportExcelPlus(url.exportSalesmanPerformance, '业务员明细')"
          v-if="buttonShowList.dc"
          >导出
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="handleChange('0')"
          v-if="buttonShowList.hd"
          :disabled="!selectionRows.length"
          >批量核对
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="visible = true"
          v-if="buttonShowList.xg"
          :disabled="!selectionRows.length"
          >批量编辑备注
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="saveProfitAfterVerificatoin"
          v-if="buttonShowList.hdtc"
          >批量保存核对后提成
        </el-button>
      </div>

      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="63vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="checkStatus(scope.row, 'checkSaleOrderDetail')"
              v-if="buttonShowList.hd"
              :disabled="scope.row.performanceCheckStatus != null"
              >核对</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
          <template
            v-else-if="scope.column.property === 'performanceCheckStatus'"
          >
            <el-tag type="danger" v-if="scope.row.performanceCheckStatus == '0'"
              >未通过</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.performanceCheckStatus == '1'"
              >已通过</el-tag
            >
            <el-tag v-else>未核对</el-tag>
          </template>
          <template v-else-if="scope.column.property === 'containerType'">
            <span v-if="scope.row.containerType === '0'">自拼</span>
            <span v-else-if="scope.row.containerType === '1'">整柜</span>
            <span v-else-if="scope.row.containerType === '2'">非拼</span>
          </template>
          <template v-else-if="scope.column.property === 'orderType'">
            <span v-if="scope.row.orderType === '0'">正常</span>
            <span v-else>亏本</span>
          </template>
          <template v-else-if="scope.column.property === 'commission'">
            {{ parseDict(scope.row.commission, "commission") }}
          </template>
          <template v-else-if="scope.column.property === 'notes'">
            <span>{{ scope.row.notes }}</span>
          </template>
          <template
            v-else-if="scope.column.property === 'profitAfterVerification'"
          >
            <el-input
              v-model="scope.row.profitAfterVerification"
              type="number"
            ></el-input>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      title="编辑备注"
      :visible="visible"
      width="50%"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <el-form :model="form" :rules="rules" ref="form" size="medium">
        <el-row class="flex_center">
          <el-col :span="24">
            <el-form-item prop="notes" label="备注">
              <el-input
                v-model="form.notes"
                type="textarea"
                maxlength="250"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button type="primary" size="medium" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { deleteAction, postAction, httpAction } from "@/api/manage";
export default {
  name: "SalesPerDetail",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      containerTypeList: [
        {
          label: "自拼",
          value: "0",
        },
        {
          label: "整柜",
          value: "1",
        },
        {
          label: "非拼",
          value: "2",
        },
      ],
      performanceCheckStatusList: [
        {
          label: "未核对",
          value: "2",
        },
        {
          label: "未通过",
          value: "0",
        },
        {
          label: "已通过",
          value: "1",
        },
      ],
      isUsePost: true,
      checked: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          fixed: "left",
        },
        {
          prop: "performanceCheckStatus",
          label: "核对状态",
          fixed: "left",
        },
        {
          prop: "containerType",
          label: "业务类型",
          fixed: "left",
        },
        {
          prop: "orderType",
          label: "订单类型",
          fixed: "left",
        },
        {
          prop: "orderNo",
          label: "订单号",
          fixed: "left",
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "operator",
          label: "操作",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "paymentType",
          label: "收款类型",
        },
        {
          prop: "realCmb",
          label: "入仓方数",
        },
        {
          prop: "deliveryTime",
          label: "派送时间/到港时间",
        },
        {
          prop: "lastSettleTime",
          label: "销账时间",
        },
        {
          prop: "performanceCheckTime",
          label: "绩效核对时间",
        },
        {
          prop: "commission",
          label: "提成性质",
        },
        {
          prop: "paymentCycle",
          label: "回款周期",
        },
        {
          prop: "receivables",
          label: "应收",
        },
        {
          prop: "costBilling",
          label: "成本/应付",
        },
        {
          prop: "profit",
          label: "利润",
        },
        {
          prop: "profitRate",
          label: "利润率",
        },
        {
          prop: "normalProportionalCommission",
          label: "提成比例",
        },
        {
          prop: "subsidy",
          label: "补助",
        },
        {
          prop: "lossCommission",
          label: "利润/亏本提成",
        },
        {
          prop: "profitAfterVerification",
          label: "核对后利润提成",
          minWidth: 120,
        },
        {
          prop: "handleType",
          label: "亏本订单处理类型",
          minWidth: 120,
        },
        {
          prop: "auditReason",
          label: "亏本订单审核原因",
          minWidth: 120,
        },
        {
          prop: "notes",
          label: "备注",
          minWidth: 150,
        },
        {
          prop: "ruleName",
          label: "匹配规则名",
        },
      ],
      url: {
        //查询列表的接口
        list: "/order/sale/SalesOrderDetails/getSalesProfitList",
        checkOrderDetailStatus:
          "/order/sale/SalesDetailsCheck/checkOrderDetailStatus", //批量核对

        updateTtOrderExtNotes:
          "/order/sale/SalesOrderDetails/updateTtOrderExtNotes", //批量编辑备注
        exportSalesmanPerformance:
          "/order/sale/SalesOrderDetails/exportSalesmanPerformance", //导出
        updateTtOrderExtProfitAfterVerification:
          "order/sale/SalesOrderDetails/updateTtOrderExtProfitAfterVerification",
      },

      queryForm: {
        salesmanIds: [],
      },
      dictCodes: ["commission", "salesmanId"],
      form: {},
      visible: false,
      rules: {
        notes: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
    };
  },
  methods: {
    changeSelect(val) {
      if (val.length === this.dictMap.salesmanId.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    selectAll() {
      this.queryForm.salesmanIds = [];
      if (this.checked) {
        for (const key in this.dictMap.salesmanId) {
          this.queryForm.salesmanIds.push(this.dictMap.salesmanId[key].value);
        }
      } else {
        this.queryForm.salesmanIds = [];
      }
    },
    handleChange() {
      let form = {};
      const h = this.$createElement;
      form.orderNo = this.selectedRowOrderNos.join(",");
      form.hasAuthReCheck = this.buttonShowList["核对过是否继续核对"]
        ? "1"
        : "0";
      this.$msgbox({
        title: "明细记录核对",
        message: h("p", null, [
          h("i", { style: "color: #00a0e9" }, "请确认审核该明细记录"),
        ]),
        showCancelButton: true,
        distinguishCancelAndClose: true,
        confirmButtonText: "通过",
        cancelButtonLoading: false,
        confirmButtonLoading: false,
        cancelButtonText: "不通过",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            form.performanceCheckStatus = "1";
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "核对中...";
            postAction(this.url.checkOrderDetailStatus, form)
              .then((res) => {
                if (res.success) {
                  this.$message.success("核对成功");
                  this.handleQuery();
                  done();
                }
              })
              .catch(() => {
                done();
              });
          } else if (action == "cancel") {
            form.performanceCheckStatus = "0";
            instance.cancelButtonLoading = true;
            instance.cancelButtonText = "核对中...";
            postAction(this.url.checkOrderDetailStatus, form)
              .then((res) => {
                if (res.success) {
                  this.$message.success("核对成功");
                  this.handleQuery();
                  done();
                }
              })
              .catch(() => {
                done();
              });
          } else {
            done();
          }
        },
      });
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction(this.url.updateTtOrderExtNotes, {
            orderNos: this.selectionRows.map((s) => s.orderNo),
            notes: this.form.notes,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleClose();
              this.handleQuery();
            }
          });
        }
      });
    },
    checkStatus(row) {
      const h = this.$createElement;
      let form = {};
      form.orderNo = row.orderNo;
      this.$msgbox({
        title: "明细记录核对",
        message: h("p", null, [
          h("i", { style: "color: #00a0e9" }, "请确认审核该明细记录"),
        ]),
        showCancelButton: true,
        distinguishCancelAndClose: true,
        confirmButtonText: "通过",
        cancelButtonLoading: false,
        confirmButtonLoading: false,
        cancelButtonText: "不通过",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            form.performanceCheckStatus = "1";
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "核对中...";
            postAction(this.url.checkOrderDetailStatus, form)
              .then((res) => {
                if (res.success) {
                  this.$message.success("核对成功");
                  this.getSalesOrderList();
                  done();
                }
              })
              .catch(() => {
                done();
              });
          } else if (action == "cancel") {
            form.performanceCheckStatus = "0";
            instance.cancelButtonLoading = true;
            instance.cancelButtonText = "核对中...";
            postAction(this.url.checkOrderDetailStatus, form)
              .then((res) => {
                if (res.success) {
                  this.$message.success("核对成功");
                  this.getSalesOrderList();
                  done();
                }
              })
              .catch(() => {
                done();
              });
          } else {
            done();
          }
        },
      });
    },
    handleReset() {
      this.queryForm = {
        containerType: "0",
        salesmanIds: [],
      };
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    handleClose() {
      this.title = "";
      this.form = {};
      this.visible = false;
    },
    saveProfitAfterVerificatoin() {
      if (!this.tableData.length) {
        this.$message.warning("列表数据为空");
        return;
      }
      let nullNos = [];
      let errShuziNos = [];
      let errZhengshuNos = [];
      let errXiaoshuNos = [];
      let list = this.tableData.map((s) => {
        let orderNo = s.orderNo;
        if (!s.profitAfterVerification) {
          nullNos.push(orderNo);
        } else {
          let value = s.profitAfterVerification.toString();
          if (value.indexOf(".") !== -1) {
            let sp = value.split(".");
            if (sp && sp.length >= 3) {
              errShuziNos.push(orderNo);
            } else if (sp[0].length > 10) {
              errZhengshuNos.push(orderNo);
            } else if (sp[1].length > 3) {
              errXiaoshuNos.push(orderNo);
            }
          } else if (value.length > 10) {
            errZhengshuNos.push(orderNo);
          }
        }
        return {
          id: s.id,
          profitAfterVerification: s.profitAfterVerification,
          orderNo: s.orderNo,
          freightCommissions: s.freightCommissions,
        };
      });
      // 拼接 报错提示
      let errStr = "";
      if (nullNos && nullNos.length > 0) {
        errStr += nullNos.join("，") + "：核对后提成不能为空\n";
      }
      if (errShuziNos && errShuziNos.length > 0) {
        errStr += errShuziNos.join("，") + "：核对后提成不是正确的数字\n";
      }
      if (errZhengshuNos && errZhengshuNos.length > 0) {
        errStr += errZhengshuNos.join("，") + "：核对后提成最后十位整数\n";
      }
      if (errXiaoshuNos && errXiaoshuNos.length > 0) {
        errStr += errXiaoshuNos.join("，") + "：核对后提成最多3位小数\n";
      }
      if (errStr && errStr.length > 0) {
        this.$message.warning("以下数据核对后提成不对：" + errStr);
        return false;
      }
      postAction(this.url.updateTtOrderExtProfitAfterVerification, list).then(
        (res) => {
          this.$message.success(res.message);
          this.handleQuery();
        }
      );
    },
  },
  computed: {
    buttonShowList() {
      return {
        dc: this.$btnIsShow("salePerConfig/SalesPerDetail", "0", "导出"),
        xg: this.$btnIsShow("salePerConfig/SalesPerDetail", "0", "批量修改"),
        hd: this.$btnIsShow("salePerConfig/SalesPerDetail", "0", "批量核对"),
        hdtc: this.$btnIsShow(
          "salePerConfig/SalesPerDetail",
          "0",
          "批量保存核对后提成"
        ),
        '核对过是否继续核对':this.$btnIsShow('dashboard/salesmanCoefficient','0','核对过是否继续核对'),
      };
    },
  },
  created() {
    this.queryForm.containerType = "0";

  },
};
</script>

<style lang="scss" scoped>
.el-checkbox {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
.tab_class_billed {
  .el-tabs--top > .is-top {
    position: unset;
    padding-left: 0;
    width: 100%;
  }
  .el-tabs--top > .el-tabs__content {
    top: 0;
  }
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs--card > .el-tabs__header {
    border-bottom: 0;
  }
}
.height_tab_bill {
  .el-tabs__item {
    line-height: 25px;
    height: 25px;
    font-size: 15px !important;
  }
}
</style>
