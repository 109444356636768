<!--操作需求-->
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="起运港">
              <f-select v-model="queryForm.pol"
                        :isNeed="queryForm.pol"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="目的港">
              <f-select v-model="queryForm.podEnd"
                        :isNeed="queryForm.podEnd"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="渠道">
              <f-select v-model="queryForm.channelId"
                        :isNeed="queryForm.channelId"
                        placeholder="请选择" filterable clearable :dict="'channelId'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="操作">
              <f-select v-model="queryForm.operateId"
                        :isNeed="queryForm.operateId"
                        placeholder="请选择" filterable clearable :dict="'operationsId'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleOpeAdd"
                   v-if="buttonShowList['opeAdd']">操作新增
        </el-button>
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="66vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleOpeEdit(scope.row)"
                       v-if="buttonShowList['opeEdit']">操作编辑
            </el-button>
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList['channelEdit']">渠道编辑
            </el-button>
            <el-button type="text" @click="handleDele(scope.row)"
                       v-if="buttonShowList['del']">删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'pol'">
            <span>{{ parseDict(scope.row.pol, 'bd_port') }}</span>
          </template>
          <template v-else-if="scope.column.property === 'transferPort'">
            <span>{{ parseDict(scope.row.transferPort, 'bd_port') }}</span>
          </template>
          <template v-else-if="scope.column.property === 'podEnd'">
            <span>{{ parseDict(scope.row.podEnd, 'bd_port') }}</span>
          </template>
          <template v-else-if="scope.column.property === 'operateId'">
            <span>{{ parseDict(scope.row.operateId, 'operationsId') }}</span>
          </template>
          <template v-else-if="scope.column.property === 'channelId'">
            <span>{{ parseDict(scope.row.channelId, 'channelId') }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog :title="form.id?'操作编辑':'操作新增'" :visible.sync="dialogFormOperAddVisible"
               :close-on-click-modal="false"
               v-if="dialogFormOperAddVisible">
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row">
          <el-col :span="12">
            <el-form-item label="起运港" :label-width="formLabelWidth" prop="pol">
              <f-select :is-need="form.pol"
                        v-model="form.pol"
                        :fatherVal="form.pol"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="中转港" :label-width="formLabelWidth" prop="transferPort">
              <f-select :is-need="form.transferPort"
                        v-model="form.transferPort"
                        :fatherVal="form.transferPort"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEnd">
              <f-select :is-need="form.podEnd"
                        v-model="form.podEnd"
                        :fatherVal="form.podEnd"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="柜型" :label-width="formLabelWidth" prop="size">
              <f-select :is-need="form.size"
                        v-model="form.size"
                        :fatherVal="form.size"
                        placeholder="请选择" filterable clearable :dict="'cabinet_type'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="this.operateNeedQuantity">
            <el-form-item label="需求数量" :label-width="formLabelWidth" prop="operateNeedQuantity">
              <el-input v-model="form.operateNeedQuantity" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="货好时间" :label-width="formLabelWidth" prop="goodOkayTime">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.goodOkayTime"
                  type="datetime"
                  size="small"
                  placeholder="请选择时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="editStartOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="预计装柜日期" :label-width="formLabelWidth" prop="loadingTime">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.loadingTime"
                  type="datetime"
                  size="small"
                  placeholder="请选择时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="editStartOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="渠道人员" :label-width="formLabelWidth" prop="channelId">
              <f-select :is-need="form.channelId"
                        v-model="form.channelId"
                        :fatherVal="form.channelId"
                        placeholder="请选择" filterable clearable :dict="'channelId'">
              </f-select>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormOperAddVisible = false">取 消</el-button>
        <el-button type="primary" size="medium" @click="handleOpeSave()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="form.id?'渠道编辑':'渠道新增'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
               v-if="dialogFormVisible">
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row">
          <el-col :span="12">
            <el-form-item label="渠道人员" :label-width="formLabelWidth" prop="channelId">
              <f-select :is-need="form.channelId"
                        v-model="form.channelId"
                        :fatherVal="form.channelId"
                        placeholder="请选择" filterable clearable :dict="'channelId'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="so仓位号" :label-width="formLabelWidth" prop="so">
              <el-input v-model="form.so" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="柜号" :label-width="formLabelWidth" prop="containerNo">
              <el-input v-model="form.containerNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="航线" :label-width="formLabelWidth" prop="osLine">
              <el-input v-model="form.osLine" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="船司" :label-width="formLabelWidth" prop="shipCompanyId">
              <f-select :is-need="form.shipCompanyId"
                        v-model="form.shipCompanyId"
                        :fatherVal="form.shipCompanyId"
                        placeholder="请选择" filterable clearable :dict="'ship_company'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起运港" :label-width="formLabelWidth" prop="pol">
              <f-select :is-need="form.pol"
                        v-model="form.pol"
                        :fatherVal="form.pol"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="中转港" :label-width="formLabelWidth" prop="transferPort">
              <f-select :is-need="form.transferPort"
                        v-model="form.transferPort"
                        :fatherVal="form.transferPort"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEnd">
              <f-select :is-need="form.podEnd"
                        v-model="form.podEnd"
                        :fatherVal="form.podEnd"
                        placeholder="请选择" filterable clearable :dict="'bd_port'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="柜型" :label-width="formLabelWidth" prop="size">
              <f-select :is-need="form.size"
                        v-model="form.size"
                        :fatherVal="form.size"
                        placeholder="请选择" filterable clearable :dict="'cabinet_type'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="截SI" :label-width="formLabelWidth" prop="si">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.si"
                  type="datetime"
                  size="small"
                  placeholder="请选择时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="editStartOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="ETD" :label-width="formLabelWidth" prop="etd">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.etd"
                  type="datetime"
                  size="small"
                  placeholder="请选择时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="editStartOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="船名航次" :label-width="formLabelWidth" prop="shipNo">
              <el-input v-model="form.shipNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开港日期" :label-width="formLabelWidth" prop="startPortTime">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.startPortTime"
                  type="datetime"
                  size="small"
                  placeholder="请选择时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="editStartOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="延船日期" :label-width="formLabelWidth" prop="delayTime">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.delayTime"
                  type="datetime"
                  size="small"
                  placeholder="请选择时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="editStartOptions"
                  format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否甩柜" :label-width="formLabelWidth" prop="isFling">
              <f-select :is-need="form.isFling"
                        v-model="form.isFling"
                        :fatherVal="form.isFling"
                        placeholder="请选择" filterable clearable :dict="'is_default'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否跳港" :label-width="formLabelWidth" prop="isJump">
              <f-select :is-need="form.isJump"
                        v-model="form.isJump"
                        :fatherVal="form.isJump"
                        placeholder="请选择" filterable clearable :dict="'is_default'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商/代理" :label-width="formLabelWidth" prop="supplierId">
              <f-select :is-need="form.supplierId"
                        v-model="form.supplierId"
                        :fatherVal="form.supplierId"
                        placeholder="请选择" filterable clearable :dict="'bookAgent_id'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="O-F(海运费)" :label-width="formLabelWidth" prop="of">
              <el-input v-model="form.of" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="ams可否自报" :label-width="formLabelWidth" prop="isAms">
              <f-select :is-need="form.isAms"
                        v-model="form.isAms"
                        :fatherVal="form.isAms"
                        placeholder="请选择" filterable clearable :dict="'is_default'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="ams可否报多个" :label-width="formLabelWidth" prop="isAmsMore">
              <f-select :is-need="form.isAmsMore"
                        v-model="form.isAmsMore"
                        :fatherVal="form.isAmsMore"
                        placeholder="请选择" filterable clearable :dict="'is_default'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="舱单可否自报" :label-width="formLabelWidth" prop="isCangdan">
              <f-select :is-need="form.isCangdan"
                        v-model="form.isCangdan"
                        :fatherVal="form.isCangdan"
                        placeholder="请选择" filterable clearable :dict="'is_default'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合约" :label-width="formLabelWidth" prop="contract">
              <f-select :is-need="form.contract"
                        v-model="form.contract"
                        :fatherVal="form.contract"
                        placeholder="请选择" filterable clearable :dict="'contract'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签单方式" :label-width="formLabelWidth" prop="signAbill">
              <f-select :is-need="form.signAbill"
                        v-model="form.signAbill"
                        :fatherVal="form.signAbill"
                        placeholder="请选择" filterable clearable :dict="'signAbill_type'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="免柜期" :label-width="formLabelWidth" prop="mianGuiQi">
              <el-input v-model="form.mianGuiQi" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="换单费" :label-width="formLabelWidth" prop="huanDanFee">
              <el-input v-model="form.huanDanFee" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的港费用" :label-width="formLabelWidth" prop="podEndFee">
              <el-input v-model="form.podEndFee" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="押金" :label-width="formLabelWidth" prop="deposit">
              <el-input v-model="form.deposit" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="亏舱费" :label-width="formLabelWidth" prop="kuiCangFee">
              <el-input v-model="form.kuiCangFee" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
              <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人员" :label-width="formLabelWidth" prop="operateId">
              <f-select :is-need="form.operateId"
                        v-model="form.operateId"
                        :fatherVal="form.operateId"
                        placeholder="请选择" filterable clearable :dict="'operationsId'">
              </f-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" size="medium" @click="handleSave()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import QueryMixins from "../../mixins/QueryMixins";
import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
import {validateCmb, validateShuliang} from "../../utils/validator";

export default {
  name: 'Potential',
  mixins: [QueryMixins],
  data() {
    return {
      editStartOptions: {
        disabledDate: time => {
          if (!this.queryForm.createTimeEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.queryForm.createTimeEnd);
          }
        }
      },
      dictCodes: ['ship_company', 'bd_port', 'is_default', 'operationsId', 'channelId', 'bookAgent_id'],
      rules: {
        channelId: [
          {required: true, message: '请选择渠道人员', trigger: 'change'},
        ],
        name: [
          {max: 200, message: '长度不能超过200', trigger: 'blur'},
        ],
        so: [
          {required: true, message: '请输入仓位号', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'},
        ],
        containerNo: [
          {max: 50, message: '长度不能超过50', trigger: 'blur'},
        ],
        osLine: [
          {max: 100, message: '长度不能超过100', trigger: 'blur'},
        ],
        shipCompanyId: [
          {required: true, message: '请选择船司', trigger: 'change'},
        ],
        pol: [
          {required: true, message: '请选择起运港', trigger: 'change'},
        ],
        podEnd: [
          {required: true, message: '请选择目的港', trigger: 'change'},
        ],
        size: [
          {required: true, message: '请选择柜型', trigger: 'change'},
        ],
        si: [
          {required: true, message: '请选择截SI', trigger: 'change'},
        ],
        etd: [
          {required: true, message: '请选择ETD', trigger: 'change'},
        ],
        shipNo: [
          {required: true, message: '请输入船名航次', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'},
        ],
        supplierId: [
          {required: true, message: '请选择订舱代理', trigger: 'change'},
        ],
        of: [
          {required: true, message: '请输入OF(海运费)', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'blur'},
        ],
        isAms: [
          {required: true, message: '请选择ams可否自报', trigger: 'change'},
        ],
        isAmsMore: [
          {required: true, message: '请选择ams可否报多个', trigger: 'change'},
        ],
        signAbill: [
          {required: true, message: '请选择签单方式', trigger: 'change'},
        ],
        mianGuiQi: [
          {validator: validateShuliang, trigger: 'change'}
        ],
        huanDanFee: [
          {validator: validateCmb, trigger: 'change'}
        ],
        podEndFee: [
          {validator: validateCmb, trigger: 'change'}
        ],
        deposit: [
          {validator: validateCmb, trigger: 'change'}
        ],
        kuiCangFee: [
          {validator: validateCmb, trigger: 'change'}
        ],
        remark: [
          {max: 255, message: '长度不能超过255', trigger: 'blur'},
        ],
        operateNeedQuantity: [
          {required: true, message: '请输入数量', trigger: 'blur'},
          {validator: validateShuliang, trigger: 'change'}
        ],
        goodOkayTime: [
          {required: true, message: '请选择货好时间', trigger: 'change'},
        ],
      },

      ids: [],
      row: {},
      form: {},
      formLabelWidth: '120px',
      //操作新增编辑
      dialogFormOperAddVisible: false,
      //渠道编辑
      dialogFormVisible: false,

      columns: [
        {
          prop: 'action',
          label: '操作',
          width: 100,
        },
        {
          prop: 'pol',
          label: '起运港',
          sortable: 'custom',
          width: 150
        },
        {
          prop: 'transferPort',
          label: '中转港',
          sortable: 'custom',
          width: 150
        },
        {
          prop: 'podEnd',
          label: '目的港',
          sortable: 'custom',
          width: 150
        },
        {
          prop: 'size',
          label: '柜型',
          sortable: 'custom',
          minWidth: 100
        },
        {
          prop: 'goodOkayTime',
          label: '货好时间',
          sortable: 'custom',
          minWidth: 140
        },
        {
          prop: 'loadingTime',
          label: '装柜日期',
          sortable: 'custom',
          minWidth: 140
        },
        {
          prop: 'remark',
          label: '备注',
          sortable: 'custom',
          minWidth: 100
        },
        {
          prop: 'channelId',
          label: '渠道',
          sortable: 'custom',
          minWidth: 100
        },
        {
          prop: 'operateId',
          label: '操作',
          sortable: 'custom',
          minWidth: 100
        },
      ],
      url: {
        list: '/sys/channelShipManage/list',
        add: '/sys/channelShipManage/add',
        edit: '/sys/channelShipManage/edit',
        getById: '/sys/channelShipManage/queryById',
        delete: '/sys/channelShipManage/delete',
        cancel: '/sys/channelShipManage/cancel',
        back: '/sys/channelShipManage/back',
        opeAdd: '/sys/channelShipManage/opeAdd',
      },
      operateNeedQuantity: false,
      initData: {isSo: '0'}
    }
  },
  methods: {
    //操作新增
    handleOpeAdd() {
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate();
      }
      this.$set(this, 'form', {});
      this.operateNeedQuantity = true;
      this.dialogFormOperAddVisible = true;
    },
    //操作编辑
    handleOpeEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.operateNeedQuantity = false;
      this.dialogFormOperAddVisible = true;
    },
    //渠道编辑
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    handleDele(row) {
      this.$confirm(`确定删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.delete, {id: row.id}).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => {
          });
    },
    //渠道编辑
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          } else {
            postAction(this.url.add, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          }
        }
      });
    },

    //操作新增
    handleOpeSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.operateNeedQuantity == 0) {
            this.$message.warning('需求数量必须 >= 1');
            return;
          }
          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormOperAddVisible = false;
                this.handleQuery();
              }
            });
          } else {
            postAction(this.url.opeAdd, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormOperAddVisible = false;
                this.handleQuery();
              }
            });
          }
        }
      });


    }
  }, created() {
    this.queryForm.isSo = '0';
  },
  computed: {
    buttonShowList() {
      return {
        'opeAdd':this.$btnIsShow('channel/ChannelOperNeed','0','操作新增'),
        'opeEdit':this.$btnIsShow('channel/ChannelOperNeed','0','操作编辑'),
        'channelEdit':this.$btnIsShow('channel/ChannelOperNeed','0','渠道编辑'),
        'del':this.$btnIsShow('channel/ChannelOperNeed','0','删除'),
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
