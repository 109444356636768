<!--待提交-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                                      type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="订舱代理">
                            <f-select
                                    v-model="queryForm.bookAgentId"
                                    :isNeed="queryForm.bookAgentId"
                                    :dict="'bookAgent_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订舱单号">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                        <el-form-item label="ETA">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.eta"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etaEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                            <f-select v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>


                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15">
                <div>
                    <!--<el-button size="medium" type="primary" @click="jumpBindBillView">批量放货</el-button>-->
                    <el-button type="primary" size="medium" @click="telexReleaseBetch()"
                               v-if="buttonShowList.fh"
                               :disabled="selectedRowKeys.length === 0" >批量放货</el-button>
                </div>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row) "  :disabled="scope.row.waitTelexRelease === '1'"
                                   v-if="buttonShowList.fh">放货</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'telexReleaseStatus'">
                        <span style="color: red" v-if = "scope.row.waitTelexRelease === '0' ">未放货</span>
                    </template>


                    <template v-else-if="scope.column.property === 'weekStatus'">
                        <span v-if="scope.row.weekStatus === '0'">自拼</span>
                        <span v-if="scope.row.weekStatus === '1'">非自拼</span>
                        <span v-if="scope.row.weekStatus === '2'">传统物流</span>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.id)">{{ scope.row.week }}</a>
                        <!-- 换成a标签，支持复制 -->
                        <!-- <el-button type="text" @click="infomation(scope.row.id)">{{scope.row.week}}</el-button> -->
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog title="到港" :visible.sync="ArrivalAtPortFromVisible" :close-on-click-modal="false" append-to-body>
            <el-form :model="ArrivalAtPortFrom" ref="ArrivalAtPortFrom" :rules="rules">
                <el-form-item label="ETA" :label-width="formLabelWidth" prop="eta">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.eta"
                            type="date"
                            size="small"
                            placeholder="请选择"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :disabled="true"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="到港 OR 延误" :label-width="formLabelWidth" prop="arrivalAtPortType">
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="ArrivalAtPortFrom.arrivalAtPortType"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="到港" value="0"></el-option>
                        <el-option key="1" label="中转延误" value="1"></el-option>
                        <el-option key="2" label="目的港延误" value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="港口" :label-width="formLabelWidth" prop="arriveType"
                              v-if="ArrivalAtPortFrom.arrivalAtPortType == 0">
                    <template>
                        <el-radio-group v-model="ArrivalAtPortFrom.arriveType">
                            <el-radio :label="'0'">中转港</el-radio>
                            <el-radio :label="'1'">目的港</el-radio>
                        </el-radio-group>
                    </template>
                </el-form-item>

                <el-form-item label="二程开船时间" :label-width="formLabelWidth" prop="secondSailTime"
                              v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==0">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.secondSailTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="二程到港时间" :label-width="formLabelWidth" prop="secondEtaTime"
                              v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==0">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.secondEtaTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item
                        label="清关地点"
                        :label-width="formLabelWidth"
                        prop="customsClearanceLocation"
                        v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==1"
                >
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="ArrivalAtPortFrom.customsClearanceLocation"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="港口" value="0"></el-option>
                        <el-option key="1" label="监管仓" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="实际到港时间"
                        :label-width="formLabelWidth"
                        prop="arrivalAtPortTime"
                        v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==1"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.arrivalAtPortTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                        label="卸船时间"
                        :label-width="formLabelWidth"
                        prop="unShipTime"
                        v-if="ArrivalAtPortFrom.arrivalAtPortType == 1"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.unShipTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                        label="延误原因"
                        :label-width="formLabelWidth"
                        prop="customsClearanceLocation"
                        v-if="ArrivalAtPortFrom.arrivalAtPortType == 1"
                >
                    <!--                    <el-select-->
                    <!--                            filterable-->
                    <!--                            class="f-full-width"-->
                    <!--                            v-model="ArrivalAtPortFrom.customsClearanceLocation"-->
                    <!--                            placeholder="请选择"-->
                    <!--                    >-->
                    <!--                        <el-option key="0" label="中转港堆场拥堵" value="0"></el-option>-->
                    <!--                        <el-option key="1" label="工人罢工" value="1"></el-option>-->
                    <!--                    </el-select>-->
                    <f-select
                            v-model="ArrivalAtPortFrom.customsClearanceLocation"
                            :dict="'yanchiReason'"
                            :isNeed="ArrivalAtPortFrom.customsClearanceLocation"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item
                        label="上火车周数"
                        :label-width="formLabelWidth"
                        prop="trainWeek"
                        v-if="ArrivalAtPortFrom.arrivalAtPortType == 1"
                >
                    <el-input v-model="ArrivalAtPortFrom.trainWeek"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="ArrivalAtPortFromVisible = false"
                >取 消
                </el-button>
                <el-button size="medium" type="primary" @click="saveArrivalAtPort('1')"
                >确 定
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="靠泊" :visible.sync="KaoBoFromVisible" :close-on-click-modal="false">
            <el-form :model="form" ref="kaoBoFrom" :rules="rules">
                <el-form-item label="船靠泊时间" :label-width="formLabelWidth" prop="berthingTime">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.berthingTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="KaoBoFromVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="kaoBoSave()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="卸船" :visible.sync="XieChuanFromVisible" :close-on-click-modal="false">
            <el-form :model="form" ref="xieChuanFrom" :rules="rules">
                <el-form-item label="卸船时间" :label-width="formLabelWidth" prop="shipUnloading">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.shipUnloading"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="XieChuanFromVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="xieChuanSave()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="国外清关" :visible.sync="CustomsClearanceFromVisible" :close-on-click-modal="false" :before-close="handleBeforeClose">
            <el-form
                    :model="ArrivalAtPortFrom"
                    ref="guowaiForm"
                    :rules="rules"
            >
                <el-form-item
                        label="清关类型"
                        :label-width="formLabelWidth"
                        prop="customsClearanceType"
                >
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="ArrivalAtPortFrom.customsClearanceType"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="预清关出税（到港前清关）" value="0">
                        </el-option>
                        <el-option key="1" label="国外清关（到港后清关）" value="1">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="清关地点"
                        :label-width="formLabelWidth"
                        prop="customsClearanceLocation"
                        v-if="ArrivalAtPortFrom.customsClearanceType == '1'"
                >
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="ArrivalAtPortFrom.customsClearanceLocation"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="港口" value="0"></el-option>
                        <el-option key="1" label="监管仓" value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="CustomsClearanceFromVisible = false"
                >取 消
                </el-button>
                <el-button size="medium" type="primary" :loading="CustomsClearanceLoad" @click="saveArrivalAtPort('3')"
                >{{CustomsClearanceLoad ? '正在处理' : '确 定'}}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="国外清关结果" :visible.sync="ClearanceResultsFrowVisible" :close-on-click-modal="false">
            <el-form :model="ArrivalAtPortFrom" ref="clearanceResultsFrom" :rules="rules">
                <el-form-item label="清关结果" :label-width="formLabelWidth" prop="customsClearanceResult">
                    <f-select
                            v-model="ArrivalAtPortFrom.customsClearanceResult"
                            :dict="'clearAgent_result'"
                            :is-need="ArrivalAtPortFrom.customsClearanceResult"
                    ></f-select>
                </el-form-item>
                <el-form-item label="清关放行时间" :label-width="formLabelWidth" prop="clearTime"
                              v-if="ArrivalAtPortFrom.customsClearanceResult == '4' ">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.clearTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="查验类型" :label-width="formLabelWidth" prop="checkTheType"
                              v-if="ArrivalAtPortFrom.customsClearanceResult == '5'">
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="ArrivalAtPortFrom.checkTheType"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="文件审查" value="0"></el-option>
                        <el-option key="1" label="X光查验" value="1"></el-option>
                        <el-option key="2" label="人工查验" value="2"></el-option>
                        <el-option key="3" label="其它" value="3"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                        label="清关查验预估时间(周)"
                        :label-width="formLabelWidth"
                        prop="checkWeek"
                        v-if="ArrivalAtPortFrom.customsClearanceResult == '5'"
                >
                    <el-input v-model="ArrivalAtPortFrom.checkWeek" type="number"></el-input>
                </el-form-item>
                <el-form-item
                        label="查验单"
                        :label-width="formLabelWidth"
                        prop="checkedFileList"
                        v-if="ArrivalAtPortFrom.customsClearanceResult == '5'"
                >
                    <f-upload
                            class="f-full-width"
                            v-model="ArrivalAtPortFrom.checkedFileList"
                            :isUseOss="true"
                    ></f-upload>
                </el-form-item>

                <el-form-item label="查验结果" :label-width="formLabelWidth" prop="abroadClearResult"
                              v-if="ArrivalAtPortFrom.customsClearanceResult=='6'">
                    <f-select
                            v-model="ArrivalAtPortFrom.abroadClearResult"
                            :dict="'abroad_clear_result'"
                            :is-need="ArrivalAtPortFrom.abroadClearResult"
                    ></f-select>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="ClearanceResultsFrowVisible = false"
                >取 消
                </el-button>
                <el-button size="medium" type="primary" @click="saveArrivalAtPort('2')"
                >确 定
                </el-button>
            </div>
        </el-dialog>

        <el-dialog :title="'放货   '+this.form.week" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   v-if="dialogFormVisible" width="600px">
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleSave">放货</el-button>
            </div>
        </el-dialog>


        <el-dialog title="送货代理" :visible.sync="DeliveryFromVisible" :close-on-click-modal="false">
            <el-form :model="form" ref="deliveryFrom" :rules="rules">
                <el-form-item label="送货代理" :label-width="formLabelWidth" prop="deliveryAgentId">
                    <f-select
                            v-model="form.deliveryAgentId"
                            :dict="'deliveryAgent_id'"
                            :is-need="form.deliveryAgentId"
                    ></f-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="DeliveryFromVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="deliverySave()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="批量更新待到港" :visible.sync="BatchFromVisible" :close-on-click-modal="false">
            <el-form :model="form" ref="deliveryFrom" :rules="rules">
                <el-form-item label="到港时间" :label-width="formLabelWidth" prop="deliveryAgentId">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="ArrivalAtPortFrom.arrivalAtPortTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="BatchFromVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="deliverySave()">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                fileList: [],
                tip: "",
                rules: {
                    deliveryAgentId: [
                        {required: true, message: "请选择送货代理", trigger: "change"},
                    ],
                    secondEtaTime: [
                        {required: true, message: "请选择二程到港", trigger: "change"},
                    ],
                    secondSailTime: [
                        {required: true, message: "请选择二程开船", trigger: "change"},
                    ],
                    arriveType: [
                        {required: true, message: "请选择港口", trigger: "change"},
                    ],
                    berthingTime: [
                        {required: true, message: "请选择靠泊时间", trigger: "change"},
                    ],
                    shipUnloading: [
                        {required: true, message: "请选择卸船时间", trigger: "change"},
                    ],
                    arrivalAtPortType: [
                        {required: true, message: "请选择到港or延误", trigger: "change"},
                    ],
                    customsClearanceLocation: [
                        {required: true, message: "请选择清关地点", trigger: "change"},
                    ],
                    arrivalAtPortTime: [
                        {required: true, message: "请选择实际到港时间", trigger: "change"},
                    ],
                    unShipTime: [
                        {required: true, message: "请选择卸船时间", trigger: "change"},
                    ],
                    reasonDelay: [
                        {required: true, message: "请选择延误原因", trigger: "change"},
                    ],
                    customsClearanceType: [
                        {required: true, message: "请选择清关类型", trigger: "change"},
                    ],
                    customsClearanceResult: [
                        {required: true, message: "请选择清关结果", trigger: "change"},
                    ],
                    clearTime: [
                        {required: true, message: "请选择放行时间", trigger: "change"},
                    ],
                    checkTheType: [
                        {required: true, message: "请选择查验类型", trigger: "change"},
                    ],
                    trainWeek: [
                        {required: true, message: "请输入上火车周数", trigger: "change"},
                        {
                            validator: (rule, value, callback) => {
                                if (/^(?:[1-9]\d*|0)$/.test(value) == false) {
                                    callback(new Error("请输入正整数"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ],
                    checkWeek: [
                        {
                            required: true,
                            message: "请输入国外清关查验周数",
                            trigger: "change",
                        },
                        {
                            validator: (rule, value, callback) => {
                                if (/^(?:[1-9]\d*|0)$/.test(value) == false) {
                                    callback(new Error("请输入正整数"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ],
                },
                ids: [],
                row: {},
                form: {},
                selectRow: {},
                uploadForm: {},
                ArrivalAtPortFrom: {},
                ArrivalAtPortFromVisible: false,
                CustomsClearanceFromVisible: false,
                CustomsClearanceLoad: false,
                ClearanceResultsFrowVisible: false,
                KaoBoFromVisible: false,
                XieChuanFromVisible: false,
                DeliveryFromVisible: false,
                BatchFromVisible: false,
                formLabelWidth: "150px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 100
                    },
                    {
                        prop: "telexReleaseStatus",
                        label: "放货状态",
                        width: 100
                    },
                    {
                        prop: "week",
                        label: "周数",

                    },
                    {
                        prop: "bookAgent",
                        label: "订舱代理",

                    },
                    {
                        prop: "eta",
                        label: "ETA",

                        width: 150,
                    },
                    {
                        prop: "size",
                        label: "柜型",

                        width: 100,
                    },
                    {
                        prop: "bookNo",
                        label: "订舱单号",

                        width: 140,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",

                        width: 150,
                    },
                    {
                        prop: "originalNo",
                        label: "封条",

                        width: 100,
                    },
                    {
                        prop: "weekStatus",
                        label: "是否自拼",
                        minWidth: 100,

                    },
                ],
                url: {
                    list: "/operation/container/WaitTelexReleaseList",
                    telexRelease:"/operation/container/updateTelexRelease",
                },
                countryList: [],
                addressList: [],
                selectedRowOrderNos: [],
                isUsePost:true,
            };
        },
        methods: {
            jumpBindBillView(){


            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                // this.$router.push("/operation/cabinet/Edit?id=" + id);
                // console.log(id);
                // // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/ToPortEdit');
                this.$router.push("/operation/cabinet/ToPortEdit?id=" + id);
                console.log(id);
            },
            handlePreview(file) {
                console.log("文件的url", file.url);

                if (!file.url) {
                    this.$message.error("下载失败");
                    return;
                }

                const type = file.url.substring(file.url.lastIndexOf(".") + 1);
                console.log("type", type);
                if (
                    type === "jpg" ||
                    type === "jpeg" ||
                    type === "img" ||
                    type === "gif" ||
                    type === "png" ||
                    type === "pneg"
                ) {
                    console.log("是图片预览", file.url);
                    this.imgUrl = file.url;
                    this.visible = true;
                }
                // 判断文件类型
                else if (
                    type === "doc" ||
                    type === "docx" ||
                    type === "xlsx" ||
                    type === "xls" ||
                    type === "ppt" ||
                    type === "pptx"
                ) {
                    console.log("不是图片下载", file.url);
                    // 在当前浏览器直接下载
                    document.location.href = file.url;
                } else {
                    // 图片在浏览器打开 新的页面展示
                    window.open(file.url, "hello");
                }
            },
            handleRemove(file) {
                console.log(file);
                if (file.id) {
                    const index = this.fileList.indexOf(file);
                    const {id} = this.fileList[index];
                    deleteAction("/sys/oss/file/delete", {id})
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                            }
                        })
                        .catch((err) => {
                            this.$emit("delete", index);
                        });
                    //如果传递参数为合同，则删除合同
                }
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            onexceed() {
                this.$message.warning("最多上传1个文件哦,亲");
            },
            //导入应收
            handleUpload(data) {
                const formData = new FormData();
                // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
                formData.append("file", data.file);
                formData.append("containerId", data.data.id);
                formData.append("type", '0');
                postAction(this.url.upload, formData).then((res) => {
                    if (res.success) {
                        this.$message.warning("应收分税正在导入，请耐心等待，稍后请到消息中心查看！");
                        // this.sailForm.fileId = res.result.id;
                        res.result && res.result.id ? this.fileList.push(res.result) : '';
                    }
                });
            },
            //导入应付
            handleUploadPay(data) {
                const formData = new FormData();
                // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
                formData.append("file", data.file);
                formData.append("containerId", data.data.id);
                formData.append("type", '1');
                postAction(this.url.upload, formData).then((res) => {
                    if (res.success) {
                        this.$message.warning("应付分税正在导入，请耐心等待，稍后请到消息中心查看！");
                        // this.sailForm.fileId = res.result.id;
                        res.result && res.result.id ? this.fileList.push(res.result) : '';
                    }
                });
            },
            saveArrivalAtPort(type) {
                //确定后台会发送什么样的短信信息
                //到港
                let refName = '';
                if (type == "1") {
                    refName = 'ArrivalAtPortFrom';
                    //中转港
                    if (this.ArrivalAtPortFrom.arrivalAtPortType == "0" && this.ArrivalAtPortFrom.arriveType == 0) {
                        this.transferPort();
                        return;
                    } else {
                        //目的港
                        if (this.ArrivalAtPortFrom.arrivalAtPortType == "0") {
                            this.ArrivalAtPortFrom.sendEmailType = "1";
                        } else if (this.ArrivalAtPortFrom.arrivalAtPortType == "1") {
                            this.ArrivalAtPortFrom.sendEmailType = "2";
                        } else if (this.ArrivalAtPortFrom.arrivalAtPortType == "2") {
                            this.ArrivalAtPortFrom.sendEmailType = "3";
                        }
                    }
                } else if (type == "2") {
                    refName = 'clearanceResultsFrom';
                    if (this.ArrivalAtPortFrom.customsClearanceResult == "4") {
                        this.ArrivalAtPortFrom.sendEmailType = "6";
                    } else if (this.ArrivalAtPortFrom.customsClearanceResult == "5") {
                        this.ArrivalAtPortFrom.sendEmailType = "7";
                    } else if (this.ArrivalAtPortFrom.customsClearanceResult == "6") {
                        this.ArrivalAtPortFrom.sendEmailType = "8";
                    }
                    let list = this.ArrivalAtPortFrom.checkedFileList;
                    if (list && list.length>0) {
                        postAction('/order/os/file/saveFileListWithContainerId?containerId='+this.ArrivalAtPortFrom.containerId+"&checkedFileIds="+this.ArrivalAtPortFrom.checkedFileIds+"&type=38",list)
                            .then(res=>{
                            })
                        this.ArrivalAtPortFrom.checkedFileIds=list.map(l=>l.id).join(",");
                    }
                } else if (type == "3") {
                    refName = 'guowaiForm';
                    if (this.ArrivalAtPortFrom.customsClearanceType == "0") {
                        this.ArrivalAtPortFrom.sendEmailType = "4";
                    } else if (this.ArrivalAtPortFrom.customsClearanceType == "1") {
                        this.ArrivalAtPortFrom.sendEmailType = "5";
                        this.ArrivalAtPortFrom.arrivalAtPortType = "0";
                    }
                }

                this.ArrivalAtPortFrom.containerId = this.selectRow.id;
                // if (this.ArrivalAtPortFrom.customsClearanceType == "0") {
                //     this.ArrivalAtPortFrom.customsClearanceLocation = "0";
                // }
                console.log('国外清关',this.ArrivalAtPortFrom);
                this.$refs[refName].validate((valid) => {
                    if (valid) {
                        this.CustomsClearanceLoad = true;
                        console.log(this.ArrivalAtPortFrom);
                        this.ArrivalAtPortFrom.complianceTime = this.selectRow.complianceTime;
                        putAction(this.url.addArrivalAtPort, this.ArrivalAtPortFrom)
                            .then((res) => {
                                if (res.success) {
                                    this.CustomsClearanceLoad = false;
                                    this.$message.success(res.message);
                                    this.ArrivalAtPortFromVisible = false;
                                    this.CustomsClearanceFromVisible = false;
                                    this.ClearanceResultsFrowVisible = false;
                                    this.handleSearch();
                                } else {
                                    this.CustomsClearanceLoad = false;
                                }
                            }).catch(err => {
                            this.CustomsClearanceLoad = false;
                        })
                    } else {
                        return false;
                    }
                });

            },
            handleBeforeClose() {
                this.CustomsClearanceFromVisible = false;
                this.CustomsClearanceLoad = false;
            },
            handprofitAndTax(row) {
            },
            handClearanceResults(row) {
                this.selectRow = JSON.parse(JSON.stringify(row));
                this.ArrivalAtPortFrom = {};
                getAction(this.url.getArrivalAtPort, {
                    containerId: this.selectRow.id,
                    complianceTime: this.selectRow.complianceTime,
                })
                    .then((res) => {
                        if (res.success) {
                            console.log(res.result);
                            if (res.result != null) {
                                this.ArrivalAtPortFrom = res.result;
                                if (res.result.customsClearanceType == null) {
                                    this.$set(
                                        this.ArrivalAtPortFrom,
                                        "customsClearanceResult",
                                        "4"
                                    );
                                }
                            }
                        }
                        this.ClearanceResultsFrowVisible = true;
                    }).catch(err => {
                    this.ClearanceResultsFrowVisible = true;
                })
            },
            handCustomsClearance(row) {
                this.selectRow = JSON.parse(JSON.stringify(row));
                this.ArrivalAtPortFrom = {};
                getAction(this.url.getArrivalAtPort, {
                    containerId: this.selectRow.id,
                    complianceTime: this.selectRow.complianceTime,
                })
                    .then((res) => {
                        if (res.success) {
                            console.log(res.result);
                            if (res.result != null) {
                                this.ArrivalAtPortFrom = res.result;
                                if (res.result.customsClearanceType == null) {
                                    this.$set(this.ArrivalAtPortFrom, "customsClearanceType", "0");
                                }
                            }
                        }
                        this.CustomsClearanceFromVisible = true;
                    }).catch(err => {
                    this.CustomsClearanceFromVisible = true;
                })
            },
            handArrivalAtPort(row) {
                this.selectRow = JSON.parse(JSON.stringify(row));
                this.ArrivalAtPortFrom = {};
                console.log(row);
                getAction(this.url.getArrivalAtPort, {
                    containerId: this.selectRow.id,
                    complianceTime: this.selectRow.complianceTime,
                })
                    .then((res) => {
                        if (res.success) {
                            if (res.result != null) {
                                this.ArrivalAtPortFrom = res.result;
                                this.ArrivalAtPortFrom.pod = row.podCname;
                                this.ArrivalAtPortFrom.eta= row.eta;
                            }
                        }
                        this.ArrivalAtPortFromVisible = true;
                    }).catch(err => {
                    this.ArrivalAtPortFromVisible = true;
                })
                this.ArrivalAtPortFrom.pod = row.podCname;
                this.ArrivalAtPortFrom.eta= row.eta;
            },
            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {}
                this.queryForm.pageType = "1";
                this.ipagination.currentPage = 1;
                this.queryForm.waitTelexRelease = "0";
                if (this.$refs['GTable']) {
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            handKaoBo(row) {
                this.form.containerId = row.id;
                this.KaoBoFromVisible = true;
            },
            telexRelease(row) {
                /*axios.post('/operation/container/telexRelease', {
                    buttonValue: this.form.containerId = row.id
                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.error(error);
                    });*/

                const containerId = row.id;
                putAction("/operation/container/telexRelease"+'?containerId='+containerId)
                    .then((res)=>{
                        if (res.success){
                            this.$message.success(res.message)
                        }
                    }).catch((err) => {
                    this.$emit("put",containerId)
                })
                this.handleSearch();
            },
            handXieChuan(row) {
                this.form.containerId = row.id;
                this.XieChuanFromVisible = true;
            },
            //中转港不影响流程，只新增柜子的状态
            transferPort() {
                this.$refs["ArrivalAtPortFrom"].validate((valid) => {
                    if (valid) {
                        console.log(this.ArrivalAtPortFrom);
                        this.ArrivalAtPortFrom.type = '1';
                        postAction(this.url.osContainerTransitPort, this.ArrivalAtPortFrom)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.ArrivalAtPortFromVisible = false;
                                    this.CustomsClearanceFromVisible = false;
                                    this.ClearanceResultsFrowVisible = false;
                                    this.handleSearch();
                                }
                            })
                    } else {
                        return false;
                    }
                });

            },
            //靠泊
            kaoBoSave() {
                this.$refs["kaoBoFrom"].validate((valid) => {
                    if (valid) {
                        this.form.type = '2';
                        postAction(this.url.osContainerTransitPort, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.KaoBoFromVisible = false;
                                }
                            })
                    } else {
                        return false;
                    }
                })

            },
            //卸船
            xieChuanSave() {
                this.$refs["xieChuanFrom"].validate((valid) => {
                    if (valid) {
                        this.form.type = '3';
                        postAction(this.url.osContainerTransitPort, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.XieChuanFromVisible = false;
                                }
                            })
                    } else {
                        return false;
                    }
                })
            },
            handDelivery(row) {
                this.$set(this, 'form', {});
                this.$set(this.form, 'id', row.id);
                this.$set(this.form, 'deliveryAgentId', row.deliveryAgentId);
                this.DeliveryFromVisible = true;
            },
            deliverySave() {
                this.$refs["deliveryFrom"].validate((valid) => {
                    if (valid) {
                        postAction(this.url.updateDeliveryAgent, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.DeliveryFromVisible = false;
                                    this.handleSearch();
                                }
                            })
                    }
                })

            },
            telexReleaseBetch(){
                postAction(this.url.telexRelease,this.selectedRowKeys)
                    /*.then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);*/
                            //关闭弹窗
                            this.dialogFormVisible = false;
                            this.handleQuery()
                       /* }
                    })*/
            },
            handleEdit(row) {
                this.form.containerId = row.id;
                this.form.week = row.week;
                this.dialogFormVisible = true;
            },
            handleSave() {
                let list = [];
                list.push(this.form.containerId);
                postAction(this.url.telexRelease,list)
                    /*.then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);*/
                            //关闭弹窗
                            this.dialogFormVisible = false;
                            this.handleQuery()
                    /*    }
                    })*/
            }
        },
        created() {
            this.queryForm.pageType = "1";
            this.queryForm.waitTelexRelease = "0";
        },
      computed:{
        buttonShowList() {
          return {
            'fh':this.$btnIsShow('operation/cabinet/WaitTelexRelease','0','放货'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
