<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="用车编号">
                            <el-input :rows="1" v-model="queryForm.thOrderNo" type="textarea" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="服务商">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'logistics_provider'"
                                      v-model="queryForm.thSupplier"
                                      :is-need="queryForm.thSupplier"
                                      placeholder="请选择" style="width:100%">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="司机姓名" v-if="this.queryForm.thSupplier=='大森林'">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'all_driver'"
                                      v-model="queryForm.driverName"
                                      :is-need="queryForm.driverName"
                                      placeholder="请选择" style="width:100%">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="司机手机号">
                            <el-input v-model="queryForm.driverTel" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="分配时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.startDistributionTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.endDistributionTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="调度员">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'operations'"
                                      v-model="queryForm.dispatch"
                                      :is-need="queryForm.dispatch"
                                      placeholder="请选择" style="width:100%">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="small" @click="handleExportXls('提货完成列表')"
                           v-if="buttonShowList.dc"

                >导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="61vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'thOrderNo'">
<!--                        <el-button type="text" @click="See(scope.row)">{{scope.row.thOrderNo}}</el-button>-->
                        <a style="color: #00a0e9" @click.prevent="See(scope.row)">{{ scope.row.thOrderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'thSupplier' && scope.row.thSupplier=='货拉拉'">
                        <el-popover trigger="hover" placement="top" @show="doPopover(scope.row.huolalaOrderStatus)">
                            <el-timeline :reverse="true" v-if="activityList.length>0">
                                <el-timeline-item
                                        v-for="(item, index) in activityList"
                                        :key="index"
                                        :timestamp="item.timestamp">
                                    {{item.content}}
                                </el-timeline-item>
                            </el-timeline>
                            <span v-if="activityList.length===0">未查询到该货拉拉状态</span>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">{{ scope.row.thSupplier }}</el-tag>
                            </div>
                        </el-popover>
                        <el-button type="primary" plain @click="zhangdan(scope.row)" v-if="scope.row.huolalaBill==1">
                            账单
                        </el-button>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog customClass="customWidth" :title="'账单支付'" :visible.sync="dialogFormBillVisible"
                   :close-on-click-modal="false">
            <div class="f-p-b-15">
                账单总金额(金额+税费)：{{this.billPriceFen}} 元
            </div>
            <f-table
                    v-loading="loading"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="billListColumn"
                    :data="huolalaBillList"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChangeBill"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                     <template v-if="scope.column.property === 'price_fen'">
                         {{scope.row.price_fen/100}}
                     </template>
                </template>
            </f-table>
            <el-form :model="form" ref="form">
                <el-form-item label="申诉类型(默认是协商)" :label-width="formLabelWidth">
                    <template>
                        <el-radio-group v-model="form.type">
                            <el-radio :label="'3'">账单申诉</el-radio>
                            <el-radio :label="'4'">费用协商</el-radio>
                        </el-radio-group>
                    </template>
                </el-form-item>
            </el-form>

            <el-form :model="form" ref="form">
                <el-form-item label="申诉说明" :label-width="formLabelWidth" prop="desc" style="margin-top: 10px">
                    <el-input v-model="form.desc" autocomplete="off" placeholder="请需要申诉时填写"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormBillVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="billSure()" :disabled="this.form.isPayBill==='1'">
                    确定支付账单
                </el-button>
                <el-button size="medium" type="primary" @click="shensuBill()" :disabled="this.shenSuStatus || this.form.isPayBill==='1'">申诉账单
                </el-button>
            </div>
        </el-dialog>

        <el-dialog customClass="customWidth" :title="'提货'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="form" ref="form">
                <el-form-item label="提货服务商" :label-width="formLabelWidth" prop="thSupplier">
                    <el-input v-model="form.thSupplier" autocomplete="off" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="司机" :label-width="formLabelWidth" prop="driverName"
                              v-if="form.thSupplier=='大森林'">
                    <el-input v-model="form.driverName" autocomplete="off" :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <f-table
                    v-loading="loading"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="column"
                    :data="tableList"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
            </f-table>
        </el-dialog>

    </div>
</template>

<script>
    import QueryMixins from "../../../mixins/QueryMixins";
    import Dict from "../../test/Tempcclate";
    import {getAction, postAction} from '@/api/manage'

    export default {
        name: 'Potential',
        components: {Dict},
        mixins: [QueryMixins],
        data() {
            return {
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '140px',
                //控制弹框显示
                dialogFormVisible: false,
                dialogFormBillVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'thOrderNo',
                        label: '用车编号',
                        sortable: 'custom',
                    },
                    {
                        prop: 'huolalaOrderNo',
                        label: '货拉拉单号',
                        sortable: 'custom',
                    },
                    {
                        prop: 'thSupplier',
                        label: '服务商',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'driverName',
                        label: '司机姓名',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'driverTel',
                        label: '司机手机号',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'distributionTime',
                        label: '分配时间',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'dispatch',
                        label: '调度员',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'ctn',
                        label: '件数',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: 'kg',
                        label: '重量',
                        sortable: 'custom',
                        width: 150
                    },
                    {
                        prop: 'cmb',
                        label: '体积',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: "remark",
                        label: "备注",
                        over: true,
                    },
                ],
                column: [
                    {
                        prop: 'orderNo',
                        label: '订单号',
                        width: 150
                    },
                    {
                        prop: 'contactAdd',
                        label: '详细地址',
                    },
                    {
                        prop: 'ctn',
                        label: '件数',
                        width: 80
                    },
                    {
                        prop: 'kg',
                        label: '重量',
                        width: 80
                    },
                    {
                        prop: 'cmb',
                        label: '体积',
                        width: 80
                    }
                ],
                billListColumn: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'name',
                        label: '费用类型',
                    },
                    {
                        prop: 'price_fen',
                        label: '费用金额',
                    }
                ],
                url: {
                    list: '/interface/warehouse/getTihuoingIPage',
                    getByThOrderNo: '/order/takeGoogs/getByThOrderNo',  //根据用车编号查询
                    gethuolalaStatusCode: '/sys/dict/getDictItems/huolala_status_code',
                    billPayHuolala: '/order/takeGoogs/billPayHuolala',  //支付司机账单
                    billAppealHuolala: '/order/takeGoogs/billAppealHuolala',  //申诉司机账单
                    exportXlsUrl: "/file/export/excel/downloadTihuoVoExcel",
                    getHuolalaOrderDetail: "/order/takeGoogs/getHuolalaOrderDetail", //获取货拉拉订单详情
                },
                tableList: [],
                huolalaBillList: [],
                huolalaBillListSelect:[],
                activityList: [],
                huolalaOrderStatus: '',
                huolalalist: [],
                huolalamap: {},
                billPriceFen: '',
                shenSuStatus: false,
                isUsePost:true,
            }
        },
        methods: {
            handleSelectionChange(selection) { // 多选
                let arr = [];
                this.insuranceNolist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.insuranceNolist.push(selection[i]['insuranceNo']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleSelectionChangeBill(selection){
                this.huolalaBillListSelect = [];
                for (let i = 0; i < selection.length; i++) {
                    this.huolalaBillListSelect.push(selection[i]);
                }
            },
            //查看不能编辑
            See(row) {
                this.form = JSON.parse(JSON.stringify(row));
                getAction(this.url.getByThOrderNo + '?thOrderNo=' + this.form.thOrderNo)
                    .then(res => {
                        if (res.success) {
                            this.tableList = res.result;
                        }
                    })
                this.dialogFormVisible = true;
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.thStatus = '3';
                this.ipagination.currentPage = 1;
                this.handleQuery()
            },
            doPopover(val) {
                console.log('货拉拉的状态', val);
                this.activityList = [];
                if (val) {
                    if (val.indexOf("@") !== -1) {
                        let list1 = val.split(',');
                        for (var i = 0; i < list1.length; i++) {
                            let list2 = list1[i].split('@');
                            if (this.huolalamap[list2[0]]) {
                                this.activityList.push({content: this.huolalamap[list2[0]].text, timestamp: list2[1]})
                            } else {
                                this.activityList.push({content: list2[0], timestamp: list2[1]})
                            }


                        }
                    }
                }
            },
            //页面一加载存放货拉拉状态码
            inithuolalalist() {
                getAction(this.url.gethuolalaStatusCode)
                    .then(res => {
                        this.huolalalist = res.result;
                        this.huolalamap = this.listToMap(this.huolalalist, 'value');
                        console.log('页面开始加载货拉拉状态码', this.huolalalist, this.huolalamap);
                    })
            },
            //点击账单时  再获取一遍订单详情
            zhangdan(row) {
                this.form = row;
                this.$set(this.form, 'type', '4');
                //获取订单详情
                getAction(this.url.getHuolalaOrderDetail + "?displayOrderId=" + row.huolalaOrderNo)
                    .then((res) => {
                        if (res.success) {
                            this.huolalaBillList = res.result.order_detail_item.bill_price_arr;
                            for (var i = 0; i < this.huolalaBillList.length; i++) {
                                this.huolalaBillList[i].bill_type = '2' + this.huolalaBillList[i].bill_type;  //映射 type （客服交代）
                           /*     this.huolalaBillList[i].price_fen = this.huolalaBillList[i].price_fen / 100;  //转成 元*/
                            }
                            this.billPriceFen = res.result.order_detail_item.order_item.send_bill_price_fen / 100;
                            /*   console.log("json", this.huolalaBillList);*/
                            this.dialogFormBillVisible = true;
                        }
                    })
                /*     var detailJson = JSON.parse(row.huolalaOrderJson);
                     this.huolalaBillList = detailJson.order_detail_item.bill_price_arr;
                     this.billPriceFen = detailJson.order_detail_item.order_item.send_bill_price_fen / 100;*/

            },
            //支付账单
            billSure() {
                getAction(this.url.billPayHuolala + '?displayOrderId=' + this.form.huolalaOrderNo).then((res) => {
                    if (res.success) {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormBillVisible = false;
                        this.handleQuery()
                    }
                })
            },
            //申诉账单
            shensuBill() {
                const cancel_json = {};
                cancel_json.appeal_items = JSON.stringify(this.huolalaBillListSelect);
                cancel_json.type = this.form.type;
                cancel_json.order_display_id = this.form.huolalaOrderNo;
                cancel_json.desc = this.form.desc;
                postAction(this.url.billAppealHuolala, cancel_json).then((res) => {
                    if (res.success) {
                        this.shenSuStatus = true;
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormBillVisible = false;
                        this.handleQuery()
                    }
                })
            }
        }, created() {
            this.queryForm.thStatus = '3';
            this.queryForm.column = 'distributionTime';
            this.inithuolalalist();
        },
      computed : {
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('docpage/takegoods/TakeGoodsEd','0','导出'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
