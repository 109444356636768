<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="类型" label-width="120px">
              <el-radio-group v-model="queryForm.type">
                <el-radio :label="'1'">企业</el-radio>
                <el-radio :label="'2'">仓库</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            v-if="queryForm.type == '2'"
          >
            <el-form-item label="性质" label-width="120px">
              <el-radio-group v-model="queryForm.nature">
                <el-radio :label="'1'">自有</el-radio>
                <el-radio :label="'2'">第三方</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="名称" label-width="120px">
              <el-input v-model="queryForm.content" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"

          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"

                       v-if="buttonShowList.bj"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDel(scope.row.id)"

                       v-if="buttonShowList.sc"
              >删除</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span v-if="scope.row.type === '1'">企业</span>
            <span v-if="scope.row.type === '2'">仓库</span>
          </template>
          <template v-else-if="scope.column.property === 'nature'">
            <span v-if="scope.row.nature === '1'">自有</span>
            <span v-if="scope.row.nature === '2'">第三方</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <basic-data-dialog
      :visible.sync="addVisible"
      :row="row"
      @ok="handleSearch"
    ></basic-data-dialog>
  </div>
</template>

<script>
import { deleteAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import BasicDataDialog from "../modules/DeliveryPointDialog";

export default {
  name: "BascilDataManagement",
  mixins: [QueryMixins],
  components: {
    BasicDataDialog,
  },
  data() {
    return {
      loading: false,
      addVisible: false,
      authVisible: false,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          type: "index",
          label: "#",
        },
        {
          prop: "type",
          label: "类型",
          sortable: "custom",

        },
        {
          prop: "nature",
          label: "性质",
          sortable: "custom",

        },
        {
          prop: "content",
          label: "名称",
          sortable: "custom",

        },
        {
          prop: "address",
          label: "仓库地址",
          sortable: "custom",
          overFlow: true,
        },
        {
          prop: "contact",
          label: "联系人",
          sortable: "custom",
        },
        {
          prop: "phone",
          label: "联系电话",
          sortable: "custom",
        },
        {
          prop: "createBy",
          label: "创建人",
          sortable: "custom",

        },

        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",

        },
      ],
      url: {
        list: "/sys/deliverpoint/list",
        delete: "/sys/deliverpoint/delete",
      },
    };
  },
  methods: {
    handleDel(id) {
      this.$confirm("确定删除该仓库吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            localStorage.removeItem("dict:delivery_point");
            localStorage.removeItem("dict:lcl_place");
            this.handleSearch();
          });
        })
        .catch(() => {});
    },

    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row) {
      this.row = row;
      this.addVisible = true;
    },

    handleChange() {
      this.$forceUpdate();
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/DeliveryManagement','0','新增'),
        'bj':this.$btnIsShow('isystem/BaseData/DeliveryManagement','1','编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/DeliveryManagement','1','删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.over-content {
  width: 80;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
