<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item label="销售">
              <f-select
                :dict="'sys_user_id'"
                v-model="queryForm.idList"
                :isNeed="queryForm.idList"
                multiple
                placeholder="请选择"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="销售组别">
              <f-select
                v-model="queryForm.deptIdList"
                :dict="'sys_depart'"
                :isNeed="queryForm.deptIdList"
                :multiple="true"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          @click="
            exportExcelPlusold(
              url.lossAndReviveCustomerSummaryExport,
              '汇总导出'
            )
          "
          >汇总导出</el-button
        >
      </div>
      <div class="c-alert f-m-b-10">
        <div>
          <span>流失率汇总:{{ lossRateTotal.toFixed(2) + "%" }}</span
          ><span style="margin-left: 20px"
            >客户复活率汇总:{{ rateTotal.toFixed(2) + "%" }}</span
          >
        </div>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'"> </template>
          <template v-else-if="scope.column.property === 'lossRate'">
            <span>{{ scope.row.lossRate + "%" }}</span>
          </template>
          <template v-else-if="scope.column.property === 'reviveRate'">
            <span>{{ scope.row.reviveRate + "%" }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { putAction, deleteAction, getallsalesmans } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
export default {
  name: "LossAndReviveCustomer",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "departName",
          label: "区域",
        },
        {
          prop: "salesmanName",
          label: "业务员",
        },
        {
          prop: "reviveCustomerCount",
          label: "复活客户数",
        },
        {
          prop: "newCustomerCount",
          label: "新客户数",
        },
        {
          prop: "lossCustomerCount",
          label: "流失客户数",
        },
        {
          prop: "allCustomerCount",
          label: "总客户数",
        },
        {
          prop: "lossRate",
          label: "客户流失率",
        },
        {
          prop: "reviveRate",
          label: "客户复活率",
        },
      ],
      url: {
        list: "/customer/getLossAndReviveCustomerSummaryList",
        lossAndReviveCustomerSummaryExport:
          "/file/export/excel/lossAndReviveCustomerSummaryExport",
      },
      isUsePost: true,
      lossRateTotal: 0, //流失率
      rateTotal: 0, //复活率
      disableMixinMounted: true,
    };
  },
  watch: {
    selectionRows: {
      handler: function (val, oldVal) {
        this.lossRateTotal = 0;
        this.rateTotal = 0;
        let lossRateTotalNum = 0;
        let rateTotalNum = 0;
        this.selectionRows.forEach((item) => {
          rateTotalNum += item.reviveRate;
          lossRateTotalNum += item.lossRate;
        });
        this.lossRateTotal = lossRateTotalNum / this.selectionRows.length || 0;
        this.rateTotal = rateTotalNum / this.selectionRows.length || 0;
      },
      deep: true,
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
</style>
