<template>
    <div class="main-full-content">
        <el-form ref="form" class="multiline_select" :model="queryForm" label-width="86px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                     <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="订单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch" type="textarea" placeholder="多个用,或者空格分隔"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品中文名">
                            <el-input v-model="queryForm.name"  @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品英文名">
                            <el-input v-model="queryForm.ename" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家">
                            <el-input v-model="queryForm.country" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
<!--                    下单时间-->
                    <el-col class="no_margin" :md="6" :sm="24">
                        <el-form-item label="下单时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="20px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="c-alert f-m-b-10">
                <span>产品总值：</span> <span>{{totalValue}}</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="57vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #28a1e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }} </a>
                    </template>
                    <template v-else-if="scope.column.property === 'taxRate'">
                        <span>{{getTaxRate(scope.row.taxRate)}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins";
    import {postAction} from "../../../api/manage";

    export default {
        name: 'AllOrderProducts',
        mixins: [QueryMixins],
        components: {
        },
        data() {
            return {
                totalValue:0,
              columns: [
                {
                    prop: "orderNo",
                    label: "订单号",
                },
                  {
                      prop: "bCreateTime",
                      label: "下单时间",
                      minWidth:110,
                  },
                  {
                      prop: "createTime",
                      label: "录入时间",
                      minWidth:110,
                  },
                {
                  prop: "name",
                  label: "中文品名",
                },
                {
                  prop: "ename",
                  label: "英文品名",
                },
                  {
                      prop: "unitValue",
                      label: "单价",
                  },
                  {
                      prop: "qty",
                      label: "数量",
                  },
                {
                  prop: "totalValue",
                  label: "产品价格",
                },
                {
                  prop: "country",
                  label: "国家",
                },
                {
                  prop: "hscode",
                  label: "海关编码",
                },
                {
                  prop: "taxRate",
                  label: "税率",
                },
                {
                  prop: "material",
                  label: "材质",
                    overFlow:true,
                },
                {
                  prop: "prupose",
                  label: "用途",
                    overFlow:true,
                },
                {
                  prop: "ctn",
                  label: "箱数",
                },
                {
                  prop: "kg",
                  label: "毛重",
                },
                {
                  prop: "nkg",
                  label: "毛重",
                },
                {
                  prop: "cmb",
                  label: "体积",
                },
                  {
                      prop: "sku",
                      label: "SKU",
                  },
                  {
                      prop: "remark",
                      label: "备注",
                      overFlow:true,
                  },
                  {
                      prop: "mixed",
                      label: "混装编码",
                  },

              ],

                url: {
                    list: '/interface/shipping/getAllOsOrderGoods',
                }
            }
        },
        created() {
        },
        methods: {
            handleQuery() {
                this.loading = true
                let form = {...this.queryForm, ...this.filterForm, ...this.initData}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else if (this.enabledCreatetime){
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                    //排序
                    //提货中查询
                    if (this.queryForm.column === 'sort') {
                        this.$set(params, 'column', 'sort')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                    postAction(this.url.list, params).then((res) => {
                        console.log(res)
                        if (res.success) {
                            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                            if (res.result) {
                                const {records, total} = res.result.page;
                                this.tableData = records;
                                this.totalValue = res.result.totalValue;
                                this.ipagination && (this.ipagination.total = total || res.result.length)
                            } else {
                                this.tableData = [];
                                this.totalValue = 0;
                                this.ipagination = 0;
                            }

                        }
                        this.loading = false;
                        this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                    }).catch(err => {
                        this.loading = false;
                        // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                    })


            },
            getTaxRate(rate){
                if (!rate){
                    return '';
                }else if (rate.indexOf('%')!==-1){
                    return rate;
                }else if (Number(rate)>=0 && Number(rate) <=1) {
                    return parseFloat(rate*100).toFixed(3) + "%";
                }else {
                    return rate + "%";
                }

            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
