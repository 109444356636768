<template>
    <el-dialog
            title="创建其他出库"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


            <el-form-item prop="otherOutType" label="出库类型" :label-width="formLabelWidth">
                <f-select v-model="form.otherOutType" :isNeed="form.otherOutType" :dict="'warehouse_other_out_type'"></f-select>
            </el-form-item>

            <el-form-item prop="lclPlaceId" label="转仓仓库" :label-width="formLabelWidth" v-if="form.otherOutType === '1' ">
                <f-select v-model="form.lclPlaceId" :isNeed="form.lclPlaceId" :dict="'delivery_point'" @changet="changeLclPlaceId"></f-select>
            </el-form-item>

<!--            <el-form-item prop="loadingTime" label="装柜时间" :label-width="formLabelWidth">-->
<!--                <el-date-picker-->
<!--                        class="f-flex-1"-->
<!--                        v-model="form.loadingTime"-->
<!--                        type="datetime"-->
<!--                        size="small"-->
<!--                        placeholder="装柜时间"-->
<!--                        default-time="00:00:00"-->
<!--                        value-format="yyyy-MM-dd HH:mm:ss">-->
<!--                </el-date-picker>-->
<!--            </el-form-item>-->

            <el-form-item prop="licencePlate" label="车牌号" :label-width="formLabelWidth" v-if="form.otherOutType !== '1' ">
                <el-input v-model="form.licencePlate" maxlength="20" show-word-limit></el-input>
            </el-form-item>

            <el-form-item prop="targetAddress" label="送货地址" :label-width="formLabelWidth" v-if="form.otherOutType !== '1' ">
                <el-input v-model="form.targetAddress" maxlength="500" show-word-limit type="textarea"></el-input>
            </el-form-item>
            <el-table :data="form.sortRuleDtoList"  border v-if="form.otherOutType !== '1' ">
                <el-table-column prop="predictId" label="预约的装柜顺序" width="200px">
                    <template slot-scope="scope"  >
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.predictId'" label-width="0px" >
                            <el-select v-model="scope.row.predictId" clearable filterable @change="changePredict(scope.row)" >
                                <el-option v-for="(i,d) in scope.row.predictOutRecordDropDownVoList"
                                :key="d" :value="i.id" :label="i.outOrderly"></el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="lclPlaceName" label="拼柜仓库" align="center" width="120px">
                    <template slot-scope="scope" >
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.lclPlaceName'" label-width="0px">
                            <span v-text="scope.row.lclPlaceName"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="loadingTime" label="装柜时间" align="center" width="160px">
                    <template slot-scope="scope" >
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.loadingTime'"
                                      :rules="scope.row.predictId ? [] : rules.loadingTime" label-width="0px">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="scope.row.loadingTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="装柜时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :disabled="scope.row.predictId && scope.row.predictId.length > 0"
                            ></el-date-picker>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="isLimit" label="是否受限制" align="center" width="60px">
                    <template slot-scope="scope" >
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.isLimit'"
                                      :rules="scope.row.predictId ? [] : rules.isLimit" label-width="0px">
                            <f-select :dict="'unit_whether'" :isNeed="scope.row.isLimit" :disabled="scope.row.predictId && scope.row.predictId.length > 0"
                                      v-model="scope.row.isLimit"></f-select>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'

    export default {
        name: 'addOtherWarehouseOutDialog',
        props: {
            visible: {
                type: Boolean
            },
            innerDataList:{
                type:Array,
                default:()=>[]
            },
            sortRuleDtoList:{
                type:Array,
                default:()=>[]
            }
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    lclPlaceId: [
                        {required: true, message: '请选择转仓仓库', trigger: 'change'},
                    ],
                    otherOutType: [
                        {required: true, message: '请选择出库类型', trigger: 'change'},
                    ],
                    loadingTime: [
                        {required: true, message: '请选择装柜时间', trigger: 'change'},
                    ],
                    targetAddress: [
                        {required: true, message: '请输入送货地址', trigger: 'change'},
                    ],
                    isLimit: [
                        {required: true, message: '请选择是否受限制', trigger: 'change'},
                    ],

                },
                url: {
                    //生成其他出库数据
                    addWarehouseOtherOutBySelectionRows:'/warehouse/stock/addWarehouseOtherOutBySelectionRows',

                },

            }
        },
        watch: {

            visible(val) {
                if (val && this.innerDataList) {
                    //勾选的订单号进行赋值
                    this.$set(this,'form',{});
                    this.$set(this.form,'innerDataList',this.innerDataList);
                    this.$set(this.form,'sortRuleDtoList',this.sortRuleDtoList);
                    this.sortRuleDtoList.forEach(s=>{
                        if (s.predictOutRecordDropDownVoList && s.predictOutRecordDropDownVoList.length > 0) {
                            this.$set(s,'preMap',this.listToMap(s.predictOutRecordDropDownVoList,'id'));
                        }
                    })
                }
            },
        },
        methods: {
            changeLclPlaceId(val) {
                this.$set(this.form,'lclPlaceName',val);
            },
            changePredict(row){
                let id = row.predictId;
                let obj = row['preMap'][id];
                if (obj) {
                    this.$set(row,'loadingTime',obj['loadingTime']);
                    this.$set(row,'isLimit',obj['isLimit']);
                }
            },
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.addWarehouseOtherOutBySelectionRows, this.form).then((res) => {
                            if (this.form.otherOutType !== '1') {
                                this.$alert(`生成的出库单号是<br><span style="color: green">${res.message}</span>`, '其他出库结果', {
                                    dangerouslyUseHTMLString: true
                                });
                            }else {
                                this.$alert(`生成的用车编号是<br><span style="color: green">${res.message}</span>`, '转仓结果', {
                                    dangerouslyUseHTMLString: true
                                });
                            }
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$refs['form'].resetFields();
            },
            listToMap(list, name) {
                var map = {};
                for (var index in list) {
                    map[list[index][name]] = list[index];
                }
                return map;
            },
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
