<template>
  <div>
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="结算对象">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.settlement"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                  </el-form-item>
                </el-col>


              <el-col :span="6">
                <el-form-item label="录入时间" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.deliveredStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.deliveredEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                  <el-form-item label="业务员">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                      :father-val="queryForm.salesmanId"
                    ></f-select>
                  </el-form-item>
                </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                <el-form-item label="收款人">
                  <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.billmanId"
                      :is-need="queryForm.billmanId"
                      :father-val="queryForm.billmanId"
                  ></f-select>
                </el-form-item>
              </el-col>

                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                        >查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <div>
        <el-button  type="primary" size="small"  @click="daochu">导出</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            type="index"
            label="序号"
        ></el-table-column>
        <el-table-column
            prop="settlement"
            label="结算对象"
        >
          <template slot-scope="scope">
            <a
                style="color: #00a0e9"
                @click.prevent="openDertail(scope.row)"
            >{{ scope.row.settlement }}</a>
          </template>
        </el-table-column>
        <el-table-column
            prop="salesman"
            label="销售"
        ></el-table-column>
        <el-table-column
            prop="billman"
            label="收款人"
        ></el-table-column>
        <el-table-column
            prop="freightway"
            label="付款类型"
        ></el-table-column>
          <el-table-column label="合计">
            <el-table-column
                prop="usdjia"
                label="USD"
            ></el-table-column>
            <el-table-column
                prop="rmbjia"
                label="CNY"
            ></el-table-column>
            <el-table-column
                prop="gbpjia"
                label="GBP"
            ></el-table-column>
            <el-table-column
                prop="jpyjia"
                label="JPY"
            ></el-table-column>
            <el-table-column
                prop="cadjia"
                label="CAD"
            ></el-table-column>
            <el-table-column
                prop="hkdjia"
                label="HKD"
            ></el-table-column>
            <el-table-column
                prop="eurjia"
                label="EUR"
            ></el-table-column>
          </el-table-column>
        <el-table-column label="0-30天">
          <el-table-column
              prop="usd30"
              label="USD"
          ></el-table-column>
          <el-table-column
              prop="rmb30"
              label="CNY"
          ></el-table-column>
          <el-table-column
              prop="gbp30"
              label="GBP"
          ></el-table-column>
          <el-table-column
              prop="jpy30"
              label="JPY"
          ></el-table-column>
          <el-table-column
              prop="cad30"
              label="CAD"
          ></el-table-column>
          <el-table-column
              prop="hkd30"
              label="HKD"
          ></el-table-column>
          <el-table-column
              prop="eur30"
              label="EUR"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="31-60天">
          <el-table-column
              prop="usd60"
              label="USD"
          ></el-table-column>
          <el-table-column
              prop="rmb60"
              label="CNY"
          ></el-table-column>
          <el-table-column
              prop="gbp60"
              label="GBP"
          ></el-table-column>
          <el-table-column
              prop="jpy60"
              label="JPY"
          ></el-table-column>
          <el-table-column
              prop="cad60"
              label="CAD"
          ></el-table-column>
          <el-table-column
              prop="hkd60"
              label="HKD"
          ></el-table-column>
          <el-table-column
              prop="eur60"
              label="EUR"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="61-90天">
          <el-table-column
              prop="usd90"
              label="USD"
          ></el-table-column>
          <el-table-column
              prop="rmb90"
              label="CNY"
          ></el-table-column>
          <el-table-column
              prop="gbp90"
              label="GBP"
          ></el-table-column>
          <el-table-column
              prop="jpy90"
              label="JPY"
          ></el-table-column>
          <el-table-column
              prop="cad90"
              label="CAD"
          ></el-table-column>
          <el-table-column
              prop="hkd90"
              label="HKD"
          ></el-table-column>
          <el-table-column
              prop="eur90"
              label="EUR"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="91-120天">
          <el-table-column
              prop="usd120"
              label="USD"
          ></el-table-column>
          <el-table-column
              prop="rmb120"
              label="CNY"
          ></el-table-column>
          <el-table-column
              prop="gbp120"
              label="GBP"
          ></el-table-column>
          <el-table-column
              prop="jpy120"
              label="JPY"
          ></el-table-column>
          <el-table-column
              prop="cad120"
              label="CAD"
          ></el-table-column>
          <el-table-column
              prop="hkd120"
              label="HKD"
          ></el-table-column>
          <el-table-column
              prop="eur120"
              label="EUR"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="120天+">
          <el-table-column
              prop="usd120jia"
              label="USD"
          ></el-table-column>
          <el-table-column
              prop="rmb120jia"
              label="CNY"
          ></el-table-column>
          <el-table-column
              prop="gbp120jia"
              label="GBP"
          ></el-table-column>
          <el-table-column
              prop="jpy120jia"
              label="JPY"
          ></el-table-column>
          <el-table-column
              prop="cad120jia"
              label="CAD"
          ></el-table-column>
          <el-table-column
              prop="hkd120jia"
              label="HKD"
          ></el-table-column>
          <el-table-column
              prop="eur120jia"
              label="EUR"
          ></el-table-column>
        </el-table-column>
      </el-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <yingshou-dt
      :visible.sync="visible"
      :settlement="settlement"
      :settleId="settleId"
      @ok="handleOk"
    ></yingshou-dt>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';
import YingshouDt from "@/views/docpage/YingshouDt"


export default {
  name: "YingshouZD",
  mixins: [QueryMixins],
  components: {
    YingshouDt
  },
  data() {
    return {
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      proVisible: false,
      url: {
        list: "/order/yingshouzd/pageListPay",
        daochu: "/file/jasper/downloadPayExport"

      },
      issalesman: "0",
      settlement:'',
      settleId:'',
    };
  },
  created() {

  },

  methods: {
    handleOk(){
      this.visible=false;
      this.handleQuery();
    },
    openDertail(row){
      this.settlement = row.settlement;
      this.settleId = row.settleId;
      this.visible=true;
    },
    daochu(){

      //清空字符串的前后空格
      this.trimParamsStr(this.queryForm);
      this.trimParamsStr(this.filterForm);
      this.trimParamsStr(this.initData);
      this.downloadExcel(this.url.daochu, "应付账单账龄", this.queryForm);
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
