<template>
  <el-dialog v-if="type !== 'view'" :title="row.id ? '编辑人才招聘' : '新增人才招聘'" :visible="visible"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文配置" name="zh">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="职位名称" prop="positionName">
                <el-input v-model.trim="form.positionName" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属部门" prop="departName">
                <el-input v-model.trim="form.departName" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属区域">
                <el-input v-model.trim="form.areaName" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="职位详情" prop="positionDescription">
                <quill-editor ref="text" v-model="form.positionDescription" class="myQuillEditor"
                  :options="quillOption" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="英文配置" name="en">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="职位名称" prop="positionNameEn">
                <el-input v-model.trim="form.positionNameEn" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属部门" prop="departNameEn">
                <el-input v-model.trim="form.departNameEn" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属区域">
                <el-input v-model.trim="form.areaNameEn" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="职位详情" prop="positionDescriptionEn">
                <quill-editor ref="text" v-model="form.positionDescriptionEn" class="myQuillEditor"
                  :options="quillOption" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
  <el-dialog v-else-if="type == 'view'" title="职位详情预览" :visible="visible" custom-class="c-custom-dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-row>
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文职位详情" name="zh">
          <el-col :span="24">
            <div v-html="form.positionDescription"></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="英文职位详情" name="en">
          <el-col :span="24">
            <div v-html="form.positionDescriptionEn"></div>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import quillConfig from "../../../utils/quill-config";
import { quillEditor } from "vue-quill-editor";
import { httpAction } from '@/api/manage'
export default {
  name: 'AddRoleDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      activeName: 'zh',
      form: {},
      rules: {
        positionName: [
          { required: true, message: '请输入职位名称', trigger: 'blur' },
        ],
        positionNameEn: [
          { required: true, message: '请输入职位名称', trigger: 'blur' },
        ],
        departName: [
          { required: true, message: '请输入所属部门', trigger: 'blur' },
        ],
        departNameEn: [
          { required: true, message: '请输入所属部门', trigger: 'blur' },
        ],
        positionDescription: [
          { required: true, message: '请输入职位详情', trigger: 'blur' },
        ],
        positionDescriptionEn: [
          { required: true, message: '请输入职位详情', trigger: 'blur' },
        ],
      },
      checkKey: {
        zh: ['positionName', 'departName', 'positionDescription'],
        en: ['positionNameEn', 'departNameEn', 'positionDescriptionEn']
      },
      url: {
        add: '/sys/owTalentRecruitment',
        edit: '/sys/owTalentRecruitment',
        detail: '/sys/owTalentRecruitment/'
      },
      quillOption: quillConfig,
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.row.id) {
          this.getDetail(this.row.id)

        } else {
          // <p>职位描述：</p><p>1.</p><p>职位要求：</p><p>1.</p><p><br></p>
          this.form.positionDescription = '<p><div style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位描述：</div></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p><p><span style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位要求：</span></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p>';
          this.form.positionDescriptionEn = '<p><div style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位描述：</div></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p><p><span style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位要求：</span></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p>';
        }
      }
    }
  },
  methods: {
    getDetail(id) {
      this.$store.commit("setFullscreenLoading", true);
      httpAction(this.url.detail + id, 'GET').then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.form = res.result;
        // this.form.positionDescription = '<p><div style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位描述：</div></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p><p><span style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位要求：</span></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p>';
        // this.form.positionDescriptionEn = '<p><div style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位描述：</div></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p><p><span style=\"color: rgb(38, 39, 41);font-size: 18px;\">职位要求：</span></p><p><span style=\"color: rgb(102, 102, 102);font-size: 14px;\">1. </span></p>';
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
      })
    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if (this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          httpAction(url, method, this.form).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写对应的英文配置';
            this.$message.warning(message);
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.form = {}
      this.activeName = 'zh'
      this.$refs.form && this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
