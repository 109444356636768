<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="订单号">
                            <el-input
                                    :rows="1"
                                    v-model="queryForm.orderNo"
                                    type="textarea"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="联系人">
                            <el-input v-model="queryForm.contact" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="港前操作">
                            <f-select
                                    clearable
                                    filterable
                                    class="f-full-width"
                                    :dict="'sys_user_id'"
                                    v-model="queryForm.docId"
                                    :is-need="queryForm.docId"
                                    placeholder="请选择"
                                    style="width: 100%"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="预计提货时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.startGetsTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.endGetsTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="提货申请时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.startCreateTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.endCreateTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="BatchDown()"
                           :disabled="this.selectionRows.length==0"
                           v-if="buttonShowList.plth"
                >＋ 批量提货
                </el-button
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="61vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="tihuo(scope.row, scope.$index)"

                                   v-if="buttonShowList.th"
                        >提货
                        </el-button
                        >
                        <el-button type="text" @click="editInfo(scope.row)"

                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'lclPlace'">
                        <span>{{parseDict(scope.row.lclPlace,'lcl_place')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                customClass="customWidth"
                :title="'编辑'"
                :visible.sync="dialogEditInfoVisible"
                :close-on-click-modal="false"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="订单号"
                        :label-width="formLabelWidth"
                        prop="orderNo"
                >
                    <el-input
                            v-model="form.orderNo"
                            autocomplete="off"
                            :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="详细地址"
                        :label-width="formLabelWidth"
                        prop="contactAdd"
                >
                    <el-input v-model="form.contactAdd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="联系人"
                        :label-width="formLabelWidth"
                        prop="contact"
                >
                    <el-input v-model="form.contact" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="电话"
                        :label-width="formLabelWidth"
                        prop="contactTel"
                >
                    <el-input v-model="form.contactTel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="预计提货时间"
                        :label-width="formLabelWidth"
                        prop="getsTime"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.getsTime"
                            type="datetime"
                            size="small"
                            placeholder="预计提货时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="件数" :label-width="formLabelWidth" prop="ctn">
                    <el-input
                            v-model="form.ctn"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="重量" :label-width="formLabelWidth" prop="kg">
                    <el-input
                            v-model="form.kg"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="体积" :label-width="formLabelWidth" prop="cmb">
                    <el-input
                            v-model="form.cmb"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="港前操作" :label-width="formLabelWidth" prop="doc">
                    <el-input
                            v-model="form.doc"
                            autocomplete="off"
                            :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="提货申请时间"
                        :label-width="formLabelWidth"
                        prop="createTime"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.createTime"
                            type="datetime"
                            size="small"
                            placeholder="预计提货时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            :disabled="true"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                        label="拼柜仓库"
                        :label-width="formLabelWidth"
                        prop="lclPlace"
                >
                    <f-select
                            clearable
                            filterable
                            class="f-full-width"
                            :dict="'lcl_place'"
                            v-model="form.lclPlace"
                            placeholder="请选择"
                            :is-need="form.lclPlace"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input
                            v-model="form.remark"
                            autocomplete="remark"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogEditInfoVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="editInfoSave()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
                customClass="customWidth"
                :title="'提货'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                :before-close="handleClose"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="提货服务商"
                        :label-width="formLabelWidth"
                        prop="thSupplier"
                >
                    <f-select
                            class="f-full-width"
                            :dict="'logistics_provider'"
                            v-model="form.thSupplier"
                            :is-need="form.thSupplier"
                            placeholder="请选择"
                            style="width: 100%"
                            @change="thSupplierChange"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item
                        label="司机"
                        :label-width="formLabelWidth"
                        prop="driverName"
                        v-if="this.form.thSupplier == '大森林'"
                >
                    <f-select
                            clearable
                            filterable
                            class="f-full-width"
                            :dict="'all_driver'"
                            @getOptions="initSiji"
                            v-model="form.driverName"
                            :is-need="form.driverName"
                            placeholder="请选择"
                            style="width: 100%"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item
                        label="发货城市"
                        :label-width="formLabelWidth"
                        prop="startCity"
                        v-if="this.form.thSupplier === '货拉拉'"
                >
                    <el-select
                            clearable
                            filterable
                            v-model="form.startCity"
                            placeholder="获取货拉拉发货城市"
                            style="width: 100%"
                            @change="getHuolalaCartype"
                    >
                        <el-option
                                v-for="(item, index) in huolalaCitiesList"
                                :label="item.label"
                                :value="item.value"
                                :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="用车时间"
                        :label-width="formLabelWidth"
                        prop="carTime"
                        v-if="this.form.thSupplier == '货拉拉'"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.carTime"
                            type="datetime"
                            size="small"
                            placeholder="用车时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-divider orientation="left" v-if="carType.length > 0">
                    请选择车型
                </el-divider
                >
                <!--      悬浮弹窗          -->
                <el-radio-group
                        v-model="selectCarType"
                        v-for="(type, index) in carType"
                        :key="index"
                        @change="getCarOptions"
                        style="display: inline-block"
                >
                    <el-popover
                            placement="top-start"
                            title="车型信息"
                            width="600"
                            trigger="hover"
                    >
                        <div
                                class="el-table-flow"
                                style="text-align: start; font-style: normal"
                        >
                            <el-row>
                                <el-col :span="3">
                                    <label title="">空间载重：</label>
                                </el-col>
                                <el-col
                                        :span="21"
                                        style="text-align: start; font-style: normal"
                                >
                                    {{
                                    type.vehicle_price_text_item.text_size +
                                    "，" +
                                    type.vehicle_price_text_item.text_volume +
                                    "，" +
                                    type.vehicle_price_text_item.text_weight
                                    }}
                                </el-col>
                                <el-col :span="3">
                                    <label title="">起步计价：</label>
                                </el-col>
                                <el-col :span="21">
                                    起步{{
                                    type.vehicle_price_text_item.start_distance_km
                                    }}公里，{{
                                    type.vehicle_price_text_item.start_price_fen / 100
                                    }}
                                    元/公里
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="4">
                                    <label title="">车型描述：</label>
                                </el-col>
                                <el-col :span="20">
                                    {{ type.vehicle_price_text_item.text_desc }}
                                </el-col>
                            </el-row>
                        </div>
                        <el-form slot="reference">
                            <el-button>
                                <el-radio :label="type.order_vehicle_id">{{
                                    type.name
                                    }}
                                </el-radio>
                                <img
                                        :src="type.img_url_high_light"
                                        style="width: 120px"
                                /><br/>
                                <!--     <el-input :value="type.name" style="text-align: center; border: white"
                                                               read-only></el-input>-->
                            </el-button>
                        </el-form>
                    </el-popover>
                </el-radio-group>
                <el-divider orientation="left" v-if="attach.length > 0"
                >车型附加服务
                </el-divider
                >
                <el-form>
                    <el-checkbox-group v-model="attachSelect" v-if="attach.length > 0">
                        <el-checkbox
                                :label="type.name"
                                v-for="(type, index) in attach"
                                :key="index"
                                style="margin: 10px"
                        >
              <span>{{
                type.name + "，" + type.desc + "，价格为：" + type.price_fen
              }}</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form>
                <el-divider orientation="left" v-if="attach.length > 0"
                >其他需求
                </el-divider
                >
                <el-form>
                    <el-checkbox-group
                            v-model="specAttachSelect"
                            v-if="attach.length > 0"
                    >
                        <el-checkbox
                                :label="type.type"
                                v-for="(type, index) in specAttach"
                                :key="index"
                                style="margin: 10px"
                        >
              <span>
                {{
                  type.name +
                  "，" +
                  type.desc +
                  "，价格为：" +
                  showSpec(type.price_type, type.price_value_fen)
                }}</span
              >
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form>
            </el-form>
            <div class="f-p-b-15 add-item">
                <el-button size="medium" type="primary" @click="addInfo()">添加提货信息</el-button>
            </div>
            <el-dialog
                    customClass="customWidth"
                    :title="'地址确认'"
                    :visible.sync="dialogFormAddressVisible"
                    :close-on-click-modal="false"
                    append-to-body
            >
                <el-table
                        ref="singleTable"
                        :data="confirmAddressList"
                        highlight-current-row
                        @row-click="selectAddress"
                        style="width: 100%"
                >
                    <el-table-column property="address" label="地址"></el-table-column>
                </el-table>
            </el-dialog>
            <f-table
                    v-loading="loading"
                    border
                    :key="addRow"
                    size="medium"
                    row-key="id"
                    ref="addTab"
                    class="c-th-has-bgcolor"
                    :columns="column"
                    :data="form.tableList"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                                type="text"
                                @click="deleterow(scope.$index)"
                                v-if="scope.row.orderNo != '拼柜仓库'"
                        >删除
                        </el-button>

                        <el-button type="text" v-if="scope.row.orderNo != '拼柜仓库'">
                            <span @click="up(scope.$index)">上移 </span>
                            <span @click="down(scope.$index)"> 下移</span>
                        </el-button>

                        <el-button
                                type="text"
                                @click="confirmAddress(scope.row, scope.$index)"
                                v-if="form.thSupplier == '货拉拉'"
                        >
                            <span v-if="!scope.row.check">确认地址</span>
                            <span v-else style="margin-left: 15px; color: #00bb00"
                            >√ 地址已确认</span
                            >
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'contactAdd'">
                        <el-input type="text" v-model="scope.row.contactAdd"></el-input>
                        <!--  <span v-if="addr.check" style="margin-left: 15px; color: #00bb00">√地址已确认</span>-->
                    </template>

                </template>
            </f-table>
            <!--计价-->
            <div style="margin-top: 20px; width: 70%">
                <el-row :gutter="16">
                    <el-col :span="4">
                        <el-button
                                style="margin-top: 13px"
                                type="primary"
                                @click="checkPrice"
                                v-if="form.thSupplier == '货拉拉'"
                        >计价
                        </el-button>
                    </el-col>
                    <el-col :span="6" v-if="form.thSupplier == '货拉拉'">
                        <div
                                class="total-price-title"
                                style="margin: 5px 0; color: rgba(0, 0, 0, 0.45); font-size: 14px"
                        >
                            Total Price (CNY)
                        </div>
                        <div>
                            {{ totalPrice / 100 }}
                        </div>
                        <!--     <el-statistic title="Total Price (CNY)" :precision="2">

                                         </el-statistic>-->
                    </el-col>
                    <el-col :span="4">
            <span style="font-size: small" v-if="distance_total">
              总公里数：{{ distance_total / 1000 }}<br/>
              超公里数：{{ exceed_distance / 1000 }}<br/>
              里程计算方式：{{ calType(distance_by) }}<br/>
              车型名称：{{ order_vehicle_name }}<br/>
            </span>
                    </el-col>

                    <el-col :span="4">
            <span
                    v-for="(item, index) in priceDetail"
                    style="font-size: small"
                    :key="index"
            >
              {{ showType(item.type) + "，" + showArrPrive(item.price_fen)
              }}<br/>
            </span>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="handleQuery();dialogFormVisible = false; filterAgin = false;">取 消</el-button>
                <el-button size="medium" type="primary" @click="xiadan()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog
                customClass="customWidth"
                :title="'添加新提货信息'"
                :visible.sync="addItem"
                :close-on-click-modal="false"
        >
            <f-table
                    v-loading="loading"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="addcolumn"
                    :data="newInfo"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >

            </f-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addItem = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addNewInfo()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import {getAction, postAction, putAction} from "@/api/manage";
  import QueryMixins from "@/mixins/QueryMixins";
  import {zhengshuPattern, zhengshuxiaoshuPartten,} from "@/utils/pattern";
  import { setUpRouteUtil } from '@/utils/util';

  export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                    thSupplier: [
                        {required: true, message: "请选择提货服务商", trigger: "change"},
                    ],
                    driverName: [
                        {required: true, message: "请选择司机", trigger: "change"},
                    ],
                    startCity: [
                        {required: true, message: "请选择发货城市", trigger: "change"},
                    ],
                    carTime: [
                        {required: true, message: "请选择用车时间", trigger: "change"},
                    ],
                    contactTel: [
                        {required: true, message: "请输入电话", trigger: "blur"},
                        // {pattern: mobilePattern, message: "手机号格式有误", trigger: "blur",},
                    ],
                    contactAdd: [
                        {required: true, message: "请输入详细地址", trigger: "blur"},
                        {max: 255, message: "地址长度不能超过255", trigger: "blur"},
                    ],
                    ctn: [
                        {required: true, message: "请输入件数", trigger: "blur"},
                        {
                            pattern: zhengshuPattern, message: "请输入正整数", trigger: "change",
                        },
                    ],
                    kg: [
                        {required: true, message: "请输入重量", trigger: "blur"},
                        {
                            pattern: zhengshuxiaoshuPartten, message: "请输入正整数及最多三位小数", trigger: "change",
                        },
                    ],
                    cmb: [
                        {required: true, message: "请输入体积", trigger: "blur"},
                        {
                            pattern: zhengshuxiaoshuPartten, message: "请输入正整数及最多三位小数", trigger: "change",
                        },
                    ],
                    lclPlace: [
                        {required: true, message: "请选择拼柜仓库", trigger: "change"},
                    ],
                    remark: [
                        {max: 255, message: "备注长度不能超过255", trigger: "blur"},
                    ],
                },
                ids: [],
                selectIndex: "",
                dictCodes: ['lcl_place'],
                row: {},
                form: {tableList: []},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                dialogFormAddressVisible: false,
                dialogEditInfoVisible: false,
                addItem: false, // 控制添加订单弹窗
                newInfo: [],
                filterAgin: false,
                selectRow: {},

                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "contactAdd",
                        label: "详细地址",
                    },
                    {
                        prop: "contact",
                        label: "联系人",
                    },
                    {
                        prop: "contactTel",
                        label: "电话",
                    },
                    {
                        prop: "getsTime",
                        label: "预计提货时间",
                        width: 130
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                    },
                    {
                        prop: "kg",
                        label: "重量",
                    },
                    {
                        prop: "cmb",
                        label: "体积",
                    },
                    {
                        prop: "doc",
                        label: "港前操作",
                    },
                    {
                        prop: "createTime",
                        label: "提货申请时间",
                        width: 130
                    },
                    {
                        prop: "lclPlace",
                        label: "拼柜仓库",
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 100,
                    },
                ],
                column: [
                    {
                        type: "selection",
                        width: 50,
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                        width: 150,
                    },
                    {
                        prop: "contactAdd",
                        label: "详细地址",
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                        width: 80,
                    },
                    {
                        prop: "kg",
                        label: "重量",
                        width: 80,
                    },
                    {
                        prop: "cmb",
                        label: "体积",
                        width: 80,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 100,
                    },
                ],
                addcolumn: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "contactAdd",
                        label: "详细地址",
                        //   width: 140,
                    },
                    {
                        prop: "contact",
                        label: "联系人",
                        width: 140,
                    },
                    {
                        prop: "contactTel",
                        label: "电话",
                        width: 140,
                    },
                    {
                        prop: "geteTime",
                        label: "提货时间",
                        width: 140,
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                        width: 140,
                    },
                    {
                        prop: "kg",
                        label: "重量",
                        width: 160,
                    },
                    {
                        prop: "cmb",
                        label: "体积",
                        width: 150,
                    },
                    {
                        prop: "doc",
                        label: "港前操作",
                        width: 160,
                    },
                    {
                        prop: "createTime",
                        label: "提货申请时间",
                        width: 130
                    },
                ],
                url: {
                    list: "/interface/warehouse/getTakeGoodsIPage",
                    edit: "/interface/warehouse/updateTihuo",
                    huolalaCities: "/interface/warehouse/getHuolalaCities", //获取货拉拉城市列表
                    getHuolalaCartype: "/interface/warehouse/getHuolalaCartypes", //获取货拉拉车型列表
                    getHuolalaAddressSearch: "/order/takeGoogs/getHuolalaAddressSearch", //获取货拉拉地址检索列表
                    huolalaCheckPrice: "/order/takeGoogs/huolalaCheckPrice", //货拉拉计价
                    huolalaPlaceOrder: "/order/takeGoogs/huolalaPlaceOrder", //货拉拉下单
                    forestPlaceOrder: "/order/takeGoogs/forestPlaceOrder", //大森林下单
                },
                emailList: [],
                insuranceNolist: [],
                huolalaCitiesList: [],
                carType: [],
                specAttach: [],
                attachSelect: [],
                specAttachSelect: [],
                attach: [],
                selectCarType: "",
                city_info_revision: "",
                confirmAddressList: [],
                totalPrice: "0.00",
                distance_total: "",
                exceed_distance: "",
                distance_by: "",
                order_vehicle_name: "",
                priceDetail: [],
                operateTime: "",
                checkPriceStatus: false,
                thSupplier: "",
                addRow: true,
              isUsePost:true,
            };
        },
        methods: {
            //在点击关闭提货弹窗的时候重置filterAgin
            handleClose(done) {
                this.filterAgin = false;
                done();
            },
            intoDocpage(orderNo) {
                if (orderNo.search('KD') != -1) {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push('/kdorder/Edit?orderNo=' + orderNo);
                } else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }
            },
            initSiji(list) {
                if (list) {
                    if (
                        !list.find((l) => {
                            l.value == this.form.driverName;
                        })
                    ) {
                        this.$set(this.form, "driverName", "");
                    }
                }
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.insuranceNolist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.insuranceNolist.push(selection[i]["insuranceNo"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                console.log("这是啥", this.selectedRowKeys);
                console.log("这是啥", this.selectionRows);
            },
            // 提货
            tihuo(row, index) {
                this.form = {};
                this.carType = [];
                this.attach = [];
                for (var name in row) {
                    this.$set(this.form, name, row[name]);
                }
                this.form.tableList = [];
                this.form.tableList.push(this.tableData[index]);
                this.form.tableList.push({
                    orderNo: "拼柜仓库",
                    contactAdd: this.tableData[index].lclAddress,
                    contact: this.tableData[index].contact,
                    contactTel: this.tableData[index].contactTel,
                });

                //计价
                this.totalPrice = "0";
                this.distance_total = "";
                this.exceed_distance = "";
                this.distance_by = "";
                this.order_vehicle_name = "";
                this.priceDetail = [];

                // this.form.id = row.id;
                this.dialogFormVisible = true;
                console.log("提货的表单", this.form);
            },
            // 删除提货信息
            deleterow(index) {
                console.log("删除行", index);
                this.form.tableList.splice(index, 1);
            },
            // 上移
            up(index) {
                console.log("上移行", index);
                let row = this.form.tableList[index];
                let beforeRow = index - 1;
                if (beforeRow < 0) {
                    alert("已到顶部");
                } else {
                    this.form.tableList[index] = this.form.tableList[beforeRow];
                    this.form.tableList[beforeRow] = row;
                    this.addRow = !this.addRow;
                }
                console.log("交换之后的table", this.form.tableList);
            },
            // 下移
            down(index) {
                console.log("下移行", index);
                // console.log(this.form.tableList[index])
                // console.log(this.form.tableList[index + 1])
                let row = this.form.tableList[index];
                let nextRow = index + 1;
                if (nextRow >= this.form.tableList.length - 1) {
                    alert("已到底部");
                } else {
                    this.form.tableList[index] = this.form.tableList[nextRow];
                    this.form.tableList[nextRow] = row;
                    this.addRow = !this.addRow;
                }
                console.log("交换之后的table", this.form.tableList);
            },
            // 添加提货信息
            addInfo() {
                if (!this.filterAgin) {
                    console.log("选择的订单信息", this.form);
                    let saveArr = [];
                    // 如果单个提交
                    if (this.form) {
                        saveArr.push(this.form);
                    }
                    // 如果批量提交
                    if (this.selectionRows.length > 0) {
                        saveArr = this.selectionRows;
                    }
                    // 过滤字段数组
                    let filterArr = [];
                    saveArr.map((val, i) => {
                        filterArr.push(val.id);
                    });
                    let arr = this.tableData.filter((val, i) => {
                        console.log("这是啥", val.id);
                        // 过滤arr
                        return !filterArr.includes(val.id);
                    });
                    this.newInfo = arr;
                    console.log("过滤后", this.newInfo);
                }
                this.addItem = true;
            },
            // 选择新订单信息后确定
            addNewInfo() {
                if (this.selectionRows.length > 0) {
                    console.log("id是", this.selectionRows[0].id);
                    console.log("你是数组吗", this.form.tableList[0].id);
                    this.selectionRows.map((val, i) => {
                        let length = this.form.tableList.length - 1;
                        let item = this.form.tableList[length];
                        this.form.tableList.pop();
                        this.form.tableList.push(val);
                        this.form.tableList.push(item);
                    });
                    // this.form.tableList.concat(this.selectionRows);
                    this.$message.success("添加成功");

                    // 过滤字段数组
                    let filterArr = [];
                    this.form.tableList.map((val, i) => {
                        filterArr.push(val.id);
                    });
                    let arr = this.tableData.filter((val, i) => {
                        console.log("这是啥", val.id);
                        // 过滤arr
                        return !filterArr.includes(val.id);
                    });
                    this.newInfo = arr;
                    this.filterAgin = true;
                } else {
                    this.$message.error("请勾选订单");
                }
                //关闭弹窗
                this.addItem = false;
                this.dialogFormVisible = true;
            },
            checkPolicy(text) {
                console.log(text);
                if (text == "null" || text == undefined || text == "") {
                    return true;
                }
                return false;
            },
            //批量提货
            BatchDown() {
                console.log(this.selectionRows);
                this.form.tableList = this.selectionRows;
                this.form.tableList.push({
                    orderNo: "拼柜仓库",
                    contactAdd: this.selectionRows[0].lclAddress,
                });
                this.dialogFormVisible = true;
            },
            //获取货拉拉发货城市
            huolalaCities() {
                getAction(this.url.huolalaCities)
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].city_id;
                                obj.label = res.result[i].name;
                                this.huolalaCitiesList.push(obj);
                                console.log("获取货拉拉发货城市：", this.huolalaCitiesList);
                            }
                        }
                    })
            },
            thSupplierChange(code) {
                //清空货拉拉列表
                if ("大森林" == code) {
                    this.carType = [];
                }
            },
            getHuolalaCartype(code) {
                getAction(this.url.getHuolalaCartype + "?cityId=" + code)
                    .then((res) => {
                        if (res.success) {
                            console.log(res.result);
                            this.carType = res.result.vehicle_arr; //城市可选车型列表
                            console.log("车型：", this.carType);
                            this.specAttach = res.result.spec_req_arr; //城市可选额外需求列表。
                            console.log("城市可选额外需求列表：", this.specAttach);
                            this.city_info_revision = res.result.revision; //城市最新版本号
                            console.log("城市最新版本号：", this.city_info_revision);
                        }
                    })
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            // 改变车型
            getCarOptions() {
                console.log("选择的车型：", this.selectCarType);
                const type = this.carType.filter(
                    (item) => this.selectCarType === item.order_vehicle_id
                );
                console.log(type[0].name);
                this.attach = type[0].vehicle_std_arr;
                console.log("车型附加服务：", this.attach);
            },
            showSpec(type, price) {
                if (type == 1) {
                    return price / 100;
                } else if (type == 2) {
                    return "计价的" + price + "%";
                } else return 0;
            },
            //确认地址
            confirmAddress(row, index) {
                this.selectRow = row;
                this.selectIndex = index;
                getAction(this.url.getHuolalaAddressSearch + "?address=" + row.contactAdd)
                    .then((res) => {
                        if (res.success) {
                            this.confirmAddressList = res.result;
                            console.log("搜索地址：", this.confirmAddressList);
                        }
                    })
                this.dialogFormAddressVisible = true;
            },
            //选中地址
            selectAddress(row, column, event) {
                console.log("选中的地址", row);
                this.$set(this.selectRow, "contactAdd", row.address);
                this.$set(
                    this.form.tableList[this.selectIndex],
                    "contactAdd",
                    row.address
                );
                this.$set(this.form.tableList[this.selectIndex], "lat", row.location.lat);
                this.$set(this.form.tableList[this.selectIndex], "lon", row.location.lon);
                this.$set(this.form.tableList[this.selectIndex], "city_id", row.city_id);
                this.$set(this.form.tableList[this.selectIndex], "check", true);
                this.$set(this.form.tableList[this.selectIndex], "name", row.name);
                /*经纬度*/
                console.log("选中地址", this.form.tableList[this.selectIndex].check);
                this.dialogFormAddressVisible = false;
                this.confirmAddressList = [];
            },
            //计价
            checkPrice() {
                const price_json = {};
                if (this.form.startCity == "" || this.form.startCity == undefined) {
                    this.$message.warning("请选择发货城市及使用的车型！");
                    return;
                }
                if (this.selectCarType == "" || this.selectCarType == undefined) {
                    this.$message.warning("请选择需要使用的车型！");
                    return;
                }
                price_json.city_id = this.form.startCity;
                price_json.city_info_revision = this.city_info_revision;
                price_json.order_vehicle_id = this.selectCarType;
                price_json.std_tag_arr = this.attachSelect;
                price_json.spec_req_arr = this.specAttachSelect;

                this.lat_lon_arr = [];
                for (let item of this.form.tableList) {
                    console.log("当前列表：", this.form.tableList);
                    if (!item.check) {
                        this.$message.warning("请对所有提货地址进行确认！");
                        return;
                    }
                    const addr_item = {};
                    addr_item.lat = Number(item.lat);
                    addr_item.lon = Number(item.lon);
                    addr_item.city_id = item.city_id;
                    this.lat_lon_arr.push(addr_item);
                }
                console.log("lat_lon_arr", this.lat_lon_arr);
                price_json.lat_lon_arr = this.lat_lon_arr;
                console.log("price_json", price_json);
                postAction(this.url.huolalaCheckPrice, price_json).then((res) => {
                    console.log(res);
                    if (!res.success) {
                        return;
                    }
                    this.totalPrice = res.result.total_price_fen;
                    this.distance_total = res.result.distance_total;
                    this.exceed_distance = res.result.exceed_distance;
                    this.distance_by = res.result.distance_by;
                    this.order_vehicle_name = res.result.order_vehicle_name;
                    this.priceDetail = res.result.order_price_arr;
                });
                this.operateTime = new Date();
                this.checkPriceStatus = true;
            },
            //下单
            xiadan() {
                if (this.form.thSupplier) {
                    if ("大森林" == this.form.thSupplier) {
                        this.forestXiadan();
                    }
                    if ("货拉拉" == this.form.thSupplier) {
                        this.huolalaXiadan();
                    }
                } else {
                    this.$message.warning("请选择提货服务商！");
                    return;
                }
            },
            //大森林下单
            forestXiadan() {
                console.log("tableList", this.form.tableList);
                for (var i = 0; i < this.form.tableList.length; i++) {
                    var obj = this.form.tableList[i];
                    if (obj.id != null && obj.id.length > 0 && (obj.contactAdd == null || obj.contactAdd.length == 0)) {
                        this.$message.warning("请填写正确的提货地址！");
                        return;
                    }
                }
                postAction(this.url.forestPlaceOrder, this.form).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        //关闭弹窗
                        this.dialogFormVisible = false;
                        this.handleQuery();
                    }
                });
            },
            //货拉拉下单
            huolalaXiadan() {
                const order_json = {};
                if (this.checkPriceStatus === false) {
                    this.$message.warning("请先完成计价！");
                    return;
                }
                if (this.form.startCity == "" || this.form.startCity == undefined) {
                    this.$message.warning("请选择发货城市及使用的车型！");
                    return;
                }
                if (this.selectCarType == "" || this.selectCarType == undefined) {
                    this.$message.warning("请选择需要使用的车型！");
                    return;
                }
                const timeInterval = new Date() - this.operateTime;
                if (timeInterval > 120000) {
                    this.$message.warning("下单与计价操作时间间隔过长，请重新计价！");
                    return;
                }
                order_json.city_id = this.form.startCity;
                order_json.city_info_revision = this.city_info_revision;
                order_json.order_vehicle_id = this.selectCarType;
                order_json.std_tag_arr = this.attachSelect;
                order_json.spec_req_arr = this.specAttachSelect;
                order_json.dispatcher = this.dispatcher;
                order_json.table_list = this.form.tableList;
                const addr_info = [];
                for (let item of this.form.tableList) {
                    if (!item.check) {
                        this.$message.warning("请对所有提货地址进行确认！");
                        return;
                    }
                    const addr_item = {};
                    addr_item.lat_lon = {};
                    addr_item.lat_lon.lat = Number(item.lat);
                    addr_item.lat_lon.lon = Number(item.lon);
                    addr_item.addr = item.contactAdd;
                    addr_item.name = item.name;
                    /*            addr_item.city_id = item.city_id;*/
                    addr_item.city_id = this.form.startCity;
                    addr_item.contact_name = item.contact;
                    addr_item.contact_phone_no = item.contactTel;
                    addr_info.push(addr_item);
                }

                order_json.addr_info = addr_info;
                console.log("carTime", this.form.carTime);
                order_json.order_time = new Date(this.form.carTime).getTime() / 1000; //转为时间戳
                order_json.total_price_fen = this.totalPrice;
                console.log("order_json", order_json);
                postAction(this.url.huolalaPlaceOrder, order_json).then((res) => {
                    console.log(res);
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.dialogFormVisible = false;
                        this.handleQuery();
                    }
                });
            },
            calType(type) {
                if (type == "1") {
                    return "百度导航";
                }
                if (type == "2") {
                    return "高德导航";
                }
                if (type == "3") {
                    return "其他";
                }
            },
            showType(type) {
                if (type == "1") {
                    return "起步价";
                }
                if (type == "2") {
                    return "超里程价";
                }
                if (type == "3") {
                    return "拉拉券";
                }
                if (type == "4") {
                    return "额外需求产生的费用";
                }
                if (type == "5") {
                    return "小费";
                }
                if (type == "6") {
                    return "车型规格";
                }
                if (type == "7") {
                    return "平台订单补贴";
                }
                if (type == "8") {
                    return "额外费用";
                }
            },
            showArrPrive(price) {
                return "￥" + (price / 100).toFixed(2);
            },
            //编辑
            editInfo(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogEditInfoVisible = true;
            },
            editInfoSave() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        putAction(this.url.edit, this.form).then((res) => {
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.dialogEditInfoVisible = false;
                                this.handleQuery();
                            }
                        });
                    }
                });
            },
        },
        created() {
            this.huolalaCities();
            this.queryForm.column = 'createTime';
        },
    computed : {
      buttonShowList() {
        return {
          'plth':this.$btnIsShow('docpage/takegoods/NoTakeGoods','0','批量提货'),
          'th':this.$btnIsShow('docpage/takegoods/NoTakeGoods','1','提货'),
          'bj':this.$btnIsShow('docpage/takegoods/NoTakeGoods','1','编辑'),
        }
      }
    }
    };
</script>

<style lang="scss" scoped>
    .customWidth {
        width: 80%;
    }
</style>
