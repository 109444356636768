<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="70px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="年份">
              <el-date-picker
                v-model="year"
                type="year"
                placeholder="选择年"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15" style="display: flex">
        <el-button type="primary" size="medium" @click="handleEdit('init')"
          >初始化</el-button
        >
        <el-button type="primary" size="medium" @click="handleEdit('edit')"
          >保存</el-button
        >
      </div>
      <el-form ref="tableData">
        <f-table
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor no_margin"
          :columns="columns"
          :data="tableData"
          :showSlot="true"
          :cell-style="{ 'text-align': 'center' }"
          v-loading="loading"
        >
          <!-- 第二行表头 -->
          <template v-slot:showSlot="{ item }">
            <template v-if="item.prop == 'Q1'">
              <el-table-column
                v-for="(itemQ1, index) in columnsQ1"
                :key="index"
                :prop="itemQ1.prop"
                :label="itemQ1.label"
                :sortable="true"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="el_form_item"
                    :prop="'month0' + (index + 1)"
                  >
                    <el-input
                      v-model="
                        tableData[scope.$index]['month0' + (index + 1)].volume
                      "
                      type="number"
                      @input="
                        eventInput($event, scope.$index, 'month0' + (index + 1))
                      "
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
            <template v-if="item.prop == 'Q2'">
              <el-table-column
                v-for="(itemQ2, index) in columnsQ2"
                :key="index"
                :prop="itemQ2.prop"
                :label="itemQ2.label"
                :sortable="true"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="el_form_item"
                    :prop="'month0' + (index + 4)"
                  >
                    <el-input
                      v-model="
                        tableData[scope.$index]['month0' + (index + 4)].volume
                      "
                      type="number"
                      @input="
                        eventInput($event, scope.$index, 'month0' + (index + 4))
                      "
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
            <template v-if="item.prop == 'Q3'">
              <el-table-column
                v-for="(itemQ3, index) in columnsQ3"
                :key="index"
                :prop="itemQ3.prop"
                :label="itemQ3.label"
                :sortable="true"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="el_form_item"
                    :prop="'month0' + (index + 7)"
                  >
                    <el-input
                      v-model="
                        tableData[scope.$index]['month0' + (index + 7)].volume
                      "
                      type="number"
                      @input="
                        eventInput($event, scope.$index, 'month0' + (index + 7))
                      "
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
            <template v-if="item.prop == 'Q4'">
              <el-table-column
                v-for="(itemQ4, index) in columnsQ4"
                :key="index"
                :prop="itemQ4.prop"
                :label="itemQ4.label"
                :sortable="true"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="el_form_item"
                    :prop="'month' + (index + 10)"
                  >
                    <el-input
                      v-model="
                        tableData[scope.$index]['month' + (index + 10)].volume
                      "
                      type="number"
                      @input="
                        eventInput($event, scope.$index, 'month' + (index + 10))
                      "
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
          </template>
        </f-table>
      </el-form>
    </el-card>
    <dialog-public
      :visible="visible"
      :title="title"
      :form="{
        year: year,
      }"
      :url="url"
      @ok="handleQuery"
      @close="handleClose"
    ></dialog-public>
  </div>
</template>
<script>
import dialogPublic from "@/views/dashboard/components/dialogPublic";
import { getAction, postAction } from "@/api/manage";
// import QueryMixins from "@/mixins/QueryMixins.js";
export default {
  // mixins: [QueryMixins],
  components: {
    dialogPublic,
  },
  data() {
    return {
      columns: [
        {
          prop: "type",
          label: "类型",
          sortable: "custom",
          sortable: false,
        },
        {
          prop: "year",
          label: "年份",
          sortable: "custom",
        },
        {
          prop: "Q1",
          label: "Q1",
          sortable: "custom",
          sortable: false,
        },
        {
          prop: "Q2",
          label: "Q2",
          sortable: "custom",
          sortable: false,
        },
        {
          prop: "Q3",
          label: "Q3",
          sortable: "custom",
          sortable: false,
        },
        {
          prop: "Q4",
          label: "Q4",
          sortable: "custom",
          sortable: false,
        },
        {
          prop: "total",
          label: "合计",
          sortable: "custom",
          sortable: false,
        },
      ],
      columnsQ1: [
        {
          prop: "month1",
          label: "1月",
        },
        {
          prop: "month2",
          label: "2月",
        },
        {
          prop: "month3",
          label: "3月",
        },
      ],
      columnsQ2: [
        {
          prop: "month4",
          label: "4月",
        },
        {
          prop: "month5",
          label: "5月",
        },
        {
          prop: "month6",
          label: "6月",
        },
      ],
      columnsQ3: [
        {
          prop: "month7",
          label: "7月",
        },
        {
          prop: "month8",
          label: "8月",
        },
        {
          prop: "month9",
          label: "9月",
        },
      ],
      columnsQ4: [
        {
          prop: "month10",
          label: "10月",
        },
        {
          prop: "month11",
          label: "11月",
        },
        {
          prop: "month12",
          label: "12月",
        },
      ],
      url: {
        //list: "/order/sysTargetSet/list",
        list: "/interface/sales/list",
        //add: "/order/sysTargetSet/add",
        add: "/interface/sales/add",
        //edit: "/order/sysTargetSet/edit",
        edit: "/interface/sales/edit",
      },
      visible: false,
      row: {},
      year: new Date(),
      tableData: [],
      title: "初始化季度目标",
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      loading: false,
      form: {},
    };
  },
  mounted() {
    this.getTargetList();
  },
  methods: {
    // 输入框数值变化
    eventInput(value, index, monthIndex) {
      if (parseFloat(value) < 0) {
        this.tableData[index][monthIndex].volume = "";
        this.$message({
          type: 'warning',
          message: '请输入大于0的数字哦'
        });
      }
      let total = 0;
      for(let i=1;i<=12;i++) {
        total += parseFloat(this.tableData[index]["month" + (i < 10 ? "0" + i : i)].volume)
      }
      this.tableData[index].total = total;
    },
    handleSearch() {
      this.getTargetList();
    },
    handleReset() {
      this.year = new Date();
      this.getTargetList();
    },
    handleClose() {
      this.visible = false;
    },
    handleQuery(year) {
      this.year = year;
      this.getTargetList();
    },
    getTargetList() {
      this.loading = true;
      let params = {
        yearMonthType: this.year.getFullYear(),
      };
      getAction(this.url.list, params)
        .then((res) => {
          this.loading = false;
          this.tableData = [];
          if (res.success && res.result && res.result.length > 0) {
            let LCLList = { total: 0 },
              FCLList = { total: 0 },
              airList = { total: 0 },
              expressList = { total: 0 };
            res.result.forEach((element) => {
              if (element.orderType == "0") {
                LCLList.total += element.volume;
                LCLList["month" + element.yearMonthType.split("-")[1]] =
                  element;
                if (Object.keys(LCLList).length !== 0) {
                  LCLList.type = "LCL拼柜(单位：柜量)";
                  LCLList.year = this.year.getFullYear();
                }
              } else if (element.orderType == "1") {
                FCLList.total += element.volume;
                FCLList["month" + element.yearMonthType.split("-")[1]] =
                  element;
                if (Object.keys(FCLList).length !== 0) {
                  FCLList.type = "FCL整柜(单位：柜量)";
                  FCLList.year = this.year.getFullYear();
                }
              } else if (element.orderType == "2") {
                airList.total += element.volume;
                airList["month" + element.yearMonthType.split("-")[1]] =
                  element;
                if (Object.keys(airList).length !== 0) {
                  airList.type = "空运(单位：吨)";
                  airList.year = this.year.getFullYear();
                }
              } else if (element.orderType == "3") {
                expressList.total += element.volume;
                expressList["month" + element.yearMonthType.split("-")[1]] =
                  element;
                if (Object.keys(expressList).length !== 0) {
                  expressList.type = "快递(单位：吨)";
                  expressList.year = this.year.getFullYear();
                }
              }
            });
            if (Object.keys(LCLList).length !== 0) {
              this.tableData.push(LCLList);
            }
            if (Object.keys(FCLList).length !== 0) {
              this.tableData.push(FCLList);
            }
            if (Object.keys(airList).length !== 0) {
              this.tableData.push(airList);
            }
            if (Object.keys(expressList).length !== 0) {
              this.tableData.push(expressList);
            }
            console.log(this.tableData, "this.tableData");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleEdit(type) {
      if (type == "init") {
        this.visible = true;
      } else {
        // this.$refs["tableData"].validate((valid) => {
        //   if (valid) {
        let params = [];
        this.tableData.forEach((item, index) => {
          for (let i = 1; i <= 12; i++) {
            item["month" + (i < 10 ? "0" + i : i)].volume = item[
              "month" + (i < 10 ? "0" + i : i)
            ].volume
              ? parseFloat(item["month" + (i < 10 ? "0" + i : i)].volume)
              : null;
            delete item["month" + (i < 10 ? "0" + i : i)].createBy;
            delete item["month" + (i < 10 ? "0" + i : i)].createTime;
            params.push(item["month" + (i < 10 ? "0" + i : i)]);
          }
        });
        postAction(this.url.edit, params)
          .then((res) => {
            console.log(res);
            if (res.success) {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.getTargetList();
              // this.handleClose();
              // this.$emit("ok");
            }
          })
          .finally((err) => {});
      }
    },
  },
};
</script>