import { render, staticRenderFns } from "./sendYingxiaoEmailLog.vue?vue&type=template&id=b8ba87b4&scoped=true&"
import script from "./sendYingxiaoEmailLog.vue?vue&type=script&lang=js&"
export * from "./sendYingxiaoEmailLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ba87b4",
  null
  
)

export default component.exports