<template>
    <el-form :model="form" :rules="rules" ref="form" label-width="90px" size="medium" >
        <el-row>
            <el-col :span="24">
                <el-form-item prop="menuType" label="菜单类型" :label-width="labelWidth">
                    <el-radio-group v-model="form.menuType">
                        <el-radio :label="0">一级菜单</el-radio>
                        <el-radio :label="1">子菜单</el-radio>
                        <el-radio :label="2">按钮/权限</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="form.menuType==1">
            <el-form-item prop="parentId" label="上级菜单" :label-width="labelWidth">
                <tree-dropdown v-model="form.parentId" :data="data"></tree-dropdown>
            </el-form-item>
        </el-col>
            <el-col :span="24" v-if="form.menuType==2">
                <el-form-item prop="parentId" label="上级菜单" :label-width="labelWidth">
                    <f-select v-model="form.parentId" :dict="'btn_menu'" :isNeed="form.parentId"></f-select>
                </el-form-item>
            </el-col>
            <el-col v-if="form.menuType==2">
                <el-form-item label="名字" prop="btnName" :label-width="labelWidth">
                    <el-input v-model="form.btnName" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
                <el-col v-if="form.menuType==2" >
                    <el-form-item prop="btnIsTable" label="是否在表格里" :label-width="labelWidth">
                        <el-radio-group v-model="form.btnIsTable">
                            <el-radio :label="'0'">否</el-radio>
                            <el-radio :label="'1'">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>


            <el-col :span="24" v-if="form.menuType!=2">
                <el-form-item prop="name" :label="form.menuType !== 2 ? '菜单名称' : '按钮名称'" :label-width="labelWidth">
                    <el-input v-model="form.name" ref="input" :disabled="row.name == '首页' ? true : false"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="form.menuType !=2">
                <el-form-item prop="url" label="菜单路径" :label-width="labelWidth">
                    <el-input v-model="form.url"></el-input>
                </el-form-item>
            </el-col>
            <template v-if="form.menuType !== 2">
                <el-col :span="24">
                    <el-form-item prop="component" label="前端组件" :label-width="labelWidth">
                        <el-autocomplete
                            v-model="form.component"
                            class="f-full-width"
                            :fetch-suggestions="querySearch"></el-autocomplete>
                    </el-form-item>
                </el-col>
            </template>
            <template v-if="form.menuType !== 2">
                <el-col :span="24">
                    <el-form-item prop="redirect" label="默认跳转" :label-width="labelWidth">
                        <el-input v-model="form.redirect"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="form.menuType !== 2">
                    <el-form-item prop="icon" label="菜单图标" class="icon-wrap" :label-width="labelWidth">
                        <el-popover
                            :width="popoverWidth"
                            :visible-arrow="false"
                            popper-class="popper-icon"
                            placement="bottom"
                            trigger="click">
                            <div class="icon-container">
                                <el-input v-model="value" placeholder="搜索…" @input="handleFilterIcon"></el-input>
                                <ul class="c-slim-scrollbar">
                                    <li v-for="(ico, index) in filterIcons" :key="index" @click="form.icon = ico">
                                        <i :class="ico"></i>
                                    </li>
                                </ul>
                            </div>
                            <template slot="reference">
                                <el-input placeholder="请输入内容" v-model="form.icon">
                                    <template slot="prepend">
                                        <i :class="form.icon" style="font-size: 18px;"></i>
                                    </template>
                                </el-input>
                                <el-tooltip placement="top" popper-class="icon-tooltip">
                                    <template v-slot:content>
                                        更多图标：<br/>
                                        <a href="https://element.eleme.cn/#/zh-CN/component/icon" target="_blank" style="color: #fff;">element ui</a><br/>
                                        <a href="http://www.fontawesome.com.cn/faicons/" target="_blank" style="color: #fff;">Font Awesome</a><br/>
                                        复制相应class到输入框即可<br/>
                                        如"el-icon-eleme" <br/> "fa fa-address-book"
                                    </template>
                                    <el-button icon="el-icon-question" class="question"></el-button>
                                </el-tooltip>
                            </template>
                        </el-popover>
                    </el-form-item>
                </el-col>
            </template>
            <el-col :span="24" v-if="form.menuType == 2">
<!--                <el-form-item prop="menuType" label="授权策略">-->
<!--                    <el-radio-group v-model="form.permsType">-->
<!--                        <el-radio label="1">可见/可访问</el-radio>-->
<!--                        <el-radio label="2">可编辑(未授权时禁用)</el-radio>-->
<!--                    </el-radio-group>-->
<!--                </el-form-item>-->
<!--                <edit-btn></edit-btn>-->

            </el-col>
            <div v-if="form.menuType !== 2">
                <el-col :span="24">
                    <el-form-item prop="perms" label="权限编码" :label-width="labelWidth">
                        <el-input v-model="form.perms"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="sortNo" label="排序" :label-width="labelWidth">
                        <el-input v-model="form.sortNo"></el-input>
                    </el-form-item>
                </el-col>
                <template v-if="form.menuType !== 2">
                    <el-col :span="24">
                        <el-form-item label="缓存页面" :label-width="labelWidth">
                            <el-switch v-model="form.keepAlive"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="隐藏菜单" :label-width="labelWidth">
                            <el-switch v-model="form.hidden"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="新窗口打开" :label-width="labelWidth">
                            <el-switch v-model="form.internalOrExternal"></el-switch>
                        </el-form-item>
                    </el-col>
                </template>
                <el-col :span="24" v-else>
                    <el-form-item label="是否启用" :label-width="labelWidth">
                        <el-switch v-model="form.status" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                </el-col>
            </div>

            <el-col :span="24" v-if="showButton">
                <el-form-item label-width="30px" class="f-text-right">
                    <el-button type="primary" @click="handleSubmit"
                               v-if="buttonShowList.ok && form.menuType !=2"
                    >确定</el-button>
                    <el-button plain @click="handleReset" v-if="form.menuType !=2">重置</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import icon from '@/utils/icon'
import { intValidator } from '@/utils/validator'
import TreeDropdown from './TreeDropdown'
import EditBtn from "../EditBtn";
const form = {
    menuType: 0,
    parentId: '',
    name: '',
    url: '',
    component: '',
    redirect: '',
    icon: '',
    perms: '',
    sortNo: '',
    keepAlive: false,
    hidden: false,
    internalOrExternal: false,
    btnId:'',
    btnName:'',
    btnIsTable:'',
    permsType: '1',
    status: '0'
}

export default {

    name: 'MenuForm',
    components: {
        TreeDropdown,EditBtn
    },
    props: {
        showButton: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            default: () => {}
        },
        data: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            labelWidth:'120px',
            filterIcons: icon,
            popoverWidth: 0,
            value: '',
            form: { ...form },
            rules: {
                menuType: [
                    { required: true, message: '请选择菜单类型', trigger: 'change' }
                ],
                btnName: [
                    { required: true, message: '请输入按钮名字', trigger: 'blur' }
                ],
                btnIsTable: [
                    { required: true, message: '请选择按钮是否在表格里', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '请输入菜单名称', trigger: 'change' }
                ],
                parentId: [
                    { required: true, message: '请选择上级菜单', trigger: 'change' }
                ],
                url: [
                    { required: true, message: '请输入菜单路径', trigger: 'change' }
                ],
                component: [
                    { required: true, message: '请输入前端组件', trigger: 'change' }
                ],
                sortNo: [
                    { validator: intValidator, trigger: 'change' }
                ],
                permsType: [
                    { required: true, message: '请选择菜单类型', trigger: 'change' }
                ],
            },
            defaultProps: {
                children: 'children',
                label: 'title'
            },
        }
    },
    watch: {
        row: {
            deep: true,
            immediate: true,
            handler(nval) {
                this.handleReset()
            }
        }
    },
    mounted() {
        if(this.$refs.input) {
            this.popoverWidth = this.$refs.input.$el.offsetWidth - 22 < 300 ? 380 : this.$refs.input.$el.offsetWidth - 22
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const { menuType, parentId, name, url, component, redirect, icon, perms, permsType, sortNo, keepAlive, hidden, internalOrExternal, status,btnIsTable,btnName,btnId  } = this.form
                    let form = {
                        menuType,
                        name,
                        url,
                        perms,
                        sortNo,
                        btnIsTable,btnName,btnId
                    }
                    if(menuType === 0) {
                        form = {
                            ...form,
                            component,
                            redirect,
                            icon,
                            keepAlive,
                            hidden,
                            internalOrExternal,
                            parentId: '',
                            permsType: '',
                            status: '',
                            btnIsTable,btnName,btnId
                        }
                    } else if(menuType === 1) {
                        form = {
                            ...form,
                            component,
                            keepAlive,
                            hidden,
                            internalOrExternal,
                            parentId,
                            redirect: '',
                            permsType: '',
                            status: '',btnIsTable,btnName,btnId
                        }
                    } else if(menuType === 2) {
                        form = {
                            ...form,
                            parentId,
                            permsType,
                            status,
                            component: '',
                            redirect: '',
                            icon: '',
                            keepAlive: '',
                            hidden: '',
                            internalOrExternal: '',btnIsTable,btnName,btnId
                        }
                    }

                    this.$emit('ok', form)
                }
            })
        },
        handleReset() {
            for(let item in form) {
                this.form[item] = this.row[item] != undefined ? this.row[item] : this.form[item]
            }
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        handleClear() {
            this.form = { ...form }
        },
        querySearch(queryString, cb) {
            cb([
                { value: 'BasicLayout' },
                { value: 'RouteView' },
            ])
        },
        handleFilterIcon(val) {
            this.filterIcons = icon.filter(item => item.indexOf(val.toLowerCase()) !== -1)
        },
        handleSetIcon(ico) {
            this.form.icon = ico
        }
    },
    computed:{
      buttonShowList(){
        return {
          'ok':this.$btnIsShow('isystem/MenuManage','0','确定'),
        }
      }
    }
}
</script>

<style lang="scss">
.icon-tooltip {
    font-size: 14px;
}
.popper-icon {
    margin-top: 1px !important;
    ul {
        padding: 0 4px;
        margin: 4px 0;
        max-height: 240px;
        overflow: auto;
        li {
            font-size: 30px;
            display:inline-block;
        }
    }
}
.icon-wrap {
    position: relative;
    .question {
        padding: 0 !important;
        top: 12px;
        right: 8px;
        position: absolute;
        border: 0;
        font-size: 16px;
        &:focus,
        &:hover {
            color: inherit;
        }
    }
    .el-input__inner {
        padding-right: 30px;
    }
}
.el-input-group__prepend {
    width: 40px;
    padding: 0 10px;
    line-height: 1;
    box-sizing: border-box;
}
</style>
