import { render, staticRenderFns } from "./FbacodeDialog.vue?vue&type=template&id=70274058&scoped=true&"
import script from "./FbacodeDialog.vue?vue&type=script&lang=js&"
export * from "./FbacodeDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70274058",
  null
  
)

export default component.exports