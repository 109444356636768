<template>
  <div>

    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="业务员">
              <f-select
                  :dict="'sys_user_id'"
                  v-model="queryForm.salesmanId"
                  :is-need="queryForm.salesmanId"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户">
              <el-input v-model="queryForm.username"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户类型">
              <el-select v-model="queryForm.type" filterable clearable>
                <el-option value="1" label="跟进中"></el-option>
                <el-option value="2" label="正常"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="5">-->
<!--            <el-form-item label="拜访月份">-->
<!--              <el-select v-model="queryForm.selectMonth" filterable clearable>-->
<!--                <el-option value="01" label="一月"></el-option>-->
<!--                <el-option value="02" label="二月"></el-option>-->
<!--                <el-option value="03" label="三月"></el-option>-->
<!--                <el-option value="04" label="四月"></el-option>-->
<!--                <el-option value="05" label="五月"></el-option>-->
<!--                <el-option value="06" label="六月"></el-option>-->
<!--                <el-option value="07" label="七月"></el-option>-->
<!--                <el-option value="08" label="八月"></el-option>-->
<!--                <el-option value="09" label="九月"></el-option>-->
<!--                <el-option value="10" label="十月"></el-option>-->
<!--                <el-option value="11" label="十一月"></el-option>-->
<!--                <el-option value="12" label="十二月"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="拜访时间" label-width="100px">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.createTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.createTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>

          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('拜访记录列表')">导出</el-button>
              <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls2('拜访记录详情列表',url.exportDetails)">导出详情</el-button>
          </el-col>

      </el-card>
    </el-form>

      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property==='username'">
              <span><a
                  style="color: #00a0e9"
                  @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
              >{{ scope.row.username }}</a></span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';
import {postAction} from "../../api/manage";


export default {
  name: "SalesmanVisitLogRecord",
  mixins: [QueryMixins],
  components: {
  },

  data() {
    return {

      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      proVisible: false,
      url: {
        list: "/order/salesman/visit/page",
        exportXlsUrl: '/order/salesman/visit/export',
        exportDetails: '/order/salesman/visit/exportDetails',

      },
      issalesman: "0",
      columns: [

        {
          type: "index",
          label:'序号',
          width: 33,
        },

        // {
        //   prop: "username",
        //   label: "客户名",
        // },
        {
          prop: "salesman",
          label: "销售",
        },
        {
          prop: "createTime",
          label: "拜访时间",
        },
        {
          prop: "months",
          label: "入职月数",

        },
        {
          prop: "shangmen",
          label: "本地拜访",
        },
        {
          prop: "saolou",
          label: "本地扫楼",
        },
        {
          prop: "renwu",
          label: "维护任务",
        },
        {
          prop: "num",
          label: "维护总数",
        },
        {
          prop: "completeRate",
          label: "完成率",
        },
        {
          prop: "roleName",
          label: "角色名称",

        },

      ],
      ss:'',
      totalcny : 0,
      totalusd : 0,
      totaleur : 0,
      totalhkd : 0,
      totalgbp : 0,
      totaljpy : 0,
      totalaud : 0,
      totalkrw : 0,
      totalcad : 0,
      totalinr : 0,
      totalnzd : 0,
      totalnok : 0,
      totaltwd : 0,
      totalsgd : 0,
      totalvnd : 0,
      totalcnyTotal: 0,
    };
  },

  created() {


  },
  methods: {
    handleExportXls2(fileName,url) {
      if (!fileName || typeof fileName != 'string') {
        fileName = '导出文件'
      }
      if (fileName === '正常产品' || fileName === '所有产品') {
        this.$confirm(`导出的数据量比较庞大，需要等待1-2分钟?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          this.exportExcel2(fileName,url);
        }).catch(() => {

        })
      } else {
        this.exportExcel2(fileName,url);
      }


    },

    exportExcel2(fileName,url) {
      let params = {...this.queryForm, ...this.filterForm};
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        params['selections'] = this.selectedRowKeys.join(',');
        params['ids'] = this.selectedRowKeys.join(',')
        params['orderNos'] =this.selectedRowOrderNos.join(',')
      } else {
        params['ids'] = '';
      }

      postAction(url, params, {responseType: 'blob'}).then((data) => {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName + '.xls');
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);//下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      })
    },

    infomation(id) {
      // 对于路由不在左侧菜单中的选中菜单处理
        if(id) {
          setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
          this.$router.push("/operation/cabinet/Edit?id=" + id);
        }

    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
