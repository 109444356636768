<template>
  <el-dialog v-if="type !== 'view'" :title="row.id ? '编辑团队介绍' : '新增团队介绍'" :visible="visible"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">

    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文配置" name="zh">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="姓名" prop="staffName">
                <el-input v-model.trim="form.staffName" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位" prop="staffPosition">
                <el-input v-model.trim="form.staffPosition" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分类" prop="staffType">
                <f-select dict="official_website_staff_type" v-model="form.staffType" :isNeed="form.staffType"></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="staffPhone">
                <el-input v-model.trim="form.staffPhone" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="简介" prop="staffIntroduction">
                <quill-editor ref="staffIntroduction" v-model="form.staffIntroduction" class="myQuillEditor"
                  :options="quillOption"
                  @change="({ quill, html }) => { hanldeHtmlInfo(quill, html, 'staffIntroduction') }" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="照片" prop="staffFile">
                <f-upload v-model="form.staffFile" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 创始人：390*414； 其他：340*337</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="英文配置" name="en">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="姓名" prop="staffNameEn">
                <el-input v-model.trim="form.staffNameEn" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位" prop="staffPositionEn">
                <el-input v-model.trim="form.staffPositionEn" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分类" prop="staffTypeEn">
                <f-select dict="official_website_staff_type" v-model="form.staffTypeEn"
                  :isNeed="form.staffTypeEn"></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="staffPhoneEn">
                <el-input v-model.trim="form.staffPhoneEn" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="简介" prop="staffIntroductionEn">
                <quill-editor ref="text" v-model="form.staffIntroductionEn" class="myQuillEditor" :options="quillOption"
                  @change="({ quill, html }) => { hanldeHtmlInfo(quill, html, 'staffIntroductionEn') }" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="照片" prop="staffEnFile">
                <f-upload v-model="form.staffEnFile" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 创始人：390*414； 其他：340*337</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-col :span="24">
          <el-card header="公共部分">
            <el-row class="inputs_row">
              <el-col :span="8">
                <el-form-item label="排序" prop="staffSort">
                  <el-input v-model.trim="form.staffSort" autocomplete="off" type="number" maxlength="20" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
  <el-dialog v-else-if="type == 'view'" title="简介预览" :visible="visible" custom-class="c-custom-dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-row>
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文简介" name="zh">
          <el-col :span="24">
            <div v-html="form.staffIntroduction"></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="英文简介" name="en">
          <el-col :span="24">
            <div v-html="form.staffIntroductionEn"></div>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import quillConfig from "../../../utils/quill-config";
import { quillEditor } from "vue-quill-editor";
import { httpAction } from '@/api/manage'
export default {
  name: 'AddRoleDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      activeName: 'zh',
      form: {},
      rules: {
        staffName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        staffNameEn: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        staffType: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        staffTypeEn: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        staffPosition: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        staffPositionEn: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        staffPhone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        staffPhoneEn: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        staffIntroduction: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
        staffIntroductionEn: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
        staffFile: [
          { required: true, message: '请选择照片', trigger: 'change' },
        ],
        staffEnFile: [
          { required: true, message: '请选择照片', trigger: 'change' },
        ],
        staffSort: [
          { required: true, message: '请选择输入排序', trigger: 'blur' },
        ],
      },
      checkKey: {
        zh: ['staffName', 'staffType', 'staffPosition', 'staffPhone', 'staffIntroduction', 'staffFile'],
        en: ['staffNameEn', 'staffTypeEn', 'staffPositionEn', 'staffPhoneEn', 'staffIntroductionEn', 'staffEnFile']
      },
      url: {
        add: '/sys/owTeamIntroduction',
        edit: '/sys/owTeamIntroduction',
        detail: '/sys/owTeamIntroduction/'
      },
      quillOption: quillConfig,
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.row.id) {
          this.getDetail(this.row.id)
        } else {
          this.form.staffIntroduction = '<p><strong style=\"color: rgb(70, 185, 124);\">· </strong><span style="color: rgb(102, 102, 102);"> </span></p>';
          this.form.staffIntroductionEn = '<p><strong style=\"color: rgb(70, 185, 124);\">· </strong><span style="color: rgb(102, 102, 102);"> </span></p>';
        }
      }
    }
  },
  methods: {
    hanldeHtmlInfo(quill, html, dataName) {
      if (html.indexOf('<br>') !== -1) {
        this.$set(this.form, dataName, this.form[dataName].replace('<br>', '<strong style=\"color: rgb(70, 185, 124);\">· </strong><span style="color: rgb(102, 102, 102);"> </span>'))
        quill.pasteHTML(this.form[dataName])
        setTimeout(() => quill.setSelection(quill.getSelection().index + 3, 0), 0)
      }
    },
    getDetail(id) {
      this.$store.commit("setFullscreenLoading", true);
      httpAction(this.url.detail + id, 'GET').then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.form = res.result;
        this.form.staffEnFile && JSON.stringify(this.form.staffEnFile) !== '{}' ? this.form.staffEnFile = [this.form.staffEnFile] : '';
        this.form.staffFile && JSON.stringify(this.form.staffFile) !== '{}' ? this.form.staffFile = [this.form.staffFile] : '';
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
      })
    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if (this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.staffFile;
      delete params.staffEnFile;
      params.staffUrl = (this.form.staffFile && this.form.staffFile.length && this.form.staffFile[0].url) || '';
      params.staffUrlId = (this.form.staffFile && this.form.staffFile.length && this.form.staffFile[0].id) || '';
      params.staffUrlEn = (this.form.staffEnFile && this.form.staffEnFile.length && this.form.staffEnFile[0].url) || '';
      params.staffUrlEnId = (this.form.staffEnFile && this.form.staffEnFile.length && this.form.staffEnFile[0].id) || '';
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写对应的英文配置';
            this.$message.warning(message);
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.form = {}
      this.activeName = 'zh'
      this.$refs.form && this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
