<!--整柜-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="最后费用销账时间" label-width="110px">
                            <el-date-picker
                              class="input time"
                              v-model="queryForm.settleTime"
                              type="month"
                              size="small"
                              default-time="00:00:00"
                              placeholder="开始时间"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              style="width: 100%"
                              @click.stop.native
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div>
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('整柜列表')">导出</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'pol'">
                        <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
                        <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'commission'">
                        <span>{{parseDict(scope.row.commission,'commission')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cusUserType'">
                        <span>{{parseDict(scope.row.cusUserType,'user_type1')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'recStatus'">
                        <span>{{parseDict(scope.row.recStatus,'os_rec_status')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default', 'transportStatus', 'bd_port','commission','user_type1','os_rec_status'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                columns: [
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                    },
                    {
                        prop: "createTime",
                        label: "订单日期",
                        minWidth: 100,
                        sortable: "custom",
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 100,
                    },
                    {
                        prop: "operator",
                        label: "操作",
                        minWidth: 80,
                    },
                    {
                        prop: "doc",
                        label: "客服",
                        minWidth: 80,
                    },
                    {
                        prop: "salesman",
                        label: "业务员",
                        minWidth: 80,
                    },
                    {
                        prop: "settleTime",
                        label: "最后费用销账时间",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "settlement",
                        label: "结算对象",
                        minWidth: 80,
                    },
                    {
                        prop: "feename",
                        label: "费用名",
                        minWidth: 80,
                    },

                    {
                        prop: "houseCode",
                        label: "仓库代码",
                        minWidth: 80,
                    },
                    {
                        prop: "containerNum",
                        label: "柜数",
                        minWidth: 80,
                        sortable: "custom",
                    },
                    {
                        prop: "commission",
                        label: "提成性质",
                        minWidth: 80,
                    },
                    {
                        prop: "recStatus",
                        label: "回款状态",
                        minWidth: 80,
                    },
                    {
                        prop: "transportStatus",
                        label: "业务性质",
                        minWidth: 80,
                    },
                    {
                        prop: "cusUserType",
                        label: "客户类型",
                        minWidth: 80,
                    },
                ],
                url: {
                    list: "/interface/sales/getMaritimeFullCabinetList",
                    //exportXlsUrl: "/order/osOrderReport/zhengGuiListExportXls",
                    exportXlsUrl: "/file/export/excel/SalesExcel/downloadWholeCabinetListExportXls",
                },
            };
        },
        methods: {

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
        },
    };
</script>

<style lang="scss" scoped>
</style>
