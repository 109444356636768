<template>
  <div class="main-full-content">
      <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium"
               @submit.native.prevent>
          <el-card class="box-card">
              <el-row>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                      <el-form-item label="标题">
                          <el-input v-model="queryForm.title" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                      <el-form-item label="内容">
                          <el-input v-model="queryForm.content" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                      <el-form-item label="状态">
                          <el-input v-model="queryForm.result" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                      <el-form-item label-width="30px">
                          <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                          <el-button plain @click="handleReset">重置</el-button>
                      </el-form-item>
                  </el-col>
              </el-row>
          </el-card>

      </el-form>
    <f-table
      v-loading="loading"
      ref="GTable"
      border
      size="medium"
      row-key="id"
      class="c-th-has-bgcolor"
      :columns="columns"
      :data="tableData"
      :cell-style="{ 'text-align': 'center' }"
      tableHeight="80vh"
      @selection-change="handleSelectionChange"
      @sort-change="handleTableChange"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button
            type="text"
            @click="handleEdit(scope.row)"
            v-if="scope.row.status === '0'"
            >标记已读</el-button
          >
          <el-button type="text" v-else disabled>已读</el-button>
        </template>
        <template v-else-if="scope.column.property === 'createBy'">
          <span>{{ parseDict(scope.row.createBy, "all_user_id") }}</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import { putAction, getAction } from "@/api/manage";
import { mapState } from 'vuex';
import store from "@/store";
export default {
  mixins: [QueryMixins],
  components: {},
  computed: {
      ...mapState(['messageQuery'])
  },
  data() {
    return {
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "title",
          label: "标题",
          sortable: "custom",
        },
        {
          prop: "content",
          label: "内容",
          sortable: "custom",
          width: 500
        },
        {
          prop: "createTime",
          label: "发送时间",
          sortable: "custom",
        },
        {
          prop: "createBy",
          label: "发送人",
          sortable: "custom",
        },
      ],
      url: {
        list: "/sys/sysMsgLog/list",
        edit: "/sys/sysMsgLog/edit",
        query: "/sys/sysMsgLog/query",
      },
      dictCodes: ["all_user_id"],
    };
  },
  watch: {
    messageQuery(val) {
      this.handleSearch()
    }
  },
  mounted() {},
  methods: {
    handleEdit(row) {
      putAction(this.url.edit, {
        id: row.id,
      })
        .then((res) => {
          // consoloe.log(res);
          if (res.success) {
            this.$message.success(res.message);
            this.handleSearch();
            this.getMessageQuery();
          }
        })
    },
    getMessageQuery() {
      getAction(this.url.query)
        .then((res) => {
          if (res.success) {
            store.commit("setMessageQuery", res.result);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
