<!--待发货-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row style="display: flex;flex-wrap: wrap;">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input class="textarea_table" v-model="queryForm.orderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="运单号">
                            <el-input class="textarea_table" v-model="queryForm.runOrderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="跟踪号">
                            <el-input class="textarea_table" v-model="queryForm.logNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <f-select :multiple="true" v-model="selectChannelList" :dict="'kd_channel'"
                                      :isNeed="selectChannelList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="服务商">
                            <el-select
                                    v-model="queryForm.supId"
                                    filterable
                                    clearable
                                    @change="getSupplierChannel"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in allSupplierList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="服务商渠道">
                            <el-select
                                    v-model="queryForm.supChannelId"
                                    filterable
                                    clearable
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in supplierChannelList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单开始时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录材积开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.volumeSureTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="录材积开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录材积结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.volumeSureTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="录材积结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="发货开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliverGoodsTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="发货开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="发货结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliverGoodsTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="发货结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="货物状态">
                            <f-select v-model="queryForm.orderStatus" :dict="'kd_order_status'"
                                      :isNeed="queryForm.orderStatus"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名">
                            <el-input class="textarea_table" v-model="queryForm.cusName"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanIdList" :dict="'sys_user_id'" :multiple="true"
                                      :isNeed="queryForm.salesmanIdList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <f-select v-model="queryForm.docIdList" :dict="'sys_user_id'" :isNeed="queryForm.docIdList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款状态">
                            <f-select v-model="queryForm.recStatusList" :dict="'kd_order_rec_status'" :isNeed="queryForm.recStatusList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="国家">
                            <f-select v-model="queryForm.countryList" :multiple="true" :dict="'bd_country'" :isNeed="queryForm.countryList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务提成">
                            <f-select v-model="queryForm.salesmanCommission"  :dict="'unit_whether'" :isNeed="queryForm.salesmanCommission"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearchM">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="handleExportXlsM"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="67vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'declareType'">
                        <span :style="scope.row.declareType == '1' || scope.row.declareType == '2' || scope.row.declareType == '4' ? 'color: red;' : ''">{{parseDict(scope.row.declareType,'kd_declare_type')}}</span>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'channel'">-->
<!--                        <span>{{parseDict(scope.row.channel,'kd_channel')}}</span>-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="handleEdit(scope.row.orderNo)">{{scope.row.orderNo}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'salesman'">
                        <span>{{scope.row.salesman}}</span> <br>
                        <span v-if="scope.row.salesmanCommission === '1'" style="color: #00a0e9">已发提成</span>
                        <span v-else style="color: red">未发提成</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cusName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.cusName}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'orderStatus'">
<!--                        <span>{{parseDict(scope.row.orderStatus,'kd_order_status')}}</span>-->
                        <span>{{$parseKdOrderStatus(scope.row.orderStatus)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'receiveCountry'">
                        <span v-if="scope.row.receiveFbaCode">{{scope.row.receiveCountry}} - {{scope.row.receiveFbaCode}}</span>
                        <span v-else>{{scope.row.receiveCountry}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'payStatus'">
                        <span>付款状态：{{parsePayStatus(scope.row.payStatus)}}</span> <br>
                        <span>收款状态：{{parseRecStatus(scope.row.recStatus)}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-p-v-8 bottom_flex">
                <el-col :span="12" class="total_price">
                    <span>{{'总票数：' + (countList.total || '0')}}</span>
                    <span>{{'总件数：' + (countList.totalCtn || '0')}}</span>
                    <span>{{'总计费重：' + (countList.totalUpdateFeeWeight || '0')}}</span>
                    <!--          <span>{{'总实重：' + (countList.totalActualWeight || '0')}}</span>
                              <span>{{'总出货重：' + (countList.totalShipmentWeight || '0')}}</span>-->
                </el-col>
                <el-col class="f-text-right" :span="12">
                    <el-pagination
                            v-bind="ipagination"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange">
                    </el-pagination>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog title="统计数据显示" :visible.sync="dialogKdCountVisible" :close-on-click-modal="false"
                   v-if="dialogKdCountVisible">
            <f-table
                    ref="kdCountTb"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columnsCount"
                    :data="kdCountNum"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh"
            >
            </f-table>
        </el-dialog>

        <el-dialog title="修改客服" :visible.sync="dialogUpdateDocVisible" :close-on-click-modal="false"
                   v-if="dialogUpdateDocVisible">
            <el-form :model="form" :rules="rules" ref="form">

                <el-form-item label="单号" :label-width="formLabelWidth" prop="orderNo">
                    <el-input v-model="form.orderNo" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="客服" :label-width="formLabelWidth" prop="doc">
                    <f-select v-model="form.doc" :dict="'sys_user'" @changet="getDocId"></f-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogUpdateDocVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateDocSure()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import {getAction, getAllSupplier, postAction} from '@/api/manage'
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        mixins: [QueryMixins],
        data() {
            return {
                isUsePost:true,
                rules: {
                    supId: [
                        {required: true, message: '请选择服务商', trigger: 'change'},
                    ],
                    supChannelId: [
                        {required: true, message: '请选择服务商渠道', trigger: 'change'},
                    ],
                    logNo: [
                        {max: 80, message: '长度不能超过80', trigger: 'change'},
                    ],
                    doc: [
                        {required: true, message: '请选择客服', trigger: 'change'},
                    ],
                },
                dictCodes: ['kd_declare_type', 'is_default', 'supplier_id', 'supplier_channel_id'],
                ids: [],
                row: {},
                form: {},
                btnLoading: false,
                btnLoadingYs: false,
                proVisible: false,
                formLabelWidth: '100px',
                //控制弹框显示
                dialogFormVisible: false,
                dialogUpdateDocVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                        width: 100
                    },

                    {
                        prop: 'cusName',
                        label: '客户名',
                        width: 100
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                        width: 100
                    },
                    {
                        prop: 'receiveCountry',
                        label: '目的地',
                        width: 110
                    },
                    {
                        prop: 'channelName',
                        label: '渠道名称',
                        width: 100
                    },
                    {
                        prop: 'logNo',
                        label: '跟踪号',
                        width: 100
                    },
                    {
                        prop: 'actualCtn',
                        label: '件数',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'updateFeeWeight',
                        label: '计费重',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'guanShuiMoney',
                        label: '关税预收',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'shouldRecMoney',
                        label: '总金额',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'noRecMoney',
                        label: '未支付',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'realRecMoney',
                        label: '已支付',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'recBur',
                        label: '应收币种',
                        width: 50
                    },
                    {
                        prop: 'payStatus',
                        label: '付款状态',
                        width: 120
                    },
                    {
                        prop: 'payType',
                        label: '付款类型',
                        width: 100
                    },
                ],
                columnsCount: [
                    {
                        prop: 'receivable',
                        label: '应收',
                    },
                    {
                        prop: 'realPay',
                        label: '实收',
                    },
                    {
                        prop: 'payable',
                        label: '应付',
                    },
                ],
                url: {
                    list: '/order/kdOrder/allOrderList',
                    queryBysupId: '/sys/supplierChannel/queryBysupId', // 根据供应商ID 获取供应商渠道
                    listCount: "/order/kdOrder/listCount", //获取total-总票数,totalCtn-总件数,totalUpdateFeeWeight-总计费重
                    exportXlsUrl: '/order/kdOrder/exportXlsSale',
                    kdSettleCount: "/order/kdOrder/kdSettleCount",
                    importHySettles: "/order/kdOrderSettle/importKdSettles",
                    importKdYsSettles: "/order/kdOrderSettle/importKdYsSettles",
                    downloadHyYsModel: '/order/os/settle/Hy/in/downloadHyYsExcel',
                    downloadHyYfModel: '/order/os/settle/Hy/in/downloadHyYfExcel',
                    syncDoc: "/order/kdOrder/syncDoc", //同步客服
                    updateDoc: "/order/kdOrder/updateDoc", //修改客服
                },
                allSupplierList: [],   //快递供应商
                supplierChannelList: [], //快递供应商的渠道
                selectChannelList: [],  //筛选渠道列表
                countList: {},  //统计总值
                dialogKdCountVisible: false,
                kdCountNum: {},  //快递统计总数
            }
        },
        methods: {
            parsePayStatus(val){
                if (val === '0'){
                    return '无应付'
                }else if (val === '1') {
                    return '未付款'
                }else if (val === '2') {
                    return '部分付款'
                }else if (val === '3'){
                    return '已支付'
                }else {
                    return '无'
                }
            },
            parseRecStatus(val){
                if (val === '0'){
                    return '无应收'
                }else if (val === '1') {
                    return '未收款'
                }else if (val === '2') {
                    return '部分收款'
                }else if (val === '3'){
                    return '已支付'
                }else {
                    return '无'
                }
            },
            initMoreFuc() {
                this.getListCount();
            },
            getListCount() {
                postAction(this.url.listCount, this.queryForm)
                    .then(res => {
                        if (res.result) {
                            this.countList = res.result;
                        } else {
                            this.countList = {
                                total: 0,
                                totalCtn: 0,
                                totalUpdateFeeWeight: 0,
                                totalActualWeight: 0,
                                totalShipmentWeight: 0
                            }
                        }

                    })
            },
            handleExportXlsM() {
                this.queryForm.channelIds = this.selectChannelList;
                this.handleExportXls('快递全部订单');
            },
            handleSearchM() {
                this.queryForm.channelIds = this.selectChannelList;
                this.handleSearch();
            },
            initCusPage({cusId}) {
                if (cusId) {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/customer/Edit');
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }

            },
            // 批量快递应付导入
            uploadSectionFile(param) {
                this.btnLoading = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法8888888888888");
                postAction(this.url.importHySettles, form)
                    .then((res) => {
                        //这里做上传之后的操作
                        if (res.success) {
                            this.$message.success(res.message);
                            this.initSettleList();
                            this.init();
                        }

                    }).finally(() => {
                    this.btnLoading = false;

                })

            },
            // 批量快递应收导入
            uploadSectionFileYs(param) {
                this.btnLoadingYs = true;
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法8888888888888");
                postAction(this.url.importKdYsSettles, form)
                    .then((res) => {
                        //这里做上传之后的操作
                        if (res.success) {
                            this.$message.success(res.message);
                            this.initSettleList();
                            this.init();
                        }

                    }).finally(() => {
                    this.btnLoadingYs = false;

                })

            },
            //获取所有的 快递供应商
            initAllSupplier() {
                this.allSupplierList = [];
                getAllSupplier("2").then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].id;   //供应商ID
                            obj.label = res.result[i].chinaseName;  //供应商中文名
                            this.allSupplierList.push(obj);
                            console.log("allSupplierList", this.allSupplierList);
                        }
                    }
                }).catch(err => {
                    this.$message.error(err)
                })
            },
            //获取供应商的渠道
            getSupplierChannel(code) {
                this.supplierChannelList = [];
                let url = code ? this.url.queryBysupId + "?supId=" + code : this.url.queryBysupId + "?supId=";
                getAction(url)
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id;   //供应商ID
                                obj.label = res.result[i].channelName;  //供应商中文名
                                this.supplierChannelList.push(obj);
                            }
                        }
                    })

            },
            //进入编辑页面
            handleEdit(val) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + val);
            },
            handleReset() {
                this.queryForm = {};
                this.selectChannelList = [];
                this.ipagination.currentPage = 1;
                this.getSupplierChannel();
                this.handleQuery()
            },
            kdCount() {
                this.kdCountNum = [];
                postAction(this.url.kdSettleCount, this.queryForm)
                    .then(res => {
                        if (res.result) {
                            if (res.result['实收']) {
                                res.result['实收'].forEach((item, index) => {
                                    if (this.kdCountNum[index]) {
                                        this.kdCountNum[index].realPay = item || '';
                                    } else {
                                        this.kdCountNum.push({
                                            realPay: item || '',
                                        })
                                    }
                                })
                            }
                            if (res.result['应付']) {
                                res.result['应付'].forEach((item, index) => {
                                    if (this.kdCountNum[index]) {
                                        this.kdCountNum[index].payable = item || '';
                                    } else {
                                        this.kdCountNum.push({
                                            payable: item || '',
                                        })
                                    }
                                })
                            }
                            if (res.result['应收']) {
                                res.result['应收'].forEach((item, index) => {
                                    if (this.kdCountNum[index]) {
                                        this.kdCountNum[index].receivable = item || '';
                                    } else {
                                        this.kdCountNum.push({
                                            receivable: item || '',
                                        })
                                    }
                                })
                            }
                            this.dialogKdCountVisible = true;
                        }
                    })
            },
            syncDoc() {
                getAction(this.url.syncDoc).then(res => {
                    if (res.result) {
                        this.$message.success(res.message);
                    }
                })

            },
            updateDoc() {
                this.form = {};
                this.form.orderNo = this.selectedRowOrderNos.join(',');
                this.form.id = this.selectedRowKeys.join(',');
                this.dialogUpdateDocVisible = true;
            },
            getDocId(val) {
                if(val){
                    this.$set(this.form,'docId',val);
                }
            },
            updateDocSure() {
                console.log("this.form",this.form)
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        getAction(this.url.updateDoc, this.form).then(res => {
                            if (res.result) {
                                this.$message.success(res.message);
                                this.handleQuery();
                                this.dialogUpdateDocVisible = false;
                            }
                        })
                    }
                })
            }
        },
        created() {
            this.initAllSupplier(); //获取所有的快递供应商
            this.getSupplierChannel();
        },
      computed:{
        buttonShowList(){
          return {
            'dc':this.$btnIsShow('kdorder/AllKdOrderSale','0','导出'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
