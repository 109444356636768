<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium"
                 @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="邮件标题">
                            <el-input v-model="queryForm.emailSubject" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="邮件发送方式">
                            <el-select v-model="queryForm.emailSendMethod" filterable clearable>
                                <el-option value="0" label="定时发送"></el-option>
                                <el-option value="1" label="手动发送"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="是否推送">
                            <el-select v-model="queryForm.isSendEmail" filterable clearable>
                                <el-option value="0" label="未推送"></el-option>
                                <el-option value="1" label="已推送"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"

                           v-if="buttonShowList.add"
                >新增
                </el-button>
                <el-button type="primary" size="medium"  @click="sendEmailByAutomatic()"
                           v-if="buttonShowList.dsfsyj">定时发送邮件</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleDelete(scope.row)"
                                   v-if="buttonShowList.sc">删除
                        </el-button>
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj">编辑
                        </el-button>
                        <el-button type="text" @click="sendEmail(scope.row)"
                                   v-if="buttonShowList.sdfsyj">手动发送邮件
                        </el-button>
                      <el-button type="text" @click="seeEmail(scope.row)">查看发送详情
                      </el-button>
                        <!--<el-button type="text" @click="handleDele(scope.row)">删除</el-button>-->
                    </template>
                    <template v-else-if="scope.column.property === 'emailSendMethod'">
                        <span v-if="scope.row.emailSendMethod == 0">定时发送</span>
                        <span v-else>手动发送</span>
                    </template>
                    <template v-else-if="scope.column.property === 'recType'">
                        <span v-if="scope.row.recType == 0">客户</span>
                        <span v-else>业务员</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isSendEmail'">
                        <span v-if="scope.row.isSendEmail == 0 && scope.row.emailSendMethod == 0" style="color: red">未推送</span>
                        <span v-else-if="scope.row.isSendEmail == 1 && scope.row.emailSendMethod == 0" style="color:green;">已推送</span>
                        <span v-else></span>
                    </template>

                  <template v-else-if="scope.column.property === 'emailSendPeopleList'">
                    <span v-if="scope.row.isForeign === '0' ">境内</span>
                    <span v-else-if="scope.row.isForeign === '1' ">境外</span>
                    <span v-else></span>
                  </template>

<!--                    <template v-else-if="scope.column.property === 'emailSendPeopleList'">-->
<!--                        <div style="display: flex;flex-direction: column">-->
<!--                            <span v-for="item in scope.row.emailSendPeopleList">{{parseEmail(item.emailSendKey)}}</span>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;<span v-for="item in scope.row.emailSendPeopleList">{{item}}</span>&ndash;&gt;-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'isEnable'">
                        <span v-if="scope.row.isEnable == 0">启用</span>
                        <span v-else>禁用</span>
                    </template>


                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog :title="form.id?'编辑邮件发送模板':'新增邮件发送模板'" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false"
                   v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">

                <el-form-item label="邮件主题" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="form.emailSubject" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮件发送方式" :label-width="formLabelWidth" prop="shortName">
                    <el-radio-group v-model="form.emailSendMethod">
                        <el-radio :label="'1'">手动发送</el-radio>
                        <el-radio :label="'0'">定时发送</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="定时发送时间" :label-width="formLabelWidth" prop="shortName" v-if="form.emailSendMethod ==0">
                    <el-date-picker
                            v-model="form.emailSendTime"
                            type="datetime"
                            format="yyyy-MM-dd HH:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"

                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收件人类型" :label-width="formLabelWidth" prop="sort">
                    <el-radio-group v-model="form.recType">
                        <el-radio :label="'0'">客户</el-radio>
                        <!--<el-radio :label="'1'">业务员</el-radio>-->
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="收件人邮箱" :label-width="formLabelWidth" prop="sort">
                    <!--<f-select :dict="'email_template_customer'" v-model="form.email" :is-need="form.email"-->
                              <!--:multiple="true" @changet="changeEmailValue"></f-select>-->
<!--                    <f-select :data-list="CustomerEmailList" :isSuo="true"  v-model="form.email" :is-need="form.email" clearable filterable :multiple="true"></f-select>-->
                  <el-radio-group v-model="form.isForeign">
                    <el-radio :label="'0'">境内</el-radio>
                    <el-radio :label="'1'">境外</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="isDisable" label="启用/禁用" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.isEnable">
                        <el-radio :label="'0'">启用</el-radio>
                        <el-radio :label="'1'">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="邮件内容" :label-width="formLabelWidth" prop="sort">
                    <quill-editor
                            ref="text"
                            v-model="form.emailContent"
                            class="myQuillEditor"
                            :options="quillOption"
                    />
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

      <send-yingxiao-email-log
          :title="rowTitle"
          :bus-id="rowBusId"
          :visible="emailLogVisible"
          @ok="closeEmailDialog"
      >
      </send-yingxiao-email-log>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {yingwenPattern} from '@/utils/pattern'
    import quillConfig from "@/utils/quill-config";
    import {quillEditor} from "vue-quill-editor";
    import {getAction} from "../../../api/manage";
    import SendYingxiaoEmailLog from "./sendYingxiaoEmailLog.vue";


    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        components: {
          SendYingxiaoEmailLog,
            quillEditor,
        },
        data() {
            return {
                chooseValue: [],
                tableCount:0,
                CustomerEmailList:[],
                CustomerEmailMap:{},
                options: [],
                rangeNumber: 10,
                quillOption: quillConfig,
                dictCodes: ['email_template_customer'],
                rules: {
                    // name: [
                    //   {required: true, message: '请输入币种中文名', trigger: 'change'},
                    //   {max: 50, message: '长度不能超过50', trigger: 'change'},
                    //
                    // ],
                    // shortName: [
                    //   {required: true, message: '请输入币种英文名', trigger: 'change'},
                    //   {max: 50, message: '长度不能超过50', trigger: 'change'},
                    //   // {pattern: yingwenPattern, message: '英文名格式有误', trigger: 'change'},
                    // ]


                },
                ids: [],
                row: {},
                form: {
                    emailSubject: null,
                    email: [],
                    emailSendMethod: '1',
                    isForeign: '1',
                    recType: '0',
                    emailContent: null,
                    isEnable: '0'
                },
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'emailSubject',
                        label: '邮件主题',
                        sortable: 'custom',

                    },
                    {
                        prop: 'emailSendMethod',
                        label: '邮件发送方式',
                        sortable: 'custom',

                    },
                    {
                        prop: 'recType',
                        label: '收件人类型',
                        sortable: 'custom',
                    },
                    {
                        prop: 'isSendEmail',
                        label: '定时任务推送状态',
                        sortable: 'custom',
                    },
                    {
                        prop: 'emailSendPeopleList',
                        label: '推送邮箱',
                        sortable: 'custom',
                    },

                    {
                        prop: 'isEnable',
                        label: '是否禁用',
                        sortable: 'custom',
                    },

                ],
                url: {
                    list: '/express/emailSendTemplate/getPageList',
                    edit: '/file/emailSendTemplate',
                    delete:'/file/emailSendTemplate/deleteEmilTemplate',
                    sendEmail: '/express/emailSendTemplate/sendEmailByHand',
                    sendEmailByAutomatic:'/express/emailSendTemplate/sendEmailByAutomatic',
                    add: '/file/emailSendTemplate',
                    getEmailList: '/file/emailSendPeople/getEmailList',
                    getEmailTemplateById: '/express/emailSendTemplate/getEmailTemplateById',
                },
                emailLogVisible:false,
                rowBusId:'',
                rowTitle:'',
            }
        },
        methods: {
          seeEmail(row){
            console.log("被选中的人",row);
            this.rowTitle = row.emailSubject;
            this.rowBusId = row.id.toString();
            this.emailLogVisible = true;
          },
          closeEmailDialog(){
            this.emailLogVisible = false;
          },
            parseEmail(val){
              let obj = this.CustomerEmailMap[val];
              console.log('匹配到的邮箱',this.CustomerEmailMap,obj);
              if (obj) {
                  return obj['text'];
              } else {
                  return '';
              }

            },
            changeEmailValue(e) {
                console.log(e)
            },
            //获取客户对应邮箱列表
            getDictEmailList() {
                getAction(this.url.getEmailList).then(res => {
                    this.CustomerEmailList = res.result
                    this.tableCount = res.result.length
                    // this.getDictList("email_template_customer")
                    this.CustomerEmailMap = this.listToMap(this.CustomerEmailList,'itemValue');
                })
            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.handleResetForm();
                this.dialogFormVisible = true;
            },
            handleResetForm() {
                this.form = {
                    email: [],
                    emailSubject: null,
                    emailSendMethod: '0',
                    isForeign: '0',
                    recType: '0',
                    emailContent: null,
                    isEnable: '0'
                }
            },
            //定时执行定时任务
            sendEmailByAutomatic(){
                getAction(this.url.sendEmailByAutomatic).then(res=>{
                    if(res.code == 200){
                        this.$message.success("定时邮件发送模板推送成功！")
                    }
                })
            },
            sendEmail(row) {
                this.form = JSON.parse(JSON.stringify(row));
                if(this.form.isEnable =='1'){
                    this.$message.error("该邮件发送模板已禁用")
                    return
                }
                this.$confirm(`确定推送信息给模板中的邮箱吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        postAction(this.url.sendEmail, this.form)
                            .then((res) => {
                                if (res.success) {
                                    // this.$message.success(res.message);
                                  this.$alert(
                                      `${res.message}</span>`,
                                      "发送邮件结果",
                                      {
                                        dangerouslyUseHTMLString: true,
                                      }
                                  );
                                    // this.$alert(res.message, '发送邮件结果', {
                                    //     dangerouslyUseHTMLString: true
                                    // });
                                    // this.$message.warning("您的邮件已发送，请耐心等待，稍后消息中心查看邮件发送成功与失败的情况！");
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery()
                                }
                            })
                    })
                    .catch(() => {
                    });


            },
            handleDelete(row){
                this.$confirm(`确定删除该邮件模板吗？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery()
                        });
                    })
                    .catch(() => {
                    });
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                getAction(this.url.getEmailTemplateById,{id:row.id}).then(res => {
                    this.form =res.result
                    this.form.email = []
                })
                // this.form.emailSendPeopleList.forEach(item => {
                //     this.form.email.push(item.emailSendKey)
                // })
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除该币种吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            delCacheDict(){
                sessionStorage.removeItem("dict:email_template_customer")
                this.$store.state.dict['dict:email_template_customer'] = null;
                this.getDictList('dict:email_template_customer"')
                this.handleQuery()
            },
        },
        created() {
            this.getDictEmailList()
        },
        mounted() {
            this.delCacheDict();
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/email/emailSendTemplate','0','新增'),
            'dsfsyj':this.$btnIsShow('isystem/email/emailSendTemplate','1','定时发送邮件'),
            'sc':this.$btnIsShow('isystem/email/emailSendTemplate','1','删除'),
            'bj':this.$btnIsShow('isystem/email/emailSendTemplate','1','编辑'),
            'sdfsyj':this.$btnIsShow('isystem/email/emailSendTemplate','1','手动发送邮件'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
