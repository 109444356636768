<!--带入仓-->
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div style="display: flex">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="客户">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.customer"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="销售">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.salesman"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="亏本订单处理类型">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.handleType"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="亏本订单审核原因">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.auditReason"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="inputs_row">
            <el-col :sm="24" :md="4">
              <el-form-item label="销售组别">
                <f-select
                    v-model="queryForm.deptIdList"
                    :dict="'sys_depart'"
                    :isNeed="queryForm.deptIdList"
                    :multiple="true"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="handleReset"
                  icon="reload"
                  style="margin-left: 8px"
              >重置</el-button
              >
              <el-button
                  size="medium"
                  @click="
                  exportExcelPlus(
                    url.downloadSalemanProfitAuditExportXls,
                    '已审核订单明细'
                  )
                "
              >导出
              </el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">
      <!--<div>-->
      <!--<el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('无成本订单报表')">导出</el-button>-->
      <!--</div>-->
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="65vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'deptId'">
            <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import QueryMixins from "../../../mixins/QueryMixins";
import { setUpRouteUtil } from "@/utils/util";

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      dictCodes: [
        "is_default",
        "transportStatus",
        "order_importer",
        "bd_port",
        "orderStatus",
        "pay_type",
        "pglx",
        "sys_depart"
      ],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 100,
        },
        {
          prop: "salesman",
          label: "销售",
        },
        {
          prop: "deptId",
          label: "销售组别",
        },
        {
          prop: "customer",
          label: "客户",
        },
        {
            prop: "realCmb",
            label: "实际体积",
        },
        {
            prop: "countFeeNum",
            label: "体积重",
        },
        {
          prop: "profit",
          label: "利润",
        },
        {
          prop: "auditMan",
          label: "审核人",
        },
        {
          prop: "auditTime",
          label: "审核时间",
          minWidth: 100,
        },
        {
          prop: "handleType",
          label: "亏本订单处理类型",
          minWidth: 100,
        },
        {
          prop: "auditReason",
          label: "亏本订单审核原因",
          minWidth: 100,
        },
      ],
      url: {
        list: "finance/fee/settleCommission/profitAuditList",
        // exportXlsUrl: "/order/sale/enterWarehouseListExportXls",
        downloadSalemanProfitAuditExportXls:
          "/file/export/excel/SalesExcel/downloadSalemanProfitAuditExportXls",
      },
      initData: { isAudit: "1" },
      isUsePost:true
    };
  },
  methods: {
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$intoDocPage(orderNo);
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether = "0";
      if (this.$refs["GTable"]) {
        this.$refs["GTable"].handleReset();
      }
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.isWhether = "0";
  },
};
</script>

<style lang="scss" scoped>
</style>
