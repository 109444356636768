<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm"  size="medium">
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="周数" :label-width="formLabelWidth">
                <el-input v-model="queryForm.week"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="专线、拆柜" :label-width="formLabelWidth">
                <f-select v-model="queryForm.cabinetCategory" :is-need="queryForm.cabinetCategory"
                          :dict="'cabinet_category'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="柜号" :label-width="formLabelWidth">
                <el-input v-model="queryForm.containerNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="派送操作" :label-width="formLabelWidth">
                <f-select v-model="queryForm.endOperatorId" :is-need="queryForm.endOperatorId" :dict="'sys_user_id'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="目的港" :label-width="formLabelWidth">
                <f-select v-model="queryForm.podEnd" :is-need="queryForm.podEnd" :dict="'bd_port'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>



            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="发预报" :label-width="formLabelWidth">
                <f-select v-model="queryForm.isMakeForecast" :is-need="queryForm.isMakeForecast" :dict="'unit_whether'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="分税状态" :label-width="formLabelWidth">
                <el-select v-model="queryForm.taxSharingState" filterable clearable
                           @keyup.enter.native="handleSearch">
                  <el-option value="1" label="未分税"></el-option>
                  <el-option value="2" label="部分分税"></el-option>
                  <el-option value="3" label="已分税"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="到港状态" :label-width="formLabelWidth">
                <el-select v-model="queryForm.arrivalState" filterable clearable
                           @keyup.enter.native="handleSearch">
                  <el-option value="0" label="未到港"></el-option>
                  <el-option value="1" label="已到港"></el-option>

                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="清关放行状态" :label-width="formLabelWidth">
                <el-select v-model="queryForm.customsReleaseState" filterable clearable
                           @keyup.enter.native="handleSearch">
                  <el-option value="1" label="已清关放行"></el-option>
                  <el-option value="2" label="清关查验"></el-option>
                  <el-option value="3" label="已查验放行"></el-option>

                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input v-model="queryForm.remark"
                          @keyup.enter.native="handleSearch">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="国家" :label-width="formLabelWidth">
                <f-select v-model="queryForm.country" :is-need="queryForm.country" :dict="'bd_country'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="ETD" :label-width="formLabelWidth">
                <el-date-picker

                    v-model="queryForm.boardTimes"
                    type="datetimerange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    unlink-panels
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="ETA" :label-width="formLabelWidth">
                <el-date-picker

                    v-model="queryForm.arriveTimes"
                    type="datetimerange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    unlink-panels
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>


        </el-card>
      </el-form>


      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button @click="pilianggengxin(scope.row,'1')"
                       type="text"
                       v-if="buttonShowList.yb">
              发预报
            </el-button>
            <el-button @click="pilianggengxin(scope.row,'2')"
                       type="text"
                       v-if="buttonShowList.fh && scope.row.cabinetCategory !== '1'">
              更新放货
            </el-button>
            <el-button @click="pilianggengxin(scope.row,'3')"
                       type="text"
                       v-if="buttonShowList.hg">
              还柜
            </el-button>
            <el-button type="text" @click="handMentionArkForm(scope.row)"
                       v-if="scope.row.circulationState === '4' && buttonShowList.tg"
            >提柜
            </el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'containerNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="tiaojinHandout(scope.row)"
            >{{ scope.row.containerNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'releaseStatusStr'">
            <span :style="scope.row.releaseStatusStr && scope.row.releaseStatusStr.indexOf('全部') !==-1 ?' color: red' : ''">{{scope.row.releaseStatusStr}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
        title="提柜"
        :visible.sync="dialogAddFormMentionArkVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="addForm" ref="addForm" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item
                label="提柜时间"
                :label-width="formLabelWidth"
                prop="holdCabinetTime"
            >
              <el-date-picker
                  class="f-flex-1"
                  v-model="addForm.holdCabinetTime"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!--                    <el-col :span="9">-->
          <!--                        <el-form-item label="清关查验预估时间(周)" :label-width="formLabelWidth" prop="checkWeek" v-if="this.isCheekWeek" >-->
          <!--                            <el-input v-model="addForm.checkWeek" type="number" :disabled="true"></el-input>-->
          <!--                        </el-form-item>-->
          <!--                    </el-col>-->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogAddFormMentionArkVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="handMentionArk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {postAction,getAction,putAction} from "@/api/manage";

export default {
  name: "DispatchVerification",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      isUsePost: true,
      queryForm: {
        boardTimes: [],
        arriveTimes: []
      },
      columns: [
        // {
        //     type: "selection",
        // },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "week",
          label: "周数",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "bookNo",
          label: "SO号",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "podEndName",
          label: "目的港",
        },
        {
          prop: "etd",
          label: "ETD",
        },
        {
          prop: "eta",
          label: "ETA",
        },
        {
          prop: "endOperator",
          label: "派送操作",
        },
        {
          prop: "isMakeForecastStr",
          label: "发预报",
        },
        {
          prop: "taxSharingStateStr",
          label: "分税状态",
        },
        {
          prop: "arrivalStateStr",
          label: "到港状态",
        },
        {
          prop: "customsReleaseStateStr",
          label: "清关放行状态",
        },
        {
          prop: "releaseStatus",
          label: "放货状态",
        },
        {
          prop: "releaseStatusStr",
          label: "订单放货状态",
        },
        {
          prop: "pickUpConditionStr",
          label: "提柜状态",
        },
        {
          prop: "holdCabinetTime",
          label: "提柜时间",
        },
        {
          prop: "deliveryStatusStr",
          label: "派送状态",
        },
        {
          prop: "returnConditionStr",
          label: "还柜状态",
        },

        {
          prop: "returnConditionTime",
          label: "还柜时间",
        },

        {
          prop: "uploadPod",
          label: "上传POD",
        },

      ],
      url: {
        //查询列表的接口
        list: "/operation/container/queryDispatchVerificationIPage",
        updateOsContainerIsMakeForecast: "/operation/container/updateOsContainerIsMakeForecast",
        updateOsContainerReleaseStatus: "/operation/container/updateOsContainerReleaseStatus",
        updateOsContainerReturnCondition: "/operation/container/updateOsContainerReturnCondition",
        checkWeek: "/operation/container/checkWeek",
        updateCabinetAudit: "/operation/container/mentionArk",
      },
      dialogAddFormMentionArkVisible: false,
      addForm: {},
      selectRow: {},
      rules: {
        holdCabinetTime: [
          {required: true, message: "请选择提柜时间", trigger: "change"},
        ],
      },
      formLabelWidth: "100px",
    };
  },
  methods: {
    //跳到待派送
    tiaojinHandout({containerNo}){
      this.$router.push('/operation/cabinet/WaitForHandOut?containerNo='+containerNo);
    },
    handMentionArk() {
      this.selectRow.holdCabinetTime = this.addForm.holdCabinetTime;
      let form = JSON.parse(JSON.stringify(this.selectRow));
      // form.actionType = 'mentionArk'
      form.circulationState = "7";
      form.sendEmailType = "9";
      console.log(form);
      putAction(this.url.updateCabinetAudit, form).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.dialogAddFormMentionArkVisible = false;
          this.handleSearch();
        }
      });
    },
    handMentionArkForm(row) {
      this.selectRow = JSON.parse(JSON.stringify(row));
      //获取 该柜子曾经是否查验过 查验的周数
      getAction(this.url.checkWeek+'?id='+row.id).then((res) => {
        if (res.success && res.result) {
          this.isCheekWeek = true;
          this.$set(this.addForm, 'checkWeek', res.result.checkWeek)
        }else{
          this.isCheekWeek = false;
        }
      });
      this.dialogAddFormMentionArkVisible = true;
    },
    pilianggengxin(row, type) {
      let message = '';
      let url = '';
      let params = [];
      params.push(row.containerId);
      if (type === '1') {
        message = '更新已发预报';
        url = this.url.updateOsContainerIsMakeForecast;
      } else if (type === '2') {
        message = '更新放货状态';
        url = this.url.updateOsContainerReleaseStatus;
      } else if (type === '3') {
        message = '更新还柜状态';
        url = this.url.updateOsContainerReturnCondition;
      }
      this.$confirm(`确定${message}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      }).then(() => {
        postAction(url, params)
            .then(res => {
              this.$message.success(res.message);
              this.handleQuery();
            })
      })
    }
  },
  computed:{
    buttonShowList() {
      return {
        'yb':this.$btnIsShow('operation/DispatchVerification','0','发预报'),
        'fh':this.$btnIsShow('operation/DispatchVerification','0','更新放货'),
        'hg':this.$btnIsShow('operation/DispatchVerification','0','还柜'),
        'tg':this.$btnIsShow('operation/DispatchVerification','1','提柜'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
