<!--带入仓-->
<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div style="display:flex;">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="销售名">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.salesman"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="客户">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.customer"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="handleReset"
                  icon="reload"
                  style="margin-left: 8px"
              >重置</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="exportExcelPlus(
                      url.downloadExitSalesAirlift,
                      '离职销售订单-空运运'
                  )"
                  icon="reload"
                  style="margin-left: 8px"
              >导出</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card last_card">

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="65vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
          </template>
          <template v-else-if="scope.row.recBill && scope.column.property === 'recBill'">
            <div style="display: flex;flex-direction: column;justify-content:  center" v-if="scope.row.recBill && scope.row.recBill.length > 0" >
              <span v-for="item in scope.row.recBill.split(',')">{{item}}</span>
            </div>
          </template>
          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">
            <div style="display: flex;flex-direction: column;justify-content: center" v-if="scope.row.costBill && scope.row.costBill.length > 0">
              <span v-for="item in scope.row.costBill.split(',')">{{item}}</span>
            </div>
          </template>
          <!--          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">-->
          <!--            <div style="display: flex;flex-direction: column;justify-content:  center">-->
          <!--              <span v-for="item in scope.row.costBill.split(',')">{{item}}</span>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--          <template v-else-if="scope.row.costBill && scope.column.property === 'costBill'">-->
          <!--            <div style="display: flex;flex-direction: column;justify-content: center">-->
          <!--              <span v-for="item in scope.row.costBill.split(',')">{{item}}}</span>-->
          <!--            </div>-->
          <!--          </template>-->
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

  </div>

</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import {getAction, postAction} from '@/api/manage'
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "Potential",
  components: {},
  mixins: [QueryMixins],
  data() {
    return {
      dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'pay_type','pglx'],
      ids: [],
      row: {},
      form: {},
      dialogFormVisibleBatchAudit: false,
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "username",
          label: "客户",
        },
        {
          prop: "salesman",
          label: "业务",
        },
        {
          prop: "createTime",
          label: "下单时间",
        },
        {
          prop: "receiveCountry",
          label: "国家",
        },
        {
          prop: "actualCmbWeight",
          label: "收款数量",
        },
        {
          prop: "commission",
          label: "提成性质",
        },
      ],
      url: {
        list: "finance/fee/settleCommission/getAirlifeListPage",
        downloadExitSalesAirlift: "file/export/excel/SalesExcel/downloadAirlifeExportXls",
      },
      isUsePost:true,

    };
  },
  methods: {
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$intoDocPage(orderNo);
      // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.isWhether='0'
      this.queryForm.profitRecheck='0'
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.isWhether='0'
    this.queryForm.profitRecheck='0'
  },
};
</script>

<style lang="scss" scoped>
</style>
