<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="周数">
                          <el-input  v-model="queryForm.week" type="textarea"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                          <f-select  v-model="queryForm.operateId" :is-need="queryForm.operateId" :dict="'sys_user_id'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="装柜仓库">
                          <f-select  v-model="queryForm.zgPlace" :is-need="queryForm.zgPlace" :dict="'lcl_place'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港">
                          <f-select  v-model="queryForm.pol" :is-need="queryForm.pol" :dict="'bd_port'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港">
                          <f-select  v-model="queryForm.podEnd" :is-need="queryForm.podEnd" :dict="'bd_port'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="起运时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.boardTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.boardTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="船公司">
                          <f-select
                              :isNeed="queryForm.shipCompanyId"
                              v-model="queryForm.shipCompanyId"
                              :dict="'ship_company'"></f-select>
                        </el-form-item>
                      </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                                <el-button plain @click="exportExcelPlus(url.daochu,'预排柜数据')"
                                v-if="buttonShowList.xzypgsj">导出</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>


            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'week'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
                    >{{ scope.row.week }}</a
                    >
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "TakeOrderWork",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    // {
                    //     type: "selection",
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "week",
                        label: "周数",
                    },
                  {
                    prop: "weekStr",
                    label: "周期",
                  },
                    {
                        prop: "cabinetCategory",
                        label: "专线or拆柜",
                    },
                  {
                    prop: "channelName",
                    label: "渠道名称",
                  },
                  {
                    prop: "stopWarehouseTime",
                    label: "截仓时间",
                  },
                  {
                    prop: "ctnStr",
                    label: "预计件重体",
                  },
                  {
                    prop: "remainVolume",
                    label: "剩余方数",
                  },
                  {
                    prop: "zgPlace",
                    label: "装柜仓库",
                  },
                  {
                    prop: "pol",
                    label: "起运港",
                  },
                  {
                    prop: "podEnd",
                    label: "目的港",
                  },
                  {
                    prop: "shipCompany",
                    label: "船司",
                  },
                  {
                    prop: "shipNo",
                    label: "船名航次",
                  },
                  {
                    prop: "etd",
                    label: "ETD",
                  },
                  {
                    prop: "eta",
                    label: "ETA",
                  },
                  {
                    prop: "operator",
                    label: "排柜操作",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/operation/container/getSalesWaitingStuffingContainerIPage",
                    daochu: "/file/export/excel/downloadSalesWaitingCabinetExcel",

                },


            };
        },
        methods: {

        },
      computed:{
        buttonShowList() {
          return {
            'xzypgsj':this.$btnIsShow('sale/SalesWaitingCabinet','0','下载预排柜数据'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
