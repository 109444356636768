<template>
  <div class="quarterly">
    <el-row :span="24" :gutter="20" style="height: 95%">
      <!-- 总数据 -->
      <el-col class="quarterly-col" :span="8" style="height: 100%">
        <div>
          <div class="title">{{ "决战Q" + quarter + "季度，全力冲刺" }}</div>
          <div class="data">{{ dateText }}</div>
          <!-- 总柜数 -->
          <div class="total">
            <div class="circular-big">
              <div class="circular-small">总柜数</div>
            </div>
            <div class="num">{{ orderNum.containerCmb || 0 }}</div>
            <div class="teu">TEU</div>
          </div>
        </div>
        <div class="title-low ellipsis_one margin_bot_20">
          各区域销售目标完成情况
        </div>
        <div style="height: 40%">
          <div class="card-region">
            <div
              v-if="showMap == 1"
              class="regionSales"
              ref="regionSales"
            ></div>
            <div
              v-else-if="showMap == 2"
              class="regionSales"
              ref="regionBaidu"
            ></div>
          </div>
        </div>
        <div class="title-low margin_top_20 ellipsis_one">重要数据</div>
        <div style="height: 18%">
          <div class="important-data">
            <div
              v-if="orderNum.monthRate && orderNum.monthRate.indexOf('%') != -1"
              class="circleBox"
            >
              <el-progress
                type="circle"
                :percentage="orderNum.monthRateNum"
                :width="110"
                :stroke-width="8"
                color="#6b63ff"
                :show-text="false"
              ></el-progress>
              <div class="circleCenter">
                <div>
                  <div class="percentage">
                    {{ orderNum.monthRate || "0%" }}
                  </div>
                  <div>{{ month + "月目标完成率" }}</div>
                </div>
              </div>
            </div>
            <div v-else class="noCircle">{{ orderNum.monthRate }}</div>
            <div
              v-if="orderNum.qxRate && orderNum.qxRate.indexOf('%') != -1"
              class="circleBox"
            >
              <el-progress
                type="circle"
                :percentage="orderNum.qxRateNum"
                :width="110"
                :stroke-width="8"
                color="#6b63ff"
                :show-text="false"
              ></el-progress>
              <div class="circleCenter">
                <div>
                  <div class="percentage">
                    {{ orderNum.qxRate || "0%" }}
                  </div>
                  <div>{{ "Q" + quarter + "目标完成率" }}</div>
                </div>
              </div>
            </div>
            <div v-else class="noCircle">{{ orderNum.qxRate }}</div>
            <div
              v-if="orderNum.yearRate && orderNum.yearRate.indexOf('%') != -1"
              class="circleBox"
            >
              <el-progress
                type="circle"
                :percentage="orderNum.yearRateNum"
                :width="110"
                :stroke-width="8"
                color="#6b63ff"
                :show-text="false"
              ></el-progress>
              <div class="circleCenter">
                <div>
                  <div class="percentage">
                    {{ orderNum.yearRate || "0%" }}
                  </div>
                  <div>年度目标完成率</div>
                </div>
              </div>
            </div>
            <div v-else class="noCircle">{{ orderNum.yearRate }}</div>
          </div>
        </div>
      </el-col>
      <!-- 销售情况 -->
      <el-col class="sales" :span="10" style="height: 100%">
        <div style="height: 50%">
          <div class="title-box">
            <div class="title-low ellipsis_one">海运销售情况</div>
            <el-date-picker
              class="quarterlyYearClick"
              v-model="year[0]"
              type="year"
              placeholder="选择年"
              :clearable="false"
              size="mini"
              :picker-options="pickerOptions"
              @change="getLCLData"
            >
            </el-date-picker>
          </div>
          <div class="marineSales" ref="marineSales"></div>
        </div>
        <div style="height: 50%">
          <div class="title-box">
            <div class="title-low ellipsis_one">空运快递销售情况</div>
            <el-date-picker
              class="quarterlyYearClick"
              v-model="year[1]"
              type="year"
              placeholder="选择年"
              :clearable="false"
              size="mini"
              @change="getAirData"
            >
            </el-date-picker>
            <div class="typeBox">
              <div class="typeTitle ellipsis_one">类型：</div>
              <el-select
                v-model="orderType"
                size="mini"
                placeholder="请选择"
                @change="getAirData"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="airSales" ref="airSales"></div>
        </div>
      </el-col>
      <!-- 销售排行榜 -->
      <el-col :span="6" style="height: 100%">
        <div style="height: 47%">
          <div class="title-box">
            <div class="title-low ellipsis_one">海运销售排行榜</div>
            <el-date-picker
              class="quarterlyYearClick"
              v-model="year[2]"
              type="year"
              placeholder="选择年"
              :clearable="false"
              size="mini"
              @change="getMarineRank"
            >
            </el-date-picker>
          </div>
          <el-table
            border
            :data="marineRank"
            style="width: 100%"
            :cell-class-name="cellClassFn"
            :header-cell-class-name="headerClassFn"
            size="mini"
            :cell-style="{ padding: '3px' }"
            class="margin_top_20"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="index"
              label="排名"
              min-width="30px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="salesman"
              label="业务员"
              min-width="50px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="departName"
              label="部门"
              min-width="50px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="volume"
              label="货量（CBM）"
              min-width="50px"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="title-box title-box-top">
            <div class="title-low ellipsis_one">空运快递销售排行榜</div>
            <el-date-picker
              class="quarterlyYearClick"
              v-model="year[3]"
              type="year"
              placeholder="选择年"
              :clearable="false"
              size="mini"
              @change="getAirRank"
            >
            </el-date-picker>
          </div>
          <el-table
            :data="airRank"
            style="width: 100%"
            border
            :cell-class-name="cellClassFn"
            :header-cell-class-name="headerClassFn"
            size="mini"
            :cell-style="{ padding: '3px' }"
            class="margin_top_20 margin_bot_20"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="index"
              label="排名"
              min-width="30px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="salesman"
              label="业务员"
              min-width="50px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="departName"
              label="部门"
              min-width="50px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="volume"
              label="货量（KG）"
              min-width="50px"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 底部数据 -->
    <div class="bottom-box">
      <div class="order margin_right_40">
        <div>未收订单金额：</div>
        <div class="bottom-num">{{ "￥" + (orderNum.noBill || 0) }}</div>
      </div>
      <div class="order margin_left_40">
        <div>已收订单金额：</div>
        <div class="bottom-num">
          {{ "￥" + (orderNum.realyBill || 0) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { map, positionMap } from "@/api/map.js";
// // import "echarts/map/js/china.js";
import "echarts/extension/bmap/bmap";
import * as echarts from "echarts";
import mapStyleJson from "@/api/mapStyleJson.json";
import { getAction, postAction } from "@/api/manage";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      showMap: 1, //显示 1 圆环 echarts版本5.2.2，2 echarts版本4.8.0+百度地图
      marineRank: [],
      airRank: [],
      regionData: [],
      LCLData: [],
      FCLData: [],
      airData: [],
      dateText: "", //当前日期文本显示
      marineMonthList: [], //海运销售的月份列表
      airMonthList: [], //空运销售的月份列表
      year: [new Date(), new Date(), new Date(), new Date()],
      month: "", //当月
      quarter: "", //当前季度
      options: [
        {
          value: "1",
          label: "空运",
        },
        {
          value: "2",
          label: "快递",
        },
        {
          value: "3",
          label: "空运+快递",
        },
      ],
      url: {
        salesLists: "/interface/saleDataManage/yearCount", //销售情况
        rankLists: "/interface/saleDataManage/rankingDataCount", //销售排行榜
        orderNum: "/interface/saleDataManage/getOrderPriceStatisticList", //未收已收订单金额
        regionSales: "/interface/saleDataManage/getSaleTargetSituation", //各区域销售目标完成情况
      },
      orderNum: {},
      orderType: "3", //类型，0：海运，1空运,2快递，3快递+空运，默认3
    };
  },
  mounted() {
    this.getData();
    this.getRegionData();
    this.getLCLData();
    this.getAirData();
    this.getOrderNum();
    this.getMarineRank();
    this.getAirRank();
  },
  methods: {
    // 获取各区域销售目标完成情况
    getRegionData() {
      let params = {};
      getAction(this.url.regionSales, params)
        .then((res) => {
          if (res.success) {
            this.regionData = [];
            res.result.forEach((item) => {
              let regionDataItem = {
                name:
                  item.departName +
                  "\n" +
                  (item.rate || "0%") +
                  "\n" +
                  (item.volume || 0) +
                  " CBM",
                value: item.volume || 0,
              };
              this.regionData.push(regionDataItem);
            });

            if (this.showMap == 1) {
              this.initRegion();
            } else {
              this.initMapBaidu();
            }
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // 获取拼柜数据-销售情况
    async getLCLData() {
      let params = {
        orderType: "0", //0：海运，1空运,2快递，3快递+空运
        containerType: "0", //0：拼柜 1：整柜
        year: this.year[0].getFullYear(),
      };
      await postAction(this.url.salesLists, params)
        .then((res) => {
          if (res.success) {
            this.marineMonthList = [];
            this.LCLData = [];
            res.result.forEach((item) => {
              this.marineMonthList.push(item.month + "月");
              this.LCLData.push(parseFloat(item.volume) || 0);
            });
            this.getFCLData();
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // 获取整柜数据-销售情况
    async getFCLData() {
      let params = {
        orderType: "0", //0：海运，1空运,2快递，3快递+空运
        containerType: "1", //0：拼柜 1：整柜
        year: this.year[0].getFullYear(),
      };
      await postAction(this.url.salesLists, params)
        .then((res) => {
          if (res.success) {
            if (this.marineMonthList.length !== res.result.length) {
              res.result.forEach((item, index) => {
                if (item.month + "月" !== this.marineMonthList[index]) {
                  this.marineMonthList.splice(index, 0, item.month + "月");
                  this.LCLData.splice(index, 0, 0);
                }
              });
            }
            this.FCLData = [];
            res.result.forEach((item) => {
              this.FCLData.push(parseFloat(item.volume) || 0);
            });
            this.initMarine();
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // 获取空运快递数据-销售情况
    async getAirData() {
      let params = {
        orderType: this.orderType, //0：海运，1空运,2快递，3快递+空运
        year: this.year[1].getFullYear(),
      };
      await postAction(this.url.salesLists, params)
        .then((res) => {
          if (res.success) {
            this.airMonthList = [];
            this.airData = [];
            res.result.forEach((item) => {
              this.airMonthList.push(item.month + "月");
              this.airData.push(parseFloat(item.volume) || 0);
            });
            this.initAir();
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // 获取未收订单已收订单数据
    getOrderNum() {
      getAction(this.url.orderNum)
        .then((res) => {
          if (res.success) {
            this.orderNum = res.result;
            if (
              this.orderNum.monthRate &&
              this.orderNum.monthRate.indexOf("%") != -1
            ) {
              this.orderNum.monthRateNum = parseFloat(
                this.orderNum.monthRate.replace(/%/, "")
              );
            }
            if (
              this.orderNum.qxRate &&
              this.orderNum.qxRate.indexOf("%") != -1
            ) {
              this.orderNum.qxRateNum = parseFloat(
                this.orderNum.qxRate.replace(/%/, "")
              );
            }
            if (
              this.orderNum.yearRate &&
              this.orderNum.yearRate.indexOf("%") != -1
            ) {
              this.orderNum.yearRateNum = parseFloat(
                this.orderNum.yearRate.replace(/%/, "")
              );
            }
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // 获取海运销售排行榜数据
    getMarineRank() {
      let params = {
        orderType: "0", //0：海运，1空运,2快递，3快递+空运
        year: this.year[2].getFullYear(),
      };
      postAction(this.url.rankLists, params)
        .then((res) => {
          if (res.success) {
            this.marineRank = [];
            res.result.forEach((item, index) => {
              let itemCopy = item;
              itemCopy.index = index + 1;
              this.marineRank.push(itemCopy);
            });
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // 获取空运快递销售排行榜数据
    getAirRank() {
      let params = {
        orderType: "3", //0：海运，1空运,2快递，3快递+空运
        year: this.year[3].getFullYear(),
      };
      postAction(this.url.rankLists, params)
        .then((res) => {
          if (res.success) {
            this.airRank = [];
            res.result.forEach((item, index) => {
              let itemCopy = item;
              itemCopy.index = index + 1;
              this.airRank.push(itemCopy);
            });
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    getData() {
      let date = new Date();
      let month = date.getMonth() + 1;
      console.log(date.getFullYear(), "date.getFullYear()");
      this.month = month.toString();
      this.quarter = Math.floor(month % 3 == 0 ? month / 3 : month / 3 + 1);
      this.dateText =
        date.getFullYear() + "年" + month + "月" + date.getDate() + "日";
    },
    pickerOptions(time) {
      let date = new Date();
      console.log(time, date.getFullYear(), "time");
      disabledDate: (time) => {
        return time.getTime() > date.getFullYear();
      };
    },
    //   各区域销售目前完成情况-地图展示形式
    async initMapBaidu() {
      await map("q1zG9995NInhXeasNNtFrGIKk6q3jTWn").then(() => {});
      var myChart = echarts.init(this.$refs.regionBaidu, "dark");
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = positionMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: i + 1 + "-" + data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };
      let option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        bmap: {
          center: [104.114129, 37.550339],
          zoom: 5,
          roam: true,
          mapStyle: {
            styleJson: mapStyleJson,
          },
        },
        series: [
          {
            name: "CMB",
            type: "scatter",
            coordinateSystem: "bmap",
            data: convertData(this.regionData),
            encode: {
              value: 2,
            },
            symbolSize: function (val) {
              return val[2] / 10;
            },
            label: {
              formatter: "{b}",
              position: "right",
            },
            itemStyle: {
              color: "#ddb926",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
          {
            name: "Top",
            type: "effectScatter",
            coordinateSystem: "bmap",
            data: convertData(
              this.regionData
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .slice(0, 6)
            ),
            encode: {
              value: 2,
            },
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: "emphasis",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: true,
            },
            itemStyle: {
              color: "#f4e925",
              shadowBlur: 10,
              shadowColor: "#333",
            },
            zlevel: 1,
          },
          {
            type: "custom",
            coordinateSystem: "bmap",
            itemStyle: {
              opacity: 0.5,
            },
            animation: false,
            silent: true,
            data: [0],
            z: -10,
          },
        ],
      };
      myChart.setOption(option);
    },
    //各区域销售目前完成情况-圆环
    initRegion() {
      const that = this;
      let myChart = echarts.init(this.$refs.regionSales, "dark");
      let option = {
        backgroundColor: "rgb(14, 20, 56)",
        tooltip: {
          trigger: "item",
          formatter(params) {
            var total = 0; // 用于计算总数
            var target; // 遍历拿到数据
            for (var i = 0; i < that.regionData.length; i++) {
              total += that.regionData[i].value;
              if (that.regionData[i].name == params.name) {
                target = that.regionData[i].value;
              }
            }
            var v = ((target / total) * 100).toFixed(2);
            
            return params.name.replace('\n','<br />').replace('\n','<br />');
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "10%",
          containLabel: true,
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            data: this.regionData,
            label: {
              fontSize: 12,
              // color: "#56B1FF",
              padding: [0, 0, 0, 5], //调整左右位置
            },
            labelLine: {
              show: true,
              length: 25, //调整上下位置
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 海运销售折线图
    initMarine() {
      let myChart = echarts.init(this.$refs.marineSales, "dark");
      let option = {
        backgroundColor: "rgb(14, 20, 56)",
        title: {
          // text: "海运销售情况",
          subtext: "单位：CBM",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["LCL拼柜", "FCL整柜"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.marineMonthList,
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "LCL拼柜",
            type: "line",
            smooth: true,
            // stack: "Total",
            data: this.LCLData,
          },
          {
            name: "FCL整柜",
            type: "line",
            smooth: true,
            // stack: "Total",
            data: this.FCLData,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 空运快递销售柱状图
    initAir() {
      let myChart = echarts.init(this.$refs.airSales, "dark");
      let dataAxis = this.airMonthList;
      let data = this.airData;
      let option = {
        backgroundColor: "rgb(14, 20, 56)",
        title: {
          // text: "空运快递销售情况",
          subtext: "单位：KG",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        xAxis: {
          data: dataAxis,
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: false,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#2378f7" },
                  { offset: 0.7, color: "#2378f7" },
                  { offset: 1, color: "#83bff6" },
                ]),
              },
            },
            data: data,
          },
        ],
      };
      myChart.setOption(option);
    },
    cellClassFn({ columnIndex }) {
      if (columnIndex === 0) {
        return "firstBackColor";
      } else {
        return "backColor";
      }
    },
    headerClassFn() {
      return "hearderBackColor";
    },
  },
};
</script>
<style lang="scss">
.quarterly {
  .el-table--border th:first-child .cell,
  .el-table--border td:first-child .cell {
    padding-left: 0px;
  }
  //改变背景色 style不能加scope
  .el-progress-circle__track {
    stroke: #2a3777;
  }

  .el-table {
    .backColor {
      background-color: rgb(14, 20, 56);
      color: #e7e8ec;
    }
    .hearderBackColor {
      background-color: #5527bb !important;
      color: #e7e8ec;
    }
    .firstBackColor {
      background-color: #1e2c62;
      color: #e7e8ec;
    }
  }
  .el-table--border:after,
  .el-table--group:after,
  .el-table:before {
    background-color: #151f4a !important;
  }
  // 左边和上边
  .el-table--border,
  .el-table--group {
    border-color: #151f4a !important;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #151f4a !important;
  }
  // 头部的下边框
  .el-table--border th,
  .el-table--border th.gutter:last-of-type {
    border-bottom: 1px solid #151f4a !important;
  }
  // 列表中间的竖线
  .el-table--border td,
  .el-table--border th {
    border-right: 1px solid #151f4a !important;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #10173c !important;
  }
  .el-table__empty-block {
    background-color: rgb(14, 20, 56);
    color: #e7e8ec;
    width: 100% !important;
  }
  .el-input--mini .el-input__inner {
    background-color: #1e2c62;
    color: #e7e8ec;
    border: 1px solid #151f4a !important;
  }
  .el-popper {
    background-color: #1e2c62;
    color: #e7e8ec;
    border: 1px solid #151f4a !important;
  }
  .el-picker-panel__icon-btn {
    color: white !important;
  }
  .el-date-picker__header-label {
    color: white !important;
  }
  .el-date-picker__header--bordered {
    border: 1px solid #151f4a !important;
  }
  .el-year-table td .cell {
    color: white;
  }
  .el-select-dropdown__item {
    color: white;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #10173c !important;
  }
}
</style>
<style lang="scss" scoped>
.quarterly {
  padding: 50px 50px 20px 50px;
  height: 100%;
  box-sizing: border-box;
  color: white;
  background-color: rgb(14, 20, 56);
  .quarterly-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 40px !important;
      font-weight: bold;
      color: #feb64d;
    }
    .data {
      width: 250px;
      height: 40px;
      background-color: #1a2764;
      padding: 0 15px;
      font-size: 16px !important;
      line-height: 40px;
      margin-top: 15px;
    }
    .total {
      margin: 20px 0;
      display: flex;
      align-items: center;
      .circular-big {
        width: 95px;
        height: 95px;
        border: 5px solid #2f48ac;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        .circular-small {
          width: 70px;
          height: 70px;
          border: 5px solid #6dd6f3;
          border-radius: 50%;
          font: 15px !important;
          text-align: center;
          line-height: 60px;
        }
      }
      .num {
        font-size: 30px !important;
      }
      .teu {
        height: 30px;
        display: flex;
        align-items: flex-end;
      }
    }
    .card-region {
      display: flex;
      height: 100%;
      .regionSales {
        width: 90%;
      }
      .mapdiv {
        width: 100%;
      }
    }
    .important-data {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0 20px;
      height: calc(100% - 20px);
    }
    .circleBox {
      position: relative;
      height: 110px;
    }
    .circleCenter {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .percentage {
        font-size: 28px !important;
        text-align: center;
      }
    }
  }
  .sales {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .marineSales {
      height: 87%;
    }
    .airSales {
      height: 88%;
    }
  }
  .title-low {
    font-size: 18px !important;
    font-weight: bold;
  }
  .bottom-box {
    display: flex;
    height: 5%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    .order {
      display: flex;
      div {
        font-size: 17px !important;
      }
      .bottom-num {
        color: #ffff50;
      }
    }
  }
}
.title-box {
  display: flex;
  align-items: center;
  .quarterlyYearClick {
    margin-left: 20px;
  }
}
.typeBox {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .typeTitle {
    font-size: 15px !important;
    min-width: 50px;
  }
}
.noCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px !important;
  text-align: center;
}
.title-box-top {
  margin-top: 50px;
}
.margin_top_20 {
  margin-top: 20px;
}
.margin_bot_20 {
  margin-bottom: 20px;
}
.margin_right_40 {
  margin-right: 40px;
}
.margin_left_40 {
  margin-left: 40px;
}
</style>
