<template>
  <el-dialog v-if="type !== 'view'" :title="row.id ? '编辑新闻资讯' : '新增新闻资讯'" :visible="visible" custom-class="c-custom-dialog width_1200_dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="50px">
      <el-tabs class="forest_tab" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="中文配置" name="zh" v-loading="loading">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="标题" prop="title">
                <el-input v-model.trim="form.title" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="24">
              <el-form-item label="作者" prop="articleAuthor" class="width_50">
                <el-input v-model.trim="form.articleAuthor" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="24">
              <el-form-item label="来源" prop="articleSource"  class="width_50">
                <el-input v-model.trim="form.articleSource" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
                <el-col :span="24">
              <el-form-item label="关键字" prop="keyword" class="width_50">
                <el-input v-model.trim="form.keyword" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24" >
              <el-form-item label="发布时间" prop="title" class="width_50" label-width="80px">
               <el-date-picker
                        class="f-flex-1"
                        v-model="form.si"
                        type="datetime"
                        size="small"
                        placeholder="请选择时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%;"
                    >
                    </el-date-picker>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="封面" prop="coverFile">
                <f-upload v-model="form.coverFile" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 551*390</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="loading == false" label="内容" prop="text">
                <vue-ueditor-wrap class="ueditor_forest_box" v-model="form.text"
                  :config="editorConfig"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="英文配置" name="en" v-loading="loading">
          <el-row class="inputs_row">
            <el-col :span="12">
              <el-form-item label="标题" prop="titleEn">
                <el-input v-model.trim="form.titleEn" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
               <el-col :span="24">
              <el-form-item label="作者" prop="articleAuthorEn" class="width_50">
                <el-input v-model.trim="form.articleAuthorEn" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="24">
              <el-form-item label="来源" prop="articleSourceEn"  class="width_50">
                <el-input v-model.trim="form.articleSourceEn" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
                <el-col :span="24">
              <el-form-item label="关键字" prop="keyword" class="width_50">
                <el-input v-model.trim="form.keyword" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24" >
              <el-form-item label="发布时间" prop="title" class="width_50" label-width="80px">
               <el-date-picker
                        class="f-flex-1"
                        v-model="form.si"
                        type="datetime"
                        size="small"
                        placeholder="请选择时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%;"
                    >
                    </el-date-picker>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="封面" prop="coverFileEn">
                <f-upload v-model="form.coverFileEn" :isUseOss="true" list-type="picture-card" :limit="1"
                  accept="image/*"></f-upload>
                <div>建议尺寸： 551*390</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-if="loading == false" label="内容" prop="textEn">
                <vue-ueditor-wrap class="ueditor_forest_box" v-model="form.textEn"
                  :config="editorConfig"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-card header="公共部分" class="f-m-t-20">
          <el-row class="inputs_row">
            <el-col :span="8">
              <el-form-item label="类别" prop="articleTypeId">
                <el-select clearable filterable v-model="form.articleTypeId">
                  <el-option v-for="(item, index) in allArticleTypeList" :label="item.articleType" :value="item.id"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
  <el-dialog v-else-if="type == 'view'" title="预览" :visible="visible" custom-class="c-custom-dialog width_1200_dialog"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-row>
      <el-tabs class="forest_tab" v-model="activeName">
        <el-tab-pane label="中文简介" name="zh">
          <el-col :span="24">
            <div v-html="form.text"></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="英文简介" name="en">
          <el-col :span="24">
            <div v-html="form.textEn"></div>
          </el-col>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction, getAction } from '@/api/manage'
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  name: 'NewsInformationDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      loading: true,
      activeName: 'zh',
      form: {},
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        titleEn: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        coverFile: [
          { required: true, message: '请选择封面', trigger: 'change' },
        ],
        coverFileEn: [
          { required: true, message: '请选择封面', trigger: 'change' },
        ],
        text: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        textEn: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        articleTypeId: [
          { required: true, message: '请选择文章类别', trigger: 'change' },
        ],
      },
      checkKey: {
        zh: ['title', 'coverFile', 'text'],
        en: ['titleEn', 'coverFileEn', 'textEn']
      },
      url: {
        add: '/sys/managerFind/add',
        edit: '/sys/managerFind/edit',
        detail: '/sys/managerFind/queryCusById',
        allList: '/sys/managerType/allList',
      },
      editorConfig: {
        UEDITOR_HOME_URL: "/static/UEditor/",
        serverUrl: "/static/UEditor/jsp/config.json"
      },
      allArticleTypeList: [],
      allArticleTypeListMap: {},
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.selectPushPort();
        this.handleClick()
        if(this.row.id) {
          this.getDetail(this.row.id)
        } else {
          this.loading = false;
        }
      }
    }
  },
  methods: {
    selectPushPort() {
      const params = {
        pushPort: '1',
      };
      getAction(this.url.allList, params).then((response) => {
        //给allArticleTypeList数组赋值
        this.allArticleTypeList = response.result;
        this.allArticleTypeListMap = this.listToMap(this.allArticleTypeList, 'id')
      });
    },
    getDetail(id) {
      this.$store.commit("setFullscreenLoading", true);
      getAction(this.url.detail, { id: id }).then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.form = res.result;
        this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0] ? '' : this.form.coverFile = [];
        this.form.coverFileEn && this.form.coverFileEn.length && this.form.coverFileEn[0] ? '' : this.form.coverFileEn = [];
        this.loading = false;
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
        this.loading = false;
      })
    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if (this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.coverFile;
      delete params.coverFileEn;
      params.coverUrl = (this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0].url) || '';
      params.coverId = (this.form.coverFile && this.form.coverFile.length && this.form.coverFile[0].id) || '';
      params.coverUrlEn = (this.form.coverFileEn && this.form.coverFileEn.length && this.form.coverFileEn[0].url) || '';
      params.coverIdEn = (this.form.coverFileEn && this.form.coverFileEn.length && this.form.coverFileEn[0].id) || '';
      params.pushPort = '1';
      params.articleType = this.form.articleTypeId && this.allArticleTypeListMap[this.form.articleTypeId] && this.allArticleTypeListMap[this.form.articleTypeId].articleType;
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写对应的英文配置';
            this.$message.warning(message);
          }
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.loading = true;
      this.form = {};
      this.activeName = 'zh';
      this.$refs.form && this.$refs.form.resetFields()
    },
    handleClick() {
      if(this.activeName=='en'){
        this.rules.title[0].required=false
        this.rules.coverFile[0].required=false
        this.rules.text[0].required=false
         this.rules.titleEn[0].required=true
        this.rules.coverFileEn[0].required=true
        this.rules.textEn[0].required=true
      }else {
 this.rules.title[0].required=true
        this.rules.coverFile[0].required=true
        this.rules.text[0].required=true
         this.rules.titleEn[0].required=false
        this.rules.coverFileEn[0].required=false
        this.rules.textEn[0].required=false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.width_50 {
  width: 50%;
}

</style>
