<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="柜型" label-width="120px">
              <el-input v-model.trim="queryForm.name" @keyup.enter="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleEdit({})"
          v-if="buttonShowList.add">新增
        </el-button>
        <el-button :disabled="selectionRows.length === 0" type="primary" size="medium" icon="el-icon-plus"
          @click="handleBatDel()" v-if="buttonShowList.plsc">批量删除
        </el-button>
        <!-- <el-upload v-if="$btnIsShow('isystem/BaseData/EddManage', '0', '导入')" action="/" :show-file-list="false"
          :http-request="uploadSectionFile" accept=".xls,.xlsx" style="margin:0 10px;display:inline">
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <el-button v-if="$btnIsShow('isystem/BaseData/EddManage', '0', '模板下载')" type="primary" size="medium"
          @click="downloadModel(url.downloadEddExcel, 'edd导入模板')">模板下载
        </el-button> -->
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              v-if="buttonShowList.bj">编辑
            </el-button>
            <el-button type="text" @click="handleDel(scope.row.id)"
              v-if="buttonShowList.sc">删除
            </el-button>
            <el-button type="text" @click="handleEdit(scope.row, 'log')"
              v-if="buttonShowList.sc">日志
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'kdIsRemote'">
            <span v-if="scope.row.kdIsRemote === '0'">否</span>
            <span v-else style="color: red">是</span>
          </template>
          <template v-else-if="scope.column.property === 'showColor'">
            <span :style="getColorByName(scope.row.showColor)">{{ parseDict(scope.row.showColor, 'fba_addr_show_color')
            }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <edit-cabinet-type-dialog :visible.sync="addVisible" :type="type" :row="row" @ok="handleSearch"></edit-cabinet-type-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditCabinetTypeDialog from "../modules/EditCabinetTypeDialog";

export default {
  name: "DispatchPlace",
  mixins: [QueryMixins],
  components: {
    EditCabinetTypeDialog,
  },
  data() {
    return {
      loading: false,
      addVisible: false,
      authVisible: false,
      type: '', 
      isUsePost: true,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
        },

        {
          prop: "name",
          label: "柜型",
        },
      ],
      url: {
        list: "/sys/bd/cabinetType/queryBdCabinetTypeIPage",
        deleteBatch: '/sys/bd/cabinetType/delList',
      },
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importEdd, form)
        .then((res) => {
          //这里做上传之后的操作
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          } else {
            this.handleQuery();
          }
        })
    },
    handleDel(id) {
      this.$confirm("确定删除该起运地?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, [id]).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {
        });
    },
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => { });
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row, type) {
      this.type = type;
      this.row = row;
      this.addVisible = true;
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/CabinetType', '0', '新增'),
        'plsc':this.$btnIsShow('isystem/BaseData/CabinetType', '0', '批量删除'),
        'bj':this.$btnIsShow('isystem/BaseData/CabinetType', '1', '编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/CabinetType', '1', '删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
