<template>
  <div class="main-full-content">
    <el-form class="multiline_select" ref="queryForm" :model="queryForm" label-width="86px">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="6">
            <el-form-item label="海关编码">
              <el-input v-model="queryForm.hsCode" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="中文品名">
              <el-input v-model="queryForm.productName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="20px">
              <el-button size="medium" type="primary" icon="el-icon-search"  @click="handleSearch"
              >查询
              </el-button>
              <el-button size="medium" plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card>
      <!--<div class="f-p-b-15">-->
        <!--<el-button type="primary">导出-->
        <!--</el-button>-->
        <!--<el-button type="primary">新增-->
        <!--</el-button>-->
        <!--<el-button type="danger">批量删除-->
        <!--</el-button>-->
      <!--</div>-->
      <f-table ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor" :columns="columns"
        :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="61vh">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <!--<el-button type="text" @click="() => {visible = true}">新增-->
            <!--</el-button>-->
            <!--<el-button type="text">删除-->
            <!--</el-button>-->
            <el-button type="text"  @click="handleEdit(scope.row)" v-if="buttonShowList.edit">编辑
            </el-button>
            <el-button type="text"  @click="handleDelete(scope.row)" v-if="buttonShowList.del">删除
            </el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
            title="海关编码产品信息"
            :visible.sync="visible"
            :close-on-click-modal="false"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="海关编码" label-width="100px" prop="productName">
          <el-input v-model="form.hsCode" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="中文品名" label-width="100px" prop="productName">
          <el-input v-model="form.productName"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false"
        >取 消
        </el-button
        >
        <el-button type="primary" @click="updateProduct">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import QueryMixins from "@/mixins/QueryMixins";
  import AddCustomsAffairs from "./modules/AddCustomsAffairs";
  import {deleteAction,getAction, getallcountries, postAction} from "@/api/manage";

  export default {
  mixins: [QueryMixins],
  components: {
    AddCustomsAffairs,
  },
  props: {
  },
  data() {
    return {
      visible: false,
      isUsePost:true,
      row: {},
      form:{},
      queryForm: {},
      url:{
        list: "/sys/bdClearProduct/getClearProductPage",
        delete:'/sys/bdClearProduct/updateClearProductStatus',
        updateProductInfo:'/sys/bdClearProduct/updateClearProductInfo',

      },
      productRules:{
        chineseProductName: [
            {required: true, message: "请输入中文品名", trigger: "change"},
        ],
      },
      tableData: [],
      rules:{
        productName: [
          {required: true, message: "请输入中文品名", trigger: "change"},
        ],
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "hsCode",
          label: "海关编码",
        },

        {
          prop: "productName",
          label: "中文品名",
        },
      ],
    }
  },
  watch: {
    visible(val) {
     if(!val){
       this.handleQuery()
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    updateProduct(){
      postAction(this.url.updateProductInfo,this.form).then(res=>{
        this.visible = false
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      })
    },
    handleDelete(row){
      let hsCode  = row["hsCode"]
      let productName = row["productName"]
      this.$confirm(`确定删除海关编码 ` + hsCode + ` 的 ` + productName + ` 吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
          deleteAction(this.url.delete, {id: row.id}).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
      }).catch(() => {
      });
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.visible = true;
    },
  },
    computed:{
      buttonShowList() {
        return {
          'edit':this.$btnIsShow('isystem/BaseData/BdCountryHsCodeModule','0','编辑'),
          'del':this.$btnIsShow('isystem/BaseData/BdCountryHsCodeModule','0','删除'),
        }
      }
    }
}
</script>
<style lang="scss" scoped></style>