<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="70px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="二字码">
              <el-input v-model="queryForm.twoCode" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="中文名称">
              <el-input v-model="queryForm.cnName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="英文名称">
              <el-input v-model="queryForm.enName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="所属洲">
              <el-input v-model="queryForm.continent" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="addCountry()"

          v-if="buttonShowList.add"
          >新增</el-button
        >
        <el-button
          class="c-theme-error-button"
          size="medium"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.plsc"
          @click="handleBatDel"
          >批量删除</el-button
        >
      </div>
      <div class="c-alert f-m-b-10">
        已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
        <span
          class="stress f-m-l-12 f-cursor-pointer"
          @click="handleClearSelection"
          >清空</span
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <!--             <template v-if="scope.column.property === 'status'">-->
          <!--                <span v-if="scope.row.status === 1">正常</span>-->
          <!--                <span class="dot-badge" v-else>冻结</span>-->
          <!--            </template>-->
          <!--            <template v-else-if="scope.column.property === 'sex'">-->
          <!--                <span v-if="scope.row.sex === 1">男</span>-->
          <!--                <span v-else>女</span>-->
          <!--            </template>-->
          <!--            <template v-else-if="scope.column.property === 'roles'">-->
          <!--               {{ handleGetRoles(scope.row.roles) }}-->
          <!--            </template>-->
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="editCountry(scope.row)"

                       v-if="buttonShowList.bj"
            >编辑</el-button>
            <el-button type="text" @click="addState(scope.row)"

                       v-if="buttonShowList.tjsz"
              >添加省/州</el-button
            >
            <el-button type="text" @click="showState(scope.row.cnName)"
              >查看属下省/州</el-button
            >
          </template>
          <template v-else-if="scope.column.property==='isQg'">
            <span>{{parseDict(scope.row.isQg,'is_default')}}</span>
          </template>
          <template v-else-if="scope.column.property==='isCangzu'">
            <span>{{parseDict(scope.row.isCangzu,'is_default')}}</span>
          </template>
          <template v-else-if="scope.column.property==='isHaiwai'">
            <span>{{parseDict(scope.row.isHaiwai,'is_default')}}</span>
          </template>
          <template v-else-if="scope.column.property==='isChayan'">
            <span>{{parseDict(scope.row.isChayan,'is_default')}}</span>
          </template>
          <template v-else-if="scope.column.property==='isSuo'">
            <span>{{parseDict(scope.row.isSuo,'is_default')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <add-state-dialog
      :visible.sync="authVisible"
      title="添加省/州"
      :url="authUrl"
      :id="id"
    ></add-state-dialog>
    <add-country-dialog
            :row="selectedRow"
      :visible.sync="countryVisible"
      title="添加国家"
      @ok="handleSearch"
    ></add-country-dialog>
    <show-states-dialog
      :visible.sync="showVisible"
      title="查看属下省/州"
      :cnName="cnName"
      :statesData="statesData"
    ></show-states-dialog>
  </div>
</template>

<script>
import { putAction, deleteAction, getAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import AddUserDrawer from "../modules/AddUserDrawer";
import AddStateDialog from "../modules/AddStateDialog";
import AddCountryDialog from "../modules/AddCountryDialog";
import ShowStatesDialog from "../modules/ShowStatesDialog";
import RelationDialog from "../modules/RelationDialog";
export default {
  name: "BaseDataManage",
  mixins: [QueryMixins],
  components: {
    AddUserDrawer,
    // EditTreeDialog,
    RelationDialog,
    AddStateDialog,
    ShowStatesDialog,
    AddCountryDialog,
  },
  data() {
    return {
      id: "",
      cnName: "",
      dictCodes:['is_default'],
      statesData: [],
      type: "",
      authVisible: false,
      showVisible: false,
      countryVisible: false,
      relateVisible: false,
      visible: false,
      row: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "twoCode",
          label: "二字码",
        },
        {
          prop: "cnName",
          label: "中文名称",
        },
        {
          prop: "enName",
          label: "英文名称",
        },
        {
          prop: "continent",
          label: "所属洲",
        },
        {
          prop: "isQg",
          label: "是否需要清关属性",
        },
        {
          prop: "isHaiwai",
          label: "是否需要海外中转仓服务",
        },
        {
          prop: "isCangzu",
          label: "是否需要锁国外仓租柜租",
        },
        {
          prop: "isChayan",
          label: "是否需要锁国外查验",
        },
        {
          prop: "isSuo",
          label: "是否需要三锁服务",
        },
        // {
        //     prop: "realname",
        //     label: "中文名",
        //     sortable: 'custom',
        // }
      ],
      authUrl: {
        list: "/sys/bdCountry/list",
      },
      url: {
        list: "/sys/bdCountry/list",
        deleteBatch: "/sys/bdCountry/deleteBatch",
        showState: "/sys/bdCountry/getStateList",
      },
      selectedRow:{},
    };
  },
  methods: {
    addState({ id }) {
      this.id = id.toString();
      this.authVisible = true;
    },
    addCountry() {
      this.countryVisible = true;
    },
    editCountry(row) {
      this.selectedRow = row;
      this.countryVisible = true;
    },
    showState(cnName) {
      this.cnName = cnName.toString();
      this.showVisible = true;
    },
    handleBatDel() {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/BaseDataManage','0','新增'),
        'plsc':this.$btnIsShow('isystem/BaseData/BaseDataManage','0','批量删除'),
        'bj':this.$btnIsShow('isystem/BaseData/BaseDataManage','1','编辑'),
        'tjsz':this.$btnIsShow('isystem/BaseData/BaseDataManage','1','添加省/州'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dot-badge {
  position: relative;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    left: -10px;
    top: calc(50% - 3px);
    position: absolute;
    background: #ec1c1c;
    border-radius: 50%;
  }
}
</style>
