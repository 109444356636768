<!--友情链接-->
<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row style="display: flex; flex-wrap: wrap">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="网站名称">
              <el-input v-model="queryForm.linksName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="网址">
              <el-input v-model="queryForm.linksUrl"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              style="margin-left: 20px"
              >查询
            </el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          v-if="buttonShowList.add"
          @click="handleAdd"
          >新增
        </el-button>
        <el-button
          @click="handleDel"
          type="primary"
          size="medium"
          v-if="buttonShowList.del"
          >批量删除</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="67vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'isEnable'">
            {{ scope.row.isEnable == 1 ? "是" : "否" }}
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handlechange(scope.row)"
              v-if="scope.row.isEnable == 0 && buttonShowList.qy"
              >启用
            </el-button>
            <el-button
              type="text"
              @click="handlechange(scope.row)"
              v-if="scope.row.isEnable == 1 && buttonShowList.jy"
              >禁用
            </el-button>
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList.edit"
              >编辑
            </el-button>
          </template>
        </template>
      </f-table>
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <!-- 友情链接弹框 -->
    <el-dialog
      :title="row.id ? '编辑友情链接' : '新增友情链接'"
      :visible="visible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        size="medium"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item prop="linksUrl" label="网址">
              <el-input
                v-model="form.linksUrl"
                placeholder="请输入网址"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="linksName" label="网站名称">
              <el-input
                v-model="form.linksName"
                placeholder="请输入网站名称"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="contactName" label="联系人姓名">
              <el-input
                v-model="form.contactName"
                placeholder="联系人姓名"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="contactPhone" label="联系电话">
              <el-input
                v-model="form.contactPhone"
                placeholder="联系电话"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="contactEmail" label="站长Email">
              <el-input
                v-model="form.contactEmail"
                placeholder="站长Email"
                maxlength="50"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="websiteOverview" label="网站简况">
              <el-input
                v-model="form.websiteOverview"
                placeholder="请输入网站简况"
                type="textarea"
                maxlength="150"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "../../mixins/QueryMixins";
import { getAction, postAction, deleteAction } from "@/api/manage";

export default {
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      row: {},
      form: {
        isEnable: 1,
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "linksName",
          label: "网站名称",
          width: 100,
        },

        {
          prop: "linksUrl",
          label: "网址",
          width: 100,
        },
        {
          prop: "createTime",
          label: "录入时间",
          width: 100,
        },
        {
          prop: "isEnable",
          label: "是否启用",
          width: 100,
        },
      ],
      url: {
        list: "/sys/friendshipLinks/list",
        saveOrUpdate: "/sys/friendshipLinks/saveOrUpdate",
        del: "/sys/friendshipLinks",
      },
      visible: false,
      rules: {
        linksUrl: [
          {
            required: true,
            message: "请填写网址",
            trigger: "change",
          },
          { max: 200, message: "长度不能超过200", trigger: "change" },
        ],
        linksName: [
          {
            required: true,
            message: "请填写网站名称",
            trigger: "change",
          },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleAdd() {
      this.visible = true;
    },
    handleClose() {
      this.form = {
        isEnable: 1,
      };
      this.visible = false;
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction(this.url.saveOrUpdate, this.form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.handleQuery();
          });
        }
      });
    },
    handlechange(row) {
      row.isEnable == 0 ? (row.isEnable = 1) : (row.isEnable = 0);
      postAction(this.url.saveOrUpdate, row).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    handleEdit(row) {
      this.visible = true;
      this.form = { ...row };
    },
    handleDel() {
      deleteAction(this.url.del, {
        idList: this.selectedRowKeys.join(","),
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
  },
  created() {},
  computed: {
    buttonShowList() {
      return {
        add: this.$btnIsShow("forestMarket/LinkRequest", "0", "新增"),
        del: this.$btnIsShow("forestMarket/LinkRequest", "0", "批量删除"),
        edit: this.$btnIsShow("forestMarket/LinkRequest", "1", "编辑"),
        qy: this.$btnIsShow("forestMarket/LinkRequest", "1", "启用"),
        jy: this.$btnIsShow("forestMarket/LinkRequest", "1", "禁用"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
