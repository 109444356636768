<template>
  <div class="menu-manage-wrap" v-loading="loading">
    <el-row :gutter="12">
      <el-col :span="10">
        <menu-tree-module
          class="tree-part"
          :data="data"
          @current-change="handleCurrentChange"
        ></menu-tree-module>
      </el-col>
      <el-col :span="14">
        <menu-edit-module class="edit-part" :row="row" :data="data"></menu-edit-module>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
import MenuTreeModule from "./modules/MenuTreeModule"
import MenuEditModule from "./modules/MenuEditModule"
export default {
  name: "MenuManage",
  components: {
    MenuTreeModule,
    MenuEditModule,
  },
  data() {
    return {
      data: [],
      dataReference: {},
      row: {},
      height: 850,
      loading: false,
      url: {
          list: '/sys/permission/list'
      }
    }
  },
  mounted() {
    this.handleGetData()
    this.handleGetWindowHeight()
    window.addEventListener('resize', this.handleGetWindowHeight)

    this.$bus.$on('isys-menu-data-refresh', () => {
        this.row = {}
        this.handleGetData()
        this.$bus.$emit('isys-menu-tree-reset')
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleGetWindowHeight)
    this.$bus.$off('isys-menu-data-refresh')
  },
  methods: {
    handleCurrentChange({id}) {
      // console.log('this.dataReference',this.dataReference,id);
      this.row = this.dataReference[id];
      // consoloe.log('选中的当前按钮',this.row)
    },
    handleGetData() {
      this.loading = true
      getAction(this.url.list).then((res) => {
        this.data = res.result
        this.handleSetReference(this.data)
      }).finally(() => {
          this.loading = false
      })
    },
    handleSetReference(data) {
      for (let i = 0; i < data.length; i++) {
        this.dataReference[data[i]["id"]] = data[i]
        if (data[i]["children"] && data[i]["children"].length) {
          this.handleSetReference(data[i]["children"])
        }
      }
    },
    handleGetWindowHeight() {
        const h = (document.body.offsetHeight || window.innerHeight || window.innerHeight) - 118
        const min_h = 844
        this.height = h > min_h ? h : min_h
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-manage-wrap {
  height: 100%;
  .el-row,
  .el-col,
  .tree-part,
  .edit-part {
    height: 100%;
  }
  .tree-part,
  .edit-part {
    min-height: 692px;
  }
  .tree-part,
  .edit-part {
    background: #ffffff;
  }
}
</style>
