<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div class="f-p-b-15" style="display: flex">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号">
                <el-input
                  class="textarea_table"
                  v-model="queryForm.orderNo"
                  type="textarea"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="客户名">
                <el-input
                  v-model="queryForm.customerName"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="材积通知">
                <el-select v-model="queryForm.isEmail" clearable fliterable>
                  <el-option value="0" label="未通知"></el-option>
                  <el-option value="1" label="已通知"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="是否入仓">
                <el-select v-model="queryForm.isRealCmb" clearable fliterable>
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="拼柜仓库">
                <f-select
                  :dict="'lclPlace'"
                  v-model="queryForm.lclPlace"
                  :isNeed="queryForm.lclPlace"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="业务员">
                <f-select
                  :dict="'sys_user_id'"
                  v-model="queryForm.salesId"
                  :isNeed="queryForm.salesId"
                >
                </f-select>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="客服">
                <f-select
                  v-model="queryForm.docId"
                  :isNeed="queryForm.docId"
                  :dict="'sys_user_id'"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="操作">
                <f-select
                  v-model="queryForm.operateId"
                  :isNeed="queryForm.operateId"
                  :dict="'sys_user_id'"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :md="6" :sm="24">
              <el-form-item label="入仓时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.rucangTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.rucangTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :md="4" :sm="24">
              <el-form-item label="发票是否确认" label-width="100px">
                <el-select
                  v-model="queryForm.invoiceStatus"
                  clearable
                  filterable
                >
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--                    <el-col class="no_margin" :md="4" :sm="24">-->
            <!--                        <el-form-item label="材积是否确认" label-width="100px">-->
            <!--                            <el-select v-model="queryForm.isRealCmb" filterable clearable>-->
            <!--                                <el-option value="1" label="是"></el-option>-->
            <!--                                <el-option value="0" label="否"></el-option>-->
            <!--                            </el-select>-->
            <!--                        </el-form-item>-->
            <!--                    </el-col>-->
            <el-col class="no_margin" :md="4" :sm="24">
              <el-form-item label="是否问题件" label-width="100px">
                <el-select v-model="queryForm.isWarning" filterable clearable>
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :md="4" :sm="24">
              <el-form-item
                label="渠道名称"
                prop="channelIds"
                label-width="100px"
              >
                <f-select
                  :dict="'discount_list'"
                  :is-need="queryForm.channelIdList"
                  :multiple="true"
                  v-model="queryForm.channelIdList"
                  autocomplete="off"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :md="4" :sm="24">
              <el-form-item label="计费方式" prop="fjfUnit">
                <el-select v-model="queryForm.fjfUnit" clearable filterable>
                  <el-option value="KG" label="KG"></el-option>
                  <el-option value="CBM" label="CBM"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :md="4" :sm="24">
              <el-form-item label="是否包税" prop="hasTax" label-width="100px">
                <el-select v-model="queryForm.hasTax" clearable filterable>
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :md="4" :sm="24">
              <el-form-item label="超大件" prop="largeInfo" label-width="100px">
                <f-select
                  :multiple="true"
                  v-model="queryForm.largeInfos"
                  :is-need="queryForm.largeInfos"
                  :dict="'volume_large_info'"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <!--                  <el-col  class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
            <!--                    <el-form-item label="清关属性">-->
            <!--                      <f-select-->
            <!--                          :dict="'qg_prop'"-->
            <!--                          v-model="queryForm.qgProp"-->
            <!--                          :is-need="queryForm.qgProp"-->
            <!--                      ></f-select>-->
            <!--                    </el-form-item>-->
            <!--                  </el-col>-->
            <!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">-->
            <!--                    <el-form-item label="海外仓中转服务">-->
            <!--                      <f-select-->
            <!--                          :dict="'haiwai_service'"-->
            <!--                          v-model="queryForm.haiwaiService"-->
            <!--                          :is-need="queryForm.haiwaiService"-->
            <!--                      ></f-select>-->
            <!--                    </el-form-item>-->
            <!--                  </el-col>-->
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="4"
              class="no_margin"
              v-if="showLoadingSearch"
            >
              <el-form-item label="锁国外仓租柜租">
                <f-select
                  :dict="'is_default'"
                  v-model="queryForm.lockCangzu"
                  :is-need="queryForm.lockCangzu"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="4"
              class="no_margin"
              v-if="showLoadingSearch"
            >
              <el-form-item label="锁国外查验">
                <f-select
                  :dict="'is_default'"
                  v-model="queryForm.lockChayan"
                  :is-need="queryForm.lockChayan"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="4"
              class="no_margin"
              v-if="showLoadingSearch"
            >
              <el-form-item label="三锁服务">
                <f-select
                  :dict="'is_default'"
                  v-model="queryForm.sanLock"
                  :is-need="queryForm.sanLock"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="4"
              class="no_margin"
              v-if="showLoadingSearch"
            >
              <el-form-item label="地址类型">
                <f-select
                  :dict="'address_type'"
                  v-model="queryForm.shipmentType"
                  :is-need="queryForm.shipmentType"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="4"
              class="no_margin"
              v-if="showLoadingSearch"
            >
              <el-form-item label="航线名称">
                <f-select
                  :dict="'fba_solicitation'"
                  v-model="queryForm.solicitation"
                  :is-need="queryForm.solicitation"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button
                size="medium"
                type="primary"
                @click="handleReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</el-button
              >
              <el-button
                type="text"
                mini
                @click="showLoadingSearch = !showLoadingSearch"
                ><i
                  :class="
                    showLoadingSearch
                      ? 'el-icon-arrow-up'
                      : 'el-icon-arrow-down'
                  "
                  style="margin-right: 3px"
                ></i
                >{{ showLoadingSearch ? "收起" : "更多" }}
              </el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card">
      <div style="display: flex" class="margin_bottom_10">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-download"
          @click="handleExportXls"
          >导出
        </el-button>
        <el-button
          v-if="buttonShowList.dc"
          type="primary"
          size="medium"
          icon="el-icon-download"
          @click="handleExportXls('location')"
          >导出库位
        </el-button>
      </div>
      <div class="c-alert f-m-b-10">
        已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
        <span
          class="stress f-m-l-12 f-cursor-pointer"
          @click="handleClearSelection"
          >清空</span
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑
            </el-button>
            <el-button type="text" @click="handDel(scope.row)">删除</el-button>
          </template>
          <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
              parseDict(scope.row.transportStatus, "transportStatus")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isEmail'">
            <span style="color: red" v-if="scope.row.isEmail === '1'"
              >已通知</span
            >
            <span v-else>未通知</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <span
              v-if="
                Number(scope.row.isWarning) === 0 ||
                scope.row.isWarning == null ||
                scope.row.isWarning == '' ||
                Number(scope.row.isWarning) === 2
              "
            >
              <a
                style="color: #00a0e9"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
                >{{ scope.row.orderNo }}
              </a>
            </span>
            <span v-if="Number(scope.row.isWarning) === 1">
              <a
                style="color: red"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
                >{{ scope.row.orderNo }}
              </a>
            </span>
          </template>
          <template v-else-if="scope.column.property === 'largeInfos'">
            <div style="display: flex; flex-direction: column">
              <span
                v-for="(item, index) in scope.row.largeInfos"
                :key="index"
                >{{ item }}</span
              >
            </div>
          </template>
          <template v-else-if="scope.column.property === 'isRealCmb'">
            <span
              style="color: #00a0e9"
              v-if="Number(scope.row.actualTotalVolume) === 0"
              >(未入仓)</span
            >
            <span
              style="color: red"
              v-if="Number(scope.row.actualTotalVolume) > 0"
              >(已入仓)</span
            >
          </template>
          <template v-else-if="scope.column.property === 'hasTax'">
            <span style="color: #00a0e9" v-if="Number(scope.row.hasTax) === 0"
              >未包税</span
            >
            <span style="color: red" v-if="Number(scope.row.hasTax) === 1"
              >已包税</span
            >
          </template>
          <template v-else-if="scope.column.property === 'customerName'">
            <a
              type="text"
              @click.prevent="$intoCustomerEditPage(scope.row.customerId)"
              style="color: #57a3f3"
              >{{ scope.row.customerName }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'number'">
            <span
              style="color: red; font-weight: bolder"
              v-if="scope.row.number !== scope.row.estimcatedTotalNumber"
              >{{ scope.row.number }}</span
            >
            <span v-else>{{ scope.row.number }}</span>
          </template>
          <template v-else-if="scope.column.property === 'actualTotalNumber'">
            <span
              style="color: red; font-weight: bolder"
              v-if="
                scope.row.actualTotalNumber !== scope.row.estimcatedTotalNumber
              "
              >{{ scope.row.actualTotalNumber }}</span
            >
            <span v-else style="color: #00a329; font-weight: bolder">{{
              scope.row.actualTotalNumber
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'tijizhong'">
            <span>{{
              scope.row.actualTotalVolume
                ? (Number(scope.row.actualTotalVolume) * Number(167)).toFixed(3)
                : 0
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'actualTotalVolume'">
            <el-popover placement="top" trigger="hover">
              <span>{{
                "（订单预报体积/订单实际体积 - 1）* 100%: " +
                (
                  (Number(scope.row.estimcatedTotalVolume) /
                    Number(scope.row.actualTotalVolume) -
                    1) *
                  100
                ).toFixed(3) +
                "%"
              }}</span>
              <div slot="reference">
                <span
                  style="color: red; font-weight: bolder"
                  v-if="
                    Math.abs(
                      Number(scope.row.estimcatedTotalVolume) /
                        Number(scope.row.actualTotalVolume) -
                        1
                    ) >= 0.1
                  "
                  >{{ scope.row.actualTotalVolume }}</span
                >
                <span v-else style="color: #00a329; font-weight: bolder">{{
                  scope.row.actualTotalVolume
                }}</span>
              </div>
            </el-popover>
          </template>
          <template v-else-if="scope.column.property === 'realKg'">
            <!-- （订单预报重量/订单过磅重 - 1）* 100%，结果的绝对值大于3%，标红 -->
            <el-popover placement="top" trigger="hover">
              <span>{{
                "（订单预报重量/订单过磅重 - 1）* 100%: " +
                (
                  (Number(scope.row.kg) / Number(scope.row.realKg) - 1) *
                  100
                ).toFixed(3) +
                "%"
              }}</span>
              <div slot="reference">
                <span
                  style="color: red; font-weight: bolder"
                  v-if="
                    Math.abs(
                      Number(scope.row.kg) / Number(scope.row.realKg) - 1
                    ) >= 0.03
                  "
                  >{{ scope.row.realKg }}</span
                >
                <span v-else>{{ scope.row.realKg }}</span>
              </div>
            </el-popover>
          </template>
          <template v-else-if="scope.column.property === 'shipmentId'">
            <el-popover placement="top-start" width="300" trigger="hover">
              <div>{{ scope.row.shipmentId }}</div>
              <span
                slot="reference"
                v-if="scope.row.shipmentId && scope.row.shipmentId.length > 40"
                >{{ scope.row.shipmentId.substr(0, 40) + "..." }}</span
              >
              <span slot="reference" v-else>{{ scope.row.shipmentId }}</span>
            </el-popover>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { postAction,postRefreshAction } from "../../api/manage";
import QueryMixins from "../../mixins/QueryMixins";
import Tempcclate from "../test/Tempcclate";
import { setUpRouteUtil } from "@/utils/util";

export default {
  name: "VolumeData",
  mixins: [QueryMixins],
  components: {
    Tempcclate,
  },
  data() {
    return {
      showLoadingSearch: false,
      dictCodes: ["isEmail", "transportStatus"],
      form: {
        url: "",
        size: "",
        fileType: "",
        type: "",
        id: "",
        cusId: "",
        name: "",
        ossId: "",
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 110,
        },
        {
          prop: "realCtn",
          label: "实际件数",
          minWidth: 50,
        },
        {
          prop: "estimcatedTotalVolume",
          label: "预计体积",
          minWidth: 50,
        },
        {
          prop: "realKg",
          label: "过磅重",
          minWidth: 50,
        },
        {
          prop: "actualTotalVolume",
          label: "实际体积",
          minWidth: 50,
        },
        {
          prop: "shipmentZip",
          label: "邮编",
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "storehouseRemark",
          label: "仓库备注",
        },
      ],
      url: {
        list: "/interface/ttOrder/getWareHouseOrderCajiIpage",
        exportXlsUrl: "/file/export/excel/downloadWarehouseCajiExcel",
        downloadWarehouseLocationExcel: '/jeecg-cloud-file-biz/file/export/excel/downloadWarehouseLocationExcel',
      },
      selectVolumeList: [],
      isUsePost: true,
    };
  },
  created() {
    this.$set(this.queryForm, "isRealCmb", "1");
  },
  mounted() {},
  methods: {
    handleExportXls(type) {
      let selectNum = 0;
      console.log(Object.keys(this.queryForm), 'Object.keys(this.queryForm)')
      Object.keys(this.queryForm).forEach(item => {
        console.log(typeof this.queryForm[item], item, this.queryForm[item], 'this.queryForm[item]')
        if((typeof this.queryForm[item] !== 'object' && this.queryForm[item]) ||(typeof this.queryForm[item] == 'object' &&  this.queryForm[item].length > 0)) {
          selectNum++;
        }
      })
      console.log(selectNum, 'selectNum')
      if (selectNum == 0) {
          this.$message.warning('导出excel必须添加查询条件')
          return
      }
      let fileName = '';
      if(type == 'location') {
        fileName = '库位';
        this.exportExcelPlus(this.url.downloadWarehouseLocationExcel, fileName);
        return;
      } else {
        fileName = '材积数据';
      }
      this.exportLoading = true;
      this.loading = true;
      // this.$store.commit("setFullscreenLoading", true);
      if (!fileName || typeof fileName != 'string') {
        fileName = '导出文件'
      }
      if (fileName === '正常产品' || fileName === '所有产品') {
        this.$confirm(`导出的数据量比较庞大，需要等待1-2分钟?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          this.exportExcel(fileName);
        }).catch(() => {

        })
      } else {
        this.exportExcel(fileName);
      }
    },


    exportExcel(fileName) {
      const {prop, order} = this.isorter
      let params = {
        ...this.queryForm,
        ...this.filterForm,
      };

      // 传入的参数
      //判断 是否要查询startTime


      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        params['selections'] = this.selectedRowKeys.join(',');
        params['ids'] = this.selectedRowKeys.join(',');
        params['orderNos'] =this.selectedRowOrderNos.join(',');
      } else {
        params['ids'] = '';
      }
      //清空字符串的前后空格
      this.trimParamsStr(params);
      let that = this;
      postRefreshAction(this.url.exportXlsUrl, params, {responseType: 'blob'}).then((data) => {
        this.loading = false;
        this.exportLoading = false;
        // this.$store.commit("setFullscreenLoading", false);
        if (data.type === 'application/json') {
          const reader = new FileReader()
          reader.onload = function () {
            const message = JSON.parse(reader.result).message
            // that.$message.error(message);// 将错误信息显示出来
          }
          reader.readAsText(data)
          return false;
        }else if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName + '.xls');
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);//下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      }).catch(err=>{
        this.loading = false;
        this.exportLoading = false;
        // this.$store.commit("setFullscreenLoading", false);
      })
    },

    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.selectVolumeList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.selectVolumeList.push(selection[i]);
      }
      this.selectVolumeList = selection;
      this.selectedRowKeys = [...arr];
    },
    handleReset() {
      this.queryForm = {};
      this.channelIds = [];
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  computed:{
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('warehouse2.0/VolumeData','0','导出库位'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
