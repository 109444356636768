<!--待提交-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="queryForm" :model="queryForm" label-width="100px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :md="4" :sm="24">
                      <el-form-item label="国家税单类型" prop="countryTaxType">
                        <f-select
                            v-model="queryForm.countryTaxType"
                            :isNeed="queryForm.countryTaxType"
                            :dict="'country_tax_type'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数" prop="week">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号" prop="containerNo">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="订舱代理" prop="bookAgentId">
                        <f-select
                            v-model="queryForm.bookAgentId"
                            :isNeed="queryForm.bookAgentId"
                            :dict="'bookAgent_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="税单发送成功状态" prop="shuidanType">
                        <f-select
                            v-model="queryForm.shuidanType"
                            :isNeed="queryForm.shuidanType"
                            :dict="'shuidan_type'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订舱单号" prop="bookNo">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="分税状态" prop="taxSharingStatus">
                            <f-select
                                    v-model="queryForm.taxSharingStatus"
                                    :isNeed="queryForm.taxSharingStatus"
                                    :dict="'tax_sharing_status'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="清关代理" prop="clearAgentId">
                        <f-select
                            v-model="queryForm.clearAgentId"
                            :isNeed="queryForm.clearAgentId"
                            :dict="'clearAgent_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="拆柜代理" prop="dismantleAgentId">
                        <f-select
                            v-model="queryForm.dismantleAgentId"
                            :isNeed="queryForm.dismantleAgentId"
                            :dict="'dismantleAgent_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="4" :sm="24">
                      <el-form-item label="送货代理" prop="deliveryAgentId">
                        <f-select
                            v-model="queryForm.deliveryAgentId"
                            :isNeed="queryForm.deliveryAgentId"
                            :dict="'deliveryAgent_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="派送操作" prop="endOperateId" label-width="70px">
                            <f-select
                                    :isNeed="queryForm.endOperateId"
                                    v-model="queryForm.endOperateId"
                                    :fatherVal="queryForm.endOperateId"
                                    :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                      <el-form-item label="ETA">
                        <div class="f-flex">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.eta"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                          <span class="f-p-h-2">~</span>
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.etaEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                        <el-form-item label="ETD">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etd"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.etdEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                            <f-select v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>


                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
          <div class="f-p-b-15">
            <el-button
                type="primary"
                size="medium"
                v-if="buttonShowList.fssdyj && queryForm.countryTaxType === '1'"
                @click="fasongshuidan"
                :disabled="selectedRowKeys.length === 0 "
            >发送邮件
            </el-button>
            <el-button
                type="primary"
                size="medium"
                v-if="buttonShowList.glyfssdyj && queryForm.countryTaxType === '1'"
                @click="fasongshuidanByAdmin"
                :disabled="selectedRowKeys.length === 0 "
            >管理员发送邮件
            </el-button>

          </div>
          <div class="c-alert f-m-b-10">
            已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
            <span
                class="stress f-m-l-12 f-cursor-pointer"
                @click="handleClearSelection"
            >清空</span
            >
          </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                                type="text"
                                v-if="buttonShowList.fssdyj"
                                @click="fasongshuidan(scope.row)"
                        >{{(scope.row.isSendShuidanEmail == '1' && scope.row.countryTaxType == '2') ? '重发税单邮件' : '发送税单邮件'}}
                        </el-button>
                        <el-button
                                type="text"
                                v-if="buttonShowList.glyfssdyj"
                                @click="fasongshuidanByAdmin(scope.row)"
                        >{{(scope.row.isSendShuidanEmail == '1' && scope.row.countryTaxType == '2') ? '重发税单邮件' : '管理員发送税单邮件'}}
                        </el-button>
                        <el-button
                                type="text"
                                v-if="buttonShowList.cksdyj"
                                @click="chakanyoujian(scope.row)"
                        >查看税单邮件
                        </el-button>

                    </template>
                    <template v-else-if="scope.column.property === 'complianceState'">
                        <span>{{parseDict(scope.row.complianceState, "compliance_state")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetCategory'">
                        <span>{{parseDict(scope.row.cabinetCategory, "cabinet_category")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookAgentId'">
                        <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id_chinese") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'taxSharingStatus'">
                        <span v-if="queryForm.countryTaxType === '1'">{{ parseDict(scope.row.taxSharingStatus, "tax_sharing_status") }}</span>
                      <el-tag v-else-if="scope.row.shuidanType === '0'" type="info">未发送</el-tag>
                      <el-tag v-else-if="scope.row.shuidanType === '1'" type="warning">部分成功</el-tag>
                      <el-tag v-else-if="scope.row.shuidanType === '2'" type="success">成功</el-tag>
                      <el-tag v-else-if="scope.row.shuidanType === '3'" type="danger">失败</el-tag>
                    </template>
                    <template v-else-if="scope.column.property === 'isVirtualCabinet'">
                        <span v-if="scope.row.isVirtualCabinet === '0'">自拼</span>
                        <span v-if="scope.row.isVirtualCabinet === '1'">非自拼</span>
                    </template>

                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.id)">{{ scope.row.week }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'clearAgentId'">
                        <span>{{ parseDict(scope.row.clearAgentId, "clearAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'endOperateId'">
                        <span>{{ parseDict(scope.row.endOperateId, "sys_user_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'dismantleAgentId'">
                        <span>{{ parseDict(scope.row.dismantleAgentId, "dismantleAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryAgentId'">
                        <span>{{ parseDict(scope.row.deliveryAgentId, "deliveryAgent_id") }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
      <send-shuidan-email-log
      :visible="emailVisible"
      :container-id="containerId"
      :country-tax-type="countryTaxType"
      :week="week"
      :initRow="row"
      @ok="close"
      @handleReSend="handleReSend"
      ></send-shuidan-email-log>

        <el-dialog
                :title="resultTitle + selectWeek"
                :visible="alertVisible"
                width="50%"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :before-close="closeEmail"
                append-to-body
        >
            <div style="vertical-align: center;horiz-align: center">
                <p v-for="(item,index) in sendEmailResult" :key="index" v-html="item"></p>
            </div>
        </el-dialog>
        <!-- 发送税单邮件 -->
        <el-dialog
                :title="'发送税单邮件-' + (isReTaxEmail ? (row && row.orderNo) : (row && row.week))"
                :visible="sendTaxEmailVisible"
                width="30%"
                custom-class="c-custom-dialog"
                :before-close="handleClose"
        >

        <el-form ref="taxEmailForm" :model="taxEmailForm" label-width="80px" size="medium" :rules="tTaxEmailRules">
          <el-row class="inputs_row">
              <el-col :span="24">
                  <el-form-item label="海运费（AUD）" prop="totalDeclared">
                      <el-input v-model="taxEmailForm.totalDeclared" type="number" :disabled="true"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="24">
                  <el-form-item label="保费" prop="ons">
                      <el-input v-model="taxEmailForm.ons" type="number" :disabled="true"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="24">
                  <el-form-item label="汇率" prop="excRate">
                      <el-input v-model="taxEmailForm.excRate" type="number"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
        </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="sendTaxEmailVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleTaxEmail">发 送</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {postAction, getAction } from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';
    import SendShuidanEmailLog from "@/views/operation/cabinet/SendShuidanEmailLog.vue";
    import SendPaiguiEmailLog from "@/views/operation/cabinet/SendPaiguiEmailLog.vue";
    import fa from "element-ui/src/locale/lang/fa";
    export default {
        name: "PreQingguan",
        components: {
            SendShuidanEmailLog,
            SendPaiguiEmailLog
        },
        mixins: [QueryMixins],
        data() {
            return {
              queryForm: {countryTaxType: '2'},
              isReTaxEmail: false,  // 是否是重发税单
              row: {},
              taxEmailForm: {},
              sendTaxEmailVisible: false,
              totalDeclared:2250.00,
              ons:2,
              tTaxEmailRules: {
                totalDeclared: [
                        {required: true, message: '请输入海运费', trigger: 'blur'},
                    ],
                    ons: [
                        {required: true, message: '请输入保费', trigger: 'blur'},
                    ],
                    excRate: [
                        {required: true, message: '请输入汇率', trigger: 'blur'},
                    ],
                },

                resultTitle:'发送税单邮件结果:',
                sendEmailResult:[],
                alertVisible:false,
                containerId:'',
                week:'',
                countryTaxType:'',
                isAud:'',
                selectWeek:'',
                dictCodes: ["compliance_state", "cabinet_category", "bookAgent_id_chinese", "clearAgent_id", "dismantleAgent_id", "deliveryAgent_id","tax_sharing_status","sys_user_id"],
                formLabelWidth: "150px",
                //控制弹框显示
                emailVisible:false,
                paiguiVisible:false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        minWidth: 110
                    },
                    {
                        prop: "week",
                        label: "周数",
                        sortable: "week",
                    },

                    {
                        prop: "bookAgentId",
                        label: "订舱代理",
                        sortable: "bookAgentId",
                    },
                    {
                        prop: "etd",
                        label: "ETD",
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                    },
                    {
                        prop: "endOperateId",
                        label: "派送操作",
                    },
                    {
                        prop: "size",
                        label: "柜型",
                        sortable: "size",
                        width: 100,
                    },
                    {
                        prop: "bookNo",
                        label: "订舱单号",
                        sortable: "bookNo",
                        width: 140,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        sortable: "custom",
                        width: 150,
                    },
                    {
                        prop: "originalNo",
                        label: "封条",
                        sortable: "custom",
                        width: 100,
                    },
                    {
                        prop: "isVirtualCabinet",
                        label: "是否自拼",
                        minWidth: 100,
                        sortable: "isVirtualCabinet",
                    },
                    {
                        prop: "taxSharingStatus",
                        label: "分税状态",
                        minWidth: 180,
                        sortable: "taxSharingStatus",
                    },
                    {
                        prop: "clearAgentId",
                        label: "清关代理",
                        minWidth: 180,
                        sortable: "clearAgentId",
                    },
                    // {
                    //     prop: "shuidanType",
                    //     label: "税单邮件",
                    //     minWidth: 80,
                    // },
                    {
                        prop: "dismantleAgentId",
                        label: "拆柜代理",
                        minWidth: 180,
                        sortable: "dismantleAgentId",
                    },
                    {
                        prop: "deliveryAgentId",
                        label: "送货代理",
                        minWidth: 180,
                        sortable: "deliveryAgentId",
                    },
                ],
                url: {
                    list: "/operation/container/getPreQingguanIPage",
                    sendShuidanEmail: "/operation/container/sendShuidanEmail",
                    sendUsShuidanEmailBatch: "/operation/container/sendUsShuidanEmailBatch",
                    sendShuidanEmailNoGeneralFee: "/operation/container/sendShuidanEmailNoGeneralFee",
                    sendShuidanEmailNoGeneralFeeBatch: "/operation/container/sendShuidanEmailNoGeneralFeeBatch",
                    sendPaiguiEmail:'/operation/container/sendPaiguiEmail',
                    sendAustraliaEmail: '/operation/container/sendAustraliaEmail',  //发送税单邮件接口
                    sendOneAustraliaEmailByOrderNo: '/operation/container/sendOneAustraliaEmailByOrderNo',  //重新发送税单邮件接口
                    getOneAudShuidanProp: '/operation/container/getOneAudShuidanProp',  //重新发送税单邮件调用的回显接口 //?containerId=1704014895202189314
                },
                isUsePost:true,
            };
        },
        methods: {
            closeEmail(){
                this.alertVisible = false;
            },
            close(){
                this.emailVisible = false;
                this.paiguiVisible = false;
                this.handleSearch();
            },
            handleSearch() {
             this.$refs.queryForm.validate((valid) => {
                if (valid) {
                  this.ipagination.currentPage = 1
                  this.handleQuery()
                }
              });
            },
            chakanyoujian(row){
                this.row = JSON.parse(JSON.stringify(row));
                this.week = row.week;
                this.containerId = row.id;
                this.countryTaxType = row.countryTaxType;
                this.emailVisible = true;
            },


            // 重新发送税单邮件
            handleReSend(row) {
              this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
              this.$set(this.taxEmailForm, 'ons', "2.00");
              row.isSendShuidanEmail = this.row.isSendShuidanEmail;
              this.row = JSON.parse(JSON.stringify(row));
              this.isReTaxEmail = true;
                if(row.isSendShuidanEmail == '1') {
                  this.$store.commit("setFullscreenLoading", true);
                  getAction(this.url.getOneAudShuidanProp + '?containerId=' + row.containerId).then(res => {
                    if(res.success) {
                    //   this.$set(this.taxEmailForm, 'totalDeclared', (res.result && res.result.totalDeclared) || '');
                    //   this.$set(this.taxEmailForm, 'ons', (res.result && res.result.ons) || '');
                    //   this.$set(this.taxEmailForm, 'excRate', (res.result && res.result.excRate) || '');
                      this.sendTaxEmailVisible = true;
                    }
                    this.$store.commit("setFullscreenLoading", false);
                  }).catch(err => {
                    this.$store.commit("setFullscreenLoading", false);
                  })
                } else {
                    this.sendTaxEmailVisible = true;
                }
            },
            handleTaxEmail() {
              this.$refs['taxEmailForm'].validate((valid) => {
                if (valid) {
                  let params = JSON.parse(JSON.stringify(this.taxEmailForm));
                  params.containerId = this.row.id
                  let url = this.isReTaxEmail ? this.url.sendOneAustraliaEmailByOrderNo : this.url.sendAustraliaEmail;
                  // 是否是重新发送
                  if(this.isReTaxEmail) {
                    params.outShuidanType = '1';
                    params.orderNo = this.row.orderNo;
                    params.containerId = this.row.containerId;
                  }
                //   this.$confirm(`确定发送税单邮件吗`, "提示", {
                    this.$confirm(`税单预计发送时间为：1分钟；过程中请勿刷新页面，请耐心等待！！！`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        closeOnClickModal: false,
                    })
                      .then(() => {
                        postAction(url, params)
                        .then((res) => {
                          if (res.success) {
                            this.$message.success('税单发送成功');
                            this.sendTaxEmailVisible = false;
                            // this.$alert(res.message, '发送邮件结果', {
                            //     dangerouslyUseHTMLString: true
                            // });
                            // this.$alert(`${res.message}`, '发送税单邮件结果', {
                            //     dangerouslyUseHTMLString: true,
                            //     showConfirmButton:false,
                            //     showCancelButton:true,
                            //     cancelButtonText:'关闭',
                            //     roundButton:true
                            // });
                            // this.selectWeek = this.row.week;
                            // this.sendEmailResult = res.result;
                            // this.resultTitle = "发送税邮件结果：";

                            // this.alertVisible = true;
                            // //关闭弹窗
                            // this.handleQuery()
                          }
                        })
                    })
                  }
              });
              },
            handleClose() {
              this.sendTaxEmailVisible = false;
            },
            //管理员 发送税单邮件不录入费用
            fasongshuidanByAdmin(row) {
              if (row) {
                this.row = JSON.parse(JSON.stringify(row));
              }

                if(row && row.countryTaxType == '2') {
                    this.isReTaxEmail = false;
                    if(row.isSendShuidanEmail == '1') {
                        this.$store.commit("setFullscreenLoading", true);
                        getAction(this.url.getOneAudShuidanProp + '?containerId=' + row.id).then(res => {
                            if(res.success) {
                            }
                            this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
                            this.$set(this.taxEmailForm, 'ons', "2.00");
                            this.sendTaxEmailVisible = true;
                            this.$store.commit("setFullscreenLoading", false);
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                        })
                    } else {
                        this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
                        this.$set(this.taxEmailForm, 'ons', "2.00");
                        this.sendTaxEmailVisible = true;
                    }
                } else {
                  let ids = [];
                  if (row && row.id) {
                    ids = [row.id];
                  }else {
                    console.log("即将要执行的发送邮件的柜子id集合",ids);
                    this.selectionRows.forEach(s => ids.push(s.id));
                  }

                    this.$confirm(`确定发送税单邮件吗`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "success",
                        closeOnClickModal: false,
                    })
                        .then(() => {
                            postAction(this.url.sendShuidanEmailNoGeneralFeeBatch,ids )
                                .then((res) => {
                                    if (res.success) {
                                        // this.$message.success(res.message);
                                        // this.$alert(res.message, '发送邮件结果', {
                                        //     dangerouslyUseHTMLString: true
                                        // });
                                        // this.$alert(`${res.message}`, '发送税单邮件结果', {
                                        //     dangerouslyUseHTMLString: true,
                                        //     showConfirmButton:false,
                                        //     showCancelButton:true,
                                        //     cancelButtonText:'关闭',
                                        //     roundButton:true
                                        // });
                                      if (row && row.id) {
                                        this.selectWeek = this.row.week;
                                      }

                                        this.sendEmailResult = res.result;
                                        this.resultTitle = "发送税邮件结果：";

                                        this.alertVisible = true;
                                        //关闭弹窗
                                        this.handleQuery()
                                    }
                                })
                        })
                }
            },
            fasongshuidan(row) {
              if (row) {
                this.row = JSON.parse(JSON.stringify(row));
              }

              if(row && row.countryTaxType == '2') {
                this.isReTaxEmail = false;
                if(row.isSendShuidanEmail == '1') {
                  this.$store.commit("setFullscreenLoading", true);
                  getAction(this.url.getOneAudShuidanProp + '?containerId=' + row.id).then(res => {
                    if(res.success) {
                    }
                    this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
                      this.$set(this.taxEmailForm, 'ons', "2.00");
                      this.sendTaxEmailVisible = true;
                    this.$store.commit("setFullscreenLoading", false);
                  }).catch(err => {
                    this.$store.commit("setFullscreenLoading", false);
                  })
                } else {
                     this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
                      this.$set(this.taxEmailForm, 'ons', "2.00");
                    this.sendTaxEmailVisible = true;
                }
              } else {
                let ids = [];

                if (row && row.id) {
                  ids.push(row.id);
                }else {
                  console.log("即将要发生的税单和邮件的柜子id",row,this.selectionRows,ids)
                  this.selectionRows.forEach(s => ids.push(s.id));
                }

                this.$confirm(`确定发送税单邮件吗`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "success",
                        closeOnClickModal: false,
                    })
                      .then(() => {
                        postAction(this.url.sendUsShuidanEmailBatch,ids)
                        .then((res) => {
                          if (res.success) {
                            // this.$message.success(res.message);
                            // this.$alert(res.message, '发送邮件结果', {
                            //     dangerouslyUseHTMLString: true
                            // });
                            // this.$alert(`${res.message}`, '发送税单邮件结果', {
                            //     dangerouslyUseHTMLString: true,
                            //     showConfirmButton:false,
                            //     showCancelButton:true,
                            //     cancelButtonText:'关闭',
                            //     roundButton:true
                            // });
                            if (row && row.id) {
                              this.selectWeek = this.row.week;
                            }

                            this.sendEmailResult = res.result;
                            this.resultTitle = "发送税单邮件结果：";

                            this.alertVisible = true;
                            //关闭弹窗
                            this.handleQuery()
                          }
                        })
                    })
              }
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                // this.$router.push("/operation/cabinet/Edit?id=" + id);
                // console.log(id);
                // // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/ToPortEdit');
                this.$router.push("/operation/cabinet/ToPortEdit?id=" + id);
                console.log(id);
            },

            handleReset() {
                this.queryForm = {countryTaxType: '2'};
                this.filterForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
        },
        created() {
            this.queryForm.pageType = "1";
            this.queryForm.countryTaxType = "2";
            this.queryForm.isAud = '1';
        },
      computed:{
        buttonShowList(){
          return {
            'fssdyj':this.$btnIsShow('operation/cabinet/PreQingguan','1','发送税单邮件'),
            'glyfssdyj':this.$btnIsShow('operation/cabinet/PreQingguan','1','管理員发送税单邮件'),
            'cksdyj':this.$btnIsShow('operation/cabinet/PreQingguan','1','查看税单邮件'),
            'ckpgyj':this.$btnIsShow('operation/cabinet/PreQingguan','1','查看排柜邮件'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
.msgBox {
  width: 60%;
  height: 70%;
}
</style>
