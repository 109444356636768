<template>
  <div>

      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客户名">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.customer"
                    type="textarea"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="业务员">
                <f-select
                    :dict="'sys_user_id'"
                    :is-need="queryForm.salesmanId"
                    class="textarea_table"
                    v-model="queryForm.salesmanId"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.orderCreateTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.orderCreateTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="费用时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="时长:" label-width="100px">
                <div class="f-flex">
                  <el-input type="number" v-model="queryForm.unpaydayStart"></el-input>
                  <span class="f-p-h-2">~</span>
                  <el-input type="number" v-model="queryForm.unpaydayEnd"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="是否包税:" label-width="100px">
                <div class="f-flex">
                  <f-select :dict="'is_default'" v-model="queryForm.hasTaxType"></f-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleQuery"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
                <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>

      <el-card class="box-card">
        <div>
          <span style="color: red;margin-left: 15px" v-if="Number(totalcny)>0">尚欠CNY:{{totalcny}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalusd)>0">尚欠USD:{{totalusd}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totaleur)>0">尚欠EUR:{{totaleur}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalhkd)>0">尚欠HKD:{{totalhkd}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalgbp)>0">尚欠GBP:{{totalgbp}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totaljpy)>0">尚欠JPY:{{totaljpy}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalaud)>0">尚欠AUD:{{totalaud}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalkrw)>0">尚欠KRW:{{totalkrw}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalcad)>0">尚欠CAD:{{totalcad}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalinr)>0">尚欠INR:{{totalinr}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalnzd)>0">尚欠NZD:{{totalnzd}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalnok)>0">尚欠NOK:{{totalnok}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totaltwd)>0">尚欠TWD:{{totaltwd}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalsgd)>0">尚欠SGD:{{totalsgd}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalvnd)>0">尚欠VND:{{totalvnd}}</span>
          <span style="color: red;margin-left: 15px" v-if="Number(totalcnyTotal)>0">折合CNY:{{totalcnyTotal}}</span>

        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
        >
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';


export default {
  name: "YingshouTJ",
  mixins: [QueryMixins],
  components: {
  },
  props:{
    settlement:{
      type:String
    },
    visible:{
      type:Boolean
    }
  },
  data() {
    return {
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      proVisible: false,
      url: {
        list: "/order/yingshou/yinghsoutongji",


      },
      issalesman: "0",
      columns: [
        {
          type: "selection",
          width: 33,
        },
        {
          type: "index",
          label:'序号',
          width: 33,
        },

        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "totalCount",
          label: "应收",
          width:33
        },
        {
          prop: "noTotalCount",
          label: "无应收",
          width:44
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "unpayday",
          label: "欠款时长(天)",
        },
        {
          prop: "cny",
          label: "尚欠CNY",
        },
        {
          prop: "usd",
          label: "尚欠USD",
        },
        {
          prop: "eur",
          label: "尚欠EUR",
        },
        {
          prop: "hkd",
          label: "尚欠HKD",
        },
        {
          prop: "gbp",
          label: "尚欠GBP",
        },
        {
          prop: "jpy",
          label: "尚欠JPY",
        },
        {
          prop: "aud",
          label: "尚欠AUD",
        },
        {
          prop: "krw",
          label: "尚欠KRW",
        },
        {
          prop: "cad",
          label: "尚欠CAD",
        },
        {
          prop: "inr",
          label: "尚欠INR",
        },
        {
          prop: "nzd",
          label: "尚欠NZD",
        },
        {
          prop: "nok",
          label: "尚欠NOK",
        },
        {
          prop: "twd",
          label: "尚欠TWD",
        },
        {
          prop: "sgd",
          label: "尚欠SGD",
        },
        {
          prop: "vnd",
          label: "尚欠VND",
        },
        {
          prop: "cnyTotal",
          label: "折合CNY",
        },
      ],
      ss:'',
      totalcny : 0,
      totalusd : 0,
      totaleur : 0,
      totalhkd : 0,
      totalgbp : 0,
      totaljpy : 0,
      totalaud : 0,
      totalkrw : 0,
      totalcad : 0,
      totalinr : 0,
      totalnzd : 0,
      totalnok : 0,
      totaltwd : 0,
      totalsgd : 0,
      totalvnd : 0,
      totalcnyTotal: 0,
    };
  },
  watch: {
    visible(val) {
      if(val && this.settlement) {
        this.$set(this.queryForm,'settlement',this.settlement);
       this.handleQuery();
      } else {

      }
    }
  },
  created() {


  },
  methods: {
    handleSelectionChange(selection) { // 多选
      let arr = [];
      this.totalcny=0;
      this.totalusd=0;
      this.totaleur=0;
      this.totalhkd=0;
      this.totalgbp=0;
      this.totaljpy=0;
      this.totalaud=0;
      this.totalkrw=0;
      this.totalcad=0;
      this.totalinr=0;
      this.totalnzd=0;
      this.totalnok=0;
      this.totaltwd=0;
      this.totalsgd=0;
      this.totalvnd=0;
      this.totalcnyTotal=0;
      this.selectedRowOrderNos=[]
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        let obj = selection[i];
        this.caculateColumn(obj,'cny','totalcny');
        this.caculateColumn(obj,'usd','totalusd');
        this.caculateColumn(obj,'eur','totaleur');
        this.caculateColumn(obj,'hkd','totalhkd');
        this.caculateColumn(obj,'gbp','totalgbp');
        this.caculateColumn(obj,'jpy','totaljpy');
        this.caculateColumn(obj,'aud','totalaud');
        this.caculateColumn(obj,'krw','totalkrw');
        this.caculateColumn(obj,'cad','totalcad');
        this.caculateColumn(obj,'inr','totalinr');
        this.caculateColumn(obj,'naz','totalnzd');
        this.caculateColumn(obj,'nok','totalnok');
        this.caculateColumn(obj,'twd','totaltwd');
        this.caculateColumn(obj,'sgd','totalsgd');
        this.caculateColumn(obj,'vnd','totalvnd');
        this.caculateColumn(obj,'cnyTotal','totalcnyTotal');
      }
      //ss开始拼接
      this.selectionRows = selection
      this.selectedRowKeys = [...arr];
      console.log('this', this)

    },
    caculateColumn(obj,columnName,total){

      let cny=obj[columnName];
      if (cny){
        this[total]+=cny;
      }

    },
    handleClose() {
      this.$emit("ok");

    },
    daochu(){
      this.downloadExcel(this.url.daochu, "应收回款率", this.queryForm);
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
