<template>
    <el-dialog
            center
            width="30%"
            :close-on-click-modal="false"
            :before-close="handleClose"
            :visible.sync="visible"
            :title="row.id ? '编辑用户' : '新增用户'"
    >
        <div class="content-wrap c-slim-scrollbar">
            <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="username" label="用户名" :rules="rules.username">
                            <el-input v-model="form.username" maxlength="20" show-word-limit
                                      :disabled="row.username && row.username.length>0"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="sex" label="性别" :rules="rules.sex">
                            <el-radio-group v-model="form.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="realname" label="中文名" :rules="rules.realname">
                            <el-input v-model="form.realname" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="englishName" label="英文名" :rules="rules.englishName">
                            <el-input v-model="form.englishName" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="phone" label="手机" :rules="rules.phone">
                            <el-input v-model="form.phone" maxlength="15" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="email" label="邮箱" :rules="rules.email">
                            <el-input v-model="form.email" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item prop="password" label="密码" :rules="form.id?[]:rules.password">
                            <el-tooltip class="item" effect="dark" content="新增时默认密码：forest123" placement="right" :disabled="!newOrEdit">
                            <el-input type="password" v-model="form.password" maxlength="50" show-word-limit
                                      autocomplete="new-password" :disabled="newOrEdit"></el-input>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="reppassword" label="确认密码" :rules="rules.reppassword">
                            <el-tooltip class="item" effect="dark" content="新增时默认密码：forest123" placement="right" :disabled="!newOrEdit">
                            <el-input type="password" v-model="form.reppassword" maxlength="50" show-word-limit
                                      autocomplete="new-password" :disabled="newOrEdit"></el-input>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                  <el-col :span="24">
                    <el-form-item prop="area" label="区域" >
                      <el-input v-model="form.area" maxlength="2" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col :span="24">
                        <el-form-item prop="leader" label="直属领导" :rules="rules.leader">
                            <f-select :dict="'sys_user_id'"
                                      v-model="form.leader"
                                      :isNeed="form.leader"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="outletsName" label="网点" >
                            <f-select dict="sys_area_kingdee" v-model="form.outletsCode" :is-need="form.outletsCode" @changet="getOutSiteName"></f-select>

                        </el-form-item>
                    </el-col>


                    <!--                    <el-col :span="24">-->
                    <!--                        <el-form-item prop="qq" label="QQ">-->
                    <!--                            <el-input v-model="form.qq" maxlength="15" show-word-limit></el-input>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item prop="departIds" label="所属部门" :rules="rules.departIds">
                            <tree-dropdown v-model="form.departIds" :data="options" @input="changeDepart"></tree-dropdown>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="selectedRoles" label="所属角色" :rules="rules.selectedRoles">
                            <el-select
                                    filterable
                                    multiple
                                    class="f-full-width"
                                    v-model="form.selectedRoles"
                                    placeholder="请选择"
                            >
                                <el-option
                                        v-for="item in roleOptions"
                                        :key="item.id"
                                        :label="item.roleName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="buttonShowList.bj && isShowOtherDepartFlag ">
                        <el-form-item  label="是否属于其他销售组" label-width="140px">
                            <el-radio-group v-model="form.isOtherSales">
                                <el-radio :label="'0'">否</el-radio>
                                <el-radio :label="'1'">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="isTest" label="是否测试" :rules="rules.isTest">
                            <el-radio-group v-model="form.isTest">
                                <el-radio :label="'1'">测试</el-radio>
                                <el-radio :label="'0'">正常</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="drawer_footer">
            <el-button plain size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit" :loading="btnLoading">确定</el-button>
        </div>
    </el-dialog>


</template>

<script>
    import {getAction, httpAction} from '@/api/manage'
    import {mobilePattern} from '@/utils/pattern'
    import TreeDropdown from './TreeDropdown'
    import {yingwenPattern, zhognwenPattern} from "../../../utils/pattern";
    import {updatedDict} from "../../../utils/util";


    export default {
        name: 'AddUserDrawer',
        components: {
            TreeDropdown
        },
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            var validatePass2 = (rule, value, callback) => {
                if (!this.form.password) {
                    callback();
                } else if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.form.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                isShowOtherDepartFlag: true,
                salesDepartList:[],
                salesDepartMap:{},
                newOrEdit:false,
                btnLoading: false,
                options: [],
                roleOptions: [],
                form: {

                },
                rules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'change'},
                        // {pattern: /^[A-Za-z0-9_.]+$/, message: "用户名只能包含英文、数字、小数点、下划线", trigger: 'change'},
                    ],
                    sex: [
                        {required: true, message: '选择性别', trigger: 'change'},
                    ],
                    realname: [
                        {required: true, message: '请输入中文名', trigger: 'change'},
                        {pattern: zhognwenPattern, message: '中文名只能由中文组成', trigger: 'change'}
                    ],
                    leader: [
                        {required: true, message: '请选择直属领导', trigger: 'change'},
                    ],

                    englishName: [
                        {required: true, message: '请输入英文名', trigger: 'change'},
                        // {pattern: yingwenPattern, message: "请输入合法的英文名！", trigger: 'change'}
                    ],
                    // phone: [
                    //     {required: true, message: '请输入手机号', trigger: 'change'},
                    //     {pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'change'},
                    // ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'change'},
                        {type: 'email', message: '请输入正确的邮箱', trigger: 'change'},
                    ],
                    // qq: [
                    //     {required: true, message: '请输入QQ', trigger: 'change'},
                    //     {pattern: /^[0-9]+$/, message: "QQ只能由数字组成！", trigger: 'change'}
                    // ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {
                            pattern: /(^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$)/,
                            message: "请输入6-20位至少包含数字和字母的密码！",
                            trigger: 'blur'
                        }
                    ],
                    reppassword: [
                        {validator: validatePass2, required: true, trigger: 'blur'}
                    ],
                    departIds: [
                        {required: true, message: '请选择所属部门', trigger: 'change'},
                    ],
                    selectedRoles: [
                        {required: true, message: '请选择所属角色', trigger: 'change'},
                    ],
                    isTest: [
                        {required: true, message: '请选择是否测试账号', trigger: 'change'},
                    ],
                },
                url: {
                    add: '/sys/user/add',
                    edit: '/sys/user/edit',
                    getDepartList:'/sys/sysDepart/querySalesGroupDepartList',
                },
                departMap: {},
                roleMap: {},
            }
        },
        watch: {
            visible(nval) {
                console.log('nval',nval)
                nval && this.handleReset()
            }
        },
        created() {
            this.getDepartList()
            //页面已加载就加载部门数据字典

        },
        mounted() {
            this.handleGetDepartData()
            this.handleGetRoleData()
        },
        methods: {
            //初始化组别列表
            getDepartList(){
                getAction(this.url.getDepartList).then(res=>{
                    this.salesDepartList = res.result
                    this.salesDepartMap = this.listToMap(this.salesDepartList,"value")
                })
            },
            changeDepart(val){
                this.isShowOtherDepartFlag = true
                if(val in this.salesDepartMap){
                    this.isShowOtherDepartFlag = false
                    this.form.isOtherSales = '0'
                }
            },
            getOutSiteName(val){
                this.form.outletsName = val;
            },
            /*获取选中的角色名字*/
            getRoleMap(list) {
                // console.log('角色列表', list);
                this.roleMap = this.listToMap(list, 'id');
            },
            //获取选中的角色名子

            //将集合转为map
            listToMap(list, name) {
                var map = {};
                for (var index in list) {
                    map[list[index][name]] = list[index];
                }
                return map;
            },
            getDepartMap(list) {
                // console.log('树结构', list);
                let departIdlist = [];
                if (list) {
                    list.forEach(m => {
                        departIdlist.push(m);
                        if (m.children && m.children.length > 0) {
                            m.children.forEach(b => {
                                departIdlist.push(b);
                                if (b.children && b.children.length > 0) {
                                    b.children.forEach(g => {
                                        departIdlist.push(g);
                                    })
                                }
                            })
                        }
                    })
                    // console.log('部门的集合', departIdlist);
                    this.departMap = this.listToMap(departIdlist, 'id');
                    // console.log('部门的map', this.departMap);
                }
            },
            handleReset() {
                console.log('进入reset')
                this.newOrEdit = false
                for (let item in this.row) {
                    this.$set(this.form, item, this.row[item])
                }

                console.log('变态的邀请码', this.form, this.row.username)
                if (this.row && this.row.id) {
                    let arr = []
                    let list = this.row.roles;
                    if (list) {
                        for (let i = 0; i < list.length; i++) {
                            arr.push(list[i]['roleId'])
                        }
                    }
                    this.form.selectedRoles = arr
                }else{
                    this.form.password = 'forest123'
                    this.form.reppassword = 'forest123'
                    this.newOrEdit = true
                }

                this.$nextTick(() => {
                    // this.$refs.form.clearValidate()
                    this.$refs.form.clearValidate()
                })
            },
            handleGetDepartData() {
                getAction('/sys/sysDepart/queryTreeList').then(res => {
                    this.options = res.result;
                    this.getDepartMap(this.options);
                })
            },
            handleGetRoleData() {
                getAction('/sys/role/queryall').then(res => {
                    this.roleOptions = res.result;
                    this.getRoleMap(this.roleOptions);
                })
            },
            handleSubmit() {
                let url = this.url.add
                let method = 'POST'
                if (this.row.id) {
                    url = this.url.edit
                    method = 'PUT'
                    this.form.id = this.row.id
                }
                let that = this;
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.btnLoading = true;
                        const params = {
                            ...this.form,
                            selectedRoles: this.form['selectedRoles'].join(',')
                        }

                        console.log(params)

                        let roleStr = '';
                        that.form.selectedRoles.map(role => {
                            roleStr += that.roleMap[role]['roleName'] + ",";
                        })


                        let depStr = '';
                        // console.log('depids',this.form.departIds);

                        // this.form.departIds.map(role=>{
                        //     depStr += this.departMap[role]['departName'] + ",";
                        // })
                        if (that.form.departIds && that.departMap[that.form.departIds]){
                            depStr += that.departMap[that.form.departIds]['departName'] + ",";

                            params.depStr = depStr;
                        }
                        //判断id 是否是自己，如果是自己，把存在localstorage的userinfo更新
                        if (method == 'PUT') {
                            let ss = localStorage.getItem("USERINFO");
                            var user = JSON.parse(ss);
                            if (user && user.id === this.form.id) {
                                user.roleName = roleStr;
                                user.deps = depStr;
                                localStorage.setItem('USERINFO',JSON.stringify(user))
                            }

                        }
                        params.roleStr = roleStr;
                        that.$store.state.dict['salesmanId'] = null;
                        that.$store.state.dict['salesman'] = null;
                        that.$store.state.dict['operations'] = null;
                        //同时清空sessionstorage的
                      updatedDict('sys_work_binding,doc,docId,operations,channel,channelId,billman_id,scqd_id,ocean_salesman_id,sys_create_by,all_user_id,sys_creator_id')

                        /*判断选择的部门是否包含销售或者操作，删除对应前端的缓存*/
                        // let departIds = this.form.departIds;
                        // let roleIds = this.form.selectedRoles;
                        // if (departIds){

                        //
                        //         let text = this.departMap[departIds]['departName'];
                        //         console.log('选择的部门名',text);
                        //         if (text.indexOf('销售')!==-1){
                        //
                        //             //存到前端页面缓存中
                        //             this.$store.state.dict['salesmanId'] = null;
                        //             this.$store.state.dict['salesman'] = null;
                        //
                        //             //同时清空sessionstorage的
                        //             localStorage.removeItem("dict:" + 'salesmanId');
                        //             localStorage.removeItem("dict:" + 'salesman');
                        //         }
                        //         if (text.indexOf('操作')!==-1){
                        //             //存到前端页面缓存中
                        //             this.$store.state.dict['operations'] = null;
                        //             //同时清空sessionstorage的
                        //             localStorage.removeItem("dict:" + 'operations');
                        //         }
                        //
                        // }
                        // if (roleIds){
                        //     for (var index in roleIds){
                        //         let text = this.roleMap[roleIds[index]]['roleName'];
                        //         console.log('选择的角色名',text);
                        //         if (text.indexOf('销售')!==-1){
                        //
                        //             //存到前端页面缓存中
                        //             this.$store.state.dict['salesmanId'] = null;
                        //             this.$store.state.dict['salesman'] = null;
                        //
                        //             //同时清空sessionstorage的
                        //             localStorage.removeItem("dict:" + 'salesmanId');
                        //             localStorage.removeItem("dict:" + 'salesman');
                        //         }
                        //         if (text.indexOf('操作')!==-1){
                        //             //存到前端页面缓存中
                        //             this.$store.state.dict['operations'] = null;
                        //             //同时清空sessionstorage的
                        //             localStorage.removeItem("dict:" + 'operations');
                        //         }
                        //     }
                        // }




                        httpAction(url, method, params).then((res) => {
                            that.$message({
                                type: 'success',
                                message: res.message
                            })
                            if (res.success) {
                                that.handleClose()
                                that.$emit('ok');
                                that.btnLoading = false;
                            }
                            that.btnLoading = false;
                        }).catch(ree => {
                            that.btnLoading = false;
                        })
                    }
                })
            },
            handleClose() {
                this.btnLoading = false;
                this.$refs.form.resetFields();
                this.$set(this, 'form', {});
                this.$emit('update:visible', false)
            }
        },
      computed:{
        buttonShowList() {
          return {
            'bj':this.$btnIsShow('isystem/DepartmentManage', '0', '是否属于其他销售组'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    .drawer_footer {
        text-align: right;
    }
</style>
