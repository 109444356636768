<template>
  <el-dialog
    title="编辑备注"
    :visible="visible"
    width="50%"
    custom-class="c-custom-dialog"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form
      class="addEditBox"
      :model="form"
      :rules="rules"
      ref="form"
      size="medium"
    >
      <!-- 提货地址信息-->
      <el-row class="flex_center" :gutter="30">
        <el-col :span="24">
          <el-form-item
            prop="notes"
            label="备注"
            key="contactId"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.notes"
              maxlength="250"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>


      </el-row>


    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import { validateCtn } from "@/utils/validator";

export default {
  name: "editNotesDialog",
  props: {
    visible: {
      type: Boolean,
    },
    selectedOrderNos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      formLabelWidth: "110px",
      rules: {
        notes: [
          { required: true, message: "请填写备注", trigger: "change" },
        ],
      },
      url: {
        updateTtOrderExtNotes:"order/sale/SalesOrderDetails/updateTtOrderExtNotes",
      },

    };
  },
  watch: {
    visible(val) {
    },
  },
  methods: {

    handleSubmit() {

      this.$refs["form"].validate((valid) => {
        if (valid) {
          postAction(this.url.updateTtOrderExtNotes, {orderNos:this.selectedOrderNos,notes:this.form.notes}).then((res) => {
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.$emit("ok");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
}
.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
