<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品名称">
                            <el-input
                                    v-model="queryForm.cname"
                                    placeholder="中文/英文"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品编码">
                            <el-input v-model="queryForm.hscode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家">
                            <f-select
                                    v-model="queryForm.country"
                                    :isNeed="queryForm.country"
                                    :dict="'bd_country'"
                                    placeholder="请选择"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-download"
                        @click="handleExportXls('敏感产品')"
                        v-if="buttonShowList.dc"
                >导出
                </el-button
                >
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
                <span
                        class="stress f-m-l-12 f-cursor-pointer"
                        @click="handleClearSelection"
                >清空</span
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="62vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleApproval(scope.row)"
                                   v-if="buttonShowList.backN"
                        >返回正常库
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'taxRate'">
                        <span>{{ showRate(scope.row.taxRate) }}</span>
                    </template>
                  <template v-else-if="scope.column.property === 'tag'">
                    <span v-if="scope.row.tag === '0'">未备案</span>
                    <span v-else>已备案</span>
                  </template>
                  <template v-else-if="scope.column.property === 'sense'">
                    <span v-if="scope.row.sense === '0'">否</span>
                    <span v-else>否</span>
                  </template>
                  <template v-else-if="scope.column.property === 'section301'">
                    <span v-if="scope.row.section301 === '0'">否</span>
                    <span v-else>否</span>
                  </template>
                    <template v-else-if="scope.column.property === 'username'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.username}}</a>
                    </template>
                  <template v-else-if="scope.column.property === 'brandType'">
                    <span>{{ parseDict(scope.row.brandType, "brand_type") }}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'exportBenefits'">
                    <span>{{ parseDict(scope.row.exportBenefits, "export_benefits") }}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {putAction} from "@/api/manage";
    import QueryMixins from "../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "SensitiveProducts",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                dictCodes: ["is_record", "is_sense", "is_default", "brand_type","export_benefits"],
              columns: [
                {
                  type: "selection",
                  width: 33,
                },
                {
                  prop: "action",
                  label: "操作",
                },
                // {
                //     prop: "username",
                //     label: "客户名称",
                //     sortable: "custom",
                // },
                {
                  prop: "cname",
                  label: "中文名",
                  sortable: "custom",

                },
                {
                  prop: "ename",
                  label: "英文名",
                  sortable: "custom",

                },

                {
                  prop: "country",
                  label: "国家",
                  sortable: "custom",
                },
                {
                  prop: "hscode",
                  label: "海关编码",
                  sortable: "custom",
                },
                  {
                      prop: "addtionalCode",
                      label: "301编码",
                  },
                {
                  prop: "taxRate",
                  label: "税率",
                  sortable: "custom",

                },

                {
                  prop: "materialRate",
                  label: "材质比例",


                },

                {
                  prop: "demand",
                  label: "清关要求",
                },
                {
                  prop: "material",
                  label: "材质",
                  sortable: "custom",
                },
                {
                  prop: "purpose",
                  label: "用途",
                },
                {
                  prop: "remark",
                  label: "备注",
                  sortable: "custom",
                },
                {
                  prop: "maker",
                  label: "厂家",
                  sortable: "custom",
                },
                {
                  prop: "makerAdd",
                  label: "厂家地址",
                },
                {
                  prop: "brand",
                  label: "品牌",
                },
                {
                  prop: "model",
                  label: "产品型号",
                },
                {
                  prop: "size",
                  label: "产品尺寸",
                  sortable: "custom",
                },
                {
                  prop: "brand",
                  label: "品牌",
                  sortable: "custom",
                },
                {
                  prop: "brandType",
                  label: "品牌类型",
                },
                {
                  prop: "exportBenefits",
                  label: "出口享惠情况",

                },
                {
                  prop: "recordTime",
                  label: "备案时间",
                  sortable: "custom",

                },
                {
                  prop: "recorder",
                  label: "备案人",
                  sortable: "custom",

                },
                {
                  prop: "tag",
                  label: "备案状态",
                  sortable: "custom",
                },
                {
                  prop: "section301",
                  label: "是否在301",
                  sortable: "custom",

                },
                {
                  prop: "sense",
                  label: "是否敏感",
                  sortable: "custom",
                },

              ],
                url: {
                    list: "/customer/product/list",
                    exportXlsUrl: "/customer/product/exportXls",
                    tuihui: "/customer/product/qxSense/",
                    tuihuiBatch: "/customer/product/qxSenseBatch",
                },
                isUsePost:true
            };
        },
        methods: {
          getIsHegui(){
            let str= localStorage.getItem('USERINFO');
            if (!str){
              return false;
            }
            let obj = JSON.parse(str);
            if (!obj){
              return false;
            }
            if (obj.depStr ==='合规部'){
              return true;
            }
            if (obj.username === 'admin') {
              return true;
            }
            return false;
          },

            showRate(val) {
                if (val == null) {
                    return "";
                }
                if (val && val.indexOf("%") !== -1) {
                    return val;
                } else if (val) {
                    return val + "%";
                }
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.sense = "1";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleApproval({id, cname}) {
                this.$confirm(`确定将【${cname}】返回正常库?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(this.url.tuihui + id, {id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    })
                    .catch(() => {
                    });
            },
        },
        created() {
            this.queryForm.sense = "1";
            this.queryForm.tag = "1";
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('conformance/SensitiveProducts','1','导出'),
            'backN':this.$btnIsShow('conformance/SensitiveProducts','1','返回正常库'),

          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .el-form-item {
        margin-bottom: 2px;
    }
</style>
