<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="单号">
                                <el-input v-model="queryForm.orderNo" type="textarea" placeholder="多个单号请输入完整单号" @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="国家">
                                <f-select :dict="'bd_country'" v-model="queryForm.countryList" :isNeed="queryForm.countryList"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="渠道名称">
                                <f-select :dict="'transportStatus'" v-model="queryForm.channelIdList" :isNeed="queryForm.channelIdList"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="出库时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.outTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.outTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="到仓时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.arriveTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.arriveTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                            <el-form-item label="订单时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.orderCreateTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.orderCreateTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="出库仓库">
                                <el-input v-model="queryForm.warehouse"  @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'出库记录')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
<!--                    <template v-if="scope.column.property === 'action'">-->
<!--                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
<!--                    </template>-->
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "WarehouseOutStockRemark",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,
                formLabelWidth: "120px",

                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "warehouseName",
                        label: "出库仓库",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "predictionCtnStr",
                        label: "预计件重体",
                    },
                    {
                        prop: "realCtnStr",
                        label: "实际件重体",
                    },
                    {
                        prop: "receiveCountry",
                        label: "国家+邮编",
                    },
                    {
                        prop: "outTypeStr",
                        label: "出库类型",
                    },
                    {
                        prop: "salesman",
                        label: "业务员",
                    },
                    {
                        prop: "doc",
                        label: "客服",
                    },
                    {
                        prop: "operator",
                        label: "排柜操作",
                    },
                    {
                        prop: "outTime",
                        label: "出库时间",
                    },
                    {
                        prop: "arriveTime",
                        label: "入库时间",
                    },
                    {
                        prop: "createTime",
                        label: "到仓时间",
                    },
                    {
                        prop: "orderCreateTime",
                        label: "订单时间",
                    },


                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/warehouseStock/list",

                    //导出excel的接口
                    exportExcelUrl: '/file/export/excel/downloadWarehouseOutRecordExcel',
                },
                initData:{
                    inOrOut: '2'
                },

            };
        },
        methods: {

        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/WarehouseOutStockRecord','0','导出'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
