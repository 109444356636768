<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="90px">
    <el-tabs class="forest_tab" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="中文配置" name="zh">
        <el-form-item label="公司简介" prop="introduction">
          <quill-editor ref="text" v-model="form.introduction" class="myQuillEditor" :options="quillOption" />
        </el-form-item>
        <el-form-item label="图片或视频" prop="introductionFileList">
          <f-upload v-model="form.introductionFileList" :isUseOss="true" list-type="picture-card" :isCustomPice="true" :limit="1" accept="image/*,video/*" :fileSizeLimit="100"></f-upload>
          <div>建议尺寸： 576*324</div>
        </el-form-item>
        <div class="f-text-right">
          <el-button
              type="primary"
              size="medium"
              icon="el-icon-s-promotion"
              @click="handleSubmit"
              v-if="buttonShowList.bc"
          >保存</el-button>
          <el-button size="medium" plain @click="hadleReset()">重置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="英文配置" name="en">
        <el-form-item label="公司简介" prop="introductionEn">
          <quill-editor ref="text" v-model="form.introductionEn" class="myQuillEditor" :options="quillOption" />
        </el-form-item>
        <el-form-item label="图片或视频" prop="introductionEnFileList">
          <f-upload v-model="form.introductionEnFileList" :isUseOss="true" list-type="picture-card" :isCustomPice="true" :limit="1" accept="image/*,video/*" :fileSizeLimit="100"></f-upload>
          <div>建议尺寸： 576*324</div>
        </el-form-item>
        <div class="f-text-right">
          <el-button
              type="primary"
              size="medium"
              icon="el-icon-s-promotion"
              @click="handleSubmit"
              v-if="buttonShowList.bc"
          >保存</el-button>
          <el-button size="medium" plain @click="hadleReset()">重置</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>
<script>
import quillConfig from "../../utils/quill-config";
import { quillEditor } from "vue-quill-editor";
import { getAction, postAction } from '@/api/manage'
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      url: {
        list: '/sys/owCompanyIntroduction/getOwCompanyIntroduction',
        update: '/sys/owCompanyIntroduction/saveOrUpdateCompanyIntroduction'
      },
      activeName: 'zh',
      form: {},
      formInit: {},
      quillOption: quillConfig,
      rules: {
        introduction: [
          { required: true, message: '请输入公司简介', trigger: "blur" },
        ],
        introductionEn: [
          { required: true, message: '请输入公司简介', trigger: "blur" },
        ],
        introductionFileList: [
          { required: true, message: '请选择图片或视频', trigger: "change" },
        ],
        introductionEnFileList: [
          { required: true, message: '请选择图片或视频', trigger: "change" },
        ],
      },
      checkKey: {
        zh: ['introduction', 'introductionFileList'],
        en: ['introductionEn', 'introductionEnFileList']
      }
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      this.$store.commit("setFullscreenLoading", true);
      getAction(this.url.list).then(res => {
        this.$store.commit("setFullscreenLoading", false);
        if(res.result == null){
          this.initForm();
        }else{
          this.form = res.result;
          this.form.introductionFileList = [this.form.introductionFile];
          this.form.introductionEnFileList = [this.form.introductionEnFile];
        }
        this.formInit = JSON.parse(JSON.stringify(this.form));
      })
        .catch((err) => {
          this.$store.commit("setFullscreenLoading", false);
        });
    },
    initForm(){
      this.form = {
        introduction:"",
        introductionFileList:[],
        introductionEnFileList:[],
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSubmit() {
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          this.$store.commit("setFullscreenLoading", true);
          console.log(this.form.introductionFileList, 'introductionEnFileList')
          let params = {
            id: this.form.id,
            introduction: this.form.introduction,
            introductionEn: this.form.introductionEn,
            introductionUrl: this.form.introductionFileList[0].url,
            introductionUrlEn: this.form.introductionEnFileList[0].url,
            introductionUrlId: this.form.introductionFileList[0].id,
            introductionUrlEnId: this.form.introductionEnFileList[0].id,
          };
          postAction(this.url.update, params)
            .then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.getInit();
              if(res.success){
                this.$message.success(res.message)
              }

            })
            .catch((err) => {
              this.$store.commit("setFullscreenLoading", false);
            });
        } else {
          let tabIsCheck = true; //当前页面是都否校验通过
          Object.keys(object).forEach((item, index) => {
            if (this.checkKey[this.activeName].indexOf(item) !== -1) {
              tabIsCheck = false;
            }
          })
          if (tabIsCheck) {
            let message = this.activeName == 'en' ? '请填写对应的中文配置' : '请填写对应的英文配置';
            this.$message.warning(message);
          }
        }
      });
    },
    hadleReset() {
      this.form = JSON.parse(JSON.stringify(this.formInit));
    }
  },
  computed:{
    buttonShowList() {
      return {
        'bc':this.$btnIsShow('forestMarket/CompanyProfile', '0', '保存'),
      }
    }
  }
};
</script>