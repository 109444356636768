<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <!-- <el-card class="box-card"> -->
            <el-row>
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="客户名称">-->
                <!--                        <el-input v-model="queryForm.username"></el-input>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="联系电话">-->
                <!--                        <el-input v-model="queryForm.phone"></el-input>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="业务员">-->
                <!--                        <f-select v-model="queryForm.salesmanId" :dict="salesmanDict"></f-select>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label-width="30px">-->
                <!--                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>-->
                <!--                        <el-button plain @click="handleReset">重置</el-button>-->
                <!--                        <el-button type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="添加人">-->
                <!--                        <f-select v-model="queryForm.createBy" :dict="userDict"></f-select>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
            </el-row>
            <!-- </el-card> -->
        </el-form>
        <el-card class="box-card">
            <!-- <div class="f-p-b-15"></div> -->
            <!--        <div class="c-alert f-m-b-10">-->
            <!--            已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项-->
            <!--            <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>-->
            <!--        </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="79vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <!--                <template v-if="scope.column.property === 'status'">-->
                    <template v-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status === 'CREATED'">新建</span>
                        <span v-if="scope.row.status === 'ASSIGNED'">已分配</span>
                        <span v-if="scope.row.status === 'SUSPENDED'">已冻结</span>
                        <span v-if="scope.row.status === 'COMPLETED'">已完成</span>
                        <span v-if="scope.row.status === 'CANCELLED'">已取消</span>
                        <span v-if="scope.row.status === 'DELETED'">已删除</span>
                    </template>

                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleApproval(scope.row)"
                        >审批
                        </el-button>
                        <!--                        <el-button type="text" @click="handleDeal(scope.row)"-->
                        <!--                        >处理-->
                        <!--                        </el-button-->
                        <!--                        >-->
                        <el-button type="text" @click="handleHistory(scope.row)"
                        >历史
                        </el-button
                        >
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog
                title="锁单流程申请"
                :visible.sync="diaFormlockActivity"
        >
            <el-form ref="addCabinetForm">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>订单信息</span>
                    </div>
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="订单号：" :label-width="formLabelWidth" prop="week">
                                {{activityForm.businessKey}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="业务员：" :label-width="formLabelWidth" prop="week">
                                <!--                                <f-select v-model="activityForm.salesmanId" :dict="'sys_user'"></f-select>-->
                                {{activityForm.salesman}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="应收账单金额：" :label-width="formLabelWidth" prop="week">
                                <!--                            <a v-for="(item, i) in receivableList" style="margin-left: 20px;">{{item.moneyType}}:{{item.total}}</a>-->
                                <template v-for="(item, i) in receivableList" v-if="payList!=receivableList">
                                    <a style="margin-left: 10px;">{{item.moneyType}}:{{item.total}}</a>
                                    <br v-if="i!= 0 && i%2==0">
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="应付账单金额：" :label-width="formLabelWidth" prop="week">
                                <template v-for="(item, i) in payList" v-if="payList!=null">
                                    <a style="margin-left: 10px;">{{item.moneyType}}:{{item.total}}</a>
                                    <br v-if="i!= 0 && i%2==0">
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="账单利润差:" :label-width="formLabelWidth" prop="week"
                                      v-if="osContainerSettle1 !=null">
                            利润：{{osContainerSettle1.profit}} RMB
                        </el-form-item>
                    </el-row>
                </el-card>
                <el-row style="width: 100%">
                    <el-card class="box-card" style="width: 100%">
                        <div slot="header" class="clearfix" s>
                            <span>审批流转意见</span>
                        </div>
                        <template v-for="(item, index) in commentList">
                            <div v-if="index == 0" class="text item">
                                <a>{{item.userId}}：{{item.fullMessage}} </a>
                            </div>
                            <div v-if="index > 0" class="text item" style="margin-top: 10px !important;">
                                <a>{{item.userId}}：{{item.fullMessage}} </a>
                            </div>
                        </template>
                    </el-card>
                </el-row>

                <el-row style="width: 100%">
                    <el-card class="box-card" style="width: 100%">
                        <div slot="header" class="clearfix" s>
                            <span>当前节点审批意见</span>
                        </div>
                        <div>
                            <el-input type="textarea" v-model="activityForm.remark" rows="5"></el-input>
                        </div>
                    </el-card>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button @click="diaFormlockActivity = false"
                >取 消
                </el-button
                >
                <el-button type="primary" @click="handleDeal">同意</el-button>
            </div>
        </el-dialog>

        <component :ref="dynamic" :is="dynamic"
                   :processDialogData="processDialogData" @handleDeal="handleDeal"
                   @handleCancel="cancelStartProcess" @comMounted="comMounted"
                   @handleDialogClose="cancelStartProcess"
        ></component>

    </div>
</template>

<script>
    import {getAction, deleteAction} from "@/api/manage";
    import Mixins from "@/mixins/Mixins.js";
    import QueryMixins from "@/mixins/QueryMixins.js";

    export default {
        name: "Undetermined",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                rules: {
                    instanceName: [
                        {required: true, message: "请输入实例名称", trigger: "change"},
                    ],
                    instanceVariable: [
                        {required: true, message: "请输入实例描述", trigger: "change"},
                    ],
                },
                namelist: [],
                row: {},
                ids: [],
                tableData: [],
                formLabelWidth: "120px",
                visible: false,
                assignVisible: false,
                diaFormlockActivity: false,
                activityForm: {},
                assignTitle: "",
                salesmanlist: [],
                commentList: [],
                userlist: [],
                selectRow: [],
                payList: [],
                receivableList: [],
                osContainerSettle1: {},

                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "instanceName",
                        label: "流程名称",
                        sortable: "instanceName",
                    },
                    {
                        prop: "name",
                        label: "任务节点名称",
                        sortable: "name",
                    },
                    {
                        prop: "status",
                        label: "任务状态",
                        sortable: "status",
                    },
                    {
                        prop: "initiator",
                        label: "流程发起人",
                        sortable: "initiator",
                    },
                    {
                        prop: "assignee",
                        label: "当前办理人",
                        sortable: "assignee",
                    },
                    {
                        prop: "createdDate",
                        label: "创建时间",
                        sortable: "createdDate",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 300,
                    },
                ],
                url: {
                    list: "/activiti/getTasks",
                    FlowImgByInstanceId: "/activiti/FlowImgByInstanceId",
                    deal: "/activiti/operationActiviti/DealInstanceId",
                    lockActivity: '/operation/container/lockActivity',
                    getComment: '/activiti/operationActiviti/getComment'
                },
                dynamicPath: '',  //动态组件
                dynamic: '', //动态组件
                isDynamic: false, //动态组件是否存在
                processDialogData: {},  //弹窗组件的传递数据
            };
        },
        created() {
            // this.initList();
        },
        computed: {
            // 动态加载对应的组件
            loaderDynamic() {
                return () => import('./components/' + this.dynamicPath)
            }
        },
        methods: {
            // 动态加载组件，components为组件的名称，所有的组件需要放在isystem/activity/components中
            initDynamic(components) {
                if (this.dynamicPath == components && this.isDynamic) {
                    this.comMounted()
                    return
                }
                this.dynamicPath = components;
                const self = this;
                self.loaderDynamic().then(() => {
                    // 动态加载模板
                    self.dynamic = () => self.loaderDynamic();
                    self.isDynamic = true;
                }).catch((err) => {
                    self.isDynamic = false;
                    this.$message.warning('模板组件不存在');
                    console.log('模板不存在', err)
                    // 模板不存在404时处理
                })
            },
            // 动态组件加载完成
            comMounted() {
                this.$refs[this.dynamic].visible = true;
                this.selectRow.actionType="deal"
                this.$refs[this.dynamic].initData(this.selectRow);
            },
            //初始化列表
            initList() {
                console.log("wojinlai1l")

                getAction(this.url.list).then((res) => {
                    if (res.success) {
                        console.log(res.result)
                        const {records, total} = res.result
                        this.tableData = records || res.result;
                        this.ipagination.total = res.result.total
                    }
                });
            },
            handleSelectionChange(selection) {
                // 多选
                // let arr = [];
                // this.namelist = [];
                // for (let i = 0; i < selection.length; i++) {
                //     arr.push(selection[i]['id'])
                //     this.namelist.push(selection[i]['username'])
                // }
                // this.selectionRows = selection
                // this.selectedRowKeys = [...arr]
            },
            handleTableChange({prop, order}) {
                if (order === null) {
                    this.handleResetSorter();
                } else {
                    this.$set(this.isorter, "prop", prop);
                    this.$set(this.isorter, "order", order);
                }
                this.handleSearch();
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1;
                this.ipagination.pageSize = val;
                this.handleQuery();
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val;
                this.handleQuery();
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleResetFilter() {
                // 清空表格过滤器
                this.$refs.GTable.handlePureClear();
                this.filterForm = {};
            },
            handleApproval(row) {
                this.selectRow = row;
                this.activityForm = JSON.parse(JSON.stringify(row));
                this.initDynamic(row.processDefinitionKey);
                // getAction(this.url.lockActivity, {orderNo: row.businessKey}).then((res) => {
                //     if (res.success) {
                //         console.log(res)
                //         this.payList = res.result.payList
                //         this.receivableList = res.result.receivableList
                //         this.osContainerSettle1 = res.result.osContainerSettle1
                //         this.activityForm.salesman = res.result.osOrder && res.result.osOrder.salesman ? res.result.osOrder.salesman : null
                //         this.$set(this.processDialogData, 'form', this.activityForm);
                //     } else {
                //         this.$message.error("暂未找到该订单号的费用，请确定是否已经存在该订单的费用");
                //     }
                // });


                //获取审批意见列表
                // getAction(this.url.getComment, {id: row.id}).then((res) => {
                //     if (res.success) {
                //         console.log(res)
                //         this.commentList = res.result
                //         console.log(this.commentList)
                //     } else {
                //         this.$message.error("暂未找到该订单号的费用，请确定是否已经存在该订单的费用");
                //     }
                // });


            },
            handleDeal(form) {
                console.log(form)
                console.log(this.activityForm)

                this.initList();

                // getAction(this.url.deal, form ? form : this.activityForm).then((res) => {
                //     if (res.success) {
                //         this.$message({
                //             type: "success",
                //             message: res.message,
                //         });
                //         this.initList();
                //     } else {
                //         this.$message({
                //             type: "error",
                //             message: res.message,
                //         });
                //     }
                // });
            },
            handleHistory(row) {
                console.log(row);
                let routeData = this.$router.resolve({
                    path:
                        "/ViewsActivitiBpmn?type=history&processInstanceId=" +
                        row.processInstanceId,
                });
                window.open(routeData.href, "_blank");
            },
            cancelStartProcess() {
                console.log("asasa")
                this.initList();
            },
            //启动实例
            startProcess() {
                console.log(this.form);
                this.$refs.form.validate((valid) => {
                    getAction(this.url.startProcess, this.form).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
            },

            handleDel(row) {
                console.log(row);
                this.$confirm(`确定删除${row.name}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteDeployment, row).then((res) => {
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.initList();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
