<template>
  <el-dialog
    title="批量自主飞"
    :visible="visible"
    width="30%"
    append-to-body
    custom-class="c-custom-dialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :before-close="handleClose"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="auto"
      size="medium"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item prop="isAutoFly" label="自主飞">
            <f-select v-model="form.isAutoFly" :dict="'is_default'"></f-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";

export default {
  name: "AutoFlyDialog",
  props: {
    visible: {
      type: Boolean,
    },
    autoFlyList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      rules: {
        isAutoFly: [
          { required: true, message: "请选择是否自主飞", trigger: "change" },
        ],
      },
      url: {
        updateAutoFly: "/order/kdOrder/updateAutoFly",
      },
    };
  },
  watch: {
    visible(val) {
      if (val) {
        console.log(this.form, "this.form");
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          postAction(this.url.updateAutoFly, {
            orderNoList: this.autoFlyList,
            ...this.form,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$emit("ok");
              this.handleClose();
            }
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.$emit("update:visible", false);
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
