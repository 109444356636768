<template>
    <div class="main-full-content">
        <el-form class="no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-row>
                <!--<el-col :span="4">
                    <el-form-item label="客户名称">
                        <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="联系电话">
                        <el-input v-model="queryForm.phone" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>-->
                <!--<el-col :span="8">
                    <el-form-item label="业务员">
                        <f-select v-model="queryForm.salesmanId" :dict="'salesman'"></f-select>
                    </el-form-item>
                </el-col>-->
                <!--<el-col :span="8">
                    <el-form-item label="添加人">
                        <f-select v-model="queryForm.createBy" :dict="'sys_user'"></f-select>
                    </el-form-item>
                </el-col>-->
                <el-col :span="16">
                    <el-form-item label-width="30px">
                       <!-- <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>-->
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15">
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="71vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button v-if="scope.row.status === 'RUNNING'" type="text" @click="handleEdit(scope.row)">挂起
                        </el-button>
                        <el-button v-if="scope.row.status !== 'RUNNING'" type="text" @click="handleEdit(scope.row)">激活
                        </el-button>
                        <el-button type="text" @click="handleView(scope.row)">历史</el-button>
                        <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>


<script>
    import {getAction, deleteAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"

    export default {
        name: 'Undetermined',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                rules: {
                    instanceName: [
                        {required: true, message: '请输入实例名称', trigger: 'change'},
                    ],
                    instanceVariable: [
                        {required: true, message: '请输入实例描述', trigger: 'change'},
                    ],
                },
                namelist: [],
                row: {},
                ids: [],
                formLabelWidth: '120px',
                visible: false,
                assignVisible: false,
                assignTitle: '',
                salesmanlist: [],
                userlist: [],
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'id',
                        label: 'ID',
                        sortable: 'id',
                    },
                    {
                        prop: 'name',
                        label: '实例名称',
                        sortable: 'name',
                    },
                    {
                        prop: 'startDate',
                        label: '实例创建日期',
                        sortable: 'startDate',
                    },
                    {
                        prop: 'deploymentName',
                        label: '流程定义名称',
                        sortable: 'deploymentName',
                    },
                    {
                        prop: 'processDefinitionId',
                        label: '流程定义编号',
                        sortable: 'processDefinitionId',
                    },
                    {
                        prop: 'processDefinitionKey',
                        label: '流程定义KEY',
                        sortable: 'processDefinitionKey',
                    },
                    {
                        prop: 'processDefinitionVersion',
                        label: '版本',
                        sortable: 'processDefinitionVersion',
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 300,
                    }
                ],
                url: {
                    list: '/activiti/ProcessList',
                    FlowImgByInstanceId: '/activiti/FlowImgByInstanceId',
                    deleteInstance: '/activiti/deleteInstance',
                    suspendOrResumeInstance:'/activiti/suspendOrResumeInstance'
                }
            }
        },
        created() {
            this.initList()

        },
        methods: {
            initList() {
                getAction(this.url.list).then(res => {
                    console.log(res)
                    if (res.success) {
                        const {records, total} = res.result
                        this.tableData =records||res.result
                        this.ipagination.total = res.result.total
                    }
                })
            },
            handleSelectionChange(selection) { // 多选
                let arr = [];
                this.namelist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.namelist.push(selection[i]['username'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },

            handleTableChange({prop, order}) {
                if (order === null) {
                    this.handleResetSorter();
                } else {
                    this.$set(this.isorter, "prop", prop);
                    this.$set(this.isorter, "order", order);
                }
                this.handleSearch();
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1;
                this.ipagination.pageSize = val;
                this.handleQuery();
            },

            handleCurrentChange(val) {
                this.ipagination.currentPage = val;
                this.handleQuery();
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },


            handleView(row) {
                console.log(row)
                let routeData = this.$router.resolve({path: '/ViewsActivitiBpmn?type=history&processInstanceId=' + row.id});
                window.open(routeData.href, '_blank');
            },
            handleEdit(row) {
                console.log(row)
                getAction(this.url.suspendOrResumeInstance, row).then(res => {
                    if (res.success) {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initList()
                    }
                })
            },
            cancelStartProcess() {
            },
            //启动实例
            startProcess() {
                console.log(this.form)
                this.$refs.form.validate((valid) => {
                    getAction(this.url.startProcess, this.form).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                })
            },
            handleDel(row) {
                console.log(row)
                this.$confirm(`确定删除${row.name}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteInstance, row).then(res => {
                        if (res.success) {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.initList()
                        }
                    })
                }).catch(() => {

                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
