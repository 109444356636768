<template>
  <div class="customerDialogBox">
    <el-dialog title="设置可见客户" :visible.sync="visible" width="50%">
      <el-row>
        <el-col :span="24">
          <el-transfer
            filterable
            filter-placeholder="请输入客户"
            v-model="clickData"
            :props="{
              key: 'id',
              label: 'username',
            }"
            :data="tableData"
            :titles="['客户列表', '已选中客户列表']"
          >
          </el-transfer>
          <el-row class="f-text-right f-p-v-8">
            <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              small
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </el-row>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="handleAdd"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
export default {
  mixins: [QueryMixins],
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      url: {
        clickList: "/sys/forestOceanshippingPricePermiss/list",
        add: "/sys/forestOceanshippingPricePermiss/add",
        list: "/customer/queryCusBySale",
      },
      clickData: [], //选中的客户列表
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.initData.salesmanId = this.rowData.createBy;
        this.handleQuery();
        this.getClickList();
      }
    },
  },
  methods: {
    // 获取该报价已指定的客户
    async getClickList() {
      this.clickData = [];
      await getAction(
        this.url.clickList + "?forestOceanshippingPriceId=" + this.rowData.fid
      ).then((res) => {
        if (res.success && res.result && res.result.records) {
          res.result.records.forEach((item) => {
            this.clickData.push(item.cusId);
          });
        }
      });
    },
    // 绑定客户
    handleAdd() {
      postAction(this.url.add, {
        forestOceanshippingPriceId: this.rowData.fid,
        cusIdList: this.clickData,
      })
        .then((res) => {
          if (res.success) {
            this.visible = false;
            this.$message.success(res.message);
          }
        })
    },
  },
  created() {
    // 让开始的时候不要调用handleQuery接口
    this.disableMixinMounted = true;
  },
};
</script>
<style lang="scss">
.customerDialogBox {
  .el-dialog__body {
    padding: 20px 15px !important;
  }
  .el-transfer-panel {
    width: 45%;
  }
  .el-transfer__buttons {
    width: 10%;
    padding: 0;
    text-align: center;
  }
}
.el-transfer-panel {
  width: 372px;
  height: 500px;
}
.el-transfer-panel__list.is-filterable {
  height: 400px;
}
</style>
<style scoped>
.cardHeight {
  height: 550px;
}
</style>