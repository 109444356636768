<template>
    <el-dialog
            title="导入结果"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
      <f-table
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="importFileData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="60vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'result'">
            <span v-if="scope.row.result === '成功'" style="color:green">{{scope.row.result}}</span>
            <span v-else style="color:red">{{scope.row.result}}</span>
          </template>
          <template v-else-if="scope.column.property === 'failedMsg'">

            <span style="color:red">{{scope.row.failedMsg}}</span>
          </template>
        </template>
      </f-table>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleClose()">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>


    export default {
        name: 'importFileDialog',
        props: {
            visible: {
                type: Boolean
            },

          importFileData:{
            type:Array,
            default:()=>[]
          },

        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                columns:[

                  {
                    prop: 'start',
                    label: '开始行',
                  },
                  {
                    prop: 'end',
                    label: '结束行',
                  },
                  {
                    prop: 'result',
                    label: '结果',
                  },
                  {
                    prop: 'failedMsg',
                    label: '失败原因',
                  },
                ],
              url:{

              }
            }
        },
        watch: {

        },
        methods: {

            handleClose() {
                this.$emit('ok')
            },
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
