<template>
    <div>
        <!-- 特价审批流程 -->
        <el-dialog
                :title="processDialogData.name"
                :visible.sync="visible"
                :before-close="handleDialogClose"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-card class="box-card" v-if="activitiInfomationShow">
                    <div slot="header" class="clearfix">
                        <span>流程信息</span>
                    </div>
                    <el-row class="inputs_row">
                        <el-col :span="12">
                            <el-form-item
                                    label="实例名称"
                                    label-width="80px"
                                    prop="instanceName"
                            >
                                <el-input v-model="form.instanceName" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                    label="实例描述"
                                    label-width="80px"
                                    prop="instanceVariable"
                            >
                                <el-input v-model="form.instanceVariable"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
                <el-row style="width: 100%">
                    <el-card class="box-card" style="width: 100%">
                        <div slot="header" class="clearfix" s>
                            <span>业务信息</span>
                        </div>
                        <el-row class="inputs_row">
                            <el-col :span="12">
                                <el-form-item
                                        label="申请人"
                                        :label-width="formLabelWidth"
                                        prop="userId"
                                >
                                    <f-select
                                            :isNeed="form.userId"
                                            v-model="form.userId"
                                            :dict="'sys_user_id'"
                                            disabled
                                    ></f-select>
                                    <!--                                    <f-select v-model="form.userId" :dict="'sys_user_id'"  :is-need="form.userId"></f-select>-->
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="申请人所在部门"
                                        :label-width="formLabelWidth"
                                        prop="departIds"
                                >
                                    <f-select
                                            :isNeed="form.departIds"
                                            v-model="form.departIds"
                                            :dict="'sys_depart'"
                                            disabled
                                    ></f-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="客户名"
                                        :label-width="formLabelWidth"

                                >
                                    <f-select
                                            v-model="form.customerId"
                                            :isNeed="form.customerId"
                                            :dict="'sys_cus_id'"
                                    >
                                    </f-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="客户类型"
                                        :label-width="formLabelWidth"
                                        prop="userType"
                                >
                                    <f-select
                                            :dict="'user_type1'"
                                            v-model="form.userType"
                                            :isNeed="form.userType"
                                            placeholder="请选择"
                                    >
                                    </f-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="客户潜力（未来货量）"
                                        :label-width="formLabelWidth"
                                        prop="potentialCustomers"
                                >
                                    <el-input v-model="form.potentialCustomers"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="历史走货量简要说明"
                                        :label-width="formLabelWidth"
                                        prop="historicalShipments"
                                >
                                    <el-input v-model="form.historicalShipments"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="竞争对手及报价"
                                        :label-width="formLabelWidth"
                                        prop="competitorQuotation"
                                >
                                    <el-input v-model="form.competitorQuotation"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="报价表报价"
                                        :label-width="formLabelWidth"
                                        prop="listPrice"
                                >
                                    <el-input v-model="form.listPrice"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                        label="申请价格"
                                        :label-width="formLabelWidth"
                                        prop="applicationPrice"
                                >
                                    <el-input v-model="form.applicationPrice"></el-input>
                                </el-form-item>
                            </el-col>
                            <!--                            <el-col :span="24">-->
                            <!--                                <el-form-item-->
                            <!--                                        label="附件图片"-->
                            <!--                                        :label-width="formLabelWidth"-->
                            <!--                                        prop=""-->
                            <!--                                >-->
                            <!--                                    <el-input></el-input>-->
                            <!--                                </el-form-item>-->
                            <!--                            </el-col>-->
                        </el-row>
                    </el-card>
                </el-row>
                <el-card class="box-card">
                    <el-button type="primary" size="medium" @click="handleAdd"
                    >新增一行
                    </el-button
                    >
                    <el-table :data="form.ListData" style="width: 100%">
                        <el-table-column prop="lcl_place" label="拼柜仓库" width="180">
                            <template slot-scope="scope">
                                <el-form-item :prop="'ListData.' + scope.$index + '.lcl_place'"
                                              :rules="rules.lclPlace">
                                    <f-select
                                            v-model="scope.row.lclPlace"
                                            :isNeed="scope.row.lclPlace"
                                            :dict="'lcl_place'"
                                    ></f-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="delivery_point" label="目的仓库" width="180">
                            <template slot-scope="scope">
                                <el-form-item :prop="'ListData.' + scope.$index + '.delivery_point'"
                                              :rules="rules.deliveryPoint">
                                    <f-select
                                            v-model="scope.row.deliveryPoint"
                                            :isNeed="scope.row.deliveryPoint"
                                            :dict="'delivery_point'"
                                    ></f-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="渠道名称" width="180">
                            <template slot-scope="scope">
                                <el-form-item :prop="'ListData.' + scope.$index + '.transport_status'"
                                              :rules="rules.transportStatus">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.transportStatus"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="货好时间" width="180">
                            <template slot-scope="scope">
                                <el-form-item>
                                    <el-date-picker
                                            v-model="scope.row.pickupTime"
                                            type="date"
                                            placeholder="选择日期">
                                    </el-date-picker>

                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="体积" width="180">
                            <template slot-scope="scope">
                                <el-form-item prop="cmb">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.cmb"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="重量" width="180">
                            <template slot-scope="scope">
                                <el-form-item prop="kg">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.kg"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="费用科目" width="180">
                            <template slot-scope="scope">
                                <el-form-item :prop="'ListData.' + scope.$index + '.cost_account'"
                                              :rules="rules.costAccount">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.costAccount"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="原价" width="180">
                            <template slot-scope="scope">
                                <el-form-item :prop="'ListData.' + scope.$index + '.original_price'"
                                              :rules="rules.originalPrice">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.originalPrice"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="折扣价" width="180">
                            <template slot-scope="scope">
                                <el-form-item :prop="'ListData.' + scope.$index + '.discount_price'"
                                              :rules="rules.discountPrice">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.discountPrice"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="备注" width="180">
                            <template slot-scope="scope">
                                <el-form-item prop="remark">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.remark"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="工作单号">
                            <template slot-scope="scope">
                                <el-form-item prop="orderNo">
                                    <el-input
                                            size="small"
                                            placeholder="请输入"
                                            class="classi"
                                            v-model="scope.row.orderNo"
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" @click="handleDel(scope.row.key)"
                                >删除
                                </el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-row style="width: 100%">
                    <el-card class="box-card" style="width: 100%">
                        <div slot="header" class="clearfix">
                            <span>审批流转意见</span>
                        </div>
                        <div
                                v-for="(item, index) in processDialogData.commentList"
                                :key="index"
                        >
                            <div v-if="index == 0" class="text item">
                                <a>{{ item.userId }}：{{ item.fullMessage }} </a>
                            </div>
                            <div
                                    v-if="index > 0"
                                    class="text item"
                                    style="margin-top: 10px !important"
                            >
                                <a>{{ item.userId }}：{{ item.fullMessage }} </a>
                            </div>
                        </div>
                    </el-card>
                </el-row>
                <el-card v-if="selectionActivityRows.actionType !='watch'">
                    <el-row style="width: 100%">
                        <el-form-item label="下一节点审批人" :label-width="formLabelWidth" prop="nextUser" v-if="selectionActivityRows.name!='渠道产品 美加线/欧线/其他航线'">
                            <el-select v-model="form.nextUser" clearable placeholder="请选择">
                                <el-option
                                        v-for="item in approvalOptions"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                                label="抄送对象"
                                :label-width="formLabelWidth"
                                prop=""
                                v-if="copyForShow"
                        >
                            <f-select
                                    :isNeed="form.carbonCopyLists"
                                    v-model="form.carbonCopyLists"
                                    :father-val="form.carbonCopyLists"
                                    :multiple="true"
                                    :dict="'sys_user_cc'"
                            ></f-select>
                        </el-form-item>
                        <el-form-item label="当前节点审批意见" :label-width="formLabelWidth" prop="" v-if="approvalOpinionShow">
                            <el-input type="textarea" rows="5" v-model="selectionActivityRows.remark"></el-input>
                        </el-form-item>
                    </el-row>
                </el-card>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button @click="handleCancel">取 消</el-button>
                <el-button type="primary" @click="handleDeal" v-if="selectionActivityRows.actionType != 'watch'">同意
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {deleteAction, putAction, postAction, getAction} from "@/api/manage";

    export default {
        components: {},
        props: {
            // dialog里需要渲染的值,form是固定的值，表单提交时候的默认回显
            processDialogData: {
                type: Object,
                default: {
                    form: {},
                },
            },
        },
        data() {
            return {
                rules: {
                    instanceName: [
                        {required: true, message: '请输入实例名称', trigger: 'change'},
                    ],
                    instanceVariable: [
                        {required: true, message: '请输入实例描述', trigger: 'change'},
                    ],
                    userId: [
                        {required: true, message: '申请人不能为空', trigger: 'change'},
                    ],
                    departIds: [
                        {required: true, message: '申请人所在部门不能为空', trigger: 'change'},
                    ],
                    customerId: [
                        {required: true, message: '客户名不能为空', trigger: 'change'},
                    ],
                    userType: [
                        {required: true, message: '客户类型不能为空', trigger: 'change'},
                    ],
                    potentialCustomers: [
                        {required: true, message: '客户潜力不能为空', trigger: 'change'},
                    ],
                    historicalShipments: [
                        {required: true, message: '客历史走货不能为空', trigger: 'change'},
                    ],
                    competitorQuotation: [
                        {required: true, message: '竞争对手及报价不能为空', trigger: 'change'},
                    ],
                    listPrice: [
                        {required: true, message: '报价表报价不能为空', trigger: 'change'},
                    ],
                    applicationPrice: [
                        {required: true, message: '申请价格不能为空', trigger: 'change'},
                    ],
                    nextUser: [
                        {required: true, message: '审批人不能为空', trigger: 'change'},
                    ],
                    lcl_place: [
                        {required: true, message: '请选择拼柜仓库', trigger: 'change'},
                    ],
                    delivery_point: [
                        {required: true, message: '请输入目的仓库', trigger: 'change'},
                    ],
                    transport_status: [
                        {required: true, message: '请选择渠道名称', trigger: 'change'},
                    ],
                    cost_account: [
                        {required: true, message: '请选择费用科目', trigger: 'change'},
                    ],
                    original_price: [
                        {required: true, message: '请输入原价', trigger: 'change'},
                    ],
                    discount_price: [
                        {required: true, message: '请输入折扣价', trigger: 'change'},
                    ],
                },
                approvalOptions: [],
                visible: false,
                formLabelWidth: "145px",
                activitiInfomationShow: true,  //显示流程信息的bar栏目
                copyForShow: true,              //显示选择抄送对象框
                approvalOpinionShow: true, //显示审批意见
                form: {}, //需要表单提交的变量回显
                commentList: [],
                selectionActivityRows: [],
                url: {
                    findLeader: '/sys/system/activity/findLeader',
                    initChannelSpecialOffer: '/activiti/activitiBusiness/initChannelSpecialOffer',
                    specialOfferStartProcess: '/activiti/activitiBusiness/startSpecialOfferProcess',
                    ChannelSpecialOfferDealInstanceId: "/activiti/activitiBusiness/ChannelSpecialOfferDealInstanceId",  // 下个节点审批
                    getComment: '/activiti/operationActiviti/getComment',
                    queryActivityByKey: '/activiti/activitiBusiness/queryActivityByKey'

                },
            };
        },
        watch: {
            processDialogData: {
                handler: function (val, oldVal) {
                    if (val.form) {
                        this.form = val.form;
                        this.form.ListData = val.form.ListData ? val.form.ListData : [];

                    } else {
                        this.form = {
                            ListData: []
                        }
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        created() {
        },
        mounted() {
            this.$emit('comMounted')
        },
        methods: {
            initData(row) {

                this.selectionActivityRows = row

                if (row != undefined && row.processInstanceId != null) {

                    //是否显示流程信息的bar栏
                    this.activitiInfomationShow = false
                    row.status = "";
                    postAction(this.url.initChannelSpecialOffer, row).then(
                        (res) => {
                            if (res.result.activityFormData != null) {
                                let data = JSON.parse(res.result.activityFormData.formData);
                                this.form = data
                                /* this.form.nextUser = row.initiator*/
                                this.form.nextUser = ''
                                this.form.id = res.result.activityFormData.id
                                this.form.processInstanceId = res.result.activityFormData.processInstanceId

                            }
                            if (row.actionType != 'watch') {
                                getAction(this.url.queryActivityByKey, {flowKey: row.processDefinitionKey}).then((res) => {
                                    if (res.success) {
                                        this.approvalOptions = []
                                        this.approvalOptions.push(
                                            {
                                                value: res.result.id,
                                                label: res.result.username
                                            }
                                        )
                                        this.$forceUpdate();
                                    } else {
                                        // this.$message({
                                        //     type: "error",
                                        //     message: res.message,
                                        // });
                                    }
                                });
                            }

                            if (res.result.listDataList != null) {
                                // console.log(res.result.listDataList)
                                this.form.ListData = res.result.listDataList

                            }

                            if (res.result.carbonCopyLists != null) {
                                // console.log(res.result.listDataList)
                                this.form.carbonCopyLists = res.result.carbonCopyLists

                            }
                        }
                    )


                    //获取审批意见
                    getAction(this.url.getComment, {processInstanceId: row.processInstanceId}).then((res) => {
                        if (res.success) {
                            this.processDialogData.commentList = res.result
                            this.$forceUpdate();
                        } else {
                            // this.$message({
                            //     type: "error",
                            //     message: res.message,
                            // });
                        }
                    })


                } else {
                    this.copyForShow = false;   //抄送对象
                    this.approvalOpinionShow = false //审批意见

                    postAction(this.url.findLeader).then(
                        (res) => {
                            if (res.success) {
                                if (res.result['leader'] != null) {
                                    this.approvalOptions = []
                                    this.approvalOptions.push(
                                        {
                                            value: res.result['leader'].id,
                                            label: res.result['leader'].username
                                        }
                                    )
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: "暂未找到直属领导信息",
                                    });
                                }

                                if (res.result['sysUser'] != null) {
                                    this.form.instanceVariable = this.processDialogData.form.name + "_" + res.result['sysUser'].createBy + "_" + this.getCurrentTime()
                                    this.form.instanceName = this.processDialogData.form.name + "_" + res.result['sysUser'].createBy + "_" + this.getCurrentTime()

                                    this.$set(this.form, 'userId', res.result['sysUser'].id)
                                    this.$set(this.form, 'departIds', res.result['sysUser'].departIds)

                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.message,
                                    });
                                }
                            }
                        }
                    )
                }

            }
            ,
            handleDeal() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        var formdata = JSON.parse(JSON.stringify(this.form));
                        // this.form.formData="";
                        // this.form.listData = [];
                        delete formdata.ListData
                        this.form.formData = JSON.stringify(formdata)
                        this.form.listData = this.form.ListData

                        if (this.selectionActivityRows != undefined && this.selectionActivityRows.processInstanceId != null) {

                            let data = {};
                            data["activityFormData"] = this.form
                            this.selectionActivityRows.formData = data;
                            delete this.selectionActivityRows.status;

                            //设置下一个节点处理人
                            if (this.form.nextUser != null) {
                                this.selectionActivityRows.nextUser = this.form.nextUser
                            }
                            console.log(this.form)
                            //抄送人
                            this.selectionActivityRows.carbonCopyLists = this.form.carbonCopyLists

                            postAction(this.url.ChannelSpecialOfferDealInstanceId, this.selectionActivityRows).then((res) => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: res.message,
                                    });
                                    this.handleCancel()
                                }
                            });
                        } else {
                            console.log("4444")
                            console.log(this.form)
                            postAction(this.url.specialOfferStartProcess, this.form).then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: 'success',
                                        message: res.message
                                    })

                                    this.handleCancel()
                                }
                            })
                            delete this.form.formData
                            delete this.form.listData
                            // this.form.formData="";
                            // this.form.listData = [];
                        }
                        // 点击同意之后触发的回调
                        this.handleCancel()
                        this.$emit("handleDeal");
                    }
                });
            }
            ,
            handleAdd() {
                let key = 0;
                if (
                    this.form.ListData &&
                    this.form.ListData.length
                ) {
                    key = this.form.ListData.length;
                }
                this.form.ListData.push({
                    key: key,
                    lcl_place: '',
                    delivery_point: '',
                    transport_status: '',
                    cmb: '',
                    kg: '',
                    cost_account: '',
                    original_price: '',
                    discount_price: '',
                    remark: '',
                    order_no: ''
                });
            }
            ,
            handleDel(key) {

                this.form.ListData.splice(key, 1);
            }
            ,
            handleCancel() {
                this.visible = false;
                this.isInit = true;
                this.form = {}
                this.selectionActivityRows = {}
                this.$emit("handleCancel");
            }
            ,
            handleDialogClose() {
                this.visible = false;
                this.isInit = true;
                this.form = {}
                this.selectionActivityRows = {}
                this.$emit('handleDialogClose')
            }
            ,
            getCurrentTime() {
                //获取当前时间并打印
                var _this = this;
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
                return _this.gettime;
            }
        },
    }
    ;
</script>
<style lang="scss" scoped>
</style>
