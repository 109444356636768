<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="订单号">
                        <el-input v-model.trim="queryForm.orderNo" @keyup.enter.native="() => {handleSearch()}"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="客户名称">
                        <el-input v-model.trim="queryForm.customer" @keyup.enter.native="() => {handleSearch()}"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label="柜号"  prop="containerNo">
                        <el-input v-model="queryForm.containerNo" autocomplete="off" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="() => {handleSearch()}">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="procudeId"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @queryChange="handleTableQueryChange"
                @sort-change="handleTableChange"
        >

            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="showGoods(scope.row)"
                               v-if="buttonShowList.editConMerOrderPro"
                    >编辑
                    </el-button>
                </template>
                <template v-else-if="scope.column.property === 'orderNo'">
                    <a v-if="$canEditCabinetInfo()"
                       style="color: #00a0e9"
                       @click.prevent="$intoDocPage(item)"
                       v-for="(item,index) in scope.row.orderNo.split(',')"
                    >{{ item + (scope.row.orderNo.split(',').length-1==index?'':'，') }}</a>
                    <span v-else>{{scope.row.orderNo}}</span>
                </template>
                <template v-else-if="scope.column.property === 'customer'">
                    <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                </template>
                <template v-else-if="scope.column.property === 'hasTax'">
                    <span>{{ scope.row.hasTax =='0'?'否':'是'}}</span>
                </template>
                <template v-else-if="scope.column.property === 'name'">
                    <span>{{ scope.row.name}}/{{ scope.row.ename}}</span>
                </template>
                <template v-else-if="scope.column.property === 'invoiceCode'">
                    <span style="color: red">{{scope.row.invoiceCode}}</span>
                </template>
                <template v-else-if="scope.column.property === 'addtionalCode'">
                    <span style="color: red"
                          v-if="scope.row.isRedAddtionalCode === '1'">{{scope.row.addtionalCode}}</span>
                    <span v-else>{{scope.row.addtionalCode}}</span>
                </template>
                <template v-else-if="scope.column.property === 'taxRate'">
                    <span style="color: red" v-if="scope.row.isRedTaxRate === '1'">{{scope.row.taxRate}}</span>
                    <span v-else>{{scope.row.taxRate}}</span>
                </template>
                <template v-else-if="scope.column.property === 'hscode'">
                    <span style="color: red" v-if="scope.row.isRedHscode === '1'">{{scope.row.hscode}}</span>
                    <span v-else>{{scope.row.hscode}}</span>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        <GoodsDialog
                :visible.sync="showGoodsVis"
                :row="selectRow"
                :invoiceCheck="!buttonShowList.editConMerOrderPro"
                @ok="closeGoos"
        ></GoodsDialog>
    </div>
</template>

<script>
    import {putAction} from '@/api/manage'
    import QueryMixins from "../../mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';
    import GoodsDialog from "@/views/docpage/modules/GoodsMergeDialog";


    export default {
        name: 'SensitiveProducts',
        mixins: [QueryMixins],
        components: { GoodsDialog },
        data() {
            return {
                showGoodsVis: false,
                selectRow: {},
                dictCodes: ['circulation_state', 'cabinet_category', 'bookAgent_id'],
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                        width: 200,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        width: 200
                        // width: 80,
                    },
                    {
                        prop: "customer",
                        label: "客户名称",
                        width: 200
                        // width: 80,
                    },
                    {
                        prop: "hasTax",
                        label: "是否包税",
                        width: 200
                        // width: 80,
                    },
                    // {
                    //     prop: "shipmentId",
                    //     label: "唛头",
                    //     minWidth: 120,
                    // },
                    {
                        prop: "name",
                        label: "货物名称",
                        minWidth: 100
                        // width: 200,
                    },
                    {
                        prop: "hscode",
                        label: "海关编码",
                        minWidth: 120
                        // minWidth: 140,
                    },
                    {
                        prop: "addtionalCode",
                        label: "301编码",
                        // minWidth: 140,
                    },
                    {
                        prop: "taxRate",
                        label: "税率",
                        minWidth: 50
                        // width: 100,
                    },
                    {
                        prop: "material",
                        label: "材质",
                        minWidth: 80
                        // width: 160,
                    },
                    {
                        prop: "purpose",
                        label: "用途",
                        minWidth: 80
                        // width: 100,
                    },
                    {
                        prop: "unitCost",
                        label: "成本单价",
                        minWidth: 40
                        // width: 100,

                    },
                    {
                        prop: "unitValue",
                        label: "申报单价",
                        minWidth: 40
                        // width: 100,
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                        minWidth: 40
                    },
                    {
                        prop: "kg",
                        label: "毛重",
                        minWidth: 40

                    },
                    {
                        prop: "cmb",
                        label: "体积",
                        minWidth: 50

                    },
                    {
                        prop: "invoiceCode",
                        label: "发票编码",
                        width: 35
                        // minWidth:60,
                    },
                    {
                        prop: "qty",
                        label: "数量",
                        minWidth: 40
                    },
                    {
                        prop: "totalValue",
                        label: "总价",
                        minWidth: 50
                    },
                    {
                        prop: "shipmentCountry",
                        label: "订单国家",
                        minWidth: 35
                    },
                    {
                        prop: "country",
                        label: "产品国家",
                        minWidth: 35
                    },
                    {
                        prop: "mgHsCode",
                        label: "美国海关编码",
                        minWidth: 85
                    },
                    {
                        prop: "customsDescription",
                        label: "产品描述",
                        minWidth: 115
                    },
                    {
                        prop: "dutyRate",
                        label: "关税税率",
                        minWidth: 70
                    },
                    {
                        prop: "addtionalDutyCode",
                        label: "附加税",
                        minWidth: 50
                    },
                    {
                        prop: "addtionalDutyRate",
                        label: "附加税率",
                        minWidth: 40
                    },
                    {
                        prop: "preTariff",
                        label: "预收关税",
                        minWidth: 55
                    },
                ],
                url: {
                    list: '/operation/containerApi/getCabinetMergeReviewList',
                    updateCabinetAudit: '/operation/containerApi/updateCabinetAudit'
                    // exportXlsUrl: '/customer/product/exportXls',
                    // tuihui:'/customer/product/qxSense/',
                    // tuihuiBatch:'/customer/product/qxSenseBatch',
                }
            }
        },
        methods: {
            closeGoos() {
                this.showGoodsVis = false;
                this.handleSearch();
            },
            showGoods(row) {
                this.$set(row,"mergeMaterial",row.material)
                this.$set(row,"mergePurpose",row.purpose)
                this.$set(row,"mergeHasTax",row.hasTax)
                this.$set(row,"mergeName",row.name)
                this.$set(row,"mergeEName",row.ename)
                this.$set(row,"mergeCusId",row.cusId)
                this.$set(row,"mergeMaterial",row.material)
                this.selectRow = JSON.parse(JSON.stringify(row));
                this.showGoodsVis = true;
            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push('/operation/cabinet/Edit?id=' + id)
            },
            showRate(val) {
                if (val.indexOf('%') !== -1) {
                    return val;
                } else {
                    return val + "%";
                }
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.sense = '1'
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleEdit(row, type) {
                let that = this;
                let form = JSON.parse(JSON.stringify(row));
                form.complianceState = type;
                form.actionType = 'complianceState'
                that.$confirm(`确定修改【${row.week}】状态?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(that.url.updateCabinetAudit, form).then(res => {
                        that.$message({
                            type: "success",
                            message: "修改成功",
                        });
                        that.handleQuery()
                    })
                }).catch(() => {

                })
            }
        },
        created() {
            this.initData.complianceState = '1';
            this.queryForm.sense = '1'
        },
      computed:{
        buttonShowList() {
          return {
            'editConMerOrderPro':this.$btnIsShow('conformance/CabinetMergeReview','0','修改柜子合并订单产品'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
