<template>
    <el-dialog class="guanlian"
               :title="type === 'RELATION' ? '关联 【' + username + '】' : '反向关联 【' + username + '】'"
               :visible="visible"
               width="50%"
               top="10vh"
               custom-class="c-custom-dialog"
               :destroy-on-close="true"
               :before-close="handleClose"
    >
        <el-checkbox


                v-model="checkAll"
                @change="isAll"
        >全选
        </el-checkbox
        >
        <div v-for="(item, index) in data" :key="index" style="margin-left: 30px">
            <el-checkbox
                    v-model="item.isCheck"
                    :indeterminate="item.isIndeterminate"
                    @change="checkTitle(item.isCheck, index,data)"
            >{{item.departName}}
            </el-checkbox>

            <div v-if="item.children && item.children.length" style="margin-left: 30px">
                <el-checkbox-group
                        v-model="item.userIds"
                        @change="checkItem(item.userIds,index,data)"
                >
                    <el-checkbox
                            v-for="(a, index) in item.children"
                            :label="a.userId"

                            :key="index"
                    >{{ a.username }}
                    </el-checkbox
                    >
                </el-checkbox-group>
            </div>
            <div v-if="item.groups && item.groups.length" v-for="(b,c) in item.groups" :key="c"
                 style="margin-left: 30px">
                <el-checkbox v-model="b.isCheck" @change="checkTitle(b.isCheck,c,item.groups)">
                    {{b.departName}}
                </el-checkbox>
                <el-checkbox-group v-if="b.children && b.children.length"
                                   style="margin-left: 30px"
                                   @change="checkItem(b.userIds,c,item.groups)"
                                   v-model="b.userIds"
                >
                    <el-checkbox
                            v-for="(a, index) in b.children"
                            :label="a.userId"
                            :key="index"
                    >{{ a.username }}
                    </el-checkbox
                    >
                </el-checkbox-group>
                <div v-if="b.groups && b.groups.length" v-for="(d,e) in b.groups" :key="e" style="margin-left: 30px">
                    <el-checkbox v-model="d.isCheck" @change="checkTitle(d.isCheck,e,b.groups)">
                        {{d.departName}}
                    </el-checkbox>
                    <el-checkbox-group v-if="d.children && d.children.length"
                                       style="margin-left: 30px"
                                       v-model="d.userIds"
                                       @change="checkItem(d.userIds,e,b.groups)"
                    >
                        <el-checkbox

                                v-for="(a, index) in d.children"
                                :label="a.userId"
                                :key="index"
                        >{{ a.username }}
                        </el-checkbox
                        >
                    </el-checkbox-group>
                </div>
            </div>


        </div>
        <span slot="footer">
<!--            <el-button class="c-theme-button" size="medium" plain @click="handleSetChecked">{{isChecked ? '全部' : '取消' }}选中</el-button>-->
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
  import {postAction} from "@/api/manage";

  export default {
        name: "RelationDialog",
        props: {
            visible: {
                type: Boolean,
            },
            id: {
                type: String,
            },
            username: {
                type: String,
            },
            type: {
                type: String,
                default: "RELATION", // REVERSE
            },
        },
        data() {
            return {
                checkAll: false,
                selectPeople: [],
                currentNodeKey: "",
                checkedMenuIds: [],
                form: {
                    relationPeople: [],
                },
                upLowVisble: false, // 上下架显隐开关
                filterText: "", // 过滤字段
                channels: [], // 栏目初始数据
                channelProps: {
                    label: "name",
                    children: "children",
                    isLeaf: "isChild",
                    id: "id",
                },
                flag: false, // 属性是否选中标识
                isCheck: false,
                upLowLoading: true, // 遮罩层
                upLowTitle: "", // 上下架面板标题
                isChecked: true,
                allKeys: [],
                // data: [],
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                url: {
                    // list: '/sys/user/list',
                    ok: "/sys/user/setRelation",
                    // auth: '/sys/user/getRelation',
                    auth: "/sys/user/getXmRelation",
                    okreserve: "/sys/user/setRelationReverse",
                    authreserve: "/sys/user/getXmRelationReserve",
                },

                data: [
                    {
                        isCheck: false,
                        isIndeterminate: true,
                        checkedData: [],
                        children: [
                            {
                                value: "11",
                                label: "分组1-1",
                            },
                            {
                                value: "12",
                                label: "分组1-2",
                            },
                        ],
                    },
                    {
                        isCheck: false,
                        isIndeterminate: true,
                        checkedData: [],
                        children: [
                            {
                                value: "21",
                                label: "分组2-1",
                            },
                            {
                                value: "22",
                                label: "分组2-2",
                            },
                            {
                                value: "23",
                                label: "分组2-3",
                            },
                            {
                                value: "24",
                                label: "分组2-4",
                            },
                        ],
                    },
                ],
            };
        },
        watch: {

            visible(val) {
                if (val) {
                    this.handleGetData();
                    // this.handleGetAuth()
                }
            },
            currentNodeKey(id) {
                // Tree 内部使用了 Node 类型的对象来包装用户传入的数据，用来保存目前节点的状态。可以用 $refs 获取 Tree 实例
                console.log(id);
                if (id.toString()) {
                    this.$refs["tree"].setCurrentKey(id);
                } else {
                    this.$refs["tree"].setCurrentKey(null);
                }
            },
        },
        methods: {
            isAll(val) {

                for (var i = 0; i < this.data.length; i++) {
                    this.$set(this.data[i], 'isCheck', val);
                    this.checkTitle(val, i, this.data);
                }

            },
            isAllSel() {
                let count = 0;
                this.data.map(ff => {
                    if (ff.isCheck) {
                        count++;
                    }
                })
                this.$set(this, 'checkAll', this.data.length === count);
            },
            // 勾选单个复选框
            checkItem(val, index, list) {
                console.log('c勾选单个', val, list[index].children);
                let checkedCount = val.length;
                this.$set(list[index], 'isCheck', checkedCount === list[index].children.length);
                // list[index].isCheck =
                //   checkedCount === list[index].children.length;
                this.$set(list[index], 'isIndeterminate', checkedCount > 0 && checkedCount < list[index].children.length);
                // list[index].isIndeterminate =
                //   checkedCount > 0 && checkedCount < list[index].children.length;
                this.isAllSel();
            },
            // 全选
            checkTitle(val, index, list) {
                let arr = [];
                const re = list[index].children;
                //全选
                if (val) {
                    for (let i = 0; i < re.length; i++) {
                        arr[i] = re[i]["userId"];
                    }
                }
                const g1 = list[index].groups;
                console.log('有没有儿子', g1);
                if (g1 && g1.length > 0) {
                    for (var i = 0; i < g1.length; i++) {
                        this.$set(g1[i], 'isCheck', val);
                        this.checkTitle(val, i, g1);
                        const g2 = g1[i].groups;
                        if (g2 && g2.length > 0) {
                            for (var j = 0; j < g2.length; j++) {
                                // g2[j].isCheck = val;
                                this.$set(g2[j], 'isCheck', val);
                                this.checkTitle(val, j, g2);

                            }
                        }
                    }
                }
                list[index].userIds = arr;
                list[index].isIndeterminate = false;
                this.isAllSel();
            },

            handleCheckChange() {
                console.log("1");
            },
            getCurrentNode(a, b) {
            },
            clickDeal(currentObj, treeStatus) {
                let that = this;
                that.selectPeople = [];
                let ids = this.$refs.tree.getCheckedKeys();

                // console.log(this.$refs.tree.getCurrentNode());
                for (let i = 0; i < ids.length; i++) {
                    let data = this.$refs.tree.getNode(ids[i]).data;
                    if (data != null && data != undefined) {
                        if (data.checkUser != null) {
                            console.log(data.checkUser);
                            for (let i = 0; i < data.checkUser.length; i++) {
                                if (data.checkUser[i].id != null) {
                                    that.selectPeople.push(data.checkUser[i]);
                                }
                            }
                        }
                    }
                }
                // // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
                // let selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未选中
                // // 选中
                // if (selected !== -1) {
                //     // 子节点只要被选中父节点就被选中
                //     this.selectedParent(currentObj)
                //     // 统一处理子节点为相同的勾选状态
                //     this.uniteChildSame(currentObj, true)
                // } else {
                //     // 未选中 处理子节点全部未选中
                //     if (currentObj.childs.length !== 0) {
                //         this.uniteChildSame(currentObj, false)
                //     }
                // }
            },
            handleChange(data) {
                console.log(data);
                // console.log(index)
                console.log(this.form.relationPeople);
            },
            // 渲染树
            upLowLoad() {
                let that = this;
                this.upLowLoading = true;
                axios
                    .post(this.$api.allChannelList)
                    .then((res) => {
                        this.upLowLoading = false;
                        that.channels = res.body;
                    })
                    .catch((err) => {
                        this.upLowLoading = false;
                    });
            },

            // 上下架树加载
            upper_lowerChange(node, checkStatus, childStatus) {
                let that = this;
                if (checkStatus) {
                    this.currentCheckChannelId = node.id;
                    if (node != null) {
                        // eslint-disable-next-line no-empty
                        if (node.checkUser != null) {
                            for (let i = 0; i < node.checkUser.length; i++) {
                                if (node.checkUser[i].id != null) {
                                    that.selectPeople.push(node.checkUser[i]);
                                }
                            }
                        }
                    }
                }
            },
            // 上下架节点过滤
            filterNode(value, data) {

                if (!value) return true;
                return data.title.indexOf(value) !== -1
            },
            // 全选或反选
            // checkAll() {
            //   console.log(this.channels);
            //   this.flag = true;
            //   this.$refs.channelTree.setCheckedNodes(this.channels);
            // },
            // 全选处理方法
            batchSelect(nodes, refs, flag, seleteds) {
                if (typeof nodes != "undefined") {
                    nodes.forEach((element) => {
                        refs.setChecked(element, flag, true);
                    });
                }

                if (typeof seleteds != "undefined") {
                    seleteds.forEach((node) => {
                        refs.setChecked(node, !flag, true);
                    });
                }
            },
            // 反选
            inverse() {
                let res = this.$refs.channelTree;
                let nodes = res.getCheckedNodes(true, true);
                this.batchSelect(this.channels, res, true, nodes);
            },
            handleSubmit() {
                const url = this.type === "RELATION" ? this.url.ok : this.url.okreserve;
                let relationList = [];
                this.data.forEach(dd => {
                    dd.userIds.forEach(uu => {
                        relationList.push(uu);
                    })
                    let g1 = dd.groups;
                    if (g1 && g1.length) {
                        g1.forEach(gg => {
                            gg.userIds.forEach(bb => {
                                relationList.push(bb);
                            })
                            let g2 = gg.groups;
                            if (g2 && g2.length) {
                                g2.forEach(cc => {
                                    cc.userIds.forEach(dd => {
                                        relationList.push(dd);
                                    })
                                })
                            }
                        })
                    }
                })
                let relationIds = relationList.join(',');

                const params = {
                    id: this.id,
                    relationIds: relationIds,
                };
                console.log('谁', params)
                if (relationIds.length === 0) {
                    this.$message.warning('请选择人员');
                    return false;
                }
                postAction(url, params).then((res) => {
                    console.log(res);
                    if (res.success) {
                        //this.handleClose()
                        this.handleClose();
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                    }
                });
            },
            handleGetData() {
                this.form.relationPeople = [];
                // this.$refs.tree.setCheckedNodes([]);
                this.data = []
                this.selectPeople = []
                const url = this.type === 'RELATION' ? this.url.auth : this.url.authreserve
                console.log(url)
                postAction(url, {userId: this.id}).then((res) => {
                    this.data = res.result
                    console.log("data", this.data)
                    this.isAllSel();
                    //勾选节点
                    // this.$nextTick(() => {
                    //     this.getRoleMenu(res.result)
                    // });
                    // this.upLowLoading = false;
                    // console.log(this.data)
                })
            },
            handleSetChecked() {
                console.log(this.data);
                this.$refs.tree.setCheckedNodes(this.isChecked ? this.data : []);
                this.selectPeople = [];
                if (!this.isChecked) {
                    this.form.type = [];
                }
                // this.selectPeople(this.isChecked ? [] : [])
                // this.$refs.tree.setCheckedKeys(this.isChecked ? this.data : [])
                this.isChecked = !this.isChecked;
            },
            //递归遍历树
            setName(menuList) {
                for (var i in menuList) {
                    if (menuList[i].checkUser.length > 0) {
                        for (let j = 0; j < menuList[i].checkUser.length; j++) {
                            if (menuList[i].checkUser[j].checked == "1") {
                                this.checkedMenuIds.push(menuList[i].id);
                                break;
                            }
                        }
                    }
                    // this.checkedMenuIds.push(menuList[i].id)
                    if (menuList[i].children) {
                        this.setName(menuList[i].children);
                    }
                }
            },
            handleClose() {
                this.form.relationPeople = [];
                // this.$refs.tree.setCheckedNodes([]);
                this.data = [];
                this.selectPeople = [];
                this.$emit("update:visible", false);
            },
            //勾选节点
            getRoleMenu(menuList) {
                if (menuList != null && menuList.length > 0) {
                    this.setName(menuList);
                }
                // this.$refs.tree.setCheckedKeys(this.checkedMenuIds); //设置目前勾选的节点
            },
        },
    };
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

    ::v-deep {
        .el-dialog__body {
            padding: 0 0 0 20px;
        }

        .el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
            background-color: #e0efff;
        }
    }
</style>
