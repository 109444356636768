<template>

    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                          <el-form-item label="仓库名称">
                            <f-select v-model="queryForm.warehouseId" :dict="'delivery_point'" :is-need="queryForm.warehouseId" :father-val="queryForm.warehouseId"></f-select>
                          </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="ownQuery">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <!--            <div class="f-p-b-15">-->
            <!--                <el-button size="medium" type="primary" @click="noConvertBill">非折合账单</el-button>-->
            <!--                <el-button size="medium" type="primary" @click="convertBill">折合账单</el-button>-->
            <!--            </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="51vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text">取消出库</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'capacityUsed'">
                        <span>{{scope.row.capacityUsed===null?0:scope.row.capacityUsed}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'capacityLimit'">
                        <span>{{Number(scope.row.warehouseLocationCapacity) - Number(scope.row.capacityUsed===null?0:scope.row.capacityUsed)}}</span>
                    </template>

                </template>
            </f-table>
            <el-form :inline="true" :label-position="'left'" label-width="109px">
                <el-row :gutter="24" class="payApplyRow">
                    <el-col>
                        <span>合计：</span>
                    </el-col>
                    <el-col v-for="(item,index) in sumFee" :key="index" :span="3" style="color: red">
                        <el-form-item  :label="item.cur" :key="index" class="payApplyItem">
                            <span>总容量:{{item.total}}</span><br>
                            <span>已使用:{{item.used}}</span><br>
                            <span>剩余量:{{item.limit}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChangeAndSum"
                        @current-change="handleCurrentChangeAndSum">
                </el-pagination>
            </el-row>
        </el-card>

    </div>


</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins"
    import {getAction, postAction} from "@/api/manage";


    export default {
        mixins: [QueryMixins],
        components: {

        },
        data() {
            return {

                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                sumFee:[],
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    // {
                    //     prop: 'action',
                    //     label: '操作',
                    // },
                    {
                        prop: 'warehouse',
                        label: '仓库名',
                    },
                    {
                        prop: 'warehouseLocationCode',
                        label: '库位',
                    },
                    {
                        prop: 'capacityUsed',
                        label: '已使用容量',
                    },
                    {
                        prop: 'capacityLimit',
                        label: '剩余容量',
                    },

                ],

                url: {
                    list: '/interface/warehouse/getWarehouseCapicityData',
                },
                disableMixinMounted:true,
                tableList:[],
            };
        },
        mounted() {

            this.ownQuery()

        },
        computed: {

        },
        created() {
            // this.$nextTick(() => {
            //     this.doSum();
            // })
        },
        methods: {
            handleSizeChangeAndSum(val){
                this.ipagination.currentPage = 1
                this.ipagination.pageSize = val
                this.ownQuery()
                this.$nextTick(() => {
                    this.doSum(this.tableList)
                })

            },
            handleCurrentChangeAndSum(val){
                this.ipagination.currentPage = val
                this.ownQuery()
                this.$nextTick(() => {
                    this.doSum(this.tableList)
                })
            },
            ownQuery(){
                this.loading = true
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                    //排序
                    //提货中查询
                    if (this.queryForm.column === 'sort') {
                        this.$set(params, 'column', 'sort')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }


                getAction(this.url.list, params).then((res) => {
                    console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result.page
                            this.tableData = records ;
                            this.tableList = res.result.list;
                            this.ipagination.total = total
                        } else {

                            this.tableData = [];

                            this.ipagination = 0;
                        }

                    }
                }).finally(() => {
                    this.loading = false
                    this.doSum(this.tableList)
                })
            },
            handleReset() {
                this.queryForm = {}
                this.ipagination.currentPage = 1
                this.ownQuery()
            },
            doSum(rows){
                this.sumFee = [];
                let allCur = []
                rows.forEach(row => {
                    allCur.push(row.warehouse)
                })
                let curNum = Array.from(new Set(allCur))
                console.log('币种->',curNum)
                //遍历每个币种，计算总和后放入sumFee中
                curNum.forEach(warehouse => {
                    let curRow = rows.filter(row =>{
                        return row.warehouse === warehouse
                    })
                    console.log('币种row->',curRow)
                    let total = Number(0);
                    let limit = Number(0);
                    let used = Number(0);
                    curRow.forEach(i=>{
                        total = total + Number(i.warehouseLocationCapacity)
                        limit = limit + Number(i.warehouseLocationCapacity)-Number(i.capacityUsed)
                        used = used + Number(i.capacityUsed)
                    })
                    console.log('总计',total)
                    this.sumFee.push({cur:warehouse, total:total.toFixed(3),used:used.toFixed(3),limit:limit.toFixed(3)})
                })
            },
            handleCheck() {
                this.loading = true
                if (this.otime.length>1){
                    this.queryForm.createTimeS = this.otime[0]
                    this.queryForm.createTimeE = this.otime[1]
                }
                if (this.stime.length>1) {
                    this.queryForm.sCreateTimeS = this.stime[0]
                    this.queryForm.sCreateTimeE = this.stime[1]
                }
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                console.log("查询对象", params)

                getAction(this.url.list, params).then((res) => {
                    if (res.success) {
                        const {records, total} = res.result
                        this.tableData = records || res.result;

                        this.ipagination && (this.ipagination.total = total || res.result.length)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            noConvertBill(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }

                let countCur = [];
                this.selectionRows.forEach(item => {
                    countCur.push(item.moneyType)
                });
                let curArr = Array.from(new Set(countCur));
                console.log('curArr->',curArr)
                if(curArr.length > 1){
                    this.$message.warning("所选费用的费用币种不一致！")
                    return
                }

                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.noConvert.edit(this.selectedRowKeys,this.selectedNos)
            },
            convertBill(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }
                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                console.log(Array.from(new Set(count)))
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.convert.edit(this.selectedRowKeys,this.selectedNos)
            },
        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
