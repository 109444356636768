<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :span="6">
            <el-form-item label="客户">
              <el-input
                v-model="queryForm.settlement"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="派送时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.deliveryTimeS"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptionsStart(queryForm.deliveryTimeE)"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.deliveryTimeE"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptionsEnd(queryForm.deliveryTimeS)"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开船时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.shipmentBoardTimeS"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="
                    pickerOptionsStart(queryForm.shipmentBoardTimeE)
                  "
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.shipmentBoardTimeE"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="
                    pickerOptionsEnd(queryForm.shipmentBoardTimeS)
                  "
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="费用录入时间" label-width="120px">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeS"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptionsStart(queryForm.createTimeE)"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeE"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptionsEnd(queryForm.createTimeS)"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="销售">
              <f-select
                v-model="queryForm.salesmanId"
                :is-need="queryForm.salesmanId"
                :dict="'sys_user_id'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="收款人">
              <f-select
                v-model="queryForm.billmanId"
                :is-need="queryForm.billmanId"
                :dict="'sys_user_id'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="30px" style="width: 500px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <el-row style="display: inline">
        <div>
          <el-col>
            <div class="f-p-b-15">
              <el-button
                size="medium"
                type="primary"
                @click="handleExportXls('应收额度列表')"
                >导出</el-button
              >
            </div>
          </el-col>
        </div>
      </el-row>

      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="63vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <!-- <template v-slot="scope">
        </template> -->
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { getAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";

export default {
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      form: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        // {
        //   prop: "action",
        //   label: "操作",
        //   width: 130,
        // },
        {
          prop: "settlement",
          label: "客户名",
        },
        {
          prop: "salesman",
          label: "销售",
        },
        {
          prop: "edu",
          label: "额度",
        },
        {
          prop: "billman",
          label: "收款人",
        },
        {
          prop: "payType",
          label: "结算方式",
        },
        {
          prop: "total",
          label: "总计欠款金额",
        },
      ],
      url: {
        list: "/finance/recFee/getPage",
        exportXlsUrl: "/finance/recFee/downloadRecFee",
      },
      isUsePost: true,
    };
  },
  computed: {
    pickerOptionsStart() {
      return (type) => {
        return {
          disabledDate(time) {
            let endTime = new Date(type).getTime();
            if (endTime) {
              return (
                time.getTime() > endTime ||
                endTime - time.getTime() > 6 * 30 * 24 * 60 * 60 * 1000
              );
            }
            return false;
          },
        };
      };
    },
    pickerOptionsEnd() {
      return (type) => {
        return {
          disabledDate(time) {
            let startTime = new Date(type).getTime();
            if (startTime) {
              return (
                time.getTime() - startTime > 6 * 30 * 24 * 60 * 60 * 1000 ||
                time.getTime() < startTime
              );
            }
            return false;
          },
        };
      };
    },
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
</style>
