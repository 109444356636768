<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item label="航线名称">
              <el-input v-model.trim="queryForm.itemText" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="handleOpen('TrackingId')"
          v-if="buttonShowList.add">新增
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row, '1')"
              v-if="buttonShowList.en && scope.row.status !== 1">启用</el-button>
            <el-button type="text" @click="handleEdit(scope.row, '0')"
              v-if="buttonShowList.dis && scope.row.status !== 0">禁用</el-button>
          </template>
          <template v-else-if="scope.column.property === 'status'">
            <span>{{scope.row.status == '1' ? '是' : '否'}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog title="新增航线" :visible.sync="visible" center width="30%" :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form :model="form" ref="form" size="medium" :rules="rules" label-width="80px">
        <el-form-item prop="itemValue" label="航线数值">
          <el-input v-model.trim="form.itemValue" disabled></el-input>
        </el-form-item>
        <el-form-item prop="itemText" label="航线名称">
          <el-input v-model.trim="form.itemText"></el-input>
        </el-form-item>
        <el-form-item prop="sortOrder" label="排序">
          <el-input v-model.trim="form.sortOrder"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label='"1"'>是</el-radio>
            <el-radio :label='"0"'>否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="handleSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction, putAction } from "@/api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";
  import { intValidator } from '@/utils/validator'

  export default {
    name: "routeManage",
    mixins: [QueryMixins],
    components: {
    },
    props: {
    },
    data() {
      return {
        visible: false,
        form: {},
        rules: {
          itemText: [
            { required: true, message: '请输入航线名称', trigger: 'change' },
          ],
          itemValue: [
            { required: true, message: '请输入航线数值', trigger: 'change' },
          ],
          sortOrder: [
            { validator: intValidator, trigger: 'change' }
          ]
        },
        columns: [
          {
            type: "selection",
            width: 50,
          },
          {
            prop: "action",
            label: "操作",
            width: 100
          },
          {
            prop: "itemText",
            label: "航线名称",
          },
          {
            prop: "itemValue",
            label: "航线数值",
          },
          {
            prop: "sortOrder",
            label: "排序", sortable: "custom"
          },
          {
            prop: "status",
            label: "启用",
          },
        ],
        url: {
          list: '/sys/dictItem/list',
          add: '/sys/dictItem/add',
          edit: '/sys/dictItem/edit',
        },
        initData: {
          dictId: '1391967895702880257'
        },
        isorter: {
          prop: 'sortOrder',
          order: 'asc',
        },  //排序参数

      };
    },
    created() {
    },
    methods: {
      handleEdit(row, status) {
        let params = { ...row };
        params.status = status;
        this.$store.commit("setFullscreenLoading", true);
        putAction(this.url.edit, params).then(res => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.$store.commit("setFullscreenLoading", false);
          this.handleSearch();
        }).catch(err => {
          this.$store.commit("setFullscreenLoading", false);
        })
      },
      handleOpen() {
        this.form = {};
        this.form.dictId = this.initData.dictId;
        this.form.itemValue = 'bd_' + this.tableData.length;
        this.form.status = '1';
        this.visible = true;
      },
      handleClose() {
        this.form = {};
        this.visible = false;
      },
      handleSubmit() {
        let params = JSON.parse(JSON.stringify(this.form)), url = this.url.add;
        console.log(params, 'params')
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$store.commit("setFullscreenLoading", true);
            postAction(url, params).then(res => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleClose();
              this.handleSearch();
            }).catch(err => {
              this.$store.commit("setFullscreenLoading", false);
            })
          }
        })
      },
    },
    computed:{
      buttonShowList() {
        return {
          'add':this.$btnIsShow('isystem/BaseData/routeManage','0','新增'),
          'en':this.$btnIsShow('isystem/BaseData/routeManage','1','启用'),
          'dis':this.$btnIsShow('isystem/BaseData/routeManage','1','禁用'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>