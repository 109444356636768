import { render, staticRenderFns } from "./OceanShippingPriceChangeSale.vue?vue&type=template&id=297b8783&scoped=true&"
import script from "./OceanShippingPriceChangeSale.vue?vue&type=script&lang=js&"
export * from "./OceanShippingPriceChangeSale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297b8783",
  null
  
)

export default component.exports