<template>
  <el-dialog
      title="转仓加货"
      :visible="visible"
      width="50%"
      custom-class="c-custom-dialog"
      append-to-body
      :before-close="handleClose"
      :close-on-click-modal="false">
    <!--:disabled="selectedRowKeys.length === 0"-->
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium" :rules='rules'>
      <el-row :gutter="20" class="flex_center" style="padding: 0 277px;">
        <el-col :span="24">
          <el-form-item label="订单号" prop="orderNo">
            <el-input v-model="queryForm.orderNo" placeholder="多订单号" type="textarea"
                      @keyup.enter.native="handleQueryS"></el-input>
          </el-form-item>
        </el-col>

         <el-col :span="24">
          <el-form-item
            prop="volumeLocationId"
            label="货物所在仓"
          >
             <f-select
                  :dict="'delivery_point'"
                  :is-need="queryForm.volumeLocationId"
                  v-model="queryForm.volumeLocationId"
                ></f-select>
          </el-form-item>
        </el-col>
         <el-col :span="24">
          <el-form-item
            prop="lclPlace"
            label="拼柜仓库"
          >
            <f-select
                  v-model="queryForm.lclPlace"
                  :dict="'lclPlace'"
                  :is-need="queryForm.lclPlace"
                ></f-select>
          </el-form-item>
        </el-col>
         <el-col :span="24">
          <el-form-item
            prop="createBy"
            label="材积录入人"
          >
          <f-select
                  v-model="queryForm.createBy"
                  :isNeed="queryForm.createBy"
                  :dict="'sys_user_id'"
                >
                </f-select>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item
            label="材积创建时间"
            :label-width="'90px'"
          >
            <el-date-picker
                  v-model="createTime"
                  type="datetimerange"
                  size="small"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  unlink-panels
                  style="width: auto;"
                >
                </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleQueryS">查询</el-button>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>

    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="50vh"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'orderNo'">
          <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
        </template>
        <!-- <template v-else-if="scope.column.property === 'ctn'">
          <el-input v-model="scope.row.ctn" type="number"></el-input>
        </template>
        <template v-else-if="scope.column.property === 'kg'">
          <el-input v-model="scope.row.kg" type="number"></el-input>
        </template>
        <template v-else-if="scope.column.property === 'cmb'">
          <el-input v-model="scope.row.cmb" type="number"></el-input>
        </template> -->
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleAddOrder">确认加货</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {putAction,getAction,postAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";

export default {
  name: 'AddTakeOrderDetailListModule',
  mixins: [QueryMixins],
  props: {
    visible: {
      type: Boolean
    },
    takeId: {
      type: String,
      default: ''
    },
   type: {
      type: String,
      default: "",
    },
        takeNo:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      isUsePost: true,
      disableMixinMounted:true,
      columns: [
        {
          type: "selection",
        },

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "ctn",
          label: "箱数",
        },
        {
          prop: "kg",
          label: "重量",
        },
        {
          prop: "cmb",
          label: "体积",
        },
        {
          prop: "warehouseName",
          label: "订单的交货仓库",
        },

      ],
      form: {},
      formLabelWidth: '100px',

      url: {
        addTakeOrders: '/warehouse/takeDelivery/addGoods',
        //查询
        list: '/warehouse/takeDelivery/getAddZCGoodsIPage',
        // list:''
      },
      createTime:[
        
      ],
      rules: { orderNo: [
                        {required: true, message: '请输入订单号', trigger: 'change'}
                    ],}

    }
  },
  watch: {
    visible(val) {
      if(this.type==='2'||this.type==='3'){
        // this.url.list='/warehouse/volume/list'
        // this.isUsePost=false
        this.columns=[
        {
          type: "selection",
          width: "33",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },

        {
          prop: "ctn",
          label: "实际件数",
        },
        {
          prop: "cmb",
          label: "实际体积",
        },
        {
          prop: "kg",
          label: "实际重量",
        },
        {
          prop: "warehouseName",
          label: "交货仓库",
        },
         {
          prop: "lclPlaceName",
          label: "拼柜仓库",
        },
         {
          prop: "volumeLocation",
          label: "货物所在仓",
        },
          {
          prop: "createBy",
          label: "录入人",
        },]
      }else{
        //  this.url.list='/warehouse/takeDelivery/getAddZCGoodsIPage'
        //  this.isUsePost=true
      }
      if (val && this.takeId) {
        //勾选的订单号进行赋值
        this.queryForm.takeId = this.takeId;
        this.queryForm.takeIds = this.takeId;
      }
      if(val){
        this.queryForm.takeNo = this.takeNo
      }

    },
  },
  methods: {

    handleAddOrder() {
      //加货
      putAction(this.url.addTakeOrders, this.selectionRows).then((res) => {
        this.$message({
          type: 'success',
          message: res.message
        })
        this.handleClose();
        this.$emit('ok');
      })
    },
    // handleQueryUrl(){

    // },

    handleClose() {
      this.queryForm={}
      this.tableData=[]
      this.$emit('ok');
    },
    handleQueryS(){
       this.$refs.form.validate((valid) => {
        if (valid) {
         if (this.createTime && this.createTime.length) {
        this.queryForm.createTimeS = this.createTime[0];
        this.queryForm.createTimeE = this.createTime[1];
      } else {
        this.queryForm.createTimeS = "";
        this.queryForm.createTimeE = "";
      }
      this.handleQuery()
        }
      });
      
    }
    // getList(){
    //   console.log(this.type,'this.type');
    //  let httpAction = this.type==='2'||this.type==='3'?getAction:postAction
    //   postAction(this.url.list, this.queryForm).then((res) => {
    //       if (res.result) {
    //       const {records, total} = res.result
    //       this.ipagination && (this.ipagination.total = total || res.result.length)
    //       if(this.type==='2'||this.type==='3'){
    //         this.tableData = res.result.orderVolumeList
    //       }else{
    //         this.tableData = records || res.result;
    //       }
    //       }else{
    //         this.tableData = [];
    //       }
    //   });
    // }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
