<template>
  <div class="main-full-content">
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="100px" size="medium">
        <el-card class="box-card">
        <el-row class="inputs_row">
        <el-col :span="6">
          <el-form-item label="客户名称">
            <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch" type="textarea" placeholder="多个用,分隔"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="6">
            <el-form-item label="邮箱">
              <el-input v-model="queryForm.email" @keyup.enter.native="handleSearch" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电话">
              <el-input v-model="queryForm.phone" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
        <el-col :span="6">
          <el-form-item label="海运业务员">
            <f-select :dict="'sys_user_id'"
                      v-model="queryForm.salesmanId"
                      :isNeed="queryForm.salesmanId"
            >
            </f-select>
          </el-form-item>
        </el-col>
          <el-col :span="6">
            <el-form-item label="快递业务员">
              <f-select :dict="'sys_user_id'"
                        v-model="queryForm.kdSalesmanId"
                        :isNeed="queryForm.kdSalesmanId"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="source" label="客户来源">
              <f-select

                  clearable
                  filterable
                  class="f-full-width"
                  v-model="queryForm.source"
                  :isNeed="queryForm.source"
                  :dict="'cus_source'"
              >
              </f-select>
            </el-form-item>
          </el-col>
<!--          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">-->
<!--            <el-form-item label="海外业务员">-->
<!--              <f-select-->
<!--                      v-model="queryForm.oceanSalesmanId"-->
<!--                      :isNeed="queryForm.oceanSalesmanId"-->
<!--                      :dict="'sys_user_id'"></f-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        <el-col :span="6">
          <el-form-item label="跟进人">
            <f-select :dict="'sys_user_id'"
                      v-model="queryForm.follow"
                      :isNeed="queryForm.follow"
            > </f-select>
          </el-form-item>
        </el-col>
<!--        <el-col :span="6">-->
<!--          <el-form-item label="跟进结果">-->
<!--            <f-select-->
<!--              :dict="'cus_intent'"-->
<!--              v-model="queryForm.intent"-->
<!--              :isNeed="queryForm.intent"-->
<!--              placeholder="请选择"-->
<!--            >-->
<!--            </f-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="6" class="no_margin">
          <el-form-item label="注册性质">
            <f-select
              :dict="'register_type'"
              v-model="queryForm.regType"
              :isNeed="queryForm.regType"
              placeholder="请选择"
            >
            </f-select>
          </el-form-item>
        </el-col>
<!--        <el-col :span="6" class="no_margin">-->
<!--          <el-form-item label="最后跟进时间">-->
<!--            <div class="f-flex">-->
<!--              <el-date-picker-->
<!--                class="f-flex-1"-->
<!--                v-model="queryForm.followTimeStart"-->
<!--                type="datetime"-->
<!--                size="small"-->
<!--                placeholder="开始时间"-->
<!--                default-time="00:00:00"-->
<!--                value-format="yyyy-MM-dd HH:mm:ss"-->
<!--              >-->
<!--              </el-date-picker>-->
<!--              <span class="f-p-h-2">~</span>-->
<!--              <el-date-picker-->
<!--                class="f-flex-1"-->
<!--                v-model="queryForm.followTimeEnd"-->
<!--                type="datetime"-->
<!--                size="small"-->
<!--                placeholder="结束时间"-->
<!--                default-time="23:59:59"-->
<!--                value-format="yyyy-MM-dd HH:mm:ss"-->
<!--              >-->
<!--              </el-date-picker>-->
<!--            </div>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="6" class="no_margin">
          <el-form-item label="注册时间">
            <div class="f-flex">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.createTime"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
              <span class="f-p-h-2">~</span>
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.createTimeEnd"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="23:59:59"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </el-col>
          <el-col :span="6">
            <el-form-item label="添加人">
              <el-input v-model="queryForm.createBy" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="no_margin">
            <el-form-item label="分配时间">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.allotTime"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.allotTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户需求">
              <f-select :dict="'customer_need'"
                        v-model="queryForm.need"
                        :isNeed="queryForm.need"
              > </f-select>
            </el-form-item>
          </el-col>

<!--          <el-col :span="6">-->
<!--            <el-form-item prop="isShiqu" label="是否属于流失客户" label-width="120px">-->
<!--              <f-select class="f-full-width" v-model="queryForm.isShiqu" :isNeed="queryForm.isShiqu" :dict="'is_default'"></f-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="6">-->
<!--            <el-form-item prop="isPushStr" label="是否属于推广注册" label-width="120px">-->
<!--              <f-select class="f-full-width" v-model="queryForm.isPushStr" :isNeed="queryForm.isPushStr" :dict="'is_default'"></f-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        <el-col :span="6" class="no_margin">
          <el-form-item label-width="30px">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              >查询</el-button
            >
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
      <el-button
        type="primary"
        size="medium"
        @click="handleApprovalBatch"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.checkB"
        >批量审核</el-button
      >
      <el-button
        type="primary"
        size="medium"
        @click="handleBatTransfer"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.zyB"
        >批量转让</el-button
      >
      <el-button
          type="primary"
          size="medium"
          @click="handleBatBack"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.backB"
      >批量退回</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="handleBatAddFollower"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.setFB"
        >批量设置跟进人</el-button
      >
      <el-button
        type="primary"
        size="medium"
        class="c-theme-error-button"
        @click="handleBatDel"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.delB"
        >批量删除</el-button
      >
          <el-button
              type="primary"
              size="medium"
              @click="handleResetPassBatch"
              :disabled="selectionRows.length === 0"
              v-if="buttonShowList.rePB"
          >批量重置密码</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="handleSendEmail"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.sendE"
        >发送邮件</el-button
      >
      <el-button
        type="primary"
        size="medium"
        @click="handleSendMsg"
        :disabled="selectionRows.length === 0"
        v-if="buttonShowList.sendS"
        >发送短信</el-button
      >
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-download"
        @click="handleExportXls"
        v-if="buttonShowList.dc"
        >导出</el-button>
    </div>
    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
      <span
        class="stress f-m-l-12 f-cursor-pointer"
        @click="handleClearSelection"
        >清空</span
      >
    </div>
    <f-table
      v-loading="loading"
      ref="GTable"
      border
      size="medium"
      row-key="id"
      class="c-th-has-bgcolor"
      :columns="columns"
      :data="tableData"
      :cell-style="{ 'text-align': 'center' }"
      tableHeight="57vh"
      :isRecordTableScroll="true"
      @selection-change="handleSelectionChange"
      @sort-change="handleTableChange"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)"

                     v-if="buttonShowList.edit"
          >编辑</el-button>
          <el-button type="text" @click="handleFrozen(scope.row)"
                     v-if="buttonShowList.dj"
            >冻结</el-button
          >
          <el-button
              type="text"
              @click="handleApproval(scope.row)"
              :disabled="scope.row.isForeign == '1' && scope.row.isEmailAvail !== '1'"
              v-if="buttonShowList.check"
            >审核</el-button
          >
          <el-button
              type="text"
              @click="handleBack(scope.row)"
              v-if="buttonShowList.back"
          >退回</el-button>
          <el-button
              type="text"
              @click="handlAddRecord(scope.row)"
              v-if="buttonShowList.addV"
            >添加拜访</el-button
          >
          <el-button
              type="text"
              @click="handlAddFollower(scope.row)"
              v-if="buttonShowList.setF"
            >设置跟进人</el-button
          >
          <el-button
              type="text"
              @click="handlTransfer(scope.row)"
              v-if="buttonShowList.zy"
            >转让</el-button
          >
          <el-button
              type="text"
              @click="handleResetPass(scope.row)"
              v-if="buttonShowList.reP"
          >重置密码</el-button>
          <el-button
              type="text"
              @click="loginClient(scope.row.id)"
              v-if="buttonShowList.loginC"
          >客户端登录</el-button>
          <el-button
              type="text"
              @click="handleSendCheckEmail(scope.row)"
              v-if="buttonShowList.yzyj && scope.row.isForeign == '1' && scope.row.isEmailAvail !== '1'"
          >验证邮件</el-button>
        </template>
        <template v-else-if="scope.column.property === 'grade'">
          <span>{{parseDict(scope.row.grade,'customer_type2')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'intent'">
          <span>{{parseDict(scope.row.intent,'cus_intent')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'salesmanId'">
          <span>{{parseDict(scope.row.salesmanId,'all_user_id')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'regType'">
          <span v-if="scope.row.regType=='0'">企业</span>
          <span v-else-if="scope.row.regType=='1'">个人</span>
          <span v-else></span>
        </template>
        <template v-else-if="scope.column.property === 'username'">
          <a type="text" @click.prevent="handleEdit(scope.row)" style="color:#57a3f3;">{{scope.row.username}}</a>
        </template>
        <template v-else-if="scope.column.property === 'source'">
          <span>{{parseDict(scope.row.source,'cus_source')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'isForeign'">
          <span>{{scope.row.isForeign == '1' ? '是' : '否'}}</span>
        </template>
        <template v-else-if="scope.column.property === 'isEmailAvail'">
          <span>{{scope.row.isForeign == '1' ? (scope.row.isEmailAvail == '1' ? '是' : '否') : ''}}</span>
        </template>

<!--        <template v-else-if="scope.column.property === 'hasSendEmail'">-->
<!--          <span v-if="scope.row.sendEmailSixMonthNum>0" style="color: red">属于</span>-->
<!--          <span v-else>不属于</span>-->
<!--        </template>-->

<!--        <template v-else-if="scope.column.property === 'isPush'">-->
<!--          <span v-if="scope.row.isPush===1" style="color: red">属于</span>-->
<!--          <span v-else>不属于</span>-->
<!--        </template>-->
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
    </el-card>

    <el-dialog
      title="拜访"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      width="40%"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col>
            <el-form-item
                label="是否是异地"
                :label-width="formLabelWidth"
            >
              <el-radio-group v-model="form.isYidi">
                <el-radio label="0">否</el-radio>
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="类型" prop="type" :label-width="formLabelWidth">
              <f-select v-model="form.type" :dict="'customer_order_type'" :isNeed="form.type">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col >
            <el-form-item
              label="客户名"
              :label-width="formLabelWidth"
              :rules="rules.contact"
            >
              <el-input
                v-model="form.username"
                autocomplete="off"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col >
            <el-form-item
              label="拜访/沟通方式"
              prop="visitType"
              :label-width="formLabelWidth"
            >
              <f-select
                      v-model="form.visitType"
                      :is-need="form.visitType"
                      :dict="'visit_type'" > </f-select>
            </el-form-item>
          </el-col>
          <el-col >
            <el-form-item label="陪同拜访人" prop="visitor" :label-width="formLabelWidth">
              <f-select
                      :multiple="true"
                      v-model="form.visitorList"
                      :isNeed="form.visitorList"
                      :dict="'sys_user_id'" > </f-select>
            </el-form-item>
          </el-col>
          <el-col v-if="form.type !=3">
            <el-form-item
              label="货量情况"
              prop="volume"
              :label-width="formLabelWidth"
            >
              <el-input
                type="textarea"
                v-model="form.volume"
                auto-complete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.type !=3">
            <el-form-item
              label="发货周期"
              prop="shippingCycle"
              :rules="form.grade === '新客户' ? rules.shippingCycle : []"
              :label-width="formLabelWidth"
            >
              <el-input v-model="form.shippingCycle" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col >
            <el-form-item
              label="竞争对手"
              prop="competitor"
              :label-width="formLabelWidth"
            >
              <el-input v-model="form.competitor" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col >
            <el-form-item
              label="痛点和需求"
              prop="attentionPoint"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.attentionPoint"
                autocomplete="off"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.type =='3'">
            <el-form-item  label="目的国家地区"  label-width="110px" prop="destAddr">
              <el-input v-model="form.destAddr" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <!--          业务类型-->
          <el-col v-if="form.type =='3'">
            <el-form-item label="业务类型"  label-width="110px" >
              <el-select multiple filterabel clearable v-model="form.bussinessTypeList" @click.native="initBussinessTypeList">
                <el-option v-for="(item,index) in bussinessTypeList" :key="index" :value="item.value" :label="item.text"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col >-->
<!--            <el-form-item-->
<!--              prop="isNormalShipping"-->
<!--              label="是否正常发货"-->
<!--              :label-width="formLabelWidth"-->
<!--            >-->
<!--              <el-radio-group v-model="form.isNormalShipping">-->
<!--                <el-radio :label="'1'">是</el-radio>-->
<!--                <el-radio :label="'0'">否</el-radio>-->
<!--              </el-radio-group>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col>
            <el-form-item
              label="沟通事项"
              prop="communicate"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.communicate"
                autocomplete="off"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col >
            <el-form-item
              label="沟通结果"
              prop="communicationResult"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="form.communicationResult"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col>
          <el-form-item
                  label="工作/收款计划"
                  prop="workPlan"
                  :label-width="formLabelWidth"
          >
            <el-input v-model="form.workPlan" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
          <el-col>
            <el-form-item
                    label="货物属性"
                    v-if="form.type ==='3'"
                    :label-width="formLabelWidth"
            >
              <el-checkbox-group v-model="form.goodsPropList">
                <el-checkbox label="0">普货</el-checkbox>
                <el-checkbox label="1">带电</el-checkbox>
                <el-checkbox label="2">纯电</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
                    label="物流规格"
                    v-if="form.type ==='3'"
                    :label-width="formLabelWidth"
            >
              <el-checkbox-group v-model="form.circuStageList">
                <el-checkbox label="0">整柜</el-checkbox>
                <el-checkbox label="1">拼箱</el-checkbox>
                <el-checkbox label="2">电商物流双清到门</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
                    label="货量及发货频次"

                    :label-width="formLabelWidth"
            >
              <el-input v-model="form.goodsHz" show-word-limit maxlength="500" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop="url" label="附件" :label-width="formLabelWidth">
              <f-upload v-model="form.url" :baifang="form" :dir="'customer'"></f-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveVisit" :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <email-group-dialog :visible.sync="dialogFormVisibleEmail" :row="form" @ok="dialogFormVisible = false"></email-group-dialog>
    <msg-dialog :visible.sync="msgVisible" :row="row"></msg-dialog>
    <assign-dialog
      :visible.sync="assignVisible"
      :ids="ids"
      :row="row"
      :title="assignTitle"
    ></assign-dialog>
    <add-follower-dialog
      :visible.sync="visible"
      :ids="ids"
      :row="row"
      :title="followTitle"
    ></add-follower-dialog>
  </div>
</template>

<script>
import { putAction, deleteAction, postAction,getAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import MsgDialog from "./modules/MsgDialog";
import AssignDialog from "./modules/AssignDialog";
import AddFollowerDialog from "./modules/AddFollowerDialog";
import EmailGroupDialog from "./modules/EmailGroupDialog";
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "Follow",
  mixins: [QueryMixins],
  components: {
    MsgDialog,
    AssignDialog,
    AddFollowerDialog,EmailGroupDialog
  },
  data() {
    return {
      bussinessTypeList:[],
      dictCodes:['customer_type2','cus_intent','all_user_id','cus_source'],
      dialogFormVisible: false,
      formLabelWidth: "120px",

      form: {
        circuStageList:[],
        goodsPropList:[],
      },
      btnDisable: false,
      rules: {
        type:[
          {required:true,message:'请选择类型',trigger:'change'}
        ],
        visitType: [
          { required: true, message: "请选择拜访沟通方式", trigger: "change" },
        ],
        // visitor: [
        //   { required: true, message: "请选择业务人员", trigger: "change" },
        // ],
        shippingCycle: [
          { required: true, message: "请输入发货周期", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        communicationResult: [
          { required: true, message: "请输入沟通结果", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        attentionPoint: [
          { required: false, message: "请输入关心的点", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        workPlan: [
          { required: true, message: "请输入工作/收款计划", trigger: "blur" },
        ],
      },
      caozuolist: [
        { value: "0", label: "上门拜访" },
        { value: "1", label: "扫楼" },
        { value: "2", label: "电话" },
        { value: "3", label: "微信" },
        { value: "4", label: "QQ" },
      ],
      dialogFormVisibleEmail: false,
      ids: [],
      row: {},
      visible: false,
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: "",
      assignTitle: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "username",
          label: "客户名称",
          sortable: "custom",
          width: 140,
        },
        // {
        //   prop: 'hasSendEmail',
        //   label: '是否属于流失客户',
        //   headerType:'remark',
        //   detail:'超过六个月未下单的客户 并且发送邮件提醒销售之后',
        // },
        // {
        //   prop: 'isPush',
        //   label: '是否属于推广注册',
        //   headerType:'remark',
        //   detail:'通过推广注册新增的客户',
        // },
        {
          prop: 'name',
          label: '联系人',
          sortable: 'custom',
        },
        // {
        //   prop: "email",
        //   label: "邮箱",
        //
        // },
        // {
        //   prop: "phone",
        //   label: "电话",
        //
        // },
        {
          prop: "grade",
          label: "客户等级",
          sortable: "custom",
          width: 140,
        },
        {
          prop: 'source',
          label: '来源',
        },
        {
          prop: 'createBy',
          label: '添加人',
        },
        {
          prop: "follow",
          label: "跟进人",
          sortable: "custom",
        },
        {
          prop: "doc",
          label: "客服",

        },
        {
          prop: "billman",
          label: "收款人",

        },
        {
          prop: "salesman",
          label: "业务员",
          sortable: "custom",
        },
        // {
        //   prop: "kdSalesman",
        //   label: "快递业务员",
        //   sortable: "custom",
        // },
        // {
        //   prop: "oceanSalesman",
        //   label: "海外业务员",
        //   sortable: "custom",
        // },
        {
          prop: "payType",
          label: "付款方式",
        },
        {
          prop: "regType",
          label: "注册性质",
        },
        {
          prop: "createTime",
          label: "注册时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "allotTime",
          label: "分配时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "isForeign",
          label: "国外",
          width: 40
        },
        {
          prop: "isEmailAvail",
          label: "邮箱验证",
          width: 60
        },
        // {
        //   prop: "intent",
        //   label: "跟进结果",
        //   sortable: "custom",
        // },
        {
          prop: "remark",
          label: "备注",
          overFlow:true
        },
        {
          prop: "action",
          label: "操作",
          width: 175,
        },
      ],
      url: {
        resetOne:'/customer/resetOne',
        resetOneBatch:'/customer/resetOneBatch',
        list: "/customer/followingList",
        exportXlsUrl: "/customer/exportGjXls",
        check: "/customer/userPassById",
        deleteBatch: "/customer/deleteBatch",
        approvalBatch: "/customer/userPassBatch",
        fronzenUrl: "/customer/forbidUserById/",
        baturl: "/customer/tuihui",
        batbatch: "/customer/tuihuiBatch",
        getsalesmanlist: "/sys/user/salesman/list",
        getuserlist: "/sys/user/list",
        addVisit: "/customer/visitLog/add",
        sendEmail: "/sys/supplier/sendEmail",
        getBussinessTypeList:'/sys/dict/getDictItems/ocean_bussiness_type',
        sendVerificationEmailToCheckAvailability: '/sys/sendVerificationEmailToCheckAvailability',
      },
      salesmanlist: [],
      userlist: [],
      //意向 0无意向 1有意向 2成交

      emailList: [],
    };
  },
  methods: {
    loginClient(id){
      let api = "http://120.25.172.133:9800";
      // let api = "http://192.168.1.128:8088";
      if(process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
        api = "https://do.do2do.com";
        console.log('线上环境，跳转线上的客户端')
      }
      let params = '?cusId=' + id;
      console.log('跟进中客户登录跳转链接',api + params)
      window.open(api + params);
    },
    initBussinessTypeList(){
      getAction(this.url.getBussinessTypeList).then(res=>{
        this.bussinessTypeList = res.result;
      })
    },
    handleResetPass(row) {
      this.$confirm(`确定将【${row.username}】重置密码?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.resetOne, row).then(res => {
          // this.$message({
          //   type: 'success',
          //   message: res.message
          // })
          this.$alert(res.message, '重置密码结果', {
            dangerouslyUseHTMLString: true
          });
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleResetPassBatch() {
      this.$confirm(`确定重置密码?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.resetOneBatch, this.selectionRows).then(res => {
          // this.$message({
          //   type: 'success',
          //   message: res.message
          // })
          this.$alert(res.message, '重置密码结果', {
            dangerouslyUseHTMLString: true
          });
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    sendEmail() {
      this.form.emails = this.form.emails.split(",");
      this.form.usernames = this.form.usernames.split(",");
      postAction(this.url.sendEmail, this.form)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            //关闭弹窗
            this.dialogFormVisibleEmail = false;
            this.handleQuery();
          }
        })
    },
    saveVisit() {
      //保存拜访记录
      this.btnDisable = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.goodsPropList) {
            this.form.goodsProp = this.form.goodsPropList.join(',')
          }
          if (this.form.circuStageList) {
            this.form.circuStage = this.form.circuStageList.join(',')
          }
          postAction(this.url.addVisit, this.form)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.handleQuery();
              } else {
                this.btnDisable = false;
              }
            })
            .catch((err) => {
              this.btnDisable = false;
            });
        } else {
          this.btnDisable = false;
        }
      });
    },
    handleEdit({ id }) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/customer/Edit');
      this.$router.push(`/customer/Edit?id=${id}`);
    },
    handleApproval({ id, username }) {
      this.$confirm(`确定审批通过${username}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.check + "?ids=" + this.selectedRowKeys, {
            id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    handleApprovalBatch({}) {
      this.$confirm(`确定审批通过${this.nameList.join(",")}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          try {
            this.selectionRows && this.selectionRows.forEach((item, index) => {
                if (item.isForeign == '1' && item.isEmailAvail !== '1') {
                  throw Error();         //满足条件，跳出循环
                }
              })
            } catch (e) {
              this.$message({
                type: "warning",
                message: '勾选的客户存在海外客户邮箱未验证， 无法审核',
              });
              return;
            }
          putAction(
            this.url.approvalBatch + "?ids=" + this.selectedRowKeys
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    //退回
    handleBack(row) {
      if(row.haveDebt == '1') {
          this.$message.warning(`【${row.username}】存在欠款的情况，无法退回公海`);
          return;
      }
      this.$confirm(`确定将【${row.username}】退回到公海?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.baturl, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleBatApproval() {
      console.log("批量操作对象：", this.selectedRowKeys);
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${this.nameList.join(",")}】退回到公海?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.approvalBatch, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleBatTransfer() {
      this.ids = this.selectedRowKeys;
      this.assignTitle = "批量转让";
      this.assignVisible = true;
    },
    handleBatBack() {
      console.log("批量操作对象：", this.selectedRowKeys);
      let isArrears = false, ArrearsUserName = [];
      this.selectionRows.forEach(item => {
          if(item.haveDebt == '1') {
              isArrears = true;
              ArrearsUserName.push(item.username);
          }
      });
      if(isArrears) {
          this.$message.warning( `【${ArrearsUserName.join(",")}】存在欠款的情况，无法退回公海`);
          return;
      }
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${this.nameList.join(",")}】退回到公海?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(
            this.url.batbatch + "?ids=" + this.selectedRowKeys.join(","),
            { ids: this.selectedRowKeys.join(",") }
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleBatAddFollower() {
      this.ids = this.selectedRowKeys;
      this.followTitle = "批量添加跟进人";
      this.visible = true;
    },
    handleBatDel() {
      this.$confirm(`确定删除${this.nameList.join(",")}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },

    handleSendEmail() {
      this.dialogFormVisibleEmail = true;
      //给邮箱赋值
      //使用$set
      this.$set(this.form, "receiver", this.emailList.join(";"));
      // this.form.emails = this.emailList.join(',');
      console.log("发送邮件", this.form);
    },
    handleSendCheckEmail(row) {
      let params = {
          cusId: row.id,
          email: row.email
      }
      this.$store.commit("setFullscreenLoading", true);
      getAction(this.url.sendVerificationEmailToCheckAvailability, params).then((res) => {
          this.$store.commit("setFullscreenLoading", false);
          this.$message({
              type: 'success',
              message: res.message
          })
      }).catch(err => {
          this.$store.commit("setFullscreenLoading", false);
      })
    },
    handleSendMsg() {
      this.row = this.selectionRows[0];
      this.msgVisible = true;
    },
    handleFrozen(row) {
      this.$confirm(`确定将【${row.username}】冻结?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.fronzenUrl, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
    },
    handlTransfer(row) {
      this.row = row;
      this.ids = [row.id];
      this.assignTitle = "转让";
      this.assignVisible = true;
    },
    handlAddRecord(row) {
      //给拜访记录赋值
      this.form={circuStageList:[],goodsPropList: []};
      this.btnDisable = false;
      this.form.username = row.username;
      this.form.cusId = row.id;
      this.form.grade = row.grade;
      this.dialogFormVisible = true;
    },
    handlAddFollower(row) {
      this.row = row;
      this.ids = [row.id];
      this.followTitle = "添加跟进人";
      this.visible = true;
    },
  },
  created() {
    this.queryForm.initUser = 1;
    this.queryForm.initSalesman = 1;
  },
  computed : {
    buttonShowList() {
      return {
        'checkB':this.$btnIsShow('customer/Follow','0','批量审核'),
        'zyB':this.$btnIsShow('customer/Follow','0','批量转让'),
        'backB':this.$btnIsShow('customer/Follow','0','批量退回'),
        'setFB':this.$btnIsShow('customer/Follow','0','批量设置跟进人'),
        'delB':this.$btnIsShow('customer/Follow','0','批量删除'),
        'rePB':this.$btnIsShow('customer/Follow','0','批量重置密码'),
        'sendE':this.$btnIsShow('customer/Follow','0','发送邮件'),
        'sendS':this.$btnIsShow('customer/Follow','0','发送短信'),
        'dc':this.$btnIsShow('customer/Follow','0','导出'),
        'edit':this.$btnIsShow('customer/Follow','1','编辑'),
        'dj':this.$btnIsShow('customer/Follow','1','冻结'),
        'check':this.$btnIsShow('customer/Follow','1','审核'),
        'back':this.$btnIsShow('customer/Follow','1','退回'),
        'addV':this.$btnIsShow('customer/Follow','1','添加拜访'),
        'setF':this.$btnIsShow('customer/Follow','1','设置跟进人'),
        'zy':this.$btnIsShow('customer/Follow','1','转让'),
        'reP':this.$btnIsShow('customer/Follow','1','重置密码'),
        'loginC':this.$btnIsShow('customer/Follow','1','客户端登录'),
        'yzyj':this.$btnIsShow('customer/Follow','1','验证邮件'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
