<template>
    <el-dialog
            :title="row.id?'编辑':'新增' + '拼柜仓库最大可装柜个数'"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


            <el-form-item prop="lclPlaceId" label="拼柜仓库" :label-width="formLabelWidth">
                <f-select v-model="form.lclPlaceId" :isNeed="form.lclPlaceId" maxlength="20" show-word-limit
                          :dict="'lclPlace'" ></f-select>
            </el-form-item>

            <el-form-item prop="nineLength" label="9点可装柜个数" :label-width="formLabelWidth">
                <el-input v-model="form.nineLength" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                          type="number"
                ></el-input>
            </el-form-item>

            <el-form-item prop="elevenLength" label="11点可装柜个数" :label-width="formLabelWidth">
                <el-input v-model="form.elevenLength" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                          type="number"
                ></el-input>
            </el-form-item>

            <el-form-item prop="thirteenLength" label="13点可装柜个数" :label-width="formLabelWidth">
                <el-input v-model="form.thirteenLength" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                          type="number"
                ></el-input>
            </el-form-item>

            <el-form-item prop="fifteenLength" label="15点可装柜个数" :label-width="formLabelWidth">
                <el-input v-model="form.fifteenLength" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                          type="number"
                ></el-input>
            </el-form-item>

            <el-form-item prop="seventeenLength" label="17点可装柜个数" :label-width="formLabelWidth">
                <el-input v-model="form.seventeenLength" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                          type="number"
                ></el-input>
            </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";
    import {numValidator} from '@/utils/validator'

    export default {
        name: 'lclPlaceMaxContainerLoadLengthDialog',
        mixins: [QueryMixins],
        props: {
            visible: {
                type: Boolean
            },

            row: {
                type: Object,
                default: () => {
                }
            },
        },
        data() {
            return {
                disableMixinMounted: true,
                form: {},
                formLabelWidth: '120px',
                rules: {
                    lclPlaceId: [
                        {required: true, message: '请选择拼柜仓库', trigger: 'change'},
                    ],
                    nineLength: [
                        {required: true, message: '请输入9点可装柜个数', trigger: 'change'},
                        {validator:numValidator,trigger:'change'}
                    ],
                    elevenLength: [
                        {required: true, message: '请输入11点可装柜个数', trigger: 'change'},
                        {validator:numValidator,trigger:'change'}
                    ],
                    thirteenLength: [
                        {required: true, message: '请输入13点可装柜个数', trigger: 'change'},
                        {validator:numValidator,trigger:'change'}
                    ],
                    fifteenLength: [
                        {required: true, message: '请输入15点可装柜个数', trigger: 'change'},
                        {validator:numValidator,trigger:'change'}
                    ],
                    seventeenLength: [
                        {required: true, message: '请输入17点可装柜个数', trigger: 'change'},
                        {validator:numValidator,trigger:'change'}
                    ],
                },
                url: {
                    add: '/warehouse/outOrderly/addOrUpdateOne',

                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.row && this.row.id) {
                    this.form = JSON.parse(JSON.stringify(this.row));
                }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.form = {};
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$refs['form'].resetFields();
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }

    .addEditBox {
        max-height: 615px;
        overflow: scroll;
    }
</style>
