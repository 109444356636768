<template>

    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="billForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="billForm.orderNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户">
                            <el-input v-model="billForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务员">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'salesmanId'"
                                      v-model="billForm.salesmanId"
                                      placeholder="请选择" style="width:100%">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作员" label-width="80px">
                            <f-select
                                :dict="'operationsId'"
                                v-model="billForm.operateId"
                                :father-val="billForm.operateId"
                                :is-need="billForm.operateId"
                                placeholder="请选择"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="费用币种">
                            <f-select
                                v-model="billForm.moneyType"
                                :isNeed="billForm.moneyType"
                                dict="bd_cur"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                class="f-flex-1 f-flex-time"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>

                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                class="f-flex-1 f-flex-time"
                                v-model="otime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
<!--                        <el-form-item label="订单类型">-->
<!--                            <f-select-->
<!--                                v-model="billForm.orderType"-->
<!--                                :isNeed="billForm.orderType"-->
<!--                                dict="all_order_type"></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleCheck">查询
                            </el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <div class="tab_class_billed">
            <el-tabs default-active-key="os" type="card" v-model="billForm.orderType" @tab-click="tabClick">
                <el-tab-pane label="海运" key="os" tab="海运" name="0">
                    <wait-bill-os ref="waitBillOs" @ok="handleCheck">海运</wait-bill-os>
                </el-tab-pane>
                <el-tab-pane label="快递" key="kd" tab="快递" name="2">
                    <wait-bill-kd ref="waitBillKd" @ok="handleCheck">快递</wait-bill-kd>
                </el-tab-pane>
            </el-tabs>
        </div>


<!--            </f-table>-->
<!--            <el-row class="f-text-right f-p-v-8">-->
<!--                <el-pagination-->
<!--                    v-bind="ipagination"-->
<!--                    layout="total, sizes, prev, pager, next, jumper"-->
<!--                    @size-change="handleSizeChange"-->
<!--                    @current-change="handleCurrentChange">-->
<!--                </el-pagination>-->
<!--            </el-row>-->
<!--        </el-card>-->


        <no-convert-bill ref="noConvert" @ok="handleCheck"></no-convert-bill>
        <convert-bill ref="convert" @ok="handleCheck"></convert-bill>
    </div>


</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction, putAction, deleteAction} from "@/api/manage";
import noConvertBill from "./noConvertBill"
import convertBill from "./convertBill"
import {setUpRouteUtil} from '@/utils/util';
import waitBillOs from "@/views/hyFinanceManage/modules/receive/waitBillOs";
import waitBillKd from "@/views/hyFinanceManage/modules/receive/waitBillKd";


export default {
    mixins: [QueryMixins],
    components: {
        noConvertBill,
        convertBill,
        waitBillOs,waitBillKd
    },
    data() {
        return {
            disableMixinMounted: true,
            dictCodes: ['cus_pay_type', 'sys_create_by'],
            editStartOptions: {
                disabledDate: time => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                }
            },
            editStopOptions: {
                disabledDate: time => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                    );
                }
            },
            kdBillType: '',
            billForm: {orderType:'0'},
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {
                ids: []
            },
            columns: [
                {
                    type: 'selection',
                    width: 50
                },
                // {
                //     prop: 'action',
                //     label: '操作',
                // },
                {
                    prop: 'orderNo',
                    label: '工作单号',
                    width: 140,
                },
                {
                    prop: 'shipmentBoard',
                    label: '开船时间',
                    width: 140,
                },
                {
                    prop: 'runOrderNo',
                    label: '运单号',
                    width: 140,
                },
                {
                    prop: 'realAgent',
                    label: '客户',
                    width: 140,
                },
                {
                    prop: 'payType',
                    label: '付款方式',
                },
                {
                    prop: 'agent',
                    label: '结算对象',
                },
                {
                    prop: 'feename',
                    label: '费用中文',
                },
                {
                    prop: 'feenameUs',
                    label: '费用英文',
                },
                {
                    prop: 'price',
                    label: '价格',
                },
                {
                    prop: 'quantity',
                    label: '数量',
                },
                {
                    prop: 'moneyType',
                    label: '费用币种',
                },
                {
                    prop: 'total',
                    label: '合计',
                },
                {
                    prop: 'createBy',
                    label: '录入人',
                },
                {
                    prop: 'screateTime',
                    label: '录入时间',
                },
                {
                    prop: 'createTime',
                    label: '下单时间',
                },
                // {
                //     prop: 'rate',
                //     label: '汇率',
                // },
                // {
                //     prop: 'rateMoney',
                //     label: '折合金额',
                // },

            ],

            url: {
                list: '/shipping/hySettleReceive/list',
                openKdOrderReceiveBill: '/file/jasper/openKdOrderReceiveBill',
                openKdOrderReceiveBillExcel: '/file/jasper/openKdOrderReceiveBillExcel',
                checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                openBill: '/file/jasper/openReceiveBill',
                sendBillMail: '/shipping/hySettleReceive/sendBillMail',
                delSettleBatch: '/shipping/hySettleReceive/delSettleBatch',
            },

            rules: {
                realCtn: [
                    {required: true, message: '请输入实际总件数', trigger: 'blur'},
                    {pattern: /^[1-9]\d*$/, message: '只能输入整数', trigger: 'blur'},

                ],
                realKg: [
                    {required: true, message: '请输入实际总重量', trigger: 'blur'},
                    {
                        pattern: /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: '只能输入最多三位小数的数字',
                        trigger: 'change'
                    }
                ],
            },
            stime: [],
            otime: [],
        };
    },
    // computed: {
    //
    // },
    created() {
        this.$nextTick(()=> {
            this.tabClick()
        })
    },
    methods: {
        tabClick(){
            if(this.billForm.orderType=='0'){
                this.$refs.waitBillOs.handleCheck(this.billForm);
            }else if (this.billForm.orderType=='2'){
                this.$refs.waitBillKd.handleCheck(this.billForm);
            }
        },

        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD") !== -1) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            } else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        handleSelectionChange(selection) { // 多选
            console.log(selection)
            let arr = []
            let nos = []
            this.nameList = []
            this.cnamelist = []
            this.emailList = []
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]['id'])
                nos.push(selection[i]['orderNo'])
                this.nameList.push(selection[i]['username'])
                this.cnamelist.push(selection[i]['cname'])
                this.emailList.push(selection[i]['email'])
            }
            this.selectionRows = selection
            this.selectedRowKeys = [...arr]
            this.selectedNos = [...nos]
            console.log('selectedNos', this.selectedNos)
        },
        handleReceive() {
            console.log("申请收款以下id的费用", this.selectedRowKeys)
        },
        handleReset() {
            this.billForm = {}
            this.otime = []
            this.stime = []
            this.ipagination.currentPage = 1
            // this.billForm.orderType='0'
            this.$set(this.billForm,'orderType',"0")
            this.handleCheck()
        },
        handleCheck() {

            // this.loading = true
            if (this.otime && this.otime.length > 1) {
                this.billForm.createTimeS = this.otime[0]
                this.billForm.createTimeE = this.otime[1]
            } else {
                this.billForm.createTimeS = '';
                this.billForm.createTimeE = '';
            }
            if (this.stime && this.stime.length > 1) {
                this.billForm.sCreateTimeS = this.stime[0]
                this.billForm.sCreateTimeE = this.stime[1]
            } else {
                this.billForm.sCreateTimeS = '';
                this.billForm.sCreateTimeE = '';
            }
            this.tabClick(this.billForm)
            // let form = {...this.billForm, ...this.filterForm}
            // for (let item in form) {
            //     if (typeof form[item] === 'string') {
            //         form[item] = form[item].trim()
            //         if (form[item] === '') {
            //             form[item] = null
            //         }
            //     }
            // }
            // const {prop, order} = this.isorter
            // // 传入的参数
            // const params = {
            //     ...form,
            //     column: prop,
            //     order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
            // }
            // //判断 是否要查询startTime
            //
            // if (!params.column) {
            //     if (this.billForm.column === 'startTime') {
            //         this.$set(params, 'column', 'startTime')
            //     } else {
            //         this.$set(params, 'column', 'createTime')
            //     }
            //     //提货中查询
            //     if (this.billForm.column === 'distributionTime') {
            //         this.$set(params, 'column', 'distributionTime')
            //     }
            // }
            // if (this.ipagination) {
            //     params.pageNo = this.ipagination.currentPage
            //     params.pageSize = this.ipagination.pageSize
            // }
            // console.log("查询对象", params)
            //
            // getAction(this.url.list, params).then((res) => {
            //     if (res.success) {
            //         this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
            //         const {records, total} = res.result
            //         this.tableData = records || res.result;
            //
            //         this.ipagination && (this.ipagination.total = total || res.result.length)
            //     }
            // }).finally(() => {
            //     this.loading = false
            // })
        },
        noConvertBill() {

            console.log("申请收款以下id的费用", this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if (preArr.length > 1) {
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if (preArr[0] === 'KD') {
                this.$message.warning("快递单费用请选择快递账单")
                return
            }
            //非折合支持不同币种申请收款
            // let countCur = [];
            // this.selectionRows.forEach(item => {
            //     countCur.push(item.moneyType)
            // });
            // let curArr = Array.from(new Set(countCur));
            // console.log('curArr->',curArr)
            // if(curArr.length > 1){
            //     this.$message.warning("所选费用的费用币种不一致！")
            //     return
            // }

            let count = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
            });
            let arr = Array.from(new Set(count));
            if (arr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos, this.selectionRows[0].realAgent)
        },
        convertBill() {

            console.log("申请收款以下id的费用", this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if (preArr.length > 1) {
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if (preArr[0] === 'KD') {
                this.$message.warning("快递单费用请选择快递账单")
                return
            }
            let count = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
            });
            console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            if (arr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            this.$refs.convert.edit(this.selectedRowKeys, this.selectedNos, this.selectionRows[0].realAgent)
        },
        kdBill() {
            if (this.kdBillType === '') {
                this.$message.warning('请选择快递账单类型之后再进行下载')
                return
            }

            console.log("申请收款以下id的费用", this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if (preArr.length > 1) {
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if (preArr[0] !== 'KD') {
                this.$message.warning("海运空运的费用请选择海运空运账单")
                return
            }
            let count = [];
            let curCount = [];
            let nameCount = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId);
                nameCount.push(item.settlement);
                curCount.push(item.moneyType)
            });
            console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            let curArr = Array.from(new Set(curCount));
            let nameArr = Array.from(new Set(nameCount));
            if (arr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            if (nameArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            if (curArr.length > 1) {
                this.$message.warning("所选费用的币种不一致！")
                return
            }
            let obj = {
                ids: this.selectedRowKeys,
                orderNos: this.selectedNos,
                billTitle: '5',
                billType: '0',//快递采用非折合
                realMoneyType: curArr[0]
            }
            let nos = Array.from(new Set(this.selectedNos)).join('-')
            let filename = this.selectionRows[0].realAgent + '-' + this.getDateStr() + '快递账单' + nos

            let url = this.url.openBill
            let type = {"type": "application/pdf"}
            if (this.kdBillType === '0') {
                url = this.url.openKdOrderReceiveBillExcel
                type = {type: 'application/vnd.ms-excel'}
            }
            if (this.kdBillType === '1') {
                url = this.url.openKdOrderReceiveBill
            }
            let that = this;
            postAction(this.url.checkBill, this.selectedRowKeys).then((res) => {
                //检测是同一个结算对象 才进行下载
                if (res.success) {
                    postAction(url, obj, {responseType: 'blob'})
                        .then(res => {
                          if (res && res.type === 'application/json') {
                            const reader = new FileReader()
                            reader.onload = function () {
                              const message = JSON.parse(reader.result).message
                              that.$message.error(message);// 将错误信息显示出来
                            }
                            reader.readAsText(res)
                            return false;
                          }else {
                            console.log(res)
                            if (res.size === 0) {
                              this.$message.error('导出失败，请稍后再试或联系管理员')
                              return
                            }
                            var binaryData = [];
                            binaryData.push(res);
                            if (this.kdBillType === '0') {
                              let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                              let link = document.createElement('a');
                              link.style.display = 'none';
                              link.href = url;
                              link.setAttribute('download', filename + '.xlsx');
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);//下载完成移除元素
                              window.URL.revokeObjectURL(url) //释放掉blob对象
                            } else {
                              let urlencoded = window.URL.createObjectURL(new Blob(binaryData, type));
                              window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                            }


                            // let file = new Blob(binaryData)
                            // file.lastModifiedDate = new Date();
                            // file.name = "mailAtt.pdf"
                            // let formData = new FormData();
                            // formData.append("file", file);
                            // formData.append("orderNo", this.selectedNos[0])
                            // //发送账单邮件给客户
                            // postAction(this.url.sendBillMail,formData).then((res)=>{
                            //     console.log(res)
                            // })
                            this.visible = false
                            setTimeout(() => {   //设置延迟执行
                              this.$message.success("开账单成功！")
                              // this.$emit('ok')
                              this.handleCheck();
                            }, 3000)
                          }

                        })

                }
            })
            //快递开账单
            // let obj = {
            //     ids:this.selectedRowKeys,
            //     orderNos:this.selectedNos,
            // }
            // console.log('请求的快递账单',obj)
            // postAction(this.url.checkBill,this.selectedRowKeys).then((res) => {
            //     //检测是同一个结算对象 才进行下载
            //     console.log('res',res)
            //     if(res.success){
            //         postAction(this.url.openKdOrderReceiveBill, obj, {responseType: 'blob'})
            //             .then(res => {
            //                 console.log(res)
            //                 var binaryData = [];
            //                 binaryData.push(res);
            //                 let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
            //                     {"type": "application/pdf"}));
            //                 window.open("/static/pdf/web/viewer.html?file=" + encodeURIComponent(urlencoded));
            //
            //                 this.visible=false
            //
            //                 setTimeout(()=>{   //设置延迟执行
            //                     this.$message.success("开账单成功！")
            //                     this.handleQuery();
            //                 },3000)
            //             })
            //
            //
            //         // this.handleQuery();
            //     }else{
            //         this.$message({
            //             type: 'error',
            //             message: res.message
            //         })
            //     }
            // })
        },
        getOrderPre() {
            let count = [];
            this.selectionRows.forEach(item => {
                let pre = this.deleteNum(item.orderNo)
                if (pre === 'KD') {
                    count.push(pre)
                } else {
                    count.push('OS')
                }
                // count.push(this.deleteNum(item.orderNo))
            });
            console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            console.log('账单前缀', arr)

            return arr
        },
        deleteNum(str) {
            let reg = /[0-9]+/g;
            return str.replace(reg, "");

        },
        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.handleCheck()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.handleCheck()
        },
        delSettle() {
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用")
                return
            }
            console.log(this.selectedRowKeys)
            let ids = this.selectedRowKeys.join(",")
            this.$confirm(`确定删除费用?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success',
                closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.delSettleBatch, {ids}).then(res => {

                    this.$message.success("删除费用成功");
                    //关闭弹窗
                    this.handleCheck()

                })
            }).catch(() => {
                this.handleCheck()
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.flex_wait_box {
    display: flex;

    .select_width {
        width: 200px;
        margin: 0 10px;
    }
}
</style>
<style lang="scss">
.tab_class_billed {
    .el-tabs--top > .is-top {
        position: unset;
        padding-left: 0;
        width: 100%;
    }
    .el-tabs--top > .el-tabs__content {
        top: 0;
    }
    .el-tabs__header {
        margin: 0;
    }
    .el-tabs--card > .el-tabs__header {
        border-bottom: 0;
    }
}
</style>
