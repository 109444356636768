<template>
  <!-- 批量下载入仓单 -->
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="form" label-width="80px" size="medium" :rules="rules">
        <el-row :gutter="24" class="inputs_row">
          <el-col :md="12" :sm="24">
            <el-form-item label="订单号">
              <el-input class="textarea_table" v-model="form.orderNo" type="textarea"
                 placeholder="请输入订单号多个逗号分隔" :rows="4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="6">
            <el-form-item label="关联编号">
              <el-input  v-model="form.relatedNo" placeholder="请输入订单关联编号啊" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button size="medium" type="primary" icon="el-icon-download" @click="getDownLoadOrderNos">入仓单PDF</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import { printPdfDoc } from "@/utils/downloadJasper";
  import { getAction } from "../../api/manage";


  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        form: {
          orderNo: '',
          relatedNo:'',
        },
        rules: {
          orderNo: [
            { required: true, message: "请输入要下载入仓单的单号", trigger: "blur" },
          ],
        },
        url: {
          //下载入仓单
          downloadRucang: "/file/jasper/download/rucangBatch",
          getDownLoadOrderNos:"file/jasper/download/getDownLoadOrderNos"
        }
      }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      handleReset() {
        this.$set(this.form, 'orderNo', '');
        this.$set(this.form, 'relatedNo', '');
      },
      getDownLoadOrderNos(){
        if(this.form.relatedNo && this.form.relatedNo !=null && this.form.relatedNo!=''){
          let params = { orderNo: this.form.orderNo ,relatedNo:this.form.relatedNo};
          getAction(this.url.getDownLoadOrderNos,params).then(res =>{
            const h = this.$createElement;
            this.$msgbox({
              title: '批量打印入仓单',
              message: h('p', null, [
                h('span', null, '确认批量入仓单订单号：'  ),
                h('i', { style: 'color: teal' }, res.result)
              ]),
              showCancelButton: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = '执行中...';
                  this.printPdfDocOrder()
                  instance.confirmButtonLoading = false;
                  done();
                } else {
                  done();
                }
              }
            })
        })
        }else{
          this.printPdfDocOrder()
        }

      },
      // 直接打印后台返回的pdf
      printPdfDocOrder() {
        if(!this.form.orderNo && !this.form.relatedNo) {
          this.$message.warning('请填写订单号或者关联编号之后在进行下载！');
          return;
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = { orderNo: this.form.orderNo, t: 'pdf' ,relatedNo:this.form.relatedNo};
            printPdfDoc(this.url.downloadRucang, params);
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>