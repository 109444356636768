<!--待提交-->
<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="保单号">
              <el-input v-model="queryForm.insuranceNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="操作员"> <!--待提交 已拒绝-->
              <f-select clearable filterable class="f-full-width"
                        :dict="'operationsId'"
                        v-model="queryForm.operateId"
                        :isNeed="queryForm.operateId"
                        placeholder="请选择" style="width:100%">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="72vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderStatus'">
            <span v-if="scope.row.orderStatus === '0'">待提交</span>
            <span v-else-if="scope.row.orderStatus === '1'">待审核</span>
            <span v-else-if="scope.row.orderStatus === '2'">审核通过</span>
            <span v-else-if="scope.row.orderStatus === '3'">审核拒绝</span>
            <span v-else-if="scope.row.orderStatus === '4'">已承保</span>
            <span v-else-if="scope.row.orderStatus === '5'">索赔中</span>
            <span v-else>已索赔</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
                    style="color: #00a0e9"
                    @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { getAction, postAction, putAction } from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "Potential",
  mixins: [QueryMixins],
  data() {
    return {
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "订单号",
          sortable: "custom",
        },
        {
          prop: "insuranceNo",
          label: "保单号",
          sortable: "custom",
        },
        {
          prop: "insured",
          label: "被保险人",
          sortable: "custom",
          width: 140,
        },
        {
          prop: "insuredTime",
          label: "投保时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "claimTime",
          label: "报案时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "claimTotal",
          label: "索赔金额",
          sortable: "custom",
          width: 130,
        },
        {
          prop: "orderStatus",
          label: "理赔状态",
          sortable: "custom",
          width: 160,
        },
      ],
      url: {
        list: "/order/insurance/list",
      },
    };
  },
  methods: {
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push("/docpage/Edit?orderNo=" + orderNo);

    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.pageType = "4";
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  created() {
    this.queryForm.pageType = "4";
  },
};
</script>

<style lang="scss" scoped>
</style>
