<template>
    <el-dialog
            :title="title"
            width=1000
            :visible="visible"
            @ok="handleOk"
            @cancel="handleCancel"
            :before-close="handleCancel"
            :modal="false"
            :close-on-click-modal="false">
        <template slot="footer">
            <el-button type="primary" @click="handleCancel" style="margin-left: 15px;">取消</el-button>
            <el-button type="primary" @click="handleOk">下单
            </el-button>
        </template>

        <el-form :form="form" ref="form" :label-position="labelPosition" v-loading="confirmLoading">
            <template v-if="accountName == 'GN2' || accountName =='PL' || accountName == 'NY' || accountName == 'CA-5RR'  || accountName == 'G92' || accountName == 'SS-CWT'">
                <el-form-item label="Shipping Service">
                    <el-input v-model="PriceDetail.service_name" disabled="disabled"/>
                </el-form-item>
                <el-form-item label="Gross Charge">
                    <el-input :value="'$'+PriceDetail.total_charges" disabled="disabled"/>
                </el-form-item>

                <!--                <el-form-item label="Discount Information">-->
                <!--                </el-form-item>-->
                <el-tag type="info" style="font-size: unset;">Discount Information</el-tag>
                <el-form-item class="lables"
                              v-for="(value, key) in PriceDetail.discount_info"
                              :key="key"
                              :label="key">
                    <!--          <el-input v-if="key=='percentage'"  :value="value+'%'"  disabled="disabled" />-->
                    <!--          <el-input v-else  :value="'$'+value"  disabled="disabled" />-->
                    <!--                    <a v-if="key == 'percentage'" v-html="value" style="color: rgba(0, 0, 0, 0.65);"></a>-->
                    <span v-html="value" style="color: rgba(0, 0, 0, 0.65);"></span>
                </el-form-item>

                <!--                <el-form-item label="Miscellaneous Charges"></el-form-item>-->
                <el-tag type="info" style="font-size: unset;">Miscellaneous Charges</el-tag>
                <el-form-item v-if="Object.keys(PriceDetail.miscellaneous_charges).length===0" label="--" class="lables"></el-form-item>
                <el-form-item class="lables"
                              v-for="(value, key, index) in PriceDetail.miscellaneous_charges"
                              :key="index"
                              :label="key">
                    <span v-html="value" style="color: rgba(0, 0, 0, 0.65);"></span>
                    <!--          <el-input  v-html="value"  disabled="disabled" />-->
                </el-form-item>

                <!-- 主表单区域 -->
                <el-form-item label="UPS Commission Fees">
                    <el-input :value="'$'+PriceDetail.commission_charge" disabled="disabled"/>
                </el-form-item>
                <el-form-item label="Shipment Total Charge">
                    <el-input :value="'$'+PriceDetail.final_price" disabled="disabled"/>
                </el-form-item>
            </template>

            <template v-if="accountName == 'PT'">
                <a>小马接口暂无报价明细</a>
            </template>

            <template v-if="accountName == 'UPS-ORIGIN'">
                <!-- 主表单区域 -->
                <el-form-item label="Shipping Service">
                    <el-input v-model="PriceDetail.service_name" disabled="disabled"/>
                </el-form-item>

                <el-form-item label="Gross Charge">
                    <el-input :value="'$'+PriceDetail.total_charges" disabled="disabled"/>
                </el-form-item>
                <!--<el-form-item label="Discount Information"></el-form-item>-->
                <el-tag type="info" style="font-size: unset;">Discount Information</el-tag>

                <el-form-item label="Amount Before Discount" class="lables">
                    <div v-html="PriceDetail.beforeDiscountAmount" style="color: rgba(0, 0, 0, 0.65);"></div>
                </el-form-item>
                <el-form-item label="percentTag" class="lables">
                    <div v-html="PriceDetail.percentTag" style="color: rgba(0, 0, 0, 0.65);"></div>
                </el-form-item>
                <el-form-item label="Amount After Discount" class="lables">
                    <!--<a v-html="PriceDetail.discountAmount_temp" style="color: rgba(0, 0, 0, 0.65);"></a>-->
                    <div v-html="PriceDetail.afterDiscountAmount" style="color: rgba(0, 0, 0, 0.65);"></div>
                </el-form-item>

                <el-form-item label="Fuel Surcharge" class="lables">
                    <div v-html="PriceDetail.fuel" style="color: rgba(0, 0, 0, 0.65);"></div>
                </el-form-item>

                <!-- 主表单区域 -->
                <el-form-item label="UPS Commission Fees" class="lables">
                    <el-input :value="'$'+PriceDetail.commission_charge" disabled="disabled"/>
                </el-form-item>

                <!-- 主表单区域 -->
                <el-form-item label="Shipment Total Charge" class="lables">
                    <el-input :value="'$'+PriceDetail.final_price" disabled="disabled"/>
                </el-form-item>
            </template>

          <template v-if="accountName == 'US5'">

            <el-form-item label="Shipping Service">
              <el-input v-model="PriceDetail.service_name" disabled="disabled"/>
            </el-form-item>

            <el-form-item label="Shipment Total Charge" class="lables">
              <el-input :value="'$'+PriceDetail.final_price" disabled="disabled"/>
            </el-form-item>
          </template>

            <template v-if="accountName == 'JHD'">

                <el-form-item label="Shipping Service">
                    <el-input v-model="PriceDetail.service_name" disabled="disabled"/>
                </el-form-item>

                <el-form-item label="Shipment Total Charge" class="lables">
                    <el-input :value="'$'+PriceDetail.final_price" disabled="disabled"/>
                </el-form-item>
            </template>


          <template v-if="accountName == 'PL-V2'">
            <!-- 主表单区域 -->
            <el-form-item label="Shipping Service">
              <el-input v-model="PriceDetail.service_name" disabled="disabled"/>
            </el-form-item>

            <!-- 主表单区域 -->
            <el-form-item label="Shipment Total Charge" class="lables">
              <el-input :value="'$'+PriceDetail.final_price" disabled="disabled"/>
            </el-form-item>
          </template>


        </el-form>

    </el-dialog>
</template>

<script>

    import {httpAction, getAction,postAction} from '@/api/manage'

    export default {
        name: 'checkPriceDetail',
        components: {},
        data() {
            return {
                title: '操作',
                visible: false,
                accountName: '',
                orderMainModel: {
                    jeecgOrderCustomerList: [{}],
                    jeecgOrderTicketList: [{}]
                },
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 5}
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16}
                },
                confirmLoading: false,
                form: {},
                orderNo: String,
                PriceDetail: [],
                orderDetil: [],
                url: {
                    add: '/test/order/add',
                    edit: '/test/order/edit',
                    orderCustomerList: '/test/order/listOrderCustomerByMainId',
                    orderTicketList: '/test/order/listOrderTicketByMainId',
                    saveOrderAddress: '/channel/saveOrderAddress',
                    selecAddressList: '/channel/selecAddressList',
                    upsRatingShip: '/interface/label/upsRatingShip',
                    upsPostManShip: '/interface/label/getPostManShip',
                    getFCCOrder: '/interface/fcc/getFCCOrder',
                    upsOriginShip: '/interface/label/getOriginShip',
                    upsRatingShip2: '/interface/label/upsRatingShip2',
                    getJhdOrder: '/interface/jhd/getJhdOrder',
                },
                labelPosition: "right"
            }
        },
        created() {
        },
        methods: {
            intoDocpage(orderNo) {

                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },

            test(a) {
            },
            add() {
                this.edit({})
            },
            edit(record) {
                let that = this
                if (record.name == 'GN2' || record.name == 'PL' || record.name == 'NY' || record.name == 'CA-5RR'
                    || record.name == 'G92' || record.name == 'SS-CWT') {
                    that.accountName = record.name
                    //PriceDetail.service_name

                    let total_charges = 0
                    let final_price = 0
                    let commission_charge = 0
                    let miscellaneous_charges = {}
                    let discount_info = {}
                    for (var p in record.pricingDetail) {//遍历json对象的每个key/value对,p为key
                        let orderRemark = p
                        that.PriceDetail.service_name = JSON.parse(record.pricingDetail[p])['service_name']
                        total_charges = Number(total_charges) + Number(JSON.parse(record.pricingDetail[p])['total_charges'])
                        final_price = Number(final_price) + Number(JSON.parse(record.pricingDetail[p])['final_price'])
                        commission_charge = Number(commission_charge) + Number(JSON.parse(record.pricingDetail[p])['commission_charge'])


                        var miscellaneous_charges_record = JSON.parse(record.pricingDetail[p])['miscellaneous_charges']
                        var discount_info_record = JSON.parse(record.pricingDetail[p])['discount_info']

                        for (var p in miscellaneous_charges_record) {//遍历json对象的每个key/value对,p为key
                            if (miscellaneous_charges.hasOwnProperty(p)) {
                                miscellaneous_charges[p] = '<span class="lablespan">'+miscellaneous_charges[p] + '</span><span class="lablespan">' + orderRemark + ':  $' + miscellaneous_charges_record[p] +'</span>'
                            } else {
                                miscellaneous_charges[p] = orderRemark + ':  $' + miscellaneous_charges_record[p]
                            }
                        }
                        for (var p in discount_info_record) {//遍历json对象的每个key/value对,p为key
                            if (discount_info.hasOwnProperty(p)) {

                                discount_info[p] = '<span class="lablespan">'+discount_info[p] + '</span><span class="lablespan">' + orderRemark + ':  $' + discount_info_record[p] +'</span>'
                            } else {
                                if (p == 'percentage') {
                                    discount_info[p] = orderRemark + ':' + discount_info_record[p] + '%'
                                } else {
                                    discount_info[p] = orderRemark + ':  $' + discount_info_record[p]
                                }
                            }
                        }

                    }

                    that.PriceDetail.total_charges = total_charges.toFixed(2)
                    that.PriceDetail.final_price = final_price.toFixed(2)
                    that.PriceDetail.commission_charge = commission_charge.toFixed(2)
                    that.PriceDetail.miscellaneous_charges = miscellaneous_charges
                    that.PriceDetail.discount_info = discount_info
                    //    this.PriceDetail = JSON.parse(record.pricingDetail)

                } else if (record.name == 'PT') {
                    that.PriceDetail = []
                    that.accountName = record.name
                } else if (record.name == 'UPS-ORIGIN') {

                    that.accountName = record.name
                    let total_charges = Number(record.gross).toFixed(2)
                    let final_price = Number(record.total).toFixed(2)
                    let commission_charge = 0
                    let miscellaneous_charges = {}
                    let discount_info = {}

                    that.PriceDetail.discountAmount_temp = record.discountAmount_temp
                    that.PriceDetail.afterDiscountAmount = record.afterDiscountAmount
                    that.PriceDetail.beforeDiscountAmount = record.beforeDiscountAmount
                    that.PriceDetail.fuel = record.fuel
                    that.PriceDetail.percentTag = record.percentTag;
                    that.PriceDetail.service_name = record.service_name;
                    that.PriceDetail.total_charges = total_charges
                    that.PriceDetail.final_price = final_price
                    that.PriceDetail.commission_charge = commission_charge
                    that.PriceDetail.miscellaneous_charges = miscellaneous_charges
                    that.PriceDetail.discount_info = discount_info

                } else if (record.name == 'US5'){
                    that.accountName = record.name;
                    let totalMoney = JSON.parse(record.pricingDetail);
                    that.PriceDetail.service_name ='US5'
                    that.PriceDetail.final_price = totalMoney.toFixed(2);
                }else if (record.name == 'JHD'){
                    that.accountName = record.name;
                    let totalMoney = JSON.parse(record.pricingDetail);
                    that.PriceDetail.service_name ='JHD'
                    that.PriceDetail.final_price = totalMoney.toFixed(2);
                } else if (record.name == 'PL-V2'){

                  that.accountName = record.name

                  //let total_charges = 0
                  let final_price = 0
                  //let commission_charge = 0
                  let miscellaneous_charges = {}
                  //let discount_info = {}
                  let rate_ids =''
                  let remark = ''

                  for (let p in record.pricingDetail) {//遍历json对象的每个key/value对,p为key
                    let orderRemark = p
                    remark = remark + p
                    that.PriceDetail.service_name = JSON.parse(record.pricingDetail[p])['service']
                    that.PriceDetail.rate_id = JSON.parse(record.pricingDetail[p])['id']
                    rate_ids += that.PriceDetail.rate_id+":"+p+","

                    //total_charges = Number(total_charges) + Number(JSON.parse(record.pricingDetail[p])['total_charges'])
                    final_price = Number(final_price) + Number(JSON.parse(record.pricingDetail[p])['final_price'])
                    //commission_charge = Number(commission_charge) + Number(JSON.parse(record.pricingDetail[p])['commission_charge'])


                    //var miscellaneous_charges_record = JSON.parse(record.pricingDetail[p])['miscellaneous_charges']
                    //var discount_info_record = JSON.parse(record.pricingDetail[p])['discount_info']

               /*     for (let p in miscellaneous_charges_record) {//遍历json对象的每个key/value对,p为key
                      if (miscellaneous_charges.hasOwnProperty(p)) {
                        miscellaneous_charges[p] = miscellaneous_charges[p] + ' <br/> ' + orderRemark + ':  $' + miscellaneous_charges_record[p]
                      } else {
                        miscellaneous_charges[p] = orderRemark + ':  $' + miscellaneous_charges_record[p]
                      }
                    }*/

                    /* for (var p in discount_info_record) {//遍历json对象的每个key/value对,p为key
                       if (discount_info.hasOwnProperty(p)) {
                         discount_info[p] = discount_info[p] + ' <br/> ' + orderRemark + ':  $' + discount_info_record[p]
                       } else {
                         if (p == 'percentage') {
                           discount_info[p] = orderRemark + ':' + discount_info_record[p] + '%'
                         } else {
                           discount_info[p] = orderRemark + ':  $' + discount_info_record[p]
                         }
                       }
                     }*/

                  }

                  //that.PriceDetail.total_charges = total_charges.toFixed(2)
                  that.PriceDetail.final_price = final_price.toFixed(2)
                  //that.PriceDetail.commission_charge = commission_charge.toFixed(2)
                  that.PriceDetail.miscellaneous_charges = miscellaneous_charges
                  // that.PriceDetail.discount_info = discount_info

                  //    this.PriceDetail = JSON.parse(record.pricingDetail)
                  if (rate_ids.length > 0) {
                    rate_ids = rate_ids.substr(0, rate_ids.length - 1);
                  }
                  rate_ids.replace(" 1CTN", "")
                  that.PriceDetail.rate_ids = rate_ids

                }
                this.orderDetil = record
                // that.form.resetFields()
                //this.orderMainModel = Object.assign({}, record);
                //初始化明细表数据
                that.visible = true
                that.$nextTick(() => {
                })
            },
            close() {
                // this.$emit('close')
                this.visible = false
            },
            handleOk() {
                const that = this
                this.confirmLoading = true
                let url = '', method = 'post'
                if (that.accountName == 'GN2' || that.accountName == 'PL' || that.accountName == 'NY' || that.accountName == 'CA-5RR'
                    || that.accountName == 'G92' || that.accountName == 'SS-CWT') {
                    url = this.url.upsRatingShip
                } else if (that.accountName == 'PT') {
                    url = this.url.upsPostManShip
                } else if (that.accountName == 'UPS-ORIGIN') {
                    url = this.url.upsOriginShip
                }else if (that.accountName == 'US5'){
                  url = this.url.getFCCOrder
                }else if (that.accountName == 'JHD'){
                    url = this.url.getJhdOrder
                } else if (that.accountName == 'PL-V2'){
                  url = this.url.upsRatingShip2
                }
                postAction(url, {
                    'orderNo': this.orderDetil.orderNo,
                    'accountName': this.orderDetil.name,
                    'reType': this.orderDetil.reType,
                    'repriceType': this.orderDetil.repriceType,
                    'type': this.orderDetil.repriceType,
                    'finalPrice': this.PriceDetail.final_price,
                    'rateId':this.PriceDetail.rate_ids,
                }).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message)
                        this.close()
                        this.$emit('ok')
                    } else {
                        this.$message.warn(res.message)
                    }
                }).finally(() => {
                    this.confirmLoading = false
                    this.$emit("ok")
                })

                // 触发表单验证

            },
            handleCancel() {
                this.close()
            }
        }
    }
</script>

<style scoped>

    .lables label {
        min-width: 170px;
    }

    .lables div {
        display: flex;
        flex-wrap: wrap;
    }

    .lables div span {
        margin: 0 10px;
    }

    .lablespan {
      margin: 0 10px;
    }
</style>
