<template>
    <el-dialog
        center
        width="30%"
        :close-on-click-modal="false"
        :visible.sync="visible"
        :title="titleStr"
        :before-close="handleClose"
        >
        <div class="content-wrap c-slim-scrollbar">
            <department-form ref="form" @ok="handleOk" :row="row" :data="data" :warehouseList="warehouseList" :isAdd="false"></department-form>
        </div>
        <el-form class="drawer_footer">
            <el-button plain size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
        </el-form>
    </el-dialog>
</template>

<script>
import {getAction, postAction} from '@/api/manage'
import DepartmentForm from './DepartmentForm'
export default {
    name: 'AddDepartmentDrawer',
    components: {
        DepartmentForm
    },
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        },
        data: {
            type: Array,
            default: () => []
        },
        titleStr:{
            type:String,
            default:''
        },
    },
    watch: {
        visible(val) {
            if (val) {
               getAction(this.url.getWarehouseList)
                   .then(res=>{
                       this.warehouseList=res.result;
                   })
            }
        },
    },
    data() {
        return {
            warehouseList:[],
            url: {
                add: '/sys/sysDepart/add',
                getWarehouseList:'/sys/sysDepart/getWarehouseListWithDepartId',
            }
        }
    },
    methods: {

        handleOk(form) {
            postAction(this.url.add, form).then(res => {
                this.handleClose()
                this.$bus.$emit('department-data-refresh')
                this.$message({
                    type: 'success',
                    message: res.message
                })
           })
        },
        handleSubmit() {
            this.$refs.form.handleSubmit()
        },
        handleClose() {
            this.$refs.form.handleClear()
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
