<template>
  <el-dialog
    :title="title"
    width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleCancel"
    @cancel="handleCancel"
    cancelText="关闭">
<!--      <a-timeline class="ope-content">-->
<!--        <a-timeline-item v-for="item in opertList" :key="item.id">-->
<!--          {{item.content}} - {{item.creator}}  - {{item.createTime}}-->
<!--        </a-timeline-item>-->
<!--      </a-timeline>-->
    <template slot="footer">
      <el-button key="3" type="primary" @click="handleCancel">
        关闭
      </el-button>
    </template>
  </el-dialog>

</template>

<script>

  import { getAction,httpAction } from '@/api/manage'
  import pick from 'lodash.pick'

  export default {
    name: "OperaRecordModal",
    data () {
      return {
        title: "操作记录",
        width: 600,
        visible: false,
        confirmLoading: false,
        opertList: []
      }
    },
    methods: {
      /**
       * @description: 关闭modal
       */
      handleCancel() {
        this.visible = false;
      },
      /**
       * @description: 展示modal显示数据
       * @param {object} modalDatas 表单选中的数据
       */
      showDatas(modalDatas) {
        this.model = Object.assign({}, modalDatas);
        this.visible = true;
        this.$nextTick(() => {
          console.log('modalDatas: ', modalDatas);
          getAction('/fbaWarehouseOrderReceive/getLogs', {
            orderNo: modalDatas.orderNo
          })
          .then((res) => {
            this.opertList = res.result;
            console.log('res: ', res);
          })
          .catch((err) => {
            this.opertList = [];
          })
        })
      }
    },
    created () {

    },
  }
</script>
<style scoped>
.ope-content {
  max-height: 500px;
  min-height: 200px;
  overflow: auto;
  padding: 10px 0;
}
</style>
