<template>
    <div class="main-full-content">
        <el-form class="no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-row>
               <!-- <el-col :span="4">
                    <el-form-item label="客户名称">
                        <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="联系电话">
                        <el-input v-model="queryForm.phone" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="业务员">
                        <f-select v-model="queryForm.salesmanId" :dict="'salesman'"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="添加人">
                        <f-select v-model="queryForm.createBy" :dict="'sys_user'"></f-select>
                    </el-form-item>
                </el-col>-->
               <!-- <el-col :span="8">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>
                    </el-form-item>
                </el-col>-->
            </el-row>
        </el-form>
        <div class="f-p-b-15">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">在线绘制流程</el-button>
        </div>
        <el-row :gutter="30" type="flex" style="flex-wrap: wrap">
            <el-col :span="4" v-for="(item, index) in tableData" :key="index">
                <el-card shadow="hover" class="process_card_box" @click.native="handleEditNomore(item)">
                    <div class="process_img_box"
                         :class="item.status == '1' ? 'process_img_status_1' : 'process_img_status_0'">
                        <i class="el-icon-s-check"></i>
                    </div>
                    <div class="process_text">
                        <div class="process_title">{{item.name}}</div>
                        <div>{{'版本号：' + item.version}}</div>
                    </div>
                    <el-dropdown v-if="showMore" class="more_box" trigger="click">
                <span>
                  <i class="el-icon-more"></i>
                </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="handleEdit(item)">启动实例</el-dropdown-item>
                            <el-dropdown-item v-if="$btnIsShow('isystem/activity/DeploymentActiviti','1','查看')"
                                              @click.native="handleView(item)">查看
                            </el-dropdown-item>
                            <el-dropdown-item
                                    v-if="item.status === 1 && $btnIsShow('isystem/activity/DeploymentActiviti','1','挂起/激活')"
                                    @click.native="suspendOrResumeInstance(item)">挂起
                            </el-dropdown-item>
                            <el-dropdown-item
                                    v-if="item.status !== 1 && $btnIsShow('isystem/activity/DeploymentActiviti','1','挂起/激活')"
                                    @click.native="suspendOrResumeInstance(item)">激活
                            </el-dropdown-item>
                            <el-dropdown-item v-if="$btnIsShow('isystem/activity/DeploymentActiviti','1','删除')"
                                              @click.native="handleDel(item)">删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-card>
            </el-col>
        </el-row>
        <component :ref="dynamic" :is="dynamic"
                   :processDialogData="processItem" @handleDeal="startProcess"
                   @handleCancel="cancelStartProcess" @comMounted="comMounted"
                   @handleDialogClose="cancelStartProcess"></component>
    </div>
</template>

<script>
    import {getAction, deleteAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"

    export default {
        name: 'Undetermined',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                // rules: {
                //     instanceName: [
                //         {required: true, message: '请输入实例名称', trigger: 'change'},
                //     ],
                //     instanceVariable: [
                //         {required: true, message: '请输入实例描述', trigger: 'change'},
                //     ],
                // },
                namelist: [],
                row: {},
                ids: [],
                formLabelWidth: '120px',
                visible: false,
                assignVisible: false,
                assignTitle: '',
                salesmanlist: [],
                userlist: [],
                form: {
                    key: null,
                    instanceName: null,
                    instanceVariable: null,
                    name: null,
                },
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'id',
                        label: '流程定义ID',
                        sortable: 'id',
                    },
                    {
                        prop: 'deploymentID',
                        label: '部署ID',
                        sortable: 'deploymentID',
                    },
                    {
                        prop: 'name',
                        label: '流程定义名称',
                        sortable: 'name',
                    },
                    {
                        prop: 'resourceName',
                        label: '流程文件名称',
                        sortable: 'resourceName',
                    },
                    {
                        prop: 'key',
                        label: 'KEY',
                        sortable: 'key',
                    },
                    {
                        prop: 'version',
                        label: '部署版本',
                        sortable: 'version',
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 300,
                    }
                ],
                url: {
                    list: '/activiti/list',
                    startProcess: '/activiti/startProcess',
                    view: '/activiti/view',
                    deleteDeployment: '/activiti/deleteDeployment',
                    suspendOrResumeInstance: '/activiti/DeploymentSuspendOrResumeInstance',
                },
                processItem: {},  //记录查看的是哪一个表单数据
                showMore: true, //是否显示右上角的更多按钮
                dynamicPath: '',  //动态组件
                dynamic: '', //动态组件
                isDynamic: false, //动态组件是否存在
            }
        },
        created() {
            this.initList();
            this.showMore = this.$btnIsShow('isystem/activity/DeploymentActiviti', '1', '查看')
                || this.$btnIsShow('isystem/activity/DeploymentActiviti', '1', '挂起/激活')
                || this.$btnIsShow('isystem/activity/DeploymentActiviti', '1', '删除');
        },
        computed: {
            // 动态加载对应的组件
            loaderDynamic() {
                return () => import('./components/' + this.dynamicPath)
            }
        },
        methods: {
            // 动态加载组件，components为组件的名称，所有的组件需要放在isystem/activity/components中
            initDynamic(components) {
                if (this.dynamicPath == components && this.isDynamic) {
                    this.comMounted()
                    return
                }
                this.dynamicPath = components;
                console.log('要启动的组件',this.dynamicPath)
                const self = this;
                self.loaderDynamic().then(() => {
                    // 动态加载模板
                    self.dynamic = () => self.loaderDynamic();
                    self.isDynamic = true;
                }).catch((err) => {
                    self.isDynamic = false;
                    this.$message.warning('模板组件不存在');
                    console.log('模板不存在', err)
                    // 模板不存在404时处理
                })
            },
            // 动态组件加载完成
            comMounted() {
                this.$refs[this.dynamic].visible = true;
                let row = {}
                row.actionType = "start"
                this.$refs[this.dynamic].initData(row);
            },
            handleQuery() {

            },
            //初始化列表
            initList() {
                getAction(this.url.list).then(res => {
                    console.log(res)
                    if (res.success) {
                        this.tableData = res.result
                    }
                })
            },
            handleSelectionChange(selection) { // 多选
                let arr = [];
                this.namelist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.namelist.push(selection[i]['username'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                let routeData = this.$router.resolve({path: '/AddActivitiBpmn?type=add'});
                window.open(routeData.href, '_blank');

            },
            //流程定义的挂起和激活
            suspendOrResumeInstance(row) {
                getAction(this.url.suspendOrResumeInstance, row).then(res => {
                    if (res.success) {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.initList()
                    }
                })
            },
            handleView(row) {
                console.log(row)
                let routeData = this.$router.resolve({path: '/AddActivitiBpmn?type=lookBpmn&processInstanceId=' + row.id});
                window.open(routeData.href, '_blank');
                // getAction(this.url.view, {processInstanceId:row.id}).then(res => {
                //    if(res.success){
                //
                //
                //    }
                // })
            },
            handleEditNomore(row) {
                if (!this.showMore) {
                    this.handleEdit(row)
                }
            },
            handleEdit(row) {
                if (row.status == '0') {
                    this.$message.warning('该流程已被禁用');
                    return
                }
                this.initDynamic(row.key);
                this.processItem = row;
                this.form.key = row.key
                this.form.name = row.name
                this.$set(this.processItem, 'form', this.form)
            },
            cancelStartProcess() {
                this.form = {
                    key: null,
                    instanceName: null,
                    instanceVariable: null,
                    name: null,
                    ListData: []
                }
                this.$set(this.processItem, 'form', this.form)
            },
            //启动实例
            startProcess(form) {
                console.log(this.form)
                // this.initList();
                // this.$refs.form.validate((valid) => {
                //     getAction(this.url.startProcess, form ? form : this.form).then(res => {
                //         this.$message({
                //             type: 'success',
                //             message: res.message
                //         })
                //         this.handleSearch()
                //     })
                // })
            },

            handleDel(row) {
                console.log(row)
                this.$confirm(`确定删除${row.name}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteDeployment, row).then(res => {
                        if (res.success) {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.initList()
                        }
                    })
                }).catch(() => {

                })
            },
            handleBatchDel() {
                this.$confirm(`确定批量删除共${this.namelist.join(',')}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteBatch + "?ids=" + this.selectedRowKeys.join(','), {ids: this.selectedRowKeys}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .process_card_box {
        margin-bottom: 30px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .more_box {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .process_img_box {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .process_img_status_1 {
        background-color: #3b9aff;
    }

    .process_img_status_0 {
        background-color: gray;
    }

    .process_text {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: gray;
    }

    .process_title {
        font-size: 16px !important;
        color: black;
    }
</style>
<style>
    .process_card_box .el-card__body {
        display: flex;
    }

    .el-icon-more {
        font-size: 21px !important;
    }

    .el-icon-s-check {
        font-size: 21px !important;
        color: white;
    }
</style>
