<template>
    <el-dialog :title="row.id ? '编辑客户端登录页' : '新增客户端登录页'" :visible="visible" width="560px"
               custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="name" label="名字">
                        <el-input v-model="addForm.name" placeholder="请输入名字" maxlength="50">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="fileList" label="图片">
                        <f-upload v-model="addForm.fileList" :isUseOss="true" list-type="picture-card" accept="image/*"
                                  :limit="1"></f-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="startTime" label="有效开始时间">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="addForm.startTime"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="endTime" label="有效结束时间">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="addForm.endTime"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {httpAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js";


    export default {
        name: 'EditClientLoginImageDialog',
        mixins: [QueryMixins],

        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            },
            // 弹窗类型
            type: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                addForm: {},
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    fileList: [
                        {required: true, message: '请选择图片', trigger: 'change'},
                    ],
                    startTime: [
                        {required: true, message: '请选择有效期', trigger: 'change'},
                    ],
                    endTime: [
                        {required: true, message: '请选择有效期', trigger: 'change'},
                    ],
                },
                url: {
                    add: '/sys/ClientLoginImage/addList',
                    edit: '/sys/ClientLoginImage/editList',

                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    let fileList = [];
                    this.addForm = JSON.parse(JSON.stringify(this.row));
                    let obj = {id: this.row.ossFileId, url: this.row.url, name: this.row.name};
                    fileList.push(obj);
                    this.addForm.fileList = fileList;
                } else {
                    this.addForm = {fileList: []};
                }
            },
        },
        created() {
        },
        methods: {
            handleSubmit() {
                let url = this.url.add
                if (this.row.id) {
                    url = this.url.edit
                }
                let list = this.addForm.fileList;
                // if (!list || list.length === 0) {
                //     this.$message.warning('请上传图片');
                //     return false;
                // }
                if(list && list.length) {
                  this.addForm.ossFileId = list[0].id;
                  this.addForm.url = list[0].url;
                }
                let paramList = [];
                paramList.push(this.addForm);
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        httpAction(url, 'POST', paramList).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.addForm && this.$refs.addForm.resetFields()
            },
        }
    }
</script>

<style lang="scss" scoped></style>
