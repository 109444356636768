<!--SEO-->
<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row style="display: flex; flex-wrap: wrap">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="自定义标题">
              <el-input
                v-model="queryForm.seoTitle"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="自定义关键词">
              <el-input
                v-model="queryForm.seoKeyword"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="自定义描述">
              <el-input
                v-model="queryForm.seoDescription"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              style="margin-left: 20px"
              >查询
            </el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          v-if="buttonShowList.add"
          @click="handleAdd({})"
          >新增
        </el-button>
        <el-button
          type="primary"
          size="medium"
          v-if="buttonShowList.del"
          @click="handleDelete"
          >批量删除
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="67vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleAdd(scope.row)"
              v-if="buttonShowList.edit"
              >编辑
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'selections'">
            <el-cascader
              v-if="scope.row.selectedIdList.length == 1"
              v-model="scope.row.selectedIdList[0]"
              :options="pageList"
              disabled
            ></el-cascader>

            <el-cascader
              v-else-if="scope.row.selectedIdList.length > 1"
              v-model="scope.row.selectedIdList[1]"
              :options="pageList"
              disabled
            ></el-cascader>
          </template>
        </template>
      </f-table>
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <add-seo-dialog
      :visible="visible"
      :row="row"
      @ok="handleQuery"
      @update:visible="
        (value) => {
          visible = value;
        }
      "
    />
  </div>
</template>

<script>
import QueryMixins from "../../mixins/QueryMixins";
import { getAction, postAction, deleteAction } from "@/api/manage";
import AddSeoDialog from "./components/AddSeoDialog";

export default {
  mixins: [QueryMixins],
  components: {
    AddSeoDialog,
  },
  data() {
    return {
      row: {},
      form: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "seoTitle",
          label: "自定义标题",
        },

        {
          prop: "seoKeyword",
          label: "自定义关键词",
          width: 100,
        },
        {
          prop: "seoDescription",
          label: "请输入自定义描述",
        },
        {
          prop: "selections",
          label: "配置页面",
        },
        {
          prop: "seoUrl",
          label: "页面路径",
        },
      ],
      url: {
        list: "/sys/owSeoConfig/selectSeoConfigList",
        del: "/sys/owSeoConfig",
      },
      visible: false,
      isUsePost: true,
      pageList: [
        {
          value: "/home",
          label: "主页",
        },
        {
          value: "/product",
          label: "产品中心",
          children: [
            // {
            //   value: "/product/all",
            //   label: "",
            // },
            {
              value: "/fbahy",
              label: "海运服务",
            },
            {
              value: "/Projects/fbaqgfw",
              label: "清关服务",
            },
            {
              value: "/fbaky",
              label: "快空服务",
            },
            {
              value: "/product/overseas",
              label: "海外仓服务",
            },
            {
              value: "/product/productDetail",
              label: "产品详情",
            },
          ],
        },
        {
          value: "/aboutUs",
          label: "关于我们",
          children: [
            {
              value: "/aboutUs/companyProfile",
              label: "公司简介",
            },
            {
              value: "/aboutUs/teamIntroduction",
              label: "团队介绍",
            },
            {
              value: "/aboutUs/globalForest",
              label: "全球森仓",
            },
            {
              value: "/aboutUs/talentRecruitment",
              label: "人才招聘",
            },
            {
              value: "/lxdsl",
              label: "联系我们",
            },
          ],
        },
        {
          value: "/",
          label: "新闻资讯",
          children: [
            {
              value: "/new",
              label: "新闻",
            },
            {
              value: "/newDetail",
              label: "新闻详情",
            },
          ],
        },
        {
          value: "/cooperation",
          label: "合伙流程",
        },
        {
          value: "/wjxz2",
          label: "下载中心",
        },
        {
          value: "/Projects/ckwlcywz",
          label: "常用网站",
        },
      ],
    };
  },
  methods: {
    handleAdd(row) {
      this.row = { ...row };
      this.visible = true;
    },
    handleDelete() {
      deleteAction(this.url.del, {
        idList: this.selectedRowKeys.join(","),
      }).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleQuery();
      });
    },
    findLabelByValue(value) {
      // 遍历pageList数组
      // for (let i = 0; i < this.pageList.length; i++) {
      //   const item = this.pageList[i];
      //   // 如果当前项的value等于目标值，则返回对应的label
      //   if (item.value === targetValue) {
      //     return item.label;
      //   }
      //   // 如果当前项有children，则递归在children中查找
      //   if (item.children && item.children.length > 0) {
      //     const childLabel = this.findLabelByValue(item.children, targetValue);
      //     if (childLabel) {
      //       return childLabel;
      //     }
      //   }
      // }
      // // 如果未找到匹配项，则返回null或者适当的默认值
      // return null;
    },
  },
  created() {},
  computed: {
    buttonShowList() {
      return {
        add: this.$btnIsShow("forestMarket/SeoConfig", "0", "新增"),
        del: this.$btnIsShow("forestMarket/SeoConfig", "0", "批量删除"),
        edit: this.$btnIsShow("forestMarket/SeoConfig", "1", "编辑"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
