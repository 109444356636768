<!--待提交-->
<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="操作员"> <!--待提交 已拒绝-->
              <f-select clearable filterable class="f-full-width"
                        :dict="'operationsId'"
                        v-model="queryForm.operateId"
                        :isNeed="queryForm.operateId"
                        placeholder="请选择" style="width:100%">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="文件操作">
              <f-select clearable filterable class="f-full-width"
                        :dict="'sys_user_id'"
                        v-model="queryForm.docOperateId"
                        :is-need="queryForm.docOperateId"
                        placeholder="请选择" style="width:100%"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="状态"> <!--待提交 已拒绝-->
              <f-select clearable filterable class="f-full-width"
                        :dict="'order_status'"
                        v-model="queryForm.orderStatus"
                        :fatherVal="queryForm.orderStatus"
                        placeholder="请选择" style="width:100%">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="被保险人">
              <el-input v-model="queryForm.insured" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="柜号">
              <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5" >
            <el-form-item label="开船开始时间">
              <el-date-picker
                      v-model="queryForm.shipmentBoardTimeS"
                      type="datetime"
                      size="small"
                      style="width: 100%"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5" >
            <el-form-item label="开船结束时间">
              <el-date-picker
                      v-model="queryForm.shipmentBoardTimeE"
                      type="datetime"
                      style="width: 100%"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" >
            <el-form-item label="订单类型">
              <el-select v-model="queryForm.containerType">
                <el-option value="0" label="自拼"></el-option>
                <el-option value="1" label="非拼/整柜"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="handleExportXls('保险待处理记录表')"
                   v-if="buttonShowList.dc"
        >导出
        </el-button>
        <el-upload
            action="/"
            :show-file-list="false"
            :http-request="uploadSectionFile"
            accept=".xls,.xlsx"
            style="margin:0 10px;display:inline"
        >
          <el-button size="medium" type="primary" :loading="btnLoading">保险导入
          </el-button>
        </el-upload>

        <el-button type="primary" size="medium" @click="updateStatus()"
        >PICC更新状态
        </el-button>
        <el-button type="primary" size="medium" @click="updateKJBStatus()"
                   v-if="buttonShowList.gxzz"
        >跨境堡更新状态
        </el-button>
        <el-button type="primary" size="medium" @click="update51Status()"
                     v-if="buttonShowList.gxzz2"
          >无忧保更新状态
        </el-button>
        <el-button type="primary" size="medium" @click="beatchToubao()"
                   :disabled="selectionRows && selectionRows.length === 0" autofocus
        >批量投保
        </el-button>
        <el-button type="primary"
                   size="medium"
                   @click="exportExcelPlus(url.downloadExcelUrl,'柜子不需要保险')"
                   v-if="buttonShowList.xz1"
        >
          下载不需要保险数据
        </el-button>
        <el-button type="primary" size="medium" @click="cancelFlag = true"
                   :disabled="selectionRows && selectionRows.length === 0"
                   v-if="buttonShowList.qxtb"

        >取消投保
        </el-button>
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="66vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)" :disabled="scope.row.orderStatus == '1'"
            >编辑
            </el-button>
            <el-button v-if="!startTimeIsover(scope.row)" type="text" @click="handleToubao(scope.row)"
            >投保
            </el-button>
            <el-button v-if="buttonShowList.tb" type="text" @click="offlineInsurance(scope.row)"
            >线下投保
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'orderStatus'">
            <span v-if="scope.row.orderStatus === '0'">待提交</span>
            <span v-else-if="scope.row.orderStatus === '1'">待审核</span>
            <span v-else-if="scope.row.orderStatus === '2'">审核通过</span>
            <span v-else-if="scope.row.orderStatus === '3'">审核拒绝</span>

            <span v-else-if="scope.row.orderStatus === '4'">已承保</span>
            <span v-else-if="scope.row.orderStatus === '5'">索赔中</span>
            <span v-else>已索赔</span>
          </template>
          <template v-else-if="scope.column.property === 'deliveryType'">
            <span v-if="scope.row.deliveryType === '1'">卡车派</span>
            <span v-else-if="scope.row.deliveryType === '2'">快递派</span>
            <span v-else>待定</span>
          </template>
          <template v-else-if="scope.column.property === 'insuredType'">
            <span v-if="scope.row.insuredType === '0'">企业</span>
            <span v-else>个人</span>
          </template>
          <template v-else-if="scope.column.property === 'insureProduct'">
            <span>{{ parseDict(scope.row.insureProduct, "insure_product") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
                :style="startTimeIsover(scope.row) ? 'color: red' : 'color: #00a0e9'"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo + (startTimeIsover(scope.row) ? '(超过七天)' : '') }}</a>
          </template>
          <!--<template v-else-if="scope.column.property === 'endPlaceType'">-->
               <!--{{ parseDict(scope.row.endPlaceType, "end_place_type")}}-->
          <!--</template>-->
          <template v-else-if="scope.column.property === 'shipmentType'">
            {{ parseDict(scope.row.shipmentType, "order_shipment_type")}}
          </template>
        </template>

      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog :title="'批量投保提示'" :visible.sync="dialogTouBaoVisible" :close-on-click-modal="false">
      <div v-if="resultFail.length" style="margin: 10px 0;">
        <div style="font-size: 16px;font-weight: bold;margin-bottom: 10px;">投保失败</div>
        <div v-for="(item,index) in resultFail" :key="index">
          <span>{{ item }}</span>
        </div>
      </div>
      <div v-if="resultSucc.length" style="margin: 10px 0;">
        <div style="font-size: 16px;font-weight: bold;margin-bottom: 10px;">投保成功</div>
        <div v-for="(item,index) in resultSucc" :key="index">
          <span>{{ item }}</span>
        </div>
      </div>

    </el-dialog>
    <el-dialog :title="'编辑'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
               v-if="dialogFormVisible">
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row">
          <el-col :span="12">
            <el-form-item label="订单号" :label-width="formLabelWidth" prop="orderNo">
              <el-input v-model="form.orderNo" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保险产品" :label-width="formLabelWidth" prop="insureProduct">
              <!-- fba地址 -->
                <f-select clearable filterable class="f-full-width"
                        :dict="'insure_addr'"
                        v-model="form.insureProduct"
                        :fatherVal="form.insureProduct"
                        placeholder="请选择" style="width:100%" :is-need="form.insureProduct"
                        v-if="shipmentTypeFlag=='0'">
              </f-select>
              <f-select clearable filterable class="f-full-width"
                        :dict="'insure_product'"
                        v-model="form.insureProduct"
                        :fatherVal="form.insureProduct"
                        placeholder="请选择" style="width:100%" :is-need="form.insureProduct"
                   v-else >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.insureProduct ==='无忧保'">
            <el-form-item label="产品类型" :label-width="formLabelWidth" prop="prodId">
              <el-select
                      clearable
                      filterable
                      placeholder="请选择产品类型"
                      class="f-full-width"
                      v-model="form.prodId"
              >
                <el-option
                        v-for="(item,index) in productTypeList"
                        :label="item.prodName"
                        :value="item.prodId"
                        :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="被保险人" :label-width="formLabelWidth" prop="insured">
              <el-input v-model="form.insured" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="this.form.insureProduct=='无忧保'">
            <el-form-item label="被保险人类型" :label-width="formLabelWidth" prop="insuredType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_insured_type'"
                        v-model="form.insuredType"
                        :fatherVal="form.insuredType"
                        placeholder="请选择" style="width:100%" :is-need="form.insuredType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="被保险人类型" :label-width="formLabelWidth" prop="insuredType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'insured_type'"
                        v-model="form.insuredType"
                        :fatherVal="form.insuredType"
                        placeholder="请选择" style="width:100%" :is-need="form.insuredType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="被保险人证件" :label-width="formLabelWidth" prop="insuredNo">
              <el-input v-model="form.insuredNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if ="this.form.insureProduct=='无忧保'">
            <el-form-item label="货值" :label-width="formLabelWidth" prop="declareTotal">
              <el-input v-model="form.declareTotal" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="申报价值总额" :label-width="formLabelWidth" prop="declareTotal">
              <el-input v-model="form.declareTotal" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="运费" :label-width="formLabelWidth" prop="freight">
              <el-input v-model="form.freight" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if ="this.form.insureProduct=='无忧保'">
            <el-form-item label="货值币种" :label-width="formLabelWidth" prop="cur">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_insured_cur'"
                        v-model="form.cur"
                        :fatherVal="form.cur"
                        placeholder="请选择" style="width:100%" :is-need="form.cur">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="保单币种" :label-width="formLabelWidth" prop="cur">
              <f-select clearable filterable class="f-full-width"
                        :dict="'insured_cur'"
                        v-model="form.cur"
                        :fatherVal="form.cur"
                        placeholder="请选择" style="width:100%" :is-need="form.cur">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="保额确定方式" :label-width="formLabelWidth" prop="insuredSureType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'insured_sure_type'"
                        v-model="form.insuredSureType"
                        :fatherVal="form.insuredSureType"
                        placeholder="请选择" style="width:100%" :is-need="form.insuredSureType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if ="form.insureProduct ==='无忧保'">
            <el-form-item label="总公斤数" :label-width="formLabelWidth" prop="totalkg">
              <el-input v-model="form.totalkg"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="毛重" :label-width="formLabelWidth" prop="totalkg">
              <el-input v-model="form.totalkg" autocomplete="off" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
              <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if = "form.insureProduct ==='无忧保'">
            <el-form-item label="运输方式" :label-width="formLabelWidth" prop="transportType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_transport_type'"
                        v-model="form.transportType"
                        :fatherVal="form.transportType"
                        placeholder="请选择" style="width:100%" :is-need="form.transportType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="运输方式" :label-width="formLabelWidth" prop="transportType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'transport_type'"
                        v-model="form.transportType"
                        :fatherVal="form.transportType"
                        placeholder="请选择" style="width:100%" :is-need="form.transportType">
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="运输工具及航次" :label-width="formLabelWidth" prop="transportVoyage">
              <el-input v-model="form.transportVoyage" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='无忧保'">
            <el-form-item label="提单号" :label-width="formLabelWidth" prop="mblOrderNo">
              <el-input v-model="form.mblOrderNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="MBL提单号" :label-width="formLabelWidth" prop="mblOrderNo">
              <el-input v-model="form.mblOrderNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="HBL提单号" :label-width="formLabelWidth" prop="hblOrderNo">
              <el-input v-model="form.hblOrderNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.insureProduct ==='无忧保'">
            <el-form-item label="派送方式" :label-width="formLabelWidth" prop="deliveryType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_delivery_type'"
                        v-model="form.deliveryType"
                        :fatherVal="form.deliveryType"
                        placeholder="请选择" style="width:100%" :is-need="form.deliveryType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="派送方式" :label-width="formLabelWidth" prop="deliveryType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'delivery_type'"
                        v-model="form.deliveryType"
                        :fatherVal="form.deliveryType"
                        placeholder="请选择" style="width:100%" :is-need="form.deliveryType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='无忧保' && this.form.deliveryType =='1'">
            <el-form-item label="快递公司" :label-width="formLabelWidth" prop="expressCompany">
              <f-select clearable filterable class="f-full-width"
                        :dict="'express_company'"
                        v-model="form.expressCompany"
                        :fatherVal="form.expressCompany"
                        placeholder="请选择" style="width:100%" :is-need="form.expressCompany">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='无忧保' && this.form.deliveryType =='1'">
            <el-form-item label="快递单号" :label-width="formLabelWidth" prop="expressOrderNo">
              <el-input v-model="form.expressOrderNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="快递公司" :label-width="formLabelWidth" prop="expressCompany">
              <f-select clearable filterable class="f-full-width"
                        :dict="'express_company'"
                        v-model="form.expressCompany"
                        :fatherVal="form.expressCompany"
                        placeholder="请选择" style="width:100%" :is-need="form.expressCompany">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="快递单号" :label-width="formLabelWidth" prop="expressOrderNo">
              <el-input v-model="form.expressOrderNo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="shipmentId" :label-width="formLabelWidth" prop="shipmentId"
              :rules="rules.shipmentId" >
              <el-input v-model="form.shipmentId" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="包装类型" :label-width="formLabelWidth" prop="packingType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'packing_type'"
                        v-model="form.packingType"
                        :fatherVal="form.packingType"
                        placeholder="请选择" style="width:100%" :is-need="form.packingType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct ==='无忧保'">
            <el-form-item label="包装类型" :label-width="formLabelWidth" prop="packingType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_packing_type'"
                        v-model="form.packingType"
                        :fatherVal="form.packingType"
                        placeholder="请选择" style="width:100%" :is-need="form.packingType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="包装数量" :label-width="formLabelWidth" prop="packageCount">
              <el-input v-model="form.packageCount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if=" this.form.insureProduct=='无忧保'">
            <el-form-item label="货物类别" :label-width="formLabelWidth" prop="goodsType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_goods_type'"
                        v-model="form.goodsType"
                        :fatherVal="form.goodsType"
                        placeholder="请选择" style="width:100%" :is-need="form.goodsType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="货物描述" :label-width="formLabelWidth" prop="productDescription">
              <el-input v-model="form.productDescription" autocomplete="off" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="货物类别" :label-width="formLabelWidth" prop="goodsType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'goods_type'"
                        v-model="form.goodsType"
                        :fatherVal="form.goodsType"
                        placeholder="请选择" style="width:100%" :is-need="form.goodsType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起运时间" :label-width="formLabelWidth" prop="startTime">
              <el-date-picker
                  class="f-flex-1"
                  v-model="form.startTime"
                  type="datetime"
                  size="small"
                  placeholder="起运时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起运国" :label-width="formLabelWidth" prop="startCountry">
              <el-select clearable filterable v-model="form.startCountry" placeholder="获取所有国家" style="width:100%"
                         @change="getStartAddress">
                <el-option v-for="(item, index) in countryList"
                           :label="item.label"
                           :value="item.value"
                           :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起运地" :label-width="formLabelWidth" prop="startPlace">
              <el-select clearable filterable v-model="form.startPlace" placeholder="获取所有地区" style="width:100%">
                <el-option v-for="(item, index) in startAddressList"
                           :label="item.label"
                           :value="item.value"
                           :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的地国" :label-width="formLabelWidth" prop="endCountry">
              <el-select clearable filterable v-model="form.endCountry" placeholder="获取所有国家" style="width:100%"
                         @change="getEndAddress">
                <el-option v-for="(item, index) in countryList"
                           :label="item.label"
                           :value="item.value"
                           :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.insureProduct !='跨境大货创新类保险服务'">
            <el-form-item label="目的地" :label-width="formLabelWidth" prop="endPlace">
              <el-select clearable filterable v-model="form.endPlace" placeholder="获取所有地区" style="width:100%">
                <el-option v-for="(item, index) in endAddressList"
                           :label="item.label"
                           :value="item.value"
                           :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.insureProduct ==='跨境大货创新类保险服务'||form.insureProduct=='PlCC邮包险'">
            <el-form-item label="目的地" :label-width="formLabelWidth" prop="endPlace">
              <el-input v-model="form.endPlace" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="目的地类型" :label-width="formLabelWidth" prop="endPlaceType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'end_place_type'"
                        v-model="form.endPlaceType"
                        :fatherVal="form.endPlaceType"
                        @change="changeEndPlaceType"
                        placeholder="请选择" style="width:100%" :is-need="form.endPlaceType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='无忧保'">
            <el-form-item label="目的地类型" :label-width="formLabelWidth" prop="endPlaceType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'wuyou_end_place_type'"
                        v-model="form.endPlaceType"
                        :fatherVal="form.endPlaceType"
                        @change="changeEndPlaceType"
                        placeholder="请选择" style="width:100%" :is-need="form.endPlaceType">
              </f-select>
            </el-form-item>
          </el-col>
          <!--<el-col :span="12"  v-if="form.insureProduct ==='跨境大货创新类保险服务'">-->
            <!--<el-form-item label="入仓编码号" :label-width="formLabelWidth" prop="warehouseCodeNo">-->
              <!--<el-input v-model="form.warehouseCodeNo" autocomplete="off" disabled  type="textarea"></el-input>-->
              <!--&lt;!&ndash; {{form}} &ndash;&gt;-->
            <!--</el-form-item>-->
          <!--</el-col>-->
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险'">
            <el-form-item label="是否上架保障" :label-width="formLabelWidth" prop="isGuarantee">
              <f-select clearable filterable class="f-full-width"
                        :dict="'is_guarantee'"
                        v-model="form.isGuarantee"
                        :fatherVal="form.isGuarantee"
                        placeholder="请选择" style="width:100%" :is-need="form.isGuarantee">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务' || this.form.insureProduct =='无忧保'">
            <el-form-item label="上架保障" :label-width="formLabelWidth" prop="shelfType">
              <f-select clearable filterable class="f-full-width"
                        :dict="'shelf_type'"
                        v-model="form.shelfType"
                        :fatherVal="form.shelfType"
                        placeholder="请选择" style="width:100%" :is-need="form.shelfType">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货传统类保险服务'">
            <el-form-item label="是否暴动保障" :label-width="formLabelWidth" prop="isRiot">
              <f-select clearable filterable class="f-full-width"
                        :dict="'is_riot'"
                        v-model="form.isRiot"
                        :fatherVal="form.isRiot"
                        placeholder="请选择" style="width:100%" :is-need="form.isRiot">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='PICC到仓险' || this.form.insureProduct=='大森林邮包险' || this.form.insureProduct=='跨境大货创新类保险服务' || this.form.insureProduct=='跨境大货创新类保险服务'">
            <el-form-item label="上架地址" :label-width="formLabelWidth" prop="shelvesAddress">
              <el-input v-model="form.shelvesAddress" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="this.form.insureProduct=='跨境大货创新类保险服务' && this.form.goodsType=='2'||this.form.insureProduct=='PlCC邮包险'&& this.form.goodsType=='2'">
            <el-form-item prop="fragileFileList" :label-width="formLabelWidth" label="易碎品内包装图片">
              <f-upload v-model="form.fragileFileList" :dir="'shipping'" :isUseOss="true"></f-upload>
            </el-form-item>
          </el-col>
          <!--          //起运时间小于当前时间 需要上传无货损保函-->
          <el-col :span="12"
                  v-if="this.form.insureProduct=='跨境大货创新类保险服务' && (new Date().getTime()-new Date(this.form.startTime).getTime())/86400000>6||this.form.insureProduct=='大森林邮包险' && (new Date().getTime()-new Date(this.form.startTime).getTime())/86400000>6">
            <el-form-item prop="guaranteeFileList" :label-width="formLabelWidth" label="无货损保函">
              <f-upload v-model="form.guaranteeFileList" :dir="'shipping'" :isUseOss="true" accept=".jpg,.jpeg,.png,.pdf"></f-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-if="this.form.insureProduct=='无忧保'">
            <el-form-item  :label-width="formLabelWidth" label="倒签保函">
              <f-upload v-model="form.guaranteeFileList" :dir="'shipping'" :isUseOss="true" accept=".jpg,.jpeg,.png,.pdf"></f-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="handleToubao(form)">投 保</el-button>
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="saveEdit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="取消投保" :visible.sync="cancelFlag" width="580px">
      <el-form :model="cancelInsuranceForm">
        <el-form-item label="保险备注" label-width="100px">
          <el-input v-model="cancelInsuranceForm.insuranceRemark" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelFlag = false">取 消</el-button>
        <el-button type="primary" @click="submitCancelInsurance">确 定</el-button>
      </div>
    </el-dialog>


    <offline-insurance-dialog :visible.sync="offlineInsuranceFlag" :row="row" @ok="handleQuery"
                        ></offline-insurance-dialog>

  </div>
</template>

<script>
import {getAction, postAction, putAction} from '@/api/manage'
import QueryMixins from "../../../mixins/QueryMixins";
import {yingwenshuziPartten, zhengshuPattern, zhengshuxiaoshuPartten} from "@/utils/pattern";
import {setUpRouteUtil} from '@/utils/util';
import OfflineInsuranceDialog from "./modules/OfflineInsuranceDialog";


export default {
  name: 'Potential',
  mixins: [QueryMixins],
  components: {
    OfflineInsuranceDialog
  },
  data() {
    return {
      productTypeList:[],
      cancelInsuranceForm:{},
      cancelFlag:false,
      offlineInsuranceFlag:false,
      fileList: [],
      tip: "",
      dictCodes: ['insure_product','shipment_type','end_place_type','order_shipment_type'],
      rules: {
        orderNo: [
          {required: true, message: '请输入订单号', trigger: 'change'},
        ],
        insureProduct: [
          {required: true, message: '请选择保险产品', trigger: 'change'},
        ],
        productType:[
          {required: true, message: '请选择产品类型', trigger: 'change'},
        ],
        insured: [
          {required: true, message: '请输入被保险人', trigger: 'change'},
          {max: 50, message: '长度不能超过50', trigger: 'change'},
        ],
        insuredType: [
          {required: true, message: '请选择被保险人类型', trigger: 'change'},
        ],
        insuredNo: [
          {max: 25, message: '长度不能超过25', trigger: 'change'},
          {pattern: yingwenshuziPartten, message: '请输入英文或数字', trigger: 'change'}
        ],
        declareTotal: [
          {required: true, message: '请输入申报价值总额', trigger: 'change'},
          {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
        ],
        freight: [
          {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
        ],
        cur: [
          {required: true, message: '请选择保单币种', trigger: 'change'},
        ],
        totalkg: [
          {required: true, message: '请输入毛重', trigger: 'change'},
          {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
        ],
        remark: [
          {max: 255, message: '长度不能超过255', trigger: 'change'},
        ],
        transportType: [
          {required: true, message: '请选择运输方式', trigger: 'change'},
        ],
        transportVoyage: [
          {required: true, message: '请输入运输工具及航次', trigger: 'change'},
          {max: 255, message: '长度不能超过255', trigger: 'change'},
        ],
        deliveryType: [
          {required: true, message: '请选择派送方式', trigger: 'change'},
        ],
        packingType: [
          {required: true, message: '请选择包装类型', trigger: 'change'},
        ],
        packageCount: [
          {required: true, message: '请输入包装数量', trigger: 'change'},
          {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'}
        ],
        cargoWeight:[
          {required: true, message: '请输入总公斤数', trigger: 'change'},
          {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'}
        ],
        productDescription: [
          {required: true, message: '请输入货物描述', trigger: 'change'},
          {max: 1000, message: '长度不能超过1000', trigger: 'change'},
        ],
        goodsType: [
          {required: true, message: '请选择货物类别', trigger: 'change'},
        ],
        startTime: [
          {required: true, message: '请选择起运时间', trigger: 'change'},
        ],
        startCountry: [
          {required: true, message: '请选择起运国', trigger: 'change'},
        ],
        startPlace: [
          {required: true, message: '请输入起运地', trigger: 'change'},
        ],
        endPlaceType: [
          {required: true, message: '请选择目的地类型', trigger: 'change'},
        ],
        endCountry: [
          {required: true, message: '请选择目的国', trigger: 'change'},
        ],
        endPlace: [
          {required: true, message: '请选择目的地', trigger: 'change'},
        ],
        isGuarantee: [
          {required: true, message: '请选择是否上架保障', trigger: 'change'},
        ],
        isRiot: [
          {required: true, message: '请选择是否暴动保障', trigger: 'change'},
        ],
        shipmentId: [
          {required: true, message: '请输入shipmentId', trigger: 'change'},
          {max: 255, message: '长度不能超过255', trigger: 'change'},
        ],
        mblOrderNo: [
          {max: 200, message: '长度不能超过200', trigger: 'change'},
        ],
        hblOrderNo: [
          {max: 200, message: '长度不能超过200', trigger: 'change'},
        ],
        expressOrderNo: [
          {max: 1000, message: '长度不能超过1000', trigger: 'change'},
          //{pattern: yingwenshuziPartten, message: '请输入英文或数字', trigger: 'change'}
        ],
        shelvesAddress: [
          {max: 255, message: '长度不能超过255', trigger: 'change'},
        ],
        fragileFileList: [
          {required: true, message: '是易碎品或含易碎品请上传易碎品内包装图片', trigger: 'change'},
        ],
        guaranteeFileList: [
          {required: true, message: '起运后投保请上传无货损保函', trigger: 'change'},
        ],
      },
      ids: [],
      row: {},
      form: {},
      formLabelWidth: '120px',
      //控制弹框显示
      dialogFormVisible: false,
      dialogTouBaoVisible: false,
      columns: [
        {
          type: 'selection',
          width: 50
        },
        {
          prop: 'action',
          label: '操作',
          width: 140,
        },
        {
          prop: 'orderNo',
          label: '订单号',
          sortable: 'custom',
        },
        {
          prop: 'insureProduct',
          label: '保险产品',
          sortable: 'custom',
          width: 140
        },
        {
          prop: 'insured',
          label: '被保险人',
          sortable: 'custom',
          width: 120
        },
        {
          prop: 'insuredType',
          label: '被保险人类型',
          sortable: 'custom',
          width: 60
        },
        {
          prop: 'insuredNo',
          label: '被保险人证件',
          sortable: 'custom',
          width: 80
        },
        {
          prop: 'declareTotal',
          label: '申报价值总额',
          sortable: 'custom',
          width: 90
        },
        {
          prop: 'cur',
          label: '币种',
          sortable: 'custom',
          width: 60
        },
        {
          prop: 'containerNo',
          label: '柜号',
          sortable: 'custom',
          width: 100
        },
        {
          prop: 'shipmentType',
          label: '派送地址类型',
          sortable: 'shipmentType',
          width: 160
        },
        {
          prop: 'insuranceRemark',
          label: '保险备注',
          sortable: 'custom',
          width: 160
        },
        {
          prop: 'startPlace',
          label: '起运地',
          sortable: 'custom',
          width: 160
        },
        {
          prop: 'endPlace',
          label: '目的地',
          sortable: 'custom',
          width: 160
        },
        {
          prop: 'deliveryType',
          label: '派送方式',
          sortable: 'custom',
          width: 70
        },
        {
          prop: 'startTime',
          label: '起运时间',
          sortable: 'custom',
          width: 130
        },
        {
          prop: 'insuranceOrderId',
          label: 'PICC订单号',
          sortable: 'custom',
          width: 160
        },
        {
          prop: 'orderStatus',
          label: '状态',
          sortable: 'custom',
          width: 160
        },
      ],
      url: {
        list: '/order/insurance/list',
        edit: '/order/insurance/edit',
        exportXlsUrl: '/file/insurance/dcOsOrderInsuranceExcelVO1',  //导出
        // exportXlsUrl: '/file/export/excel/downloadInsuranceUnCommitExcel',  //导出
        import: '/order/insurance/import',  //导出
        toubao: '/order/insurance/toubao', //投保
        beatchToubao: '/order/insurance/beatchToubao', //批量投保
        cancelInsurance: '/order/insurance/cancelInsurance', //批量投保
        updateInsuredStatus: '/order/insurance/updateInsuredStatus', //更新状态
        updateInsuredKJBStatus: '/order/insurance/updateInsuredKJBStatus', //跨境堡更新状态
        update51InsuredStatus: '/order/insurance/update51InsuredStatus', //无忧保更新状态
        getFbaPiccAddressCountry: '/order/insurance/getFbaPiccAddressCountry', //获取PICC地址国家
        getFbaPiccAddress: '/order/insurance/getFbaPiccAddress', //获取PICC地址
        getUpsOrderNo: '/order/insurance/getUpsOrderNo', //获取upsNo
        getDeliveryTypeByOrderNo: '/order/insurance/getDeliveryTypeByOrderNo', //通过订单号获取派送方式
        getById: '/order/insurance/queryById',
        downloadExcelUrl:'/file/export/excel/downloadOpeNotNeedInsuranceExcel',
        getProductTypeList: '/order/insurance/get51InsureProductList', // 获取无忧保产品类型列表
      },
      countryList: [],
      startAddressList: [],
      endAddressList: [],
      resultFail: [],
      resultSucc: [],
      selectedRows: [], //多选选择的所有行
      btnLoading: false,
      shipmentTypeFlag:""
    }
  },
  watch:{
     'form.insureProduct': {
      deep: true,
      handler(o, p) {
       console.log(this.form.insureProduct,'form.insureProduct');
      }
    }
  },
  methods: {
    getProductTypeList(){
      getAction(this.url.getProductTypeList).then(res => {
        if (res.success) {
          this.productTypeList = res.result
        }
      })
    },
    offlineInsurance(row){
      this.offlineInsuranceFlag = true
      this.row = row
    },
     changeEndPlaceType(e){
         if(e == 'PRIVATE' || e == 'OTHER'){
             Reflect.deleteProperty(this.rules, 'shipmentId')
         }else{
             this.rules['shipmentId'] = [
                 {required: true, message: '请输入shipmentId', trigger: 'change'},
                 {max: 255, message: '长度不能超过255', trigger: 'change'}]
         }
     },
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push("/docpage/Edit?orderNo=" + orderNo);

    },
    handleSelectionChange(selection) { // 多选
      let arr = []
      let res = []
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        res.push(selection[i])
        this.emailList.push(selection[i]['email']);
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr]
      this.selectedRows = [...res]
    },
    handleEdit(row) {
      console.log(row.shipmentType ,'row666');
      getAction(this.url.getById + '?id=' + row.id).then(res => {
        if (res.success) {
          this.form = res.result;
          if(row.shipmentType!=='2'){
            this.form.warehouseCodeNo=''
          }
          if (res.result.fragileFileList && res.result.fragileFileList.length > 0) {
            this.$set(this.form, 'fragileFileList', res.result.fragileFileList);
          } else {
            this.$set(this.form, 'fragileFileList', []);
          }
          if (res.result.guaranteeFileList && res.result.guaranteeFileList.length > 0) {
            this.$set(this.form, 'guaranteeFileList', res.result.guaranteeFileList);
          } else {
            this.$set(this.form, 'guaranteeFileList', []);
          }
          this.getStartAddress(this.form.startCountry);
          this.getEndAddress(this.form.endCountry);
          this.getDeliveryTypeByOrderNo(this.form.orderNo);
          this.shipmentTypeFlag=row.shipmentType;
          this.dialogFormVisible = true;
        }
      })


    },
    saveEdit() {
      this.$refs.form.validate(valid => {
        if (valid) {

          if (this.form.fragileFileList) {
            this.$set(this.form, 'fragileOssId', this.form.fragileFileList.map(function (e) {
              return e.id
            }).join(","));
          }
          if (this.form.guaranteeFileList) {
            this.$set(this.form, 'guaranteeLetterOssId', this.form.guaranteeFileList.map(function (e) {
              return e.id
            }).join(","));
          }
          //编辑保存
          if (this.form.id) {
            console.log(this.form)
            putAction(this.url.edit, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery()
                  }
                })
          }
        }
      })
    },
    // 保险产品是PICC 开头的且起运时间是否超过7天
    startTimeIsover(row) {
      if (this.parseDict(row.insureProduct, "insure_product").indexOf('PICC') == -1) {
        return false;
      }
      if ((new Date() - 0) - (new Date(row.startTime) - 0) > 1000 * 60 * 60 * 24 * 7) {
        return true;
      } else {
        return false;
      }
    },
    //投保
    handleToubao(row) {
      let that = this
      if (this.startTimeIsover(row)) {
        this.$message({
          type: 'warning',
          message: '起运时间超过七天，PICC无法投保'
        })
        return
      }
      this.$confirm(`确定给 ：${row.insured} 投保吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        if(row.insureProduct === '无忧保'){
          let item = that.productTypeList.find(object => object.prodId === row.prodId);
          let productName = item.prodName
          row.productName = productName
        }

        postAction(this.url.toubao, row).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        }).catch(err => {
          this.$message.error(err.message);
        })
      }).catch(() => {
      })
    },
    //批量投保
    beatchToubao() {
      let startTimeIsover = false;
      this.selectedRows.forEach((item, index) => {
        if (this.startTimeIsover(item)) {
          startTimeIsover = true;
        }
      })
      if (startTimeIsover) {
        this.$message({
          type: 'warning',
          message: '起运时间超过七天，PICC无法投保'
        })
        return;
      }
      this.$confirm('确定批量投保？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        getAction(this.url.beatchToubao, {ids: this.selectedRowKeys.join(',')}).then(res => {
          if (res.result.fail.length > 0) {
            this.resultFail = res.result.fail;
            console.log(this.resultFail)
          } else {
            this.resultFail = [];
          }
          if (res.result.succ.length > 0) {
            this.resultSucc = res.result.succ;
          } else {
            this.resultSucc = [];
          }
          this.dialogTouBaoVisible = true;
          this.handleSearch()
        }).catch(err => {
          this.$message.error(err.message);
        })
      }).catch(() => {
      })
    },
    // 取消投保
    submitCancelInsurance(){
      postAction(this.url.cancelInsurance, {ids: this.selectedRowKeys.join(','),insuranceRemark:this.cancelInsuranceForm.insuranceRemark}).then(res => {
        this.cancelFlag = false;
        if(res.success){
          this.$message.success(res.message);
        }
        this.handleSearch()
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    //更新状态
    updateStatus() {
      getAction(this.url.updateInsuredStatus)
          .then(res => {
            this.form = res.result;
            var str = '';
            for (var i = 0; i < res.result.length; i++) {
              str += res.result[i];
            }
            this.$message.info(str);
          })
    },
    //跨境堡更新状态
    updateKJBStatus() {
      getAction(this.url.updateInsuredKJBStatus)
          .then(res => {
            /* this.form = res.result;
             var str = '';
             for (var i = 0; i < res.result.length; i++) {
               str +=res.result[i];
             }*/
            this.$message.success(res.message);
          })
    },
    update51Status() {
      getAction(this.url.update51InsuredStatus)
              .then(res => {
                /* this.form = res.result;
                 var str = '';
                 for (var i = 0; i < res.result.length; i++) {
                   str +=res.result[i];
                 }*/
                this.$message.success(res.message);
              })
    },
    //获取所有PICC国家
    getPiccCountry() {
      getAction(this.url.getFbaPiccAddressCountry)
          .then(res => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                var obj = {};
                obj.value = res.result[i].threecode;
                obj.label = res.result[i].cnname;
                this.countryList.push(obj);
              }
            }
          })
    },
    //获取PICC地址
    getStartAddress(code) {
      this.startAddressList = [];
      getAction(this.url.getFbaPiccAddress + '?countryCode=' + code)
          .then(res => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                var obj = {};
                obj.value = res.result[i];
                obj.label = res.result[i];
                this.startAddressList.push(obj);
              }
            }
          })
    },

    getDeliveryTypeByOrderNo(orderNo) {
      getAction(this.url.getDeliveryTypeByOrderNo + '?orderNo=' + orderNo)
          .then(res => {
            if (res.success) {
              let flag = res.result;
              if (flag === 'true') {
                this.form.deliveryType = "2";
              }

            }
          })
    },
    getUpsOrderNo(row) {
      if (row.expressCompany == 'UPS') {
        getAction(this.url.getUpsOrderNo + '?orderNo=' + row.orderNo)
            .then(res => {
              if (res.success) {
                this.form.expressOrderNo = res.result;
              }
            })
      } else {
        this.form.expressOrderNo = "";
      }

    },
    getEndAddress(code) {
      this.endAddressList = [];
      getAction(this.url.getFbaPiccAddress + '?countryCode=' + code)
          .then(res => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                var obj = {};
                obj.value = res.result[i];
                obj.label = res.result[i];
                this.endAddressList.push(obj);
              }
            }
          })
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.pageType = "1";
      this.ipagination.currentPage = 1
      this.handleQuery()
    },
    uploadSectionFile(param) {
      this.btnLoading = true;
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();

      form.append("file", fileObj);
      postAction(this.url.import, form)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery()
            }
          }).finally(() => {
        this.btnLoading = false;

      })

    },
  }, created() {
    this.queryForm.pageType = "1";
    this.getProductTypeList()
    this.getPiccCountry();
  },
  computed:{
    buttonShowList(){
      return {
        'dc':this.$btnIsShow('operation/baoxian/NoCommit','1','导出'),
        'gxzz':this.$btnIsShow('operation/baoxian/NoCommit','1','跨境堡更新状态'),
        'gxzz2':this.$btnIsShow('operation/baoxian/NoCommit','1','无忧保更新状态'),
        'xz1':this.$btnIsShow('operation/baoxian/NoCommit','1','下载不需要保险数据'),
        'qxtb':this.$btnIsShow('operation/baoxian/NoCommit','0','取消投保'),
        'tb':this.$btnIsShow('operation/baoxian/NoCommit','1','线下投保'),

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
