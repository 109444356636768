<template>
    <!-- 查询区域 -->
    <div class="main-full-content">
        <el-form
            class="input_top"
            ref="form"
            :model="queryForm"
            label-width="120px"
            size="medium"
        >
            <el-card class="box-card">
                <el-row :gutter="24">
                    <el-col :md="6" :sm="24">
                        <el-form-item label="工作单号">
                            <el-input
                                placeholder="请输入工作单号"
                                v-model="queryForm.orderNo"
                                @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="6" :sm="24">
                        <el-form-item label="客户">
                            <el-input
                                placeholder="请输入客户名字"
                                v-model="queryForm.userName"
                                @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :md="6" :sm="24">
            <span
                style="float: left; overflow: hidden"
                class="table-page-search-submitButtons"
            >
              <el-button
                  size="medium"
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询</el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  @click="handleReset"
                  icon="reload"
                  style="margin-left: 8px"
              >重置</el-button
              >
            </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <f-table
                ref="table"
                bordered
                rowKey="labelId"
                :columns="columns"
                :data="tableData"
                :pagination="ipagination"
                :expandedRowKeys="expandedRowKeys"
                :rowSelection="{ selectedRowKeys: selectedRowKeys }"
                tableHeight="78vh"
                :isRecordTableScroll="true"
                @change="handleTableChange"
                :scroll="{ x: 900, y: 1000 }"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'upsStatus'">
                        <span>{{ upsStatusRender(scope.row.upsStatus) }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                            style="color: #00a0e9"
                            @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'userName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                           style="color:#57a3f3;">{{ scope.row.userName }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import {getAction, postAction} from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";
import {setUpRouteUtil} from '@/utils/util';

export default {
    name: "TableDemo",
    mixins: [QueryMixins],
    components: {},
    data() {
        return {
            tableData: [],
            queryForm: {
                upsStatus: "2",
                orderNo: "",
                username: "",
            },
            OrderNo: null,
            expandedRowKeys: [],
            ysfsDictOptions: [], // 运输方式字典
            lhfsDictOptions: [], // 航线名称字典
            id: " ",
            confirmLoading: false,
            ipagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ["10", "50", "100"],
                showTotal: (total, range) => {
                    return range[0] + "-" + range[1] + " 共" + total + "条";
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0,
            },
            // 列表表头
            columns: [
                {
                    label: "拆单号",
                    align: "center",
                    width: 150,
                    prop: "shipOrder",
                },
                {
                    label: "主订单号",
                    align: "center",
                    prop: "orderNo",
                    width: 130,
                },
                {
                    label: "打单时间",
                    align: "center",
                    prop: "creatData",
                    width: 120,
                    customRender: (text, record, index) => {
                        var date = new Date(text);
                        var YY = date.getFullYear() + "-";
                        var MM =
                            (date.getMonth() + 1 < 10
                                ? "0" + (date.getMonth() + 1)
                                : date.getMonth() + 1) + "-";
                        var DD =
                            date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

                        return YY + MM + DD;
                    },
                },
                {
                    label: "客户名称",
                    align: "center",
                    prop: "userName",
                    width: 200,
                },
                {
                    label: "承运商",
                    width: 70,
                    align: "center",
                    prop: "carriers",
                },
                {
                    label: "发到",
                    align: "center",
                    prop: "sendAddress",
                    width: 320,
                },
                {
                    label: "件数",
                    align: "center",
                    prop: "parcelNum",
                    width: 150,
                },
                {
                    label: "已付价格",
                    align: "center",
                    prop: "finalPrice",
                    width: 150,
                },
                {
                    label: "状态",
                    align: "center",
                    prop: "upsStatus",
                    width: 90,
                    scopedSlots: {customRender: "upsStatusRender"},
                },
                {
                    label: "跟踪单号",
                    prop: "trackingNumbers",
                    align: "center",
                    width: 180,
                },
            ],
            // 分页参数
            type: "radio",
            url: {
                list: "/interface/label/selecUpsHasUpsShipList",
                UpsDemolitionOrderList: "/interface/label/UpsDemolitionOrderList",
            },
        };
    },
    computed: {
        currentId() {
            return this.id;
        },
    },
    created() {

        this.initDictConfig();
    },
    mounted() {
    },
    methods: {


        intoDocpage(orderNo) {
            // 对于路由不在左侧菜单中的选中菜单处理
            setUpRouteUtil(this.$route.path, '/docpage/Edit');
            this.$router.push("/docpage/Edit?orderNo=" + orderNo);

        },
        upsStatusRender(text) {
            if (text == "1") {
                return "正常";
            } else {
                return "取消";
            }
        },
        handleSearch() {
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
        handleReset() {
            this.queryForm = {};
            this.filterForm = {};
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
        async handleQuery() {
            this.loading = true
            this.queryForm.upsStatus = '2'
            let form = {...this.queryForm, ...this.filterForm, ...this.initData}
            for (let item in form) {
                if (typeof form[item] === 'string') {
                    form[item] = form[item].trim()
                    if (form[item] === '') {
                        form[item] = null
                    }
                }
            }
            const {prop, order} = this.isorter
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
            }
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === 'startTime') {
                    this.$set(params, 'column', 'startTime')
                } else if (this.enabledCreatetime) {
                    this.$set(params, 'column', 'createTime')
                }
                //提货中查询
                if (this.queryForm.column === 'distributionTime') {
                    this.$set(params, 'column', 'distributionTime')
                }
                //排序
                //提货中查询
                if (this.queryForm.column === 'sort') {
                    this.$set(params, 'column', 'sort')
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage
                params.pageSize = this.ipagination.pageSize
            }
            await getAction(this.url.list, params).then((res) => {
                console.log(res)
                if (res.success) {
                    this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                    if (res.result) {
                        const {records, total} = res.result
                        this.tableData = records || res.result;

                        this.ipagination && (this.ipagination.total = total || res.result.length)
                    } else {

                        this.tableData = [];

                        this.ipagination = 0;
                    }

                }
            }).finally(() => {
                this.loading = false;
                this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
            })
        },

        loadData(arg) {
            console.log('进入loadata')
            if (!this.url.list) {
                this.$message.error("请设置url.list属性!");
                return;
            }
            //加载数据 若传入参数1则加载第一页的内容
            if (arg === 1) {
                this.ipagination.current = 1;
            }
            // var params = this.getQueryParams();//查询条件
            let params = {...this.queryForm};
            params.upsStatus = "2";
            this.loading = true;
            getAction(this.url.list, params).then((res) => {
                if (res.success) {
                    this.dataSource = res.result.list;
                    this.ipagination.total = res.result.totalCount;
                }
                if (res.code === 510) {
                    this.$message.warning(res.message);
                }
                this.loading = false;
            });
        },
        initDictConfig() {
        },
        modalFormOk(formData) {
        },
    },
};
</script>
<style scoped>
</style>
