<template>
  <el-dialog
      :title="row.id ? '编辑' : '新增'"
      :visible="visible"
      width="860px"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
  >
    <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        size="medium"
    >
      <el-row>
        <!--<el-col :span="12">
          <el-form-item prop="pushPort" label="发布端口">
            <el-select
                clearable
                filterable
                class="f-full-width"
                @change="selectPushPort($event)"
                v-model="form.pushPort"
                placeholder="请选择"
                style="width:100%"
            >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>-->
        <!--<el-col :span="12">
          <el-form-item prop="articleTypeId" label="文章类别">
            <el-select
                clearable
                filterable
                v-model="form.articleTypeId"
                placeholder="获取所有文章类别"
                style="width:100%"
            >
              <el-option
                  v-for="(item, index) in allArticleTypeList"
                  :label="item.articleType"
                  :value="item.articleType?item.articleType:item.id"
                  :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>-->
        <el-col :span="12">
          <el-form-item prop="title" label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="title" label="版本号">
            <el-input v-model="form.version"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="text" label="内容">
            <!-- <el-input v-model="form.text" type="textarea" rows="5"></el-input> -->
            <!-- 富文本编辑 -->
            <quill-editor
                ref="text"
                v-model="form.text"
                class="myQuillEditor"
                :options="editorOption"
            />
            <!-- ueditor方式 -->
            <!-- <vue-ueditor-wrap v-model="form.text" :config="myConfig" @beforeInit="addCustomButtom"></vue-ueditor-wrap> -->
          </el-form-item>
        </el-col>
        <!--<el-col :span="24">
          <el-form-item prop="coverUrl" label="封面">

            <f-upload
                v-model="form.coverFile"
                list-type="picture-card"
                v-if="form.coverFile.length !==1"
                :dir="'content'"
            ></f-upload>
          </el-form-item>
        </el-col>-->
        <el-col :span="24">
          <el-form-item prop="attachFileId" label="附件">
            <!-- <f-upload v-model="fileList"></f-upload> -->
            <f-upload v-model="form.attachFile" :dir="'content'"></f-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
      >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { httpAction, getAllArticle } from "@/api/manage";

import VueUeditorWrap from "vue-ueditor-wrap"; // ES6 Module

import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Quill.register("modules/ImageExtend", ImageExtend);

const form = {
  title: "", // 文章标题
  pushPort: "", // 发布端口
  articleTypeId: "", // 文章类别
  text: "",
  coverUrl: '', // 封面
  coverId: '', // 封面id
  fileList: [], // 附件
  attachFileId: [] // 附件id

};
export default {
  name: "StorePublishAddDialog",
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    quillEditor,
    VueUeditorWrap,
  },
  data() {
    return {
      form: { ...form },
      //   fileList: [],

      // 富文本配置
      editorOption: {
        modules: {
          ImageExtend: {
            loading: true,
            name: "img",
            action: "",
            response: (res) => {
              return res.info;
            },
          },
          toolbar: {
            container: container,
            handlers: {
              image: function () {
                QuillWatch.emit(this.quill.id);
              },
            },
          },
        },
      },
      // ueditor 方式
      //  myConfig: {
      //   // 编辑器不自动被内容撑高
      //   autoHeightEnabled: false,
      //   // 初始容器高度
      //   initialFrameHeight: 240,
      //   // 初始容器宽度
      //   initialFrameWidth: '100%',
      //   // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
      // //   serverUrl: 'http://35.201.165.105:8000/controller.php',
      //   // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
      //   UEDITOR_HOME_URL: '/static/UEditor/'
      // },
      options: [
        // {
        //     value: '',
        //     label: '全部'
        // },
        {
          value: "0",
          label: "PC员工端",
        },
        {
          value: "1",
          label: "PC客户端",
        },
        {
          value: "2",
          label: "小程序员工端",
        },
        {
          value: "3",
          label: "小程序客户端",
        },
      ],
      allArticleTypeList: [
        // {
        //     value: 0,
        //     label: 'PC员工端'
        // },
        // {
        //     value: 1,
        //     label: 'PC客户端'
        // },
      ],
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        /*pushPort: [
          { required: true, message: "请输入端口类型", trigger: "change" },
        ],*/
        text: [{ required: true, message: "请输入内容", trigger: "change" }],
        /*articleTypeId: [
          { required: true, message: "请输入文章类别", trigger: "change" },
        ],*/
        // coverUrl: [
        //   { required: true, message: "请上传封面", trigger: "change" },
        // ],
        /*coverId: [
          { required: true, message: "请上传封面", trigger: "change" },
        ]*/
      },
      url: {
        add: "/sys/storePublish/add",
        edit: "/sys/storePublish/edit",
      },
      queryForm: {},
    };
  },
  watch: {
    visible(val) {
      if (this.row){
        this.form = this.row;
      }

      if(val && this.row.id){
        return;
      }else{
        this.form = {...form}
        // this.$refs.form.resetFields(); //等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
      }
    },
  },
  methods: {
    //   ueditor方式 实现富文本，上传暂时无法实现
    //   addCustomButtom(editorId) {
    //     let that = this
    //     // 1. 自定义按钮
    //     window.UE.registerUI('test-button' + editorId, function (editor, uiName) {
    //       // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
    //       editor.registerCommand(uiName, {
    //         execCommand: function () {
    //           editor.execCommand(
    //           )
    //         }
    //       })
    //       // 创建一个 button
    //       var btn = new window.UE.ui.Button({
    //         // 按钮的名字
    //         name: uiName,
    //         // 提示
    //         title: '图片上传',
    //         // 需要添加的额外样式，可指定 icon 图标，图标路径参考常见问题 2
    //         cssRules: "background-image: url('/static/image/upload.png') !important;background-size: cover;",
    //         // 点击时执行的命令
    //         onclick: function () {
    //           // 这里可以不用执行命令，做你自己的操作也可
    //           that.dialogVisible = true
    //         }
    //       })
    //       // 当点到编辑内容上时，按钮要做的状态反射
    //       editor.addListener('selectionchange', function () {

    //         var state = editor.queryCommandState(uiName)
    //         if (state === -1) {
    //           btn.setDisabled(true)
    //           btn.setChecked(false)
    //         } else {
    //           btn.setDisabled(false)
    //           btn.setChecked(state)
    //         }
    //       })
    //       // 因为你是添加 button，所以需要返回这个 button
    //       return btn
    //     }, 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */, editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */)
    //   },
    // 查询各端口下的文章类别
    /*selectPushPort(val) {
      const params = {
        pushPort: val,
      };
      if (val != null && val != "" && val != undefined) {
        getAllArticle(params)
            .then((response) => {
              //给allArticleTypeList数组赋值
              this.allArticleTypeList = response.result;
            })
            .catch((err) => {
              return err;
            });
      }
    },*/
    // 提交表单
    handleSubmit() {
      let url = this.url.add;
      let method = "POST";
      if (this.row.id) {
        url = this.url.edit;
        method = "PUT";
      }
      this.$refs.form.validate((valid) => {
        if (valid) {

          //将上传的集合 转为字符串
          let urllist= [];
          if(this.form.coverFile){
            for(var i=0;i<this.form.coverFile.length;i++){
              let id = this.form.coverFile[i].id;
              urllist.push(id);
            }
          }
          this.form.coverId = urllist.join(",");
          let attchlist = [];
          if(this.form.attachFile){
            for(var i=0;i<this.form.attachFile.length;i++){
              let id = this.form.attachFile[i].id;
              attchlist.push(id);
            }
          }
          this.form.attachFileId = attchlist.join(',')
          console.log("此时提交的里面有什么",this.form)
          httpAction(url, method, this.form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.$emit("update:visible", false);
      this.$refs.form.resetFields();
      //   this.form = { ...form };
    },
  },
  created() {
    // this.form = this.row;
    if (!this.form.attachFile){
      this.form.attachFile = [];
    }
    if (!this.form.coverFile){
      this.form.coverFile = [];
    }
  },
};
</script>

<style lang="scss">
.ql-tooltip {
  z-index: 1;
}
.ql-editor {
  height: 200px;
}
</style>
