<template>
    <el-form :model="form" :rules="rules" ref="form" label-width="90px" size="medium">
        <el-row>
            <el-col :span="24">
                <el-form-item prop="orgType" label="类型">
                    <el-radio-group v-model="form.orgType" @change="handleChange">
                        <el-radio label="1">公司</el-radio>
                        <el-radio label="2">部门</el-radio>
                        <el-radio label="3">职位</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item prop="departName" :label="form.orgType === '1' ? '公司名称' : form.orgType === '2' ? '部门名称' : '职位名称'">
                    <el-input v-model="form.departName" maxlength="30" show-word-limit ></el-input>
                </el-form-item>
            </el-col>
            <template v-if="form.orgType !== '1'">
                <el-col :span="24">
                    <el-form-item prop="parentId" :label="form.orgType === '2' ? '上级部门' :'所属部门'">
                        <tree-dropdown v-model="form.parentId" :data="data"></tree-dropdown>
                    </el-form-item>
                </el-col>
            </template>
            <el-col :span="24">
                <el-form-item prop="charge" label="负责人">
<!--                    <el-input v-model="form.charge"></el-input>-->
                    <el-select v-model="form.charge" placeholder="请选择" clearable filterable>
                        <el-option
                                v-for="item in chargeOptions"
                                :key="item.id"
                                :label="item.realname"
                                :value="item.realname">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item prop="contact" label="联系人" :rules="form.orgType === '1'? rules['contact'] : []">
                    <el-input v-model="form.contact" maxlength="20" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item prop="mobile" label="联系电话" :rules="form.orgType === '1'? rules['mobile'] : rulePhone">
                    <el-input v-model="form.mobile" maxlength="20" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <template v-if="form.orgType === '1'">
                <el-col :span="24">
                    <el-form-item prop="registrationTime" label="注册时间">
                        <el-date-picker
                            class="c-full-width"
                            v-model="form.registrationTime"
                            type="datetime"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="longitude" label="经度">
                        <el-input v-model="form.longitude" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="latitude" label="纬度">
                        <el-input v-model="form.latitude" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="address" label="办公地址">
                        <el-input type="textarea" v-model="form.address" rows="4" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </template>
            <el-col :span="24">
                <el-form-item prop="departOrder" label="排序">
                    <el-input v-model="form.departOrder" maxlength="5" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="isAdd && buttonShowList.aa && row.parentId && !row.children">
                <el-form-item prop="isSalesGroup" label="是否销售小组">
                    <el-radio-group v-model="form.isSalesGroup" >
                        <el-radio :label="'0'">否</el-radio>
                        <el-radio :label="'1'">是</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item prop="deliveryPointId" label="所属仓库">
                    <el-select filterable clearable v-model="form.deliveryPointId">
                        <el-option v-for="(i,d) in warehouseList" :key="d" :value="i.id" :label="i.content"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="showButton">
                <el-form-item label-width="30px" class="f-text-right">
                    <el-button type="primary" @click="handleSubmit"

                               v-if="buttonShowList.bb"
                    >确定</el-button>
                    <el-button plain @click="handleReset">重置</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { mobilePattern } from '@/utils/pattern'
import { getAction } from '@/api/manage'
import { phoneValidator, intValidator } from '@/utils/validator'
import TreeDropdown from './TreeDropdown'
const form = {
    orgType: '1',
    departName: '',
    parentId: '',
    charge: '',
    contact: '',
    mobile: '',
    registrationTime: '',
    longitude: '',
    latitude: '',
    address: '',
    departOrder: '',
    isSalesGroup: '',
}
export default {
    name: 'DepartmentForm',
    components: {
        TreeDropdown
    },
    props: {
        isAdd:{
            type:Boolean,
            default:true
        },
        showButton: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            default: () => {}
        },
        data: {
            type: Array,
            default: () => []
        },
        warehouseList: {
            type: Array,
            default: () => []
        },
        // chargeOptions:{
        //     type: Array,
        //     default: () => []
        // },
    },
    data() {
        return {
            value: '',
            form: { ...form },
            chargeOptions:[],
            rulePhone: [{ validator: phoneValidator, trigger: 'change' }],
            rules: {
                orgType: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
                departName: [
                    { required: true, message: '请输入公司/部门/职位名称', trigger: 'change' }
                ],
                parentId: [
                    { required: true, message: '请选择上级部门', trigger: 'change' }
                ],
                charge: [
                    { required: true, message: '请输入负责人', trigger: 'change' }
                ],
                contact: [
                    // { required: true, message: '请输入联系人', trigger: 'change' }
                ],
                mobile: [
                    // { required: true, message: '请输入手机号', trigger: 'change' },
                    // { pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'change' },
                ],
                registrationTime: [
                    // { required: true, message: '请输入注册时间', trigger: 'change' }
                ],
                longitude: [
                    // { required: true, message: '请输入经度', trigger: 'change' }
                ],
                latitude: [
                    // { required: true, message: '请输入纬度', trigger: 'change' }
                ],
                address: [
                    // { required: true, message: '请输入详细地址', trigger: 'change' }
                ],
                departOrder: [
                    { validator: intValidator, trigger: 'change' }
                ],
            },
            url:{
                getCharges:'/sys/user/getCharges'
            },
        }
    },
    watch: {
        row: {
            deep: true,
            immediate: true,
            handler() {
                this.handleReset()
            }
        }
    },
    created() {
        this.getCharges();
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const { orgType, departName, parentId, charge, contact, mobile, registrationTime, longitude, latitude, address, departOrder,deliveryPointId,isSalesGroup  } = this.form
                    let form = {
                        orgType,
                        departName,
                        charge,
                        contact,
                        mobile,
                        departOrder,
                        deliveryPointId,
                        isSalesGroup
                    }
                    if(orgType === '1') {
                        form = {
                            ...form,
                            registrationTime,
                            longitude,
                            latitude,
                            address,
                            parentId: '',
                            deliveryPointId
                        }
                    } else if(orgType === '2') {
                        form = {
                            ...form,
                            parentId,
                            registrationTime: '',
                            longitude: '',
                            latitude: '',
                            address: '',
                            deliveryPointId
                        }
                    } else if(orgType === '3') {
                        form = {
                            ...form,
                            parentId,
                            registrationTime: '',
                            longitude: '',
                            latitude: '',
                            address: '',
                            deliveryPointId
                        }
                    }

                    this.$emit('ok', form)
                }
            })
        },
        handleReset() {
            for(let item in form) {
                this.form[item] = this.row[item] != undefined ? this.row[item] : form[item]
            }
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        handleChange() {
            this.$refs.form.clearValidate()
        },
        handleClear() {
            this.form = { ...form }
        },
        getCharges(){
            getAction(this.url.getCharges).then((res)=>{
                this.chargeOptions = res;
            })
        },
    },
  computed:{
    buttonShowList() {
      return {
        'aa':this.$btnIsShow('isystem/DepartmentManage', '0', '是否属于销售小组'),
        'bb':this.$btnIsShow('isystem/DepartmentManage','0','确定'),
      }
    }
  }
}
</script>

<style lang="scss">

</style>
