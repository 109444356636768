<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="敏感词">
            <el-input v-model="queryForm.name" @keyup.enter.native="handleSearch"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
                   v-if="buttonShowList.add"
        >新增</el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" v-if="buttonShowList.bj" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" v-if="buttonShowList.sc" @click="handleDele(scope.row)">删除</el-button>
        </template>


      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>
    <el-dialog :title="'新增敏感词汇'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
               v-if="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">

        <el-form-item label="敏感词" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {deleteAction, postAction, putAction} from '@/api/manage'
import QueryMixins from "../../../mixins/QueryMixins";
import {yingwenPattern} from '@/utils/pattern'

export default {
  name: 'Importer',
  mixins: [QueryMixins],
  data() {
    return {
      dictCodes: ['apportion_type'],
      rules: {
        name: [
          {required: false, message: '请输入敏感词', trigger: 'change'},
          {max: 50, message: '长度不能超过50', trigger: 'change'},

        ],

      },
      ids: [],
      row: {},
      form: {},
      formLabelWidth: '110px',
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          prop: 'action',
          label: '操作',
        },
        {
          prop: 'name',
          label: '敏感词',
          /*sortable: 'custom',*/
        },

      ],
      url: {
        list: '/sys/bd/senstive/word/list',
        add: '/sys/bd/senstive/word/add',
        edit: '/sys/bd/senstive/word/edit',
        getById: '/sys/bd/senstive/word/getById',
        delete: '/sys/bd/senstive/word/delete',
      },
    }
  },
  methods: {
    handleSelectionChange(selection) { // 多选
      let arr = []
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.emailList.push(selection[i]['email']);
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr]
    },
    handleAdd() {
      this.form = {};
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    localStorage.removeItem("dict:bd_cur");
                    this.handleQuery()
                  }
                })
          } else {
            postAction(this.url.add, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery()
                  }
                })
          }
        }
      })

    },
    handleDele(row) {
      this.$confirm(`确定删除该敏感词吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delete, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1
      this.handleQuery()
    }
  }, created() {
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/SensitiveWord','0','新增'),
        'bj':this.$btnIsShow('isystem/BaseData/SensitiveWord','1','编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/SensitiveWord','1','删除'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
