<template>
  <div class="department-manage-wrap" v-loading="loading">
    <el-row :gutter="12">
      <el-col :span="10">
        <department-tree-module
          class="tree-part"
          :data="data"
          @current-change="handleCurrentChange"
          @ok="initOk"
          :style="{ height: height + 'px' }">
        </department-tree-module>
      </el-col>
      <el-col :span="14">
        <department-edit-module class="edit-part" :row="row" :data="data" :style="{ height: height + 'px' }"></department-edit-module>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAction } from "@/api/manage";
import DepartmentTreeModule from "./modules/DepartmentTreeModule"
import DepartmentEditModule from "./modules/DepartmentEditModule"
export default {
  name: "MenuManage",
  components: {
    DepartmentTreeModule,
    DepartmentEditModule,
  },
  data() {
    return {
      data: [],
      dataReference: {},
      row: {},
      height: 850,
      loading: false,
      url: {
          list: '/sys/sysDepart/queryTreeList'
      }
    }
  },
  mounted() {
    this.initOk();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleGetWindowHeight)
    this.$bus.$off('department-data-refresh')
  },
  methods: {
      initOk(){
          this.handleGetData();
          this.handleGetWindowHeight()
          window.addEventListener('resize', this.handleGetWindowHeight)

          this.$bus.$on('department-data-refresh', () => {
              this.row = {}
              this.handleGetData()
              this.$bus.$emit('department-data-reset')
          })
      },
    handleCurrentChange(id) {
      this.row = this.dataReference[id];
      // console.log('当前选择的部门',this.row);
    },
    handleGetData() {
        this.loading = true
        getAction(this.url.list).then((res) => {
            this.data = res.result;
            this.handleSetReference(this.data);
        }).finally(() => {
          this.loading = false
        })
    },
    handleSetReference(data) {
      for (let i = 0; i < data.length; i++) {
        this.dataReference[data[i]["id"]] = data[i];
        if (data[i]["children"] && data[i]["children"].length) {
          this.handleSetReference(data[i]["children"]);
        }
      }
    },
    handleGetWindowHeight() {
        const h = (document.body.offsetHeight || window.innerHeight || window.innerHeight) - 118
        const min_h = 844
        this.height = h > min_h ? h : min_h
    }
  },
};
</script>

<style lang="scss" scoped>
.department-manage-wrap {
  height: 100%;
  .el-row,
  .el-col,
  .tree-part,
  .edit-part {
    height: 100%;
  }
  .tree-part,
  .edit-part {
    min-height: 600px;
  }
  .tree-part,
  .edit-part {
    background: #ffffff;
  }
}
</style>
