<template>
  <el-dialog
          :title="row.id ? '编辑关务' : '新增关务'"
          :visible="dialogVisible"
          width="840px"
          custom-class="c-custom-dialog"
          append-to-body
          :before-close="handleClose"
          :close-on-click-modal="false">
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
          <el-row class="inputs_row">
              <el-col :span="12">
                  <el-form-item prop="hsCode" label="海关编码">
                      <el-input v-model="form.hsCode" show-word-limit maxlength="50"></el-input>
                  </el-form-item>
              </el-col>
              <!--<el-col :span="12">-->
                  <!--<el-form-item prop="cname" label="中文品名">-->
                      <!--<el-input v-model="form.chineseProductName" show-word-limit maxlength="255" type="textarea"></el-input>-->
                  <!--</el-form-item>-->
              <!--</el-col>-->
              <el-col :span="12">
                  <el-form-item prop="country" label="国家">
                      <el-select
                              clearable
                              filterable
                              @click.native="initCountryList"
                              class="f-full-width"
                              v-model="form.country"
                              placeholder="请选择">
                          <el-option
                                  v-for="(item,index) in countrylist"
                                  :key="index"
                                  :label="item.label"
                                  :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item prop="dutyRate" label="税率">
                      <el-input v-model="form.dutyRate">
                          <template slot="append">%</template>
                      </el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item pga="demand" label="监管条件">
                      <el-input v-model="form.pga" maxlength="255" show-word-limit type="textarea"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item prop="clearRequirementsIdList" label="清关要求">
                        <f-select v-model="form.clearRequirementsIdList" :isNeed="form.clearRequirementsIdList" :dict="'tax_clear_requirements'" :multiple="true"></f-select>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item prop="clearCasesIdList" label="案例">
                      <f-select v-model="form.clearCasesIdList" :isNeed="form.clearCasesIdList" :dict="'tax_clear_cases'" :multiple="true"></f-select>
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>
      <span slot="footer">
          <el-button size="medium" @click="handleClose">取消</el-button>
          <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
      </span>
  </el-dialog>
</template>

<script>
  import {httpAction} from '@/api/manage'
  import {getAction, getallcountries,postAction} from "@/api/manage";
  import {sanweixiaoshu} from "@/utils/pattern";
  import {noZhongwenValidator} from "@/utils/validator";
  import {mgHsCodePattern} from "@/utils/pattern";

  export default {
      name: 'ProductDialog',
      props: {
          dialogVisible: {
              type: Boolean
          },
          row: {
              type: Object,
              default: () => {
              }
          }
      },
      data() {
          return {
              dictCodes:['tax_clear_requirements','bd_clear_cases'],
              picList: [],
              form: {},
              rules: {
                  mgHsCode: [
                      {required: true, message: "请输入美国海关编码", trigger: "change"},
                      {pattern:mgHsCodePattern,message: '只能是4位数字.2位数字.4位数字的格式'}
                  ],
                  // hsCode: [
                  //     {required: true, message: "请输入海关编码", trigger: "change"},
                  //
                  // ],
                  dutyRate: [
                      {required: true, message: "请输入税率", trigger: "change"},
                  ],
              },
              url: {
                  add: "/sys/bdCountryHsCode/addOrUpdate",
                  edit: '/customer/product/edit',
                  getDemandList: '/sys/dict/getDictItems/demand',
              },
              demandList: [],
              clearCasesIdList:[],
              clearRequirementsIdList:[],
              countrylist: [],
          }
      },
      watch: {
          dialogVisible(val) {
              if (val && this.row.id) {
                  this.form = {...this.row}
              }
              if (this.form.clearCases) {
                  this.$set(this.form, 'clearCasesIdList', this.form.clearCases.split('/'))
              }
              if (this.form.clearRequirements) {
                  this.$set(this.form, 'clearRequirementsIdList', this.form.clearRequirements.split('/'))
                  console.log(this.form.clearRequirementsIdList)
              }
          },
          picList(val) {
              if (val && val.length > 0) {
                  if (this.$refs['picUrl']) {
                      this.$refs['picUrl'].clearValidate();
                  }
              }
          },

      },
      methods: {
          initCountryList() {
              if (this.countrylist.length <= 0) {
                  this.countrylist = [];
                  getallcountries().then(res => {
                      if (res.success) {
                          for (var i = 0; i < res.result.length; i++) {
                              let obj = {};
                              obj.value = res.result[i].cnName;
                              obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                              this.countrylist.push(obj);
                          }
                      }
                  }).catch(err => {
                      this.$message.error(err)
                  })
              }

          },
          handleSubmit() {
              this.$refs["form"].validate((valid) => {
                  if (valid) {
                      postAction(this.url.add, this.form)
                          .then((res) => {
                              if (res.success) {
                                  this.$message.success(res.message);
                                  this.$emit('update:dialogVisible', false)
                              } else {
                              }
                          });
                  }
              });
          },
          handleClose() {
              this.$emit('update:dialogVisible', false)
              this.$refs.form.resetFields()
          }
      },
      created() {

      }
  }
</script>

<style lang="scss" scoped>
  ::v-deep
  .el-dialog__body {
      padding: 20px 40px 20px 6px;
  }
  .addEditBox {
    max-height: 615px;
    overflow: scroll;
  }
</style>
