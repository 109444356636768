
<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="auto" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :span="5">
            <el-form-item label="活动类型">
              <f-select dict="ac_fee_config_type" v-model="queryForm.type" :isNeed="queryForm.type">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="6" :sm="24">
            <el-form-item label="活动期限" label-width="100px">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.startTime"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.endTime"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="status" label=" 是否启用">
              <el-select v-model="queryForm.isEnable">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item label=" 费用名称">
              <el-input v-model="queryForm.feeName" @keyup.enter.native="handleSearch" placeholder="支持模糊查询"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
          v-if="buttonShowList.add">新增</el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="66vh"
        @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              v-if="buttonShowList.bj">编辑</el-button>
            <el-button type="text" @click="handleDele(scope.row)"
              v-if="buttonShowList.sc">删除</el-button>
          </template>
          <template v-else-if="scope.column.property === 'fieldRulesList'">
            <div class="flex_wrap_start">
              <span class="f-m-r-20" v-for="(item, index) in scope.row.fieldRulesList" :key="index">{{(item.fieldName + '：' + item.fieldValue)}}</span>
            </div>
          </template>
          <template v-else-if="scope.column.property === 'isEnable'">
            <span>{{ scope.row.isEnable == '1' ? '启用' : '禁用' }}</span>
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span>{{ $store.state.activityMap[scope.row.type] }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <add-active-configuration-dialog :visible="activeVisible" :row="row" @update:visible="(value) => { activeVisible = value; }"
      @ok="handleQuery()">
    </add-active-configuration-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction, putAction, getcityList, getstateList, getallcountries } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";
import { zhengshuPattern } from "@/utils/pattern";
import AddActiveConfigurationDialog from "./components/AddActiveConfigurationDialog"
//import {mobilePattern} from '@/utils/pattern'

export default {
  name: 'ActiveConfiguration',
  mixins: [QueryMixins],
  components: {
    AddActiveConfigurationDialog
  },
  data() {
    return {
      isUsePost: true,
      rules: {
        bankName: [
          { required: true, message: '请输入银行名称', trigger: 'change' },
          { max: 100, message: '长度不能超过100', trigger: 'change' },
        ],
        bankAddress: [
          { required: false, message: '请输入银行地址', trigger: 'change' },
          { max: 200, message: '长度不能超过50', trigger: 'change' },
        ],
        cur: [
          { required: true, message: '请输入币种', trigger: 'change' },
          { max: 20, message: '长度不能超过20', trigger: 'change' },
        ],
        accountName: [
          { required: true, message: '请输入开户名', trigger: 'change' },
          { max: 50, message: '长度不能超过50', trigger: 'change' },
        ],
        account: [
          { required: true, message: '请输入账号', trigger: 'change' },
          { max: 50, message: '长度不能超过50', trigger: 'change' },
        ],
        swiftCode: [
          { required: false, message: '请输入银行识别码', trigger: 'change' },
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'change' },
          { max: 255, message: '长度不能超过255', trigger: 'change' },
        ],
        sortNum: [
          { pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change' },
        ],
      },
      emailList: [],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: '110px',
      //控制弹框显示
      activeVisible: false,
      columns: [
        {
          prop: 'action',
          label: '操作',
        },
        {
          prop: 'type',
          label: '活动类型',
        },
        {
          prop: 'actName',
          label: '活动名称',
        },
        {
          prop: 'isEnable',
          label: '状态',
        },
        {
          prop: 'startTime',
          label: '活动开始',
        },
        {
          prop: 'endTime',
          label: '活动截止',
        },
        {
          prop: 'createTime',
          label: '创建时间',
        },
        {
          prop: 'createBy',
          label: '创建人',
        },
        {
          prop: 'updateTime',
          label: '修改时间',
        },
        {
          prop: 'updateBy',
          label: '修改人',
        },
        {
          prop: 'fieldRulesList',
          label: '活动规则',
        },
        {
          prop: 'baseInfoShow',
          width: 300,
          label: '基础信息',
        },
      ],
      url: {
        list: '/finance/activitiesInfo/queryPage',
        delete: '/finance/activitiesInfo/del',
      },
    }
  },
  methods: {
    handleAdd() {
      this.row = {};
      this.activeVisible = true;
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.row = JSON.parse(JSON.stringify(row));
      this.activeVisible = true;
    },
    handleDele(row) {
      this.$confirm(`确定删除`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.delete, [row.id]).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
  }, created() {
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/ActiveConfiguration', '0', '新增'),
        'bj':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/ActiveConfiguration', '1', '编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/ActiveConfiguration', '1', '删除'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
