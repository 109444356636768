<!--海运报价-已发布-->
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">


                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="船公司">
                            <f-select
                                    v-model="queryForm.shippingCompanyId"
                                    :isNeed="queryForm.shippingCompanyId"
                                    :dict="'ship_company'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港" :label-width="formLabelWidth">
                            <f-select
                                    v-model="queryForm.startPortId"
                                    :isNeed="queryForm.startPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港" :label-width="formLabelWidth">
                            <f-select
                                    v-model="queryForm.endPortId"
                                    :isNeed="queryForm.endPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="handleAddLine" v-if="buttonShowList.addLine">新增航线</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="fId"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="backOff(scope.row)" v-if="buttonShowList.back">退回</el-button>
                        <el-button type="text" @click="baojia(scope.row)" v-if="buttonShowList.price">报价</el-button>
                        <el-button type="text" @click="customerVisible(scope.row)" v-if="buttonShowList.setSee">设置可见客户</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'startTime'">
                        <span>{{ formatterTime(scope.row.startTime) }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'endTime'">
                        <span>{{ formatterTime(scope.row.endTime) }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'createBy'">
                        <span>{{parseDict(scope.row.createBy,'all_user_id')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="'新增航线'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="报价名称" :label-width="formLabelWidth" prop="priceName">
                            <el-input v-model="form.priceName" autocomplete="off" show-word-limit
                                      maxlength="100"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="船公司" :label-width="formLabelWidth" prop="shippingCompanyId">
                            <f-select
                                      v-model="form.shippingCompanyId"
                                      :isNeed="form.shippingCompanyId"
                                      :dict="'ship_company'"></f-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="起运港" :label-width="formLabelWidth" prop="startPortId">
                            <f-select v-model="form.startPortId" :isNeed="form.startPortId" :dict="'bd_port'"
                                      @changet="getSatrtPort">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="目的港" :label-width="formLabelWidth" prop="endPortId">
                            <f-select v-model="form.endPortId" :isNeed="form.endPortId" :dict="'bd_port'"
                                      @change="getfbacode" @changet="getEndPort" >
                            </f-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有效期" :label-width="formLabelWidth" prop="startTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.startTime"
                                    type="datetime"
                                    size="medium"
                                    :picker-options="editStartOptions"
                                    placeholder="开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    style="width:100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="有效结束时间" :label-width="formLabelWidth" prop="endTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.endTime"
                                    type="datetime"
                                    size="medium"
                                    placeholder="截止时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    :picker-options="editStopOptions"
                                    style="width:100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="直达/中转" :label-width="formLabelWidth" prop="arriveType">
                            <template>
                                <el-radio-group v-model="form.arriveType">
                                    <el-radio :label="'0'">直达</el-radio>
                                    <el-radio :label="'1'">中转</el-radio>
                                </el-radio-group>
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.arriveType =='1'">
                        <el-form-item label="中转港" :label-width="formLabelWidth">
                            <f-select v-model="form.transferPortId" :isNeed="form.transferPortId" :dict="'bd_port'"
                                      @changet="getTransferPort">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="航程/天" :label-width="formLabelWidth" prop="voyage">
                            <el-input v-model="form.voyage" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div style="font-size: 18px;padding: 10px 0">
                    增值服务
                </div>
                <el-button type="primary" @click="addGp" size="small" round style="margin-bottom: 10px"
                >添加柜子
                </el-button
                >
                <el-form :model="form" ref="gpform" :rules="rules">
                    <el-row v-for="(item, index) in form.gplist" :key="index">
                        <el-col :span="7">
                            <el-form-item label="箱型" :label-width="formLabelWidth"
                                          :prop="'gplist.'+index+'.gp'"
                                          :rules="rules.gp">
                                <el-select
                                        clearable
                                        filterable
                                        placeholder="获取柜型基础数据"
                                        style="width: 100%"
                                        v-model="item.gp"
                                        @change="updateBoxlist($event,index)"
                                >
                                    <el-option
                                            v-for="(j, i) in item.boxlist"
                                            :label="j.label"
                                            :value="j.label"
                                            :key="i"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="数量" :label-width="formLabelWidth"
                                          :prop="'gplist.'+index+'.number'"
                                          :rules="rules.number">
                                <el-input
                                        v-model="item.number"
                                        type="number"
                                        placeholder="数量，正整数"
                                        @change="updateGp"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="价格" :label-width="formLabelWidth"
                                          :prop="'gplist.'+index+'.price'"

                                          :rules="rules.price">
                                <el-input v-model="item.price" placeholder="价格" @change="caculateTotal"
                                          type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-button
                                    style="margin: 4px 10px"
                                    type="primary"
                                    size="small"
                                    @click="deleteItem(item, index)"
                            >删除
                            </el-button
                            >
                        </el-col>
                    </el-row>


                </el-form>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="isFba" label="是否FBA" :label-width="formLabelWidth">
                            <template>
                                <el-radio-group v-model="form.isFba">
                                    <el-radio :label="'1'">是</el-radio>
                                    <el-radio :label="'0'">否</el-radio>
                                </el-radio-group>
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-show="form.isFba =='1'">
                        <el-form-item label="FBA CODE" :label-width="formLabelWidth"
                                      prop="fbaCode" :rules="form.isFba =='1'?rules.fbaCode:[]">
                            <el-select
                                    clearable
                                    filterable
                                    v-model="form.fbaCode"
                                    placeholder="根据目的地获取FBA编码基础数据"
                            >
                                <el-option
                                        v-for="(item, index) in fbaCodeList"
                                        :label="item.label"
                                        :value="item.value"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item
                        v-for="(item, index) in priceFeeTemplate"
                        :label="item.templateCode"
                        :label-width="formLabelWidth"
                        :key="index"
                >
                    <template v-if="item.templateCode.indexOf('拆柜') === -1">
                        <el-radio-group
                                v-model="isNeedList[index].isNeed"
                                @change="selectPrice(index, isNeedList[index].isNeed)"
                                :key="index"
                        >
                            <el-radio :label="'1'">是</el-radio>
                            <el-radio :label="'0'">否</el-radio>
                        </el-radio-group>
                    </template>
                    <template v-else>
                        <el-row>
                            <el-col :span="4">
                                <el-radio-group
                                        v-model="isNeedList[index].isNeed"
                                        @change="selectPrice(index, isNeedList[index].isNeed)"
                                        :key="index"
                                >
                                    <el-radio :label="'1'">是</el-radio>
                                    <el-radio :label="'0'">否</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="11"  v-if="isNeedList[index].isNeed ==='1'">
                                <el-form-item label="拆柜仓库" :label-width="formLabelWidth" prop="warehouse">
                                    <el-select
                                            clearable
                                            filterable
                                            v-model="form.warehouse"
                                            placeholder="根据目的地获取FBA编码基础数据"
                                            style="width:100%"
                                    >
                                        <el-option
                                                v-for="item in warehouse"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="9" v-if="isNeedList[index].isNeed ==='1'">
                                <el-form-item label="托盘数量" :label-width="formLabelWidth" prop="tray">
                                    <el-input v-model="form.tray" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="客户名" :label-width="formLabelWidth">
                            <el-select
                                    clearable
                                    filterable
                                    v-model="form.customerName"
                                    placeholder="获取当前业务员的客户数据"
                                    style="width: 100%"
                                    @click.native="initCusList"
                            >
                                <el-option
                                        v-for="(item, index) in cusList"
                                        :label="item.label"
                                        :value="item.value"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报价类型" :label-width="formLabelWidth">
                            <el-select
                                    clearable
                                    filterable
                                    v-model="form.priceExplainName"
                                    placeholder="获取所有报价说明类型"
                                    style="width: 100%"
                                    @change="getcontent"
                                    @click.native="initpriceExplainList"
                            >
                                <el-option
                                        v-for="(item, index) in priceExplainList"
                                        :label="item.label"
                                        :value="item.value"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="报价说明" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.priceExplainContent"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-button type="primary" @click="AddListRow" size="small" round
                >添加费用
                </el-button
                >
                <el-form :model="form" ref="feeform" :rules="rules">
                    <el-table
                            ref="table"
                            :data="form.selectPriceList"
                            :label-width="formLabelWidth"
                            :key="itemKey"
                            class="table_input"
                    >
                    <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button
                                        size="small"
                                        type="danger"
                                        @click="handleDelete(scope.$index)"
                                >删除
                                </el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="费用名称" width="250">
                            <!-- 标头需要加必填标识 -->
                            <template slot="header">
                              <template>
                                  <span class="is_require_form">费用名称</span>
                              </template>
                            </template>
                            <template slot-scope="scope">
                                <el-form-item
                                        :prop="'selectPriceList.'+scope.$index +'.feeId'"
                                        :rules="rules.feeId"
                                >


                                    <el-select
                                            clearable
                                            filterable
                                            v-model="scope.row.feeId"
                                            placeholder="获取费用类别基础数据"
                                            @click.native="initBdFeeTypeList"
                                    >
                                        <el-option
                                                v-for="(item, index) in feeTypeList"
                                                :label="item.label"
                                                :value="item.value"
                                                :key="index"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="20GP"
                                width="120"
                                v-if="colData[0].isShow"
                                key="1"
                        >
                            <template slot-scope="scope">
                                <el-form-item
                                        :prop="'selectPriceList.'+scope.$index +'.twoGp'"
                                        :rules="rules.shuliange"
                                >
                                    <el-input v-model="scope.row.twoGp" type="number" disabled
                                              @change="caculateTotal"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="40GP"
                                width="120"
                                v-if="colData[1].isShow"
                                key="2"
                        >
                            <template slot-scope="scope">
                                <el-form-item :prop="'selectPriceList.'+scope.$index +'.fourGp'"
                                              :rules="rules.shuliange">
                                    <el-input v-model="scope.row.fourGp" type="number" disabled
                                              @change="caculateTotal"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="40HQ"
                                width="120"
                                v-if="colData[2].isShow"
                                key="3"
                        >
                            <template slot-scope="scope">
                                <el-form-item :prop="'selectPriceList.'+scope.$index +'.fourHq'"
                                              :rules="rules.shuliange">
                                    <el-input v-model="scope.row.fourHq" type="number" disabled
                                              @change="caculateTotal"></el-input>

                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="45HQ"
                                width="120"
                                v-if="colData[3].isShow"
                                key="4"
                        >
                            <template slot-scope="scope">
                                <el-form-item :prop="'selectPriceList.'+scope.$index +'.four5hq'"
                                              :rules="rules.shuliange">
                                    <el-input v-model="scope.row.four5hq" type="number" disabled
                                              @change="caculateTotal"></el-input>

                                </el-form-item>
                            </template>
                        </el-table-column>

                        <el-table-column label="价格" width="120">
                            <template slot-scope="scope">
                                <el-form-item :prop="'selectPriceList.'+scope.$index +'.price'"
                                              :rules="rules.price">
                                    <el-input v-model="scope.row.price" type="number"
                                              @change="caculateTotal"></el-input>

                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量" width="120">
                            <template slot-scope="scope">
                            <el-form-item>
                                {{getAllCount(scope.row)}}
                                <el-input v-model="scope.row.count" type="number" disabled></el-input>
                            </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="币种" width="200">
                            <!-- 标头需要加必填标识 -->
                            <template slot="header">
                              <template>
                                  <span class="is_require_form">币种</span>
                              </template>
                            </template>
                            <template slot-scope="scope">
                                <el-form-item :prop="'selectPriceList.'+scope.$index +'.cur'"
                                              :rules="rules.cur">
                                    <f-select
                                            clearable
                                            filterable
                                            v-model="scope.row.cur"
                                            :isNeed="scope.row.cur"
                                            placeholder="获取币种基础数据"
                                            :dict="'bd_cur'"

                                    >

                                    </f-select>
                                </el-form-item>

                            </template>
                        </el-table-column>

                    </el-table>
                </el-form>
            </el-form>
            <div style="margin-top: 10px">
                总计：
                <span>海运费:{{totalUsd.toFixed(3)}}</span>&nbsp;&nbsp;
                <span>杂费:{{totalOther.toFixed(3)}}</span>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>
        <customer-visible-dialog ref="customerDialog" :rowData="customerVisibleData"></customer-visible-dialog>
    </div>
</template>

<script>
    import {getAction, getBdFeeTypeList, getPriceExplainList, postAction} from "@/api/manage";
    import QueryMixins from "../../mixins/QueryMixins";
    import Tempcclate from "../isystem/Dict";
    import {validateCmb, validateCtn, validateShuliang} from "../../utils/validator";
    import CustomerVisibleDialog from './modules/customerVisibleDialog.vue';

    export default {
        name: "Potential",
        components: {Tempcclate,CustomerVisibleDialog,},
        mixins: [QueryMixins],
        data() {

            return {
                dictCodes: ['all_user_id'],
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.endTime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.endTime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.startTime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                //校验
                rules: {
                    feeId: [
                        {required: true, message: '请选择费用名称', trigger: 'change'},
                    ],
                    cur: [
                        {required: true, message: '请选择币种', trigger: 'change'},
                    ],
                    priceName: [
                        {required: true, message: '请输入报价名称', trigger: 'change'},
                    ],
                    shippingCompanyId: [
                        {required: true, message: '请选择船公司', trigger: 'change'},
                    ],
                    startPortId: [
                        {required: true, message: '请选择起运港', trigger: 'change'},
                    ],
                    endPortId: [
                        {required: true, message: '请选择目的港', trigger: 'change'},
                    ],
                    startTime: [
                        {required: true, message: '请选择有效开始时间', trigger: 'change'},
                    ],
                    endTime: [
                        {required: true, message: '请选择有效结束时间', trigger: 'change'},
                    ],
                    arriveType: [
                        {required: true, message: '请选择直达中转', trigger: 'change'},
                    ],
                    voyage: [
                        {required: true, message: '请输入航程', trigger: 'change'},
                        {validator: validateCtn, trigger: 'change'},
                    ],
                    gp: [
                        {required: true, message: '请选择柜型', trigger: 'change'},
                    ],
                    number: [

                        {validator: validateCtn, trigger: 'change'},
                    ],
                    shuliange: [

                        {validator: validateShuliang, trigger: 'change'},
                    ],
                    price: [

                        {validator: validateCmb, trigger: 'change'},
                    ],
                    isFba: [
                        {required: true, message: '请选择是否FBA', trigger: 'change'}
                    ],
                    fbaCode: [
                        {required: true, message: '请选择FBACode', trigger: 'change'}
                    ],
                    tray: [
                        {required: true, message: '请输入托盘数量', trigger: 'change'},
                        {validator: validateShuliang, trigger: 'change'},
                    ],
                    warehouse: [
                        {required: true, message: '请选择拆柜仓库', trigger: 'change'}
                    ],
                },
                colData: [
                    {isShow: false},
                    {isShow: false},
                    {isShow: false},
                    {isShow: false},
                ],
                itemKey: Math.random(),
                //
                boxlist: [
                    {value: "1", label: "20GP"},
                    {value: "2", label: "40GP"},
                    {value: "3", label: "40HQ"},
                    {value: "4", label: "45HQ"},
                ],
                warehouse: [
                    {value: "1", label: "深圳仓"},
                    {value: "2", label: "宁波仓"},
                    {value: "3", label: "上海仓"},
                    {value: "4", label: "厦门仓"},
                ],
                //遍历显示的初始化费用集合
                span: 8,

                pricelist: [],
                //selectPricelist
                selectPriceList: [],
                feeTemplateList: [],
                priceFeeTemplate: [],
                isNeedList: [],
                //控制弹框显示
                dialogFormVisible: false,
                dialogPrice: false,
                emailList: [],
                ids: [],
                row: {},
                form: {
                    gplist: [],
                    gpPrice: 0,
                    priceExplainContent: "",
                    selectPriceList: [],
                },
                totalUsd: 0,
                totalOther: 0,
                formLabelWidth: "120px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 100,
                    },
                    {
                        prop: "shippingCompany",
                        label: "船公司",
                        width: 120,
                    },
                    {
                        prop: "startPort",
                        label: "起运港",
                        width: 130,
                    },
                    {
                        prop: "endPort",
                        label: "目的港",
                        width: 110,
                    },
                    {
                        prop: "transferPort",
                        label: "中转港",
                        width: 110,
                    },
                    {
                        prop: "voyage",
                        label: "航程(天)",
                        sortable: "custom",
                        width: 120,
                    },
                    {
                        prop: "twentyGp",
                        label: "20GP",
                        sortable: "custom",
                        width: 100,
                    },
                    {
                        prop: "fortyGp",
                        label: "40GP",
                        sortable: "custom",
                        width: 100,
                    },
                    {
                        prop: "fortyHq",
                        label: "40HQ",
                        sortable: "custom",
                        width: 100,
                    },
                    {
                        prop: "fortyFiveHq",
                        label: "45HQ",
                        sortable: "custom",
                        width: 100,
                    },
                    {
                        prop: "startTime",
                        label: "生效日期",
                        sortable: "custom",
                        width: 120,
                    },
                    {
                        prop: "endTime",
                        label: "截止日期",
                        sortable: "custom",
                        width: 120,
                    },
                    {
                        prop: "remark",
                        label: "备注提醒",
                        width: 150,
                    },
                    {
                        prop: "createBy",
                        label: "发布人",
                        width: 150,
                    },
                ],
                url: {
                    list: "/sys/salePrice/salePicePage",
                    backOff: "/sys/salePrice/backOffSale",
                    getPriceTemp: "/sys/feeTemplate/getPriceTemp", //获取所有报价费用模板
                    baojia: "/sys/priceRecords/addPrice", //报价
                    editPrice: "/sys/priceRecords/editPrice", //重新报价
                    getCountryNum: "/sys/bdPort/getCountryNum", //根据港口中文名获取fbacode
                    getCustomerByLogin: "/customer/getCustomerByLogin", //获取当前客户
                },
                bjFlag: false,
                obj1: {},
                obj2: {},
                obj3: {},
                obj4: {},
                fbaCodeList: [],
                cusList: [],
                customerVisibleData: {},  //设置可见客户的数据传递
            };
        },
        methods: {
            getSatrtPort(val) {
                this.form.startPort = val;
            },
            getEndPort(val) {
                this.form.endPort = val;
            },
            getTransferPort(val) {
                this.form.transferPort = val;
            },
            initCusList() {
                this.cusList = [];
                getAction(this.url.getCustomerByLogin)
                    .then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].username;
                                obj.label = res.result[i].username;
                                this.cusList.push(obj);
                            }
                        } else {
                            this.$message.warning('获取fbacode列表失败')
                        }
                    })
            },
            getfbacode(val) {
                this.fbaCodeList = [];
                if (val) {
                    getAction(this.url.getCountryNum + '?id=' + val)
                        .then(res => {
                            if (res.success) {
                                for (var i = 0; i < res.result.length; i++) {
                                    var obj = {};
                                    obj.value = res.result[i].code;
                                    obj.label = res.result[i].code;
                                    this.fbaCodeList.push(obj);
                                }
                            } else {
                                this.$message.warning('获取fbacode列表失败')
                            }
                        }).catch(err => {
                        this.$message.error("无法根据目的港找到国家，请更换目的港")
                    })
                }


            },
            initBdFeeTypeList() {
                if (this.feeTypeList.length <= 0) {
                    this.feeTypeList = [];
                    getBdFeeTypeList('1').then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id;
                                obj.label = res.result[i].chineseName;
                                this.feeTypeList.push(obj);
                            }
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }

            },

            initpriceExplainList() {
                this.priceExplainList = [];
                // this.priceExplainList = [];
                getPriceExplainList().then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].priceExplainName;
                            obj.label = res.result[i].priceExplainName;
                            obj.content = res.result[i].priceExplainContent;
                            this.priceExplainList.push(obj);
                        }
                    }
                }).catch(err => {
                    this.$message.error(err)
                })
            },
            //日期隐藏时分秒显示
            formatterTime(data) {
                return data.substring(0, 10);
            },
            getcontent(val) {
                //根据当前选中的纸便利集合 给内容赋值
                for (var i = 0; i < this.priceExplainList.length; i++) {
                    if (val === this.priceExplainList[i].value) {
                        this.$set(
                            this.form,
                            "priceExplainContent",
                            this.priceExplainList[i].content
                        );
                    }
                }
            },
            //todo 选中的值发生变化，动态改变下拉框集合
            updateBoxlist(val, index) {
                this.$set(this.form.gplist[index], 'gp', val)
                //获取gplist已选择的所有柜型
                let strlist = this.form.gplist.map(i => {
                    return i.gp
                }).join(',');
                //遍历集合
                for (var i = 0; i < this.form.gplist.length; i++) {
                    let oldboxlist = this.boxlist;
                    let list = [];
                    for (var j = 0; j < oldboxlist.length; j++) {
                        if (strlist.indexOf(oldboxlist[j].label) == -1) {
                            list.push(oldboxlist[j]);
                        }


                    }
                    if (index == i) {
                        if (val) {
                            list.push({label: val})
                        }

                    }
                    this.form.gplist[i].boxlist = list;
                }
                //重新计算
                // 一次
                this.caculateTotal();
                //重新
                this.updateGp();
                this.$forceUpdate();
            },
            //计算总计
            caculateTotal() {
                //计算选择了柜型的价格*数量
                this.totalUsd = 0;
                for (var i = 0; i < this.form.gplist.length; i++) {
                    let numVar = this.form.gplist[i].number;
                    let priceVar = this.form.gplist[i].price;
                    let num = numVar ? parseFloat(numVar).toFixed(0) : 0;
                    let price = priceVar ? parseFloat(priceVar).toFixed(3) : 0;
                    if (this.form.gplist[i].gp) {
                        this.totalUsd += num * price;
                    }

                }
                this.totalOther = 0;
                //计算选择了的费用的总
                for (var i = 0; i < this.form.selectPriceList.length; i++) {
                    let numVar = this.form.selectPriceList[i].count;
                    let priceVar = this.form.selectPriceList[i].price;
                    let num = numVar ? parseFloat(numVar).toFixed(0) : 0;
                    let price = priceVar ? parseFloat(priceVar).toFixed(3) : 0;
                    //未选择柜型不参与计算

                    this.totalOther += num * price;
                }
                this.$forceUpdate();
            },
            //将集合转为map
            listToMap(list, name) {
                var map = {};
                for (var index in list) {
                    map[list[index][name]] = list[index];
                }
                return map;
            },
            //计算每一行的数量
            getAllCount(item) {
                //获取四个柜子数据
                let twoGpStr = item.twoGp;
                let fourGpStr = item.fourGp;
                let fourHqStr = item.fourHq;
                let four5HqStr = item.four5hq;
                let twoGp = twoGpStr ? parseFloat(twoGpStr).toFixed(0) : 0;
                let fourGp = fourGpStr ? parseFloat(fourGpStr).toFixed(0) : 0;
                let fourHq = fourHqStr ? parseFloat(fourHqStr).toFixed(0) : 0;
                let four5Hq = four5HqStr ? parseFloat(four5HqStr).toFixed(0) : 0;
                this.$set(item, 'count', Number(twoGp) + Number(fourGp) + Number(fourHq) + Number(four5Hq))
            },
            //柜型数量发生变化 动态更新费用列表中的数据
            updateTable() {
                //获取当前已选中的所有柜型j的集合
                let strlist = this.form.gplist.map(g => g.gp).join(',');
                let gpmap = this.listToMap(this.form.gplist, 'gp');
                //只要柜子有值并且数量大于0

                //判断是哪一种柜型，给对应的柜型回显
                if (strlist.indexOf("20GP") !== -1) {
                    this.colData[0].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'twoGp', gpmap['20GP'].number)

                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'twoGp', gpmap['20GP'].number)

                    }
                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'twoGp', '');

                    }
                    this.colData[0].isShow = false;
                }
                if (strlist.indexOf("40GP") !== -1) {
                    this.colData[1].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.fourGp = gpmap['40GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourGp', gpmap['40GP'].number)
                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourGp = gpmap['40GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourGp', gpmap['40GP'].number)
                    }

                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourGp', '');

                    }
                    this.colData[1].isShow = false;
                }
                if (strlist.indexOf("40HQ") !== -1) {
                    this.colData[2].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourHq', gpmap['40HQ'].number)

                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourHq', gpmap['40HQ'].number)

                    }

                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourHq', '')

                    }
                    this.colData[2].isShow = false;
                }
                if (strlist.indexOf("45HQ") !== -1) {
                    this.colData[3].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.four5hq = gpmap['45HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'four5hq', gpmap['45HQ'].number)
                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'four5hq', gpmap['45HQ'].number)

                    }

                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'four5hq', '');

                    }
                    this.colData[3].isShow = false;
                }

                this.$nextTick(() => {
                    this.caculateTotal();
                })

            },
            updateGp() {
                this.updateTable();

                //重新计算
                this.caculateTotal();


                this.$forceUpdate();

            },
            addGp() {
                if (this.form.gplist && this.form.gplist.length >= this.boxlist.length) {
                    this.$message.warning("最多添加四种柜型呢亲");
                    return false;
                }
                //判断当前已经选中的费用中 那些柜型已选中
                var list = [];
                let strlist = this.form.gplist.map(i => i.gp).join(',');
                for (var i = 0; i < this.boxlist.length; i++) {
                    if (strlist.indexOf(this.boxlist[i]["label"]) === -1) {
                        list.push(this.boxlist[i]);
                    }
                }
                this.obj1 = {
                    gp: "",
                    number: 0,
                    boxlist: list,
                    price: 0,
                };
                this.form.gplist.push(this.obj1);
                this.$forceUpdate();
            },
            AddListRow() {
                this.form.selectPriceList.push({
                    feeId: "",
                    twoGp: '',
                    fourGp: '',
                    fourHq: '',
                    four5hq: '',
                    price: '',
                    count: '',
                    cur: '',

                });
                this.updateTable();
            },
            deleteItem(item, index) {
                let label = this.form.gplist[index].gp;
                for (var i = 0; i < this.form.gplist.length; i++) {
                    let box = this.form.gplist[i].boxlist;
                    if (label) {
                        box.push({label: label});
                    }
                    //刷新列表


                }

                this.form.gplist.splice(index, 1);
                this.updateTable();

                this.$forceUpdate();
            },
            //控制价格的显示和隐藏
            selectPrice(index, val) {
                //获取选中的集合
                let obj = this.isNeedList[index];
                //获取模板名称
                let name = obj.templateCode;

                //选择了是 则将对应模板的内容显示
                if (val === "1") {
                    for (var i = 0; i < this.pricelist.length; i++) {
                        if (name === this.pricelist[i].templateCode) {
                            //模板名称相同,则添加到对应的集合
                            this.form.selectPriceList.push(this.pricelist[i]);
                        }
                    }
                } else {
                    //清空
                    //模板名称相同,则删除对应的集合
                    for (var k = 0; k < this.form.selectPriceList.length; k++) {
                        if (name === this.form.selectPriceList[k].templateCode) {
                            this.form.selectPriceList.splice(k, 1);
                        }
                    }
                }

                this.updateTable();
            },
            //新增航线 点击确定
            handleSave() {
                //获取 柜型的数量 和价格 封装进对应的form对象里面
                //将list转为map
                let gpmap = this.listToMap(this.form.gplist, 'gp')
                //遍历gplist集合
                //传 柜子列表 以及是否费用的id 用,连接
                let cabientList = [];
                this.form.gplist.map(g => {
                    let obj = {cabinetType: g.gp, quantity: g.number, price: g.price};
                    cabientList.push(obj);
                });
                this.form.cabientList = cabientList;
                //判断选择的费用
                let selectFeeList = '';
                let selectFeeListAll = [];
                for (var i = 0; i < this.priceFeeTemplate.length; i++) {
                    if (this.isNeedList[i].isNeed == '1') {
                        selectFeeListAll.push(this.priceFeeTemplate[i].id);
                    }
                }
                this.form.selectFeeList = selectFeeListAll.join(',');
                this.form.totalFee = 'USD:' + this.totalUsd + ",CNY:" + this.totalOther;

                if (this.form.selectPriceList) {
                    let list = [];
                    this.form.selectPriceList.forEach(f => {
                        let {count, cur, feeId, price} = f;
                        let obj = {count, cur, feeId, price};
                        list.push(obj);
                    });
                    this.$set(this.form, 'feeTemplateList', list);

                }
                //打印一下封装的数据
                //校验
                let resultArr = [];
                let formArr = [];
                formArr.push('form', 'gpform', 'feeform')
                formArr.forEach(item => {
                    resultArr.push(this.checkForm(item))
                })
                let _self = this;
                Promise.all(resultArr).then(function () {
                    let url = _self.url.baojia;

                    postAction(url, _self.form).then((res) => {
                        if (res.success) {
                            _self.$message.success(res.message);
                            //关闭弹窗
                            _self.dialogFormVisible = false;
                            _self.handleQuery();
                        }
                    });


                })


            },
            //多个表单校验
            async checkForm(formName) {
                var _self = this;
                var result = await new Promise(function (resolve, reject) {
                    _self.$refs[formName].validate((valid) => {
                        if (valid) {
                            resolve();
                        } else {
                            reject()
                        }
                    })
                })
                return result;
            },
            init() {
                this.queryForm.saleType = "3";
                //初始化港口基础数据
                this.queryForm.initBdPort = true;
                //初始化报价说明类型
                this.queryForm.initPriceExplain = true;
                //给gplist赋值
                this.form.gplist.push({
                    gp: "",
                    number: 0,
                    boxlist: this.boxlist,
                    price: 0,
                });
                for (var i = 0; i < this.isNeedList.length; i++) {
                    this.selectPrice(i, this.isNeedList[i].isNeed);
                }
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.saleType = "3";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            //退回
            backOff(row) {
                this.$confirm(`确定退回`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        postAction(this.url.backOff + "?id=" + row.id+'&userId='+row.createBy).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            initPriceTemplate() {
                getAction(this.url.getPriceTemp)
                    .then((res) => {
                        if (res.success) {
                            this.isNeedList = [];
                            this.priceFeeTemplate = res.result;
                            for (var i = 0; i < res.result.length; i++) {
                                let templateCode = res.result[i].templateCode;
                                let isNeed = "0";
                                this.isNeedList.push({
                                    templateCode: templateCode,
                                    isNeed: isNeed,
                                });
                                //获取每一层下面的费用集合
                                let list = res.result[i].feeTemplateList;

                                if (list) {
                                    //count: null
                                    // createTime: "2021-05-20 00:41:02"
                                    // cur: ""
                                    // datalevel: "0"
                                    // feeName: "FDA HOLD entry review"
                                    // id: "1395056915065131010"
                                    // price: null
                                    // supId: ""
                                    // templateId: "6fce13a41b114d43bf1c0a39f763c76d"
                                    for (var k = 0; k < list.length; k++) {
                                        let {feeId, price, count, cur, createTime, id, supId, templateId} = list[k];
                                        this.pricelist.push({
                                            feeId,
                                            price,
                                            count,
                                            cur,
                                            templateCode: templateCode,
                                            templateId: templateId,
                                            supId: supId,
                                            id: id,
                                            createTime: createTime,
                                            twoGp: '',
                                            fourGp: '',
                                            fourHq: '',
                                            four5hq: '',
                                        });
                                    }
                                }
                            }
                            this.caculateTotal();
                        }
                    })
            },
            // 设置可见客户
            customerVisible(row) {
              this.customerVisibleData = row;
              this.$refs.customerDialog.visible = true;
            },

            baojia(row) {
                //标识报价还是新增航线
                this.dialogFormVisible = true;
                this.bjFlag = true;
                this.form = JSON.parse(JSON.stringify(row));
                this.form.gplist = [];
                this.form.selectPriceList = [];
                this.pricelist = []
                this.form.supOcePriceId = row.id;
                //给柜子赋值

                if (row.twentyGp) {
                    this.obj1 = {
                        gp: '20GP', number: row.twentyGpCount ? row.twentyGpCount : 0, price: row.twentyGp
                            ? row.twentyGp : 0, boxlist: []
                    };
                    this.form.gplist.splice(0, 1, this.obj1);
                }
                if (row.fortyGp) {
                    this.obj2 = {
                        gp: '40GP',
                        number: row.fortyGpCount ? row.fortyGpCount : 0,
                        price: row.fortyGp ? row.fortyGp : 0,
                        boxlist: []
                    };
                    this.form.gplist.splice(1, 1, this.obj2);
                }
                if (row.fortyHq) {
                    this.obj3 = {
                        gp: '40HQ',
                        number: row.fortyHqCount ? row.fortyHqCount : 0,
                        price: row.fortyHq ? row.fortyHq : 0,
                        boxlist: []
                    }
                    this.form.gplist.splice(2, 1, this.obj3);
                }
                if (row.fortyFiveHq) {
                    this.obj4 = {
                        gp: '45HQ',
                        number: row.fortyFiveHqCount ? row.fortyFiveHqCount : 0,
                        price: row.fortyFiveHq ? row.fortyFiveHq : 0,
                        boxlist: []
                    }
                    this.form.gplist.splice(3, 1, this.obj4);
                }

                this.initPriceTemplate();
                //计算费用
                this.caculateTotal();
                this.getfbacode(this.form.endPortId);

            },
            //新增航线
            handleAddLine() {
                //清空
                this.bjFlag = false;
                this.form = {
                    gplist: [],
                    gpPrice: 0,
                    priceExplainContent: "",
                    selectPriceList: [],
                };
                this.pricelist = []
                this.init();
                this.initPriceTemplate();
                this.dialogFormVisible = true;
            },
            handleDelete(index) {
                this.form.selectPriceList.splice(index, 1);
            },
        },
        created() {
            this.init();
            this.caculateTotal()
        },
      computed:{
          buttonShowList() {
            return {
              'addLine':this.$btnIsShow('channel/OceanShippingReleaseSale','0','新增航线'),
              'back':this.$btnIsShow('channel/OceanShippingReleaseSale','1','退回'),
              'price':this.$btnIsShow('channel/OceanShippingReleaseSale','1','报价'),
              'setSee':this.$btnIsShow('channel/OceanShippingReleaseSale','1','设置可见客户'),
            }
          }
      }
    };
</script>
<style>
.el-dialog__body {
    padding: 20px 30px 20px 30px !important;
}
</style>
<style lang="scss" scoped>
</style>
