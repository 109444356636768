<template>
    <el-dialog
            center
            width="30%"
            :close-on-click-modal="false"
            :before-close="handleClose"
            :visible.sync="visible"
            :title="row.id ? '编辑人员绑定' : '新增人员绑定'"
    >
        <div class="content-wrap c-slim-scrollbar">
            <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
                <el-row>
<!--                    <el-col :span="24">-->
<!--                        <el-form-item prop="salesId" label="业务员" :rules="rules.salesmanId">-->
<!--                            <f-select-->
<!--                                    v-model="form.salesId"-->

<!--                                    :isNeed="form.salesId"-->
<!--                                       :dict="'salesmanId'">-->
<!--                            </f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="24">-->
<!--                        <el-form-item prop="oceanSalesmanId" label="海外业务员" :rules="rules.salesmanId">-->
<!--                            <f-select-->
<!--                                    v-model="form.oceanSalesmanId"-->

<!--                                    :isNeed="form.oceanSalesmanId"-->
<!--                                    :dict="'ocean_salesman_id'">-->
<!--                            </f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item prop="docId" label="客服">
                            <f-select
                                    v-model="form.docId"

                                    :isNeed="form.docId"
                                      :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="billerId" label="收款人">
                            <f-select
                                    v-model="form.billerId"

                                    :isNeed="form.billerId"
                                       :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
        </div>
        <div class="drawer_footer">
            <el-button plain size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {httpAction} from '@/api/manage'


    const form = {
        id: '',
        sales: '',
        doc: '',
        biller: '',
        salesId:'',
        docId:'',
        billerId:'',
    }

    export default {
        name: 'AddBindingDrawer',
        components: {},
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            },
            bOptions: {
                type: Array,
                default: () => []
            },
        },
        data() {
            const salesmanValid = (rule, value, callback) => {
                if (!this.form.salesId && !this.form.oceanSalesmanId){
                    callback(new Error('请选择业务员或者海外业务员'))
                }else {
                    callback();
                }
            }
            return {

                roleOptions: [],
                form: {...form},
                rules: {
                    salesmanId: [
                        { validator: salesmanValid, trigger: 'change' },
                    ],
                    salesId: [
                        {required: true, message: '请选择业务员', trigger: 'change'},
                    ],
                    docId: [
                        {required: true, message: '请选择客服', trigger: 'change'},
                    ],
                    billerId: [
                        {required: true, message: '请选择账单员', trigger: 'change'},
                    ],
                },
                url: {
                    add: '/sys/sysWorkBinding/addBinding',
                    edit: '/sys/sysWorkBinding/editBinding'
                },
                options: this.bOptions,
            }
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    for (var name in this.row){
                        this.$set(this.form,name,this.row[name])
                    }
                } else {
                    this.form = {}
                }
            }
        },
        mounted() {

        },
        methods: {
            handleSubmit() {
                let url = this.url.add
                let method = 'POST'
                if (this.row.id) {
                    url = this.url.edit
                    method = 'PUT'
                    this.form.id = this.row.id
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        const params = {
                            ...this.form,
                        }
                        httpAction(url, method, params).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                })
            },
            handleClose() {
                this.$refs.form.resetFields()
                this.$emit('update:visible', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
.drawer_footer{
    text-align: right;
}
</style>
