<!--潜在合作-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="运输方式">
                            <!--                        <el-input v-model="queryForm.transportStatus"></el-input>-->
                            <f-select
                                class="f-full-width"
                                :dict="'transportStatus'"
                                v-model="queryForm.transportStatus"
                                :is-need="queryForm.transportStatus"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="航线名称">
                            <f-select
                                v-model="queryForm.solicitation"
                                :is-need="queryForm.solicitation"
                                dict="fba_solicitation"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="FBA CODE">
                            <f-select
                                v-model="queryForm.fbaCode"
                                :is-need="queryForm.fbaCode"
                                dict="fba_code_list"
                            ></f-select>
                        </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作员">
                            <!--                        <el-select v-model="queryForm.operateId" placeholder="请选择">-->
                            <!--                            <el-option-->
                            <!--                                    v-for="item in optionssupplierType"-->
                            <!--                                    :key="item.value"-->
                            <!--                                    :label="item.label"-->
                            <!--                                    :value="item.value">-->
                            <!--                            </el-option>-->
                            <!--                        </el-select>-->
                            <f-select
                                placeholder="请选择操作员"
                                v-model="queryForm.operateId"
                                :is-need="queryForm.operateId"
                                dict="operationsId"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="入仓">
                            <el-select
                                class="full_width"
                                v-model="queryForm.cargoArrivedType"
                                placeholder="请选择"
                            >
                                <el-option value="1" label="未入仓"> 未入仓</el-option>
                                <el-option value="2" label="已入仓"> 已入仓</el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="67vh"
                :isRecordTableScroll="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                        >推送地址
                        </el-button
                        >
                        <el-button type="text" @click="handleUpsOrder(scope.row)"
                        >拆单
                        </el-button
                        >
                        <el-button type="text" @click="handleRecovery(scope.row)"
                        >打单
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'solicitation'">
          <span>{{
                  parseDict(scope.row.solicitation, "fba_solicitation")
              }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
          <span>{{
                  parseDict(scope.row.transportStatus, "transportStatus")
              }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                            style="color: #00a0e9"
                            @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'username'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                           style="color:#57a3f3;">{{ scope.row.username }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <!--推送地址-->
        <ups-push-address ref="modalForm"></ups-push-address>

        <!--拆单-->
        <label-split ref="modalFormOrder"></label-split>

        <!--打单-->
        <check-and-place ref="aboutPrice" @ok="handleSearch"></check-and-place>
    </div>
</template>

<script>
import {
    deleteAction,
    getAction,
    getallusers,
    postAction,
    putAction,
} from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";
import upsPushAddress from "./modules/UpsPushAddress";
import LabelSplit from "./modules/LabelSplit";
import CheckAndPlace from "./modules/CheckAndPlace";
import {setUpRouteUtil} from '@/utils/util';

export default {
    name: "Potential",
    mixins: [QueryMixins],
    components: {
        upsPushAddress,
        LabelSplit,
        CheckAndPlace,
    },
    data() {
        return {
            dictCodes: ["transportStatus", "fba_solicitation"],
            ids: [],
            row: {},
            form: {},
            formLabelWidth: "100px",
            //控制弹框显示
            dialogFormVisible: false,
            dialogFormVisibleEmail: false,
            columns: [
                {
                    type: "selection",
                    width: 50,
                },

                {
                    prop: "orderNo",
                    label: "工作单号",
                    sortable: true,
                },
                {
                    prop: "username",
                    label: "客户",
                    sortable: true,
                },
                {
                    prop: "fbaCode",
                    label: "FBA CODE",
                },
                {
                    prop: "lb",
                    label: "重量（LBS）",
                    sortable: true,
                },
                {
                    prop: "ctn",
                    label: "件数（CTN）",
                    sortable: true,
                },
                {
                    prop: "solicitation",
                    label: "航线名称",
                },
                {
                    prop: "transportStatus",
                    label: "运输方式",
                },
                {
                    prop: "action",
                    label: "操作",
                },
            ],
            url: {
                list: "/interface/label/list",
                edit: "/interface/label/edit",
                getById: "/interface/label/queryById",
                delete: "/interface/label/delete",
                recovery: "/interface/label/recovery",
                sendEmail: "/interface/label/sendEmail",
                getPrintingStatus: "/interface/label/getPrintingStatus",
            },
            optionsSettlementType: [
                {
                    value: "0",
                    label: "票结",
                },
                {
                    value: "1",
                    label: "周结",
                },
                {
                    value: "2",
                    label: "月结",
                },
                {
                    value: "3",
                    label: "预付",
                },
            ],
            optionssupplierType: [
                {
                    value: "1",
                    label: "订舱代理",
                },
                {
                    value: "2",
                    label: "拖车代理",
                },
                {
                    value: "3",
                    label: "报关行",
                },
                {
                    value: "4",
                    label: "保险公司",
                },
                {
                    value: "5",
                    label: "清关代理",
                },
                {
                    value: "6",
                    label: "送货代理",
                },
                {
                    value: "7",
                    label: "船公司",
                },
                {
                    value: "8",
                    label: "拆柜代理",
                },
            ],
            emailList: [],
            userlist: [],
            OrderNo: null,
        };
    },
    methods: {

        intoDocpage(orderNo) {
            // 对于路由不在左侧菜单中的选中菜单处理
            setUpRouteUtil(this.$route.path, '/docpage/Edit');
            this.$router.push("/docpage/Edit?orderNo=" + orderNo);

        },
        handleSelectionChange(selection) {
            // 多选
            let arr = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
        },

        init() {
            this.queryForm.cooperationType = "4";
        },
        handleEdit(row) {
            this.OrderNo = row.orderNo;
            this.$refs.modalForm.edit(row);
            this.$refs.modalForm.title = "编辑";
            this.$refs.modalForm.disableSubmit = false;
        },
        //点击编辑，显示弹窗
        handleAdd() {
            this.dialogFormVisible = true;
        },
        handleUpsOrder: function (record) {
            this.OrderNo = record.orderNo;
            this.$refs.modalFormOrder.edit(record);
            this.$refs.modalFormOrder.title = "拆单";
            this.$refs.modalFormOrder.disableSubmit = false;
        },
        handleRecovery(record) {
            //判断是否正在打单
            getAction(this.url.getPrintingStatus, { orderNo: record.orderNo }).then((res) => {
                    if (res.success) {
                        this.OrderNo = record.orderNo;
                        this.$refs.aboutPrice.edit(record);
                        this.$refs.aboutPrice.title = "报价清单";
                        this.$refs.aboutPrice.disableSubmit = false;
                    } else {
                        this.$message.error(res.message);
                    }
                })
        },
        handleReset() {
            this.queryForm = {};
            this.init();
            this.ipagination.currentPage = 1;
            this.handleQuery();
        },
    },
    created() {
        this.init();
        this.queryForm.initUser = 1;
    },
};
</script>

<style lang="scss" scoped>
</style>
