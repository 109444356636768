<template>
    <el-dialog
            :title="title"
            width=1000
            :visible="visible"
            @ok="handleOk"
            @cancel="handleCancel"
            :before-close="handleCancel"
            :close-on-click-modal="false">
        <!--        <a-spin :spinning="confirmLoading">-->
        <el-form :form="form" ref="form">
            <!-- 主表单区域 -->
            <el-card :bordered="false">
                <el-table
                        rowKey="name"
                        bordered
                        :columns="columns"
                        :data="dataSource"
                        :pagination="false"
                        :cell-style="cellStyle"
                >
                    <el-table-column
                            prop="name"
                            label="发货账号"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="GFP"
                            label="GFP"
                    >
                        <template slot-scope="scope">
                            <div class="sub-body" style="cursor:pointer" @click="handleGFP(scope.row)"
                                 v-html="scope.row.GFP">{{ scope.row.GFP
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="GFPremark"
                            label="GFPremark"
                    >
                        <template slot-scope="scope">
                            <div class="sub-body" v-html="scope.row.GFPremark" style="white-space: pre-wrap;"></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="Ground"
                            label="Ground"
                    >
                        <template slot-scope="scope">
                            <div class="sub-body" style="cursor:pointer" @click="handleGround(scope.row)">{{
                                scope.row.Ground }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="Groundremark"
                            label="Groundremark"
                    >
                        <template slot-scope="scope">
                            <div class="sub-body" v-html="scope.row.Groundremark" style="white-space: pre-wrap;"></div>
                        </template>
                    </el-table-column>


                    <span slot="GFPremark" slot-scope="text">
              <a v-html="text" style="color: rgba(0, 0, 0, 0.65);"></a>
        </span>

                    <span slot="Groundremark" slot-scope="text">
              <a v-html="text" style="color: rgba(0, 0, 0, 0.65);"></a>
        </span>


                    <span slot="actionGFP" slot-scope="text, record">
               <a v-if="record.GFP==pricing" v-text="text" @click="handleGFP(record) "
                  style="text-align:center;color: #7ac23c;"></a>
               <a v-if="record.GFP!=pricing" v-text="text" @click="handleGFP(record) "></a>
        </span>
                    <!-- 插槽-->
                    <span slot="actionGround" slot-scope="text, record">
               <a v-if="record.Ground==pricing" v-text="text" @click="handleGround(record) "
                  style="text-align:center;color: #7ac23c;"></a>
               <a v-if="record.Ground!=pricing && (record.name=='CA-5RR'||record.name=='GN2'||record.name=='PL'||record.name=='PT'
               ||record.name=='G92' ||record.name=='SS-CWT' || record.name=='PL-V2') "
                  v-text="text" @click="handleGround(record) "></a>
               <a v-if="record.Ground!=pricing && record.name=='NY'" v-text="text" @click="handleGround(record) "
                  style="text-align:center;color: red;"></a>
        </span>

                </el-table>

                <!-- 打单区域 -->
                <checkPriceDetail ref="modalFormcheckPriceDetail" @ok="modalFormOk()"/>

            </el-card>
        </el-form>
        <!--        </a-spin>-->
    </el-dialog>
</template>
<script>

    // import pick from 'lodash.pick'
    import {checkAddress} from "../api/Ups";
    import {postAction} from '@/api/manage'
    import checkPriceDetail from "./checkPriceDetail";

    export default {
        name: 'CheckAndPlace',
        components: {
            checkPriceDetail
        },
        data() {
            return {
                title: '操作',
                visible: false,
                confirmLoading: false,
                form: {},
                columns: [
                    {
                        title: '发货账号',
                        dataIndex: 'name',
                        align: 'center'
                    },
                    {
                        title: 'UPS GFP',
                        dataIndex: 'GFP',
                        align: 'center',
                        scopedSlots: {customRender: 'actionGFP'}

                    },
                    {
                        title: '备注',
                        align: 'center',
                        width: "250",
                        dataIndex: 'GFPremark',
                        scopedSlots: {customRender: 'GFPremark'}
                    },
                    {
                        title: 'UPS Ground',
                        dataIndex: 'Ground',
                        align: 'center',
                        scopedSlots: {customRender: 'actionGround'}

                    },
                    {
                        title: '备注',
                        dataIndex: 'Groundremark',
                        align: 'center',
                        scopedSlots: {customRender: 'Groundremark'}
                    }
                ],
                dataSource: [
                    // { name: 'CA-5RR', point:'$ 97.34' , level: '' },
                    // { name: 'PL', point: '$ 97.34', level: '$ 97.34' },
                    // { name: 'NY', point: '', level:  '$ 97.34'},
                    // { name: 'GN2', point: '$ 97.34', level:  '$ 97.34'}
                ],
                orderNo: String,
                // pricingDetail:Object,
                pricingDetail: [],
                pricing: 1000000,  // 设置一个起始最大的值
                GN2GFP: String,
                GN2Groud: String,
                PLGFP: String,
                PLGroud: String,
                NYGFP: String,
                NYGroud: String,
                PL_V2GFP: String,
                PL_V2Groud: String,
                US5Ground: String,
                US3_GFP: String,
                CA5RRGFP: String,
                CA5RRGroud: String,
                G92GFP: String,
                SSCWTGroud: String,
                UPS_ORIGIN: String,
                JHDGroud: String,
                url: {
                    rating: '/interface/label/upsRating',
                    getGFP: '/interface/label/getGFP',
                    getFCCPrice: '/interface/fcc/getFCCPrice',
                    getPostMan: '/interface/label/getPostManRate',
                    getUpsOriginRate: '/interface/label/getUpsOriginRate',
                    getGFP2: '/interface/label/getGFP2',
                    getJhdPrice: '/interface/jhd/getJhdPrice',
                }
            }
        },
        mounted() {
        },
        created() {

        },
        methods: {
            intoDocpage(orderNo) {

                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            modalFormOk() {
                this.handleCancel()
                this.$emit('ok')
                // this.loadData();
            },
            handleCheckPriceDetail: function (record) {
                this.OrderNo = record.orderNo
                this.$refs.modalFormcheckPriceDetail.edit(record)
                this.$refs.modalFormcheckPriceDetail.title = '报价清单'
                this.$refs.modalFormcheckPriceDetail.disableSubmit = false
            },

            handleGround(record) {
                let that = this
                if (that.dataSource.length < 1) {

                    this.$confirm('查价数据还未加载完，是否继续查看当前选择的价格明细？', '提示', {
                        type: 'warning'
                    }).then(() => {
                        if (record.name == 'GN2') {
                            that.pricingDetail.pricingDetail = that.GN2Groud
                        } else if (record.name == 'NY') {
                            that.pricingDetail.pricingDetail = that.NYGroud
                        } else if (record.name == 'PL') {
                            that.pricingDetail.pricingDetail = that.PLGroud
                        } else if (record.name == 'PT') {
                            that.pricingDetail.pricingDetail = []
                        } else if (record.name == 'SS-CWT') {
                            that.pricingDetail.pricingDetail = that.SSCWTGroud
                        } else if (record.name == 'UPS-ORIGIN') {
                            that.pricingDetail = that.UPS_ORIGIN
                            that.pricingDetail.service_name = 'Ground Freight Pricing'
                        } else if (record.name == "US5") {
                            that.pricingDetail.pricingDetail = that.US5Ground
                        } else if (record.name == "US3_GFP") {
                            that.pricingDetail.pricingDetail = that.US3_GFP
                        } else if (record.name == "PL-V2") {
                            that.pricingDetail.pricingDetail = that.PL_V2Groud
                        }else if (record.name == "JHD") {
                            that.pricingDetail.pricingDetail = that.JHDGroud
                        }


                        that.pricingDetail.orderNo = that.orderNo
                        that.pricingDetail.name = record.name
                        that.pricingDetail.reType = '2'
                        that.pricingDetail.repriceType = '2'

                        that.handleCheckPriceDetail(that.pricingDetail)
                        that.$emit('close')
                    }).catch(() => {
                    });


                } else {
                    if (record.name == 'GN2') {
                        that.pricingDetail.pricingDetail = that.GN2Groud
                    } else if (record.name == 'NY') {
                        that.pricingDetail.pricingDetail = that.NYGroud
                    } else if (record.name == 'PL') {
                        that.pricingDetail.pricingDetail = that.PLGroud
                    } else if (record.name == 'PT') {
                        that.pricingDetail.pricingDetail = []
                    } else if (record.name == 'SS-CWT') {
                        that.pricingDetail.pricingDetail = that.SSCWTGroud
                    } else if (record.name == 'UPS-ORIGIN') {
                        that.pricingDetail = that.UPS_ORIGIN
                        that.pricingDetail.service_name = 'Ground Freight Pricing'
                    } else if (record.name == "PL-V2") {
                        that.pricingDetail.pricingDetail = that.PL_V2Groud
                    } else if (record.name == "US5") {
                        that.pricingDetail.pricingDetail = that.US5Ground
                    }else if (record.name == "JHD") {
                        that.pricingDetail.pricingDetail = that.JHDGroud
                    }


                    that.pricingDetail.orderNo = that.orderNo
                    that.pricingDetail.name = record.name
                    that.pricingDetail.reType = '2'
                    that.pricingDetail.repriceType = '2'

                    that.handleCheckPriceDetail(this.pricingDetail)
                    that.$emit('close')
                }


            },
            handleGFP(record) {
                let that = this
                if (that.dataSource.length < 1) {
                    this.$confirm('查价数据还未加载完，是否继续查看当前选择的价格明细？', '提示', {
                        type: 'warning'
                    }).then(() => {

                        if (record.name == 'GN2') {
                            that.pricingDetail.pricingDetail = that.GN2GFP
                        } else if (record.name == 'PL') {
                            that.pricingDetail.pricingDetail = that.PLGFP
                        } else if (record.name == 'CA-5RR') {
                            that.pricingDetail.pricingDetail = that.CA5RRGFP
                        } else if (record.name == 'NY') {
                            that.pricingDetail.pricingDetail = that.NYGFP
                        } else if (record.name == 'G92') {
                            that.pricingDetail.pricingDetail = that.G92GFP
                        } else if (record.name == 'UPS-ORIGIN') {
                            that.pricingDetail = that.UPS_ORIGIN
                            that.pricingDetail.service_name = 'Ground Freight Pricing'
                        } else if (record.name == 'PL-V2') {
                            that.pricingDetail.pricingDetail = that.PL_V2GFP
                        }

                        that.pricingDetail.orderNo = that.orderNo
                        that.pricingDetail.name = record.name
                        that.pricingDetail.reType = '2'
                        that.pricingDetail.repriceType = '1'
                        that.handleCheckPriceDetail(that.pricingDetail)
                        that.$emit('close')

                    }).catch(() => {
                    });


                } else {
                    if (record.name == 'GN2') {
                        that.pricingDetail.pricingDetail = that.GN2GFP
                    } else if (record.name == 'PL') {
                        that.pricingDetail.pricingDetail = that.PLGFP
                    } else if (record.name == 'CA-5RR') {
                        that.pricingDetail.pricingDetail = that.CA5RRGFP
                    } else if (record.name == 'NY') {
                        that.pricingDetail.pricingDetail = that.NYGFP
                    } else if (record.name == 'G92') {
                        that.pricingDetail.pricingDetail = that.G92GFP
                    } else if (record.name == 'UPS-ORIGIN') {
                        that.pricingDetail = that.UPS_ORIGIN
                        that.pricingDetail.service_name = 'Ground Freight Pricing'
                    } else if (record.name == 'PL-V2') {
                        that.pricingDetail.pricingDetail = that.PL_V2GFP
                    }

                    that.pricingDetail.orderNo = that.orderNo
                    that.pricingDetail.name = record.name
                    that.pricingDetail.reType = '2'
                    that.pricingDetail.repriceType = '1'
                    that.handleCheckPriceDetail(that.pricingDetail)
                    that.$emit('close')
                }


            },
            setRowClassName(record, rowIndex) {

                let cellStyle = ''
                record.name == 'NY' ? cellStyle = 'backColorRed' : cellStyle = ''

                return cellStyle

            },
            edit(record) {
                this.pricing = 1000000
                this.orderNo = record.orderNo

                this.orderMainModel = Object.assign({}, record)
                this.dataSource = []
                //初始化明细表数据
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['form'].resetFields()
                    let url = this.url.rating, method = 'post'
                    // this.confirmLoading = true
                    postAction(url, {'orderNo': record.orderNo}).then((res) => {
                        console.log(res)
                        if (res.success) {
                            this.$message.success(res.message)
                            this.$store.commit("setFullscreenLoading", true);
                            let p1 = new Promise((resolve, reject) => {
                                this.getFCCPrice('US5', '1', '2').then(res => {
                                    resolve(true);
                                }).catch(err => {
                                    resolve(false);
                                })
                            })
                            let p2 = new Promise((resolve, reject) => {
                                this.getGN2V2('PL-V2', '2', '3').then(res => {
                                    resolve(true);
                                }).catch(err => {
                                    resolve(false);
                                })
                            })
                            let p3 = new Promise((resolve, reject) => {
                                this.getUpsOrigin().then(res => {
                                    resolve(true);
                                }).catch(err => {
                                    resolve(false);
                                })
                            })
                            let p4 = new Promise((resolve, reject) => {
                                this.getJhdPrice().then(res => {
                                    resolve(true);
                                }).catch(err => {
                                    resolve(false);
                                })
                            })

                            // 三个接口调用完成之后loading消失
                            Promise.all([p1, p3, p2,p4]).then((result) => {
                                this.$store.commit("setFullscreenLoading", false);
                            })
                            // this.getFCCPrice('US5', '1', '2')
                            // this.getGN2V2('PL-V2', '2', '3')
                            // this.getUpsOrigin()


                            //this.getGN2('GN2', '2', '3')
                            //this.getGN2('PL', '2', '3')
                            //this.getGN2('CA-5RR', '2', '3')
                            //this.getGN2('G92', '2', '1')
                            //this.getGN2('SS-CWT', '2', '2')
                            //  this.getGN2('NY','2','3');
                            // this.getPostMan() 暂停小马接口
                            // this.confirmLoading = false
                        } else {
                            this.$message.warning(res.message)
                            this.close()
                        }
                    }).finally(() => {

                    })
                })
            },
            //调用UPS查价
            async getUpsOrigin() {
                let that = this;
                let url = this.url.getUpsOriginRate, method = 'post';
                await postAction(url, {
                    'orderNo': this.orderNo,
                    'accountName': 'UPS-ORIGIN'
                }).then((res) => {
                    if (res.code == '200') {
                        // that.dataSource.push({
                        //     name: res.result.accountName,
                        //     GFP: '-',
                        //     GFPremark: '-',
                        //     Ground:res.result.amount_price != 0 ? res.result.amount_price.toFixed(2) : '-',
                        //     Groundremark: res.result.priceRemark
                        // });
                        // parseInt(that.pricing) > parseInt(res.result.amount_price).toFixed(2) ? that.pricing = res.result.amount_price.toFixed(2) : that.pricing = that.pricing

                        that.UPS_ORIGIN = res.result;
                        let total = 0;
                        let gross = 0;
                        let name = 'UPS-ORIGIN';
                        let percentTag = '';
                        let remark = '';
                        let discountAmount_temp = ''
                        let afterDiscountAmount = ''
                        let beforeDiscountAmount = ''
                        let fuel = ''
                        for (let item in res.result) {

                            let mark = ''
                            if (item.indexOf('-') != -1) {
                                mark = item.substring(item.indexOf('-'))
                            }

                            total = Number(total) + Number(res.result[item]['totalCharges_temp']);
                            gross = Number(gross) + Number(res.result[item]['grossCharge_temp']);
                            percentTag = percentTag + '<span class="lablespan" style="min-width: 150px">' + 'E' + mark + res.result[item]['remark'] + ' :' + res.result[item]['discountPercentage_temp'] + '</span>';
                            remark = remark + '<span class="lablespan" style="min-width: 150px">' + 'E' + mark + res.result[item]['remark'] + ' :$' + Number(res.result[item]['totalCharges_temp']).toFixed(2) + '</span>';
                            discountAmount_temp = discountAmount_temp + '<span class="lablespan" style="min-width: 150px">' + 'E' + mark + res.result[item]['remark'] + ' :$' + Number(res.result[item]['discountAmount_temp']).toFixed(2) + '</span>';
                            afterDiscountAmount = afterDiscountAmount + '<span class="lablespan" style="min-width: 150px">' + 'E' + mark + res.result[item]['remark'] + ' :$' + ((Number(res.result[item]['grossCharge_temp']).toFixed(2) - Number(res.result[item]['discountAmount_temp']).toFixed(2))).toFixed(2) + '</span>'
                            fuel = fuel + '<span class="lablespan" style="min-width: 150px">' + 'E' + mark + res.result[item]['remark'] + ' :$' + ((Number(res.result[item]['totalCharges_temp']).toFixed(2) - Number(res.result[item]['netCharge_temp']).toFixed(2))).toFixed(2) + '</span>'
                            beforeDiscountAmount = beforeDiscountAmount + '<span class="lablespan" style="min-width: 150px">' + 'E' + mark + res.result[item]['remark'] + ' :$' + Number(res.result[item]['grossCharge_temp']).toFixed(2) + '</span>'

                        }
                        // this.shipForm.checkPrice = total;
                        //显示为GFP价格
                        that.dataSource.push({
                            name: name,
                            GFP: total.toFixed(2),
                            Ground: '-',
                            GFPremark: remark,
                            Groundremark: ''
                        })

                        that.UPS_ORIGIN.total = total;
                        that.UPS_ORIGIN.gross = gross;
                        that.UPS_ORIGIN.percentTag = percentTag;
                        that.UPS_ORIGIN.discountAmount_temp = discountAmount_temp;
                        that.UPS_ORIGIN.afterDiscountAmount = afterDiscountAmount;
                        that.UPS_ORIGIN.beforeDiscountAmount = beforeDiscountAmount;
                        that.UPS_ORIGIN.fuel = fuel;


                        parseInt(that.pricing) > total.toFixed(2) ? that.pricing = total.toFixed(2) : that.pricing
                    }
                })
            },

            async getPostMan() {

                let that = this
                let url = this.url.getPostMan, method = 'post'
                postAction(url, {
                    'orderNo': this.orderNo,
                    'accountName': 'PT'
                }).then((res) => {
                    if (res.code == '200') {


                        that.dataSource.push({
                            name: res.result.accountName,
                            GFP: '-',
                            GFPremark: '-',
                            Ground: res.result.amount_price != 0 ? res.result.amount_price.toFixed(2) : '-',
                            Groundremark: res.result.priceRemark
                        })
                        parseInt(that.pricing) > parseInt(res.result.amount_price).toFixed(2) ? that.pricing = res.result.amount_price.toFixed(2) : that.pricing = that.pricing
                    }
                })
            },

            // reType 类型（1.返回金额 2.金额详情")
            //repriceType 类型（1.返回GFP 2.返回Ground  3.返回全部" )
            async getFCCPrice(name, reType, repriceType) {
                let that = this
                let url = this.url.getFCCPrice, method = 'post'
                await postAction(url, {
                    'orderNo': this.orderNo,
                    'accountName': name,
                    'reType': reType,
                    'repriceType': repriceType
                }, {timeout: 1000 * 60 * 5}).then((res) => {
                    if (res.success) {
                        if (name == "US5") {
                            that.US5Ground = res.result.amount_price;

                            that.dataSource.push({
                                name: res.result.accountName,
                                GFP: '-',
                                GFPremark: '-',
                                Ground: res.result.amount_price != 0 ? res.result.amount_price.toFixed(2) : '-',
                                Groundremark: res.result.priceRemark,
                            });
                            parseInt(that.pricing) > parseInt(res.result.amount_price).toFixed(2) ? that.pricing = res.result.amount_price.toFixed(2) : that.pricing = that.pricing
                        }
                    } else {
                        this.$message.warning(res.message)
                        // this.confirmLoading = false
                    }
                }).finally(() => {
                    this.confirmLoading = false
                }).catch(() => {

                })

            },
            //调用京华达查价
            async getJhdPrice() {
                let that = this
                let url = this.url.getJhdPrice, method = 'post'
                await postAction(url, {'orderNo': this.orderNo,}, {timeout: 1000 * 60 * 5}).then((res) => {
                    if (res.success) {
                        that.JHDGroud = res.result.amount_price;
                        that.dataSource.push({
                            name: res.result.accountName,
                            GFP: '-',
                            GFPremark: '-',
                            Ground: res.result.amount_price != 0 ? res.result.amount_price.toFixed(2) : '-',
                            Groundremark: res.result.priceRemark,
                        });
                        parseInt(that.pricing) > parseInt(res.result.amount_price).toFixed(2) ? that.pricing = res.result.amount_price.toFixed(2) : that.pricing = that.pricing

                    } else {
                        this.$message.warning(res.message)
                        // this.confirmLoading = false
                    }
                }).finally(() => {
                    this.confirmLoading = false
                }).catch(() => {

                })


            },

            // reType 类型（1.返回金额 2.金额详情")
            //repriceType 类型（1.返回GFP 2.返回Ground  3.返回全部" )
            async getGN2(name, reType, repriceType) {
                let that = this
                let url = this.url.getGFP, method = 'post'
                postAction(url, {
                    'orderNo': this.orderNo,
                    'accountName': name,
                    'reType': reType,
                    'repriceType': repriceType
                }, {timeout: 1000 * 60 * 5}).then((res) => {
                    if (res.success) {

                        var a = null
                        var b = null

                        if (name == 'GN2') {

                            let a_final_price = 0
                            let b_final_price = 0
                            let GFPremark = ''
                            let Groundremark = ''

                            for (var p in JSON.parse(res.result.GFP)) {//遍历json对象的每个key/value对,p为key
                                a = JSON.parse(JSON.parse(res.result.GFP)[p])
                                a_final_price = Number(a_final_price) + Number(a['final_price'].replace(',', ''))
                                GFPremark = GFPremark + '<span class="lablespan">' + p + ' :$' + a['final_price'] + '</span>'

                            }

                            for (var p in JSON.parse(res.result.Ground)) {//遍历json对象的每个key/value对,p为key
                                b = JSON.parse(JSON.parse(res.result.Ground)[p])
                                b_final_price = Number(b_final_price) + Number(b['final_price'].replace(',', ''))
                                Groundremark = Groundremark + '<span class="lablespan">' + p + ' :$' + b['final_price'] + '</span>'

                            }

                            that.GN2GFP = JSON.parse(res.result.GFP)
                            that.GN2Groud = JSON.parse(res.result.Ground)
                            that.dataSource.push({
                                name: name,
                                GFP: a_final_price != 0 ? a_final_price.toFixed(2) : '-',
                                GFPremark: GFPremark,
                                Ground: b_final_price != 0 ? b_final_price.toFixed(2) : '-',
                                Groundremark: Groundremark
                            })
                            // 对比是哪个数值最小，便替换
                            parseInt(that.pricing) > parseInt(a_final_price).toFixed(2) ? that.pricing = a_final_price.toFixed(2) : that.pricing = that.pricing
                            parseInt(that.pricing) > parseInt(b_final_price).toFixed(2) ? that.pricing = b_final_price.toFixed(2) : that.pricing = that.pricing
                        } else if (name == 'PL') {


                            let a_final_price = 0
                            let b_final_price = 0
                            let GFPremark = ''
                            let Groundremark = ''


                            for (var p in JSON.parse(res.result.GFP)) {//遍历json对象的每个key/value对,p为key
                                a = JSON.parse(JSON.parse(res.result.GFP)[p])
                                a_final_price = Number(a_final_price) + Number(a['final_price'].replace(',', ''))
                                GFPremark = GFPremark + '<span class="lablespan">' + p + ' :$' + a['final_price'] + '</span>'

                            }

                            for (var p in JSON.parse(res.result.Ground)) {//遍历json对象的每个key/value对,p为key
                                b = JSON.parse(JSON.parse(res.result.Ground)[p])
                                b_final_price = Number(b_final_price) + Number(b['final_price'].replace(',', ''))
                                Groundremark = Groundremark + '<span class="lablespan">' + p + ' :$' + b['final_price'] + '</span>'


                            }


                            that.PLGFP = JSON.parse(res.result.GFP)
                            that.PLGroud = JSON.parse(res.result.Ground)


                            that.dataSource.push({
                                name: name,
                                GFP: a_final_price != 0 ? a_final_price.toFixed(2) : '-',
                                GFPremark: GFPremark,
                                Ground: b_final_price != 0 ? b_final_price.toFixed(2) : '-',
                                Groundremark: Groundremark
                            })

                            // 对比是哪个数值最小，便替换
                            parseInt(that.pricing) > parseInt(a_final_price).toFixed(2) ? that.pricing = a_final_price.toFixed(2) : that.pricing = that.pricing
                            parseInt(that.pricing) > parseInt(b_final_price).toFixed(2) ? that.pricing = b_final_price.toFixed(2) : that.pricing = that.pricing


                        } else if (name == 'NY') {
                            let a_final_price = 0
                            let b_final_price = 0
                            let GFPremark = ''
                            let Groundremark = ''


                            for (var p in JSON.parse(res.result.Ground)) {//遍历json对象的每个key/value对,p为key
                                b = JSON.parse(JSON.parse(res.result.Ground)[p])
                                b_final_price = Number(b_final_price) + Number(b['final_price'].replace(',', ''))
                                Groundremark = Groundremark + '<span class="lablespan">' + p + ' :$' + b['final_price'].replace(',', '') + '</span>'

                            }
                            that.NYGroud = JSON.parse(res.result.Ground)

                            that.dataSource.push({
                                name: name,
                                GFP: a_final_price != 0 ? a_final_price.toFixed(2) : '-',
                                GFPremark: GFPremark,
                                Ground: b_final_price != 0 ? b_final_price.toFixed(2) : '-',
                                Groundremark: Groundremark
                            })

                            // that.dataSource.push({ name: name, GFP: a!=null?a['final_price']:'-', Ground:  b!= null?b['final_price']:'-'})
                            // 对比是哪个数值最小，便替换
                            parseInt(that.pricing) > parseInt(b_final_price).toFixed(2) ? that.pricing = b_final_price.toFixed(2) : that.pricing = that.pricing

                        } else if (name == 'CA-5RR') {

                            let a_final_price = 0
                            let b_final_price = 0
                            let GFPremark = ''
                            let Groundremark = ''

                            that.CA5RRGFP = JSON.parse(res.result.GFP)

                            for (var p in JSON.parse(res.result.GFP)) {//遍历json对象的每个key/value对,p为key
                                a = JSON.parse(JSON.parse(res.result.GFP)[p])
                                a_final_price = Number(a_final_price) + Number(a['final_price'].replace(',', ''))
                                GFPremark = GFPremark + '<span class="lablespan">' + p + ' :$' + a['final_price'].replace(',', '') + '</span>'

                            }

                            that.dataSource.push({
                                name: name,
                                GFP: a_final_price != 0 ? a_final_price.toFixed(2) : '-',
                                GFPremark: GFPremark,
                                Ground: b_final_price != 0 ? b_final_price.toFixed(2) : '-',
                                Groundremark: Groundremark
                            })
                            // 对比是哪个数值最小，便替换
                            parseInt(that.pricing) > parseInt(a_final_price).toFixed(2) ? that.pricing = a_final_price.toFixed(2) : that.pricing = that.pricing
                        } else if (name == 'G92') {

                            let a_final_price = 0

                            let GFPremark = ''


                            for (var p in JSON.parse(res.result.GFP)) {//遍历json对象的每个key/value对,p为key
                                a = JSON.parse(JSON.parse(res.result.GFP)[p])
                                a_final_price = Number(a_final_price) + Number(a['final_price'].replace(',', ''))
                                GFPremark = GFPremark + '<span class="lablespan">' + p + ' :$' + a['final_price'] + '</span>'

                            }

                            that.G92GFP = JSON.parse(res.result.GFP)
                            that.dataSource.push({
                                name: name,
                                GFP: a_final_price != 0 ? a_final_price.toFixed(2) : '-',
                                GFPremark: GFPremark,

                            })
                            // 对比是哪个数值最小，便替换
                            parseInt(that.pricing) > parseInt(a_final_price).toFixed(2) ? that.pricing = a_final_price.toFixed(2) : that.pricing = that.pricing

                        } else if (name == 'SS-CWT') {

                            let b_final_price = 0
                            let Groundremark = ''

                            for (var p in JSON.parse(res.result.Ground)) {//遍历json对象的每个key/value对,p为key
                                b = JSON.parse(JSON.parse(res.result.Ground)[p])
                                b_final_price = Number(b_final_price) + Number(b['final_price'].replace(',', ''))
                                Groundremark = Groundremark + '<span class="lablespan">' + p + ' :$' + b['final_price'] + '</span>'

                            }

                            that.SSCWTGroud = JSON.parse(res.result.Ground)
                            that.dataSource.push({
                                name: name,
                                Ground: b_final_price != 0 ? b_final_price.toFixed(2) : '-',
                                Groundremark: Groundremark
                            })
                            // 对比是哪个数值最小，便替换

                            parseInt(that.pricing) > parseInt(b_final_price).toFixed(2) ? that.pricing = b_final_price.toFixed(2) : that.pricing = that.pricing
                        }


                    } else {
                        this.$message.warning(res.message)
                        // this.confirmLoading = false
                    }
                }).finally(() => {
                    this.confirmLoading = false
                }).catch(() => {

                })

            },

            // reType 类型（1.返回金额 2.金额详情")
            //repriceType 类型（1.返回GFP 2.返回Ground  3.返回全部" )
            async getGN2V2(name, reType, repriceType) {
                let that = this
                let url = this.url.getGFP2, method = 'post'
                await postAction(url, {
                    'orderNo': this.orderNo,
                    'accountName': name,
                    'reType': reType,
                    'repriceType': repriceType
                }, method).then((res) => {
                    if (res.success) {

                        var a = null
                        var b = null

                        if (name == 'PL-V2') {


                            let a_final_price = 0
                            let b_final_price = 0
                            let GFPremark = ''
                            let Groundremark = ''


                            for (let p in JSON.parse(res.result.GFP)) {//遍历json对象的每个key/value对,p为key
                                a = JSON.parse(JSON.parse(res.result.GFP)[p])
                                a_final_price = Number(a_final_price) + Number(a['final_price'])
                                GFPremark = GFPremark + p + ' :$' + a['final_price'] + ' <br/> '

                            }

                            for (let p in JSON.parse(res.result.Ground)) {//遍历json对象的每个key/value对,p为key
                                b = JSON.parse(JSON.parse(res.result.Ground)[p])
                                b_final_price = Number(b_final_price) + Number(b['final_price'])
                                Groundremark = Groundremark + p + ' :$' + b['final_price'] + ' <br/> '

                            }


                            that.PL_V2GFP = JSON.parse(res.result.GFP)
                            that.PL_V2Groud = JSON.parse(res.result.Ground)


                            that.dataSource.push({
                                name: name,
                                GFP: a_final_price != 0 ? a_final_price.toFixed(2) : '-',
                                GFPremark: GFPremark,
                                Ground: b_final_price != 0 ? b_final_price.toFixed(2) : '-',
                                Groundremark: Groundremark
                            })

                            // 对比是哪个数值最小，便替换
                            parseInt(that.pricing) > parseInt(a_final_price).toFixed(2) ? that.pricing = a_final_price.toFixed(2) : that.pricing = that.pricing
                            parseInt(that.pricing) > parseInt(b_final_price).toFixed(2) ? that.pricing = b_final_price.toFixed(2) : that.pricing = that.pricing


                        }
                    } else {
                        this.$message.warning(res.message)
                        this.confirmLoading = false
                    }
                }).finally(() => {
                    this.confirmLoading = false
                })

            },


            close() {
                this.$emit('close')
                this.visible = false
            },
            handleOk() {
                const that = this
                // 触发表单验证
                this.form.validateFields((err, values) => {
                    if (!err) {
                        checkAddress(values)
                            .then(res => {

                                if (res.success) {
                                    this.Data = res.result.list
                                }
                            })
                    }
                    this.dataSource = []
                    /*
                    that.confirmLoading = true;
                    let httpurl = '';
                    let method = '';
                    if (!this.orderMainModel.id) {
                      httpurl += this.url.add;
                      method = 'post';
                    } else {
                      httpurl += this.url.edit;
                      method = 'put';
                    }
                    let orderMainData = Object.assign(this.orderMainModel, values);
                    //时间格式化
                    orderMainData.orderDate = orderMainData.orderDate ? orderMainData.orderDate.format('YYYY-MM-DD HH:mm:ss') : null;
                    let formData = {
                      ...orderMainData
                    }


                    httpAction(httpurl, formData, method).then((res) => {
                      if (res.success) {
                        that.$message.success(res.message);
                        that.$emit('ok');
                      } else {
                        that.$message.warning(res.message);
                      }
                    }).finally(() => {
                      that.confirmLoading = false;
                      that.close();
                    })
                  }*/
                })
            },
            handleCancel() {
                this.dataSource = []
                this.pricing = 1000000
                this.close()
            },
            cellStyle(row, column, rowIndex, columnIndex) {
                if ((row.column.label === "Ground" && row.row.Ground === this.pricing)
                    || (row.column.label === "GFP" && row.row.GFP === this.pricing)) {
                    return "color:#7ac23c;font-weight: bold;"
                }
            },
        }
    }
</script>
<style>
    .backColorRed {
        color: red;
    }

    .backColorBlue {
        color: Blue;
    }
</style>

<style scoped>
    .ant-btn {
        padding: 0 10px;
        margin-left: 3px;
    }

    .ant-form-item-control {
        line-height: 0px;
    }

    /** 主表单行间距 */
    .ant-form .ant-form-item {
        margin-bottom: 10px;
    }

    /** Tab页面行间距 */
    .ant-tabs-content .ant-form-item {
        margin-bottom: 0px;
    }


</style>
