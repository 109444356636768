<template>
    <el-dialog
            title="问题件"
            :visible="visible"
            width="50%"
            append-to-body
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="orderNo" label="订单号">
                  <el-input class="padding_right_area" v-model="form.orderNo" maxlength="100" show-word-limit type="textarea" placeholder="允许输入多个订单号，请用逗号分割"></el-input>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="warehouseRemark" label="问题描述">
                        <el-input class="padding_right_area" v-model="form.warehouseRemark" maxlength="2000" show-word-limit type="textarea"></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="reamark" label="处理状态">
                  <el-input class="padding_right_area" v-model="form.remark" maxlength="500" show-word-limit type="textarea"></el-input>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="problemType" label="问题类型">
                        <f-select v-model="form.problemType" :isNeed="form.problemType" :dict="'problem_type'"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="fileList" label="附件上传">
                        <f-upload v-model="form.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction,getAction } from '@/api/manage'

export default {
    name: 'AddProblemDialog',
    props: {
        visible: {
            type: Boolean
        },
      userName:{
        type :String
      },
      problemTypeList:{
          type:Array,
          default:()=>[]
      }
    },
    data() {
        return {
            form: {},
            options: [],
            rules: {
              orderNo: [
                { required: true, message: '请输入单号', trigger: 'blur' },
              ],
                warehouseRemark: [
                    { required: true, message: '请输入问题描述', trigger: 'blur' },
                ],
                problemType: [
                    { required: true, message: '请选择问题类型', trigger: 'change' },
                ],
            },
            url: {
                add: '/interface/ttOrder/addOneProblem',
                edit: '/interface/ttOrder/updateFbaWarehouseProblemInfo',
                getById:'/interface/ttOrder/getOneProblemById',
                queryTtOrderContainerIdByOrderNo:'/interface/ttOrder/queryTtOrderContainerIdByOrderNo',

            }
        }
    },
    watch: {
        visible(val) {
           this.form = {fileList:[]}
        }
    },
    methods: {
        submitQaq(url,method,params){

            httpAction(url, method, params).then((res)=>{
                this.$message({
                    type: 'success',
                    message: res.message
                })
                this.handleClose();
            })
        },
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if (this.form.fileList){
                this.$set(this.form,'fileIds',this.form.fileList.map(function (e) {
                    return  e.id
                }).join(","));
            }
            if (!this.form.orderNo){
                this.$set(this.form,'orderNo',this.row.orderNo);
            }
            // if(this.row.id) {
            //     url = this.url.edit
            //     method = 'PUT'
            // }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    /*将filelist的id取出来拼成ids给form*/
                    getAction(this.url.queryTtOrderContainerIdByOrderNo,{orderNo:this.form.orderNo})
                        .then(res=>{
                            if (res.message && res.message.indexOf('已经排柜了')!==-1){
                                this.$confirm(`订单${this.form.orderNo}已排柜，新增问题件后请马上通知到排柜操作踢柜`, '提示', {
                                    confirmButtonText: '是',
                                    cancelButtonText: '否',
                                    type: 'info',
                                    closeOnClickModal: false,
                                    distinguishCancelAndClose: true,//区分取消与关闭
                                }).then(()=>{
                                    this.submitQaq(url,method,this.form);
                                })
                            }else {
                                this.submitQaq(url,method,this.form);
                            }
                        })

                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false);
            this.$emit('ok');
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>
