<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="销售">
              <f-select
                :dict="'sys_user_id'"
                v-model="queryForm.idList"
                :isNeed="queryForm.idList"
                multiple
                placeholder="请选择"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="销售组别">
              <f-select
                v-model="queryForm.deptIdList"
                :dict="'sys_depart'"
                :isNeed="queryForm.deptIdList"
                :multiple="true"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col style="display: flex" :span="8">
            <el-form-item label="第一次下单时间" label-width="120px">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.firstOrderTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.firstOrderTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col style="display: flex" :span="8">
            <el-form-item label="最后一次下单时间" label-width="120px">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.lastOrderTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.lastOrderTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="客户状态">
              <f-select
                :dict="'loss_revice_status'"
                v-model="queryForm.customerStatus"
                :isNeed="queryForm.customerStatus"
                placeholder="请选择"
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label-width="30px" class="btn_box">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          @click="
            exportExcelPlusold(
              url.lossAndReviveCustomerDetailsExport,
              '明细导出'
            )
          "
          >明细导出</el-button
        >
      </div>
      <!-- <div class="c-alert f-m-b-10">
        <div>
          <span>流失率汇总:{{ lossRateTotal.toFixed(2) + "%" }}</span
          ><span style="margin-left: 20px"
            >客户复活率汇总:{{ rateTotal.toFixed(2) + "%" }}</span
          >
        </div>
      </div> -->
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'"> </template>
          <template v-else-if="scope.column.property === 'lossRate'">
            <span>{{ scope.row.lossRate + "%" }}</span>
          </template>
          <template v-else-if="scope.column.property === 'reviveRate'">
            <span>{{ scope.row.reviveRate + "%" }}</span>
          </template>
          <template v-else-if="scope.column.property === 'customerStatus'">
            <span>{{
              parseDict(scope.row.customerStatus, "loss_revice_status")
            }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { putAction, deleteAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
export default {
  name: "LossAndReviveDetail",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "departName",
          label: "区域",
        },
        {
          prop: "salesmanName",
          label: "业务员",
        },
        {
          prop: "customerName",
          label: "客户",
        },
        {
          prop: "lastOrderNo",
          label: "最后一次订单号",
        },
        {
          prop: "firstOrderTime",
          label: "第一次下单时间",
        },
        {
          prop: "lastOrderTime",
          label: "最后一次下单时间",
        },
        {
          prop: "customerStatus",
          label: "客户状态",
        },
      ],
      url: {
        list: "/customer/getLossAndReviveCustomerDetailsList",
        lossAndReviveCustomerDetailsExport:
          "/file/export/excel/lossAndReviveCustomerDetailsExport",
      },
      isUsePost: true,
      lossRateTotal: 0, //流失率
      rateTotal: 0, //复活率
      disableMixinMounted: true,
      dictCodes: ["loss_revice_status"],
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep {
  .btn_box {
    .el-form-item__content {
      display: flex;
      justify-content: end;
      margin-top: 10px;
    }
  }
}
</style>
