<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="港口代码">
                                <el-input  v-model="queryForm.portCode"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="码头代码">
                            <el-input  v-model="queryForm.matouCode"
                                       @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="f-p-b-15">
                        <el-button
                                type="primary"
                                size="medium"
                                icon="el-icon-plus"
                                @click="handleAdd()"
                                v-if="buttonShowList.add">新增</el-button>
                        <el-button
                                class="c-theme-error-button"
                                size="medium"
                                :disabled="selectedRowKeys.length === 0"
                                v-if="buttonShowList.plsc"
                                @click="handleBatDel">批量删除</el-button>
                    </div>

                </el-card>
            </el-form>


            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                  <el-button v-if="buttonShowList.bj"
                             @click="handleEdit(scope.row)" type="text">编辑</el-button>
                </template>
            </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑' : '新增'"
                :visible="visible"
                width="560px"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :model="form" :rules="rules" ref="addForm" label-width="120px" size="medium">
                <el-row>


                    <el-col :span="24">
                        <el-form-item prop="portCode" label="港口代码">
                            <el-input v-model="form.portCode" placeholder="请输入港口代码" maxlength="5"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="matouCode" label="码头代码">
                            <el-input v-model="form.matouCode" placeholder="请输入码头代码" maxlength="20"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>


                </el-row>
            </el-form>
            <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="refreshOne">确定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {postAction} from "@/api/manage";
    export default {
        name: "YundangCodes",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "portCode",
                        label: "港口代码",
                    },
                    {
                        prop: "matouCode",
                        label: "码头代码",
                    },
                  {
                    prop: "createBy",
                    label: "录入人",
                  },
                  {
                    prop: "createTime",
                    label: "录入时间",
                  },


                ],
                url: {
                    //查询列表的接口
                    list: "/operation/yundangCodes/getIPage",
                    addOrEditOne: "/operation/yundangCodes/addOrEditOne",
                    delDuo: "/operation/yundangCodes/delDuo",

                },
                visible:false,
                form:{},
                rules:{
                    portCode:[
                        {required:true,message:'请输入港口代码',trigger:'change'}
                    ],
                    matouCode:[
                        {required:true,message:'请输入码头代码',trigger:'change'}
                    ]
                }
            };
        },
        methods: {
          handleClose(){
              this.visible = false;
              this.handleSearch();
          },
            handleAdd(){
              this.form = {};
              this.visible = true;
            },
            handleEdit(row){
              this.form = JSON.parse(JSON.stringify(row));
              this.visible = true;
            },
            refreshOne(){
              this.$refs['form'].validate(valid=>{
                  if (valid) {
                      postAction(this.url.addOrEditOne,this.form)
                          .then(res=>{
                              this.$message({type:'success',message:res.message});
                              this.handleClose();
                          })
                  }
              })
            },
            handleDelDuo(){
                this.$confirm(`确定删除`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.delDuo,this.selectedRowKeys)
                        .then(res=>{
                            this.$message({type:'success',message:res.message});
                            this.handleClose();
                        })
                })
            }
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('operation/YundangCodes','0','新增'),
            'plsc':this.$btnIsShow('operation/YundangCodes','0','批量删除'),
            'bj':this.$btnIsShow('operation/YundangCodes','0','编辑'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
