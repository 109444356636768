<!--已完成-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div class="f-p-b-15" style="display:flex;">
                <el-card class="box-card">
                  <el-row :gutter="24" class="inputs_row">
                    <el-col :md="4" :sm="24">
                  <el-form-item label="订单号">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="客户名">
                          <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="是否收款">
                      <f-select :dict="'is_default'"
                                v-model="queryForm.recStatus"
                                :isNeed="queryForm.recStatus"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="进口商类型">
                      <f-select :dict="'order_importer'"
                                v-model="queryForm.importerType"
                                :isNeed="queryForm.importerType"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="是否包税">
                      <f-select
                          v-model="queryForm.isHasTax"
                          :isNeed="queryForm.isHasTax"
                          :dict="'is_default'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="操作员">
                      <f-select
                          v-model="queryForm.operateId"
                          :isNeed="queryForm.operateId"
                          :dict="'operationsId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="客服">
                      <f-select
                          v-model="queryForm.docId"
                          :isNeed="queryForm.docId"
                          :dict="'docId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="销售">
                      <f-select
                          v-model="queryForm.salesmanId"
                          :isNeed="queryForm.salesmanId"
                          :dict="'salesmanId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="目的地">
                      <f-select
                          v-model="queryForm.podEnd"
                          :isNeed="queryForm.podEnd"
                          :dict="'bd_port'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="渠道名称">
                      <f-select
                          v-model="queryForm.transportStatus"
                          :isNeed="queryForm.transportStatus"
                          :dict="'transportStatus'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="保险状态">
                      <f-select
                          v-model="queryForm.insureStatus"
                          :isNeed="queryForm.insureStatus"
                          :dict="'is_default'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="12" :lg="4">
                      <el-form-item label="是否上传pod">
                          <el-select v-model="queryForm.isPod" clearable filterable>
                               <el-option value="1" label="是"></el-option>
                               <el-option value="0" label="否"></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :md="6" :sm="24">
                    <el-form-item label="入仓时间" label-width="100px">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.cargoArrivedStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.cargoArrivedEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
<!--                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
<!--                      <el-form-item label="清关属性">-->
<!--                        <f-select-->
<!--                            :dict="'qg_prop'"-->
<!--                            v-model="queryForm.qgProp"-->
<!--                            :is-need="queryForm.qgProp"-->
<!--                        ></f-select>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
<!--                      <el-form-item label="海外仓中转服务">-->
<!--                        <f-select-->
<!--                            :dict="'haiwai_service'"-->
<!--                            v-model="queryForm.haiwaiService"-->
<!--                            :is-need="queryForm.haiwaiService"-->
<!--                        ></f-select>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                      <el-form-item label="锁国外仓租柜租">
                        <f-select
                            :dict="'is_default'"
                            v-model="queryForm.lockCangzu"
                            :is-need="queryForm.lockCangzu"
                        ></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                      <el-form-item label="锁国外查验">
                        <f-select
                            :dict="'is_default'"
                            v-model="queryForm.lockChayan"
                            :is-need="queryForm.lockChayan"
                        ></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                      <el-form-item label="三锁服务">
                        <f-select
                            :dict="'is_default'"
                            v-model="queryForm.sanLock"
                            :is-need="queryForm.sanLock"
                        ></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                      <el-form-item label="地址类型">
                        <f-select
                            :dict="'address_type'"
                            v-model="queryForm.shipmentType"
                            :is-need="queryForm.shipmentType"
                        ></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                      <el-form-item label="航线名称">
                        <f-select
                            :dict="'fba_solicitation'"
                            v-model="queryForm.solicitation"
                            :is-need="queryForm.solicitation"
                        ></f-select>
                      </el-form-item>
                    </el-col>
                  <el-col :span="4">
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置</el-button
                    >
                    <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i
                        :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                        style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card">
            <el-button type="primary" size="medium" icon="el-icon-plus" @click="beatchUnLock"
                        :disabled="this.selectedRowOrderNos.length===0 " v-if="buttonShowList.pljs"
                        >批量解锁</el-button>
            <el-button type="primary"
                        size="medium"
                        @click="downloadExcelComplete(url.download, '已完成数据',queryForm)"
                       v-if="buttonShowList.dcywcsj"
                >导出已完成数据</el-button>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="unLock(scope.row)" v-if="buttonShowList.js"
                                   >解锁</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a><br>
                        <span v-if="scope.row.channelName &&scope.row.channelName.includes('海+快')"> </span>
                        <span v-else-if="scope.row.isPod === '1'" style="color:green;">已上传POD</span>
                        <span v-else-if="scope.row.isPod ==='0'" style="color: red">未上传POD</span>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">
                            {{scope.row.customer}}
                        </a>
                    </template>
                    <template v-else-if="scope.column.property === 'realCtn'"> <!--拼接件重体-->
                        <span>{{scope.row.realCtn +'/'+scope.row.realKg+'/'+scope.row.realCmb}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isHasTax'">
                      <span v-if="scope.row.isHasTax === '1'">包税</span>
                       <span v-else>不包税</span>
                    </template>

                    <template v-else-if="scope.column.property === 'importerType'">
                        <span>{{parseDict(scope.row.importerType,'order_importer')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'recStatus'">
                        <span v-if="scope.row.recStatus === '3'">已收款</span>
                        <span v-if="scope.row.bondVat === '2'">部分收款</span>
                        <span v-else>未收款</span>
                    </template>
                    <template v-else-if="scope.column.property === 'insureStatus'">
                        <span>{{parseDict(scope.row.insureStatus,'order_status')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
              showLoadingSearch:false,
                dictCodes: ['is_default','transportStatus','order_importer','bd_port','order_status'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        minWidth: 80,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 100,
                    },
                    {
                        prop: "recStatus",
                        label: "是否收款",
                        minWidth: 50,
                    },
                    {
                        prop: "importerType",
                        label: "进口商类型",
                        minWidth: 50,
                    },
                    {
                        prop: "isHasTax",
                        label: "是否包税",
                        minWidth: 50,
                    },
                    {
                        prop: "realCtn",
                        label: "实件/重/体",
                        minWidth: 100,
                    },
                    {
                        prop: "cargoArrived",
                        label: "入仓时间",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "operator",
                        label: "操作员",
                        minWidth: 80,
                    },
                    {
                        prop: "doc",
                        label: "客服",
                        minWidth: 80,
                    },
                    {
                        prop: "salesman",
                        label: "销售",
                        minWidth: 80,
                    },
                    {
                        prop: "podEnd",
                        label: "目的地",
                        minWidth: 100,
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                        minWidth: 100,
                    },
                    {
                        prop: "insureStatus",
                        label: "保险状态",
                        minWidth: 80,
                    },
                ],
                url: {
                    list: "/operation/container/complete", //已完成列表
                    beatchUnLock: "/operation/container/updateDeatchUnLock",
                    download: "/operation/container/downloadExcelComplete",
                },
                selectedRowOrderNos: [],
            };
        },
        methods: {


            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['orderNo'])
                }
                this.selectedRowOrderNos = [...arr];
            },
            beatchUnLock(){
                getAction(this.url.beatchUnLock+'?orderNos='+this.selectedRowOrderNos.join(",")).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        //关闭弹窗
                        this.dialogFormVisible = false;
                        this.handleQuery();
                    }
                });
            },
            unLock(row){
                getAction(this.url.beatchUnLock+'?orderNos='+row.orderNo).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        //关闭弹窗
                        this.dialogFormVisible = false;
                        this.handleQuery();
                    }
                });
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                if (this.$refs['GTable']){
                  console.log(45245245)
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
        },
        created() {
        },
      computed:{
        buttonShowList(){
          return {
            'pljs':this.$btnIsShow('operation/cabinet/Complete','0','批量解锁'),
            'dcywcsj':this.$btnIsShow('operation/cabinet/Complete','0','导出已完成数据'),
            'js':this.$btnIsShow('operation/cabinet/Complete','1','解锁'),


          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
