<!--待收货-->
<template>
    <div class="main-full-content">
        <el-form
                class="input_top"
                ref="form"
                :model="queryForm"
                label-width="120px"
                size="medium"
        >
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道名称">
                            <el-input v-model="queryForm.channelName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道编码">
                            <el-input v-model="queryForm.channelCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务类型">
                            <el-select v-model="queryForm.busType" clearable filterable @keyup.enter.native="handleSearch">
                                <el-option value="1" label="快递"></el-option>
                                <el-option value="2" label="空运"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        v-if="buttonShowList.add"
                >新增
                </el-button
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button
                        >
                        <el-button
                                type="text"
                                @click="handleJy(scope.row)"
                                v-if="scope.row.status == '0' && buttonShowList.dis"
                        >禁用
                        </el-button
                        >
                        <el-button
                                type="text"
                                @click="handleQy(scope.row)"
                                v-if="scope.row.status == '1' && buttonShowList.en"
                        >启用
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'tax'">
                        <span v-if="scope.row.tax === '0'">否</span>
                        <span v-else>是</span>
                    </template>
                    <template v-else-if="scope.column.property === 'feeType'">
                        <span v-if="scope.row.feeType === '0'">单箱</span>
                        <span v-else>整票</span>
                    </template>
                    <template v-else-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status === '0'">已启用</span>
                        <span v-else>已禁用</span>
                    </template>
                    <template v-else-if="scope.column.property === 'goodType'">
                        <span>{{parseDict(scope.row.goodType,'kd_good_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'busType'">
                        <span v-if="scope.row.busType === '1'">快递</span>
                        <span v-else-if="scope.row.busType === '2'">空运</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑渠道' : '新增渠道'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="业务类型"
                        :label-width="formLabelWidth"
                        prop="busType"
                >
                    <el-select v-model="form.busType" clearable filterable>
                        <el-option value="1" label="快递"></el-option>
                        <el-option value="2" label="空运"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="国家"
                        :label-width="formLabelWidth"
                        prop="country"
                >
                    <f-select :is-need="form.country"
                              v-model="form.country"
                              :dict="'bd_country'"></f-select>
                </el-form-item>

                <el-form-item
                        label="渠道名称"
                        :label-width="formLabelWidth"
                        prop="channelName"
                >
                    <el-input v-model="form.channelName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="渠道编码"
                        :label-width="formLabelWidth"
                        prop="channelCode"
                >
                    <el-input v-model="form.channelCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否包税" :label-width="formLabelWidth" prop="tax">
                    <el-radio-group v-model="form.tax">
                        <el-radio :label="'1'">是</el-radio>
                        <el-radio :label="'0'">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                        label="最低计费重(KG)"
                        :label-width="formLabelWidth"
                        prop="minFeeKg"
                >
                    <el-input
                            v-model="form.minFeeKg"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="体积重基数"
                        :label-width="formLabelWidth"
                        prop="cmbBaseStr"
                >
                    <f-select
                            :is-need="form.cmbBaseStr"
                            v-model="form.cmbBaseStr"
                            :dict="'kd_cmb_base'"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="计费类型"
                        :label-width="formLabelWidth"
                        prop="feeType"
                >
                    <f-select
                            :is-need="form.feeType"
                            v-model="form.feeType"
                            :dict="'kd_fee_type'"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="计费标准"
                        :label-width="formLabelWidth"
                        prop="feeMode"
                >
                    <f-select
                            :is-need="form.feeMode"
                            v-model="form.feeMode"
                            :dict="'kd_fee_mode'"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="超重进1(KG)"
                        :label-width="formLabelWidth"
                        prop="overweight"
                >
                    <el-input
                            v-model="form.overweight"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="承运产品备注"
                        :label-width="formLabelWidth"
                        prop="remark"
                >
                    <el-input v-model="form.remark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="货运类型"
                        :label-width="formLabelWidth"
                        prop="goodType"
                >
                    <f-select
                            :is-need="form.goodType"
                            v-model="form.goodType"
                            :dict="'kd_good_type'"
                    ></f-select>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false"
                >取 消
                </el-button
                >
                <el-button type="primary" size="medium" @click="handleSave"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['tariff_advance','kd_good_type'],
                rules: {
                    busType: [
                        {required: true, message: "请选择业务类型", trigger: "change"},
                    ],
                    country: [
                        {required: true, message: "请选择国家", trigger: "change"},
                    ],
                    channelName: [
                        {required: true, message: "请输入渠道名称", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    channelCode: [
                        {required: true, message: "请输入渠道编码", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    tax: [{required: true, message: "请选择是否包税", trigger: "change"}],
                    minFeeKg: [
                        {required: true, message: "请输入最低计费重", trigger: "change"},
                    ],
                    cmbBaseStr: [
                        {required: true, message: "请选择体积重基数", trigger: "change"},
                    ],
                    feeType: [
                        {required: true, message: "请选择计费类型", trigger: "change"},
                    ],
                    feeMode: [
                        {required: true, message: "请选择计费标准", trigger: "change"},
                    ],
                    overweight: [
                        {required: true, message: "请输入超重进1", trigger: "change"},
                    ],
                    goodType: [
                        {required: true, message: "请选择货运类型", trigger: "change"},
                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "busType",
                        label: "业务类型",
                    },
                    {
                        prop: "country",
                        label: "国家",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "channelCode",
                        label: "渠道编码",
                    },
                    {
                        prop: "tax",
                        label: "是否包税",
                    },
                    {
                        prop: "remark",
                        label: "承运产品备注",
                    },
                    {
                        prop: "minFeeKg",
                        label: "最低计费重",
                        sortable: "custom",
                    },
                    {
                        prop: "cmbBase",
                        label: "体积重基数",
                        sortable: "custom",
                    },
                    {
                        prop: "feeType",
                        label: "计费类型",
                    },
                    {
                        prop: "feeMode",
                        label: "计费标准",
                        sortable: "custom",
                    },
                    {
                        prop: "overweight",
                        label: "超重进1",
                        sortable: "custom",
                    },
                    {
                        prop: "status",
                        label: "状态",
                    },
                    {
                        prop: "goodType",
                        label: "货运类型",
                    },
                ],
                url: {
                    list: "/order/KdOrderChannel/list",
                    add: "/order/KdOrderChannel/add",
                    edit: "/order/KdOrderChannel/edit",
                    getById: "/order/KdOrderChannel/queryById",
                    jinyong: "/order/KdOrderChannel/disable",
                    qiyong: "/order/KdOrderChannel/qiyong",
                },
            };
        },
        methods: {
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        } else {
                            postAction(this.url.add, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        }
                    }
                });
            },
            handleQy(row) {
                this.$confirm(`确定启用渠道: ${row.channelName}`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        getAction(this.url.qiyong, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    });
            },
            handleJy(row) {
                this.$confirm(`确定禁用渠道: ${row.channelName}`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        getAction(this.url.jinyong, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    });
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
        },
      computed:{
        buttonShowList(){
          return {
            'add':this.$btnIsShow('kdorder/kdset/ChannelManage','0','新增'),
            'bj':this.$btnIsShow('kdorder/kdset/ChannelManage','1','编辑'),
            'dis':this.$btnIsShow('kdorder/kdset/ChannelManage','1','禁用'),
            'en':this.$btnIsShow('kdorder/kdset/ChannelManage','1','启用'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
