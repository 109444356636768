<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="680px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" ref="billForm" :rules="rules">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="realCur" label="折合币种">
                        <f-select v-model="billForm.realCur"
                                  :isNeed="billForm.realCur"
                                  :dict="'bd_cur'"
                                  placeholder="请选择折合币种"
                                  clearable
                                  filterable
                                  @change="curChangeBank"
                                  disabled
                                  class="f-full-width"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="payBankId" label="银行账户">
                        <el-select v-model="billForm.payBankId"
                                   placeholder="根据币种选择银行"
                                   clearable
                                   filterable
                                   class="f-full-width">
                            <el-option v-for="(item,index) in banks" :value="item.id" :label="item.bankName" :key="item.id">
                                {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="realMoney" label="实付金额">
                        <el-input disabled="disabled" type="text" v-model="billForm.realMoney" style="width: 250px" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item label="上传账单" prop="fileList" ref="fileList">
                        <el-upload
                                class="upload-demo"
                                :action="url.uploadInvoice"
                                :http-request="handleUpload"
                                :on-remove="handleRemove"
                                accept="image/gif,image/jpeg,image/png,image/jpg,image/bmp"
                                :before-upload="beforeUpload"
                                :file-list="fileList"
                                list-type="picture">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'
    import QueryMixins from "../../../../mixins/QueryMixins";

    export default {
        name: 'payDialog',
        mixins: [QueryMixins],

        data() {
            const validateFileList = (rule, value, callback) => {
                if (this.billForm.uploadFileIds.length === 0) {
                    callback(new Error('请上传账单'));
                }
                callback();
            };
            return {
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                banks:[],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                fileList: [],
                billForm: {
                    id:'',
                    realCur:'',
                    realMoney:'',
                    uploadFileIds:[],//上传文件的id，保存时增加申请单号
                },
                url: {
                    list: '/sys/bdCountry/list',
                    curBanks: '/shipping/hySettleReceive/curGetBank',
                    uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                    delFile: '/shipping/hySettlePay/delFile',
                    payTheMerge: '/shipping/hySettlePay/payTheMerge',
                    delOss: '/sys/oss/file/delete',
                    getFiles: '/shipping/hySettlePay/getFilesByApplyNo'
                },
                rules: {
                    realCur: [
                        {required: true, message: '请选择折合币种', trigger: 'change'},
                    ],
                    PayBankId: [
                        {required: true, message: '根据币种选择银行', trigger: 'change'},
                    ],
                    realMoney: [
                        {required: true, message: '请输入实付金额', trigger: 'change'},
                    ],
                    fileList: [
                        { validator: validateFileList, trigger: 'change'}
                    ],


                },
                title: '合并账单付款',
                settleIds: [],
            }
        },
        methods: {
            handleSubmit() {
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.ifDis = true
                        this.enabled()

                        postAction(this.url.payTheMerge,this.billForm).then((res) => {
                            //检测是同一个结算对象 才进行下载
                            if(res.success){

                                this.fileList = [];
                                this.visible=false
                                this.$emit('ok');
                            }
                        })


                    }
                })
            },
            //上传文件到oss
            handleUpload(data) {
                this.ifDis = true;
                const formData = new FormData();
                formData.append("file", data.file);

                postAction(this.url.uploadInvoice, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        this.billForm.uploadFileIds.push(res.result.id);
                        // console.log(this.billForm.uploadFileIds)
                        this.fileList.push(res.result);
                        this.$refs.fileList.clearValidate();
                        this.$message.success('上传成功');
                        this.ifDis = false;
                    }else{

                    }
                })
            },
            handleRemove(file) {
                this.fileList.map((item, index) => {
                    if (item.uid == file.uid) {
                        //向服务器发送要删除的文件的路径，删除oss上的文件
                        // console.log(item)
                        postAction(this.url.delFile,{osOrderFileId:item.id, url:item.url}).then((res)=>{
                            // console.log(res)
                            //页面的上的文件也要删除
                            this.fileList.splice(index, 1);
                            this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                        })

                    }
                })
            },
            removeFile(val){
                if (val && val.status==="success") {
                    // 成功时候的方法
                    //删除oss的文件
                    const index = this.fileList.indexOf(val);
                    deleteAction(this.url.delOss,{id:this.form.ossId})
                        .then(res=>{
                            if (res.success){
                                // console.log('执行删除文件',index);
                                this.fileList.splice(index,1);
                                //同时清空文件的url
                                this.form.url = '';
                                this.form.ossId = '';
                                this.$message.success('删除文件成功')
                            }else {
                                //清空filelist
                                this.$refs.upload.clearFiles();
                                // console.log('执行删除文件',index);
                            }
                        }).catch(err=>{
                        this.$refs.upload.clearFiles();
                        // console.log('执行删除文件',index);
                    })
                    this.fileList = [];
                }

            },
            //文件上传之前校验文件合法
            beforeUpload(file) {
                // console.log(file)
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15
                if (!isLt2M) {
                    this.$message.warning('文件大小不能超过15MB')
                }
                const isFile = file.name.indexOf('.') !== -1;
                if (!isFile) {
                    this.$message.warning('该文件名不合法')
                }
                return isLt2M && isFile;
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.visible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(row) {

                this.fileList = [];
                // console.log('payCur',row.payCur);
                // console.log('payMoney',row.payMoney);
                // this.billForm.id  = row.id;
                // this.billForm.payCur = row.payCur;
                // this.billForm.payMoney = row.payMoney;
                this.billForm = row
                this.billForm.uploadFileIds = []
                this.billForm.realCur = row.payCur;
                this.billForm.realMoney = row.payMoney;

                this.curChangeBank(row.payCur);
                // console.log('billForm',this.billForm);
                //获取所有申请编号下上传的水单
                postAction(this.url.getFiles,{mergeNo:row.mergeNo}).then((res)=>{
                    // console.log(res)
                })

                this.visible = true

            },
            curChangeBank(val){
                // console.log(val)
                this.billForm.payBankId = '';
                this.ifDis = true
                getAction(this.url.curBanks,{cur:val}).then((res)=>{
                    // console.log(res)
                    this.banks = res.result
                    this.ifDis = false;
                })
            },

        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
