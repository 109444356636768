<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="表名">
                            <el-input v-model="queryForm.tableName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="表别名">
                            <el-input v-model="queryForm.tableOtherName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="类型">
                      <f-select v-model="queryForm.ruleType" :is-need="queryForm.ruleType" :dict="'sale_config_dict_rule_type'" @keyup.enter.native="handleSearch"></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="编码">
                      <el-input v-model="queryForm.fieldId" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="名称">
                      <el-input v-model="queryForm.fieldName" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch"

                            >查询</el-button>
                            <el-button plain @click="handleReset"

                            >重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleEdit({})"

                           v-if="buttonShowList.add"

                >新增
                </el-button>
                <el-button size="medium" type="primary" class="c-theme-error-button" @click="handleBatDel"
                            :disabled="selectionRows.length === 0"
                           v-if="buttonShowList.plsc">批量删除
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    border
                    ref="GTable"
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
                <template v-slot="scope">

                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj">编辑</el-button>
                        <el-button type="text" @click="handleConfigure(scope.row)"
                                   v-if="buttonShowList.pz && scope.row.ruleType === '1'">配置</el-button>
                    </template>
                  <template v-else-if="scope.column.property === 'ruleType'">
                    <span v-if="scope.row.ruleType === '1'">基本</span>
                    <span v-else-if="scope.row.ruleType === '2'">数据字典</span>
                    <span v-else-if="scope.row.ruleType === '3'">字符串</span>
                    <span v-else-if="scope.row.ruleType === '4'">整数</span>
                    <span v-else-if="scope.row.ruleType === '5'">数字</span>
                    <span v-else-if="scope.row.ruleType === '6'">订单集合</span>
                  </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <ac-dict-dialog :visible.sync="visible" :row="row" @ok="handleSearch"></ac-dict-dialog>
        <ac-dict-item :visible.sync="itemVisible" :dict-id="dictId" :rule-name="ruleName" :rule-code="ruleCode" @ok="handleSearch"></ac-dict-item>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"
    import acDictDialog from "./modules/acDictDialog.vue";
    import acDictItem from "./modules/acDictItem.vue";
    import {getAction, postAction} from "@/api/manage";
    export default {
        name: 'acDict',
        mixins: [QueryMixins],
        components: {
            acDictDialog,
          acDictItem
        },
        data() {
            return {
                row: {},
                visible: false,
              itemVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        label: '#',
                        type: 'index',
                        width: 50
                    },
                  {
                    prop: 'ruleType',
                    label: '类型',

                  },
                    {
                        prop: 'tableName',
                        label: '表名',

                    },
                  {
                    prop: 'tableOtherName',
                    label: '表别名',

                  },
                  {
                    prop: 'fieldId',
                    label: '编码',

                  },
                  {
                    prop: 'fieldName',
                    label: '名称',

                  },
                    {
                        prop: 'createTime',
                        label: '创建时间',

                    },
                  {
                    prop: 'createBy',
                    label: '创建人',

                  },
                  {
                    prop: 'updateTime',
                    label: '修改时间',

                  },

                  {
                    prop: 'updateBy',
                    label: '修改人',

                  },

                    {
                        prop: 'action',
                        label: '操作',
                        width: 200,
                    },
                ],
                url: {
                    list: '/finance/fieldDict/queryPage',
                    deleteBatch: '/finance/fieldDict/del',

                },
              isUsePost : true,

              dictId : 0,
              ruleName : '',
              ruleCode : '',
            }
        },
        created() {

        },
        methods: {
            handleEdit(row) {
                this.row = row
                this.visible = true
            },
            handleConfigure(row) {
                this.dictId = row.id;
                this.ruleName = row.fieldName;
                this.ruleCode = row.fieldId;
                this.itemVisible = true
            },

            handleBatDel() {
                this.$confirm(`确定批量删除共${this.selectedRowKeys.length}项？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.deleteBatch, this.selectedRowKeys).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
        },
      computed:{
        buttonShowList(){
          return {
            'add':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/acDict','0','新增'),
            'plsc':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/acDict','0','删除'),
            'bj':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/acDict','1','编辑'),
            'pz':this.$btnIsShow('isystem/BaseData/ActiveConfiguration/acDict','1','配置'),
          }
        }
      }
    }
</script>
