<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="客户名称">
                        <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch" type="textarea" placeholder="多个用,分隔"></el-input>
                    </el-form-item>
                </el-col>
                  <el-col :span="6">
                    <el-form-item label="邮箱">
                      <el-input v-model="queryForm.email" @keyup.enter.native="handleSearch" ></el-input>
                    </el-form-item>
                  </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="业务员">
                        <f-select
                            :dict="'sys_user_id'"
                            v-model="queryForm.salesmanId"
                            :isNeed="queryForm.salesmanId"
                            placeholder="请选择">
                        </f-select>
                    </el-form-item>
                </el-col>
                  <el-col :span="6">
                    <el-form-item prop="source" label="客户来源">
                      <f-select

                          clearable
                          filterable
                          class="f-full-width"
                          v-model="queryForm.source"
                          :isNeed="queryForm.source"
                          :dict="'cus_source'"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
<!--                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">-->
<!--                        <el-form-item label="海外业务员">-->
<!--                            <f-select-->
<!--                                    v-model="queryForm.oceanSalesmanId"-->
<!--                                    :isNeed="queryForm.oceanSalesmanId"-->
<!--                                    :dict="'sys_user_id'"></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
            <el-button
                type="primary"
                size="medium"
                @click="handleBatApproval"
                :disabled="selectionRows.length === 0"
                v-if="buttonShowList.jdB"
            >批量解冻</el-button>
            <el-button
                type="primary"
                size="medium"
                class="c-theme-error-button"
                @click="handleBatDel"
                :disabled="selectionRows.length === 0"
                v-if="buttonShowList.delB"
            >批量删除</el-button>
        </div>
        <div class="c-alert f-m-b-10">
            已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
            <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            tableHeight="62vh"
            :isRecordTableScroll="true"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button
                        type="text"
                        @click="handleThaw(scope.row)"
                        v-if="buttonShowList.jd"
                    >解冻</el-button>
                    <el-button
                        type="text"
                        @click="handleDel(scope.row)"
                        v-if="buttonShowList.del"
                    >删除</el-button>
                </template>
                <template v-else-if="scope.column.property === 'grade'">
                    <span>{{parseDict(scope.row.grade,'customer_type2')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'salesmanId'">
                    <span>{{parseDict(scope.row.salesmanId,'salesmanId')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'username'">
                    <a type="text" @click.prevent="handleEdit(scope.row)" style="color:#57a3f3;">{{scope.row.username}}</a>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        </el-card>

    </div>
</template>

<script>
import { putAction, deleteAction,getallsalesmans } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
export default {
    name: 'Frozen',
    mixins: [QueryMixins],
    components: {

    },
    data() {
        return {
            dictCodes:['customer_type2','salesmanId'],
            visible: false,
            row: {},
            columns: [
                {
                    type: 'selection',
                    width: 50
                },

                {
                    prop: 'username',
                    label: '客户名称',
                    sortable: 'custom',
                },
                {
                    prop: 'name',
                    label: '联系人',
                    sortable: 'custom',
                },
                {
                    prop: 'grade',
                    label: '客户等级',
                    sortable: 'custom',
                },
                {
                    prop: "doc",
                    label: "客服",

                },
                {
                    prop: 'salesmanId',
                    label: '业务员',
                    sortable: 'custom',
                },
                // {
                //     prop: "oceanSalesman",
                //     label: "海外业务员",
                //     sortable: "custom",
                // },
                {
                    prop: 'need',
                    label: '客户需求',
                    sortable: 'custom',
                },
                {
                    prop: 'updateTime',
                    label: '冻结时间',
                    sortable: 'custom',
                },
                {
                    prop: 'createTime',
                    label: '注册时间',
                    sortable: 'custom',
                },
                {
                    prop: 'action',
                    label: '操作',
                    width: 100,
                },
            ],
            url: {
                list: '/customer/djcustomerList',
                deleteBatch: '/customer/deleteBatch',
                jiedong: '/customer/unfreeze',
                batchjiedong: '/customer/unfreezeBatch',
                delbyid:'/customer/delete',


            },
          salesmanlist:[],
          namelist:[],
        }
    },
    methods: {
      handleSelectionChange(selection) { // 多选
        let arr = [];
        this.namelist = [];
        for(let i = 0; i < selection.length; i++) {
          arr.push(selection[i]['id'])
          this.namelist.push(selection[i]['username'])
        }
        this.selectionRows = selection
        this.selectedRowKeys = [...arr]
      },
        handleEdit(row) {
            this.row = row
            this.visible = true
        },
        handleDel({id,username}) {
            this.$confirm(`确定删除${username}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.delbyid, {id}).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleQuery()
                })
            }).catch(() => {

            })
        },
        handleBatApproval() {


          this.$confirm(`确定将【${this.namelist.join(',')}】解冻?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.batchjiedong+"?ids="+this.selectedRowKeys.join(',')).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleQuery()
                })
            }).catch(() => {

            })
        },
        handleBatDel() {

            this.$confirm(`确定删除${this.namelist.join(',')}？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleSearch()
                })
            }).catch(() => {

            })
        },
        handleThaw(row) {
          this.$confirm(`确定解冻${row.username}？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
            closeOnClickModal: false,
          }).then(() => {
            deleteAction(this.url.jiedong, {id: row.id}).then(res => {
                this.$message({
                    type: 'success',
                    message: res.message
                })
                this.handleSearch()
            })
          }).catch(() => {

          })
        },

    },
  created() {
      this.queryForm.initSalesman=1;
  },
  computed : {
    buttonShowList() {
      return {
        'jdB':this.$btnIsShow('customer/Frozen','0','批量解冻'),
        'delB':this.$btnIsShow('customer/Frozen','0','批量删除'),
        'jd':this.$btnIsShow('customer/Frozen','1','解冻'),
        'del':this.$btnIsShow('customer/Frozen','1','删除'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
