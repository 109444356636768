<template>
    <div class="main-full-content">
        <el-form
                class="input_top"
                ref="form"
                :model="queryForm"
                label-width="120px"
                size="medium"
        >
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.userName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作员">
                            <f-select
                                    placeholder="请选择操作员"
                                    v-model="queryForm.operateId"
                                    :is-need="queryForm.operateId"
                                    dict="operationsId"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="72vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button
                                type="text"
                                :loading="loading"
                                @click="downloadLabel(scope.row)"
                                v-if="scope.row.labelDownloadStatus == 0"
                        >未下载
                        </el-button
                        >
                        <el-button
                                type="text"
                                :loading="loading"
                                @click="downloadLabel(scope.row)"
                                v-if="scope.row.labelDownloadStatus == 1"
                        >已下载
                        </el-button
                        >
                        <el-divider direction="vertical"/>
                        <el-button type="text" @click="printPdf(scope.row)"
                        >打印
                        </el-button>
                        <el-divider direction="vertical"/>
                        <el-button type="text" @click="readPdf(scope.row)"
                        >预览
                        </el-button>
                        <el-divider direction="vertical"/>
                        <el-button type="text" @click="handleDetail(scope.row)"
                        >查看
                        </el-button
                        >
                        <el-divider direction="vertical"/>
                        <el-popconfirm
                                title="是否确认进行取消操作？"
                                confirm-button-text="确认"
                                cancel-button-text="不用了"
                                icon="el-icon-info"
                                icon-color="red"
                                @confirm="handleUpsCancel(scope.row)"
                        >
                            <el-button slot="reference" type="text"
                            > 取消
                            </el-button>
                        </el-popconfirm>
                        <el-divider direction="vertical"/>
                        <el-button type="text" @click="updateUpsUrl(scope.row) " v-if="scope.row.type == '5'"
                        >更新
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'status'">
                        {{scope.row.status == '1' ? '正常' : '取消'}}
                    </template>
                    <template v-else-if="scope.column.property === 'trackingNumbers'">
                        <span :style="scope.row.abnormalNum > 0 ? 'color: red;' : ''">{{scope.row.trackingNumbers}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'solicitation'">
            <span>{{
                parseDict(scope.row.solicitation, "fba_solicitation")
              }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
                parseDict(scope.row.transportStatus, "transportStatus")
              }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="intoDocpage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'userName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.userName}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <ship-detail ref="modalForm"></ship-detail>
    </div>

    <!-- table区域-begin -->

    <!-- table区域-end -->
    <!-- 订单详情-->
</template>

<script>
    import {getAction, postAction, postBlobAction,} from "@/api/manage";
    import ShipDetail from "./modules/ShipDetail";
    import QueryMixins from "../../../mixins/QueryMixins";

    export default {
        name: "TableDemo",
        mixins: [QueryMixins],
        components: {
            ShipDetail,
        },
        data() {
            return {
                dictCodes: ["transportStatus", "fba_solicitation"],
                loading: false,
                queryForm: {
                    upsStatus: "1",
                },
                filterForm: {},
                tableData: [],
                OrderNo: null,
                expandedRowKeys: [],
                ysfsDictOptions: [], // 运输方式字典
                lhfsDictOptions: [], // 航线名称字典
                confirmLoading: false,
                id: " ",
                description: "列表展开子表Demo",
                ipagination: {
                    current: 1,
                    pageSize: 50,
                    pageSizeOptions: ["10", "50", "100"],
                    showTotal: (total, range) => {
                        return range[0] + "-" + range[1] + " 共" + total + "条";
                    },
                    showQuickJumper: true,
                    showSizeChanger: true,
                    total: 0,
                },
                // 列表表头
                columns: [
                    {
                        label: "主订单号",
                        align: "center",
                        prop: "orderNo",
                        width: 130,
                        //   fixed: 'left',
                    },
                    {
                        label: "拆单号",
                        align: "center",
                        //   width: 130,
                        prop: "shipOrder",
                        key: "shipOrder",
                    },
                    {
                        label: "打单时间",
                        align: "center",
                        prop: "creatData",
                        //   width: 100,
                        customRender: (text, record, index) => {
                            var date = new Date(text);
                            var YY = date.getFullYear() + "-";
                            var MM =
                                (date.getMonth() + 1 < 10
                                    ? "0" + (date.getMonth() + 1)
                                    : date.getMonth() + 1) + "-";
                            var DD =
                                date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

                            return YY + MM + DD;
                        },
                    },
                    {
                        label: "客户名称",
                        align: "center",
                        prop: "userName",
                        width: 230,
                    },
                    {
                        label: "承运商",
                        width: 75,
                        align: "center",
                        prop: "carriers",
                    },
                    {
                        label: "发到",
                        align: "center",
                        prop: "sendAddress",
                        width: 100,
                    },
                    {
                        label: "件数",
                        align: "center",
                        prop: "parcelNum",
                        width: 70,
                    },
                    {
                        label: "已付价格",
                        align: "center",
                        prop: "finalPrice",
                        width: 90,
                    },
                    {
                        label: "状态",
                        align: "center",
                        prop: "status",
                        width: 60,
                    },
                    {
                        label: "跟踪单号",
                        prop: "trackingNumbers",
                        align: "center",
                        //   width: 160,
                    },
                    {
                        label: "操作",
                        prop: "action",
                        align: "center",
                        width: 260,
                        fixed: 'left',
                        scopedSlots: {customRender: "action"},
                    },
                ],
                // 分页参数
                type: "radio",
                url: {
                    list: "/interface/label/selecUpsHasUpsShipList",
                    Label: "/interface/label/Label",
                    PostManLabel: "/interface/label/getPostManLabel",
                    downLoadFccLable: "/interface/fcc/downLoadFccLable",
                    fccCancel: "/interface/fcc/fccCancel",
                    UpsLabel2: "/interface/label/getUpsLabel",
                    upsOriginLabel: "/interface/label/getUpsOriginLabel",
                    printPdf: "/interface/label/printPdf",
                    upsCancel: "/interface/label/upsCancel",
                    ups2Cancel: "/interface/label/upsCancelOrder",
                    ups2Update: "/interface/label/upsUpdateOrder",
                    batchDown: "/interface/label/batchDown",
                    PostManCancel: "/interface/label/PostManLabelVoid",
                    upsOriginCancel: "/interface/label/upsOriginCancel",
                    getJhdCancel: "/interface/jhd/getJhdCancel",
                    downLoadJhdLable: "/interface/jhd/downLoadJhdLable",
                },
            };
        },
        computed: {
            currentId() {
                return this.id;
            },
        },
        created() {
        },
        methods: {
            initCusPage({cusId}) {
                if (cusId) {
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }

            },
            intoDocpage(orderNo) {

                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            batchDown() {
                // this.selectedRowKeys = [];
                // this.selectionRows = [];
                this.confirmLoading = true;
                postAction(this.url.batchDown, {
                    shipOrderList: this.selectedRowKeys,
                    upsStatus: "1",
                })
                    .then((res) => {
                        if (res.success) {
                            this.downloadFileByBase64(
                                "data:application/pdf;base64," + res.result,
                                Date.parse(new Date())
                            );
                            this.$message.warning(res.message);
                        } else {
                            this.$message.warning(res.message);
                        }
                    })
                    .finally(() => {
                        this.confirmLoading = false;
                        // this.loadData()
                    });
            },
            batchPDF() {
                this.confirmLoading = true;
                postAction(this.url.batchDown, {shipOrderList: this.selectedRowKeys})
                    .then((res) => {
                        if (res.success) {
                            var myBlob = this.dataURLtoBlob(
                                "data:application/pdf;base64," + res.result
                            );
                            var myUrl = URL.createObjectURL(myBlob);
                            this.FileprintPdf(myUrl, res.orderNo);

                            this.$message.warning(res.message);
                        } else {
                            this.$message.warning(res.message);
                        }
                    })
                    .finally(() => {
                        this.confirmLoading = false;
                        // this.loadData()
                    });
            },
            onChangeCreateTimeStart(value, dateString) {
                if (value.length > 0) {
                    this.queryParam.creatStartData = dateString[0];
                    this.queryParam.creatEndData = dateString[1];
                } else {
                    this.queryParam.creatStartData = "";
                    this.queryParam.creatEndData = "";
                }
                return dateString;
            },
            handleSearch() {
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.operatorId = null
                console.log(this.queryForm,1111111111)
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            async handleQuery() {
                this.loading = true
                this.queryForm.upsStatus = '1'
                let form = {...this.queryForm, ...this.filterForm, ...this.initData}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else if (this.enabledCreatetime) {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                    //排序
                    //提货中查询
                    if (this.queryForm.column === 'sort') {
                        this.$set(params, 'column', 'sort')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                await getAction(this.url.list, params).then((res) => {
                    console.log(res)
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        if (res.result) {
                            const {records, total} = res.result
                            this.tableData = records || res.result;

                            this.ipagination && (this.ipagination.total = total || res.result.length)
                        } else {

                            this.tableData = [];

                            this.ipagination = 0;
                        }

                    }
                }).finally(() => {
                    this.loading = false;
                    this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                })
            },
            loadData(arg) {
                if (!this.url.list) {
                    this.$message.error("请设置url.list属性!");
                    return;
                }
                //加载数据 若传入参数1则加载第一页的内容
                if (arg === 1) {
                    this.ipagination.current = 1;
                    this.ipagination.pageSize = 50;
                }
                //var params = this.getQueryParams()//查询条件
                var params = this.queryForm; //查询条件
                params.upsStatus = "1";
                this.confirmLoading = true;

                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            this.tableData = res.result.list;
                            this.ipagination.total = res.result.totalCount;
                        }
                        if (res.code === 510) {
                            this.$message.warning(res.message);
                        }
                    })
                    .finally(() => {
                        this.confirmLoading = false;
                    });
            },
            handleUpsCancel(record) {
                this.loading = true;
                if (record.type == "1") {
                    postAction(this.url.upsCancel, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        orderNo: record.orderNo,
                    })
                        .then((res) => {
                            if (res.success) {
                                this.$message.warning(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                            this.handleQuery();
                            // this.loadData()
                        });
                } else if (record.type == "2") {
                    postAction(this.url.PostManCancel, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        orderNo: record.orderNo,
                    })
                        .then((res) => {
                            if (res.success) {
                                this.$message.warning(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                            this.handleQuery();
                            // this.loadData()
                        });
                } else if (record.type == "3") {
                    //UPS官方
                    postAction(this.url.upsOriginCancel, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        orderNo: record.orderNo,
                    })
                        .then((res) => {
                            if (res.success) {
                                this.$message.warning(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                            this.handleQuery();
                            // this.loadData()
                        });
                } else if (record.type == '5') {
                    //UPS V2
                    postAction(this.url.ups2Cancel, {
                        'labelId': record.labelId,
                        'shipOrder': record.shipOrder,
                        'orderNo': record.orderNo
                    }).then((res) => {
                        console.log(res)
                        if (res.success) {
                            this.$message.success(res.message)
                        } else {
                            this.$message.warning(res.message)
                        }

                    }).finally(() => {
                        this.confirmLoading = false
                        this.loading = false;
                        // this.loadData();
                        this.handleQuery();
                    })
                } else if (record.type == '4') {
                    //乾森
                    postAction(this.url.fccCancel, {
                        'labelId': record.labelId,
                        'shipOrder': record.shipOrder,
                        'orderNo': record.orderNo
                    }).then((res) => {
                        if (res.success) {
                            this.$message.info(res.message)
                        }
                    }).finally(() => {
                        this.confirmLoading = false
                        this.loading = false;
                        this.handleQuery();
                    })
                } else if (record.type == '6') {
                    //京华达
                    postAction(this.url.getJhdCancel, {
                        'labelId': record.labelId,
                        'shipOrder': record.shipOrder,
                        'orderNo': record.orderNo
                    }).then((res) => {
                        if (res.success) {
                            this.$message.info(res.message)
                        }
                    }).finally(() => {
                        this.confirmLoading = false
                        this.loading = false;
                        this.handleQuery();
                    })
                } else {
                    this.loading = false;
                }
            },
            modalFormOk(formData) {
                // this.loadData();
            },
            readPdf(record) {
                if (record.url != '') {
                    window.open(record.url);
                } else if (record.type == "6") {
                    this.confirmLoading = true;
                    postAction(this.url.downLoadJhdLable, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.success) {
                                window.open(res.url);
                                this.confirmLoading = false;
                            }
                            if (res.code === 510) {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.confirmLoading = false;
                        });

                }


                else if (record.type == "4") {
                    this.confirmLoading = true;
                    postAction(this.url.downLoadFccLable, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.success) {
                                window.open(res.url);
                                this.confirmLoading = false;
                            }
                            if (res.code === 510) {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.confirmLoading = false;
                        });

                } else if (record.type == "5") {
                    this.confirmLoading = true;
                    postAction(this.url.UpsLabel2, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.success) {
                                window.open(res.url);
                                this.confirmLoading = false;
                            }
                            if (res.code === 510) {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.confirmLoading = false;
                        });

                } else {
                    if (record.type == "1") {
                        this.confirmLoading = true;
                        postAction(this.url.Label, {
                            labelId: record.labelId,
                            shipOrder: record.shipOrder,
                        })
                            .then((res) => {
                                if (res.success) {
                                    window.open(res.url);
                                }
                                if (res.code === 510) {
                                    this.$message.warning(res.message);
                                }
                            })
                            .finally(() => {
                                this.confirmLoading = false;
                            });
                    }
                }
            },

            FileprintPdf(url, name) {
                var ifr = document.createElement("iframe");
                ifr.style.frameborder = "no";
                ifr.style.display = "none";
                ifr.style.pageBreakBefore = "always";
                ifr.setAttribute("id", "printPdf" + name);
                ifr.setAttribute("name", "printPdf" + name);
                ifr.src = url;
                document.body.appendChild(ifr);
                this.doPrint("printPdf" + name);
                window.URL.revokeObjectURL(ifr.src); // 释放URL 对象
            },
            doPrint(val) {
                var ordonnance = document.getElementById(val).contentWindow;
                setTimeout(() => {
                    // window.print()
                    ordonnance.print();
                    this.pdfLoading = false;
                }, 100);
            },
            printPdf(record) {
                this.confirmLoading = true;
                if (record.type == "1") {
                    postAction(this.url.printPdf, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.success) {
                                // this.FileprintPdf('data:application/pdf;base64,'+res.label_base64,res.orderNo);

                                var myBlob = this.dataURLtoBlob(
                                    "data:application/pdf;base64," + res.label_base64
                                );
                                var myUrl = URL.createObjectURL(myBlob);
                                this.FileprintPdf(myUrl, res.orderNo);
                            }
                            if (res.code === 510) {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            // this.loadData()
                            this.confirmLoading = false;
                        });
                } else if (record.type == "2") {
                    // 小马的

                    postAction(this.url.PostManLabel, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.Status == "200") {
                                var myBlob = this.dataURLtoBlob(
                                    "data:application/pdf;base64," + res.label_base64[0]
                                );
                                var myUrl = URL.createObjectURL(myBlob);
                                this.FileprintPdf(myUrl, res.orderNo);
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            // this.loadData()
                            this.confirmLoading = false;
                        });
                } else if (record.type == "4") {
                    // FCC的
                    postAction(this.url.downLoadFccLable, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.Status == "200") {
                                let LabelUrl = res.url;
                                if (null == LabelUrl) {
                                    this.confirmLoading = false;
                                }
                                var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                                newWindow.location.href = LabelUrl;
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            // this.loadData()
                            this.confirmLoading = false;
                        });
                } else if (record.type == "5") {
                    // UPS V2
                    postAction(this.url.UpsLabel2, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.Status == "200") {
                                let LabelUrl = res.url;
                                if (null == LabelUrl) {
                                    this.confirmLoading = false;
                                }
                                var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                                newWindow.location.href = LabelUrl;
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            // this.loadData()
                            this.confirmLoading = false;
                        });
                }else if (record.type == "6") {
                    // FCC的
                    postAction(this.url.downLoadJhdLable, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    })
                        .then((res) => {
                            if (res.Status == "200") {
                                let LabelUrl = res.url;
                                if (null == LabelUrl) {
                                    this.confirmLoading = false;
                                }
                                var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                                newWindow.location.href = LabelUrl;
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            // this.loadData()
                            this.confirmLoading = false;
                        });
                }
            },
            downloadLabel(record) {
                this.$message.warning("开始下载，请稍等");
                this.confirmLoading = true;
                console.log(JSON.stringify(record))
                if (record.type == "1") {
                    postAction(this.url.Label, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        labelDownloadStatus: "1",
                    })
                        .then((res) => {
                            if (res.success) {
                                this.downloadFileByBase64(
                                    "data:application/pdf;base64," + res.label_base64,
                                    res.orderNo
                                );
                            }
                            if (res.code === 510) {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            console.log("开始刷新");
                            this.handleQuery();
                            this.confirmLoading = false;
                        });
                } else if (record.type == "2") {
                    postAction(this.url.PostManLabel, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        labelDownloadStatus: "1",
                    })
                        .then((res) => {
                            if (res.Status == "200") {
                                this.downloadFileByBase64(
                                    "data:application/pdf;base64," + res.label_base64[0],
                                    res.orderNo
                                );
                            } else {
                                this.$message.warning(res.message);
                            }
                        })
                        .finally(() => {
                            this.handleQuery();
                            this.confirmLoading = false;
                        });
                } else if (record.type == "3") {
                    postBlobAction(this.url.upsOriginLabel, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        labelDownloadStatus: "1",
                    })
                        .then((res) => {
                            let url = window.URL.createObjectURL(new Blob([res]), {
                                type: "application/pdf",
                            });
                            let link = document.createElement("a");
                            link.style.display = "none";
                            link.href = url;
                            link.setAttribute("download", "Label-" + record.shipOrder + ".pdf");
                            document.body.appendChild(link);
                            link.click();
                            this.$message.success("请注意下载的文件");
                        })
                        .finally(() => {
                            this.handleQuery();
                            this.confirmLoading = false;
                        });
                } else if (record.type == "4") {

                    postAction(this.url.downLoadFccLable, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        labelDownloadStatus: "1",
                    })
                        .then((res) => {
                            if (res.url == null) {
                                this.confirmLoading = false;
                            }
                            var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                            let LabelUrl = res.url;
                            newWindow.location.href = LabelUrl;
                        })
                        .finally(() => {
                            this.handleQuery();
                            this.confirmLoading = false;
                        });
                }  else if (record.type == "6") {

                    postAction(this.url.downLoadJhdLable, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                        labelDownloadStatus: "1",
                    })
                        .then((res) => {
                            if (res.url == null) {
                                this.confirmLoading = false;
                            }
                            var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                            let LabelUrl = res.url;
                            newWindow.location.href = LabelUrl;
                        })
                        .finally(() => {
                            this.handleQuery();
                            this.confirmLoading = false;
                        });
                }

                else if (record.type == "5") {
                    // UPS V2
                    postAction(this.url.UpsLabel2, {
                        labelId: record.labelId,
                        shipOrder: record.shipOrder,
                    }).then((res) => {
                        if (res.Status == "200") {
                            let LabelUrl = res.url;
                            if (null == LabelUrl) {
                                this.confirmLoading = false;
                            }
                            var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
                            newWindow.location.href = LabelUrl;
                        } else {
                            this.$message.warning(res.message);
                        }
                    }).finally(() => {
                        // this.loadData()
                        this.confirmLoading = false;
                    });
                }
            },

            updateUpsUrl(record) {
                this.confirmLoading = true;
                if (record.type == "5") {
                    postAction(this.url.ups2Update, {
                        labelId: record.labelId,
                    })
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message)
                            } else {
                                this.$message.error(res.message)
                            }

                        })
                        .finally(() => {
                            // this.loadData()
                            this.confirmLoading = false;
                        });
                }
            },

            downloadFileByBase64(base64, name) {
                var myBlob = this.dataURLtoBlob(base64);
                var myUrl = URL.createObjectURL(myBlob);
                this.downloadFile(myUrl, name);
            },

            downloadFile(url, name) {
                var a = document.createElement("a");
                a.setAttribute("href", url);
                a.setAttribute("download", name);
                a.setAttribute("target", "_blank");
                let clickEvent = document.createEvent("MouseEvents");
                clickEvent.initEvent("click", true, true);
                a.dispatchEvent(clickEvent);
                //  this.confirmLoading = false;
            },
            dataURLtoBlob(dataurl) {
                var arr = dataurl.split(","),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], {type: mime});
            },
            handleDetail(record) {
                this.OrderNo = record.orderNo;
                this.$refs.modalForm.edit(record);
                this.$refs.modalForm.title = "发货运单详情";
                this.$refs.modalForm.disableSubmit = false;
            },
            trackingNoDetail: function (record) {
                this.OrderNo = record.orderNo;
                record.ClaimStatus = "0";
                this.$refs.trackingNomodalForm.edit(record);
                this.$refs.trackingNomodalForm.title = "追踪单号详情";
                this.$refs.trackingNomodalForm.disableSubmit = false;
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleTableChange({prop, order}) {
                if (order === null) {
                    this.handleResetSorter();
                } else {
                    this.$set(this.isorter, "prop", prop);
                    this.$set(this.isorter, "order", order);
                }
                this.handleSearch();
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1;
                this.ipagination.pageSize = val;
                this.handleQuery();
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val;
                this.handleQuery();
            },
        },
    };
</script>
<style scoped>
</style>
