<template>
    <el-dialog
            title="订单列表"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <!--:disabled="selectedRowKeys.length === 0"-->
        <el-button
                type="primary"
                size="medium"
                @click="handleAddOrder"
                class="margin_right_10"
                :disabled="selectionRows.length === 0"
                v-if="buttonShowList.add">加货
        </el-button>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
            <template v-slot="scope">
                                    <template v-if="scope.column.property === 'action'">
                                        <el-button type="text" @click="hanleKnee(scope.row)"
                                                   v-if="buttonShowList.tih && scope.row.canAdd==='0'"
                                        >踢货</el-button>
                                    </template>

                <template v-else-if="scope.column.property === 'orderNo'">
                    <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                </template>


            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";
    import {getAction} from "../../../api/manage";
    export default {
        name: 'otherWarehouseOutOrderListModule',
        mixins: [QueryMixins],
        props: {
            visible: {
                type: Boolean
            },
            busId:{
                type:String,
                default:''
            },
            busNo:{
                type:String,
                default:''
            },
        },
        data() {
            return {
                isUsePost:true,
                disableMixinMounted:true,
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "lclPlaceName",
                        label: "拼柜仓库",
                    },
                    {
                        prop: "channel",
                        label: "渠道名称",
                    },
                    {
                        prop: "number",
                        label: "实际件数",
                    },
                    {
                        prop: "weight",
                        label: "实际重量",
                    },
                    {
                        prop: "volume",
                        label: "实际体积",
                    },
                    {
                        prop: "createTime",
                        label: "订单录入时间",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },

                ],
                form: {},
                formLabelWidth:'100px',

                url: {
                    addWarehouseStockOrderRelation: '/warehouse/stock/addWarehouseStockOrderRelation',
                    //踢货
                    delWarehouseStockOrderRelation:'/warehouse/stock/delWarehouseStockOrderRelation',
                    //查询
                    list:'/warehouse/stock/queryOtherWarehouseOutNeedAddData',
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.busId) {
                    //勾选的订单号进行赋值
                    this.queryForm.busId = this.busId;
                    this.handleQuery();
                }
            },
        },
        methods: {

            handleAddOrder(){
                //加货
                let flag = true;
                this.selectionRows.forEach(r=>{
                    r.busId = this.busId;
                    r.busNo = this.busNo;
                    if (r.canAdd === '0') {
                        flag = false;
                    }
                })
                if (!flag) {
                    this.$message({
                        type: 'warning',
                        message: '不可以选择已经其他出库的订单'
                    })
                    return false;
                }else {
                    postAction(this.url.addWarehouseStockOrderRelation, this.selectionRows).then((res) => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose();
                        this.$emit('ok');
                    })
                }
            },
            hanleKnee(row){
                this.$confirm(`确定踢货${row.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.delWarehouseStockOrderRelation,row)
                    .then(res=>{
                        this.$message.success(res.message);
                        this.handleQuery();
                    })
                })
            },

            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('warehouse2.0/otherWarehouseOutOrderListModule','0','加货'),
            'tih':this.$btnIsShow('warehouse2.0/otherWarehouseOutOrderListModule','1','踢货'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
