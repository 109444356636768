<template>
    <div>
        <f-upload v-model="file" list-type="picture-card" :dir="'content'"></f-upload>
        <!-- <vue-ueditor-wrap
            v-model="content"
            :config="myConfig"
            :destroy="true"
            @ready="handleReady"></vue-ueditor-wrap> -->
    </div>
</template>

<script>
    import VueUeditorWrap from 'vue-ueditor-wrap'
    import FUpload from '@/components/common/FUpload'
    export default {
        name: 'Try',
        components: {
            FUpload,
            VueUeditorWrap
        },
        data() {
            return {
                file: [],
                editor: null,
                content: '',
                myConfig: {
                    autoHeightEnabled: false,
                    initialFrameHeight: 340,
                    initialFrameWidth: '100%',
                    // serverUrl:  'http://35.201.165.105:8000/controller.php'
                }
            }
        },
        methods: {
            handleReady(editorInstance) {
                this.editor = editorInstance
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
