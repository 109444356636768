<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      label-width="120px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="船公司代码">
              <el-input
                v-model="queryForm.shortname"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="船公司英文名称">
              <el-input
                v-model="queryForm.englishName"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="船公司中文名">
              <el-input
                v-model="queryForm.chinaseName"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"
          :disabled="$btnIsDisabled('isystem/shippingCompany', '0', '新增')"
          v-if="buttonShowList.add"
          >新增
        </el-button>
        <el-button
          size="medium"
          type="primary"
          class="c-theme-error-button"
          @click="handleBatDel"
          :disabled="selectionRows.length === 0"
          v-if="buttonShowList.plsc"
          >批量删除
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList.bj"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleDel(scope.row.id)"
              v-if="buttonShowList.sc"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'officialWebsite'">
            <el-button
              type="text"
              @click="windowOpen(scope.row.officialWebsite)"
            >
              {{ scope.row.officialWebsite }}
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'goodsTracking'">
            <el-button type="text" @click="windowOpen(scope.row.goodsTracking)">
              {{ scope.row.goodsTracking }}
            </el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <add-shipping-company
      :visible.sync="visible"
      :row="row"
      @ok="handleSearch"
    ></add-shipping-company>
  </div>
</template>

<script>
import { deleteAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import AddShippingCompany from "./modules/AddShippingCompany";
import EditTreeDialog from "./modules/EditTreeDialog";
import RoleBtnPermissionDialog from "./modules/RoleBtnPermissionDialog";

export default {
  name: "shippingCompany",
  mixins: [QueryMixins],
  components: {
    AddShippingCompany,
    EditTreeDialog,
    RoleBtnPermissionDialog,
  },
  data() {
    return {
      visible: false,
      row: {},
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 100,
        },
        {
          prop: "shortname",
          label: "船公司代码",
          sortable: "custom",
        },
        {
          prop: "englishName",
          label: "船公司英文名称",
          sortable: "custom",
        },
        {
          prop: "chinaseName",
          label: "船公司中文名",
          sortable: "custom",
        },
        {
          prop: "abbreviation",
          label: "船公司简称",
          sortable: "custom",
        },
        {
          prop: "officialWebsite",
          label: "官方网站",
          sortable: "custom",
        },
        {
          prop: "goodsTracking",
          label: "货物跟踪",
          sortable: "custom",
          width: 180,
        },
        {
          prop: "introduction",
          label: "船公司简介",
          sortable: "custom",
          width: 180,
        },
        {
          prop: "companyAddress",
          label: "公司地址",
          sortable: "custom",
          width: 180,
        },
      ],
      url: {
        list: "/sys/supplierShippCompany/list",
        // list: "/sys/dict/list",
        delete: "/sys/supplierShippCompany/delete",
        deleteBatch: "/sys/dict/deleteBatch",
      },
    };
  },
  created() {},
  methods: {
    windowOpen(url) {
      window.open(url);
    },
    handleDel(id) {
      this.$confirm("确定删除该船公司?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    handleEdit(row) {
      this.row = row;
      this.visible = true;
    },
    handleBatDel() {
      this.$confirm(
        `确定批量删除共${this.selectedRowKeys.length}项？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
  },
  computed:{
    buttonShowList(){
      return {
        'add':this.$btnIsShow('isystem/shippingCompany', '0', '新增'),
        'plsc':this.$btnIsShow('isystem/shippingCompany', '0', '批量删除'),
        'bj':this.$btnIsShow('isystem/shippingCompany', '1', '编辑'),
        'sc':this.$btnIsShow('isystem/shippingCompany', '1', '删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
