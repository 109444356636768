<template>
  <div class="flex_center">
    <div class="system_updates_box">
      <div class="f-m-b-75">系统升级中，预计发布时间15分钟</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.system_updates_box {
  background-image: url('../assets/systemUpdatesImg.png');
  width: 480px;
  height: 508px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: contain;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
  margin-top: 75px;
}
</style>