<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      label-width="80px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="推广渠道" label-width="60px">
              <el-input v-model="queryForm.itemText">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="描述" label-width="60px">
              <el-input v-model="queryForm.description">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"
          v-if="buttonShowList['新增']"
          >新增
        </el-button>
        <el-button
          :disabled="selectionRows.length === 0"
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleDel()"
          v-if="buttonShowList['批量删除']"
          >批量删除
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList['编辑']"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleDel(scope.row.id)"
              v-if="buttonShowList['删除']"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'status'">
            <span v-if="scope.row.status =='1'"> 是</span>
            <span v-else> 否</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <edit-marking-cus-source-dialog
      :visible.sync="addVisible"
      :row="row"
      @ok="handleSearch"
    ></edit-marking-cus-source-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditMarkingCusSourceDialog from "@/views/forestMarket/components/EditMarkingCusSourceDialog";


export default {
  name: "BdSuccessClearedCase",
  mixins: [QueryMixins],
  components: {
    EditMarkingCusSourceDialog,
  },
  data() {
    return {
      dictCodes: [
        "cus_source",
      ],
      day32:2764800000,
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeE) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return !(new Date(this.queryForm.createTimeE).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.createTimeE).getTime() - this.day32);
          }
        },
      },
      editEndOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeS) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return !(new Date(this.queryForm.createTimeS).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.createTimeS).getTime());
          }
        },
      },
      loading: false,
      addVisible: false,
      authVisible: false,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 90,
        },
        {
          prop: "itemText",
          label: "推广渠道名称",
          width: 100,
        },
        {
          prop: "sortOrder",
          label: "排序",
          width: 120,
        },
        {
          prop: "createBy",
          label: "创建人",
          width: 100,
        },
        {
          prop: "status",
          label: "启用",
          width: 100,
        },
        {
          prop: "description",
          label: "描述",
          width: 200,
        },
      ],
      url: {
        list: "/sys/bdCusSource/list",
        delete: "/sys/bdCusSource/delete",
        deleteBatch: "/sys/bdCusSource/delete",
      },
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importSuccessCase, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.handleQuery();
        }
      });
    },
    handleDel(id) {
      this.$confirm("确定删除该推广渠道?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          if(!id)  id = this.selectedRowKeys.join(",")
          deleteAction(this.url.delete, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    importCustomer() {
      this.$confirm("确定导入到公海客户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.importCustomer, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row) {
      this.row = row;
      this.addVisible = true;
    },
  },
  computed:{
    buttonShowList(){
      return {
        '新增':this.$btnIsShow('forestMarket/MarketingCusSource', '0', '新增'),
        '批量删除':this.$btnIsShow('forestMarket/MarketingCusSource', '1', '批量删除'),
        '编辑':this.$btnIsShow('forestMarket/MarketingCusSource', '0', '编辑'),
        '删除':this.$btnIsShow('forestMarket/MarketingCusSource', '0', '删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
