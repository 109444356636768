<template>
  <div class="main-full-content">
    <el-form class="multiline_select" ref="queryForm" :model="queryForm" label-width="86px">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="6">
            <el-form-item label="海关编码">
              <el-input v-model="queryForm.hsCode" @keyup.enter.native="handleSearchSelf"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="中文品名">
              <el-input v-model="queryForm.productName" @keyup.enter.native="handleSearchSelf"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="国家">
              <el-input v-model="queryForm.country" @keyup.enter.native="handleSearchSelf"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="20px">
              <el-button size="medium" type="primary" icon="el-icon-search"  @click="handleSearchSelf"
              >查询
              </el-button>
              <el-button size="medium" plain @click="()=>{ productNameStr = null ; handleReset() }">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card>
      <div class="f-p-b-15">
        <el-upload
                class="upload-demo margin_right_10"
                action=""
                :http-request="importOperation"
                :show-file-list="false"
                :before-upload="beforeUpload"
                accept=".xls,.xlsx">
          <el-button type="primary" v-if="buttonShowList.imClCase">导入清关要求案例</el-button>
        </el-upload>
        <el-button v-if="buttonShowList.doClCase" type="primary" size="medium" class="f-m-l-10"
                   @click="downloadModel(url.downloadModel, '清关要求案例模板下载')">清关要求案例模板下载
        </el-button>
      </div>

      <f-table ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor" :columns="columns"
        :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="61vh">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <!--<el-button type="text" @click="() => {visible = true}">新增-->
            <!--</el-button>-->
            <!--<el-button type="text">删除-->
            <!--</el-button>-->
            <el-button type="text"  @click="handleEdit(scope.row)" v-if="buttonShowList.edit">编辑
            </el-button>
            <el-button type="text"  @click="caculateRax(scope.row)" v-if="buttonShowList.taxJs">税费计算
            </el-button>
            <el-button type="text"  @click="addProductDialog(scope.row)" v-if="buttonShowList.addChPro">添加品名
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'clearRequirements'">
            <div v-if="scope.row.clearRequirementsList">
              <!-- <span>{{ scope.row.demand }}</span> -->
              <el-button class="f-m-l-10" type="text"
                @click="handleDetail(scope.row.clearRequirementsList, '清关要求详情-' + scope.row.hsCode,'0')">详情</el-button>
            </div>
            <!-- <span v-else>{{ scope.row.demand }}</span> -->
          </template>
          <template v-else-if="scope.column.property === 'chineseProductName'">
            <div style="display: flex;flex-direction: row;justify-content: center">
              <div v-if="scope.row.chineseProductName">
                <span v-for="(item,index) in scope.row.chineseProductName" :key="index" :class="item.productName.includes(productNameStr) ? 'target_class' : ''">{{item.productName}}<br/></span>
              </div>
            </div>
            <!-- <span v-else>{{ scope.row.demand }}</span> -->
          </template>
          <template v-else-if="scope.column.property === 'clearCases'">
            <div v-if="scope.row.clearCasesList">
              <!-- <span>{{ scope.row.receiveEmail }}</span> -->
              <el-button class="f-m-l-10" type="text"
                @click="handleDetail(scope.row.clearCasesList, '清关案例-' + scope.row.hsCode,'1')">详情</el-button>
            </div>
            <!-- <span v-else>{{ scope.row.receiveEmail }}</span> -->
          </template>
          
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
            title="添加中文品名"
            :visible="addProductVisible"
            custom-class="c-custom-dialog"
            width="600px"
            :before-close="()=> this.addProductVisible = false"
            :close-on-click-modal="false">
      <el-form class="addEditBox" :model="productForm" :rules="productRules" ref="productForm" label-width="80px" size="medium">
        <el-row >
          <el-col :span="24">
            <el-form-item prop="cname" label="中文品名">
              <el-input v-model="productForm.productName" show-word-limit maxlength="255" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
          <el-button size="medium" @click="addProductVisible = false">取消</el-button>
          <el-button type="primary" size="medium" @click="addProduct">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
            title="税费计算"
            :visible="caculateRaxVisible"
            custom-class="c-custom-dialog"
            width="600px"
            :before-close="()=> this.caculateRaxVisible = false"
            :close-on-click-modal="false">
      <el-form class="addEditBox" :model="productForm" :rules="productRules" ref="productForm" label-width="120px" size="medium">
        <el-row >
          <el-col :span="24">
            <el-form-item prop="cname" label="海关编码">
              <el-input v-model="caculateRaxForm.hsCode" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="cname" label="中文品名">
              <el-input v-model="caculateRaxForm.productName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="cname" label="关税税率">
              <el-input v-model="caculateRaxForm.dutyRate" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="cname" label="附加税率">
              <el-input v-model="caculateRaxForm.addtionalDutyRate" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="cname" label="货值">
              <div style="display: flex;flex-direction: row;justify-content: space-between">
                <el-input style="width: 62%" v-model="caculateRaxForm.goodValue" ></el-input>
                <el-button  style="width: 35%" type="primary" @click="caculateFee">税费计算</el-button>
              </div>
            </el-form-item>
            <el-form-item prop="cname" label="预算进口关税">
              <el-input v-model="caculateRaxForm.budgetTariffs" ></el-input>
            </el-form-item>
            <el-form-item prop="cname" label="预算附加税">
              <el-input v-model="caculateRaxForm.budgetSurtax" ></el-input>
            </el-form-item>
            <el-form-item prop="cname" label="预算总税费">
              <el-input v-model="caculateRaxForm. totalTaxes2Fees" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <span style="width:100%;color: red;display: flex;justify-content: center;margin: 20px 0;">
             此预算税费不含MPF及HMF费用， MPF：货值 * 0.3464% ，最低31.67USD/HMF:产品货值 * 0.1250%
          </span>
      </el-form>
    </el-dialog>

    <add-customs-affairs :dialogVisible.sync="visible" :row="row"></add-customs-affairs>
  </div>
</template>
<script>
import QueryMixins from "@/mixins/QueryMixins";
import AddCustomsAffairs from "./modules/AddCustomsAffairs";
import {getAction, getallcountries,postAction} from "@/api/manage";
import {caculateSishewuru} from "../../utils/caculate";
export default {
  mixins: [QueryMixins],
  components: {
    AddCustomsAffairs,
  },
  props: {
  },
  data() {
    return {
      productForm:{},
      caculateRaxForm:{},
      addProductVisible:false,
      caculateRaxVisible:false,
      visible: false,
      isUsePost:true,
      row: {},
      queryForm: {},
      url:{
        list: "/sys/bdCountryHsCode/getClearRequireAndCasesPage",
        saveHsCodeChinesProduct: "/sys/bdCountryHsCode/saveHsCodeChinesProduct",
        importClearCasesAndRequirement:"/file/read/excel/importClearCasesAndRequirementXls",
        downloadModel:"/file/read/excel/downloadModel",
      },
      productRules:{
        chineseProductName: [
            {required: true, message: "请输入中文品名", trigger: "change"},
        ],
      },
      tableData: [],
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 220,
        },
        {
          prop: "hsCode",
          label: "海关编码",
        },
        {
          prop: "customsDescription",
          label: "产品描述",
        },

        {
          prop: "dutyRate",
          label: "关税税率",
        },
        {
          prop: "pga",
          label: "监管条件",
        },
        {
          prop: "addtionalDutyCode",
          label: "附加关税",
        },
        {
          prop: "chineseProductName",
          label: "中文品名",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: 'clearRequirements',
          label: '清关要求',
        },
        {
          prop: "clearCases",
          label: "案列",
        },
      ],
      productNameStr:null
    }
  },
  watch: {
    visible(val) {
     if(!val){
       this.handleQuery()
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    //下载excel模板
    //下载产品导入模板
    downloadModel(url, fileName) {
      let that = this;
      let params = {...this.queryForm, ...this.filterForm}
      postAction(url, params, {responseType: 'blob'})
              .then(response => {
                if (!response) {
                  return
                }

                const blob = new Blob([response])
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  navigator.msSaveBlob(blob, fileName)
                } else {
                  let u = window.URL.createObjectURL(response)
                  let aLink = document.createElement('a')
                  aLink.style.display = 'none'
                  aLink.href = u
                  aLink.setAttribute('download', fileName + '.xls')
                  document.body.appendChild(aLink)
                  aLink.click()
                  document.body.removeChild(aLink)
                  window.URL.revokeObjectURL(u)
                }
                // var blob = new Blob([response],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                // var downloadElement = document.createElement('a');
                // var herf = window.URL.createObjectURL(blob);
                // downloadElement.herf = herf;
                // downloadElement.download = fileName + ".xlsx";
                // document.body.appendChild(downloadElement);
                // downloadElement.click();
                // document.body.removeChild(downloadElement);
                // window.URL.revokeObjectURL(herf);
              })
    },
    caculateFee() {
      let dutyRate = "0%"
      if(this.caculateRaxForm.dutyRate != 'FREE'){
        dutyRate = this.caculateRaxForm.dutyRate
      }
      let goodValue = Number(this.caculateRaxForm.goodValue)
      dutyRate = Number(dutyRate.replace("%",""))
      let addtionalDutyRate = Number(this.caculateRaxForm.addtionalDutyRate.replace("%",""))
      if(isNaN(dutyRate) || isNaN(goodValue) ||isNaN(addtionalDutyRate) ){
        this.$message.error("请确保货值和税率格式正确！")
        return
      }
      this.$set(this.caculateRaxForm,"budgetTariffs",caculateSishewuru(goodValue,dutyRate)/100)
      this.$set(this.caculateRaxForm,"budgetSurtax",caculateSishewuru(goodValue,addtionalDutyRate)/100)
      this.$set(this.caculateRaxForm,"totalTaxes2Fees",this.caculateRaxForm.budgetTariffs +  this.caculateRaxForm.budgetSurtax)

    },
    addProduct(){
      postAction(this.url.saveHsCodeChinesProduct,this.productForm).then(res=>{
        if(res.success){
          this.$message.success(res.message)
        }
        this.addProductVisible = false
        this.handleQuery()
      })
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      const len = file.name.length < 100;
      if (!len) {
        this.$message.warning("该文件名太长，请修改文件名最多100个字符");
      }
      return isLt2M && isFile && len;
    },
    //导入清关要求和案例
    importOperation(data) {
      console.log("上传文件", data);
      const formData = new FormData();
      formData.append("file", data.file);
      postAction(this.url.importClearCasesAndRequirement, formData)
              .then((res) => {
                console.log("产品返回数据", res);
                if (res.success) {
                  this.$message.success(res.message);
                  this.handleQuery();
                }
              })
    },
    handleEdit(row) {
      for (var name in row) {
        this.$set(this.row, name, row[name]);
      }
      this.visible = true;
    },
    caculateRax(row){
      console.log(row)
      this.caculateRaxForm = JSON.parse(JSON.stringify(row));
      if(this.caculateRaxForm.chineseProductName){
        this.caculateRaxForm.productName = this.caculateRaxForm.chineseProductName.map((obj)=>{return obj.productName}).join(",");
      }
      if(!this.caculateRaxForm.addtionalDutyRate){
        this.caculateRaxForm.addtionalDutyRate = '0%'
      }
      if(!this.caculateRaxForm.dutyRate){
        this.caculateRaxForm.dutyRate = '0%'
      }else{
        let tempRate = this.caculateRaxForm.dutyRate.substring(0, this.caculateRaxForm.dutyRate.indexOf("%")) ==''?'0':this.caculateRaxForm.dutyRate.substring(0, this.caculateRaxForm.dutyRate.indexOf("%"))
        this.caculateRaxForm.dutyRate = tempRate+'%';
      }
      this.caculateRaxVisible = true
    },
    addProductDialog(row) {
      this.$set(this.productForm,"hsCodeId",row["id"])
      this.$set(this.productForm,"hsCode",row["hsCode"])
      this.addProductVisible = true;
    },
    handleDetail(detail, title,type) {
      let showStr =''
      detail.forEach(item=>{
        if (type =='0'&& item) {
          showStr = showStr +item.productCategories + '<br/>' + item.clearanceRequirementsSort +'<br/>'
          return;
        }
        if(type =='2' && item){
          showStr = showStr + item.productName + '<br/>'
          return;
        }
        if(item) showStr = showStr + item.productName + '<br/>' + item.clearanceCases +'<br/>'
      })
      showStr = showStr.replace(/\n/g,"</br>") || '暂无'
      this.$alert(showStr, title, {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true,
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${action}`
          // });
        }
      });
    },
    handleSearchSelf() {
      if(this.queryForm.productName) {
         this.productNameStr = this.queryForm.productName
      } else {
        this.productNameStr = null
      }
      this.handleSearch()
    }
  },
  computed:{
    buttonShowList() {
      return {
        'imClCase':this.$btnIsShow('conformance/CustomsAffairs','0','导入清关要求案例'),
        'doClCase':this.$btnIsShow('conformance/CustomsAffairs','0','清关要求案例模板下载'),
        'edit':this.$btnIsShow('conformance/CustomsAffairs','0','编辑'),
        'taxJs':this.$btnIsShow('conformance/CustomsAffairs','0','税费计算'),
        'addChPro':this.$btnIsShow('conformance/CustomsAffairs','0','添加中文品名'),
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.target_class {
  color: red;
  font-weight: bold;
}
</style>