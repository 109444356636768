<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      label-width="80px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="FbaCode" label-width="120px">
              <f-select
                :dict="'fba_code_edd'"
                v-model="queryForm.shipmentCode"
                :is-need="queryForm.shipmentCode"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="仓库代码" label-width="120px">
              <f-select
                :dict="'warehouse_code'"
                v-model="queryForm.shipmentZip"
                :is-need="queryForm.shipmentZip"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="渠道名称" label-width="120px">
              <f-select
                :dict="'transportStatus_enable'"
                v-model="queryForm.channelId"
                :is-need="queryForm.channelId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="30" :sm="15" :md="15" :lg="3">
            <el-form-item label="是否为生效版本" label-width="120px">
              <f-select
                :dict="'is_default'"
                :is-need="queryForm.isEffective"
                v-model="queryForm.isEffective"
                autocomplete="off"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"
          v-if="buttonShowList.add"
          >新增
        </el-button>
        <el-button
          :disabled="selectionRows.length === 0"
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleBatDel()"
          v-if="buttonShowList.plsc"
          >批量删除
        </el-button>
        <el-upload
          v-if="buttonShowList.im"
          action="/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          accept=".xls,.xlsx"
          style="margin: 0 10px; display: inline"
        >
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <el-button
          v-if="buttonShowList.mbxz"
          type="primary"
          size="medium"
          @click="downloadModel(url.downloadEddExcel, 'edd导入模板')"
          >模板下载
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="buttonShowList.bj"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleDel(scope.row.id)"
              v-if="buttonShowList.sc"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'kdIsRemote'">
            <span v-if="scope.row.kdIsRemote === '0'">否</span>
            <span v-else style="color: red">是</span>
          </template>
          <template v-else-if="scope.column.property === 'isEffective'">
            <span v-if="scope.row.isEffective === '1'">是</span>
            <span v-else>否</span>
          </template>
          <template v-else-if="scope.column.property === 'showColor'">
            <span :style="getColorByName(scope.row.showColor)">{{
              parseDict(scope.row.showColor, "fba_addr_show_color")
            }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <edit-edd-dialog
      :visible.sync="addVisible"
      :row="row"
      @ok="handleSearch"
    ></edit-edd-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditEddDialog from "../modules/EditEddDialog";

export default {
  name: "EddManage",
  mixins: [QueryMixins],
  components: {
    EditEddDialog,
  },
  data() {
    return {
      loading: false,
      addVisible: false,
      authVisible: false,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          width: 90,
        },
        {
          prop: "isEffective",
          label: "是否为生效版本",
          width: 100,
        },
        {
          prop: "shipmentCode",
          label: "FbaCode",
          width: 170,
        },
        {
          prop: "shipmentZip",
          label: "仓库代码",
          width: 170,
        },
        {
          prop: "channelName",
          label: "渠道名称",
          width: 200,
        },
        {
          prop: "afns",
          label: "AFNS",
          width: 100,
        },
        {
          prop: "ed",
          label: "ED/VDNS",
          width: 100,
        },
        {
          prop: "vans",
          label: "VANS",
          width: 100,
        },
        {
          prop: "x4fn",
          label: "X4FN",
          width: 100,
        },
        {
          prop: "k1aj",
          label: "K1AJ",
          width: 100,
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "updateTime",
          label: "修改时间",
        },
      ],
      url: {
        list: "/sys/edd/list",
        delete: "/sys/edd/delete",
        deleteBatch: "/sys/edd/deleteBatch",
        downloadEddExcel: "/sys/edd/downloadEddExcel",
        importEdd: "/sys/edd/importEdd",
      },
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importEdd, form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        } else {
          this.handleQuery();
        }
      });
    },
    handleDel(id) {
      this.$confirm("确定删除该edd?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row) {
      this.row = row;
      this.addVisible = true;
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/EddManage', '0', '新增'),
        'plsc':this.$btnIsShow('isystem/BaseData/EddManage', '0', '批量删除'),
        'im':this.$btnIsShow('isystem/BaseData/EddManage', '0', '导入'),
        'mbxz':this.$btnIsShow('isystem/BaseData/EddManage', '0', '模板下载'),
        'bj':this.$btnIsShow('isystem/BaseData/EddManage', '1', '编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/EddManage', '1', '删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
