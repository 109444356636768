<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="publicVisible"
      status-icon
      width="620px"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
    >
      <div class="c-slim-scrollbar">
        <el-form
          ref="form"
          :rules="rules"
          :model="formData"
          label-width="150px"
          label-position="left"
        >
          <el-row type="flex" justify="start" align="top" :gutter="20">
            <el-col :span="12">
              <el-form-item prop="year" label="初始化年份">
                <el-date-picker
                  v-model="formData.year"
                  type="year"
                  placeholder="选择年"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/manage";
export default {
  name: "dialogPublic",
  props: {
    // Dialog 对话框标题
    title: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    url: {
      type: Object,
      default: {},
    },
    form: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      publicVisible: false,
      rules: {
        year: [
          {
            type: "date",
            required: true,
            message: "请选择年份",
            trigger: "change",
          },
        ],
      },
      formData: this.form,
    };
  },
  watch: {
    visible: {
      handler(newName) {
        this.publicVisible = newName;
      },
      immediate: true,
    },
    form: {
      handler(newName) {
        this.formData = newName;
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        console.log(this.formData, "form");
        if (valid) {
          this.addTargetList();
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
    async addTargetList(params) {
      await postAction(
        this.url.add + "?year=" + this.formData.year.getFullYear(),
        params
      )
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.$emit("ok", this.formData.year);
          }
        })
        .finally((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  i {
    cursor: pointer;
  }
}
::v-deep .el-dialog__body {
  padding: 20px 30px 0px 26px;
  height: calc(100% - 130px);
  overflow: auto;
}
</style>
