<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <div class="f-p-b-15">
                    <el-button
                            type="primary"
                            size="medium"
                            icon="el-icon-plus"
                            @click="handleAdd"

                            v-if="buttonShowList['add']"
                    >新增
                    </el-button
                    >
                </div>
                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columns"
                        :data="tableData"
                        :cell-style="{ 'text-align': 'center' }"
                        tableHeight="73vh"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-button type="text" @click="handleEdit(scope.row)"

                                       v-if="buttonShowList['edit']"
                            >编辑</el-button>
                            <el-button type="text" @click="handleDele(scope.row)"

                                       v-if="buttonShowList['del']"
                            >删除</el-button>
                        </template>
                        <template v-else-if="scope.column.property === 'templateType'">
                            <span v-if="scope.row.templateType === '1'">是</span>
                            <span v-else>否</span>
                        </template>
                    </template>
                </f-table>
                <el-row class="f-text-right f-p-v-8">
                    <el-pagination
                            v-bind="ipagination"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </el-row>
            </el-card>
        </el-form>
        <el-dialog
                :title="form.id ? '编辑模板' : '新增模板'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                width="60%"
        >
            <el-form :model="form" :rules="rules" ref="form" class=" addEditBox">
                <el-card class="box-card">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item
                              label="模板名称"
                              :label-width="formLabelWidth"
                              prop="templateName">
                          <el-input v-model="form.templateName" autocomplete="off"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                              label="模板别名"
                              :label-width="formLabelWidth"
                              prop="templateCode">
                          <el-input v-model="form.templateCode" autocomplete="off"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                              label="是否报价模板"
                              :label-width="formLabelWidth"
                              prop="templateType">
                          <el-radio-group v-model="form.templateType">
                              <el-radio :label="'1'">是</el-radio>
                              <el-radio :label="'2'">否</el-radio>
                          </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-card>
                <el-card class="box-card last_card">
                    <el-button size="small" type="primary" @click="AddListRow()" round
                    >添加费用
                    </el-button
                    >
                    <el-form :model="form" ref="fyform" class="form no_content" :rules="rules">
                        <el-table :data="form.feeTemplateList" :label-width="formLabelWidth">
                            <el-table-column fixed label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button
                                            class=""
                                            size="small"
                                            type="primary"
                                            @click="handleDelete(scope.$index)"
                                            style="margin: 4px 10px;"
                                    >删除
                                    </el-button
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column fixed="left" label="费用名称" width="220">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'feeTemplateList.' + scope.$index + '.feeId'"
                                            :rules="rules.name"
                                    >
                                        <el-select
                                                @click.native="initBdFeeTypeList"
                                                clearable
                                                filterable
                                                v-model="scope.row.feeId"
                                                placeholder="请选择"
                                        >
                                            <el-option
                                                    v-for="(item, index) in feeTypeList"
                                                    :label="item.label"
                                                    :value="item.value"
                                                    :key="index"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="供应商">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'feeTemplateList.' + scope.$index + '.supId'"
                                            :rules="rules.sup"
                                    >
                                        <el-select
                                                clearable
                                                filterable
                                                v-model="scope.row.supId"
                                                placeholder="请选择"
                                                @click.native="initAllSupplier"
                                        >
                                            <el-option
                                                    v-for="(item, index) in allSupplierList"
                                                    :label="item.label"
                                                    :value="item.value"
                                                    :key="index"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="价格" width="90">
                                <template slot-scope="scope">
                                    <el-form-item class="el_form_item" :prop="'feeTemplateList.' + scope.$index + '.price'" :rules="rules.price">
                                        <el-input v-model="scope.row.price" type="number"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="数量" width="90">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'feeTemplateList.' + scope.$index + '.count'"
                                            :rules="rules.count"
                                             onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                    >
                                        <el-input v-model="scope.row.count" type="number"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="币种" width="200">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'feeTemplateList.' + scope.$index + '.cur'"
                                            :rules="rules.cur"
                                    >
                                        <f-select
                                                :dict="'bd_cur'"
                                                v-model="scope.row.cur"
                                                :isNeed="scope.row.cur"
                                                placeholder="请选择"

                                        >
                                        </f-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </el-card>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
  import {deleteAction, postAction, putAction} from "@/api/manage";
  import QueryMixins from "../../mixins/QueryMixins";
  import {getAllSupplier, getBdFeeTypeList} from "../../api/manage";
  import {zhengshuPattern, zhengshuxiaoshuPartten} from "@/utils/pattern";
  import {validateCmb,validateCtn} from "@/utils/validator";

  export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                    count: [{validator: validateCtn, trigger: 'change'},
                        {required: true, message: "请填写数量", trigger: "blur"}
                    ],
                    price: [{validator: validateCmb, trigger: 'change'},
                        {required: true, message: "请填写价格", trigger: "blur"}
                    ],
                    name: [{required: true, message: "请选择费用名称", trigger: "change"}],
                    sup: [{required: true, message: "请选择供应商", trigger: "change"}],
                    cur: [{required: true, message: "请选择币种", trigger: "change"}],
                    templateName: [
                        {required: true, message: "请输入模板名称", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    templateCode: [
                        {required: true, message: "请输入模板别名", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    templateType: [
                        {required: true, message: "请选择是否报价模板", trigger: "change"},
                    ],
                },
                feeTemplateList: [],
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                dialogFormVisibleEmail: false,
                columns: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "templateName",
                        label: "模板名称",
                        sortable: "custom",
                    },
                    {
                        prop: "templateCode",
                        label: "模板别名",
                        sortable: "custom",
                    },
                    {
                        prop: "templateType",
                        label: "是否报价模板",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                        sortable: "custom",
                    },
                ],
                url: {
                    list: "/sys/feeTemplate/list",
                    add: "/sys/feeTemplate/add",
                    edit: "/sys/feeTemplate/edit",
                    getById: "/sys/feeTemplate/queryById",
                    delete: "/sys/feeTemplate/delete",
                },
            };
        },
        methods: {
            initBdFeeTypeList() {
                if (this.feeTypeList.length <= 0) {
                    this.feeTypeList = [];
                    getBdFeeTypeList("1")
                        .then((res) => {
                            if (res.success) {
                                for (var i = 0; i < res.result.length; i++) {
                                    var obj = {};
                                    obj.value = res.result[i].id;
                                    obj.label = res.result[i].chineseName;
                                    this.feeTypeList.push(obj);
                                }
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                }
            },

            initAllSupplier() {
                this.allSupplierList = [];
                //获取所有海运供应商
                getAllSupplier("1")
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id; //供应商ID
                                obj.label = res.result[i].chinaseName; //供应商中文名
                                this.allSupplierList.push(obj);
                            }
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //点击加号添加一行数据
            AddListRow() {
                /*  this.feeTemplateList=[];*/
                this.form.feeTemplateList.push({
                    feeId: "",
                    supId: "",
                    count: "",
                    cur: "",
                    price: "",
                });
            },
            handleDelete(index) {
                this.form.feeTemplateList.splice(index, 1);
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.form = {};
                this.form.feeTemplateList = [];
                this.dialogFormVisible = true;
                this.$refs.form.resetFields();
            },
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.initAllSupplier();
                postAction(this.url.getById + "?id=" + row.id).then((res) => {
                    if (res.success) {
                        this.form.feeTemplateList = res.result.feeTemplateList;
                        this.dialogFormVisible = true;
                    }
                });
            },
            handleSave() {
                let validate = null;
                if (this.form.feeTemplateList) {
                    this.$refs["fyform"].validate((valid) => {
                        if (valid) {
                            validate = valid;
                        } else {
                            this.$message.warning("请将所有数据校验通过");
                            return false;
                        }
                    });
                }
                this.$refs.form.validate((valid) => {
                    if (valid && validate) {
                        // this.form.feeTemplateList = this.form.feeTemplateList;
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        } else {
                            postAction(this.url.add, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        }
                    }
                });
            },
            handleDele(row) {
                this.$confirm(`确定删除模板`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
            this.queryForm.initFeeType = true;
            this.queryForm.initAllSupplier = true;
        },
        computed:{
          buttonShowList(){
            return {
              'add':this.$btnIsShow('channel/FeeTemple','0','新增'),
              'edit':this.$btnIsShow('channel/FeeTemple','1','编辑'),
              'del':this.$btnIsShow('channel/FeeTemple','1','删除'),
            }
          }
        }
    };
</script>
<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
