import { render, staticRenderFns } from "./TeamIntroductionDialog.vue?vue&type=template&id=71f5ce35&scoped=true&"
import script from "./TeamIntroductionDialog.vue?vue&type=script&lang=js&"
export * from "./TeamIntroductionDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f5ce35",
  null
  
)

export default component.exports