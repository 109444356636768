<template>
    <el-card class="f-flex-col" shadow="never" :body-style="{flex: 1}">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <el-dropdown trigger="click" placement="bottom-start" @command="handleCommand">
                <span class="el-dropdown-link">
                    <el-button class="c-theme-button" size="medium" type="primary"

                               v-if="buttonShowList.add"

                    >添加菜单<i class="el-icon-arrow-down"></i></el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="TOP-MENU">一级菜单</el-dropdown-item>
                    <el-dropdown-item :disabled="currentNode === null" command="SUB-MENU">子菜单</el-dropdown-item>
                    <el-dropdown-item  command="BUTTON">按钮/权限</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button class="c-theme-button f-m-l-10" size="medium" type="primary" @click="handleCollapsed">全部{{flag ? '展开' : '折叠'}}</el-button>
            <el-button
                class="c-theme-error-button"
                size="medium"
                icon="el-icon-delete"
                type="primary"
                :disabled="!checkedKeys.length"
                v-if="buttonShowList.plsc"
                @click="handleBatchDel">批量删除</el-button>
        </div>
        </el-card>

        <el-card class="box-card last_card">
            <div class="f-full-height f-flex-col">
            <el-input v-model="filterText" suffix-icon="el-icon-search" size="medium" placeholder="输入菜单名称搜索"></el-input>
            <div class="tree-wrap f-overflow-auto f-flex-1">
                <el-tree
                    show-checkbox
                    ref="tree"
                    node-key="id"
                    :data="data"
                    :props="defaultProps"
                    :check-strictly="true"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    :filter-node-method="filterNode"
                    @current-change="handleCurrentChange"
                    @check="handleCheck">
                </el-tree>
            </div>
        </div>
        </el-card>
        <add-menu-drawer :visible.sync="visible" :data="data" :row="row"></add-menu-drawer>
    </el-card>
</template>

<script>
import { deleteAction } from '@/api/manage'
import AddMenuDrawer from './AddMenuDrawer'
export default {
    name: 'MenuTreeModule',
    components: {
        AddMenuDrawer
    },
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            checkedKeys: [],
            row: {},
            visible: false,
            flag: true,
            filterText: '',
            currentNode: null,
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            url: {
                delete: '/sys/permission/deleteBatch',
            }
        }
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    mounted() {
        this.$bus.$on('isys-menu-tree-reset', () => {
            this.flag = true
            this.currentNode = null
            this.checkedKeys= []
        })
    },
    beforeDestroy() {
        this.$bus.$off('isys-menu-tree-reset')
    },
    methods: {
        handleCommand(cmd) {
            if(cmd === 'TOP-MENU') {
                this.row = { menuType: 0 }
            } else if(cmd === 'SUB-MENU') {
                this.row = {
                    menuType: 1,
                    parentId: this.currentNode.id
                }
            } else if (cmd === 'BUTTON') {
                 this.row = {
                    menuType: 2,
                    parentId: this.currentNode.id,
                    url: this.currentNode.url
                }
            }
            this.visible = true
        },
        handleBatchDel() {
            this.$confirm('确定删除该菜单以及相应的子菜单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                closeOnClickModal: false,
            }).then(() => {
                const params = { ids: this.$refs.tree.getCheckedKeys().join(',')}
                deleteAction(this.url.delete, params).then(res => {
                    this.$bus.$emit('isys-menu-data-refresh')
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                })
            }).catch(() => {

            })
        },
        filterNode(value, data) {

            if (!value) return true;
            return data.title.indexOf(value) !== -1
        },
        handleCheck(node, { checkedKeys }) {
            console.log('选中的节点',node,checkedKeys)
            this.checkedKeys = checkedKeys
        },
        handleCurrentChange(node) {
            console.log('node',node);

                this.currentNode = node
                this.$emit('current-change', this.currentNode)

        },
        handleCollapsed() {
            const expandFn = ($list) =>  {
                $list.map($el => {
                    $el.expanded = this.flag
                    if($el.childNodes && $el.childNodes.length) {
                        expandFn($el.childNodes)
                    }
                })
            }
            this.$refs.tree.$children.map($el => {
                $el.node.expanded = this.flag
                $el.node.childNodes && expandFn($el.node.childNodes)
            })
            this.flag = !this.flag
        }
    },
  computed:{
    buttonShowList(){
      return {
        'plsc':this.$btnIsShow('isystem/MenuManage','0','批量删除'),
        'add':this.$btnIsShow('isystem/MenuManage','0','添加菜单'),
      }
    }
  }
}
</script>

<style lang="scss">
.menu-popover {
    padding: 4px;
    margin-top: 0;
}
</style>

<style lang="scss" scoped>
.tree-wrap {
    margin-top: 10px;
}
::v-deep
    {
        .el-card__body {
            overflow: auto;
        }
        .el-card__header {
            padding: 14px 20px;
        }
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
            background-color: #e0efff;
        }
        .el-tree-node__content {
            height: 28px;
        }
        .el-button {
            padding: 8px 16px;
        }
    }
</style>
