<template>
  <div>
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <div class="f-p-b-15" style="display:flex;">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :md="4" :sm="24">
              <el-form-item label="订单号" :label-width="queryFormLabelWidth">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    @keyup.enter.native="handleSearch"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="客户" :label-width="queryFormLabelWidth">
                <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="航线名称" :label-width="queryFormLabelWidth">
                <f-select :dict="'fba_solicitation'"
                          v-model="queryForm.solicitation"
                          :isNeed="queryForm.solicitation"
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="国家" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.shipmentCountry"
                    :isNeed="queryForm.shipmentCountry"
                    :dict="'bd_country'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="拼柜仓库" :label-width="queryFormLabelWidth">
                <f-select :multiple="true"
                          v-model="queryForm.lclPlaceList"
                          :isNeed="queryForm.lclPlaceList"
                          :dict="'lclPlace'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="24">
              <el-form-item label="交货仓库" :label-width="queryFormLabelWidth">
                <f-select :multiple="true"
                          v-model="queryForm.deliveryPointList"
                          :isNeed="queryForm.deliveryPointList"
                          :dict="'delivery_point'">
                </f-select>
              </el-form-item>
            </el-col>

            <el-col :md="4" :sm="24">
              <el-form-item label="渠道名称" :label-width="queryFormLabelWidth">
                <f-select :multiple="true"
                          v-model="queryForm.channeIdList"
                          :isNeed="queryForm.channeIdList"
                          :dict="'transportStatus'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="仓库代码" :label-width="queryFormLabelWidth">
                <el-input v-model="queryForm.shipmentCode"   type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="邮编" :label-width="queryFormLabelWidth">
                <el-input v-model="queryForm.shipmentZip" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>

            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="报关方式" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.declareType"
                    :isNeed="queryForm.declareType"
                    :dict="'os_declare_type'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="发票状态" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.invoiceStatus"
                    :isNeed="queryForm.invoiceStatus"
                    :dict="'invoice_status'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="是否已录材积" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.isRucang"
                    :isNeed="queryForm.isRucang"
                    :dict="'is_default'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="是否已开单" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.isKaidan"
                    :isNeed="queryForm.isKaidan"
                    :dict="'is_default'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="操作员" :label-width="queryFormLabelWidth">
                <f-select
                    :multiple="true"
                    v-model="queryForm.operateIdList"
                    :isNeed="queryForm.operateIdList"
                    :dict="'sys_user_id'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">
              <el-form-item label="超大件" :label-width="queryFormLabelWidth">
                <f-select
                    :multiple="true"
                    v-model="queryForm.largeInfos"
                    :isNeed="queryForm.largeInfos"
                    :dict="'volume_large_info'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :md="8" :sm="24">
              <el-form-item label="入仓时间" :label-width="queryFormLabelWidth">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.cargoArrivedStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.cargoArrivedEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <!--                      <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="4" :sm="24">-->
            <!--                        <el-form-item label="FBA优先状态" :label-width="'100px'">-->
            <!--                          <f-select-->
            <!--                              :dict="'fba_addr_show_color'"-->
            <!--                              v-model="queryForm.showColor"-->
            <!--                              :is-need="queryForm.showColor"-->
            <!--                          ></f-select>-->
            <!--                        </el-form-item>-->
            <!--                      </el-col>-->


            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="是否包税" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.hasTaxType"
                    :isNeed="queryForm.hasTaxType"
                    :dict="'is_default'">
                </f-select>
              </el-form-item>
            </el-col>

            <!--                  <el-col v-if="showLoadingSearch" :md="4" :sm="24">-->
            <!--                    <el-form-item label="是否录材积">-->
            <!--                      <f-select-->
            <!--                          v-model="queryForm.isCmb"-->
            <!--                          :isNeed="queryForm.isCmb"-->
            <!--                          :dict="'is_default'">-->
            <!--                      </f-select>-->
            <!--                    </el-form-item>-->
            <!--                  </el-col>-->
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="客户等级" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.grade"
                    :isNeed="queryForm.grade"
                    :dict="'customer_type'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="业务员" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.salesmanId"
                    :isNeed="queryForm.salesmanId"
                    :dict="'sys_user_id'">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="客服" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.docId"
                    :isNeed="queryForm.docId"
                    :dict="'sys_user_id'">
                </f-select>
              </el-form-item>
            </el-col>

            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="中文品名" :label-width="queryFormLabelWidth">
                <el-input v-model="queryForm.productNameCn"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="排柜备注" :label-width="queryFormLabelWidth">
                <el-input v-model="queryForm.pgRemark" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="业务类型" :label-width="queryFormLabelWidth">
                <el-select v-model="queryForm.containerType" @keyup.enter.native="handleSearch"
                           clearable filterable>
                  <el-option value="0" label="LCL"></el-option>
                  <el-option value="1" label="FCL"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="材积录入人" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.createBy"
                    :isNeed="queryForm.createBy"
                    :dict="'sys_user'">
                </f-select>
              </el-form-item>
            </el-col>
            <!--                      <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
            <!--                        <el-form-item label="清关属性">-->
            <!--                          <f-select-->
            <!--                              :dict="'qg_prop'"-->
            <!--                              v-model="queryForm.qgProp"-->
            <!--                              :is-need="queryForm.qgProp"-->
            <!--                          ></f-select>-->
            <!--                        </el-form-item>-->
            <!--                      </el-col>-->
            <!--                      <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
            <!--                        <el-form-item label="海外仓中转服务">-->
            <!--                          <f-select-->
            <!--                              :dict="'haiwai_service'"-->
            <!--                              v-model="queryForm.haiwaiService"-->
            <!--                              :is-need="queryForm.haiwaiService"-->
            <!--                          ></f-select>-->
            <!--                        </el-form-item>-->
            <!--                      </el-col>-->
            <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="锁国外仓租柜租" :label-width="queryFormLabelWidth">
                <f-select
                    :dict="'is_default'"
                    v-model="queryForm.lockCangzu"
                    :is-need="queryForm.lockCangzu"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="锁国外查验" :label-width="queryFormLabelWidth">
                <f-select
                    :dict="'is_default'"
                    v-model="queryForm.lockChayan"
                    :is-need="queryForm.lockChayan"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="三锁服务" :label-width="queryFormLabelWidth">
                <f-select
                    :dict="'is_default'"
                    v-model="queryForm.sanLock"
                    :is-need="queryForm.sanLock"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="地址类型" :label-width="queryFormLabelWidth">
                <f-select
                    :dict="'address_type'"
                    v-model="queryForm.shipmentType"
                    :is-need="queryForm.shipmentType"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="是否入仓超5天" :label-width="queryFormLabelWidth">
                <f-select
                    :dict="'is_default'"
                    v-model="queryForm.isCargoOverFiveDay"
                    :is-need="queryForm.isCargoOverFiveDay"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="货物所在仓"  prop="suozaiCangkuIdList" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.suozaiCangkuIdList"
                    :isNeed="queryForm.suozaiCangkuIdList"
                    :multiple="true"
                    :dict="'delivery_point'"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :md="8" :sm="24">
              <el-form-item label="提货时间" :label-width="queryFormLabelWidth">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.cargoPickedStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.cargoPickedEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col v-if="showLoadingSearch" :md="4" :sm="24">
              <el-form-item label="问题件"  prop="isWarning" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.isWarning"
                    :isNeed="queryForm.isWarning"

                    :dict="'is_default'"
                ></f-select>
              </el-form-item>
            </el-col>
            <!--                      <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
            <!--                        <el-form-item label="航线名称">-->
            <!--                          <f-select-->
            <!--                              :dict="'fba_solicitation'"-->
            <!--                              v-model="queryForm.solicitation"-->
            <!--                              :is-need="queryForm.solicitation"-->
            <!--                          ></f-select>-->
            <!--                        </el-form-item>-->
            <!--                      </el-col>-->
            <!--      <el-col class="no_margin" v-if="showLoadingSearch" :md="4" :sm="24">
                      <el-form-item label="fbaCode">
                          <el-input v-model="queryForm.fbaCode" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col class="no_margin" v-if="showLoadingSearch" :md="4" :sm="24">
                      <el-form-item label="zip">
                          <el-input v-model="queryForm.zip" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>-->
            <el-col :md="4" :sm="24">
              <el-form-item label="库位"  prop="locationCodes" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.locationCodes"
                    :isNeed="queryForm.locationCodes"
                    :multiple="true"
                    :dict="'warehouse_location_type'"
                ></f-select>
              </el-form-item>
            </el-col>
             <el-col :md="4" :sm="24">
              <el-form-item label="订单产品定义"  prop="sensitiveTypeList" :label-width="queryFormLabelWidth">
                <f-select
                    v-model="queryForm.sensitiveTypeList"
                    :isNeed="queryForm.sensitiveTypeList"
                    :multiple="true"
                    :dict="'order_sensitveType'"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
                <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i
                    :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                    style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </el-form>
    <el-card class="box-card">
      <div>
        <el-button
            type="primary"
            size="medium"
            @click="handleBatchWaitingCabinet"
            :disabled="this.selectionRows.length ===0 "
        >批量排柜
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleBatchWaitingCabinet2"
            :disabled="this.selectionRows.length ===0 "
            v-if="buttonShowList['无条件排柜']"
        >批量无条件排柜
        </el-button>
        <el-button type="primary" size="medium" @click="handleAddCabinet"
        >新增柜子
        </el-button>
        <el-button type="primary" size="medium" @click="handleExportXls('待排柜订单表')"
                   v-if="buttonShowList['导出']">导出</el-button>
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="orderNo"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :isorter="isorter"
          :show-operation="true"
          :cell-style="{ 'text-align': 'center' }"
          :tableHeight="showLoadingSearch ? '59vh' : '67vh'"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          @queryChange="handleTableQueryChange"

      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="WaitingCabinet(scope.row)" :disabled="scope.row.isWarning==='1'">排柜</el-button>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <!--                        如果材积信息已有 也是已入仓-->
            <a v-if="(scope.row.realCmb && Number(scope.row.realCmb)>0)||scope.row.orderStatus>2"
               style="color: green"
               @click.prevent="$intoDocPage(scope.row.orderNo)"
            ><span style="color: green">{{scope.row.orderNo}}</span></a>
            <a v-else
               style="color: red"
               @click.prevent="$intoDocPage(scope.row.orderNo)"
            ><span style="color: red">{{scope.row.orderNo}}(未入仓)</span></a>
          </template>
          <!--                    所在仓库-->
          <template v-else-if="scope.column.property === 'suozaiCangkuName'">
            <el-popover trigger="hover" placement="top" >
              <!-- 鼠标移动需要提示的其他字段信息（showMessage字段传递对应要展示的字段名称） : 自己的字段超出的鼠标移动展示  -->
              <el-timeline >
                <el-timeline-item
                    v-for="(item, index) in scope.row.kaidanWarehouseLogList"
                    :key="index"
                    :timestamp="item.createTime">
                  {{item.createBy}}--开单--{{item.suozaiCangkuName}}
                </el-timeline-item>
              </el-timeline>
              <div slot="reference">
                <span>{{scope.row.suozaiCangkuName}}</span>
              </div>
            </el-popover>
          </template>
          <template v-else-if="scope.column.property === 'declareType'">
            <span>{{ parseDict(scope.row.declareType, "os_declare_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'invoiceStatus'">
            <span>{{
                parseDict(scope.row.invoiceStatus, "invoice_status")
              }}</span>
          </template>

          <template v-else-if="scope.column.property === 'username'">
            <a type="text" @click.prevent="handleEdit(scope.row)" style="color:#57a3f3;"><span>{{scope.row.username}}</span></a>
            <img src="../../docpage/images/v.png" alt="" v-if="scope.row.grade ==='0'">
            <img src="../../docpage/images/icon-heart.png" alt="" v-if="scope.row.orderQuantity ===0">
          </template>
          <template v-else-if="scope.column.property === 'grade'">
            <span>{{ parseDict(scope.row.grade, "customer_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isVirtualCabinet'">
            <span v-if="scope.row.isVirtualCabinet === '0'">自拼</span>
            <span v-if="scope.row.isVirtualCabinet === '1'">非自拼</span>
          </template>


          <template v-else-if="scope.column.property === 'isRucang'">
            <span v-if="scope.row.isRucang==='1'">已入仓</span>
            <span v-else>未入仓</span>
          </template>
          <template v-else-if="scope.column.property === 'clearanceOfGoods'">
            <span v-if="scope.row.clearanceOfGoods ===0">否</span>
            <span v-else>是</span>
          </template>
          <!--                    是否包税-->
          <template v-else-if="scope.column.property === 'hasTaxType'">
            <span v-if="scope.row.hasTaxType ==='0'">未包税</span>
            <span v-else>已包税</span>
          </template>
          <!-- 实际小于预计标红，大于或者等于标绿 -->
          <template v-else-if="scope.column.property === 'realCtn'">
            <span :style="(scope.row.realCtn !== scope.row.ctn) ? 'color: red' : 'color: green'">{{ scope.row.realCtn }}</span>
          </template>
          <template v-else-if="scope.column.property === 'realCmb'">
            <span :style="(scope.row.realCmb !== scope.row.cmb) ? 'color: red' : 'color: green'">{{ scope.row.realCmb }}</span>
          </template>
          <!--                    问题件显示-->
          <template v-else-if="scope.column.property==='isWarning'">
            <span v-if="scope.row.isWarning==='1'" style="color:red">是</span>
            <span v-else>否</span>
          </template>
          <template v-else-if="scope.column.property === 'largeInfos'">
            <div style="display: flex;flex-direction: column">
              <span v-for="(item,index) in scope.row.largeInfos" :key="index">{{item}}</span>
            </div>
          </template>

        </template>
      </f-table>
      <el-row class="f-p-v-8 bottom_flex">
        <el-col :span="12" class="total_price">
          <span v-if="selectionRows.length">合计：毛重 {{ totalKg }} 预计体积  {{ totalCmb }} 实际体积 {{totalRealCmb}}</span>
        </el-col>
        <el-col class="f-text-right" :span="12">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange">
          </el-pagination>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog
        title="排柜"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-form :model="form" ref="form">
        <el-form-item
            label="订单号"
            :label-width="formLabelWidth"
        >
          {{ formNunber.orderNo }}
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">待排件数</label>
              <a class="elrow-a">{{ formNunber.waitingCtn }}</a>
            </el-col>

            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">待排重量</label>
              <a class="elrow-a">{{ formNunber.waitingKg }}</a>
            </el-col>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">待排体积</label>
              <a class="elrow-a">{{ formNunber.waitingCmb }}</a>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            label="周数"
            :label-width="formLabelWidth"
            prop="priceExplainName"
        >
          <el-select
              v-model="formNunber.id"
              placeholder="请选择"
              filterable
              clearable
              style="width: 50%"
              @change="changeSize"
              @click.native="init"

          >
            <el-option
                v-for="item in weekList"
                :key="item.id"
                :label="item.week"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">已排件数</label>
              <a class="elrow-a">{{ formNunber.alreadyCtn }}</a>
            </el-col>

            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">已排重量</label>
              <a class="elrow-a">{{ formNunber.alreadyKg }}</a>
            </el-col>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">已排体积</label>
              <a class="elrow-a">{{ formNunber.alreadyCmb }}</a>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">总排件数</label>
              <a class="elrow-a">{{ formNunber.allCtn }}</a>
            </el-col>

            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">总排重量</label>
              <a class="elrow-a" :class="formNunber.allKg > 19500 ? 'red_color' : ''">{{ formNunber.allKg
                }}</a>
            </el-col>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">总排体积</label>
              <a class="elrow-a" :class="redAllCmb ? 'red_color' : ''">{{ formNunber.allCmb }}</a>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false; formNunber={}"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="saveOrderNoCabinet(0)"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
    <el-dialog
        title="无条件排柜"
        :visible.sync="dialogFormVisible2"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-form :model="form" ref="form">
        <el-form-item
            label="订单号"
            :label-width="formLabelWidth"
        >
          {{ formNunber.orderNo }}
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">待排件数</label>
              <a class="elrow-a">{{ formNunber.waitingCtn }}</a>
            </el-col>

            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">待排重量</label>
              <a class="elrow-a">{{ formNunber.waitingKg }}</a>
            </el-col>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">待排体积</label>
              <a class="elrow-a">{{ formNunber.waitingCmb }}</a>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            label="周数"
            :label-width="formLabelWidth"
            prop="priceExplainName"
        >
          <el-select
              v-model="formNunber.id"
              remote
              filterable
              clearable
              style="width: 50%"
              @change="changeSize"
              :remote-method="getRemoteCabinetList"
              placeholder="请输入搜索条件"
              :loading="loading2"

          >
            <el-option
                v-for="item in weekList"
                :key="item.id"
                :label="item.week"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">已排件数</label>
              <a class="elrow-a">{{ formNunber.alreadyCtn }}</a>
            </el-col>

            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">已排重量</label>
              <a class="elrow-a">{{ formNunber.alreadyKg }}</a>
            </el-col>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">已排体积</label>
              <a class="elrow-a">{{ formNunber.alreadyCmb }}</a>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">总排件数</label>
              <a class="elrow-a">{{ formNunber.allCtn }}</a>
            </el-col>

            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">总排重量</label>
              <a class="elrow-a" :class="formNunber.allKg > 19500 ? 'red_color' : ''">{{ formNunber.allKg
                }}</a>
            </el-col>
            <el-col :span="8" class="elrow">
              <label class="el-form-item_label">总排体积</label>
              <a class="elrow-a" :class="redAllCmb ? 'red_color' : ''">{{ formNunber.allCmb }}</a>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible2 = false; formNunber={}"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="saveOrderNoCabinet(1)"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
    <el-dialog
        title="新增柜子"
        :visible.sync="dialogFormAddCabinetVisible"
        :close-on-click-modal="false"
    >
      <el-form :model="addCabinetForm" ref="addCabinetForm" :rules="rules">
        <!-- <el-form-item
                 label="拼柜仓库"
                 :label-width="formLabelWidth"
                 prop="lclPlaceId"
         >
             <f-select
                     v-model="addCabinetForm.lclPlaceId"
                     :is-need="addCabinetForm.lclPlaceId"
                     :dict="'lcl_place'"
                     @change="changeWeekName"
             ></f-select>
         </el-form-item>
         <el-form-item
                 label="航线组"
                 :label-width="formLabelWidth"
                 prop="airRoute"
         >
             <f-select
                     v-model="addCabinetForm.airRoute"
                     :isNeed="addCabinetForm.airRoute"
                     :dict="'airRouteCode'"
                     @change="changeWeekName"
             ></f-select>
         </el-form-item>
         <el-form-item
                 label="港口"
                 :label-width="formLabelWidth"
                 prop="bdPortId"
         >
             <f-select
                     v-model="addCabinetForm.bdPortId"
                     :isNeed="addCabinetForm.bdPortId"
                     :dict="'bd_port_code'"
                     @change="changeWeekName"
             ></f-select>
         </el-form-item>
         <el-form-item
                 label="仓库"
                 :label-width="formLabelWidth"
                 prop="fbacode"
         >
             <f-select
                     v-model="addCabinetForm.fbacode"
                     :isNeed="addCabinetForm.fbacode"
                     :dict="'bd_fba_addr'"
                     @change="changeWeekName"
             ></f-select>
         </el-form-item>-->
        <el-form-item label="周数" :label-width="formLabelWidth" prop="week">
          <el-input v-model="addCabinetForm.week"></el-input>
        </el-form-item>
        <el-form-item label="周期" prop="whichWeek" :label-width="formLabelWidth">
          <el-date-picker
              id="getWhichWeek"
              v-model="addCabinetForm.whichWeek"
              type="week"
              :format="formatWeekStr"
              placeholder="选择周"
              :picker-options="{'firstDayOfWeek' : 1}"
              @change="changeWhichWeek"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="柜型" :label-width="formLabelWidth" prop="size">
          <f-select
              v-model="addCabinetForm.size"
              :isNeed="addCabinetForm.size"
              :dict="'cabinet_type'"
              :disabledCondition="disabledCondition"
              @changet="changeCabinetType"
          ></f-select>
        </el-form-item>
        <el-form-item label="预排方数" prop="preVolume" :label-width="formLabelWidth" :rules="rules.preVolume">
          <el-input v-model="addCabinetForm.preVolume" type="number"></el-input>
        </el-form-item>
        <el-form-item label="起运港" :label-width="formLabelWidth" prop="pol">
          <f-select
              v-model="addCabinetForm.pol"
              :isNeed="addCabinetForm.pol"
              :dict="'bd_port'"
          ></f-select>
        </el-form-item>
        <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEnd">
          <f-select
              v-model="addCabinetForm.podEnd"
              :isNeed="addCabinetForm.podEnd"
              :dict="'bd_port'"
          ></f-select>
        </el-form-item>
        <el-form-item label="中转港" :label-width="formLabelWidth" prop="transferPort">
          <f-select
              v-model="addCabinetForm.transferPort"
              :isNeed="addCabinetForm.transferPort"
              :dict="'bd_port'"
          ></f-select>
        </el-form-item>
        <el-form-item label="订舱号" :label-width="formLabelWidth" prop="bookNo">
          <f-select
              v-model="addCabinetForm.bookNo"
              :isNeed="addCabinetForm.bookNo"
              :dict="'so'"
          ></f-select>
        </el-form-item>
        <el-form-item label="拼柜仓库" :label-width="formLabelWidth" prop="lclPlace">
          <f-select
              v-model="addCabinetForm.lclPlace"
              :isNeed="addCabinetForm.lclPlace"
              :dict="'lcl_place'"
          ></f-select>
        </el-form-item>
        <el-form-item label="运输方式" :label-width="formLabelWidth" prop="transportType">
          <f-select
              v-model="addCabinetForm.transportType"
              :isNeed="addCabinetForm.transportType"
              :dict="'os_send_type'"
          ></f-select>
        </el-form-item>
        <el-form-item label="开港时间" :label-width="formLabelWidth" prop="departureTime">
          <el-date-picker
              class="f-flex-1"
              v-model="addCabinetForm.departureTime"
              type="datetime"
              size="small"
              placeholder="请选择时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="editStartOptions"
              format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>


        <el-form-item
            label="专线/拆柜"
            :label-width="formLabelWidth"
            prop="cabinetCategory"

        >
          <f-select
              v-model="addCabinetForm.cabinetCategory"
              :isNeed="addCabinetForm.cabinetCategory"
              :dict="'cabinet_category'"
              :disabledCondition="disabledCondition"
              @changet="changeWeekName"
          ></f-select>
        </el-form-item>
        <el-form-item
            label="柜子用途"
            :label-width="formLabelWidth"
            prop="weekStatus"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="addCabinetForm.weekStatus"
              placeholder="请选择"
          >
            <el-option key="0" label="自拼" value="0"></el-option>
            <el-option key="1" label="非自拼/整柜" value="1"></el-option>
            <el-option key="2" label="传统物流" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="是否虚拟柜"
            :label-width="formLabelWidth"
            prop="weekStatus"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="addCabinetForm.isVirtualCabinet"
              placeholder="请选择"
              @change="$forceUpdate()"
          >
            <el-option key="0" label="自拼" value="0"></el-option>
            <el-option key="1" label="非自拼" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="是否线上仓位"
            :label-width="formLabelWidth"
            prop="isOnlineWarehouse"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="addCabinetForm.isOnlineWarehouse"
              placeholder="请选择"
              clearable
          >
            <el-option key="0" label="否" value="0"></el-option>
            <el-option key="1" label="是" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormAddCabinetVisible = false ;  addCabinetForm = {}"
        >取 消
        </el-button
        >
        <el-button type="primary" @click="addCabinet">确 定</el-button>
      </div>
    </el-dialog>
    <son-cabinet :container-id="rowContainerId" :visible.sync="sonVisible" :table-list="sonTableList" @close="close"></son-cabinet>
  </div>
</template>

<script>
import QueryMixins from "../../../mixins/QueryMixins";
import {getAction, postAction, putAction} from "@/api/manage";
import {setUpRouteUtil} from '@/utils/util';
import SonCabinet from "@/views/operation/cabinet/SonCabinet.vue";
import {validateUnitValue} from "@/utils/validator";

export default {
  name: "ContactModule",
  mixins: [QueryMixins],
  components: {SonCabinet},
  data() {
    return {
      queryFormLabelWidth:'120px',
      rowContainerId:'',
      isUsePost:true,
      showLoadingSearch: false,
      editStartOptions: {
        disabledDate: time => {
          if (!this.queryForm.createTimeEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.queryForm.createTimeEnd);
          }
        }
      },
      // rulesForm: {
      //     priceExplainName: [
      //         {required: true, message: '请填写排柜', trigger: 'change'},
      //     ],
      // },

      rules: {
        preVolume: [
          {validator: validateUnitValue, trigger: 'change'}
        ],
        /* airRoute: [
             {required: true, message: "请选择航线", trigger: "change"},
         ],
         lclPlaceId: [
             {required: true, message: "请选择拼柜仓库", trigger: "change"},
         ],
         bdPortId: [
             {required: true, message: "请选择港口", trigger: "change"},
         ],*/
        pol: [
          {required: true, message: "请选择起运港", trigger: "change"},
        ],
        podEnd: [
          {required: true, message: "请选择目的港", trigger: "change"},
        ],
        cabinetCategory: [
          {required: true, message: "请选择专线/拆柜", trigger: "change"},
        ],
        week: [{required: true, message: "请填写周数", trigger: "change"}],
        size: [{required: true, message: "请选择柜型", trigger: "change"}],
        weekStatus: [
          {required: true, message: "请选择柜子用途", trigger: "change"},
        ],
        isVirtualCabinet: [
          {required: true, message: "请选择是否虚拟柜", trigger: "change"},
        ],
      },
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      dictCodes: [
        "os_declare_type",
        "invoice_status",
        "cus_important",
        "customer_type",
      ],
      btnDisable: false,
      form: {},
      addCabinetForm: {},
      declareList: {},
      formNunber: {},
      weekList: [],
      dialogFormVisible: false,
      dialogFormVisible2: false,
      loading2:false,
      dialogFormAddCabinetVisible: false,
      formLabelWidth: "120px",
      row: {},
      visible: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: 'left'
        },
        {
          prop: "action",
          label: "操作",
          minWidth: 80,
          fixed: 'left'
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 120,
          fixed: 'left'
        },
        {
          prop: "isWarning",
          label: "问题件",
          minWidth: 80,
          fixed: 'left'
        },
        {
          prop: "largeInfos",
          label: "超大件",
          minWidth: 80,
          fixed: 'left'
        },
        {
          prop: "solicitationText",
          label: "航线名称",
          minWidth: 80,
        },
        {
          prop: "shipmentCountry",
          label: "国家",
          minWidth: 80,
        },
        {
          prop: "deliveryPointName",
          label: "送货仓库",
          minWidth: 80,
        },
        {
          prop: "lclPlaceName",
          label: "拼柜仓库",
          minWidth: 80,
        },
        {
          prop: "location",
          label: "库位",
          minWidth: 50,
        },

        {
          prop: "allVolumeLocation",
          label: "货物所在仓",
        },
        {
          prop: "channelName",
          label: "渠道名称",
          minWidth: 80,
        },
        {
          prop: "shipmentCode",
          label: "仓库代码",
          minWidth: 80,
        },
        {
          prop: "sensitiveType",
          label: "订单产品定义",
          minWidth: 80,
        },
        {
          prop: "shipmentZip",
          label: "邮编",
          minWidth: 80,
        },
        {
          prop: "realCtn",
          label: "实件",
          headerType: "rang",
          minWidth: 60,
          sortable: "realCtn",
        },
        {
          prop: "ctn",
          label: "预报件数",
          headerType: "rang",
          minWidth: 60,
          sortable: "ctn",
        },
        {
          prop: "kg",
          label: "预报毛重",
          headerType: "rang",
          minWidth: 60,
          sortable: "kg",
        },
        {
          prop: "cmb",
          label: "预报体积",
          headerType: "rang",
          minWidth: 60,
          sortable: "cmb",
        },
        {
          prop: "realCmb",
          label: "实际体积",
          headerType: "rang",
          minWidth: 60,
          sortable: "realCmb",
        },

        {
          prop: "username",
          label: "客户",
          minWidth: 100,
          fixed: 'left'
        },
        {
          prop: "declareType",
          label: "报关方式",
          minWidth: 50,
        },
        {
          prop: "invoiceStatus",
          label: "发票状态",
          minWidth: 50,
        },
        {
          prop: "clearanceOfGoods",
          label: "是否上传报关材料",
          minWidth: 80,
        },
        {
          prop: "cargoPicked",
          label: "提货时间",
          minWidth: 80,
          sortable: "custom",
        },
        // {
        //     prop: "isRucang",
        //     label: "是否入仓",
        //     minWidth: 50,
        // },
        {
          prop: "cargoArrived",
          label: "入仓时间",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "pgRemark",
          label: "排柜备注",
          minWidth: 100,
        },
        {
          prop: "productNameCn",
          label: "中文品名",
          minWidth: 100,
          overFlow: true,
        },
        {
          prop: "salesman",
          label: "业务员",
          minWidth: 50,
        },
        {
          prop: "doc",
          label: "客服",
          minWidth: 50,
        },
        {
          prop: "operator",
          label: "操作员",
          minWidth: 50,
        },

        // {
        //     prop: "cargoArrived",
        //     label: "入仓时间",
        //     headerType: "datetime",
        //     minWidth: 215,
        //     sortable: "custom",
        // },

        {
          prop: "grade",
          label: "客户等级",
          minWidth: 80,
        },
        {
          prop: "shipmentAddress",
          label: "地址",
          minWidth: 100,
        },
        {
          prop: "withinOrderRemark",
          label: "内部备注",
          minWidth: 100,
        },
        {
          prop: "storehouseRemark",
          label: "入仓备注",
          minWidth: 100,
        },

        {
          prop: "createBy",
          label: "材积录入人",
          minWidth: 50,
        },
        {
          prop: "hasTaxType",
          label: "是否包税",
          minWidth: 50,
        },


        /*{
            prop: "fbaCode",
            label: "fbaCode",
            minWidth: 80,
        },
        {
            prop: "zip",
            label: "zip",
            minWidth: 80,
        },*/
        // {
        //     prop: "isVirtualCabinet",
        //     label: "是否自拼",
        //     headerType: "string",
        //     minWidth: 180,
        //     sortable: "isVirtualCabinet",
        // },
      ],
      url: {
        list: "/operation/container/list",
        addCabinet: "/operation/container/addCabinet",
        cabinetList: "/operation/container/cabinetList",
        cabinetList2: "/operation/container/cabinetList2",
        updateBatchOrder: "/operation/container/updateBatchOrder",
        exportXlsUrl: '/file/export/excel/downloadWaitingCabinetExcel',  //导出
        getSonList:'/operation/container/getSonContainerListByContainerId',
      },
      redAllCmb: false, //总体积是否显示红色
      disabledCondition: [],  //f-select中选项禁用条件
      totalCmb: 0,
      totalKg: 0,
      totalRealCmb:0,
      sonVisible:false,
      sonTableList:[],
      whichWeekStr :'',
      formatWeekStr:'',
    };
  },
  created() {
    this.init();
  },
  methods: {
    changeWhichWeek(){
      const now = new Date(this.addCabinetForm.whichWeek);
      const nowTime = now.getTime();
      const day = now.getDay();
      const oneDayTime = 24*60*60*1000;
      const mondayTime = nowTime - (day-1)*oneDayTime;
      const sundayTime = nowTime + (7-day)*oneDayTime;
      const startTime = this.$momonet(mondayTime).format('MM.DD');
      const endTime = this.$momonet(sundayTime).format('MM.DD');
      let formatY = this.$momonet(now).format('yyyy');
      let formatW = this.$momonet(now).format('WW');
      if (formatW !== '52') {
        formatW = Number(formatW) + Number(1);
      }else {
        formatW = '1';
        formatY = Number(formatY) + Number(1);
      }
      // :format="'yyyy第WW周' + whichWeekStr"
      console.log("选择周期的开始日期：" + startTime + "，结束日期：" + endTime);
      this.whichWeekStr = "(" + startTime + "-" + endTime + ")";
      this.formatWeekStr = formatY + "第" + formatW + '周' + this.whichWeekStr;
      this.$nextTick(() => {
        let a = document.querySelector("#getWhichWeek").value
        // this.queryForm.whichWeek = JSON.stringify(JSON.stringify(a))
        this.addCabinetForm.whichWeekStr = a
      })

    },
    getSonList(row){
      this.rowContainerId = row.id;
      postAction(this.url.getSonList,{id:row.id})
          .then(res=>{
            this.sonTableList = res.result;
            this.sonVisible = true;
          })
    },
    close(){
      this.sonVisible = false;
    },
    // changeCellStyle (row, column, rowIndex, columnIndex) {
    //   let colorName = this.parseDict(row.row.showColor,'fba_addr_show_color');
    //   // 应收-是否已销账添加背景色
    //   return this.getColorByName(colorName);
    //
    // },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.toExamine = '0';
      if (this.$refs['GTable']) {
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },
    handleEdit({id}) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/customer/Edit');
      this.$router.push(`/customer/Edit?id=${id}`)
    },
    weekName() {
      var date = new Date();
      let week = this.theWeek()
      let lclPlace = "";
      if (this.addCabinetForm.lclPlaceId != null) {
        if (this.addCabinetForm.lclPlaceId === 'f84d33a6c3464dbdb16e643e04263b0a') {
          lclPlace = 'B' //上海青浦仓
        } else if (this.addCabinetForm.lclPlaceId === 'f84d33a6c3464dbdb16e643e04263b0b') {
          lclPlace = 'D' //厦门海沧仓
        } else if (this.addCabinetForm.lclPlaceId === 'f84d33a6c3464dbdb16e643e04263b0e') {
          lclPlace = 'C' //宁波北仑仓
        } else if (this.addCabinetForm.lclPlaceId === 'f84d33a6c3464dbdb16e643e04263b0f') {
          lclPlace = 'A'  //东莞凤岗仓
        } else if (this.addCabinetForm.lclPlaceId === '1435131743802757121') {
          lclPlace = 'E'  //杭州仓
        } else if (this.addCabinetForm.lclPlaceId === '1435548563772805122') {
          lclPlace = 'F'  //盐田仓
        } else if (this.addCabinetForm.lclPlaceId === '1468779439459008514') {
          lclPlace = 'G'  //青岛仓
        }
      }

      let airRoute = "";
      if (this.addCabinetForm.airRoute != null) {
        airRoute = this.addCabinetForm.airRoute;
      }

      let bdPort = "";
      if (this.addCabinetForm.bdPortId != null) {

        bdPort = this.addCabinetForm.bdPortId
      }


      let cabinetCategory = ""
      if (this.addCabinetForm.cabinetCategory != null) {

        if (this.addCabinetForm.cabinetCategory === '1') {
          cabinetCategory = "Z"
        } else {
          cabinetCategory = "C"
        }
      }

      console.log(cabinetCategory)

      let fbaCode = "";

      if (this.addCabinetForm.fbacode != null) {
        fbaCode = this.addCabinetForm.fbacode
      }


      this.addCabinetForm.week = date.getFullYear() + '' + week + lclPlace + airRoute + bdPort + cabinetCategory + fbaCode


    },
    theWeek() {
      var totalDays = 0;
      let now = new Date();
      let years = now.getYear()
      if (years < 1000)
        years += 1900
      var days = new Array(12);
      days[0] = 31;
      days[2] = 31;
      days[3] = 30;
      days[4] = 31;
      days[5] = 30;
      days[6] = 31;
      days[7] = 31;
      days[8] = 30;
      days[9] = 31;
      days[10] = 30;
      days[11] = 31;
      //判断是否为闰年，针对2月的天数进行计算
      if (Math.round(now.getYear() / 4) === now.getYear() / 4) {
        days[1] = 29
      } else {
        days[1] = 28
      }
      if (now.getMonth() === 0) {
        totalDays = totalDays + now.getDate();
      } else {
        var curMonth = now.getMonth();
        for (var count = 1; count <= curMonth; count++) {
          totalDays = totalDays + days[count - 1];
        }
        totalDays = totalDays + now.getDate();
      }
      //得到第几周
      let week = Math.round(totalDays / 7);
      return week;
    },
    async init() {
      let that = this;
      //获取周期
      getAction(this.url.cabinetList)
          .then((res) => {
            console.log(res);
            if (res.success) {
              that.weekList = res.result;
              console.log(that.weekList);
            }
          })
          .catch(() => {
          });
    },
    getRemoteCabinetList(query){
      this.loading2 = true;
      console.log("远程查询",query);
      this.weekList = [];
      getAction(this.url.cabinetList2 + "?week=" + query)
          .then(res => {
            this.weekList = res.result;
            this.loading2 = false;
          })
    },
    changeSize() {
      let weekItem = {};
      for (let i = 0; i < this.weekList.length; i++) {
        if (this.weekList[i].id === this.formNunber.id) {
          weekItem = this.weekList[i];
          this.formNunber.allCmbCanUse = this.weekList[i].volume;

          this.formNunber.alreadyCtn =
              this.weekList[i].realCtn == null ? 0 : this.weekList[i].realCtn;
          this.formNunber.alreadyCmb =
              this.weekList[i].realCmb == null ? 0 : this.weekList[i].realCmb;
          this.formNunber.alreadyKg =
              this.weekList[i].realKg == null ? 0 : this.weekList[i].realKg;

          this.formNunber.allCtn =
              Number(this.formNunber.alreadyCtn) +
              Number(this.formNunber.waitingCtn);
          this.formNunber.allKg = (
              parseFloat(this.formNunber.alreadyKg) +
              parseFloat(this.formNunber.waitingKg)
          ).toFixed(3);
          this.formNunber.allCmb = (
              parseFloat(this.formNunber.alreadyCmb) +
              parseFloat(this.formNunber.waitingCmb)
          ).toFixed(3);
        }
      }
      this.redAllCmbSize(weekItem.size);
      console.log(this.formNunber.id);
    },
    // 总体积显示红色逻辑
    redAllCmbSize(size) {
      switch (size) {
        case '40NOR':
          this.redAllCmb = this.formNunber.allCmb > 60;
          break;
        case '40HQ':
          this.redAllCmb = this.formNunber.allCmb > 70;
          break;
        case '45HQ':
          this.redAllCmb = this.formNunber.allCmb > 78;
          break;
        default:
          this.redAllCmb = false;
      }
    },
    WaitingCabinet(row) {
      console.log(row);
      this.init();
      this.dialogFormVisible = true;
      this.form = row;
      this.$set(this, 'formNunber', {});
      this.formNunber.waitingCtn = row.realCtn == null ? 0 : row.realCtn;
      this.formNunber.waitingKg = row.realKg == null ? 0 : row.realKg;
      this.formNunber.waitingCmb = row.realCmb == null ? 0 : row.realCmb;
      this.formNunber.orderNo = row.orderNo;
    },
    handleSelectionChange(selection) {
      console.log();
      let arr = []
      this.nameList = []
      this.cnamelist = []
      this.emailList = []
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.nameList.push(selection[i]['username'])
        this.cnamelist.push(selection[i]['cname'])
        this.emailList.push(selection[i]['email'])
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr];
      console.log('this.selection', selection)
      // 计算选择订单的总毛重总体积
      this.getTotalKgCmb();
    },
    // 计算选择订单的总毛重总体积
    getTotalKgCmb() {
      let totalKg = 0, totalCmb = 0, totalRealCmb = 0;
      this.selectionRows.forEach(element => {
        totalKg = totalKg + parseFloat(element.kg == null ? 0 : element.kg);
        totalCmb = totalCmb + parseFloat(element.cmb == null ? 0 : element.cmb);
        totalRealCmb = totalRealCmb + parseFloat(element.realCmb == null ? 0 : element.realCmb);
      });
      this.totalKg = totalKg.toFixed(3);
      this.totalCmb = totalCmb.toFixed(3);
      this.totalRealCmb = totalRealCmb.toFixed(3);
    },
    //批量排柜
    handleBatchWaitingCabinet() {
      let flag = true;

      this.selectionRows.forEach(s=>{
        if ('1'===s.isWarning) {
          flag = false;
          return;
        }
      })
      if (!flag) {
        this.$message.warning("存在有问题件的数据，不允许排柜，请重新选择");
        return false;
      }
      this.dialogFormVisible = true;
      this.$set(this, 'formNunber', {});
      if (this.selectionRows.length > 0) {
        let ctn = 0;
        let kg = 0;
        let cmb = 0;
        let orderNo = "";
        for (let i = 0; i < this.selectionRows.length; i++) {
          ctn = (
              parseFloat(ctn) +
              parseFloat(
                  this.selectionRows[i].realCtn == null
                      ? 0
                      : this.selectionRows[i].realCtn
              )
          ).toFixed(3);
          kg = (
              parseFloat(kg) +
              parseFloat(
                  this.selectionRows[i].realKg == null
                      ? 0
                      : this.selectionRows[i].realKg
              )
          ).toFixed(3);
          cmb = (
              parseFloat(cmb) +
              parseFloat(
                  this.selectionRows[i].realCmb == null
                      ? 0
                      : this.selectionRows[i].realCmb
              )
          ).toFixed(3);
          orderNo = orderNo + this.selectionRows[i].orderNo + ",";
        }
        orderNo = orderNo.substring(0, orderNo.length - 1);
        this.formNunber.waitingCtn = ctn;
        this.formNunber.waitingKg = kg;
        this.formNunber.waitingCmb = cmb;
        this.formNunber.orderNo = orderNo;
      }
    },
    handleBatchWaitingCabinet2() {
      this.weekList = [];
      let flag = true;

      this.selectionRows.forEach(s=>{
        if ('1'===s.isWarning) {
          flag = false;
          return;
        }
      })
      if (!flag) {
        this.$message.warning("存在有问题件的数据，不允许排柜，请重新选择");
        return false;
      }
      this.dialogFormVisible2 = true;
      this.$set(this, 'formNunber', {});
      if (this.selectionRows.length > 0) {
        let ctn = 0;
        let kg = 0;
        let cmb = 0;
        let orderNo = "";
        for (let i = 0; i < this.selectionRows.length; i++) {
          ctn = (
              parseFloat(ctn) +
              parseFloat(
                  this.selectionRows[i].realCtn == null
                      ? 0
                      : this.selectionRows[i].realCtn
              )
          ).toFixed(3);
          kg = (
              parseFloat(kg) +
              parseFloat(
                  this.selectionRows[i].realKg == null
                      ? 0
                      : this.selectionRows[i].realKg
              )
          ).toFixed(3);
          cmb = (
              parseFloat(cmb) +
              parseFloat(
                  this.selectionRows[i].realCmb == null
                      ? 0
                      : this.selectionRows[i].realCmb
              )
          ).toFixed(3);
          orderNo = orderNo + this.selectionRows[i].orderNo + ",";
        }
        orderNo = orderNo.substring(0, orderNo.length - 1);
        this.formNunber.waitingCtn = ctn;
        this.formNunber.waitingKg = kg;
        this.formNunber.waitingCmb = cmb;
        this.formNunber.orderNo = orderNo;
      }
    },
    //打开新增柜子页面
    handleAddCabinet() {
      //周数操作部要求手动填写
      /*  var date = new Date();
        let week = this.theWeek()
        console.log(date.getFullYear())
        console.log(week)
        this.addCabinetForm.week = date.getFullYear() + '' + week*/

      this.dialogFormAddCabinetVisible = true;
      this.addCabinetForm.isVirtualCabinet = '0';
      this.addCabinetForm.transportType = '1';
    },
    //保存新增柜子  cami 给的标准
    /*20GP    35
    40GP    65
    40RH    65
    40HQ   75
    45HQ   85
    40NOR 65*/
    addCabinet() {
      this.addCabinetForm.volume = 10000;
      /*if (this.addCabinetForm.size == "20GP") {
          this.addCabinetForm.volume = 10000;
      } else if (this.addCabinetForm.size == "40GP") {
          this.addCabinetForm.volume = 10000;
      } else if (this.addCabinetForm.size == "40RH") {
          this.addCabinetForm.volume = 10000;
      } else if (this.addCabinetForm.size == "40HQ") {
          this.addCabinetForm.volume = 10000;
      } else if (this.addCabinetForm.size == "45HQ") {
          this.addCabinetForm.volume = 10000;
      } else if(this.addCabinetForm.size == "40NOR"){
          this.addCabinetForm.volume = 10000;
      }*/
      this.$refs["addCabinetForm"].validate((valid) => {
        if (valid) {

          postAction(this.url.addCabinet, this.addCabinetForm)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormAddCabinetVisible = false;
                  this.init();
                  this.handleSearch();
                  this.addCabinetForm = {}
                  localStorage.removeItem('dict:os_container');
                }
              })
        } else {
          return false;
        }
      });
    },
    saveOrderNoCabinet(type) {
      console.log(this.formNunber.allCmbCanUse);
      console.log(this.formNunber.allCmb);

      if (
          Number(this.formNunber.allCmb) > Number(this.formNunber.allCmbCanUse)
      ) {
        this.$message.warning(
            "该柜子的最大容积量为：" +
            this.formNunber.allCmbCanUse +
            ",待排总体积为:" +
            this.formNunber.allCmb +
            ",请重新选择容积量满足的柜子！"
        );
        return;
      }

      if (this.formNunber.id == null || this.formNunber.id === '') {
        this.$message.warning("请选择周数！");
        return;
      }

      this.formNunber.orderNoList = this.formNunber.orderNo.split(",");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(type == 1){
            this.formNunber.paiguiType = "1"
          }
          putAction(this.url.updateBatchOrder, this.formNunber)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.handleSearch();
                  this.dialogFormVisible = false;
                  this.dialogFormVisible2 = false;
                  this.formNunber = {};
                  this.init();
                }
              })
        } else {
          return false;
        }
      });

      //saveOrderNoCabinet
    },

    //进入客服页
    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    },
    // 新增柜子-柜型变化触发
    changeCabinetType(value) {
      // 40NORvalue为40NOR   专线value值为1
      this.disabledCondition = value === '40NOR' ? ['1'] : [];
    },
    // 新增柜子-专线/拆柜变化触发
    changeWeekName(value) {
      this.disabledCondition = value === '专线' ? ['40NOR'] : [];
    },
  },
  computed: {
    buttonShowList() {
      return {
        '无条件排柜': this.$btnIsShow('operation/cabinet/WaitingCabinet','1','无条件排柜'),
        '导出': this.$btnIsShow('operation/cabinet/WaitingCabinet','0','导出'),
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.elrow {
  padding-left: 60px;
}

.elrow-a {
  padding-left: 15px;
}

.red_color {
  color: red;
}

.bottom_tw {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
</style>
