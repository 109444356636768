<!--海运报价-待发布-->
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="船公司">
                            <f-select
                                    v-model="queryForm.shippingCompanyId"
                                    :is-need="queryForm.shippingCompanyId"
                                    :dict="'ship_company'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港" :label-width="formLabelWidth">

                            <f-select
                                    v-model="queryForm.startPortId"
                                    :is-need="queryForm.startPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港" :label-width="formLabelWidth">

                            <f-select
                                    v-model="queryForm.endPortId"
                                    :is-need="queryForm.endPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="batchRelease" :disabled="selectionRows.length === 0"
                           v-if="buttonShowList.pushBatch"
                >
                    批量发布
                </el-button>
                <el-button type="primary" size="medium" @click="allRelease" v-if="buttonShowList.onePush">一键发布</el-button>
                <el-button type="primary" size="medium" @click="batchPriceDiog" :disabled="selectionRows.length === 0"
                           v-if="buttonShowList.tiaoBatch"
                >
                    批量调价
                </el-button>
                <el-button type="primary" size="medium" @click="allPriceDiog" v-if="buttonShowList.oneTiao">一键调价</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="fId"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="release(scope.row)" v-if="buttonShowList.push">发布</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'startTime'">
                        <span>{{formatterTime(scope.row.startTime)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'endTime'">
                        <span>{{formatterTime(scope.row.endTime)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'createBy'">
                        <span>{{parseDict(scope.row.createBy,'all_user_id')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog title="调价" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="20GP" :label-width="formLabelWidth" prop="twentyGp">
                    <el-input v-model="form.twentyGp" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="40GP" :label-width="formLabelWidth" prop="fortyGp">
                    <el-input v-model="form.fortyGp" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="40HQ" :label-width="formLabelWidth" prop="fortyHq">
                    <el-input v-model="form.fortyHq" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="45HQ" :label-width="formLabelWidth" prop="fortyFiveHq">
                    <el-input v-model="form.fortyFiveHq" autocomplete="off" type="number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="confirmRelease">确认并发布</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确认</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction} from '@/api/manage'
    import QueryMixins from "../../mixins/QueryMixins";
    import {zhengshuxiaoshuPartten} from "../../utils/pattern";
    import {getBdPortList} from "../../api/manage";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['all_user_id'],
                rules: {
                    twentyGp: [
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    fortyGp: [
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    fortyHq: [
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ],
                    fortyFiveHq: [
                        {pattern: zhengshuxiaoshuPartten, message: '请输入正整数或最多三位小数', trigger: 'change'}
                    ]
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '100px',
                dialogFormVisible: false,
                aa: 1,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 140,
                    },
                    {
                        prop: 'shippingCompany',
                        label: '船公司',
                        width: 120
                    },
                    {
                        prop: 'startPort',
                        label: '起运港',
                        width: 110
                    },
                    {
                        prop: 'endPort',
                        label: '目的港',
                        width: 110
                    },
                    {
                        prop: 'transferPort',
                        label: '中转港',
                        width: 110
                    },
                    {
                        prop: 'voyage',
                        label: '航程(天)',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'twentyGp',
                        label: '20GP',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'fortyGp',
                        label: '40GP',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'fortyHq',
                        label: '40HQ',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'fortyFiveHq',
                        label: '45HQ',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'startTime',
                        label: '生效日期',
                        sortable: 'custom',
                    },
                    {
                        prop: 'endTime',
                        label: '截止日期',
                        sortable: 'custom',
                    },
                    {
                        prop: 'remark',
                        label: '备注提醒',
                    },
                    {
                        prop: 'createBy',
                        label: '调价人',
                    },
                ],
                url: {
                    list: '/sys/salePrice/salePicePage',
                    release: '/sys/salePrice/releasePriceSale',  //发布
                    batchRelease: '/sys/salePrice/batchReleasePriceSale', //批量发布
                    allRelease: '/sys/salePrice/allReleasePriceSale', //一键发布
                    batchPrice: '/sys/salePrice/batchPriceAgainSale', //已调价 批量调价
                    allPrice: '/sys/salePrice/allPriceAgainSale', //已调价 一键调价
                },
            }
        },
        methods: {
            initBdPortList() {
                if (this.allBdportList.length <= 0) {
                    this.allBdportList = [];
                    getBdPortList().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].code + '-' + res.result[i].cnName;
                                this.allBdportList.push(obj);
                            }
                            this.bdPortList = this.allBdportList.slice(0, 100);
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }

            },
            formatterTime(data) {
                return data.substring(0, 10)
            },
            init() {
                //销售已调价列表
                this.queryForm.saleType = "2";
                //初始化港口基础数据
                this.queryForm.initBdPort = true;
            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['fid'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },

            //批量发布
            batchRelease() {
                this.$confirm('确定发布？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.batchRelease + "?ids=" + this.selectedRowKeys.join(',')).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormVisible = false;
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            //一键发布
            allRelease() {
                this.$confirm('确定发布？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.allRelease).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormVisible = false;
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            //批量调价
            batchPriceDiog() {
                this.form = {};
                this.dialogFormVisible = true;
                this.aa = 1;
            },
            //一键调价
            allPriceDiog() {
                this.form = {};
                this.dialogFormVisible = true;
                this.aa = 2;
            },
            //确认按钮
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        if (this.aa === 2) {
                            //一键调价
                            this.form.status = false; //只调价不发布
                            this.allPrice();

                        }
                        if (this.aa === 1) {
                            //批量调价
                            this.form.status = false; //只调价不发布
                            this.batchPrice();

                        }
                    }
                })

            },
            //确认并发布
            confirmRelease() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        if (this.aa === 2) {
                            //一键调价
                            this.form.status = true; //调价并发布
                            this.allPrice();

                        }
                        if (this.aa === 1) {
                            //批量调价
                            this.form.status = true; //调价并发布
                            this.batchPrice();

                        }
                    }
                })
            },
            //批量调价
            batchPrice() {
                this.$confirm('确定调价？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.batchPrice + "?ids=" + this.selectedRowKeys.join(','), this.form).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormVisible = false;
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            //一键调价
            allPrice() {
                this.$confirm('确定调价？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.allPrice, this.form).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormVisible = false;
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            //发布
            release(row) {
                this.$confirm(`确定发布`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.release + "?id=" + row.fid).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormVisible = false;
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.saleType = "2";
                this.ipagination.currentPage = 1
                this.handleQuery()
            }
        }, created() {
            this.init();
        },
      computed:{
          buttonShowList() {
            return {
              'pushBatch':this.$btnIsShow('channel/OceanShippingPriceChangeSale','0','批量发布'),
              'onePush':this.$btnIsShow('channel/OceanShippingPriceChangeSale','0','一键发布'),
              'oneTiao':this.$btnIsShow('channel/OceanShippingPriceChangeSale','0','一键调价'),
              'tiaoBatch':this.$btnIsShow('channel/OceanShippingPriceChangeSale','0','批量发布'),
              'push':this.$btnIsShow('channel/OceanShippingPriceChangeSale','1','发布'),
            }
          }
      }
    }
</script>

<style lang="scss" scoped>

</style>
