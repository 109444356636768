<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="标题">
              <el-input v-model.trim="queryForm.title" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="标题英文">
              <el-input v-model.trim="queryForm.titleEn" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" icon="el-icon-plus" v-if="buttonShowList['新增']" @click="handleEdit({})">
          新增
        </el-button>
        <el-button type="danger" icon="el-icon-delete" :disabled="selectionRows.length == 0" v-if="buttonShowList['删除']"
          @click="handleDel()">
          批量删除
        </el-button>
      </div>
      <f-table v-loading="loading" border ref="GTable" size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :isorter="isorter" :show-operation="true" :showSlot="true"
        :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
        @sort-change="handleTableChange" @queryChange="handleTableQueryChange">
        <template v-slot:showSlot="{ item }">
          <template v-if="item.prop == 'chinese'">
            <el-table-column prop="title" label="标题">
            </el-table-column>
            <el-table-column prop="coverUrl" label="封面图片">
              <template #default="scope">
                <img class="company_img" @click="() => { imgUrl = scope.row.coverUrl; visibleFile = true; }"
                  v-if="scope.row.coverUrl" :src="scope.row.coverUrl" alt="">
              </template>
            </el-table-column>
          </template>
          <template v-if="item.prop == 'english'">
            <el-table-column prop="titleEn" label="标题">
            </el-table-column>
            <el-table-column prop="coverUrlEn" label="封面图片">
              <template #default="scope">
                <img class="company_img" @click="() => { imgUrl = scope.row.coverUrlEn; visibleFile = true; }"
                  v-if="scope.row.coverUrlEn" :src="scope.row.coverUrlEn" alt="">
              </template>
            </el-table-column>
          </template>
        </template>
        <template #default="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button v-if="buttonShowList['编辑']" type="text" size="small" @click="handleEdit(scope.row)">
              编辑
            </el-button>
            <el-button v-if="buttonShowList['置顶'] && scope.row.isTop !== '1'" type="text" size="small" @click="handleTop(scope.row)">
              置顶
            </el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.row, 'view')">预览</el-button>
          </template>
          <template v-else-if="scope.column.property === 'isTop'">
            <span>{{ scope.row.isTop == '1' ? '是' : '否' }}</span>
          </template>
        </template>
      </f-table>
    </el-card>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    <news-information-dialog :visible="visible" :row="row" :type="type" @ok="handleQuery" @update:visible="(value) => {
      visible = value;
    }
      "></news-information-dialog>
    <preview-img :visible="visibleFile" :imgUrl="imgUrl"
      @setImgVisible="(val) => { imgUrl = ''; visibleFile = val }"></preview-img>
  </div>
</template>

<script>
import { putAction, deleteAction,postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import NewsInformationDialog from "./components/NewsInformationDialog";

export default {
  name: "TalentRecruitment",
  mixins: [QueryMixins],
  components: {
    NewsInformationDialog,
  },
  data() {
    return {
      row: {},
      type: '',
      visible: false,
      visibleFile: false,
      imgUrl: '',
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "chinese",
          label: '中文',
        },
        {
          prop: "english",
          label: '英文',
        },
        {
          prop: "isTop",
          label: '是否置顶',
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "action",
          label: '操作',
          width: 200,
        },
      ],
      url: {
        list: "/sys/managerFind/queryList",
        delete: "/sys/managerFind/deleteBatch",
        top: "/sys/managerFind/topManageFind",
      },
      initData: { pushPort: '1' }
    };
  },
  computed: {
    buttonShowList() {
      return {
        '新增': this.$btnIsShow('forestMarket/NewsInformation','0', '新增'),
        '编辑': this.$btnIsShow('forestMarket/NewsInformation','1', '编辑'),
        '删除': this.$btnIsShow('forestMarket/NewsInformation','0', '删除'),
        '置顶': this.$btnIsShow('forestMarket/NewsInformation','1', '置顶'),
      }
    }
  },
  created() { },
  methods: {
    handleEdit(row, type) {
      this.row = row;
      this.type = type;
      this.visible = true;
    },
    handleTop(row) {
      this.$confirm('确定置顶?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: "warning",
        closeOnClickModal: false,
        buttonSize: "small",
      })
        .then(() => {
          this.$store.commit("setFullscreenLoading", true);
          putAction(this.url.top + '?id=' + row.id)
            .then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message.success(res.message);
              this.handleQuery();
            })
            .catch((err) => {
              this.$store.commit("setFullscreenLoading", false);
            });
        })
        .catch(() => { });
    },
    handleDel() {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: "warning",
        closeOnClickModal: false,
        buttonSize: "small",
      })
        .then(() => {
          this.$store.commit("setFullscreenLoading", true);
          console.log()
          postAction(this.url.delete, { ids: this.selectedRowKeys})
            .then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message(res.message, "success");
              this.handleQuery();
            })
            .catch((err) => {
              this.$store.commit("setFullscreenLoading", false);
            });
        })
        .catch(() => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.company_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}
</style>
