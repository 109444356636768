
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"
                                      type="textarea" placeholder="多个精确匹配"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="FBA订单号">
                            <el-input  v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"
                                      type="textarea" placeholder="多个精确匹配"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="转运单号">
                      <el-input  v-model="queryForm.transferNo" @keyup.enter.native="handleSearch"
                                 type="textarea" placeholder="多个精确匹配"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="柜号">
                      <el-input  v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                                 type="textarea" placeholder="多个精确匹配"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="已买保险">
                            <f-select
                                v-model="queryForm.isInsured"
                                :isNeed="queryForm.isInsured"
                                :dict="'unit_whether'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="需要保险">
                      <f-select
                          v-model="queryForm.insurance"
                          :isNeed="queryForm.insurance"
                          :dict="'unit_whether'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名">
                            <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="操作员">
                        <f-select
                            v-model="queryForm.operateId"
                            :isNeed="queryForm.operateId"
                            :dict="'sys_user_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="客服">
                        <f-select
                            v-model="queryForm.docId"
                            :isNeed="queryForm.docId"
                            :dict="'sys_user_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="4" :sm="24">
                      <el-form-item label="目的地">
                        <f-select
                            v-model="queryForm.podEnd"
                            :isNeed="queryForm.podEnd"
                            :dict="'sys_port'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                      <el-form-item label="提柜时间">
                        <div class="f-flex">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.shipmentPickedTimeStart"
                              type="datetime"
                              size="small"
                              placeholder="开始时间"
                              default-time="00:00:00"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                          <span class="f-p-h-2">~</span>
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.shipmentPickedTimeEnd"
                              type="datetime"
                              size="small"
                              placeholder="结束时间"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
      <el-button type="primary" siz="small"  @click="add"
                 :disabled="selectedRowKeys.length === 0"
                 v-if="buttonShowList.sh"
      >审核</el-button>
        <el-card class="box-card">
            <el-table
                    ref="GTable"
                    v-loading="loading"
                    :data="tableData"
                    height="69vh"
                    style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table
                                :data="props.row.children"
                                align="center"
                                @selection-change="handleSelectionChange"
                                stripe>
<!--                            <el-table-column-->
<!--                                    align="center"-->
<!--                                    label="操作"-->
<!--                                    prop="action">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-button type="text" @click="handleSendComand(scope.row,'34',props.$index,null)" v-if="isCanSend(scope.row)">发送贴标指令</el-button>-->
<!--                                    <el-button type="text" @click="handleSendComand(scope.row,'35',props.$index,null)" v-if="isCanFinal(scope.row)">完成贴标</el-button>-->
<!--                                    <el-button type="text" @click="handAppointmentTime(scope.row,props.$index,null)" v-if="isCanPre(scope.row)">预约</el-button>-->
<!--                                    &lt;!&ndash;                    <el-button type="text" @click="handleSendComand(scope.row,'34')"&ndash;&gt;-->
<!--                                    &lt;!&ndash;                               v-if="scope.row.deliveryLabelLingNum == 0">派送成功&ndash;&gt;-->
<!--                                    &lt;!&ndash;                    </el-button>&ndash;&gt;-->
<!--                                    <el-button type="text" @click="handleSend(scope.row,props.$index)" v-if="isCanDelivery(scope.row)">派送成功</el-button>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
                          <el-table-column type="selection" width="44">
                          </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="贴标编码"
                                    prop="stickMarkNo">
                            </el-table-column>
                          <el-table-column
                              align="center"
                              label="贴标状态"
                              prop="status">
                            <template slot-scope="scope">
                              <span v-if="scope.row.status === '0'">未贴标</span>
                              <span v-if="scope.row.status === '1'" style="color: #ecbb1c">待贴标</span>
                              <span v-if="scope.row.status === '2'" style="color:#494ed7">贴标中</span>
                              <span v-if="scope.row.status === '3'" style="color:#49d752">已贴标</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                              align="center"
                              label="派送方式"
                              prop="deliveryType">
                            <template slot-scope="scope">
                              <span v-if="scope.row.deliveryType === '1'">卡车自提</span>
                              <span v-if="scope.row.deliveryType === '2'">快递自提</span>
                              <span v-if="scope.row.deliveryType === '3'">大森林卡派</span>
                              <span v-if="scope.row.deliveryType === '4'">大森林快递派</span>
                            </template>
                          </el-table-column>
<!--                          <el-table-column-->
<!--                              align="center"-->
<!--                              label="原产品sku"-->
<!--                              prop="oldSku">-->
<!--                          </el-table-column>-->
<!--                          <el-table-column-->
<!--                              align="center"-->
<!--                              label="新产品sku"-->
<!--                              prop="newSku">-->
<!--                          </el-table-column>-->
                            <el-table-column
                                    align="center"
                                    label="贴标件数"
                                    prop="quantity">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="FBA标签"
                                    prop="cusFbaMarkUrl">
                                <template slot-scope="scope">
                                  <a
                                      style="color: #00a0e9"
                                      :href="scope.row.cusFbaMarkUrl"
                                  >{{ scope.row.cusFbaMarkFileName }}</a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="快递公司"
                                    prop="expressCompany">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="跟踪单号"
                                    prop="expressNo">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="Shipment ID"
                                    prop="amazonShipmentCode">
                            </el-table-column>
                          <el-table-column
                              align="center"
                              label="Reference ID"
                              prop="amazonReferenceCode">
                          </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="派送地址"
                                    prop="address">
                            </el-table-column>
                          <el-table-column
                              align="center"
                              label="邮编"
                              prop="zip">
                          </el-table-column>
                            <el-table-column
                                    align="center"
                                    label="操作流程"
                                    prop="cusRemark">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                        label="周数"
                        prop="week">
                    <template slot-scope="scope">
                      <a
                          style="color: #00a0e9"
                          @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
                      >{{ scope.row.week }}</a>
                    </template>
                </el-table-column>
                <el-table-column
                        label="柜号"
                        prop="containerNo">
                </el-table-column>
                <el-table-column
                        label="提柜时间"
                        prop="shipmentPickedTime">
                </el-table-column>
                <el-table-column
                        label="订单号"
                        prop="orderNo">
                    <template slot-scope="scope">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                </el-table-column>
              <el-table-column
                  label="转运单号"
                  prop="transferNo">
              </el-table-column>
                <el-table-column
                        label="保险状态"
                        prop="isInsured">
                    <template slot-scope="scope">
                        <span v-if="scope.row.insurance === '0'">不需要保险</span>
                        <span v-else>需要保险<br>
                          <span v-if="scope.row.isInsured === '1'">已买保险</span>
                        <span v-else>未买保险</span>
                        </span>

                    </template>
                </el-table-column>

<!--                <el-table-column-->
<!--                        label="姓名"-->
<!--                        prop="name">-->
<!--                </el-table-column>-->
                <el-table-column
                        label="客户名"
                        prop="customer">
                    <template slot-scope="scope">
                      <a
                          style="color: #00a0e9"
                          @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                      >{{ scope.row.customer }}</a
                      >
                    </template>
                </el-table-column>
                <el-table-column
                        label="贴标件数"
                        prop="allQty">
                </el-table-column>
                <el-table-column
                        label="贴标情况"
                        prop="labelLingNum">
                    <template slot-scope="scope">
                        <span>已贴件数：{{scope.row.labeledQty}}</span>
                        <br>
                        <span>贴标中件数：{{scope.row.labelingQty}}</span>
                        <br>
                        <span>待贴标件数：{{scope.row.labelQty}}</span>
                        <br>
                      <span>未贴标件数：{{scope.row.noQty}}</span>
                      <br>
                        <span>全部件数：{{scope.row.allQty}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作员"
                        prop="operator">

                </el-table-column>
                <el-table-column
                        label="客服"
                        prop="doc">

                </el-table-column>
                <el-table-column
                        label="目的地"
                        prop="podEnd">

                </el-table-column>
            </el-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>



    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins";
    import {postAction} from "@/api/manage";

    export default {
        name: 'StickOrder',
        mixins: [QueryMixins],
        data() {


            return {
              isUsePost:true,
                url: {
                    list: '/interface/fbaToOlc/stick/getLabelIPage',
//审核
                  add: '/interface/fbaToOlc/stick/add',
                },
            }
        },
        methods: {
          add(){
            postAction(this.url.add,this.selectionRows)
                .then(res=>{
                  this.$message({type:'success',message:res.message});
                  this.handleSearch();
                })
          },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },

            //导入


        }, created() {

        },
      mounted() {

      },
      computed:{
        buttonShowList(){
          return {
            'sh':this.$btnIsShow('ocean/StickOrder','0','审核'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
</style>
