<template>
  <el-card shadow="never">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="icon-grid-wrap"><i class="el-icon-s-grid"></i></span>
        编辑部门 <span v-if="row.id">{{ ": " + row.title }}</span>
      </div>
    </el-card>
    <el-card class="box-card last_card">
      <div class="f-full-height f-flex-col f-overflow-auto">
        <div class="form-wrap" v-if="row.id">
          <department-form
            ref="form"
            show-button
            :row="row"
            :data="data"
            @ok="handleSubmit"
          ></department-form>
        </div>
        <div v-else class="c-alert">从左侧列表选择一项后，进行编辑</div>
      </div>
    </el-card>
  </el-card>
</template>

<script>
import { postAction, putAction, getAction } from "@/api/manage";
import DepartmentForm from "./DepartmentForm";
export default {
  name: "MenuEditModule",
  components: {
    DepartmentForm,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      url: {
        edit: "/sys/sysDepart/edit",
      },
    };
  },
  methods: {
    handleSubmit(form) {
      putAction(this.url.edit, { id: this.row.id, ...form }).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.$bus.$emit("department-data-refresh");
        localStorage.removeItem('dict:sys_depart');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  padding: 0 6px;
  max-width: 640px;
}
.icon-grid-wrap {
  width: 28px;
  height: 28px;
  display: inline-block;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  background: #e6f7ff;
  .el-icon-s-grid {
    color: rgb(24, 144, 255);
  }
}
::v-deep {
  .el-card__body {
    overflow: hidden;
    padding: 10px;
  }
}
</style>
