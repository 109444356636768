<template>
    <el-dialog
            :title="billForm.id ? '编辑' : '新增'"
            :visible="visible"
            width="580px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="'right'"
                 label-width="109px">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="checkStatus" label="仓库名">
                        <el-input v-model="billForm.content"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="checkStatus" label="类型">
                        <el-select v-model="billForm.type">
                            <el-option value="1" label="自有仓"></el-option>
                            <el-option value="2" label="物流点"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="地址" prop="address">
                        <el-input type="text" v-model="billForm.address" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="city" label="城市">
<!--                        <el-input type="text" v-model="billForm.receiveTotalAmount" style="width: 250px"></el-input>-->
                        <f-select
                                clearable
                                filterable
                                v-model="billForm.city"
                                :fatherVal="billForm.city"
                                dict="china_city"
                        ></f-select>

                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="contact" label="联系人">
                        <el-input type="text" v-model="billForm.contact" style="width: 250px"></el-input>

                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="phone" label="联系电话">
                        <el-input type="text" v-model="billForm.phone" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="经度">
                        <el-input type="text" v-model="billForm.lng" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="纬度">
                        <el-input type="text" v-model="billForm.lat" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction,httpAction} from '@/api/manage'
    import moment from 'moment'

    export default {
        name: 'noConvertBill',


        data() {
            return {
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                unusualInfoVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                lang: [
                    {required: true, message: '请选择账单语言', trigger: 'change'},
                ],
                billForm: {},
                url: {
                    add: '/shipping/warehouseInfo/add',
                    edit: '/shipping/warehouseInfo/edit',
                },
                rules: {
                    content: [
                        {required: true, message: '请输入仓库名', trigger: 'blur'},
                    ],
                    address: [
                        {required: true, message: '请输入仓库地址', trigger: 'blur'},
                    ],
                    city: [
                        {required: true, message: '请选择所在城市', trigger: 'blur'},
                    ],
                    contact: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请驶入联系人电话', trigger: 'blur'},
                    ],
                },

                settleIds: [],

            }
        },
        methods: {
            handleChange(val) {
                this.$refs["billForm"].clearValidate();//重置校验
                if (val == '1') {
                    this.unusualInfoVisible = false;
                } else {
                    this.unusualInfoVisible = true;
                }


            },
            // 提交表单
            handleSubmit() {
                let url = this.url.add;
                let method = "POST";
                console.log("父亲的parnet", this.$parent.row);
                if (this.billForm.id) {
                    url = this.url.edit;
                    method = "PUT";
                }
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        console.log('上传获取数据', this.billForm)
                        httpAction(url, method, this.billForm).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleClose();
                            this.$emit("ok");
                        });
                    }
                });
            },
            handleClose() {
                this.$refs.billForm.resetFields();
                this.visible = false
            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(row) {
                this.billForm = {...row}
                this.visible = true
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
