<template>
    <div class="general-table-wrap">
        <el-dropdown
                class="operation"
                trigger="click"
                :hide-on-click="false"
                @command="handleCommand"
                ref="dropdowm"
                v-if="showOperation"
        >
      <span class="el-dropdown-link">
        <el-button size="medium">
          <i class="fa fa-bars"></i>
        </el-button>
      </span>
            <el-dropdown-menu
                    slot="dropdown"
                    class="table-operater-wrap c-slim-scrollbar"
            >
                <!-- <el-dropdown-item command="SAVE">保存设置</el-dropdown-item> -->
                <el-dropdown-item command="RESTORE">恢复默认设置</el-dropdown-item>
                <el-dropdown-item command="CLEAR">清除所有过滤器</el-dropdown-item>
                <div class="divider">列展示</div>
                <template v-for="(item, index) in display_columns">
                    <el-dropdown-item
                            v-if="item.type !== 'selection'"
                            :key="index"
                            :command="index"
                    >
                        <i
                                :class="item.show ? 'el-icon-check' : 'el-icon-close'"
                                style="font-weight: bold"
                        ></i>
                        {{ item.label }}
                    </el-dropdown-item>
                </template>
            </el-dropdown-menu>
        </el-dropdown>
        <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
        >查询</el-button
        >
        <el-button plain @click="handleReset">重置</el-button>
        <el-table
                v-if="render"
                ref="table"
                :default-sort="isorter"
                :header-cell-style="{ 'text-align': 'center' }"
                v-on="$listeners"
                v-bind="$attrs"
                @expand-change="open"
        >
            <!-----------------------------------------------------        子表格开始       ------------------------------------------------->
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-table
                            class="child_table"
                            v-if="render"
                            ref="table"
                            :default-sort="isorter"
                            :header-cell-style="{ 'text-align': 'center' }"
                            :cell-style="{ 'text-align': 'center' }"
                            :data="childTableData"
                    >
                        <template v-for="(item, index) in display_child_colums">
                            <el-table-column
                                    :key="index"
                                    v-if="item.show && item.type === 'selection'"
                                    v-bind="item"
                                    :prop="item.prop"
                                    :label="item.label"
                            >
                            </el-table-column>
                            <el-table-column
                                    :key="item.prop"
                                    v-else-if="item.show && item.type === 'index'"
                                    :prop="item.prop"
                                    :label="item.label"
                                    v-bind="item"
                            >
                            </el-table-column>
                            <el-table-column
                                    :key="item.prop"
                                    v-else-if="item.show"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :show-overflow-tooltip="item.over"
                                    v-bind="item"
                            >
                                <template slot="header">
                                    <!-- 自定义表头 -->
                                    <template v-if="item.headerType === 'string'">
                                        <div class="input-wrap">
                                            <span>{{ item.label }}</span>
                                            <br/>
                                            <el-input
                                                    clearable
                                                    class="input"
                                                    v-model="form[item.prop]"
                                                    size="small"
                                                    @input="handleInput"
                                                    @click.stop.native
                                            />
                                        </div>
                                    </template>
                                    <template v-else-if="item.headerType === 'text'">
                                        <div class="input-wrap">
                                            <span>{{ item.label }}</span>
                                            <br/>
                                            <el-input
                                                    clearable
                                                    class="input"
                                                    v-model="form[item.prop]"
                                                    size="small"
                                                    @input="handleInput"
                                                    type="textarea"
                                                    @click.stop.native
                                            />
                                        </div>
                                    </template>
                                    <template v-else-if="item.headerType === 'range'">
                                        <div class="input-wrap">
                                            <span>{{ item.label }}</span>
                                            <br/>
                                            <el-input
                                                    clearable
                                                    class="input"
                                                    v-model="form[item.prop + 'Start']"
                                                    size="small"
                                                    prefix-icon="el-icon-arrow-right"
                                                    @input="handleInput"
                                                    @click.stop.native
                                            />
                                            <el-input
                                                    clearable
                                                    v-model="form[item.prop + 'End']"
                                                    class="input bottom"
                                                    size="small"
                                                    prefix-icon="el-icon-arrow-left"
                                                    @input="handleInput"
                                                    @click.stop.native
                                            />
                                        </div>
                                    </template>
                                    <template v-else-if="item.headerType === 'datetime'">
                                        <div class="input-wrap">
                                            <span>{{ item.label }}</span>
                                            <br/>
                                            <el-date-picker
                                                    class="input time"
                                                    v-model="form[item.prop + 'Start']"
                                                    type="datetime"
                                                    size="small"
                                                    placeholder="开始时间"
                                                    default-time="00:00:00"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    style="width: 100%"
                                                    @change="handleInput"
                                                    @click.stop.native
                                            >
                                            </el-date-picker>
                                            <el-date-picker
                                                    class="input time bottom"
                                                    v-model="form[item.prop + 'End']"
                                                    type="datetime"
                                                    size="small"
                                                    placeholder="结束时间"
                                                    default-time="23:59:59"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    @change="handleInput"
                                                    @click.stop.native
                                            >
                                            </el-date-picker>
                                        </div>
                                    </template>
                                    <template v-else-if="item.headerType === 'select'">
                                        <div class="input-wrap">
                                            <span>{{ item.label }}</span>
                                            <br/>
                                            <f-select
                                                    filterable
                                                    clearable
                                                    class="input"
                                                    :dict="item.dict"
                                                    v-model="form[item.prop]"
                                                    size="small"
                                                    placeholder="请选择"
                                                    @change="handleInput"
                                            >
                                            </f-select>
                                        </div>
                                    </template>

                                    <!-- 默认表头渲染 -->
                                    <template v-else>{{ item.label }}</template>
                                </template>

                                <template v-slot="scope">
                                    <slot v-bind="scope">
                                        {{ scope.row[scope.column.property] }}
                                    </slot>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </template>
            </el-table-column>
            <!-----------------------------------------------------        子表格结束       ------------------------------------------------->

            <template v-for="(item, index) in display_columns">
                <el-table-column
                        :key="index"
                        v-if="item.show && item.type === 'selection'"
                        v-bind="item"
                        :prop="item.prop"
                        :label="item.label"
                >
                </el-table-column>
                <el-table-column
                        :key="item.prop"
                        v-else-if="item.show && item.type === 'index'"
                        :prop="item.prop"
                        :label="item.label"
                        v-bind="item"
                >
                </el-table-column>
                <el-table-column
                        :key="item.prop"
                        v-else-if="item.show"
                        :prop="item.prop"
                        :label="item.label"
                        :show-overflow-tooltip="item.over"
                        v-bind="item"
                >
                    <template slot="header">
                        <!-- 自定义表头 -->
                        <template v-if="item.headerType === 'string'">
                            <div class="input-wrap">
                                <span>{{ item.label }}</span>
                                <br/>
                                <el-input
                                        clearable
                                        class="input"
                                        v-model="form[item.prop]"
                                        size="small"
                                        @input="handleInput"
                                        @click.stop.native
                                />
                            </div>
                        </template>
                        <template v-else-if="item.headerType === 'text'">
                            <div class="input-wrap">
                                <span>{{ item.label }}</span>
                                <br/>
                                <el-input
                                        clearable
                                        class="input"
                                        v-model="form[item.prop]"
                                        size="small"
                                        @input="handleInput"
                                        type="textarea"
                                        @click.stop.native
                                />
                            </div>
                        </template>
                        <template v-else-if="item.headerType === 'range'">
                            <div class="input-wrap">
                                <span>{{ item.label }}</span>
                                <br/>
                                <el-input
                                        clearable
                                        class="input"
                                        v-model="form[item.prop + 'Start']"
                                        size="small"
                                        prefix-icon="el-icon-arrow-right"
                                        @input="handleInput"
                                        @click.stop.native
                                />
                                <el-input
                                        clearable
                                        v-model="form[item.prop + 'End']"
                                        class="input bottom"
                                        size="small"
                                        prefix-icon="el-icon-arrow-left"
                                        @input="handleInput"
                                        @click.stop.native
                                />
                            </div>
                        </template>
                        <template v-else-if="item.headerType === 'datetime'">
                            <div class="input-wrap">
                                <span>{{ item.label }}</span>
                                <br/>
                                <el-date-picker
                                        class="input time"
                                        v-model="form[item.prop + 'Start']"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        style="width: 100%"
                                        @change="handleInput"
                                        @click.stop.native
                                >
                                </el-date-picker>
                                <el-date-picker
                                        class="input time bottom"
                                        v-model="form[item.prop + 'End']"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        @change="handleInput"
                                        @click.stop.native
                                >
                                </el-date-picker>
                            </div>
                        </template>
                        <template v-else-if="item.headerType === 'select'">
                            <div class="input-wrap">
                                <span>{{ item.label }}</span>
                                <br/>
                                <f-select
                                        filterable
                                        clearable
                                        class="input"
                                        :dict="item.dict"
                                        v-model="form[item.prop]"
                                        size="small"
                                        placeholder="请选择"
                                        @change="handleInput"
                                >
                                </f-select>
                            </div>
                        </template>

                        <!-- 默认表头渲染 -->
                        <template v-else>{{ item.label }}</template>
                    </template>

                    <template v-slot="scope">
                        <slot v-bind="scope">
                            {{ scope.row[scope.column.property] }}
                        </slot>
                    </template>
                </el-table-column>
            </template>
        </el-table>
    </div>
</template>

<script>
    import Tempcclate from "@/views/isystem/Dict";
    import {getAction} from "../../api/manage";

    export default {
        name: "FTable",
        components: {Tempcclate},
        inheritAttrs: false,
        props: {
            columns: {
                type: Array,
                default: () => {
                },
            },
            childColumns: {
                type: Array,
                default: () => {
                },
            },
            isorter: {
                type: Object,
            },
            childUrl: {
                type: String,
            },
            childArry: {
                type: Array,
                default: () => {
                },
            },
            showOperation: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Array,
                default: () => {
                },
            },
        },
        data() {
            return {
                childTableData: [],
                render: true,
                defaultSort: {},
                form: {},
                display_columns: [],
                display_child_colums: [],
                url: {
                    list: '/sys/channelSet/list',
                },
            };
        },
        watch: {
            columns: {
                deep: true,
                handler() {
                    this.handleInColumnsChange();
                },
            },
            childColumns: {
                deep: true,
                handler() {
                    this.handleInChildColumnsChange();
                },
            },
        },
        mounted() {
            this.handleInColumnsChange();
            this.handleInChildColumnsChange();
        },
        methods: {
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                // this.queryForm.toExamine = '0';
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            // 展开行
            async open(row, expandedRows) {
                console.log(row)
                if (expandedRows && expandedRows.length > 0) {
                    console.log("展开");
                    getAction(this.childUrl, row).then((res) => {
                        console.log(res)
                        if (res.success) {
                            const {records, total} = res.result
                            this.childTableData = records || res.result;
                            // this.ipagination && (this.ipagination.total = total || res.result.length)
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    console.log("关闭");
                    return;
                }
            },
            freashChildData(row) {
                getAction(this.childUrl, row).then((res) => {
                    console.log(res)
                    if (res.success) {
                        const {records, total} = res.result
                        this.childTableData = records || res.result;
                        // this.ipagination && (this.ipagination.total = total || res.result.length)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            changeTraceEnd(prop) {
                console.log("结束时间", this.form[prop + "End"]);
                if (this.form[prop + "End"] !== null) {
                    //当前值为空 也会进行判断 所以条件最好不要非空验证
                    this.form[prop + "Start"] = {
                        disableDate: (time) => {
                            return time.getTime() > new Date(this.form[prop + "End"]).getTime();
                        },
                    };
                } else {
                    this.form[prop + "Start"] = {};
                }
            },
            //跟踪API结束时间限制开始时间
            changeTraceStart(prop) {
                console.log("开始时间", this.form[prop + "Start"]);
                if (this.form[prop + "Start"] !== null) {
                    //当前值为空 也会进行判断 所以条件最好不要非空验证
                    this.form[prop + "End"] = {
                        disableDate: (time) => {
                            return (
                                time.getTime() > new Date(this.form[prop + "Start"]).getTime()
                            );
                        },
                    };
                } else {
                    this.form[prop + "End"] = {};
                }
            },
            handleCommand(cmd) {
                if (typeof cmd === "number") {
                    this.handleColVisible(cmd);
                    return;
                }

                cmd === "SAVE" && this.handleSave();
                cmd === "RESTORE" && this.handleRestore();
                cmd === "CLEAR" && this.handleClear();
                this.$refs.dropdowm.hide();
            },
            handleSave() {
                console.log("save");
            },
            handleRestore() {
                this.handleInColumnsChange();
            },
            handlePureClear() {
                for (let item in this.form) {
                    this.form[item] = "";
                }
            },
            handleClear() {
                this.handlePureClear();
                this.$emit("queryChange", this.form, true, true);
            },
            handleInput() {
                //如果有值，说明是文本框，做字符串分割
                this.$emit("queryChange", this.form, true);
            },
            handleInColumnsChange() {
                this.display_columns = [];
                for (let i = 0; i < this.columns.length; i++) {
                    this.display_columns.push({
                        show: true,
                        ...this.columns[i],
                    });
                }
                console.log(this.display_columns);
                this.handleReRender();
            },
            handleInChildColumnsChange() {
                this.display_child_colums = [];
                for (let i = 0; i < this.childColumns.length; i++) {
                    this.display_child_colums.push({
                        show: true,
                        ...this.childColumns[i],
                    });
                }
                console.log(this.display_columns);
                this.handleReRender();
            },
            handleReRender() {
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                });
            },
            handleColVisible(index) {
                const show = !this.display_columns[index]["show"];
                this.$set(this.display_columns[index], "show", show);

                this.handleReRender();

                // if(!show) {
                //     if (this.display_columns[index]['headerType'] === 'datetime' || this.display_columns[index]['headerType'] === 'range') {
                //         this.form[this.display_columns[index]['prop'] + 'start'] = ''
                //         this.form[this.display_columns[index]['prop'] + 'end'] = ''
                //     } else {
                //         this.form[this.display_columns[index]['prop']] = ''
                //     }

                //     this.$emit('queryChange', this.form, false)
                // }
            },
            handleClearSelection() {
                this.$refs.table.clearSelection();
            },
        },
    };
</script>

<style lang="scss">
    .table-operater-wrap {
        padding: 4px 0;
        max-width: 160px;
        max-height: 500px;
        overflow: hidden auto;

        .el-dropdown-menu__item {
            padding: 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .divider {
            padding: 8px 16px;
            margin: 4px 0;
            border-top: 1px solid #e8eaec;
            border-bottom: 1px solid #e8eaec;
            font-size: 14px;
        }
    }
</style>

<style lang="scss" scoped>
    .general-table-wrap {
        position: relative;

        .operation {
            top: 0;
            right: 0;
            z-index: 10;
            position: absolute;

            .el-button {
                padding: 8px 12px;
            }
        }

        .input-wrap {
            display: inline-block;
            max-width: calc(100% - 36px);

            .input {
                display: block;
                max-width: 200px;
            }

            .time {
                width: 100%;
            }

            .bottom {
                margin-top: 4px;
            }
        }
    }

    ::v-deep .el-table--medium td {
        line-height: 46px;
        padding: 14px 0;

        .el-button {
            padding: 0;
        }
    }
</style>
