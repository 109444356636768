<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="申请编号">
                            <el-input v-model="queryForm.applyNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="销账编号">
                            <el-input v-model="queryForm.mergeNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="销账时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="stime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :default-time="['00:00:00', '23:59:59']"
                                    :picker-options="editStartOptions"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="otime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStopOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="btime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStopOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="feetime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStopOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="申请人">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.creator"
                                      :isNeed="queryForm.creator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="审批人">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.checkAccountBy"
                                      :isNeed="queryForm.checkAccountBy">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="结算对象">
                            <el-input v-model.trim="queryForm.settleName" @keyup.enter.native="handleCheck"
                                      placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务类型">
                            <el-select v-model="queryForm.agentPayType" clearable>
                                <el-option value="-1" label="全部"></el-option>
                                <el-option value="0" label="海运"></el-option>
                                <el-option value="1" label="柜子"></el-option>
                                <el-option value="2" label="快递"></el-option>
                                <el-option value="3" label="海运/柜子混合"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select
                                    :dict="'sys_user_id'"
                                    v-model="queryForm.salesmanId"
                                    :is-need="queryForm.salesmanId"
                                    :father-val="queryForm.salesmanId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款对象">
                            <el-input v-model.trim="queryForm.payMan" @keyup.enter.native="handleCheck"
                                      placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="付款开户行">
                            <f-select :dict="'receive_bank'"
                                      v-model="queryForm.forestBankName"
                                      :isNeed="queryForm.forestBankName">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="应收/应付">
                            <el-select v-model="queryForm.type" clearable>
                                <el-option value="0" label="应收"></el-option>
                                <el-option value="1" label="应付"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="抛转状态">
                            <el-select v-model="queryForm.isSendVoucher" clearable>
                                <el-option value="0" label="未抛转"></el-option>
                                <el-option value="1" label="已抛转"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <div>
                    <el-button size="medium" type="primary" @click="jumpBindBillView">合并销账单流水</el-button>
                </div>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="61vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'invoiceNoThird'">
                        <span v-for="(item, index) in scope.row.supplierInvoiceNos" :key="index">
                            {{item+';'}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'agentPayType'">
                        <span v-if="scope.row.agentPayType=='0'">海运费用申请</span>
                        <span v-if="scope.row.agentPayType=='1'">柜子的费用申请</span>
                        <span v-if="scope.row.agentPayType=='2'">快递费用申请</span>
                        <span v-if="scope.row.agentPayType=='3'">海运/柜子混合费用申请</span>
                        <span v-if="scope.row.agentPayType=='4'">海外仓费用申请</span>
                    </template>
                    <template v-else-if="scope.column.property === 'mergeNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="showMergeDetail(scope.row)">{{ scope.row.mergeNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'creator'">
                        <span>{{ parseDict(scope.row.creator, 'sys_create_by') }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'applyNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="showApplyDetail(scope.row)">{{ scope.row.applyNo }}</a>
                    </template>
                    <!--<template v-if="scope.column.property === 'type'">-->
                    <!--<span v-if="scope.row.type=='0'">应收</span>-->
                    <!--<span v-if="scope.row.type=='1'">应付</span>-->
                    <!--</template>-->
                    <!--<template v-if="scope.column.property === 'isSendVoucher'">-->
                        <!--<span v-if="scope.row.status==''">待抛1转</span>-->
                        <!--<span v-if="scope.row.status=='0'">待抛转</span>-->
                        <!--<span v-if="scope.row.status=='1'">已抛转</span>-->
                        <!--<span v-if="scope.row.status=='2'">已生成</span>-->
                        <!--<span v-if="scope.row.status=='3'">已撤回</span>-->
                    <!--</template>-->
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <add-bill-no-dialog :visible.sync="billVisible":applyNos="applyNos":voucherType="voucherType"></add-bill-no-dialog>
    </div>
</template>
<script>
    import QueryMixins from "../../../../mixins/QueryMixins";
    import {getAction, postAction} from "@/api/manage";
    import AddBillNoDialog from "../voucher/AddBillNoDialog";

    export default {
        mixins: [QueryMixins],
        components: {
           AddBillNoDialog
        },
        data() {
            return {
                dictCodes: ['sys_create_by'],
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos: [],
                loading: false,
                form: {},
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "removeTime",
                        label: "销账时间",
                    },
                    {
                        prop: "isSendVoucher",
                        label: "是否抛转",
                    },
                    {
                        prop: "voucherDate",
                        label: "凭证期间",
                    },
                    {
                        prop: "voucherNo",
                        label: "凭证号",
                    },
                    {
                        prop: "applyNo",
                        label: "申请编号",
                        width: 120,
                    },
                    // {
                    //     prop: "type",
                    //     label: "应收/应付",
                    // },
                    {
                        prop: "agentPayType",
                        label: "申请类型",
                    },
                    {
                        prop: "invoiceNoThird",
                        label: "代理InvoiceNo",
                        width: 100,
                    },
                    {
                        prop: "invoiceRemark",
                        label: "备注",
                        sortable: true,
                    },
                    {
                        prop: "settleName",
                        label: "代理商",
                    },
                    {
                        prop: "payMoney",
                        label: "应收/付金额",
                    },
                    {
                        prop: "payCur",
                        label: "应收/付币种",
                    },
                    {
                        prop: "realMoney",
                        label: "实付费用",
                    },
                    {
                        prop: "realCur",
                        label: "实付币种",
                    },
                    {
                        prop: "mergeSalesMoney",
                        label: "结算金额",
                    },
                    {
                        prop: "mergeSalesMoneyCur",
                        label: "结算币种",
                    },
                    {
                        prop: "kingdeeCode",
                        label: "银行科目",
                    },
                    {
                        prop: "bankName",
                        label: "收款开户行",
                    },
                    {
                        prop: "settleAccount",
                        label: "收款账号",
                    },
                    {
                        prop: "payMan",
                        label: "收款名称",
                    },

                    {
                        prop: "forestBankName",
                        label: "付款开户行",
                    },
                    {
                        prop: "forestAccount",
                        label: "付款账号",
                    },
                    {
                        prop: "forestPayMan",
                        label: "付款名称",
                    },
                    {
                        prop: "creator",
                        label: "申请人",
                    },
                    {
                        prop: "createTime",
                        label: "申请时间",
                    },
                    {
                        prop: "checkAccountBy",
                        label: "审批人",
                    },
                    {
                        prop: "checkTime",
                        label: "审批时间",
                    },
                ],

                url: {
                    list: "/finance/voucher/removedList",
                    setProof: "/shipping/hySettlePay/setProof",
                    // removeBatch: '/shipping/hySettlePay/removeBatch',
                    // backToWaitPay: '/shipping/hySettlePay/backToWaitPay',
                    cancelPayRemoved: '/shipping/hySettlePay/cancelPayRemoved',
                    downloadPayExcel:'/shipping/hySettlePay/downloadPayExcel',
                    downloadPayOutApply: "/file/jasper/downloadPayOutApply",
                    synPayBankId:"/shipping/hySettlePay/synPayBankId",
                    downloadRemovedMergeDetail:"/shipping/hySettlePay/downloadRemovedMergeDetail",
                },

                rules: {
                    realCtn: [
                        {required: true, message: "请输入实际总件数", trigger: "blur"},
                        {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                    ],
                    realKg: [
                        {required: true, message: "请输入实际总重量", trigger: "blur"},
                        {
                            pattern:
                                /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                            message: "只能输入最多三位小数的数字",
                            trigger: "change",
                        },
                    ],
                },
                stime: [],
                otime: [],

                btime: [],  //开船时间
                feetime: [],  //开船时间
                // queryForm: {agentPayType: '0'}
                queryForm: {},


                billVisible:false,//控制弹窗是否显示,
                applyNos:[],//传递给弹窗的 申请编号
                voucherType:"",//传递给弹窗的 凭证类型
            };
        },
        computed: {},
        created() {

        },

        methods: {
            setProof() {
                if ((this.btime === null || this.btime.length === 0) && (this.feetime === null || this.feetime.length === 0)
                    && (this.otime === null || this.otime.length === 0)) {
                    this.$message.warning("查询条件的【下单时间】【开船时间】【费用时间】须填写一个或多个");
                    return;
                }


                // console.log("应付-生成凭证以下id的费用", this.selectionRows);
                if (this.selectedRowKeys.length === 0) {
                    this.$message.warning("请勾选需要生成凭证的申请号");
                    return;
                }

                let agentPayType = []

                let count = [];

                this.selectionRows.forEach((item) => {
                    count.push(item.id);
                    if (item.agentPayType == '1') {
                        agentPayType.push(item.id)
                    }
                });
                if (agentPayType.length > 0) {
                    this.$message.warning("柜子类型的销账编号不支持生成凭证")
                    return;
                }
                this.loading = true;
                let arr = Array.from(new Set(count));

                let obj = {}
                obj.agentPayMergeIds = arr;
                if (this.otime !== null && this.otime.length > 1) {
                    obj.orderCreateTimeS = this.otime[0];
                    obj.orderCreateTimeE = this.otime[1];
                } else {
                    obj.orderCreateTimeS = '';
                    obj.orderCreateTimeE = '';
                }
                if (this.btime !== null && this.btime.length > 1) {
                    obj.boatTimeS = this.btime[0];
                    obj.boatTimeE = this.btime[1];
                } else {
                    obj.boatTimeS = '';
                    obj.boatTimeE = '';
                }
                if (this.feetime !== null && this.feetime.length > 1) {
                    obj.feeCreateTimeS = this.feetime[0];
                    obj.feeCreateTimeE = this.feetime[1];
                } else {
                    obj.feeCreateTimeS = '';
                    obj.feeCreateTimeE = '';
                }
                // console.log('obj', obj)
                let fileName = '应付凭证' + new Date().toLocaleDateString();
                postAction(this.url.setProof, obj, {responseType: 'blob'}).then((data) => {
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                    } else {
                        let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.setAttribute('download', fileName + '.xls');
                        document.body.appendChild(link);
                        link.click()
                        document.body.removeChild(link);//下载完成移除元素
                        window.URL.revokeObjectURL(url) //释放掉blob对象
                        this.loading = false;
                    }
                })

            },
            showApplyDetail(row) {
                // this.$refs.applyDetail.showDetail(row);
                if (row.agentPayType == '1') {
                    this.$refs.containerApplyDetailDialog.showDetail(row);
                } else {
                    this.$refs.applyDetail.showDetail(row);
                }
            },
            cancelPayRemoved(row) {
                // console.log('进入取消应付销账的方法', row)
                postAction(this.url.cancelPayRemoved, row).then((res) => {
                    // console.log(res);
                    if (res.success === true) {
                        this.$message.success("退回成功！");
                        this.handleQuery();
                    }
                });
            },
            showMergeDetail(row) {
                this.$refs.mergeDetailDialog.showDetail(row);
            },
            importBill() {
                this.$message.warning("此功能后续开放！");
            },
            backToWaitPay(row) {
                postAction(this.url.backToWaitMerge, row).then((res) => {
                    // console.log(res);
                    if (res.success === true) {
                        this.$message.success("退回成功！");
                        this.handleQuery();
                    }
                });
            },
            doRemove(row) {
                this.$refs.removeDialog.edit(row);
            },
            removeBatch() {
                // console.log("申请付款以下id的费用", this.selectionRows);
                if (this.selectedRowKeys.length === 0) {
                    this.$message.warning("请勾选付款申请");
                    return;
                }
                let payCount = [];
                this.selectionRows.forEach((item) => {
                    payCount.push(item.id);
                });

                let payArr = Array.from(new Set(payCount));
                // if(payArr.length > 1){
                //     this.$message.warning("所选费用的结算对象不一致！")
                //     return
                // }
                // let arr = this.tableData.filter(item => {return this.selectionRows.indexOf(item) === -1})
                // console.log("arr--",arr)
                // this.$refs.payApply.edit(this.selectionRows,arr,this.columns)
                //跳过审批流程，直接审批完成
                postAction(this.url.removeBatch, payArr).then((res) => {
                    // console.log(res);
                    if (res.success === true) {
                        this.$message.success("合并成功");
                        this.handleQuery();
                    }
                });
            },
            waitwait(obj) {
                this.$message.warning("后续开放！");
            },
            handleReset() {
                this.stime = []
                this.otime = []
                this.btime = []
                this.feetime = []
                this.queryForm = {}
                // this.$set(this.queryForm, 'agentPayType', '0');
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleSelectionChange(selection) {
                // 多选
                // console.log(selection);
                let arr = [];
                let nos = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    nos.push(selection[i]["orderNo"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                this.selectedNos = [...nos];
            },
            handleReceive() {
                // console.log("申请收款以下id的费用", this.selectedRowKeys);
            },
            handleCheck() {
                if (!this.queryForm.type) {
                    this.$message.warning('请勾选已销账应收/应付类型');
                    return false;
                }
                this.loading = true;
                if (this.stime && this.stime.length > 1) {
                    this.queryForm.removeTimeS = this.stime[0];
                    this.queryForm.removeTimeE = this.stime[1];
                } else {
                    this.queryForm.removeTimeS = '';
                    this.queryForm.removeTimeE = '';
                }
                if (this.otime && this.otime.length > 1) {
                    this.queryForm.createTimeS = this.otime[0];
                    this.queryForm.createTimeE = this.otime[1];
                } else {
                    this.queryForm.createTimeS = '';
                    this.queryForm.createTimeE = '';
                }
                if (this.btime && this.btime.length > 1) {
                    this.queryForm.boatTimeS = this.btime[0];
                    this.queryForm.boatTimeE = this.btime[1];
                } else {
                    this.queryForm.boatTimeS = '';
                    this.queryForm.boatTimeE = '';
                }
                if (this.feetime && this.feetime.length > 1) {
                    this.queryForm.feeCreateTimeS = this.feetime[0];
                    this.queryForm.feeCreateTimeE = this.feetime[1];
                } else {
                    this.queryForm.feeCreateTimeS = '';
                    this.queryForm.feeCreateTimeE = '';
                }
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                // console.log("查询对象", params);
                params.type = this.queryForm.type;
                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            const {records, total} = res.result;
                            this.tableData = records || res.result;

                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            jumpBindBillView() {
                if (this.selectedRowKeys.length === 0) {
                    this.$message.warning("请勾选需要绑定流水的申请号");
                    return;
                }

                let countType = [];
                this.selectionRows.forEach((item) => {
                    countType.push(item.type);
                });
                let typeArr = Array.from(new Set(countType));
                if (typeArr.length > 1) {
                    this.$message.warning("不能同时勾选已销账应收/应付类型进行操作！");
                    return;
                }

                if(typeArr[0] === '0') {
                    this.voucherType = "3";
                }
                if(typeArr[0] === '1') {
                    this.voucherType = "4";
                }


                //传递值
                // this.selectionRows
                this.applyNos = this.selectionRows.map(a=>a.applyNo);

                //打开弹窗
                this.billVisible = true;
            },
        },
    };
</script>
<style scoped>
    /* 单行就设置间距小一点 */
    /* .el-form-item {
      margin-bottom: 2px;
    } */
</style>
