<template>
  <el-dialog
    :title="order.id ? '编辑入仓单模板' : '新增入仓单模板'"
    :visible="visible"
    width="70%"
    :close-on-click-modal="false"
    :before-close="handleClose"
    append-to-body
  >
    <el-form class="addEditBox" :model="order" ref="form" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item
            prop="isCl"
            label="用户类型"
            label-width="100px"
            style="margin-right: 20px"><f-select
              :dict="'rucang_type'"
              v-model="order.isCl"
              :is-need="order.isCl"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="绑定的客户" prop="cusIdList" :label-width="formLabelWidth">
            <f-select  v-model="order.cusIdList"  :is-need="order.cusIdList" :dataList="order.dictList" :multiple="true"></f-select>
<!--            <f-select v-else v-model="order.cusIdList"  :is-need="order.cusIdList" :dict="'customer_id'" :multiple="true"></f-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否显示联系人" prop="isShowEme" label-width="100px" style="margin-right: 20px">
            <f-select
                :dict="'is_default'"
                v-model="order.isShowEme"
                :is-need="order.isShowEme"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="lclPlace"
            label="交货地点"
            label-width="100px"
            style="margin-right: 20px"
          >
            <f-select
              v-model="order.lclPlace"
              :isNeed="order.lclPlace"
              :dict="'delivery_point'"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓库预约二维码" prop="fileList" :label-width="formLabelWidth">
            <f-upload ref="ck" v-model="order.fileList"  list-type="picture-card" :dir="'content'" :limit="1"></f-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="二维码名字" prop="fileName" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.fileName" maxlength="10" show-word-limit ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="公司名字" prop="companyName" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.companyName" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入仓单名字" prop="danName" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.danName" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订单号前缀" prop="orderNoPrefix" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.orderNoPrefix" maxlength="7" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收货时间" prop="recTime" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.recTime" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓库地址" prop="address" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.address" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="导航搜索" prop="keyword" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.keyword" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="phone" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.phone" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓库提醒1" prop="mark1" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.mark1" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓库提醒2" prop="mark2" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.mark2" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入仓须知1" prop="info1" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.info1" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入仓须知2" prop="info2" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.info2" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入仓须知3" prop="info3" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.info3" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="入仓须知4" prop="info4" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.info4" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称1" prop="fee1" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.fee1" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称1收费标准" prop="fee1Type" label-width="120px">
            <el-input class="padding_right_area" v-model="order.fee1Type" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称2" prop="fee2" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.fee2" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称2收费标准" prop="fee2Type" label-width="120px">
            <el-input class="padding_right_area" v-model="order.fee2Type" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称3" prop="fee1" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.fee3" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称3收费标准" prop="fee3Type" label-width="120px">
            <el-input class="padding_right_area" v-model="order.fee3Type" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称4" prop="fee4" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.fee4" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称4收费标准" prop="fee4Type" label-width="120px">
            <el-input class="padding_right_area" v-model="order.fee4Type" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称5" prop="fee1" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.fee5" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用名称5收费标准" prop="fee5Type" label-width="120px">
            <el-input class="padding_right_area" v-model="order.fee5Type" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="测量标准1" prop="clInfo1" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.clInfo1" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="测量标准2" prop="clInfo2" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.clInfo2" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="测量标准3" prop="clInfo3" :label-width="formLabelWidth">
            <el-input class="padding_right_area" v-model="order.clInfo3" maxlength="255" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" size="medium" @click="handleClose">
        关闭</el-button
      >
      <el-button
        type="primary"
        size="medium"
        class="btn btn-primary"
        @click="saveModel"
      >
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAction } from "@/api/manage";


import { httpAction } from "../../../api/manage";
export default {
  name: "MarkRm",
  components: {

  },
  props: {
    visible: {
      type: Boolean,
    },
    islogo: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (this.form) {
        for (var name in this.form) {
          this.$set(this.order, name, this.form[name]);
        }
        if (this.form.id) {
          this.isDis = true;
        } else {
          this.isDis = false;
        }
        if (this.$refs.ck){
          this.$refs.ck.handleYC();
        }
      }
      console.log('this.order',this.order)
      this.fsee = true;
    });
  },
  data() {
    return {
      fsee:false,
      formLabelWidth:'100px',
      codeVisible: false,
      isDis: false,
      /*校验*/
      order: {},
      rules: {
        isCl: [
          { required: true, message: "请输入用户类型", trigger: "change" },
        ],
        lclPlace: [
          { required: true, message: "请选择拼柜仓库", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入公司名", trigger: "blur" },
          { max: 255, message: "不能超过255", trigger: "change" },
        ],
        danName: [
          { required: true, message: "请输入入仓单名", trigger: "blur" },
          { max: 255, message: "不能超过255", trigger: "change" },
        ],
        fee1: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee1Type: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee1Info: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee2: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee2Type: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee2Info: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee3: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee3Type: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee3Info: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee4: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee4Type: [{ max: 255, message: "不能超过255", trigger: "change" }],
        fee4Info: [{ max: 255, message: "不能超过255", trigger: "change" }],
        shRemark: [
          { required: true, message: "请输入送货备注", trigger: "blur" },
          { max: 750, message: "不能超过750", trigger: "change" },
        ],
        bgZiliao: [
          { required: true, message: "请输入报关资料", trigger: "blur" },
          { max: 500, message: "不能超过500", trigger: "change" },
        ],
        ckAddress: [
          { required: true, message: "请输入仓库地址", trigger: "blur" },
          { max: 1000, message: "不能超过1000", trigger: "change" },
        ],
        ckContact: [
          { required: true, message: "请输入仓库联系人", trigger: "blur" },
          { max: 500, message: "不能超过500", trigger: "change" },
        ],
        // tixing:[
        //     {required:true,message:'请输入提醒',trigger:'blur'},
        //     {max:500,message:'不能超过500',trigger:'change'},
        // ],
      },
      typeVisible: false,

      url: {
        getMark: "/order/os/rucang/info",
        add: "/order/os/rucang/model/add",
        edit: "/order/os/rucang/model/edit",
        checkIsExist: "/order/os/rucang/model/checkIsExist",

      },
    };
  },
  created() {
    // getAction(this.url.getMark,{orderNo:this.$route.query.orderNo})
    //     .then(res=>{
    //         this.order = res.result;
    //         this.codeVisible = true;
    //     })
    console.log("入仓单数据", this.order);

    this.typeVisible = true;

  },
  watch: {},
  methods: {
    bindQrCode() {
      new Qrcode(this.$refs.qrCodeDiv, {
        text: this.order.orderNo,
        width: 110,
        height: 110,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: Qrcode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    formatTime(time) {
      if (time) {
        return time.substring(0, 10);
      }
    },
    saveModel() {
      //校验
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.order.id) {

                let url = this.url.add;
                let method = "post";
                if (this.order.id) {
                  url = this.url.edit;
                  method = "put";
                }
                httpAction(url, method, this.order).then((res) => {
                  this.$message.success(res.message);

                  this.handleClose();
                });

          } else {
            let url = this.url.add;
            let method = "post";
            if (this.order.id) {
              url = this.url.edit;
              method = "put";
            }
            httpAction(url, method, this.order).then((res) => {
              this.$message.success(res.message);

              this.handleClose();
            });
          }
        }
      });
    },
    propdf() {
      let name = "热敏唛头";
      if (this.islogo) {
        name += "logo" + this.$route.query.orderNo;
      } else {
        name += "this.$route.query.orderNo";
      }
      this.getPdf(name, []);
      this.handleClose();
    },

    handleClose() {
      this.codeVisible = false;
      this.order = {};
      this.$emit("update:visible", false);
      this.$emit("ok");
    },
  },
};
</script>

<style lang="scss" scoped>
.ii {
  text-align: center;
}
.el-col-lg-4-8 {
  width: 20%;
}
.el-col-lg-8-4 {
  width: 80%;
}
.gz {
  height: 120px;
  width: 120px;
  position: absolute;
  top: 1400px;
  right: 50px;
  background: none;
}
.ps {
  height: 600px;
  font-size: 33px;
}
.ck {
  margin-top: 20px;
  height: 30px;
  font-size: 33px;
}
.ckdz {
  height: 65px;
  text-align: center;

  line-height: 65px;
}
.shfs {
  height: 90px;

  /*line-height: 30px;*/
  margin-bottom: 20px;
}
.zl {
  height: 25px;
  text-align: start;
  font-size: 25px;
  line-height: 25px;
}
.tx {
  height: 50px;
  text-align: start;
  line-height: 50px;
  font-size: 20px;
}
.bb {
  border: 1px solid black;
}
.kk {
  border: 1px solid black;
  height: 100%;
}
.el-col-lg-4-9 {
  width: 205px;
}
.el-col-lg-4-10 {
  width: 172px;
}
.el-col-lg-4-12 {
  width: 171.5px;
}
.bq {
  border: 1px solid black;
  height: 22px;
  text-align: center;
}
.bq2 {
  border: 1px solid black;
  height: 22px;
  text-align: start;
}
.sh {
  height: 132px;
  text-align: center;
  color: red;
}
.yaoqiu {
  border: 1px solid black;
  height: 132px;
  text-align: center;
  line-height: 132px;
  font-size: 13px;
}
.kb {
  height: 39px;
  border: 1px solid black;
}
.bz {
  margin-bottom: 20px;
  /*border: 1px solid black;*/
}
.kb2 {
  height: 79px;
  border: 1px solid black;
  color: red;
  font-size: 16px;
}
.fee3 {
  min-width: 90px;
  height: 130px;
  /*border: 1px solid black;*/
  text-align: right;
  line-height: 130px;
  font-size: 14px;
  margin-right: 11px;
}
.fee {
  min-width: 90px;
  height: 40px;
  /*border: 1px solid black;*/
  text-align: right;
  line-height: 45px;
  font-size: 14px;
  margin-right: 11px;
  margin-bottom: 16px;
}
.fee2 {
  min-width: 90px;
  height: 45px;
  // border: 1px solid black;
  text-align: right;
  font-size: 14px;
  margin-right: 11px;
}
textarea {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
}
input {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
}
.name {
  font-size: 18px;
  height: 178px;
  margin: 0;
  text-align: center;
  border: 1px solid black;
}
.img {
  margin: 10px auto;
  height: 112px;
  width: 112px;
}
::v-deep .el-dialog__body {
  padding: 20px 0px;
}

.el-row {
  margin: 0 20px;
}

.bookNo {
  font-size: 1em;
  font-family: "SimSun";
  color: #000000;
}

.trans {
  font-weight: bold;
  font-size: 28px;

  text-align: center;
}
.orderNo1 {
  font-size: 18px;

  margin: 0;
  text-align: center;
  border: 1px solid black;
}
.orderNo2 {
  font-size: 18px;
  height: 139px;
  margin: 0;
  text-align: center;
  border: 1px solid black;
}

.orderNo {
  font-size: 18px;

  margin: 0;
  text-align: center;
  border: 1px solid black;
}
.code {
  font-size: 12.6667px;

  transform: scaleX(0.921816);
  margin-top: 10px;
  text-align: center;
}
.address {
  margin-top: 10px;
  text-align: center;
  font-size: 50px;

  transform: scaleX(0.939568);
}
.remark {
  font-size: 33.3333px;

  transform: scaleX(0.951272);
  margin-top: 10px;
  text-align: center;
}
.info {
  font-size: 12.6667px;

  transform: scaleX(0.921816);
  margin-top: 15px;
  text-align: center;
}
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
