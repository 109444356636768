<template>
  <el-dialog :title="row.id ? '编辑渠道代码' : '新增渠道代码'" :visible="visible" width="560px"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="auto" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="channelName" label="渠道名称">
            <el-input v-model.trim="addForm.channelName" placeholder="请输入渠道名称" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="code1" label="代码1">
            <el-input v-model.trim="addForm.code1" placeholder="请输入代码1" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="code2" label="代码2">
            <el-input v-model.trim="addForm.code2" placeholder="请输入代码2" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="area" label="区域">
            <el-input v-model.trim="addForm.area" placeholder="请输入区域" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="fbaCode" label="fbaCode">
            <el-input v-model.trim="addForm.fbaCode" placeholder="请输入fbaCode" maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="location" label="储位">
            <el-input v-model.trim="addForm.location" placeholder="请输入储位" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'


export default {
  name: 'EditChannelCodesDialog',

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      addForm: {},
      rules: {
        channelName: [
          { required: true, message: '请输入渠道名称', trigger: 'blur' },
        ],
        code1: [
          { required: true, message: '请输入代码1', trigger: 'blur' },
        ],
        code2: [
          { required: false, message: '请输入代码2', trigger: 'blur' },
        ],
      },
      url: {
        add: '/jeecg-cloud-system-biz/channelCodes/insert',
        edit: '/jeecg-cloud-system-biz/channelCodes/updateById',
      },
    }
  },
  watch: {
    visible(val) {
      if (val && this.row.id) {
        if (this.type == 'log') {
          this.initData = {
            id: this.row.id,
          }
          this.handleReset();
        } else {
          const { id, channelName, code1, code2, area, fbaCode, location } = this.row
          this.addForm = { id, channelName, code1, code2, area, fbaCode, location }
        }
      } else {
        this.addForm = {}
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
      let url = this.url.add
      if (this.row.id) {
        url = this.url.edit
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          httpAction(url, 'POST', this.addForm).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
