<template>
  <el-dialog :title="type == 'log' ? '日志' : (row.id ? '编辑起运地' : '新增起运地')" :visible="visible" width="560px"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type == 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
      class="c-th-has-bgcolor" :columns="columns" :data="tableData" :show-operation="true"
      :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
      @sort-change="handleTableChange">
    </f-table>
    <el-form v-else :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="region" label="所属区域">
            <el-input v-model.trim="addForm.region" placeholder="请输入所属区域" maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="departure" label="起运地">
            <el-input v-model.trim="addForm.departure" placeholder="请输入起运地" maxlength="20"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";


export default {
  name: 'EditPlaceDialog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    // 弹窗类型
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,
      columns: [
        {
          prop: "detail",
          label: "详情",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],
      addForm: {},
      rules: {
        region: [
          { required: true, message: '请输入所属区域', trigger: 'blur' },
        ],
        departure: [
          { required: true, message: '请输入起运地', trigger: 'blur' },
        ],
      },
      url: {
        add: '/sys/bd/departure/addOne',
        edit: '/sys/bd/departure/editOne',
        list: '/sys/bd/departure/queryLogsById'
      },
    }
  },
  watch: {
    visible(val) {
      if (val && this.row.id) {
        if (this.type == 'log') {
          this.initData = {
            id: this.row.id,
          }
          this.handleReset();
        } else {
          const { id, region, departure } = this.row
          this.addForm = { id, region, departure }
        }
      } else {
        this.addForm = {}
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
      let url = this.url.add
      if (this.row.id) {
        url = this.url.edit
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          httpAction(url, 'POST', this.addForm).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
