<template>
  <all-order-products></all-order-products>
</template>

<script>
import AllOrderProducts from "@/views/conformance/modules/AllOrderProducts";
export default {
  name: "AllOrderProducts2",

  components: {AllOrderProducts},

};
</script>

<style lang="scss" scoped>
</style>
