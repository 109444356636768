<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="580px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="'right'" label-width="109px">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="checkStatus" label="查账结果">
                        <el-select v-model="billForm.checkStatus" @change="handleChange">
                            <el-option label="查账正常" value="1"></el-option>
                            <el-option label="查账异常" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23" v-if="unusualInfoVisible">
                    <el-form-item prop="checkUnusualInfo" label="异常原因">
                        <el-input type="text" v-model="billForm.checkUnusualInfo" style="width: 250px"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23" v-if="!unusualInfoVisible">
                    <el-form-item prop="realMoney" label="水单金额">
                        <el-input type="text" v-model="billForm.billMoney" style="width: 250px" oninput="value=value.match(/\d+\.?\d{0,3}/)"
                                  disabled="disabled"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23" v-if="!unusualInfoVisible">
                    <el-form-item prop="salesMoney" label="实际到账总金额">
                        <el-input type="text" v-model="billForm.salesMoney" style="width: 250px;font-weight: bold" oninput="value=value.match(/^[-]?\d*\.?\d{0,3}/)"
                                  ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23" v-if="!unusualInfoVisible">
                    <el-form-item prop="salesMoneyCur" label="实际到账币种">
                        <f-select
                            v-model="billForm.salesMoneyCur"
                            :isNeed="billForm.salesMoneyCur"
                            :dict="'bd_cur'"
                            placeholder="请选择折合币种"
                            clearable
                            filterable
                            class="f-full-width"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23" v-if="!unusualInfoVisible">
                    <el-form-item prop="checkBillTime" label="查账时间">
<!--                        <el-input type="text" v-model="billForm.checkBillTime" style="width: 250px"></el-input>-->
                        <el-date-picker
                                class="f-flex-1"
                                v-model="billForm.checkBillTime"
                                type="datetime"
                                size="small"
                                placeholder="请选择时间"

                                value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="remark" label="备注">
                        <el-input type="area" v-model="billForm.remark" style="width: 250px" disabled></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'
    import moment from 'moment'

    export default {
        name: 'noConvertBill',


        data() {
            return {
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                unusualInfoVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                lang: [
                    {required: true, message: '请选择账单语言', trigger: 'change'},
                ],
                billForm: {},
                url: {
                    checkBill: '/shipping/hySettleReceive/checkBill',
                },
                rules: {
                        checkStatus: [
                            {required: true, message: '请选择查账结果', trigger: 'change'},
                        ],
                        // noReason: [
                        //     {required: true, message: '请选择账单语言', trigger: 'change'},
                        // ],
                        realMoney: [
                            {required: true, message: '请输入收款总金额', trigger: 'blur'},
                        ],
                        salesMoney: [
                            {required: true, message: '请输入实际收款金额', trigger: 'change'},
                        ],
                        salesMoneyCur: [
                            {required: true, message: '请选择实际收款币种', trigger: 'change'},
                        ],
                        checkBillTime: [
                            {required: true, message: '请选择查账时间', trigger: 'blur'},
                        ],
                        checkUnusualInfo: [
                            {required: true, message: '请输入异常原因', trigger: 'blur'},
                        ],

                    },
                title: '查账',
                settleIds: [],

            }
        },
        methods: {
            handleChange(val) {
                this.$refs["billForm"].clearValidate();//重置校验
                if (val == '1'){

                    this.unusualInfoVisible = false;
                }else{
                    this.unusualInfoVisible = true;

                }

            },
            handleSubmit() {
                this.billForm.checkBillTime = moment(this.billForm.checkBillTime).format('YYYY-MM-DD HH:mm:ss')
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.ifDis = true
                        this.enabled()

                        postAction(this.url.checkBill,this.billForm).then((res) => {
                            if(res.success){
                                // postAction(this.url.openBill, this.billForm).then(res => {
                                //        console.log(res)
                                //        if (res.success === true){
                                //            this.$message.success("操作成功！")
                                //        }else{
                                //            this.$message.error("操作失败！")
                                //        }
                                //     })
                                this.billForm = {};
                                this.visible=false;
                                this.$emit('ok')
                            }
                        })


                    }
                })
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.billForm = {};
                this.visible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(row) {
                if (this.billForm.checkStatus == undefined || this.billForm.checkStatus == null) {
                    this.$set(this.billForm,'checkStatus','1');
                }
                for (var name in row){
                    this.$set(this.billForm,name,row[name]);
                }
                this.$set(this.billForm,'salesMoney','');
                this.$set(this.billForm,'salesMoneyCur',row.needCur);
                this.$set(this.billForm,'remark','');
                // this.billForm = {...row}
                if (this.billForm.checkStatus === '0'){
                    this.billForm.checkStatus = ''
                }
                this.$set(this.billForm,'checkBillTime',new Date());
                if (this.billForm.checkStatus === '1'){
                    this.unusualInfoVisible = false;
                }
                if (this.billForm.checkStatus === '2'){
                    this.unusualInfoVisible = true;

                }
                this.visible = true

            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
