import { render, staticRenderFns } from "./NormalEd.vue?vue&type=template&id=ab0e5138&scoped=true&"
import script from "./NormalEd.vue?vue&type=script&lang=js&"
export * from "./NormalEd.vue?vue&type=script&lang=js&"
import style0 from "./NormalEd.vue?vue&type=style&index=0&id=ab0e5138&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab0e5138",
  null
  
)

export default component.exports