import { render, staticRenderFns } from "./KdOrderProfit.vue?vue&type=template&id=112e9c65&scoped=true&"
import script from "./KdOrderProfit.vue?vue&type=script&lang=js&"
export * from "./KdOrderProfit.vue?vue&type=script&lang=js&"
import style0 from "./KdOrderProfit.vue?vue&type=style&index=0&id=112e9c65&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112e9c65",
  null
  
)

export default component.exports