
<template>
    <div class="main-full-content">
        <!-- 新布局 -->
        <div class="main-full-content">
            <!-- <el-divider content-position="left"></el-divider> -->
            <!-- <div style="margin-left: 50px;position: absolute;top: 10px;font-size:18px">发布端口</div> -->
            <el-divider content-position="left" style="font-size:18px">文章类型编辑</el-divider>
            <el-tabs tab-position="left" class="pushPort" @tab-click="handleClick">
                <el-tab-pane
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :label="tab.label"
                    :lazy="true">
                    <component ref="childTab" style="padding:0" :is="tab.component" ></component>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../mixins/QueryMixins";

    // 组件
    import PcBackstage from './modules/PcBackstage'
    import PcClient from './modules/PcClient'
    import AppBackstage from './modules/AppBackstage'
    import AppClient from './modules/AppClient'

    export default {
        name: 'Potential',
        components:{
            PcBackstage,
            PcClient,
            AppBackstage,
            AppClient,
        },
        mixins: [QueryMixins],
        data() {
            return {
                tabs: [
                    { label: 'PC员工端', component: 'PcBackstage', show: true },
                    { label: 'PC客户端', component: 'PcClient', show: true },
                    { label: '小程序员工端', component: 'AppBackstage', show: true },
                    { label: '小程序客户端', component: 'AppClient', show: true }
                ],
                optionsType: [
                    {
                        value: '0',
                        label: 'PC后台'
                    },
                    {
                        value: '1',
                        label: 'PC客户端'
                    },
                    {
                        value: '2',
                        label: 'Applets后台'
                    },
                    {
                        value: '3',
                        label: 'Applets客户端'
                    },
                ],
                rules: {
                    articleType: [
                        {required: true, message: '请输入类型名称', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},

                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '100px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'articleType',
                        label: '类型名称',
                        sortable: 'custom',

                    },
                    {
                        prop: 'pushPort',
                        label: '发布端口',
                        sortable: 'custom',

                    },
                    {
                        prop: 'sort',
                        label: '排序',
                        sortable: 'custom',

                    },
                ],
                url: {
                    list: '/sys/managerType/list',
                    add: '/sys/managerType/add',
                    edit: '/sys/managerType/edit',
                    getById: '/sys/managerType/queryById',
                    delete: '/sys/managerType/delete',
                },
            }
        },
        methods: {
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除报价说明`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleClick(e) {
              this.$refs.childTab[e.index] ? this.$refs.childTab[e.index].handleReset() : '';
            },
        },
        created() {

        },

    }
</script>

<style lang="scss" scoped>
.pushPort{
    display: flex;
}
</style>
