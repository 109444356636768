import { render, staticRenderFns } from "./NoReviewed.vue?vue&type=template&id=534dc429&scoped=true&"
import script from "./NoReviewed.vue?vue&type=script&lang=js&"
export * from "./NoReviewed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534dc429",
  null
  
)

export default component.exports