<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="活动名称">
                        <el-input v-model="queryForm.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="活动名称">
                        <f-select
                            v-model="queryForm.cc"
                            dict="TEST_TEST"
                            clearable
                            filterable
                            placeholder="请选择"></f-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="9">
                    <el-form-item label="时间">
                        <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                style="width:100%"
                                v-model="queryForm.start"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                style="width:100%"
                                v-model="queryForm.end"
                                type="datetime"
                                size="small"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <f-table
            v-loading="loading"
            border
            :options="options"
            ref="GTable"
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :isorter="isorter"
            :show-operation="true"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange"
            @queryChange="handleTableQueryChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope)">编辑</el-button>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
import { getAction } from "@/api/manage"
import QueryMixins from "@/mixins/QueryMixins.js"
export default {
    name: 'Dict',
    mixins: [QueryMixins],
    components: {

    },
    data() {

        return {
            filelist: [],
            options:[
            {value:'0',label:'测试'},
            {value:'1',label:'测试2'},
          ],

            columns: [
                {
                    type: 'selection',
                    width: 50
                },
                {
                    prop: 'date',
                    label: '日期',
                    sortable: 'custom',
                    headerType: 'string',
                    minWidth: 180,
                },
                {
                    prop: 'name',
                    label: '姓名',
                    sortable: 'custom',
                    headerType: 'range',
                    minWidth: 200,
                },
                {
                    prop: 'address',
                    label: '地址',
                    sortable: 'custom',
                    headerType: 'datetime',
                    minWidth: 240,
                },
                {
                    prop: 'age',
                    label: '年龄',
                    sortable: 'custom',
                    headerType: 'select',
                    dict:'salesmanId',
                    minWidth: 240,
                },
                {
                    prop: 'action',
                    label: '操作',
                    width: 200,
                },
            ],
            url: {
                list: '/sys/role/list'
            }
        }
    },
    mounted() {

    },
    methods: {
        handleChange(rest) {
            console.log(rest)
        },
        handleEdit(scope) {
            console.log(scope)
        }
    }
}
</script>
