<!--待提交-->
<template>
    <el-dialog :title="'子柜子'" :visible.sync="visible" :close-on-click-modal="false" :before-close="handleClose"
               v-if="visible" width="70%" append-to-body>


    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>


                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">

                    <template v-if="scope.column.property === 'complianceState'">
                        <span>{{ parseDict(scope.row.complianceState, "compliance_state")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetCategory'">
                        <span>{{parseDict(scope.row.cabinetCategory, "cabinet_category")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookAgentId'">
                        <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id_chinese") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompanyId'">
                        <span>{{ parseDict(scope.row.shipCompanyId, "ship_company") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'weekStatus'">
                        <span v-if="scope.row.weekStatus == '0'">自拼</span>
                        <span v-if="scope.row.weekStatus == '1'">非自拼</span>
                        <span v-if="scope.row.weekStatus == '2'">传统物流</span>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="qaq(scope.row.id)">{{ scope.row.week }}</a>
                        <!-- 换成a标签，支持复制 -->
                        <!-- <el-button type="text" @click="infomation(scope.row.id)">{{scope.row.week}}</el-button> -->
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                </template>
            </f-table>

        </el-card>

    </div>
    </el-dialog>
</template>

<script>
    import {getAction, postAction, putAction, deleteAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins";
    import Vue from "vue";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "SonCabinet",
        mixins: [QueryMixins],
        props:{
            tableList:{
                type : Array,
                default : () => []
            },
            visible:{
                type : Boolean,
                default : false
            },
            containerId:{
                type : String,
                default : ''
            }
        },
        data() {
            return {
                disableMixinMounted:true,

                dictCodes: ["compliance_state", "cabinet_category", "bookAgent_id_chinese", "ship_company"],
                formLabelWidth: "130px",
                //控制弹框显示
                columns: [
                    {
                        type: "index",
                        label: "#",

                    },
                    {
                        prop: "week",
                        label: "周数",

                    },
                    {
                        prop: "bookAgentId",
                        label: "订舱代理",


                    },
                    {
                        prop: "shipCompanyId",
                        label: "船公司",


                    },
                    {
                        prop: "eta",
                        label: "ETA",


                    },
                    {
                        prop: "size",
                        label: "柜型",


                    },
                    {
                        prop: "bookNo",
                        label: "订舱单号",

                    },
                    {
                        prop: "containerNo",
                        label: "柜号",


                    },
                    {
                        prop: "originalNo",
                        label: "封条",


                    },
                    {
                        prop: "weekStatus",
                        label: "是否自拼",
                        // headerType: "string",
                    },
                ],
                url: {
                    list: "/operation/container/getSonContainerListByContainerId",

                },
                isUsePost:true
            };
        },
        methods:{
            qaq(id){
                this.handleClose();
                this.$intoOperationCabinetEditPage(id)
            },
          handleClose(){
              this.$emit('close');
          }
        },
        created() {
            this.initData.id = this.containerId;
        },
        watch:{
            visible(val){
                if (val) {
                    this.tableData = this.tableList;
                    this.initData.id = this.containerId;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
