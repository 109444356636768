<template>
    <el-dialog
        :title="row.id ? '编辑跨境堡账号' : '新增跨境堡账号'"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="name" label="姓名">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="phone" label="手机号">
                        <el-input v-model="form.phone" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="kjbAccount" label="跨境堡账号">
                      <el-input v-model="form.kjbAccount" ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
<!--            <el-button type="primary" size="medium" @click="makePinyin">确定2</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
import { httpAction} from '@/api/manage'
export default {
    name: 'AddKjbDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {},
            rules: {
             /*   roleName: [
                    { required: true, message: '请输入角色名称', trigger: 'change' },
                    { pattern: /^[\u0391-\uFFE5A-Za-z]+$/ , message: '角色名称只能由中文、英文字母组成', trigger: 'change'}
                ],
                roleCode: [
                    { required: true, message: '请输入角色编码', trigger: 'change' },
                ],
                description: [
                    { required: true, message: '请输入描述', trigger: 'change' },
                    { max:100,message: "描述最多填写50个字符！", trigger: 'change'}
                ],*/
            },
            url: {
                add: '/order/insurance/binding/add',
                edit: '/order/insurance/binding/edit'
            }
        }
    },
    watch: {
      visible(val) {
        if(val && this.row.id) {
          const { id, kjbAccount, name, phone } = this.row
          this.form = { id, kjbAccount, name, phone }
        } else {
          this.form = {}
        }
      },
    },
    methods: {
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
                method = 'PUT'
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },

        handleClose() {
            this.$emit('update:visible', false)
            this.$refs.form.resetFields()
        },


    }
}
</script>

<style lang="scss" scoped>

</style>
