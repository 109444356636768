<template>
    <el-dialog
            :title="title"
            :visible="visible"
            :before-close="handleCancel"
    >
        <template slot="footer">
            <!--            <el-button type="primary" @click="checkAddress">校验地址</el-button>-->
            <el-button @click="handleCancel" style="margin-left: 15px;">取消</el-button>
            <el-button type="primary" style="margin-left: 15px;" @click="handleOk">确定
            </el-button>
        </template>


        <el-form class="addEditBox" :model="form" ref="form" :rules="validatorRules" v-loading="confirmLoading">
            <!-- 主表单区域 -->
            <el-row>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="供应商名称"
                        prop="contractorName"
                        label-width="90px">
                    <el-input maxlength="100" show-word-limit
                              placeholder="请输入供应商名称"
                              v-model="form.contractorName"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="账号"
                        label-width="90px"
                >
                    <el-input
                            placeholder="请输入账号"
                            v-model="form.account"
                            maxlength="15" show-word-limit
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="密钥"
                        prop="password"
                        label-width="90px">
                    <el-input
                            placeholder="请输入密钥"
                            v-model="form.password"
                            maxlength="50" show-word-limit
                    ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="编码1"
                        label-width="90px"
                >
                    <el-input
                            placeholder="请输入编码1的值，并在备注中说明用途"
                            v-model="form.code1"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="编码2"
                        label-width="90px"
                >
                    <el-input
                            placeholder="请输入编码2的值，并在备注中说明用途"
                            v-model="form.code2"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="编码3"
                        label-width="90px">
                    <el-input
                            placeholder="请输入编码3的值，并在备注中说明用途"
                            v-model="form.code3"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="shipperNumber"
                        prop="shipperNumber"
                        label-width="100px"
                >
                    <el-input
                            placeholder="请输入shipperNumber"
                            v-model="form.shipperNumber"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="地址行1"
                        prop="shipperAddressline1"
                        label-width="90px"
                >
                    <el-input
                            placeholder="请输入地址行1"
                            v-model="form.shipperAddressline1"
                            maxlength="35" show-word-limit
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="地址行2"
                        label-width="90px"
                >
                    <el-input
                            placeholder="请输入地址行2"
                            v-model="form.shipperAddressline2"
                            maxlength="35" show-word-limit
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="发货城市"
                        label-width="90px"
                >
                    <el-input type="text"
                              v-model="form.shipperCity"
                              maxlength="20" show-word-limit
                              placeholder="请输城市名称"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="发货州"
                        prop="shipperStateProvinceCode"
                        label-width="90px"
                >
                    <el-input type="text"
                              v-model="form.shipperStateProvinceCode"
                              placeholder="请输入州的二字码"
                              maxlength="2" show-word-limit
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="邮编"
                        label-width="90px"

                >
                    <el-input type="text"
                              v-model="form.shipperPostalCode"
                              maxlength="15" show-word-limit
                              placeholder="请输入邮编"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="国家"
                        prop="shipperCountryCode"
                        label-width="90px"
                >
                    <el-input type="text"
                              v-model="form.shipperCountryCode"
                              placeholder="请输入国家的二字码"
                              maxlength="2" show-word-limit

                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="发货公司"
                        label-width="90px"
                >
                    <el-input type="text"
                              v-model="form.shipperName"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="联系人"
                        label-width="90px"
                >
                    <el-input type="text"
                              v-model="form.shipperAttentionName"
                              placeholder="请输入联系人"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="联系电话"
                        label-width="90px"
                >
                    <el-input type="text"
                              v-model="form.shipperPhone"
                              placeholder="请输入联系电话"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="备注"
                        label-width="90px"
                >
                    <el-input type="textarea"
                              v-model="form.remark"
                              placeholder="请输入备注"
                    />
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
    </el-dialog>

</template>

<script>
    import {deleteAction, getAction, getallusers, postAction, putAction, httpAction} from '@/api/manage'
    import {addressEnum, checkAddress} from "../api/Ups";
    import pick from 'lodash/pick'
    import { setUpRouteUtil } from '@/utils/util';


    export default {
        name: "upsPushAddress",
        components: {},
        data() {
            return {
                title: "操作",
                visible: false,
                orderMainModel: {
                    jeecgOrderCustomerList: [{}],
                    jeecgOrderTicketList: [{}]
                },
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 5},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
                dataSource: [],
                confirmLoading: false,
                form: {},
                validatorRules: {
                    contractorName: [
                        {required: true, message: '公司或名字不可为空', trigger: 'blur'},
                    ],
                    shipperCountryCode: [
                        {required: true, message: '国家不可为空', trigger: 'blur'},
                        {pattern: /^[a-zA-Z]+$/, message: '国家只能输入二字码，请重新输入', trigger: 'change'}
                    ],
                    shipperStateProvinceCode: [
                        {required: true, message: '请选择州/省', trigger: 'blur'},
                        {pattern: /^[a-zA-Z]+$/, message: '州/省只能输入二字码，请重新输入', trigger: 'change'}
                    ],
                    city: [
                        {required: true, message: '城市不可为空', trigger: 'blur'},
                    ],
                    zip: [
                        {required: true, message: '邮编不可为空', trigger: 'blur'},
                    ],
                    address1: [
                        {required: true, message: '地址栏1不可为空', trigger: 'blur'},
                    ],

                },
                url: {
                    add: "/interface/contractor/add",
                    edit: "/interface/contractor/edit",
                },
            }
        },
        created() {

        },
        methods: {
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            validateCountry(rule, value, callback) {
                if (!value || new RegExp(/^[a-zA-Z]+$/).test(value)) {
                    callback();
                } else {
                    callback("国家只能输入二字码，请重新输入。");
                }
            },

            add() {
                this.edit({});
            },
            edit(record) {
                const row = {...record}
                this.form = row
                this.visible = true;
            },
            close() {
                this.$emit('close');
                this.visible = false;
            },

            handleOk() {
                const that = this;


                // 触发表单验证
                this.$refs['form'].validate((err, values) => {
                    if (err) {
                        that.confirmLoading = true;

                        let url = this.url.add
                        let method = 'POST'
                        if (this.form.id) {
                            url = this.url.edit
                            method = 'PUT'
                        }

                        this.confirmLoading = true;

                        httpAction(url, method, that.form).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.$emit('ok')
                                this.close()
                            }
                        }).finally(() => {
                            this.confirmLoading = false
                            this.$emit('ok')
                        })

                    }
                })

            },
            handleCancel() {
                this.close()
            },
        }
    }
</script>

<style scoped>
    .addEditBox {
      max-height: 625px;
      overflow: scroll;
    }
    .ant-btn {
        padding: 0 10px;
        margin-left: 3px;
    }

    .ant-form-item-control {
        line-height: 0px;
    }

    /** 主表单行间距 */
    .ant-form .ant-form-item {
        margin-bottom: 10px;
    }

    /** Tab页面行间距 */
    .ant-tabs-content .ant-form-item {
        margin-bottom: 0px;
    }
</style>
