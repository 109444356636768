<template>
  <div class="main-full-content">
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="用车编号">
              <el-input
                :rows="1"
                v-model="queryForm.thOrderNo"
                type="textarea"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="服务商">
              <f-select
                clearable
                filterable
                class="f-full-width"
                :dict="'logistics_provider'"
                v-model="queryForm.thSupplier"
                :fatherVal="queryForm.thSupplier"
                placeholder="请选择"
                style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item
              label="司机姓名"
              v-if="this.queryForm.thSupplier == '大森林'"
            >
              <f-select
                clearable
                filterable
                class="f-full-width"
                :dict="'all_driver'"
                v-model="queryForm.driverName"
                :fatherVal="queryForm.driverName"
                placeholder="请选择"
                style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="司机手机号">
              <el-input v-model="queryForm.driverTel" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="分配时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.startDistributionTime"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.endDistributionTime"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="调度员">
              <f-select
                clearable
                filterable
                class="f-full-width"
                :dict="'operations'"
                v-model="queryForm.dispatch"
                :fatherVal="queryForm.dispatch"
                placeholder="请选择"
                style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="small" @click="handleExportXls('提货中列表')"
                   v-if="buttonShowList.dc"

          >导出</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="61vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
                  <el-button
              type="text"
              @click="Complete(scope.row)"
              v-if="scope.row.thSupplier == '大森林' && buttonShowList.wcth"


              >完成提货
            </el-button>
            <el-button type="text" @click="Cancel(scope.row)"
            style="position: relative;left: -5px;"
                       v-if="buttonShowList.qxth"

              >取消提货</el-button
            >

          </template>
          <template v-else-if="scope.column.property === 'thOrderNo'">
     <!--       <el-button type="text" @click="See(scope.row)">{{scope.row.thOrderNo }}</el-button>-->
            <a style="color: #00a0e9" @click.prevent="See(scope.row)">{{ scope.row.thOrderNo }}</a>
          </template>
          <template
            v-else-if="
              scope.column.property === 'thSupplier' &&
              scope.row.thSupplier == '货拉拉'
            "
          >
            <el-popover
              trigger="hover"
              placement="top"
              @show="doPopover(scope.row.huolalaOrderStatus)"
            >
              <el-timeline :reverse="true" v-if="activityList.length > 0">
                <el-timeline-item
                  v-for="(item, index) in activityList"
                  :key="index"
                  :timestamp="item.timestamp"
                >
                  {{ item.content }}
                </el-timeline-item>
              </el-timeline>
              <span v-if="activityList.length === 0">未查询到该货拉拉状态</span>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.thSupplier }}</el-tag>
              </div>
            </el-popover>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
      customClass="customWidth"
      :title="'大森林取消提货'"
      :visible.sync="dialogForestCancelVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="请填写取消原因"
          :label-width="formLabelWidth"
          prop="remark"
        >
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogForestCancelVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="forestCancel()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      customClass="customWidth"
      :title="'提货'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" ref="form">
        <el-form-item
          label="提货服务商"
          :label-width="formLabelWidth"
          prop="thSupplier"
        >
          <el-input
            v-model="form.thSupplier"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="司机"
          :label-width="formLabelWidth"
          prop="driverName"
          v-if="form.thSupplier == '大森林'"
        >
          <el-input
            v-model="form.driverName"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <f-table
        v-loading="loading"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="column"
        :data="form.tableList"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
      </f-table>
      <!--<div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendMailAndSave()">确 定</el-button>
            </div>-->
    </el-dialog>

    <el-dialog
      customClass="customWidth"
      :title="'请选择取消原因'"
      :visible.sync="dialogcancelVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-collapse accordion>
        <el-collapse-item
          :title="type.category"
          :name="type.id"
          v-for="(type, index) in cancelReasonList"
          :key="index"
          style="font-size: 18px"
        >
          <div
            v-for="(list, i) in type.sub_cat"
            :key="i"
            style="
              display: flex;
              justify-content: space-between;
              font-size: 16px;
              align-items: center;
            "
          >
            <span>{{ list.sub_name }}</span>
            <el-radio
              v-model="checked"
              :label="list.sub_id"
              @click.native.prevent="getCheckedName(list)"
            >
              {{ "" }}
            </el-radio>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div style="margin: 20px 0 0; display: flex; justify-content: flex-end">
        <el-button @click="commit()">确认取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "../../../mixins/QueryMixins";
import { getAction, postAction } from "@/api/manage";
import Tempcclate from "../../isystem/Dict";

export default {
  name: "Potential",
  components: { Tempcclate },
  mixins: [QueryMixins],
  data() {
    return {
      rules: {
        remark: [
          { required: true, message: "请输入取消原因", trigger: "change" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
      },
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "120px",
      //控制弹框显示
      dialogcancelVisible: false,
      dialogFormVisible: false,
      dialogForestCancelVisible: false,
      checked: "",
      checkedName: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "thOrderNo",
          label: "提货单号",
          sortable: "custom",
        },
        {
          prop: "huolalaOrderNo",
          label: "货拉拉单号",
          sortable: "custom",
          over: true,
        },
        {
          prop: "thSupplier",
          label: "服务商",
          sortable: "custom",
        },
        {
          prop: "driverName",
          label: "司机姓名",
          sortable: "custom",
        },
        {
          prop: "driverTel",
          label: "司机手机号",
          sortable: "custom",
        },
        {
          prop: "distributionTime",
          label: "分配时间",
          sortable: "custom",
        },
        {
          prop: "dispatch",
          label: "调度员",
          sortable: "custom",
        },
        {
          prop: "ctn",
          label: "件数",
          sortable: "custom",
        },
        {
          prop: "kg",
          label: "重量",
          sortable: "custom",
        },
        {
          prop: "cmb",
          label: "体积",
          sortable: "custom",
        },
        {
          prop: "remark",
          label: "备注",
          sortable: "custom",
          over: true,
        },
        {
          prop: "action",
          label: "操作",
          width: 85
        },
      ],
      column: [
        {
          prop: "orderNo",
          label: "订单号",
          over: true,
        },
        {
          prop: "contactAdd",
          label: "详细地址",
          over: true,
        },
        {
          prop: "ctn",
          label: "件数",
        },
        {
          prop: "kg",
          label: "重量",
        },
        {
          prop: "cmb",
          label: "体积",
        },
      ],
      url: {
        list: "/interface/warehouse/getTihuoingIPage",
        forestCompleteTh: "/order/takeGoogs/forestCompleteTh", //大森林完成提货
        getByThOrderNo: "/order/takeGoogs/getByThOrderNo", //根据提货单号查询
        cancelHuolala: "/order/takeGoogs/cancelHuolala", //货拉拉取消订单
        getCancelReason: "/order/takeGoogs/getCancelReason", //获取货拉拉取消订单的原因
        cancelForest: "/order/takeGoogs/cancelForest", //大森林取消订单
        getHuolalaOrderDetail: "/order/takeGoogs/getHuolalaOrderDetail", //获取货拉拉订单详情
        gethuolalaStatusCode: "/sys/dict/getDictItems/huolala_status_code", //根据货拉拉状态码获取对应的value
        exportXlsUrl: "/file/export/excel/downloadTihuoVoExcel",
      },
      cancelReasonList: [],
      huolalaOrderDetailList: [],
      huolalaOrderStatus: "",
      huolalalist: [],
      huolalamap: {},
      activityList: [],
      isUsePost:true,
    };
  },
  methods: {
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.insuranceNolist = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.insuranceNolist.push(selection[i]["insuranceNo"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    //查看
    See(row) {
      this.form = JSON.parse(JSON.stringify(row));
      getAction(this.url.getByThOrderNo + "?thOrderNo=" + this.form.thOrderNo)
        .then((res) => {
          if (res.success) {
            this.form.tableList = res.result;
          }
        })
      this.dialogFormVisible = true;
    },
    //大森林完成提货
    Complete(row) {
      this.$confirm(`确定完成提货吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          getAction(
            this.url.forestCompleteTh + "?thOrderNo=" + row.thOrderNo
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        });
    },
    //取消提货
    Cancel(row) {
      if ("大森林" == row.thSupplier) {
        this.forestCancelDiorg(row);
      }
      if ("货拉拉" == row.thSupplier) {
        this.huolalaCancel(row);
      }
    },
    forestCancelDiorg(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.form.remark = "";
      this.dialogForestCancelVisible = true;
    },
    forestCancel() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm(`确定取消 ${this.form.thOrderNo} 提货单吗？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          })
            .then(() => {
              getAction(
                this.url.cancelForest +
                  "?thOrderNo=" +
                  this.form.thOrderNo +
                  "&remark=" +
                  this.form.remark
              ).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.dialogForestCancelVisible = false;
                this.handleQuery();
              });
            });
        }
      });
    },
    //获取货拉拉取消原因
    huolalaCancel(row) {
      this.form = row;
      //获取订单详情
      getAction(
        this.url.getHuolalaOrderDetail + "?displayOrderId=" + row.huolalaOrderNo
      )
        .then((res) => {
          if (res.success) {
            this.huolalaOrderDetailList = res.result;
            this.huolalaOrderStatus =
              res.result.order_detail_item.order_item.order_status;
            console.log("货拉拉状态：", this.huolalaOrderStatus);
            //0：配对司机中，1：有司机接单待装货 才可以取消
            if (
              "0" == this.huolalaOrderStatus ||
              "1" == this.huolalaOrderStatus
            ) {
              getAction(
                this.url.getCancelReason +
                  "?displayOrderId=" +
                  row.huolalaOrderNo +
                  "&type=" +
                  this.huolalaOrderStatus
              )
                .then((res) => {
                  if (res.success) {
                    this.cancelReasonList = res.result;
                    this.dialogcancelVisible = true;
                    console.log("搜索地址：", this.cancelReasonList);
                  }
                })
            } else if ("5" == this.huolalaOrderStatus) {
              this.$message.error("该订单超时无司机接单已取消，无需手动取消！");
              return;
            } else if ("4" == this.huolalaOrderStatus) {
              this.$message.error("该订单被司机拒绝！");
              return;
            } else {
              this.$message.error("该订单已有司机接单并在进行中或平台已取消，无法取消！");
              return;
            }
          }
        })
    },
    //确认取消货拉拉订单
    commit() {
      const cancel_json = {};
      cancel_json.displayOrderId = this.form.huolalaOrderNo;
      cancel_json.orderStatus = this.huolalaOrderStatus;
      cancel_json.reasonId = this.checked;
      cancel_json.reason = this.checkedName;
      console.log("选择的东西", this.checked, this.checkedName);
      postAction(this.url.cancelHuolala, cancel_json).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.dialogcancelVisible = false;
          this.handleQuery();
        }
      });
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.thStatus = "2";
      this.queryForm.column = "distributionTime";
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    getCheckedName(val) {
      this.checked = val.sub_id;
      this.checkedName = val.sub_name;
    },
    doPopover(val) {
      console.log("货拉拉的状态", val);
      this.activityList = [];
      if (val) {
        if (val.indexOf("@") !== -1) {
          let list1 = val.split(",");
          for (var i = 0; i < list1.length; i++) {
            let list2 = list1[i].split("@");
            if (this.huolalamap[list2[0]]) {
              this.activityList.push({
                content: this.huolalamap[list2[0]].text,
                timestamp: list2[1],
              });
            } else {
              this.activityList.push({
                content: list2[0],
                timestamp: list2[1],
              });
            }
          }
        }
      }
    },
    //页面一加载存放货拉拉状态码
    inithuolalalist() {
      getAction(this.url.gethuolalaStatusCode).then((res) => {
        this.huolalalist = res.result;
        this.huolalamap = this.listToMap(this.huolalalist, "value");
        console.log(
          "页面开始加载货拉拉状态码",
          this.huolalalist,
          this.huolalamap
        );
      });
    },
  },
  created() {
    this.queryForm.thStatus = "2";
    this.queryForm.column = "distributionTime";
    this.inithuolalalist();
  },
  computed : {
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('docpage/takegoods/TakeGoodsIng','0','导出'),
        'wcth':this.$btnIsShow('docpage/takegoods/TakeGoodsIng','1','完成提货'),
        'qxth':this.$btnIsShow('docpage/takegoods/TakeGoodsIng','1','取消提货'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-collapse-item__header {
  font-size: 18px;
}

radio {
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>
