<!--待发货-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row style="display: flex;flex-wrap: wrap;">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input class="textarea_table" v-model="queryForm.orderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="运单号">
                            <el-input class="textarea_table" v-model="queryForm.runOrderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单开始时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名">
                            <!--  <f-select v-model="queryForm.cusName" :dict="'sys_customer'"
                                        :isNeed="queryForm.cusName"></f-select>-->
                            <el-input class="textarea_table" v-model="queryForm.cusName"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanId" :dict="'salesmanId'"
                                      :isNeed="queryForm.salesmanId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <f-select v-model="queryForm.docId" :dict="'docId'" :is-need="queryForm.docId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否购买保险">
                            <f-select v-model="queryForm.insurance" :dict="'is_default'"
                                      :isNeed="queryForm.insurance"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="配送方式">
                            <f-select v-model="queryForm.deliveryType" :dict="'wh_type'"
                                      :isNeed="queryForm.deliveryType"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="服务商">
                            <el-select
                                    v-model="queryForm.supId"
                                    filterable
                                    clearable
                                    @change="getSupplierChannel"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in allSupplierList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="服务商渠道">
                            <el-select
                                    v-model="queryForm.supChannelId"
                                    filterable
                                    clearable
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in supplierChannelList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录材积开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.volumeSureTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录材积结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.volumeSureTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <f-select :multiple="true" v-model="selectChannelList" :dict="'kd_channel'" :isNeed="selectChannelList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="货运类型">
                            <f-select v-model="queryForm.goodType" :dict="'kd_good_type'" :isNeed="queryForm.goodType"></f-select>
                        </el-form-item>
                    </el-col>

                     <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="自主飞">
                      <f-select
                          v-model="queryForm.isAutoFly"
                          :dict="'is_default'"
                          :isNeed="queryForm.isAutoFly"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="国家">
                      <f-select v-model="queryForm.countryList" :multiple="true" :dict="'bd_country'" :isNeed="queryForm.countryList"></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearchM">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="beachFaHuo" :disabled="selectionRows.length === 0"
                           v-if="buttonShowList.plfh">批量发货
                </el-button>

                     <el-button type="primary" size="medium" @click="handleIsAutoFly" :disabled="!selectionRows.length" v-if="buttonShowList.zzf"
                           >批量修改自主飞
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="67vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleGoods(scope.row)"
                                   v-if="buttonShowList.fh"
                        >发货
                        </el-button>
                        <el-button type="text" @click="handleProblem(scope.row)"
                                   v-if="buttonShowList.bjwtj"
                        >标记问题件
                        </el-button>
                        <el-button type="text" @click="handleCancel(scope.row)"
                                   v-if="buttonShowList.qxdd"
                        >取消订单
                        </el-button>
                        <el-button type="text" @click="handleCancelSureInvoice(scope.row)"
                                   v-if="buttonShowList.qxqrfp"
                        >取消确认发票
                        </el-button>
                        <el-button v-if="scope.row.isEditInvoice == '1' && buttonShowList.xzfp" type="text"
                                   @click="downloadExcel(url.downLoad + '?orderNo=' + scope.row.orderNo,
                                  scope.row.channelName+'-'+scope.row.cusName+'-'+scope.row.receiveCountry+'-'+scope.row.actualCtn+'-'+
                               parseDict(scope.row.declareType,'kd_declare_type')+'-'+scope.row.orderNo,
                                   {}, '.xls')"
                        >下载发票</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'insurance'">
                        <span v-if="scope.row.isInsured== '0' && scope.row.insurance== '1'" style="color: #dd0000"> {{parseDict(scope.row.insurance,'is_default')}} / 未买</span>
                        <span v-else-if="scope.row.isInsured== '0'"> {{parseDict(scope.row.insurance,'is_default')}} / 未买</span>
                        <span v-else>{{parseDict(scope.row.insurance,'is_default')}} / 已买</span>
                    </template>
                    <template v-else-if="scope.column.property === 'declareType'">
                        <span style="color: red">{{parseDict(scope.row.declareType,'kd_declare_type')}}</span>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'channel'">-->
<!--                        <span>{{parseDict(scope.row.channel,'kd_channel')}}</span>-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="handleEdit(scope.row.orderNo)">{{scope.row.orderNo}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'cusName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.cusName}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'recStatus'">
                        <template v-if="scope.row.recStatus === '3'">
                            <span style="color: #dd0000">{{parseDict(scope.row.recStatus,'os_pay_status')}}</span>
                        </template>
                        <template v-if="scope.row.recStatus !== '3'">
                            <span>{{parseDict(scope.row.recStatus,'os_pay_status')}}</span>
                        </template>
                    </template>
                    <template v-else-if="scope.column.property === 'payType'">
                        <template v-if="scope.row.payType && (scope.row.payType.indexOf('周结') !=-1 || scope.row.payType.indexOf('月结') !=-1 ||
                    scope.row.payType.indexOf('签收结') !=-1 )">
                            <span style="color: #dd0000">{{scope.row.payType}}</span>
                        </template>
                    </template>
                    <template v-else-if="scope.column.property === 'supId'">
                        <span>{{parseDict(scope.row.supId,'supplier_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supChannelId'">
                        <span>{{parseDict(scope.row.supChannelId,'supplier_channel_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'receiveCountry'">
                        <span v-if="scope.row.receiveFbaCode">{{scope.row.receiveCountry}} - {{scope.row.receiveFbaCode}}</span>
                        <span v-else>{{scope.row.receiveCountry}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'goodType'">
                        <span>{{parseDict(scope.row.goodType,'kd_good_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isAutoFly'">
                        <span>{{parseDict(scope.row.isAutoFly,'is_default')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-p-v-8 bottom_flex">
              <el-col :span="12" class="total_price">
                <span>{{'总票数：' + (countList.total || '0')}}</span>
                <span>{{'总件数：' + (countList.totalCtn || '0')}}</span>
                <span>{{'总计费重：' + (countList.totalUpdateFeeWeight || '0')}}</span>
                <span>{{'总实重：' + (countList.totalActualWeight || '0')}}</span>
              </el-col>
              <el-col class="f-text-right" :span="12">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
              </el-col>
          </el-row>
        </el-card>
        <add-problem-dialog :visible.sync="proVisible" :row="row" @ok="handleQuery"
                            v-if="proVisible"></add-problem-dialog>

        <el-dialog customClass="customWidth" :title="'发货'" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="服务商" :label-width="formLabelWidth" prop="supId">
                    <el-select v-model="form.supId" filterable clearable @change="getSupplierChannel"
                               style="width: 100%">
                        <el-option v-for="(item,index) in allSupplierList"
                                   :value="item.value"
                                   :label="item.label" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务商渠道" :label-width="formLabelWidth" prop="supChannelId">
                    <el-select v-model="form.supChannelId" filterable clearable style="width: 100%">
                        <el-option v-for="(item,index) in supplierChannelList"
                                   :value="item.value"
                                   :label="item.label" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="空运单号" :label-width="formLabelWidth" prop="airNo">
                    <el-input v-model="form.airNo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="航司" :label-width="formLabelWidth" prop="logNo">
                    <f-select
                        v-model="form.airCompanyCode"
                        :isNeed="form.airCompanyCode"
                        :dict="'air_company'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="跟踪单号" :label-width="formLabelWidth" prop="logNo">
                    <el-input v-model="form.logNo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="fahuo('0')">保 存</el-button>
                <el-button type="primary" @click="fahuo('1')">发 货</el-button>
            </div>

        </el-dialog>


        
                        <auto-fly-dialog  :visible="autoFlyVis" :autoFlyList='autoFlyList'
      @update:visible="
        (val) => {
          autoFlyVis = val;
        }
      "
      @ok="handleQuery"/>
    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import {getAction, getAllSupplier, postAction} from '@/api/manage'
    import AddProblemDialog from "./modules/AddProblemDialog";
    import {setUpRouteUtil} from '@/utils/util';
        import AutoFlyDialog from "./modules/AutoFlyDialog.vue";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        components: {
            AddProblemDialog,
            AutoFlyDialog
        },
        data() {
            return {
                isUsePost:true,
                rules: {
                    supId: [
                        {required: true, message: '请选择服务商', trigger: 'change'},
                    ],
                    supChannelId: [
                        {required: true, message: '请选择服务商渠道', trigger: 'change'},
                    ],
                    logNo: [
                        {max: 80, message: '长度不能超过80', trigger: 'change'},
                    ],
                },
                dictCodes: ['kd_declare_type', 'is_default','os_pay_status','supplier_id','supplier_channel_id','kd_good_type'],
                ids: [],
                row: {},
                form: {},
                proVisible: false,
                formLabelWidth: '100px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                    },
                    {
                        prop: 'runOrderNo',
                        label: '运单号',
                    },
                    {
                        prop: 'cusName',
                        label: '客户名',
                    },
                    {
                        prop: 'insurance',
                        label: '是否购买保险',
                        width: 50
                    },
                       {
                        prop: 'orderGoodsName',
                        label: '货物品名',
                    },
                    {
                        prop: 'receiveCountry',
                        label: '目的地',
                        width: 80
                    },
                              {
                        prop: 'isAutoFly',
                        label: '自主飞',
                    },
                    {
                        prop: 'channelName',
                        label: '渠道名称',
                    },
                    {
                        prop: 'declareType',
                        label: '报关类型',
                    },
                    {
                        prop: 'actualCtn',
                        label: '实际件数',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'updateFeeWeight',
                        label: '计费重(KG)',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'doc',
                        label: '客服',
                    },
                    {
                        prop: 'payType',
                        label: '付款类型',
                    },
                    {
                        prop: 'recStatus',
                        label: '付款状态',
                    },
                    {
                        prop: 'supId',
                        label: '服务商',
                    },
                    {
                        prop: 'supChannelId',
                        label: '服务商渠道',
                    },
                    {
                        prop: 'volumeSureTime',
                        label: '材积录入时间',
                        sortable: 'custom',
                    },
                    {
                        prop: 'invoiceSureTime',
                        label: '发票确认时间',
                        sortable: 'custom',
                    },
                    {
                        prop: 'createTime',
                        label: '下单时间',
                        sortable: 'custom',
                    },
                    {
                        prop: 'goodType',
                        label: '货运类型',
                        sortable: 'custom',
                    },

                ],
                url: {
                    list: '/order/kdOrder/list',
                    problem: '/order/kdOrder/problem',
                    cancel: '/order/kdOrder/cancel',
                    toGoods: '/order/kdOrder/toGoods',
                    queryBysupId: '/sys/supplierChannel/queryBysupId', // 根据供应商ID 获取供应商渠道
                    downLoad: "/file/jasper/downloadKdInvoice",
                    listCount: "/order/kdOrder/listCount", //获取total-总票数,totalCtn-总件数,totalUpdateFeeWeight-总计费重
                    cancelSureInvoice : "/order/kdOrder/cancelSureInvoice", //取消确认发票
                },
                allSupplierList: [],   //快递供应商
                supplierChannelList: [], //快递供应商的渠道
                selectChannelList: [],  //筛选渠道列表
                countList: {},  //统计总值
                  autoFlyVis:false,
                autoFlyList:[]
            }
        },
        methods: {
            initMoreFuc() {
              this.getListCount();
            },
            getListCount(){
              postAction(this.url.listCount,this.queryForm)
                .then(res=>{
                    if (res.result){
                        this.countList = res.result;
                    }else {
                        this.countList = {total:0,totalCtn:0,totalUpdateFeeWeight:0,totalActualWeight:0}
                    }

                })
            },
            handleSearchM() {
              this.queryForm.channelIds = this.selectChannelList;
              this.handleSearch();
            },
            initCusPage({cusId}) {
                if (cusId) {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/customer/Edit');
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }

            },
            //获取所有的 快递供应商
            initAllSupplier() {
                this.allSupplierList = [];
                getAllSupplier("2").then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].id;   //供应商ID
                            obj.label = res.result[i].chinaseName;  //供应商中文名
                            this.allSupplierList.push(obj);
                            console.log("allSupplierList", this.allSupplierList);
                        }
                    }
                }).catch(err => {
                    this.$message.error(err)
                })
            },
            //获取供应商的渠道
            getSupplierChannel(code) {
                this.supplierChannelList = [];
                let url = code ? this.url.queryBysupId + "?supId=" + code : this.url.queryBysupId + "?supId=" ;
                getAction(url)
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id;   //供应商ID
                                obj.label = res.result[i].channelName;  //供应商中文名
                                this.supplierChannelList.push(obj);
                            }
                        }
                    })

            },
            //进入编辑页面
            handleEdit(val) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + val);
            },
            handleReset() {
                this.queryForm = {};
                this.selectChannelList = [];
                this.queryForm.orderStatus = '2';
                this.ipagination.currentPage = 1;
                this.selectChannelList = [];
                this.getSupplierChannel();
                this.handleQuery()
            },
            //标记问题件
            handleProblem(row) {
                //打开标记问题弹窗
                this.row.orderNo = row.orderNo;
                this.proVisible = true;
            },
            //取消订单
            handleCancel(row) {
                this.$confirm(`确定取消订单${row.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    getAction(this.url.cancel + '?orderNo=' + row.orderNo)
                        .then(res => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.handleQuery()
                            }
                        }).catch(err => {
                    })
                }).catch(() => {

                })


            },
            //发货
            handleGoods(row) {
                this.form = JSON.parse(JSON.stringify(row));
                //回显服务商渠道
                if (this.form.supId) {
                    console.log("this.form.supId", this.form.supId);
                    this.getSupplierChannel(this.form.supId);
                }

                this.dialogFormVisible = true;
            },
            fahuo(val) {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let orderNos = '';
                        if (this.selectionRows.length > 0) {
                            //批量发货
                            orderNos = this.selectedRowOrderNos.join(',');
                            this.form.orderNos = orderNos;  //批量发货
                        } else {
                            //单个发货
                            orderNos = this.form.orderNo;
                        }
                        this.form.isToGoods = val;
                        this.$confirm('确定保存或发货' + orderNos, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            postAction(this.url.toGoods, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                }).catch(err => {
                                this.$message.error(err.message)
                            })
                        }).catch(() => {

                        })
                    }
                })
            },
            //批量发货
            beachFaHuo() {
                this.$set(this, 'form', {});
                this.dialogFormVisible = true;
            },
            //取消确认发票
            handleCancelSureInvoice(row){

                this.$confirm(`确定取消确认发票: ${row.orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    getAction(this.url.cancelSureInvoice + '?orderNo=' + row.orderNo)
                        .then(res => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.handleQuery()
                            }
                        }).catch(err => {
                        this.$message.error(err.message)
                    })
                }).catch(() => {

                })

            },
              handleIsAutoFly() {
                 this.autoFlyList = this.selectionRows.map((item) => item.orderNo)
               this.autoFlyVis = true
              
            },
        },
        created() {
            this.queryForm.orderStatus = '2';
            this.initAllSupplier(); //获取所有的快递供应商
            this.getSupplierChannel();
        },
      computed:{
        buttonShowList(){
          return {
            'plfh':this.$btnIsShow('kdorder/WaitDeliverGoods','0','批量发货'),
            'fh':this.$btnIsShow('kdorder/WaitDeliverGoods','1','发货'),
            'bjwtj':this.$btnIsShow('kdorder/WaitDeliverGoods','1','标记问题件'),
            'qxdd':this.$btnIsShow('kdorder/WaitDeliverGoods','1','取消订单'),
            'qxqrfp':this.$btnIsShow('kdorder/WaitDeliverGoods','1','取消确认发票'),
            'xzfp':this.$btnIsShow('kdorder/SureInvoice','1','下载发票'),
             'zzf':this.$btnIsShow('kdorder/WaitDeliverGoods','0','批量修改自主飞'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
