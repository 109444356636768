<template>
    <el-dialog
        center
        width="30%"
        :close-on-click-modal="false"
        :title="row && row.id ? '编辑' : '新增'"
        append-to-body
        :visible.sync="visible"
        :before-close="handleClose"
        >
        <div class="content-wrap c-slim-scrollbar">
            <el-form :model="form" :rules="rules" ref="form" label-width="60px" size="medium">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="itemText" label="名称">
                            <el-input v-model="form.itemText" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="itemValue" label="数值">
                            <el-input v-model="form.itemValue" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="isEnable" label="是否启用">
                            <f-select v-model="form.isEnable" :is-need="form.isEnable" :dict="'unit_whether'" ></f-select>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
        </div>
        <div class="drawer_footer">
            <el-button plain size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
export default {
    name: 'DictItemDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        },
        dictId:{
          type : String,
          default : ''
        }
    },
    data() {
        return {
            form: {},
            rules: {
                itemText: [
                    { required: true, message: '请输入文字', trigger: 'change' },
                ],
                itemValue: [
                    { required: true, message: '请输入数值', trigger: 'change' },
                ],
                isEnable: [
                  { required: true, message: '请选择是否启用', trigger: 'change' },
                ]
            },
            url: {
                add: '/shipping/sales/per/config/dict/item/add',
                edit: 'shipping/sales/per/config/dict/item/edit',
            }
        }
    },
    watch: {
        visible(val) {
            if (this.row) {
              this.form = JSON.parse(JSON.stringify(this.row));
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add;
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                  this.form.dictId = this.dictId;
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose();
                        this.$emit('ok');
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
