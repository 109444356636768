<template>
    <el-dialog
            :title="row.id ? '编辑规则' : '新增规则'"
            :visible="visible"
            width="840px"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
            <el-form-item prop="country" label="国家" :label-width="formLabelWidth">
                <f-select v-model="form.country" :is-need="form.country" :dict="'bd_country'"></f-select>
            </el-form-item>
            <el-form-item prop="solicitation" label="航线名称" :label-width="formLabelWidth">
                <f-select v-model="form.solicitation" :is-need="form.solicitation" :dict="'fba_solicitation'"
                @changet="setSolicitationText"></f-select>
            </el-form-item>
            <el-form-item prop="preHscode" label="海关编码前缀" :label-width="formLabelWidth">
                <el-input v-model="form.preHscode" show-word-limit maxlength="10" ></el-input>
            </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'

    export default {
        name: 'MinganGoodsRuleDialog',
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                formLabelWidth:'100px',

                form: {},

                url: {
                    addOrEdit:'/sys/minGanGoodsRules/addOrUpdate',
                },
                rules:{

                    preHscode:[
                        {required:true,message:'请输入编码',trigger:'change'}
                    ],
                }

            }
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    this.form = JSON.parse(JSON.stringify(this.row));
                }
            },

        },
        methods: {
            setSolicitationText(val){
                this.$set(this.form,'solicitationText',val);
            },
            handleSubmit() {

                this.$refs.form.validate((valid) => {
                    if (valid) {
                        postAction(this.url.addOrEdit, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.form.resetFields()
            }
        },
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
