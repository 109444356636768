import { render, staticRenderFns } from "./OceanModel.vue?vue&type=template&id=3e03ddfe&scoped=true&"
import script from "./OceanModel.vue?vue&type=script&lang=js&"
export * from "./OceanModel.vue?vue&type=script&lang=js&"
import style0 from "./OceanModel.vue?vue&type=style&index=0&id=3e03ddfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e03ddfe",
  null
  
)

export default component.exports