<!--待审核-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" :inline="true" label-position="right" size="medium">
            <!--                  2           -->
            <div v-if="activeName == 'salesOrderSum'">
                <el-card class="box-card">
                    <el-row :gutter="24" class="inputs_row">
                        <el-col :md="5" :sm="24" v-if="buttonShowList['业务员统计']">
                            <!--<el-form-item label="业务员" :label-width="formLabelWidth">-->
                            <!--<f-select-->
                            <!--v-model="queryForm.salesmanId"-->
                            <!--:isNeed="queryForm.salesmanId"-->
                            <!--:dict="'salesmanId'" placeholder="请选择" filterable clearable-->
                            <!--@changet="getSalemanName">-->
                            <!--</f-select>-->
                            <!--</el-form-item>-->
                            <el-form-item label="业务员" v-if="buttonShowList['业务员统计']">
                                <!--<f-select :dict="'salesmanId'" v-model="queryForm.salesmanId"-->
                                <!--:is-need="queryForm.salesmanId" ></f-select>-->
                                <el-select
                                        v-model="salesmanList"
                                        multiple
                                        @change='changeSelect'
                                        collapse-tags
                                        filterable
                                        style="width: 200px"
                                        placeholder="请选择">
                                    <el-checkbox v-model="checked" @change='selectAll'>全选</el-checkbox>
                                    <el-option
                                            v-for="item in this.dictMap.salesmanId"
                                            :key="item.value"
                                            :label="item.text"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md="5" :sm="24">
                            <el-form-item label="时间">
                                <el-date-picker
                                        v-model="queryForm.month"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM"
                                        type="month"
                                        placeholder="选择月">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>

                        <el-col :span="4">
                            <el-button
                                    v-if="buttonShowList['业务员统计']"
                                    size="medium"
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button>
                            <el-button
                                    v-if="buttonShowList['业务员统计']"
                                    size="medium"
                                    type="primary"
                                    @click="handleReset"
                                    icon="reload"
                                    style="margin-left: 8px"
                            >重置
                            </el-button>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
            <!--             3          -->
            <div style="display:flex;" v-if="activeName == 'salesmanConfig'">
                <el-card class="box-card">
                    <el-row :gutter="24" class="inputs_row">
                        <el-col :md="5" :sm="24" v-if="buttonShowList['业务员统计']">
                            <!--<el-form-item label="业务员" :label-width="formLabelWidth">-->
                            <!--<f-select-->
                            <!--v-model="queryForm.salesmanId"-->
                            <!--:isNeed="queryForm.salesmanId"-->
                            <!--:dict="'salesmanId'" placeholder="请选择" filterable clearable-->
                            <!--@changet="getSalemanName">-->
                            <!--</f-select>-->
                            <!--</el-form-item>-->
                            <el-form-item label="业务员" v-if="buttonShowList['业务员统计']">
                                <!--<f-select :dict="'salesmanId'" v-model="queryForm.salesmanId"-->
                                <!--:is-need="queryForm.salesmanId" ></f-select>-->
                                <el-select
                                        v-model="salesmanList"
                                        @change='changeSelect'
                                        multiple
                                        collapse-tags
                                        filterable
                                        style="width: 200px"
                                        placeholder="请选择">

                                    <el-checkbox v-model="checked" @change='selectAll'>全选</el-checkbox>
                                    <el-option
                                            v-for="item in this.dictMap.salesmanId"
                                            :key="item.value"
                                            :label="item.text"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md="5" :sm="24">

                            <el-form-item label="时间">
                                <el-date-picker
                                        v-model="queryForm.month"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM"
                                        type="month"
                                        placeholder="选择月">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-button
                                    size="medium"
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button
                                    size="medium"
                                    type="primary"
                                    @click="handleReset"
                                    icon="reload"
                                    style="margin-left: 8px"
                            >重置
                            </el-button
                            >
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </el-form>
        <div class="tab_class_billed">
            <!--- - - - - - - 1 - - - - - - - --->
            <el-tabs class="height_tab_bill" default-active-key="salesOrderDetail" type="card" v-model="activeName"
                     @tab-click="handleClick">

                <!--- - - - - - - 2 - - - - - - - --->
                <el-tab-pane label="汇总页面" name="salesOrderSum" tab="汇总页面" v-if="buttonShowList['汇总页面']">
                    <el-card class="box-card last_card">
                        <div class="f-p-b-15">
                            <el-button
                                    v-if="buttonShowList['汇总数据生成']"
                                    size="medium"
                                    type="primary"
                                    @click="generateSaleOrderSum"
                            >汇总数据生成
                            </el-button>
                        </div>
                        <f-table
                                v-loading="loading"
                                ref="GTable"
                                border
                                size="medium"
                                row-key="id"
                                class="c-th-has-bgcolor"
                                :columns="SaleOrderSumcolumns"
                                :data="SaleOrderDetailtableData"
                                :cell-style="{ 'text-align': 'center' }"
                                tableHeight="69vh"
                                :isRecordTableScroll="true"
                                @selection-change="handleSelectionChange"
                                @sort-change="handleTableChange"
                                @queryChange="handleTableQueryChange">
                            <template v-slot="scope">
                                <template v-if="scope.column.property === 'action'">
                                    <el-button type="text" @click="checkStatus(scope.row,'checkSaleOrderSum')">核对
                                    </el-button>
                                    <el-button type="text" @click="editSaleOrderSum(scope.row)">编辑</el-button>
                                </template>
                                <template v-else-if="scope.column.property === 'checkStatus'">
                                    <el-tag type="danger" v-if="scope.row.checkStatus =='0'">未核对</el-tag>
                                    <el-tag type="success" v-else>已核对</el-tag>
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.customerVisitCoefficient'">
                                    {{scope.row.salesmanConfigDTO.customerVisitCoefficient}}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.customerConversionRate'">
                                    {{scope.row.salesmanConfigDTO.customerConversionRate}}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.taskCompletionRate'">
                                    {{scope.row.salesmanConfigDTO.taskCompletionRate }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.guaranteedProfitTaskCompletionRate'">
                                    {{scope.row.salesmanConfigDTO.guaranteedProfitTaskCompletionRate }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.collectionRate'">
                                    {{scope.row.salesmanConfigDTO.collectionRate }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.contractSigningCompletionRate'">
                                    {{scope.row.salesmanConfigDTO.contractSigningCompletionRate }}
                                </template>

                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.otherCommissions'">
                                    {{scope.row.salesmanConfigDTO.otherCommissions }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.managementBonusCoefficient'">
                                    {{scope.row.salesmanConfigDTO.managementBonusCoefficient }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.deductionsBadDebts'">
                                    {{scope.row.salesmanConfigDTO.deductionsBadDebts }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.moneyLastMonth'">
                                    {{scope.row.salesmanConfigDTO.moneyLastMonth }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.recommendationReward'">
                                    {{scope.row.salesmanConfigDTO.recommendationReward }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.guaranteeTempRetention'">
                                    {{scope.row.salesmanConfigDTO.guaranteeTempRetention }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.overFiveMillion'">
                                    {{scope.row.salesmanConfigDTO.overFiveMillion }}
                                </template>
                                <template
                                        v-else-if="scope.row.salesmanConfigDTO !=undefined && scope.column.property === 'salesmanConfigDTO.remark'">
                                    {{scope.row.salesmanConfigDTO.remark }}
                                </template>


                            </template>
                        </f-table>
                        <el-row class="f-text-right f-p-v-8">
                            <el-pagination
                                    v-bind="SaleOrderDetailipagination"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    @size-change="SaleOrderDetailhandleSizeChange"
                                    @current-change="SaleOrderDetailhandleCurrentChange"
                            >
                            </el-pagination>
                        </el-row>
                    </el-card>
                </el-tab-pane>
                <!--        3              -->
                <el-tab-pane label="业务员系数" tab="业务员系数" name="salesmanConfig" v-if="buttonShowList['汇总页面']">
                    <el-card class="box-card last_card">
                        <div style="margin: 5px 0 15px 0">
                            <el-button type="primary" size="medium" @click="handleAdd">新增</el-button>
                            <el-button type="primary" size="medium" @click="deleteBatch"
                                       :disabled="this.selectedRowIds.length==0?true:false">批量删除
                            </el-button>
                            <!--<el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('销售待审核报表')">导出-->
                            <!--</el-button>-->
                            <el-dropdown
                                    size="medium"
                                    placement="bottom"
                                    trigger="click"
                                    @command="batchOperate"
                                    style="margin-left: 10px"
                            >

                                <el-button type="primary" >
                                    导入<i class="el-icon-arrow-down el-icon--right" ></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-upload

                                            class="upload-demo"
                                            action=""
                                            :http-request="importOperation"
                                            :show-file-list="false"
                                            :before-upload="beforeUpload"
                                            accept=".xls,.xlsx"
                                    >
                                        <el-dropdown-item command.stop="import_excel" style="width: 80px">导入
                                        </el-dropdown-item>

                                    </el-upload>

                                    <el-dropdown-item command="export_excel_template">下载模板
                                    </el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <f-table
                                v-loading="loading"
                                ref="GTable"
                                border
                                size="medium"
                                row-key="id"
                                class="c-th-has-bgcolor"
                                :columns="columns"
                                :data="SaleOrderDetailtableData"
                                :cell-style="{ 'text-align': 'center' }"
                                tableHeight="69vh"
                                :isRecordTableScroll="true"
                                @selection-change="handleSelectionChange"
                                @sort-change="handleTableChange"
                                @queryChange="handleTableQueryChange">
                            <template v-slot="scope">
                                <template v-if="scope.column.property === 'action'">
                                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                                    <el-button type="text" @click="deleteBatch(scope.row)"
                                               v-if="buttonShowList['审核']">刪除
                                    </el-button>
                                </template>
                                <template v-else-if="scope.column.property === 'bussinessType'">
                                    <span>{{getBusType(scope.row.containerType,scope.row.bussinessType)}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'transportStatus'">
                                    <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'orderStatus'">
                                    <span>{{parseDict(scope.row.orderStatus,'orderStatus')}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'podEnd'">
                                    <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'payType'">
                                    <span>{{parseDict(scope.row.payType,'pay_type')}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'ctn'">
                                    <span>{{scope.row.ctn}}/{{scope.row.kg}}/{{scope.row.cmb}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'realCtn'">
                                    <span>{{scope.row.realCtn}}/{{scope.row.realKg}}/{{scope.row.realCmb}}</span>
                                </template>

                                <template v-else-if="scope.column.property === 'customer'">
                                    <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                                       style="color:#57a3f3;">{{scope.row.customer}}</a>
                                </template>
                            </template>
                        </f-table>
                        <el-row class="f-text-right f-p-v-8">
                            <el-pagination
                                    v-bind="SaleOrderDetailipagination"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                            >
                            </el-pagination>
                        </el-row>
                    </el-card>
                </el-tab-pane>
                <!--                <el-tab-pane label="海外仓" key="wms" tab="海外仓" name="3">-->
                <!--                    <billed-wms ref="billedWms" @ok="(e) => handleCheck({},e&&e.isClearSelection)">海外仓</billed-wms>-->
                <!--                </el-tab-pane>-->
            </el-tabs>
        </div>

        <el-dialog width="40%" class="editNodeBox" :title="title" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false" v-if="dialogFormVisible">
            <el-form class="demo-form-inline" :model="form" :rules="saleOrderSumRules" ref="form">
                <el-card class="box-card">
                    <div>
                        <el-form-item label="业务员" :label-width="formLabelWidth" v-if="!title.indexOf('新增')">
                            <f-select
                                    v-model="form.salemanId"
                                    :isNeed="form.salemanId"
                                    :dict="'salesmanId'" placeholder="请选择" filterable clearable
                                    @changet="getSalemanName">
                            </f-select>
                        </el-form-item>
                    </div>
                    <div slot="header" class="clearfix">
                        业务员系数
                    </div>
                    <el-form-item label="客户拜访系数" :label-width="formLabelWidth" prop="customerVisitCoefficient">
                        <el-input v-model="form.customerVisitCoefficient" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="新客户转化率" :label-width="formLabelWidth" prop="customerConversionRate">
                        <el-input v-model="form.customerConversionRate" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="责任任务" :label-width="formLabelWidth" prop="responsibilityTask">
                        <el-input v-model="form.responsibilityTask" autocomplete="off"></el-input>
                    </el-form-item>
                    <!--                    <el-form-item label="保底利润任务完成率" :label-width="formLabelWidth" prop="flowKey">-->
                    <!--                        <el-input v-model="form.guaranteedProfitTaskCompletionRate" autocomplete="off"></el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="回款率" :label-width="formLabelWidth" prop="collectionRate">
                        <el-input v-model="form.collectionRate" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="合同签定完成率" :label-width="formLabelWidth" prop="contractSigningCompletionRate">
                        <el-input v-model="form.contractSigningCompletionRate" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="整柜/非拼提成" :label-width="formLabelWidth" prop="fullContainerCommission">
                        <el-input v-model="form.fullContainerCommission" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="空运/快递提成" :label-width="formLabelWidth" prop="kdCommission">
                        <el-input v-model="form.kdCommission" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="其他提成" :label-width="formLabelWidth" prop="otherCommissions">
                        <el-input v-model="form.otherCommissions" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="管理奖金/系数" :label-width="formLabelWidth" prop="managementBonusCoefficient">
                        <el-input v-model="form.managementBonusCoefficient" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="其他扣款/坏账" :label-width="formLabelWidth" prop="deductionsBadDebts">
                        <el-input v-model="form.deductionsBadDebts" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="上月应发/扣" :label-width="formLabelWidth" prop="moneyLastMonth">
                        <el-input v-model="form.moneyLastMonth" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="推荐奖励" :label-width="formLabelWidth" prop="recommendationReward">
                        <el-input v-model="form.recommendationReward" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="担保暂扣" :label-width="formLabelWidth" prop="guaranteeTempRetention">
                        <el-input v-model="form.guaranteeTempRetention" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="超5W暂时" :label-width="formLabelWidth" prop="overFiveMillion">
                        <el-input v-model="form.overFiveMillion" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                        <el-input v-model="form.remark" autocomplete="off"></el-input>
                    </el-form-item>

                </el-card>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog width="40%" class="editNodeBox" :title="title" :visible.sync="saleOrderSumDialogVisible"
                   :close-on-click-modal="false" v-if="saleOrderSumDialogVisible">
            <el-form class="demo-form-inline" :model="saleOrderSumForm" :rules="saleOrderSumRules"
                     ref="saleOrderSumForm">
                <el-card class="box-card">
                    <div>
                        <el-form-item label="业务员" :label-width="formLabelWidth" v-if="!title.indexOf('新增')">
                            <f-select
                                    v-model="saleOrderSumForm.salemanId"
                                    :isNeed="saleOrderSumForm.salemanId"
                                    :dict="'salesmanId'" placeholder="请选择" filterable clearable
                                    @changet="getSalemanName">
                            </f-select>
                        </el-form-item>
                    </div>
                    <div slot="header" class="clearfix">
                        汇总数据
                    </div>
                    <el-form-item label="业务员" :label-width="formLabelWidth" prop="salesman">
                        <el-input :disabled="true" v-model="saleOrderSumForm.salesman" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="入仓体积汇总" :label-width="formLabelWidth" prop="sumRealCmb">
                        <el-input v-model="saleOrderSumForm.sumRealCmb" autocomplete="off"
                                  @blur="changeRealCmb(saleOrderSumForm.sumRealCmb)"></el-input>
                    </el-form-item>
                    <el-form-item label="提成比例" :label-width="formLabelWidth" prop="proportionalCommission">
                        <el-tooltip
                                effect="dark"
                                content="入仓体积汇总小于200cmb，提成比例3元/cmb;入仓体积汇总大于等于200cmb小于500cmb，提成比例5元/cmb;入仓体积汇总大于等于500cmb小于100cmb，提成比例7元/cmb;入仓体积汇总大于等于1000cmb，提成比例10元/cmb"
                                placement="top"
                        >
                            <el-input v-model="saleOrderSumForm.proportionalCommission" autocomplete="off"
                                      :disabled="true"></el-input>
                        </el-tooltip>
                    </el-form-item>
                    <el-form-item label="方数提成" :label-width="formLabelWidth" prop="materialInWarehouseCommission">
                        <el-tooltip
                                effect="dark"
                                content="方数提成 = 入仓体积汇总 * 比例"
                                placement="top"
                        >
                            <el-input v-model="saleOrderSumForm.materialInWarehouseCommission" autocomplete="off" @change="calculateHyCommissionTotal"
                                      :disabled="true"></el-input>
                        </el-tooltip>
                    </el-form-item>
                    <el-form-item label="利润提成" :label-width="formLabelWidth" prop="materialProfitCommission">
                        <el-input v-model="saleOrderSumForm.materialProfitCommission" autocomplete="off"
                                  @change="calculateHyCommissionTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="责任任务" :label-width="formLabelWidth" prop="responsibilityTask">
                        <el-input v-model="saleOrderSumForm.responsibilityTask" autocomplete="off"
                                  @change="calculateHyCommissionTotal"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="保底利润" :label-width="formLabelWidth" prop="guaranteedProfitTaskCompletionRate">-->
<!--                        <el-input v-model="saleOrderSumForm.guaranteedProfitTaskCompletionRate"-->
<!--                                  autocomplete="off"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="客户拜访系数" :label-width="formLabelWidth" prop="customerVisitCoefficient">
                        <el-input v-model="saleOrderSumForm.customerVisitCoefficient" autocomplete="off" @change="calculateHyCommissionTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="新客户转化率" :label-width="formLabelWidth" prop="customerConversionRate">
                        <el-input v-model="saleOrderSumForm.customerConversionRate" autocomplete="off" @change="calculateHyCommissionTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="回款率" :label-width="formLabelWidth" prop="collectionRate">
                        <el-input v-model="saleOrderSumForm.collectionRate" autocomplete="off" @change="calculateHyCommissionTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="合同签定完成率" :label-width="formLabelWidth" prop="contractSigningCompletionRate">
                        <el-input v-model="saleOrderSumForm.contractSigningCompletionRate" @change="calculateHyCommissionTotal"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="整柜/非拼提成" :label-width="formLabelWidth" prop="fullContainerCommission">
                        <el-input v-model="saleOrderSumForm.fullContainerCommission" @change="calculateHyCommissionTotal"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="海运提成合计" :label-width="formLabelWidth" prop="payMaterialProfitCommission">
                        <el-tooltip
                                effect="dark"
                                content="海运提成合计=（方数提成+利润提成）*（1+系数（客户拜访+新客户转化+回款率+合同签定完成率）+整柜/非拼提成+责任任务"
                                placement="top"
                        >
                            <el-input v-model="saleOrderSumForm.payMaterialProfitCommission" autocomplete="off" @change="totalCommission"
                                      :disabled="true"></el-input>
                        </el-tooltip>
                    </el-form-item>
                    <el-form-item label="空运/快递提成" :label-width="formLabelWidth" prop="payExpressProfitCommission">
                        <el-input v-model="saleOrderSumForm.payExpressProfitCommission" autocomplete="off" @change="totalCommission"></el-input>
                    </el-form-item>
                    <el-form-item label="其它提成（FDA绑定/回收卡）" :label-width="formLabelWidth" prop="otherCommissions">
                        <el-input v-model="saleOrderSumForm.otherCommissions" autocomplete="off" @change="totalCommission"></el-input>
                    </el-form-item>
                    <el-form-item label="合计应发提成" :label-width="formLabelWidth" prop="sumPayProfitCommission">
                        <el-tooltip
                                effect="dark"
                                content="合计应发提成=海运提成合计+空运/快递提成+其它提成"
                                placement="top"
                        >
                            <el-input v-model="saleOrderSumForm.sumPayProfitCommission" autocomplete="off"  :disabled="true" @change="calculatePayTotal"></el-input>

                        </el-tooltip>
                    </el-form-item>
                    <el-form-item label="管理奖金/系数" :label-width="formLabelWidth" prop="managementBonusCoefficient">
                        <el-input v-model="saleOrderSumForm.managementBonusCoefficient" autocomplete="off"  @change="calculatePayTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="其他相关扣款/坏账" :label-width="formLabelWidth" prop="deductionsBadDebts">
                        <el-input v-model="saleOrderSumForm.deductionsBadDebts" autocomplete="off"  @change="calculatePayTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="上月应发/扣" :label-width="formLabelWidth" prop="moneyLastMonth">
                        <el-input v-model="saleOrderSumForm.moneyLastMonth" autocomplete="off"  @change="calculatePayTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="推荐奖励" :label-width="formLabelWidth" prop="recommendationReward">
                        <el-input v-model="saleOrderSumForm.recommendationReward" autocomplete="off"  @change="calculatePayTotal"></el-input>
                    </el-form-item>
                    <el-form-item label="应发总额" :label-width="formLabelWidth" prop="paySumCommission">
                        <el-tooltip
                                effect="dark"
                                content="应发总额=合计应发+管理奖金+其它相关扣款/坏账+上月应发/扣+推荐奖励"
                                placement="top"
                        >
                            <el-input v-model="saleOrderSumForm.paySumCommission" autocomplete="off" @change="calculateRealPayCommission" :disabled="true"></el-input>
                        </el-tooltip>
                    </el-form-item>

                    <el-form-item label="担保暂扣" :label-width="formLabelWidth" prop="guaranteeTempRetention">
                        <el-input v-model="saleOrderSumForm.guaranteeTempRetention" autocomplete="off" @change="calculateRealPayCommission"></el-input>
                    </el-form-item>

                    <el-form-item label="超5W暂时" :label-width="formLabelWidth" prop="overFiveMillion">
                        <el-input v-model="saleOrderSumForm.overFiveMillion" autocomplete="off" @change="calculateRealPayCommission"></el-input>
                    </el-form-item>
                    <el-form-item label="实发提成" :label-width="formLabelWidth" prop="realPayCommission">
                        <el-tooltip
                                effect="dark"
                                content="实发提成 = 应发总额 + 担保暂扣 + 超5W暂扣"
                                placement="top"
                        >
                            <el-input v-model="saleOrderSumForm.realPayCommission" autocomplete="off" :disabled="true"></el-input>
                        </el-tooltip>
                    </el-form-item>

                    <el-form-item label="备注1" :label-width="formLabelWidth" prop="remark">
                        <el-input v-model="saleOrderSumForm.remark" autocomplete="off"></el-input>
                    </el-form-item>
                </el-card>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="saleOrderSumDialogVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveSalesOrderSum">确 定</el-button>
            </div>
        </el-dialog>

        <edit-notes-dialog :visible="notesVisible" :selected-order-nos="selectedOrderNos"
                           @ok="closeNotes"></edit-notes-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins";
    import {getAction, postAction, putAction} from '@/api/manage';
    import {setUpRouteUtil} from '@/utils/util';
    import {DeleteActionByUrlParam, GetActionByUrlParam} from "@/api/manage";
    import editNotesDialog from "@/views/dashboard/components/editNotesDialog";
    import {caculateSishewuru} from "@/utils/caculate";
    import {validateCmb,validateSaleSum} from "../../utils/validator";


    export default {

        name: "Potential",
        mixins: [QueryMixins],
        components: {
            editNotesDialog
        },
        data() {
            return {
                queryForm: {orderCreateTimes: []},
                performanceCheckStatusList: [
                    {
                        label: '未核对',
                        value: "2"
                    },
                    {
                        label: '未通过',
                        value: "0"
                    },
                    {
                        label: '已通过',
                        value: "1"
                    },
                ],
                containerTypeList: [
                    {
                        label: '自拼',
                        value: "0"
                    },
                    {
                        label: '整柜',
                        value: "1"
                    },
                    {
                        label: '非拼',
                        value: "2"
                    }
                ],
                checked: false,
                salesmanList: [],
                // queryForm:{agentPayType:'0'},
                //审核弹窗标识
                checkDialogFlag: false,
                defaultValue: 0,
                //详细汇总分页
                SaleOrderDetailipagination: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [50, 100, 200, 300, 400, 500, 1000, 2000],
                    total: 0
                },
                SaleOrderDetailtableData: [],
                //tabs起始标签
                activeName: 'salesOrderSum',
                title: '',
                dictMap: {},
                dictlist: [],
                dictCodes: ['commission', 'os_rec_status', 'salesmanId'],
                ids: [],
                row: {},
                form: {},
                saleOrderSumForm: {},
                saleOrderSumRules:{
                    sumRealCmb:[
                        {required: true, message: "请输入入仓体积汇总", trigger: "change"},
                        {validator: validateCmb, trigger: "change"},
                    ],
                    materialProfitCommission:[
                        {required: true, message: "请输入利润提成", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    responsibilityTask:[
                        {required: true, message: "请输入责任任务", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    customerVisitCoefficient:[
                        {required: true, message: "请输入客户拜访系数", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    customerConversionRate:[
                        {required: true, message: "请输入新客户转化率", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    collectionRate:[
                        {required: true, message: "请输入回款率", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    contractSigningCompletionRate:[
                        {required: true, message: "请输入合同签定完成率", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    fullContainerCommission:[
                        {required: true, message: "请输入整柜/非拼提成", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    payExpressProfitCommission:[
                        {required: true, message: "请输入空运/快递提成", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    kdCommission:[
                        {required: true, message: "请输入空运/快递提成", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    otherCommissions:[
                        {required: true, message: "请输入其它提成（FDA绑定/回收卡）", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    managementBonusCoefficient:[
                        {required: true, message: "请输入管理奖金/系数", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    deductionsBadDebts:[
                        {required: true, message: "请输入其他相关扣款/坏账", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    moneyLastMonth:[
                        {required: true, message: "请输入上月应发/扣", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    recommendationReward:[
                        {required: true, message: "请输入推荐奖励", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    guaranteeTempRetention:[
                        {required: true, message: "请输入担保暂扣", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                    overFiveMillion:[
                        {required: true, message: "请输入超5W暂时", trigger: "change"},
                        {validator: validateSaleSum, trigger: "change"},
                    ],
                },
                rules: {
                    customerVisitCoefficient: [
                        {required: true, message: "请输入客户拜访系数", trigger: "blur"},
                        // { min: 1, max: 20, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    customerConversionRate: [
                        {required: true, message: "请输入新客户转化率", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    taskCompletionRate: [
                        {required: true, message: "请输入任务完成率", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    guaranteedProfitTaskCompletionRate: [
                        {required: true, message: "请输入保底利润任务完成率", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    collectionRate: [
                        {required: true, message: "请输入回款率", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    contractSigningCompletionRate: [
                        {required: true, message: "请输入合同签定完成率", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    otherCommissions: [
                        {required: true, message: "请输入其他提成", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    managementBonusCoefficient: [
                        {required: true, message: "请输入管理奖金/系数", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    deductionsBadDebts: [
                        {required: true, message: "请输入其他扣款/坏账", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ], moneyLastMonth: [
                        {required: true, message: "请输入上月应发/扣", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ], recommendationReward: [
                        {required: true, message: "请输入推荐奖励", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    guaranteeTempRetention: [
                        {required: true, message: "请输入担保暂扣", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                    overFiveMillion: [
                        {required: true, message: "请输入超5W暂时", trigger: "blur"},
                        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                    ],
                },
                formLabelWidth: "110px",
                //控制弹框显示（业务员配置系数）
                dialogFormVisible: false,
                saleOrderSumDialogVisible: false,
                SaleOrderSumcolumns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "salesman",
                        label: "业务员",
                        minWidth: 100,
                    },
                    {
                        prop: "month",
                        label: "汇总月份",
                        minWidth: 100,
                    },
                    {
                        prop: "sumRealCmb",
                        label: "入仓体积汇总",
                        minWidth: 100,
                    },
                    {
                        prop: "proportionalCommission",
                        label: "提成比例",
                        minWidth: 100,
                    },
                    {
                        prop: "materialInWarehouseCommission",
                        label: "方数提成",
                        minWidth: 100,
                    },
                    {
                        prop: "materialProfitCommission",
                        label: "利润提成",
                    },
                    {
                        prop: "responsibilityTask",
                        label: "责任任务",
                    },
                    {
                        prop: "customerVisitCoefficient",
                        label: "客户拜访系数",
                        minWidth: 100,
                    },
                    {
                        prop: "customerConversionRate",
                        label: "新客户转化率",
                        minWidth: 100,
                    },
                    {
                        prop: "collectionRate",
                        label: "回款率",
                        minWidth: 150,
                    },
                    {
                        prop: "contractSigningCompletionRate",
                        label: "合同签定完成率",
                        minWidth: 100,
                    },
                    {
                        prop: "fullContainerCommission",
                        label: "整柜/非拼提成",
                        minWidth: 100,
                    },
                    {
                        prop: "payMaterialProfitCommission",
                        label: "海运提成合计",
                        minWidth: 100,
                    },
                    {
                        prop: "payExpressProfitCommission",
                        label: "空运/快递提成",
                        minWidth: 100,
                    },
                    {
                        prop: "otherCommissions",
                        label: "其它提成（FDA绑定/回收卡）",
                        minWidth: 100,
                    },
                    {
                        prop: "sumPayProfitCommission",
                        label: "合计应发提成",
                        minWidth: 100,
                    },
                    {
                        prop: "managementBonusCoefficient",
                        label: "管理奖金/系数",
                        minWidth: 200,
                    },
                    {
                        prop: "deductionsBadDebts",
                        label: "其他相关扣款/坏账",
                        minWidth: 200,
                    },
                    {
                        prop: "moneyLastMonth",
                        label: "上月应发/扣",
                        minWidth: 200,
                    },
                    {
                        prop: "recommendationReward",
                        label: "推荐奖励",
                        minWidth: 200,
                    },
                    {
                        prop: "paySumCommission",
                        label: "应发总额",
                        minWidth: 200,
                    },
                    {
                        prop: "guaranteeTempRetention",
                        label: "担保暂扣",
                        minWidth: 200,
                    },
                    {
                        prop: "overFiveMillion",
                        label: "超5W暂时",
                        minWidth: 200,
                    },
                    {
                        prop: "realPayCommission",
                        label: "实发提成",
                        minWidth: 200,
                    },
                    {
                        prop: "remark",
                        label: "备注1",
                        minWidth: 200,
                    },
                    {
                        prop: "checkStatus",
                        label: "核对状态",
                        minWidth: 100,
                    },

                ],
                SaleOrderDetailcolumns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        // fixed: 'left'
                    },
                    {
                        prop: "performanceCheckStatus",
                        label: "核对状态",
                        minWidth: 100,
                        // fixed: 'left'

                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                        // fixed: 'left'
                    },
                    // {
                    //     prop: "operatorId",
                    //     label: "操作员编号",
                    //     minWidth: 150,
                    // },
                    {
                        prop: "operator",
                        label: "操作员",
                        minWidth: 100,
                    },
                    // {
                    //     prop: "docId",
                    //     label: "客服编号",
                    //     minWidth: 80,
                    // },
                    {
                        prop: "doc",
                        label: "客服",
                        minWidth: 100,
                    },
                    // {
                    //     prop: "salesmanId",
                    //     label: "业务员编号",
                    //     minWidth: 100,
                    // },
                    {
                        prop: "salesman",
                        label: "业务员",
                        minWidth: 100,
                    },
                    // {
                    //     prop: "cusId",
                    //     label: "客户编号",
                    //     minWidth: 100,
                    // },
                    {
                        prop: "customer",
                        label: "客户",
                        minWidth: 100,
                    },
                    {
                        prop: "isPerform",
                        label: "核对状态",
                        minWidth: 80,
                    },
                    {
                        prop: "salesPerformTime",
                        label: "核对状态时间",
                        minWidth: 150,
                    },
                    {
                        prop: "billman",
                        label: "收款人",
                    },
                    {
                        prop: "recStatus",
                        label: "订单状态",
                    },
                    {
                        prop: "realCmb",
                        label: "提成方数",
                        minWidth: 150,
                    },
                    // {
                    //     prop: "realKg",
                    //     label: "收费重量",
                    //     minWidth: 100,
                    // },
                    {
                        prop: "deliveryTime",
                        label: "派送时间",
                        minWidth: 100,
                    },
                    {
                        prop: "lastSettleTime",
                        label: "销账时间",
                        minWidth: 100,
                    },
                    // {
                    //     prop: "lockOrderTime",
                    //     label: "锁单时间",
                    //     minWidth: 100,
                    // },
                    {
                        prop: "commission",
                        label: "提成性质",
                        minWidth: 100,
                    },
                    {
                        prop: "paymentCycle",
                        label: "回款周期",
                        minWidth: 100,
                    },
                    {
                        prop: "receivables",
                        label: "应收",
                        minWidth: 200,
                    },
                    {
                        prop: "costBilling",
                        label: "成本账单",
                        minWidth: 200,
                    },
                    {
                        prop: "profit",
                        label: "利润",
                        minWidth: 200,
                    },
                    {
                        prop: "profitRate",
                        label: "利润率",
                        minWidth: 200,
                    },
                    {
                        prop: "normalProportionalCommission",
                        label: "正常提成比例",
                        minWidth: 200,
                    },
                    {
                        prop: "freightCommissions",
                        label: "利润提成",
                        minWidth: 200,
                    },
                    {
                        prop: "profitAfterVerification",
                        label: "核对后提成",
                        minWidth: 200,
                    },


                    {
                        prop: "commissionStatus",
                        label: "提成发放状态",
                        minWidth: 200,
                    },
                    // {
                    //     prop: "expressCommission",
                    //     label: "空运利润",
                    //     minWidth: 200,
                    // },
                    // {
                    //     prop: "performanceCheckTime",
                    //     label: "核对月份",
                    //     minWidth: 200,
                    // },
                    {
                        prop: "salesmanCommissionTime",
                        label: "提成发放月份",
                        minWidth: 200,
                    },
                    {
                        prop: "handleType",
                        label: "亏本订单处理类型",
                        minWidth: 200,
                    },
                    {
                        prop: "auditReason",
                        label: "亏本订单审核原因",
                        minWidth: 200,
                    },
                    {
                        prop: "notes",
                        label: "备注",
                        minWidth: 200,
                    },
                ],
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    // {
                    //     prop: "salemanId",
                    //     label: "业务员编号",
                    //     minWidth: 150,
                    // },
                    {
                        prop: "salemanName",
                        label: "业务员",
                        minWidth: 100,
                    },
                    {
                        prop: "createTime",
                        label: "月份",
                        minWidth: 100,
                    },
                    {
                        prop: "customerVisitCoefficient",
                        label: "客户拜访系数",
                        minWidth: 80,
                    },
                    {
                        prop: "customerConversionRate",
                        label: "新客户转化率",
                        minWidth: 100,
                    },
                    {
                        prop: "responsibilityTask",
                        label: "责任任务",
                        minWidth: 100,
                    },
                    {
                        prop: "collectionRate",
                        label: "回款率",
                        minWidth: 100,
                    },
                    {
                        prop: "contractSigningCompletionRate",
                        label: "合同签定完成率",
                        minWidth: 100,
                    },
                    {
                        prop: "fullContainerCommission",
                        label: "整柜/非拼提成",
                        minWidth: 100,
                    },
                    {
                        prop: "kdCommission",
                        label: "空运快递提成",
                        minWidth: 100,
                    },
                    {
                        prop: "otherCommissions",
                        label: "其他提成",
                    },
                    {
                        prop: "managementBonusCoefficient",
                        label: "管理奖金/系数",
                    },
                    {
                        prop: "deductionsBadDebts",
                        label: "其他扣款/坏账",
                        minWidth: 100,
                    },
                    {
                        prop: "moneyLastMonth",
                        label: "上月应发/扣",
                        minWidth: 100,
                    },
                    {
                        prop: "recommendationReward",
                        label: "推荐奖励",
                        minWidth: 100,
                    },
                    {
                        prop: "guaranteeTempRetention",
                        label: "担保暂扣",
                        minWidth: 100,
                    },
                    {
                        prop: "overFiveMillion",
                        label: "超5W暂时",
                        minWidth: 100,
                    },
                    {
                        prop: "remark",
                        label: "备注",
                        minWidth: 200,
                    },
                ],
                renderFlag: true,
                url: {
                    commonList: "order/sale/SalesOrderSum/getSaleOrderSumList",
                    saleOrderList: "/order/sale/SalesOrderDetails/list",
                    salesmanConfigList: "/order/sale/salesmanConfig/list",
                    salesOrderSumList: "/order/sale/SalesOrderSum/getSaleOrderSumList",
                    info: "/order/sale/salesmanConfig",
                    deleteBatch: "/order/sale/salesmanConfig/delete/",
                    downloadSalesmanConfigTemplate: '/file/read/excel/salemanConfigExcel',
                    // exportXlsUrl: "/file/export/excel/SalesExcel/downloadSalemanConfigListExportXls",
                    importSaleConfig: "/file/read/excel/importSalesmanConfigImportXls",
                    dict: '/sys/dict/getDictItems/',
                    checkSaleOrderDetailInfo: "/order/sale/SalesDetailsCheck/checkStatus",
                    editSaleOrderSum: "/order/sale/SalesOrderSum",
                    generateOrderSum: "/order/sale/SalesOrderSum/generateSaleOrderSum",
                    exportXlsUrl: "order/sale/SalesOrderDetails/exportSalesDetailTXls",
                    updateTtOrderExtProfitAfterVerification: "order/sale/SalesOrderDetails/updateTtOrderExtProfitAfterVerification",

                },
                selectedRowIds: [],
                notesVisible: false,
                selectedOrderNos: [],
            };
        },
        methods: {
            calculateRealPayCommission(){
                // 实发提成=应发+担保暂扣+超5W暂扣
                let paySumCommission = Number(this.saleOrderSumForm.paySumCommission)
                if (isNaN(paySumCommission)) {
                    this.$message.error("请确保应发总额格式正确！")
                    return
                }
                let guaranteeTempRetention = Number(this.saleOrderSumForm.guaranteeTempRetention)
                if (isNaN(guaranteeTempRetention)) {
                    this.$message.error("请确保担保暂扣格式正确！")
                    return
                }
                let overFiveMillion = Number(this.saleOrderSumForm.overFiveMillion)
                if (isNaN(overFiveMillion)) {
                    this.$message.error("请确保超5W暂时格式正确！")
                    return
                }
                let realPayCommission = paySumCommission + guaranteeTempRetention + overFiveMillion
                this.$set(this.saleOrderSumForm,"realPayCommission",realPayCommission.toFixed(2))
            },
            calculatePayTotal(){
                // 应发总额=合计应发+管理奖金+其它相关扣款/坏账+上月应发/扣+推荐奖励
                let sumPayProfitCommission = Number(this.saleOrderSumForm.sumPayProfitCommission)
                if (isNaN(sumPayProfitCommission)) {
                    this.$message.error("请确保合计应发提成格式正确！")
                    return
                }
                let managementBonusCoefficient = Number(this.saleOrderSumForm.managementBonusCoefficient)
                if (isNaN(managementBonusCoefficient)) {
                    this.$message.error("请确保管理奖金格式正确！")
                    return
                }
                let deductionsBadDebts = Number(this.saleOrderSumForm.deductionsBadDebts)
                if (isNaN(deductionsBadDebts)) {
                    this.$message.error("请确保其他扣款/坏账格式正确！")
                    return
                }
                let moneyLastMonth = Number(this.saleOrderSumForm.moneyLastMonth)
                if (isNaN(moneyLastMonth)) {
                    this.$message.error("请确保上月应发/扣格式正确！")
                    return
                }
                let recommendationReward = Number(this.saleOrderSumForm.recommendationReward)
                if (isNaN(recommendationReward)) {
                    this.$message.error("请确保推荐奖励格式正确！")
                    return
                }
                let paySumCommission = sumPayProfitCommission + managementBonusCoefficient + deductionsBadDebts + moneyLastMonth + recommendationReward
                this.$set(this.saleOrderSumForm,"paySumCommission",paySumCommission.toFixed(2))
                this.calculateRealPayCommission()
            },
            totalCommission(){
                debugger
                // 合计应发提成=海运提成合计+空运/快递提成+其它提成
                let payMaterialProfitCommission = Number(this.saleOrderSumForm.payMaterialProfitCommission)
                if (isNaN(payMaterialProfitCommission)) {
                    this.$message.error("请确保海运提成合计格式正确！")
                    return
                }
                let payExpressProfitCommission = Number(this.saleOrderSumForm.payExpressProfitCommission)
                if (isNaN(payExpressProfitCommission)) {
                    this.$message.error("请确保空运/快递提成格式正确！")
                    return
                }
                let otherCommissions = Number(this.saleOrderSumForm.otherCommissions)
                if (isNaN(otherCommissions)) {
                    this.$message.error("请确保其他提成格式正确！")
                    return
                }
                let sumPayProfitCommission = payMaterialProfitCommission + payExpressProfitCommission + otherCommissions
                this.$set(this.saleOrderSumForm,"sumPayProfitCommission",sumPayProfitCommission.toFixed(2))
                this.calculatePayTotal()
            },
            calculateHyCommissionTotal(e) {
                debugger
                let materialInWarehouseCommission = Number(this.saleOrderSumForm.materialInWarehouseCommission)
                if (isNaN(materialInWarehouseCommission)) {
                    this.$message.error("请确保方数提成格式正确！")
                    return
                }
                let materialProfitCommission = Number(this.saleOrderSumForm.materialProfitCommission)
                if (isNaN(materialProfitCommission)) {
                    this.$message.error("请确保利润提成格式正确！")
                    return
                }
                let customerVisitCoefficient = Number(this.saleOrderSumForm.customerVisitCoefficient)
                if (isNaN(customerVisitCoefficient)) {
                    this.$message.error("请确保客户拜访格式正确！")
                    return
                }
                let customerConversionRate = Number(this.saleOrderSumForm.customerConversionRate)
                if (isNaN(customerConversionRate)) {
                    this.$message.error("请确保新客户转化格式正确！")
                    return
                }
                let collectionRate = Number(this.saleOrderSumForm.collectionRate)
                if (isNaN(collectionRate)) {
                    this.$message.error("请确保回款率格式正确！")
                    return
                }
                let contractSigningCompletionRate = Number(this.saleOrderSumForm.contractSigningCompletionRate)
                if (isNaN(contractSigningCompletionRate)) {
                    this.$message.error("请确保合同签定完成率格式正确！")
                    return
                }
                let fullContainerCommission = Number(this.saleOrderSumForm.fullContainerCommission)
                if (isNaN(fullContainerCommission)) {
                    this.$message.error("请确整柜/非拼提成格式正确！")
                    return
                }
                let responsibilityTask = Number(this.saleOrderSumForm.responsibilityTask)
                if (isNaN(responsibilityTask)) {
                    this.$message.error("请确保责任任务 格式正确！")
                    return
                }
                //海运提成合计=（方数提成+利润提成）*（1+系数（客户拜访+新客户转化+回款率+合同签定完成率）+整柜/非拼提成+责任任务
                let profit = materialInWarehouseCommission + materialProfitCommission;
                let changeProp = 1 + customerVisitCoefficient + customerConversionRate + collectionRate + contractSigningCompletionRate
                let payMaterialProfitCommission = caculateSishewuru(profit, changeProp) + fullContainerCommission + responsibilityTask
                this.$set(this.saleOrderSumForm, "payMaterialProfitCommission", payMaterialProfitCommission.toFixed(2))
                this.totalCommission()
            },
            changeRealCmb(e) {
                let totalCmb = Number(e)

                if (isNaN(totalCmb)) {
                    this.$message.error("请确保入仓体积汇总格式正确！")
                    return
                }
                let proportionalCommission = 0
                if (totalCmb < 200) {
                    proportionalCommission = 3
                } else if (200 <= totalCmb && totalCmb < 500) {
                    proportionalCommission = 5
                } else if (500 <= totalCmb && totalCmb < 1000) {
                    proportionalCommission = 7
                } else if (totalCmb >= 1000) {
                    proportionalCommission = 10
                }

                this.$set(this.saleOrderSumForm, "proportionalCommission", proportionalCommission)
                this.$set(this.saleOrderSumForm, "materialInWarehouseCommission", caculateSishewuru(totalCmb, proportionalCommission).toFixed(2))
                this.calculateHyCommissionTotal()
            },
            closeNotes() {
                this.notesVisible = false;
                this.getSalesOrderList();
            },
            openNotes() {
                this.selectedOrderNos = this.selectionRows.map(s => s.orderNo);
                this.notesVisible = true;
            },
            //批量核对
            checkBatch() {
                let form = {}
                const h = this.$createElement;
                this.url.checkSaleOrderDetailInfo = '/order/sale/SalesDetailsCheck/checkOrderDetailStatus'
                form.orderNo = this.selectedRowOrderNos.join(",");
                form.hasAuthReCheck = this.buttonShowList['核对过是否继续核对'] ? '1' : '0'
                this.$msgbox({
                    title: '绩效汇总核对',
                    message: h('p', null, [
                        h('i', {style: 'color: #00a0e9'}, '请确认审核该汇总记录')
                    ]),
                    showCancelButton: true,
                    distinguishCancelAndClose: true,
                    confirmButtonText: '通过',
                    cancelButtonLoading: false,
                    confirmButtonLoading: false,
                    cancelButtonText: '不通过',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            form.checkStatus = '1'
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '核对中...';
                            postAction(this.url.checkSaleOrderDetailInfo, form).then((res) => {
                                if (res.success) {
                                    this.$message.success("核对成功")
                                    this.getSalesOrderList();
                                    done()
                                }
                            }).catch(() => {
                                done()
                            })
                        } else if (action == 'cancel') {
                            form.checkStatus = '0'
                            instance.cancelButtonLoading = true;
                            instance.cancelButtonText = '核对中...';
                            postAction(this.url.checkSaleOrderDetailInfo, form).then((res) => {
                                if (res.success) {
                                    this.$message.success("核对成功")
                                    this.getSalesOrderList();
                                    done()
                                }
                            }).catch(() => {
                                done()
                            })
                        } else {
                            done()
                        }
                    }
                })
            },
            saveProfitAfterVerificatoin() {
                if (!this.SaleOrderDetailtableData || this.SaleOrderDetailtableData.length == 0) {
                    this.$message.warning("列表不能为空");
                    return false;
                }
                let nullNos = [];
                let errShuziNos = [];
                let errZhengshuNos = [];
                let errXiaoshuNos = [];
                let list = this.SaleOrderDetailtableData.map(s => {
                    let orderNo = s.orderNo;
                    if (!s.profitAfterVerification) {
                        nullNos.push(orderNo);
                    } else {
                        let value = s.profitAfterVerification.toString();
                        if (value.indexOf(".") !== -1) {
                            let sp = value.split(".");
                            if (sp && sp.length >= 3) {
                                errShuziNos.push(orderNo);
                            } else if (sp[0].length > 10) {
                                errZhengshuNos.push(orderNo);
                            } else if (sp[1].length > 3) {
                                errXiaoshuNos.push(orderNo);
                            }
                        } else if (value.length > 10) {
                            errZhengshuNos.push(orderNo);
                        }
                    }
                    return {
                        id: s.id,
                        profitAfterVerification: s.profitAfterVerification,
                        orderNo: s.orderNo,
                        freightCommissions: s.freightCommissions
                    }
                });
                // 拼接 报错提示
                let errStr = '';
                if (nullNos && nullNos.length > 0) {
                    errStr += nullNos.join("，") + "：核对后提成不能为空\n";
                }
                if (errShuziNos && errShuziNos.length > 0) {
                    errStr += errShuziNos.join("，") + "：核对后提成不是正确的数字\n";
                }
                if (errZhengshuNos && errZhengshuNos.length > 0) {
                    errStr += errZhengshuNos.join("，") + "：核对后提成最后十位整数\n";
                }
                if (errXiaoshuNos && errXiaoshuNos.length > 0) {
                    errStr += errXiaoshuNos.join("，") + "：核对后提成最多3位小数\n";
                }
                if (errStr && errStr.length > 0) {
                    this.$message.warning("以下数据核对后提成不对：" + errStr);
                    return false;
                }
                postAction(this.url.updateTtOrderExtProfitAfterVerification, list)
                    .then(res => {
                        this.$message.success(res.message);
                        this.getSalesOrderList();
                    })
            },
            formatYearDayDate(date) {
                if (date) {
                    let arr = date.split('-');
                    let dateStr = arr[0] + '-' + arr[1]
                    return dateStr
                }
            },
            //业务员绩效汇总界面数据生成
            generateSaleOrderSum() {
                postAction(this.url.generateOrderSum, this.queryForm).then(res => {
                    if (res.success) {
                        this.$message.success("数据生成成功")
                        this.getSalesOrderList();
                    }
                })
            },
            changeSelect(val) {
                if (val.length === this.dictMap.salesmanId.length) {
                    this.checked = true
                } else {
                    this.checked = false
                }
            },
            selectAll() {
                this.salesmanList = []
                if (this.checked) {
                    for (const key in this.dictMap.salesmanId) {
                        this.salesmanList.push(this.dictMap.salesmanId[key].value)

                    }
                } else {
                    this.salesmanList = []
                }
            },
            //编辑汇总数据
            editSaleOrderSum(row) {
                this.saleOrderSumForm = JSON.parse(JSON.stringify(row));
                this.title = '编辑业务员汇总数据'
                this.saleOrderSumDialogVisible = true;

            },
            //审核销售订单详情
            checkStatus(row, type) {
                const h = this.$createElement;
                let that = this
                let form = {}
                if (type === 'checkSaleOrderDetail') {
                    this.url.checkSaleOrderDetailInfo = '/order/sale/SalesDetailsCheck/checkOrderDetailStatus'
                    form.orderNo = row.orderNo
                } else {
                    form.id = row.id
                    this.url.checkSaleOrderDetailInfo = '/order/sale/SalesOrderSum/checkStatus'
                }
                this.$msgbox({
                    title: '汇总记录核对',
                    message: h('p', null, [
                        h('i', {style: 'color: #00a0e9'}, '请确认审核该汇总记录')
                    ]),
                    showCancelButton: true,
                    distinguishCancelAndClose: true,
                    confirmButtonText: '通过',
                    cancelButtonLoading: false,
                    confirmButtonLoading: false,
                    cancelButtonText: '不通过',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            form.checkStatus = '1'
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '核对中...';
                            postAction(this.url.checkSaleOrderDetailInfo, form).then((res) => {
                                if (res.success) {
                                    this.$message.success("核对成功")
                                    this.getSalesOrderList();
                                    done()
                                }
                            }).catch(() => {
                                done()
                            })
                        } else if (action == 'cancel') {
                            form.checkStatus = '0'
                            instance.cancelButtonLoading = true;
                            instance.cancelButtonText = '核对中...';
                            postAction(this.url.checkSaleOrderDetailInfo, form).then((res) => {
                                if (res.success) {
                                    this.$message.success("核对成功")
                                    this.getSalesOrderList();
                                    done()
                                }
                            }).catch(() => {
                                done()
                            })
                        } else {
                            done()
                        }
                    }
                })
            },
            SaleOrderDetailhandleSizeChange(val) {
                this.SaleOrderDetailipagination.currentPage = 1
                this.SaleOrderDetailipagination.pageSize = val
                this.getSalesOrderList()
            },
            SaleOrderDetailhandleCurrentChange(val) {
                this.SaleOrderDetailipagination.currentPage = val
                this.getSalesOrderList()
            },
            handleSearch() {
                this.SaleOrderDetailipagination.currentPage = 1
                this.getSalesOrderList()
            },
            //获取销售订单详细列表
            getSalesOrderList() {
                this.loading = true
                this.SaleOrderDetailcolumns.forEach(item => {
                    if (item.prop === 'costBilling') {
                        item.label = '成本账单'
                        if (!this.queryForm.containerType) {
                            item.label = '成本账单/应付'
                        }
                        if (this.queryForm.containerType === '1' || this.queryForm.containerType === '2') {
                            item.label = '应付'
                        }
                    }
                })
                this.renderFlag = false;
                this.$nextTick(() => {
                    this.renderFlag = true;
                });
                this.queryForm.salesmanIds = this.salesmanList
                let form = {...this.queryForm, ...this.filterForm, ...this.initData}
                // for (let item in form) {
                //     if (typeof form[item] === 'string') {
                //         form[item] = form[item].trim()
                //         if (form[item] === '') {
                //             form[item] = null
                //         }
                //     }
                // }
                //清空字符串的前后空格
                this.trimParamsStr(form);
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else if (this.enabledCreatetime) {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                    //排序
                    //提货中查询
                    if (this.queryForm.column === 'sort') {
                        this.$set(params, 'column', 'sort')
                    }
                }
                if (this.SaleOrderDetailipagination) {
                    params.pageNo = this.SaleOrderDetailipagination.currentPage
                    params.pageSize = this.SaleOrderDetailipagination.pageSize
                    params.current = this.SaleOrderDetailipagination.currentPage

                }
                // let method = "GET";
                // if (this.isUsePost) {
                //     method = "POST";
                // }
                postAction(this.url.commonList, params).then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        if (res.result) {
                            const {records, total} = res.result
                            this.SaleOrderDetailtableData = records || res.result;
                            this.SaleOrderDetailipagination && (this.SaleOrderDetailipagination.total = total || res.result.length)
                        } else {
                            this.SaleOrderDetailtableData = [];
                            this.SaleOrderDetailipagination = {
                                currentPage: 1,
                                pageSize: 50,
                                pageSizes: [50, 100, 200, 300, 400, 500],
                                total: 0
                            };
                        }
                    }
                    this.loading = false;
                }).catch(err => {
                    this.SaleOrderDetailtableData = [];
                    this.SaleOrderDetailipagination = {
                        currentPage: 1,
                        pageSize: 50,
                        pageSizes: [50, 100, 200, 300, 400, 500],
                        total: 0
                    };
                    this.loading = false;
                    // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                })
            },
            handleQuery() {
                this.SaleOrderDetailipagination.currentPage = 1
                this.getSalesOrderList()
            },
            //tabs标签点击
            handleClick(tab, event) {
                this.activeName = tab.name
                this.queryForm = {}
                switch (this.activeName) {
                    case "salesOrderDetail":
                        this.url.commonList = this.url.saleOrderList
                        this.queryForm.containerType = '0'
                        break
                    case "salesOrderSum":
                        this.url.commonList = this.url.salesOrderSumList
                        break
                    case "salesmanConfig":
                        this.url.commonList = this.url.salesmanConfigList
                }
                this.getSalesOrderList()
            },
            uploadFile() {
                // this.$message.info("功能尚未开发");
                // return;
                const _this = this;
                //   const fileType = [
                //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                //     "application/vnd.ms-excel"
                //   ];
                const fileType = ['xls', 'xlsx', 'et']
                const inputFile = document.createElement("input")
                inputFile.type = "file"
                inputFile.style.display = "none"
                document.body.appendChild(inputFile)
                inputFile.click()
                inputFile.addEventListener("change", function () {
                    const file = inputFile.files[0];
                    var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
                    if (!fileType.includes(testmsg)) {
                        _this.$message.warning("上传的文件格式只能是,xls,xlsx,et");
                        document.body.removeChild(inputFile);
                        return false;
                    }
                    const formData = new FormData();
                    formData.append("file", file)
                })
                postAction(this.url.importSaleConfig, formData).then((res) => {
                    if (res.success) {
                        this.sailForm.fileId = res.result.id
                        this.fileList.push(res.result)
                        console.log('文件上传', this.fileList)
                    }
                })
            },
            //字典取值
            parseDictValue(val, code) {
                //如果val带有,证明是多个
                // console.log('要渲染的value',val);
                if (val && val.length > 0 && Array.isArray(val)) {
                    let ss = '';
                    for (var i = 0; i < val.length; i++) {
                        // console.log('部门id',list[i]);
                        if (this.dictMap[code] && this.dictMap[code][val[i]]) {
                            ss += this.dictMap[code][val[i]]['text'] + ",";
                        } else {
                            ss += "";
                        }
                    }
                    return ss;
                } else if (val && val.length === 0 && Array.isArray(val)) {
                    return "";
                }
                if (val && val.indexOf(',') !== -1) {
                    let ss = '';
                    let list = val.split(',');
                    for (var i = 0; i < list.length; i++) {
                        // console.log('部门id',list[i]);
                        if (this.dictMap[code] && this.dictMap[code][list[i]]) {
                            ss += this.dictMap[code][list[i]]['text'] + ",";
                        } else {
                            ss += "";
                        }
                    }
                    ss = ss.substring(0, ss.lastIndexOf(','));
                    if (ss.length > 1) {
                        return ss;
                    }
                    return val;
                } else if (val) {
                    if (this.dictMap[code] && this.dictMap[code][val]) {
                        return this.dictMap[code][val]['text'];
                    } else {
                        return val;
                    }

                } else {
                    return val;
                }

            },
            //导入操作绑定
            importOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.importSaleConfig, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            batchOperate(command) {
                switch (command) {
                    case "import_excel":
                        this.uploadFile();
                        break;
                    case "export_excel_template":
                        //下载导入模板
                        this.downloadModel(this.url.downloadSalesmanConfigTemplate, '业务员系数导入模板');
                        break;
                }
            },
            getSalemanName(val) {
                //下拉框回调填充form 的salemanName字段
                this.form.salemanName = val
            },
            handleAdd() {
                this.form = {}
                this.dialogFormVisible = true;
                this.title = "新增业务员系数";
            },
            handleSelectionChange(selection) { // 多选
                this.selectedRowIds = []
                selection.forEach(item => {
                    this.selectedRowIds.push(item.id)
                    this.selectedRowOrderNos.push(item.orderNo)
                })
            },
            getBusType(containerType, type) {
                let str = "";
                if (!type) {
                    return "";
                }
                if (containerType == "1") {
                    //fcl

                    if (type.indexOf("0") !== -1) {
                        str += "订舱,";
                    }
                    if (type.indexOf("1") !== -1) {
                        str += "拖车,";
                    }
                    if (type.indexOf("2") !== -1) {
                        str += "报关,";
                    }
                    if (type.indexOf("3") !== -1) {
                        str += "清关,";
                    }
                    if (type.indexOf("4") !== -1) {
                        str += "派送到门,";
                    }
                }
                if (containerType == "0") {
                    //fcl

                    if (type.indexOf("0") !== -1) {
                        str += "提货,";
                    }
                    if (type.indexOf("1") !== -1) {
                        str += "报关,";
                    }
                    if (type.indexOf("2") !== -1) {
                        str += "到港,";
                    }
                    if (type.indexOf("3") !== -1) {
                        str += "清关,";
                    }
                    if (type.indexOf("4") !== -1) {
                        str += "派送,";
                    }
                }
                return str;
            },

            /** 修改按钮操作 */
            handleEdit(row) {
                this.form = {}
                const id = row.id
                GetActionByUrlParam(this.url.info, id).then(response => {
                    this.form = response.result;
                    this.dialogFormVisible = true;
                    this.title = "修改业务员系数" + " - " + this.form.salemanName;
                });
            },
            saveSalesOrderSum() {
                this.$refs.saleOrderSumForm.validate(valid => {
                    if (valid) {
                        putAction(this.url.editSaleOrderSum, this.saleOrderSumForm).then(res => {
                            if (res.success) {
                                this.$message.success("修改成功");
                                //关闭弹窗
                                this.saleOrderSumDialogVisible = false;
                                this.getSalesOrderList()
                            }
                        })
                    }
                });
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            putAction(this.url.info, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success("修改成功");
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.getSalesOrderList()
                                    }
                                })
                        } else {
                            postAction(this.url.info, this.form).then(res => {
                                if (res.success) {
                                    this.$message.success("新增成功");
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery()
                                }
                            })
                        }

                    }
                });

            },

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.containerType = '0'
                this.checked = false
                // this.$set(this.queryForm,'agentPayType',"0")
                this.filterForm = {};
                this.SaleOrderDetailipagination.currentPage = 1;
                // this.queryForm.toExamine = '0';
                if (this.$refs['GTable']) {
                    this.$refs['GTable'].handleReset();
                }
                this.salesmanList = []
                this.getSalesOrderList();
            },
            deleteBatch(row) {
                const ids = row.id || this.selectedRowIds
                this.$confirm(`确定批量删除已选择的信息吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    DeleteActionByUrlParam(this.url.deleteBatch, ids).then((res) => {
                        this.handleQuery();
                    });
                }).catch(() => {
                });
            },
            getDictList(code) {
                if (sessionStorage.getItem("dict:" + code) || (this.$store.state.dict && this.$store.state.dict[code] && Array.isArray(this.$store.state.dict[code]))) {
                    this.dictlist = JSON.parse(sessionStorage.getItem("dict:" + code)) || this.$store.state.dict[code];
                    // console.log('local有这个数据:',sessionStorage.getItem('dict:'+code));
                    //存到前端页面缓存中
                    this.$set(this.dictMap, code, this.listToMap(this.dictlist, 'value'));
                    // console.log('session获取字典map:',code,this.dictMap);
                } else {
                    if (this.dictMap && this.dictMap[code]) {

                    } else {
                        getAction(this.url.dict + code).then(res => {
                            this.dictlist = res.result;
                            // if (this.removeValue){
                            //     this.dictlist.filter(i=>{
                            //         return i.value != this.removeValue;
                            //     })
                            // }
                            // this.options = this.allOptions.splice(0,20)
                            // console.log('查询完后端存到前端缓存中:',this.dictlist);

                            // console.log('后端获取字典map:',code,this.dictMap);
                            //存到前端页面缓存中
                            // if(this.dictlist && this.dictlist.length>0 && Array.isArray(this.dictlist)) {
                            //     this.$set(this.dictMap, code, this.listToMap(this.dictlist, 'value'));
                            //     // sessionStorage.setItem('dict:' + code, JSON.stringify(this.dictlist));
                            //     this.$store.commit('dict/setDict', {
                            //         key: code,
                            //         value: this.dictlist
                            //     })
                            // }
                            if (this.dictlist && this.dictlist.length > 0 && Array.isArray(this.dictlist)) {
                                this.$set(this.dictMap, code, this.listToMap(this.dictlist, 'value'));
                                //存到前端页面缓存中-大于1000条存储到store里面
                                if (this.dictlist.length > 1000) {
                                    this.$store.commit('dict/setDict', {
                                        key: code,
                                        value: this.dictlist
                                    })
                                } else {
                                    sessionStorage.setItem("dict:" + code, JSON.stringify(this.dictlist));
                                }
                            }
                        })
                    }

                }
            },

        },
        computed: {
            buttonShowList() {
                return {
                    '业务员统计': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '业务员统计'),
                    '客服': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '客服'),
                    '操作员': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '操作员'),
                    '销售支持审核时间': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '销售支持审核时间'),
                    '导出': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '导出'),
                    '批量核对': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '批量核对'),
                    '批量编辑备注': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '批量编辑备注'),
                    '批量保存核对后提成': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '批量保存核对后提成'),
                    '是否编辑核对后提成': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '是否编辑核对后提成'),
                    '汇总页面': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '汇总页面'),
                    '汇总数据生成': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '汇总数据生成'),
                    '审核': this.$btnIsShow('sale/NoReviewed', '1', '审核'),
                    '核对过是否继续核对': this.$btnIsShow('dashboard/salesmanCoefficient', '0', '核对过是否继续核对'),
                }
            }
        },
        created() {
            // this.$nextTick(()=>{
            //     this.$set(this.queryForm,'orderType','0')
            // })
            if (this.dictCodes) {
                for (var i = 0; i < this.dictCodes.length; i++) {
                    if (this.dictCodes[i]) {
                        this.getDictList(this.dictCodes[i]);
                    }
                }
            }
            this.queryForm.toExamine = '0'
            this.queryForm.containerType = '0'
        },
    };
</script>

<style lang="scss" scoped>
    .el-checkbox {
        text-align: right;
        width: 100%;
        padding-right: 10px;
    }

    .flex_wait_box {
        display: flex;

        .select_width {
            width: 200px;
            margin: 0 10px;
        }
    }

    .no_margin {
        margin: 0;
        padding: 0 0px 0px 70px !important;
        height: 22px !important;
    }
</style>
<style lang="scss">
    .tab_class_billed {
        .el-tabs--top > .is-top {
            position: unset;
            padding-left: 0;
            width: 100%;
        }

        .el-tabs--top > .el-tabs__content {
            top: 0;
        }

        .el-tabs__header {
            margin: 0;
        }

        .el-tabs--card > .el-tabs__header {
            border-bottom: 0;
        }
    }

    .height_tab_bill {
        .el-tabs__item {
            line-height: 25px;
            height: 25px;
            font-size: 15px !important;
        }
    }
</style>
