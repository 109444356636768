<template>
    <div class="main-full-content">
        <el-dialog
            class="yulan"
            title="预览"
            append-to-body
            :visible="imgVisible"
            custom-class="dialogClass"
            top="50px"
            :before-close="() => { imgVisible = false }">
            <div class="addEditBox">
                <img :src="imgUrl" width="100%">
            </div>
        </el-dialog>
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="申请编号">
                            <el-input type="textarea" v-model="queryForm.applyNo" @keyup.enter.native="handleCheck" placeholder="用逗号或者空格分割"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="费用币种">
                            <f-select v-model="queryForm.payCur" dict="bd_cur"
                                      :isNeed="queryForm.payCur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="申请时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="stime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStartOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                                    clearable
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="结算对象">
<!--                            <f-select :dict="'supplierId'"-->
<!--                                      v-model="queryForm.settleId"-->
<!--                                      :isNeed="queryForm.settleId">-->
<!--                            </f-select>-->
                            <el-input v-model="queryForm.settleName" @keyup.enter.native="handleCheck" placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="查账时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="checkBillTime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                                clearable
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="申请人">
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.creator"
                                      :isNeed="queryForm.creator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="客户端申请人" label-width="120px">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'client_laoliu_kehu'"
                                      v-model="queryForm.cusCreator"
                                      :isNeed="queryForm.cusCreator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="5">
                    <el-form-item label="业务类型">
                      <el-select v-model="queryForm.agentPayType">
                        <el-option value="0" label="海运"></el-option>
                        <el-option value="2" label="快递"></el-option>
<!--                          <el-option value="4" label="海外仓"></el-option>-->
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="业务员">
                      <f-select
                          :dict="'sys_user_id'"
                          v-model="queryForm.salesmanId"
                          :is-need="queryForm.salesmanId"
                          :father-val="queryForm.salesmanId"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="removeBatch"
                           v-if="buttonShowList.plxz"
                >批量销账</el-button>
                <el-button size="medium" type="primary" @click="downloadWaitCheckExcel"
                           v-if="buttonShowList.dcexcel"
                >导出excel
                </el-button
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="72vh"
                    :show-operation="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleCheckBill(scope.row)"
                                   v-if="buttonShowList.xz"
                        >销账</el-button>
                        <span v-if="scope.row.warnMark==='1'" style="font-weight: bold;color: red">(费用或币种不一致！)</span>
                        <el-divider direction="vertical"></el-divider>
                        <el-popconfirm
                            title="是否设为未查账？"
                            confirm-button-text="确认"
                            cancel-button-text="不用了"
                            icon="el-icon-info"
                            icon-color="red"
                            v-if="scope.row.checkStatus === '1'"
                            @confirm="changeToWaitCheck(scope.row)"
                        >
                            <el-button slot="reference" type="text"
                                       v-if="buttonShowList.qxcz"
                            > 取消查账</el-button>
                        </el-popconfirm>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'applyNo'">
                      <el-button type="text" @click="showApplyDetail(scope.row)">{{scope.row.applyNo}}
                      </el-button>
                    </template>

                    <template v-else-if="scope.column.property === 'picUrl'">
                        <span v-for="(item,index) in scope.row.fileList" :key="index">
                            <a @click.prevent="showImg(item.url)" target="_Blank" download style="color: #00a0e9">{{item.name}}</a><br>
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'picUrlDown'">
                        <span v-for="(item,index) in scope.row.fileList" :key="index">
                            <a :href="item.url" target="_Blank" download style="color: #00a0e9">{{item.name}}</a><br>
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'waitPay'">
                        <span v-if="scope.row.payCur === scope.row.realCur">{{(Number(scope.row.payMoney) - scope.row.payed).toFixed(3)}}</span>
                        <span v-if="scope.row.payCur !== scope.row.realCur">应收实收币种不一致</span>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'payed'">-->
<!--                        <span>{{ (Number(scope.row.payMoney) - Number(scope.row.waitPay)).toFixed(3)}}</span>-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'creator'">
                        <span>{{parseDict(scope.row.creator,'sys_create_by')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'settleAccount'">
                        <span>{{scope.row.bankName + ' - '+ scope.row.account}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <remove-dialog ref="removeDialog" @ok="this.handleQuery"></remove-dialog>
        <receive-apply-update ref="apply" @ok="this.handleQuery"></receive-apply-update>
    </div>
</template>
<script>
    import QueryMixins from "../../../../mixins/QueryMixins";
    import {deleteAction, getAction, postAction} from "@/api/manage";
    import removeDialog from "./removeDialog";
    import receiveApplyUpdate from "./receiveApplyUpdate";
    import { setUpRouteUtil } from '@/utils/util';
    import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "../../../../utils/downloadJasper";

    export default {
        mixins: [QueryMixins],
        components: {
            removeDialog,
            receiveApplyUpdate
        },
        data() {
            return {
                queryForm:{agentPayType:'0'},
                dictCodes:['sys_create_by'],
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos: [],
                loading: false,
                form: {},
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 130,
                    },
                    // {
                    //     prop: 'checkStatus',
                    //     label: '查账状态',
                    // },
                    // {
                    //     prop: 'checkUnusualInfo',
                    //     label: '异常原因',
                    // },
                    {
                        prop: "applyNo",
                        label: "申请编号",
                        width: 120,
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },
                    // {
                    //     prop: "remark",
                    //     label: "备注",
                    //     width: 130,
                    // },
                    {
                        prop: "checkTime",
                        label: "查账时间",
                        width: 130,
                    },
                    {
                        prop: "checkAccountBy",
                        label: "查账人",
                    },
                    {
                        prop: "settleName",
                        label: "结算对象",
                    },
                    // {
                    //     prop: "company",
                    //     label: "公司名称",
                    // },
                    {
                        prop: "payMoney",
                        label: "应收金额",
                    },
                    {
                        prop: "payCur",
                        label: "应收币种",
                    },
                    {
                        prop: "payed",
                        label: "已收金额",
                    },
                    {
                        prop: "realMoney",
                        label: "账单金额",
                    },
                    {
                        prop: "realCur",
                        label: "账单币种",
                    },
                    {
                        prop: "billMoney",
                        label: "水单金额",
                    },
                    {
                        prop: "salesMoney",
                        label: "实际到账金额",
                    },
                    {
                        prop: "salesMoneyCur",
                        label: "实际到账币种",
                    },
                    {
                        prop: "waitPay",
                        label:"待收金额",
                        headerType:'remark',
                        detail:'提示：待收金额将在销账之后更新。<br>【待收余额 = 应收 - 已销账】',
                        width:100
                    },

                    // {
                    //     prop: "waitReceive",
                    //     label: "待收余额",
                    //
                    // },

                    {
                        prop: "creator",
                        label: "申请人",
                    },
                    {
                        prop: "createTime",
                        label: "申请时间",
                    },
                    {
                        prop: "settleAccount",
                        label: "收款银行",
                    },
                    {
                        prop: "picUrl",
                        label: "水单预览",
                    },
                    {
                        prop: "picUrlDown",
                        label: "水单下载",
                    },
                    // {
                    //     prop: 'rate',
                    //     label: '水单付款人',
                    //     width: 100
                    // },
                    // {
                    //     prop: 'rateMoney',
                    //     label: '水单金额',
                    // },
                    // {
                    //     prop: "billTime",
                    //     label: "账单时间",
                    // },

                ],
                imgVisible: false,
                imgUrl: '',
                url: {
                    list: "/shipping/hySettleReceive/waitRemove",
                    checkAmount: "/shipping/hySettleReceive/checkAmount",
                    removeSubmit: "/shipping/hySettleReceive/removeSubmit",
                    removeBatch: "/shipping/hySettleReceive/removeBatchSubmit",
                    changeToWaitCheck: "/shipping/hySettleReceive/changeToWaitCheck",
                    // downloadWaitCheckExcel:"/shipping/hySettleReceive/downloadWaitCheckExcel",
                    downloadWaitCheckExcel:"/file/export/excel/downloadCaiwuDm2",

                },
                rules: {
                    realCtn: [
                        {required: true, message: "请输入实际总件数", trigger: "blur"},
                        {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                    ],
                    realKg: [
                        {required: true, message: "请输入实际总重量", trigger: "blur"},
                        {
                            pattern:
                                /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                            message: "只能输入最多三位小数的数字",
                            trigger: "change",
                        },
                    ],
                },
                stime: [],
                otime: [],
                checkBillTime: [],
                // btime: [],  //开船时间
                // feetime: [],  //开船时间
                // rmTime: [],  //开船时间
            };
        },
        computed: {
          buttonShowList() {
            return {
              'plxz':this.$btnIsShow('hyFinanceManage/modules/receive/waitRemove','0','批量销账'),
              'dcexcel':this.$btnIsShow('hyFinanceManage/modules/receive/waitRemove','0','导出当前页excel'),
              'xz':this.$btnIsShow('hyFinanceManage/modules/receive/waitRemove','1','销账'),
              'qxcz':this.$btnIsShow('hyFinanceManage/modules/receive/waitRemove','1','取消查账'),
            }
          }

        },
        created() {
          this.$set(this.queryForm,'orderType','0')
        },
        methods: {
            initCusPage({cusId}) {
                if (cusId){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/customer/Edit');
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }
            },
            //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }
            },
            showApplyDetail(row) {
              this.$refs.apply.showDetail(row);
            },

            changeToWaitCheck(row) {
                // console.log('取消查账row.id',row.id)
                row.checkStatus = "0";
                postAction(this.url.changeToWaitCheck, row).then((res) => {
                    //检测是同一个结算对象 才进行下载
                    if (res.success) {
                        this.visible = false;
                        this.handleQuery();
                    }
                });
            },

            removeBatch(){
                console.log('进入批量销账')
                console.log(this.selectedRowKeys)
                console.log(this.selectionRows)
                console.log(this.selectedRowOrderNos)

                this.$confirm(`确定进行批量销账？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        let agentStatus = 0;
                        let curList = 0;
                        let applyIds = [];

                        this.selectionRows.forEach(s => {
                            applyIds.push(s.id)
                            console.log(s.payMoney)
                            console.log(s.salesMoney)
                            if(s.payMoney !== s.salesMoney){
                                agentStatus++
                            }
                            if(s.payCur !== s.salesMoneyCur){
                                curList++
                            }
                        })
                        if(agentStatus>0){
                            this.$message.warning('只有【应收金额 = 实际到账金额】，才可进行批量销账')
                            return
                        }
                        if(curList>0){
                          this.$message.warning("所选费用的折合币种不一致 或 实际到账币种和费用折合币种不一致！")
                            return
                        }
                        postAction(this.url.removeBatch, {applyIds:applyIds}).then((res)=>{
                            console.log(res)
                            if(res.success){
                                this.$message.success('批量销账成功！')
                                this.handleCheck()
                            }else{
                                this.$message.error(res.message)
                            }
                        })
                    });
            },
            handleCheckBill(row) {
                console.log('请求销账',row);

                //如果应付和实付一致，确认后直接销账
                if(row.payMoney===row.salesMoney){
                  if(row.payCur !== row.salesMoneyCur) {
                    this.$message.warning("所选费用的折合币种不一致 或 实际到账币种和费用折合币种不一致！")
                    return
                  }
                    this.$confirm(`应收与实际到账金额相等，是否直接进行销账?`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'success',
                        closeOnClickModal: false,
                    }).then(() => {
                        postAction(this.url.removeSubmit, {id:row.id}).then(res => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleCheck()
                        })
                    }).catch(() => {

                    })
                }else{
                    this.$refs.removeDialog.edit(row);
                    // 检查销账申请的金额和当前费用金额是否一致 2022-2-11 max 取消
                    // const obj = {
                    //     applyNo:row.applyNo,
                    //     payMoney:row.payMoney,
                    //     realMoney:row.realMoney
                    // }
                    // getAction(this.url.checkAmount, obj).then((res) => {
                    //     if (res.success === true) {
                    //         this.$refs.removeDialog.edit(row);
                    //     }
                    // }).catch(err=>{
                    //
                    // })
                }
            },
            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                let nos = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    nos.push(selection[i]["orderNo"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                this.selectedNos = [...nos];
            },
            handleReceive() {
                console.log("申请收款以下id的费用", this.selectedRowKeys);
            },
            handleReset() {
                this.queryForm = {};
                this.$set(this.queryForm,'agentPayType','0')
                this.otime = "";
                this.stime = "";
                this.checkBillTime= "";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleCheck() {
                this.loading = true;

                if (this.stime && this.stime.length > 1) {
                    this.queryForm.sCreateTimeS = this.stime[0];
                    this.queryForm.sCreateTimeE = this.stime[1];
                }else{
                    this.queryForm.sCreateTimeS = '';
                    this.queryForm.sCreateTimeE = '';
                }

                if (this.checkBillTime && this.checkBillTime.length > 1) {
                    this.queryForm.checkBillTimeS = this.checkBillTime[0];
                    this.queryForm.checkBillTimeE = this.checkBillTime[1];
                }else{
                    this.queryForm.checkBillTimeS = '';
                    this.queryForm.checkBillTimeE = '';
                }
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                console.log("查询对象", params);

                getAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            const {records, total} = res.result;
                            this.tableData = records || res.result;

                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
          downloadWaitCheckExcel2() {
            // if(this.selectionRows.length ===0){
            //     this.$message.warning("请勾选费用");
            //     return;
            // }
            // console.log(this.checkBillTime)
            if((this.queryForm.applyNo === '' || this.queryForm.applyNo === undefined)
                && (this.queryForm.username === '' || this.queryForm.username === undefined)
                // && (this.queryForm.creator === '' || this.queryForm.creator === undefined)
                && (this.stime ===null || this.stime.length === 0)
                && (this.checkBillTime ===null || this.checkBillTime.length === 0)
                // && (this.otime ===null || this.otime.length === 0)
                // && (this.feetime ===null || this.feetime.length === 0)
                // && (this.rmTime ===null || this.rmTime.length === 0)
                // && (this.queryForm.company === '' || this.queryForm.company === undefined)
                // && (this.queryForm.payCur ==='' || this.queryForm.payCur===undefined)
            ){
              this.$message.warning('导出excel必须添加查询条件')
              return
            }
            this.loading = true;

            this.queryForm.downloadExcelType='waitRemove';
            if (this.stime && this.stime.length > 1) {
              this.queryForm.sCreateTimeS = this.stime[0];
              this.queryForm.sCreateTimeE = this.stime[1];
            } else {
              this.queryForm.sCreateTimeS = '';
              this.queryForm.sCreateTimeE = '';
            }

            if (this.checkBillTime && this.checkBillTime.length > 1) {
              this.queryForm.checkBillTimeS = this.checkBillTime[0];
              this.queryForm.checkBillTimeE = this.checkBillTime[1];
            }else{
              this.queryForm.checkBillTimeS = '';
              this.queryForm.checkBillTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
              if (typeof form[item] === "string") {
                form[item] = form[item].trim();
                if (form[item] === "") {
                  form[item] = null;
                }
              }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
              ...form,
              column: prop,
              order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
              if (this.queryForm.column === "startTime") {
                this.$set(params, "column", "startTime");
              } else {
                this.$set(params, "column", "createTime");
              }
              //提货中查询
              if (this.queryForm.column === "distributionTime") {
                this.$set(params, "column", "distributionTime");
              }
            }
            if (this.ipagination) {
              params.pageNo = this.ipagination.currentPage;
              params.pageSize = this.ipagination.pageSize;
            }
            console.log("开始下载");

            let filename = this.getDateStr() + '导出待销账'

            let url = this.url.downloadWaitCheckExcel

            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName('POST',url,params,filename + ".xlsx",this);
          },
            downloadWaitCheckExcel() {
                // if(this.selectionRows.length ===0){
                //     this.$message.warning("请勾选费用");
                //     return;
                // }
                // console.log(this.checkBillTime)
                if((this.queryForm.applyNo === '' || this.queryForm.applyNo === undefined)
                    && (this.queryForm.username === '' || this.queryForm.username === undefined)
                    // && (this.queryForm.creator === '' || this.queryForm.creator === undefined)
                    && (this.stime ===null || this.stime.length === 0)
                    && (this.checkBillTime ===null || this.checkBillTime.length === 0)
                    // && (this.otime ===null || this.otime.length === 0)
                    // && (this.feetime ===null || this.feetime.length === 0)
                    // && (this.rmTime ===null || this.rmTime.length === 0)
                    // && (this.queryForm.company === '' || this.queryForm.company === undefined)
                    // && (this.queryForm.payCur ==='' || this.queryForm.payCur===undefined)
                ){
                    this.$message.warning('导出excel必须添加查询条件')
                    return
                }
                this.loading = true;

                this.queryForm.downloadExcelType='waitRemove';
                if (this.stime && this.stime.length > 1) {
                    this.queryForm.sCreateTimeS = this.stime[0];
                    this.queryForm.sCreateTimeE = this.stime[1];
                } else {
                    this.queryForm.sCreateTimeS = '';
                    this.queryForm.sCreateTimeE = '';
                }

                if (this.checkBillTime && this.checkBillTime.length > 1) {
                    this.queryForm.checkBillTimeS = this.checkBillTime[0];
                    this.queryForm.checkBillTimeE = this.checkBillTime[1];
                }else{
                    this.queryForm.checkBillTimeS = '';
                    this.queryForm.checkBillTimeE = '';
                }

                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                console.log("开始下载");

                let filename = this.getDateStr() + '导出待销账'

                let url = this.url.downloadWaitCheckExcel

                getAction(url, params, {responseType: 'blob'})
                    .then(res => {
                        // console.log(res)
                        if (res.size === 0) {
                            this.$message.error('导出失败，请稍后再试或联系管理员')
                            this.visible = false
                            return
                        }
                        var binaryData = [];
                        binaryData.push(res);

                        let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                        let link = document.createElement('a');
                        link.style.display = 'none';
                        link.href = url;
                        link.setAttribute('download', filename + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);//下载完成移除元素
                        window.URL.revokeObjectURL(url) //释放掉blob对象

                        this.visible = false
                        setTimeout(() => {   //设置延迟执行
                            this.$message.success("导出成功！")
                            // this.$emit('ok')
                            this.handleCheck();
                        }, 3000)
                    })
            },
            showImg(url){
                console.log('url',url);
                this.imgUrl = url;
                this.imgVisible = true;
            },
        },
    };
</script>
<style scoped>
  .el-button{
    user-select: unset;
  }
</style>
