<template>
  <div>

    <el-card class="box-card">
      <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
        <el-card class="box-card">
          <el-row style="display: flex; flex-wrap: wrap">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="交货地点">
                <f-select
                    v-model="queryForm.lclPlace"
                    :is-need="queryForm.lclPlace"
                    :dict="'delivery_point'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="入仓单名称">
                <el-input
                    v-model="queryForm.danName"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="公司名">
                <el-input
                    v-model="queryForm.companyName"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-card>
      </el-form>
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="handleAdd"

          v-if="buttonShowList.add"
          >新增</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
                type="text"
                @click="handleEdit(scope.row)"
                v-if="buttonShowList.bj"
              >编辑</el-button
            >
            <el-button
                type="text"
                @click="handleDel(scope.row)"
                v-if="buttonShowList.sc"
              >删除</el-button
            >
          </template>

          <template v-else-if="scope.column.property === 'isCl'">
            <span>{{parseDict(scope.row.isCl,'rucang_type')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <rucang-fg
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      :form="selectRow"
      @ok="handleSearch"
    ></rucang-fg>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { deleteAction, putAction, postAction, getAction } from "@/api/manage";
import { mobilePattern } from "@/utils/pattern";
import RucangFg from "../pdf/rucang/RucangFg";
export default {
  name: "RucangModel",
  mixins: [QueryMixins],
  components: {
    RucangFg,
  },
  data() {
    return {
      isUsePost:true,
      dictCodes:['rucang_type'],
      selectRow: {},
      btnDisable: false,
      row: {},
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      countrylist: [],

      //表单校验

      visible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "isCl",
          label: "用户类型",
        },
        {
          prop: "username",
          label: "绑定的客户名",
          overFlow:true,
        },
        {
          prop: "lclPlaceName",
          label: "交货地点",
        },
        {
          prop: "companyName",
          label: "公司名",
        },
        {
          prop: "danName",
          label: "入仓单名字",
          over: true,
        },
        {
          prop: "feeStr",
          label: "费用详细",
          overFlow: true,
        },
        {
          prop: "keyword",
          label: "导航搜索",
          over: true,
        },
        {
          prop: "phone",
          label: "联系电话",
          over: true,
          width: 160,
        },
        {
          prop: "address",
          label: "仓库地址",
          overFlow: true,
        },
        {
          prop: "infoStr",
          label: "入仓须知",
          overFlow: true,
        },
        {
          prop: "clStr",
          label: "测量标准",
          // sortable: "custom",
          overFlow: true,
        },
        {
          prop: "createTime",
          label: "录入时间",
        },
      ],
      url: {
        list: "/order/os/rucang/model/list",
        delete: "/order/os/rucang/model/delete",
        getOnlyById:'/order/os/rucang/model/getOnlyById',
        getCustomerList:'/order/os/rucang/model/getCustomerList',
      },
    };
  },
  created() {
    this.queryForm.initCountry = 1;
    this.queryForm.cusId = this.$route.query.id;
  },
  methods: {
    //导入操作绑定
    importOperation(data) {
      // consoloe.log("上传文件", data);
      const formData = new FormData();
      formData.append("file", data.file);
      postAction(this.url.import + "?orderNo=" + this.order.orderNo, formData)
        .then((res) => {
          // consoloe.log("产品返回数据", res);
          if (res.success) {
            //如果第一行的中文名为空则清空
            // let list = [];
            // for (var i=0;i<this.form.osOrderGoodsList.length;i++){
            //   if(this.form.osOrderGoodsList[i].name){
            //     list.push(this.form.osOrderGoodsList[i]);
            //   }
            // }
            // this.form.osOrderGoodsList = list;
            // //上传成功,返回结果赋值给form对象的文件集合
            // this.form.osOrderGoodsList = this.form.osOrderGoodsList.concat(res.result);
            // this.proMap = this.listToMap(this.form.airOrderGoodsList, 'name')
            this.$message.success(res.message);
            this.handleQuery();
          }
        })
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isLt2M && isFile;
    },
    getDp(val) {
      if (val) {
        this.$set(this.form, "deliveryPoint", val.join("/"));
      }
      // consoloe.log("小彪子，交货", this.form);
    },
    gettr(val) {
      if (val) {
        if (this.$refs["tr"]) {
          this.$set(this.form, "transportStatusText", val);
        }
        // consoloe.log("小彪子，渠道", this.form);
      }
    },
    getso(val) {
      if (val) {
        if (this.$refs["so"]) {
          this.$set(this.form, "solicitationText", val);
        }
        // consoloe.log("小彪子，揽货", this.form);
      }
    },
    handleDel(row) {
      this.$confirm(`确定删除${row.danName}吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleAdd() {
      this.selectRow = {};
      this.form = {};
      // consoloe.log("供应商表单对象", this.form);
      getAction(this.url.getCustomerList).then(res=>{
        this.$set(this.selectRow,'dictList',res.result);
        this.dialogFormVisible = true;
        this.btnDisable = false;
      })

    },
    handleEdit(row) {
      this.selectRow = row;
      this.btnDisable = false;
      for (var name in row) {
        this.$set(this.form, name, row[name]);
        if (name == "deliveryPoint") {
          this.$set(this.form, "deliveryPoints", row[name].split("/"));
        }
      }
      getAction(this.url.getOnlyById+"?id="+row.id)
      .then(res=>{
        this.selectRow = res.result;
        this.dialogFormVisible = true;
      })

    },
    saveImporter() {
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;
      // consoloe.log("进口商对象", this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            putAction(this.url.edit, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          } else {
            postAction(this.url.add, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisable = false;
        }
      });
    },
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('docpage/RucangModel','0','新增'),
        'bj':this.$btnIsShow('docpage/RucangModel','1','编辑'),
        'sc':this.$btnIsShow('docpage/RucangModel','1','删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
