<template>
  <div>

      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="订单号">
                <el-input
                    class="textarea_table"
                    v-model="queryForm.orderNo"
                    type="textarea"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="收款状态">
                <el-select filterable clearable v-model="queryForm.recStatus">
                  <el-option value="0" label="无应收"></el-option>
                  <el-option value="1" label="已销账"></el-option>
                  <el-option value="2" label="部分销账"></el-option>
                  <el-option value="9" label="已申请付款"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="结算对象">
                <el-input
                    type="textarea"
                    class="textarea_table"
                    v-model="queryForm.agent"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客服">
                <f-select
                    :dict="'docId'"
                    :is-need="queryForm.docId"
                    class="textarea_table"
                    v-model="queryForm.docId"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="业务员">
                <f-select
                    :dict="'salesmanId'"
                    :is-need="queryForm.salesmanId"
                    class="textarea_table"
                    v-model="queryForm.salesmanId"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="收款人">
                <f-select
                    :dict="'sys_user_id'"
                    :is-need="queryForm.billmanId"
                    class="textarea_table"
                    v-model="queryForm.billmanId"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="完成时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.deliveredStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.deliveredEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="ETD:" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.etdStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.etdEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="ETA:" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.etaStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.etaEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleQuery"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
                <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-card class="box-card">
        <div>
          <el-button  type="primary" size="small"  @click="daochu">导出</el-button>
        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
        >
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';


export default {
  name: "YingshouHK",
  mixins: [QueryMixins],
  components: {
  },
  props:{
    settlement:{
      type:String
    },
    visible:{
      type:Boolean
    }
  },
  data() {
    return {
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      disableMixinMounted :true,
      proVisible: false,
      url: {
        list: "/order/yingshou/pageList",
        daochu: "/file/jasper/downloadHuikuanExport"

      },
      issalesman: "0",
      columns: [
        {
          type: "index",
          label:'序号',
          width: 33,
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "agent",
          label: "客户名",
        },
        {
          prop: "feename",
          label: "费用名",
        },
        {
          prop: "payType",
          label: "付款类型",
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "billman",
          label: "收款人",
        },
        {
          prop: "createTimeStr",
          label: "下单日期",
          minWidth: 120
        },
        {
          prop: "deliveredsStr",
          label: "完成日期",
          minWidth: 120
        },
        {
          prop: "etdStr",
          label: "ETD",
        },
        {
          prop: "etaStr",
          label: "ETA",
        },
        {
          prop: "total",
          label: "应收金额(CNY)",
        },
        {
          prop: "realPayMoney",
          label: "已收金额(CNY)",
        },
        {
          prop: "noPay",
          label: "未收金额(CNY)",
        },
      ],
    };
  },
  watch: {
    visible(val) {
      if(val && this.settlement) {
        this.$set(this.queryForm,'settlement',this.settlement);
       this.handleQuery();
      } else {

      }
    }
  },
  created() {


  },
  methods: {
    handleClose() {
      this.$emit("ok");

    },
    daochu(){
      this.downloadExcel(this.url.daochu, "订单应收回款报表", this.queryForm);
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "1";
      this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.handleQuery();
    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
