<!--渠道设置-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="90px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="船公司">
                            <f-select
                                    v-model="queryForm.shippingCompanyId"
                                    :is-need="queryForm.shippingCompanyId"
                                    :dict="'ship_company'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港" :label-width="formLabelWidth">
                            <f-select
                                    v-model="queryForm.startPortId"
                                    :is-need="queryForm.startPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港" :label-width="formLabelWidth">
                            <f-select
                                    v-model="queryForm.endPortId"
                                    :is-need="queryForm.endPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="报价人">
                            <f-select
                                    v-model="queryForm.createBy"
                                    :is-need="queryForm.createBy"
                                    :dict="'sys_user'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="报价名称">
                            <el-input v-model="queryForm.priceName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch()"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15"></div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="64vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button style="margin-left:10px" type="text" @click="handleEdit(scope.row,$event)"
                                   v-if="buttonShowList.rePrice"
                        >重新报价
                        </el-button
                        >
                        <el-button type="text" @click="handleOrderNoDialog(scope.row)"
                                   v-if="buttonShowList.bindNo"
                        >绑定单号
                        </el-button
                        >

                        <el-button type="text" @click="handleEditPdf(scope.row,$event)"
                                   v-if="buttonShowList.gePdf"
                        >生成PDF
                        </el-button
                        >
                        <el-button type="text" @click="handleDele(scope.row)"
                                   v-if="buttonShowList.sendEmail"
                        >发送邮件
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'startTime'">
                        <span>{{ formatterTime(scope.row.startTime) }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'endTime'">
                        <span>{{ formatterTime(scope.row.endTime) }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.pageTitle"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form id="pdfDom" ref="pdfDom" :model="form" :rules="rules">
                <!-- <el-card class="box-card"> -->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="报价名称" :label-width="formLabelWidth" prop="priceName">
                                <el-input v-model="form.priceName" autocomplete="off" show-word-limit
                                          maxlength="100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="船公司" :label-width="formLabelWidth" prop="shippingCompanyId">
                                <f-select :is-Need="form.shippingCompanyId"
                                          v-model="form.shippingCompanyId"
                                          :dict="'ship_company'"></f-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="起运港" :label-width="formLabelWidth" prop="startPortId">
                                <f-select v-model="form.startPortId"  :dict="'bd_port'"
                                          :is-need="form.startPortId" @changet="getSatrtPort">
                                </f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="目的港" :label-width="formLabelWidth" prop="endPortId">
                                <f-select v-model="form.endPortId" @change="getfbacode"
                                          :dict="'bd_port'" :is-need="form.endPortId" @changet="getEndPort">
                                </f-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="有效期开始时间" :label-width="formLabelWidth" prop="startTime">

                        <el-date-picker
                                class="f-flex-1"
                                v-model="form.startTime"
                                type="datetime"
                                size="small"
                                :picker-options="editStartOptions"
                                placeholder="请选择"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="有效结束时间" :label-width="formLabelWidth" prop="endTime">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="form.endTime"
                                type="datetime"
                                size="small"
                                placeholder="请选择"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                :picker-options="editStopOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="直达/中转" :label-width="formLabelWidth" prop="arriveType">
                                <template>
                                    <el-radio-group v-model="form.arriveType">
                                        <el-radio :label="'0'">直达</el-radio>
                                        <el-radio :label="'1'">中转</el-radio>
                                    </el-radio-group>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="中转港" :label-width="formLabelWidth">
                                <f-select
                                        v-model="form.transferPortId"
                                        :dict="'bd_port'"
                                        @changet="getTransferPort"
                                        :is-need="form.transferPortId"
                                >
                                </f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="航程/天" :label-width="formLabelWidth" prop="voyage">
                                <el-input v-model="form.voyage" autocomplete="off" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                <!-- </el-card> -->
                <div class="lable_title">增值服务</div>
                <!-- <el-card class="box-card"> -->
                    <div slot="header" class="clearfix">
                        <span>增值服务</span>
                    </div>
                    <el-button type="primary" @click="addGp" size="small" round id="add">添加柜子</el-button>
                    <el-form :model="form" ref="gpform" :rules="rules">
                        <div v-for="(item, index) in form.gplist" :key="index">
                            <el-row>
                                <el-col :span="7">
                                    <el-form-item label="箱型" :label-width="formLabelWidth"
                                                  :prop="'gplist.'+index+'.gp'"
                                                  :rules="rules.gp">
                                        <el-select
                                                clearable
                                                filterable
                                                placeholder="请选择"
                                                style="width: 100%"
                                                v-model="item.gp"
                                                @change="updateBoxlist($event,index)"
                                        >
                                            <el-option
                                                    v-for="(j, i) in item.boxlist"
                                                    :label="j.label"
                                                    :value="j.label"
                                                    :key="i"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item label="数量" :label-width="formLabelWidth"
                                                  :prop="'gplist.'+index+'.number'"
                                                  :rules="rules.number">
                                        <el-input
                                                v-model="item.number"
                                                type="number"
                                                placeholder="数量，正整数"
                                                @change="updateGp()"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item label="价格" :label-width="formLabelWidth"
                                                  :prop="'gplist.'+index+'.price'"

                                                  :rules="rules.price">
                                        <el-input v-model="item.price" placeholder="价格" @change="caculateTotal"
                                                  type="number"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                    <el-button
                                            style="margin: 4px 10px"
                                            type="primary"
                                            size="small"
                                            @click="deleteItem(item, index)"
                                    >删除
                                    </el-button
                                    >
                                </el-col>
                            </el-row>

                        </div>
                    </el-form>
                    <el-form-item label="是否FBA" :label-width="formLabelWidth" prop="isFba">
                        <template>
                            <el-radio-group v-model="form.isFba">
                                <el-radio :label="'0'">否</el-radio>
                                <el-radio :label="'1'">是</el-radio>
                            </el-radio-group>
                        </template>
                    </el-form-item>
                    <el-row>
                        <el-col :span="12" v-show="form.isFba =='1'">
                            <el-form-item label="FBA CODE" :label-width="formLabelWidth"
                                          prop="fbaCode" :rules="form.isFba =='1'?rules.fbaCode:[]">
                                <el-select
                                        clearable
                                        filterable
                                        v-model="form.fbaCode"
                                        placeholder="请选择"
                                        style="width:100%"
                                >
                                    <el-option
                                            v-for="(item, index) in fbaCodeList"
                                            :label="item.label"
                                            :value="item.value"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>

                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="客户名" :label-width="formLabelWidth">
                                <el-select
                                        clearable
                                        filterable
                                        v-model="form.customerName"
                                        placeholder="请选择"
                                        style="width:100%"
                                        @click.native="initCusList"
                                >
                                    <el-option
                                            v-for="(item, index) in cusList"
                                            :label="item.label"
                                            :value="item.value"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="报价类型" :label-width="formLabelWidth">
                                <el-select
                                        clearable
                                        filterable
                                        v-model="form.priceExplainName"
                                        placeholder="请选择"
                                        style="width:100%"
                                        @change="getcontent"
                                        @click.native="initpriceExplainList"
                                >
                                    <el-option
                                            v-for="(item, index) in priceExplainList"
                                            :label="item.label"
                                            :value="item.value"
                                            :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="报价说明" :label-width="formLabelWidth">
                                <el-input
                                        v-model="form.priceExplainContent"
                                        autocomplete="off"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>


                    <el-form-item
                            v-for="(item, index) in priceFeeTemplate"
                            :label="item.templateCode"
                            :label-width="formLabelWidth"
                            :key="index"
                    >
                        <template v-if="item.templateCode.indexOf('拆柜') === -1">
                            <el-radio-group
                                    v-model="isNeedList[index].isNeed"
                                    @change="selectPrice(index, isNeedList[index].isNeed)"
                                    :key="index"
                            >
                                <el-radio :label="'1'">是</el-radio>
                                <el-radio :label="'0'">否</el-radio>
                            </el-radio-group>
                        </template>
                        <template v-else>
                            <el-row>
                                <el-col :span="span">
                                    <el-radio-group
                                            v-model="isNeedList[index].isNeed"
                                            @change="selectPrice(index, isNeedList[index].isNeed)"
                                            :key="index"
                                    >
                                        <el-radio :label="'1'">是</el-radio>
                                        <el-radio :label="'0'">否</el-radio>
                                    </el-radio-group>
                                </el-col>
                                <el-col :span="span" v-if="isNeedList[index].isNeed ==='1'">
                                    <el-form-item label="拆柜仓库" :label-width="formLabelWidth" prop="warehouse">
                                        <el-select
                                                clearable
                                                filterable
                                                v-model="form.warehouse"
                                                placeholder="请选择"
                                        >
                                            <el-option
                                                    v-for="item in warehouse"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="span" v-if="isNeedList[index].isNeed ==='1'">
                                    <el-form-item label="托盘数量" :label-width="formLabelWidth" prop="tray">
                                        <el-input v-model="form.tray"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                    </el-form-item>
                    <el-button id="addPrice" type="primary" @click="AddListRow()" size="small" round>添加费用</el-button>
                    <el-form :model="form" ref="feeform" :rules="rules">
                        <el-table ref="table" :data="form.selectPriceList" :label-width="formLabelWidth" :key="itemKey">
                            <el-table-column label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button
                                            style="position:relative;left: -5px;margin: 4px 0px"
                                            size="small"
                                            type="primary"
                                            @click="handleDelete(scope.$index)"
                                    >删除
                                    </el-button
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column label="费用名称" width="150">
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                  <template>
                                      <span class="is_require_form">费用名称</span>
                                  </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'selectPriceList.'+scope.$index +'.feeId'"
                                                  :rules="rules.feeId">
                                        <el-select
                                                clearable
                                                filterable
                                                v-model="scope.row.feeId"
                                                placeholder="请选择"
                                                @click.native="initBdFeeTypeList"

                                        >
                                            <el-option
                                                    v-for="(item, index) in feeTypeList"
                                                    :label="item.label"
                                                    :value="item.value"
                                                    :key="index"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="20GP"
                                    width="120"
                                    v-if="colData[0].isShow"
                                    key="1"
                            >
                                <template slot-scope="scope">
                                    <el-form-item
                                            :prop="'selectPriceList.'+scope.$index +'.twoGp'"
                                            :rules="rules.shuliange">
                                        <el-input v-model="scope.row.twoGp" type="number" disabled
                                                  @change="caculateTotal"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="40GP"
                                    width="120"
                                    v-if="colData[1].isShow"
                                    key="2">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'selectPriceList.'+scope.$index +'.fourGp'"
                                                  :rules="rules.shuliange">
                                        <el-input v-model="scope.row.fourGp" type="number" disabled
                                                  @change="caculateTotal"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="40HQ"
                                    width="120"
                                    v-if="colData[2].isShow"
                                    key="3">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'selectPriceList.'+scope.$index +'.fourHq'"
                                                  :rules="rules.shuliange">
                                        <el-input v-model="scope.row.fourHq" type="number" disabled
                                                  @change="caculateTotal"></el-input>

                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="45HQ"
                                    width="120"
                                    v-if="colData[3].isShow"
                                    key="4">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'selectPriceList.'+scope.$index +'.four5hq'"
                                                  :rules="rules.shuliange">
                                        <el-input v-model="scope.row.four5hq" type="number" disabled
                                                  @change="caculateTotal"></el-input>

                                    </el-form-item>
                                </template>
                            </el-table-column>

                            <el-table-column label="价格" width="120">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'selectPriceList.'+scope.$index +'.price'"
                                                  :rules="rules.price">
                                        <el-input v-model="scope.row.price" type="number"
                                                  @change="caculateTotal"></el-input>

                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="数量" width="120">
                                <template slot-scope="scope">
                                    <el-form-item>
                                        {{getAllCount(scope.row)}}
                                        <el-input v-model="scope.row.count" type="number" @change="caculateTotal"
                                                  disabled></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="币种" width="200">
                                <!-- 标头需要加必填标识 -->
                                <template slot="header">
                                  <template>
                                      <span class="is_require_form">币种</span>
                                  </template>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'selectPriceList.'+scope.$index +'.cur'"
                                                  :rules="rules.cur">
                                        <f-select
                                                v-model="scope.row.cur"
                                                placeholder="请选择"
                                                :dict="'bd_cur'"
                                                :is-need="scope.row.cur">
                                        </f-select>
                                    </el-form-item>

                                </template>
                            </el-table-column>

                        </el-table>

                    </el-form>

                <!-- </el-card> -->
                <div>
                    总计：
                    <span>海运费:{{totalUsd.toFixed(3)}}</span>&nbsp;&nbsp;
                    <span>杂费:{{totalOther.toFixed(3)}}</span>
                </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" class="btn btn-primary"
                           @click="getPdf('报价记录',['add','addPrice']);dialogFormVisible = false" v-if="this.lableType=='2'">
                    生成PDF
                </el-button>
                <el-button size="medium" type="primary" @click="handleSave" v-if="this.lableType=='1'">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="'绑定单号'" :visible.sync="dialogFormOrderNo" v-if="dialogFormOrderNo"
                   :close-on-click-modal="false">
            <el-form :model="form" ref="osOrder" :rules="rules">
                <el-card class="box-card last_card">

                    <el-button size="small" type="primary" @click="AddOrderNoRow" round>添加单号</el-button>
                    <el-table :data="form.osOrderList" :label-width="formLabelWidth">
                        <el-table-column label="操作" width="90">
                            <template slot-scope="scope">
                                <el-button
                                        style="position:relative;left: -5px;margin: 4px 0px"
                                        size="small"
                                        type="primary"
                                        @click="handleDeleteOrderNo(scope.$index)"
                                        :disabled="scope.row.isDisabled"
                                >删除
                                </el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="单号" width="90">
                            <template slot-scope="scope">
                                <el-form-item :prop="'osOrderList.'+scope.$index +'.orderNo'"
                                              :rules="rules.orderNo">
                                    <el-select
                                            clearable
                                            filterable
                                            v-model="scope.row.orderNo"
                                            placeholder="请选择"
                                            @click.native="initOrderNo"
                                            :disabled="scope.row.isDisabled"
                                    >
                                        <el-option
                                                v-for="(item, index) in noOrderNoList"
                                                :label="item"
                                                :value="item"
                                                :key="index"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                            <!--<template slot-scope="scope">
                                <el-form-item :prop="'osOrderList.' + scope.$index + '.orderNo'" :rules="rules.orderNo">
                                    <f-select v-model="scope.row.orderNo" :father-val="scope.row.orderNo"
                                              :is-need="scope.row.orderNo"
                                              :dict="'os_order_no'"
                                              :disabled="scope.row.isDisabled"
                                              autocomplete="off"
                                    ></f-select>
                                </el-form-item>

                            </template>-->
                        </el-table-column>

                    </el-table>
                </el-card>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormOrderNo = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleOrderNoSave()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="发送邮件" :visible.sync="dialogFormVisibleEmail" :close-on-click-modal="false"
                   v-if="dialogFormVisibleEmail">
            <el-form :model="form">
                <el-form-item label="标题" :label-width="formLabelWidth">
                    <el-input v-model="form.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="text" label="内容" :label-width="formLabelWidth">
                    <!-- 富文本编辑 -->
                    <quill-editor
                            ref="text"
                            v-model="form.content"
                            class="myQuillEditor"
                            :options="editorOption"
                    />
                </el-form-item>
                <el-form-item label="邮箱" :label-width="formLabelWidth">
                    <el-input v-model="form.email" autocomplete="off" placeholder="可支持发送多个邮箱逗号隔开"></el-input>
                </el-form-item>
          <!--      <el-form-item label="名字" :label-width="formLabelWidth">
                    <el-input v-model="form.usernames" autocomplete="off" placeholder="请输入内容,多个用户逗号隔开"></el-input>
                </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisibleEmail = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="sendEmail">发 送</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getAction, getBdFeeTypeList, getBdPortList, getPriceExplainList, postAction} from "@/api/manage";
    import QueryMixins from "../../mixins/QueryMixins";

    import {Quill, quillEditor} from "vue-quill-editor";
    import {container, ImageExtend, QuillWatch} from "quill-image-extend-module";
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import {validateCmb, validateCtn, validateShuliang} from "../../utils/validator";

    Quill.register("modules/ImageExtend", ImageExtend);

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        components: {
            quillEditor
        },
        computed: {
            total() {
                let price = 0;
                let count = this.count;
                if (count < 11 && count >= 0) {
                    price = 1.03
                }
                if (count > 10 && count < 101) {
                    price = 1.02
                }
                if (count > 100 && count < 10001) {
                    price = 1.01
                }
                return price * count
            },
          buttonShowList(){
              return {
                'rePrice':this.$btnIsShow('channel/PriceRecords','1','重新报价'),
                'bindNo':this.$btnIsShow('channel/PriceRecords','1','绑定单号'),
                'gePdf':this.$btnIsShow('channel/PriceRecords','1','生成PDF'),
                'sendEmail':this.$btnIsShow('channel/PriceRecords','1','发送邮件'),
              }
          }
        },
        data() {
            return {
                // 富文本配置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: "img",
                            action: "",
                            response: (res) => {
                                return res.info;
                            },
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                image: function () {
                                    QuillWatch.emit(this.quill.id);
                                },
                            },
                        },
                    },
                },
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.endTime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.endTime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.startTime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                //校验
                rules: {
                    orderNo: [
                        {required: true, message: '请选择订单号', trigger: 'change'},
                    ],
                    feeId: [
                        {required: true, message: '请选择费用名称', trigger: 'change'},
                    ],
                    cur: [
                        {required: true, message: '请选择币种', trigger: 'change'},
                    ],
                    priceName: [
                        {required: true, message: '请输入报价名称', trigger: 'blur'},
                    ],
                    shippingCompanyId: [
                        {required: true, message: '请选择船公司', trigger: 'change'},
                    ],
                    startPortId: [
                        {required: true, message: '请选择起运港', trigger: 'change'},
                    ],
                    endPortId: [
                        {required: true, message: '请选择目的港', trigger: 'change'},
                    ],
                    startTime: [
                        {required: true, message: '请选择有效开始时间', trigger: 'change'},
                    ],
                    endTime: [
                        {required: true, message: '请选择有效结束时间', trigger: 'change'},
                    ],
                    arriveType: [
                        {required: true, message: '请选择直达中转', trigger: 'change'},
                    ],
                    voyage: [
                        {required: true, message: '请输入航程', trigger: 'blur'},
                        {validator: validateCtn, trigger: 'change'},
                    ],
                    gp: [
                        {required: true, message: '请选择柜型', trigger: 'change'},
                    ],
                    number: [

                        {validator: validateCtn, trigger: 'change'},
                    ],
                    shuliange: [

                        {validator: validateShuliang, trigger: 'change'},
                    ],
                    price: [

                        {validator: validateCmb, trigger: 'change'},
                    ],
                    isFba: [
                        {required: true, message: '请选择是否FBA', trigger: 'change'}
                    ],
                    fbaCode: [
                        {required: true, message: '请选择FBACode', trigger: 'change'}
                    ],
                    warehouse: [
                        {required: true, message: '请选择拆柜仓库', trigger: 'change'}
                    ],
                    tray: [
                        {required: true, message: '请输入托盘数量', trigger: 'change'},
                        {validator: validateShuliang, trigger: 'change'},
                    ],
                },
                colData: [
                    {isShow: false},
                    {isShow: false},
                    {isShow: false},
                    {isShow: false},
                ],
                itemKey: Math.random(),
                //
                boxlist: [
                    {value: "1", label: "20GP"},
                    {value: "2", label: "40GP"},
                    {value: "3", label: "40HQ"},
                    {value: "4", label: "45HQ"},
                ],
                warehouse: [
                    {value: "1", label: "深圳仓"},
                    {value: "2", label: "宁波仓"},
                    {value: "3", label: "上海仓"},
                    {value: "4", label: "厦门仓"},
                ],
                //遍历显示的初始化费用集合
                span: 8,

                pricelist: [],
                //selectPricelist
                selectPriceList: [],
                feeTemplateList: [],
                priceFeeTemplate: [],
                isNeedList: [],
                dialogFormVisibleEmail: false, // 控制邮件弹窗
                //控制弹框显示
                dialogFormVisible: false,
                dialogPrice: false,
                emailList: [],
                ids: [],
                row: {},
                form: {
                    gplist: [],
                    gpPrice: 0,
                    priceExplainContent: "",
                    selectPriceList: [],
                    osOrderList: [],
                },
                totalUsd: 0,
                totalOther: 0,
                formLabelWidth: "120px",
                orderNoList: [],
                //控制弹框显示
                dialogFormOrderNo: false,
                columns: [
                    // {
                    //   type: "selection",
                    //   width: 50,
                    // },
                    {
                        prop: "action",
                        label: "操作",
                        width: 140,
                    },
                    {
                        prop: "priceName",
                        label: "报价名称",
                        width: 120,
                    },
                    {
                        prop: "createBy",
                        label: "报价人",
                        width: 120,
                    },
                    {
                        prop: "shippingCompany",
                        label: "船公司",
                        width: 180,
                    },
                    {
                        prop: "startPort",
                        label: "起运港",
                        width: 150,
                    },
                    {
                        prop: "endPort",
                        label: "目的港",
                        width: 150,
                    },
                    {
                        prop: "startTime",
                        label: "生效日期",
                        sortable: "custom",
                    },
                    {
                        prop: "endTime",
                        label: "截至日期",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "报价时间",
                        sortable: "custom",
                    },
                    {
                        prop: "remark",
                        label: "备注提醒",
                    },
                ],
                url: {
                    list: "/sys/priceRecords/list",
                    editPrice: "/sys/priceRecords/editPrice", //重新报价
                    bindingOrderNo: "/sys/priceRecords/bindingOrderNo", //绑定单号
                    queryById: "/sys/priceRecords/queryById", // 生成pdf
                    sendEmail: '/sys/supplier/sendEmail', // 发送邮件
                    getPriceTemp: "/sys/feeTemplate/getPriceTemp", //获取所有报价费用模板
                    getCountryNum: "/sys/bdPort/getCountryNum", //根据港口中文名获取fbacode
                    getCustomerByLogin: "/customer/getCustomerByLogin", //获取当前客户
                    getOrderNo: "/sys/priceRecords/getOrderNo", //获取未绑定的单号
                },
                obj1: {},
                obj2: {},
                obj3: {},
                obj4: {},
                fbaCodeList: [],
                cusList: [],
                orderNoObj: {},
                mdLabel: '',
                bdPortList: [],
                noOrderNoList: [],
                lableType: '',
            };
        },
        watch: {
            // dialogFormVisible(val) {
            //     if(val){
            //       document.querySelector('#add').style.display = 'block';
            //       document.querySelector('#addPrice').style.display = 'block';
            //     }
            // }
        },
        methods: {
            getSatrtPort(val) {
                this.form.startPort = val;
            },
            getEndPort(val) {
                this.form.endPort = val;
            },
            getTransferPort(val) {
                this.form.transferPort = val;
            },
            initCusList() {
                this.cusList = [];
                getAction(this.url.getCustomerByLogin)
                    .then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].username;
                                obj.label = res.result[i].username;
                                this.cusList.push(obj);
                            }
                        } else {
                            this.$message.warning('获取fbacode列表失败')
                        }
                    })
            },
            getfbacode(val) {
                this.fbaCodeList = [];
                if (val) {
                    getAction(this.url.getCountryNum + '?id=' + val)
                        .then(res => {
                            if (res.success) {
                                for (var i = 0; i < res.result.length; i++) {
                                    var obj = {};
                                    obj.value = res.result[i].code;
                                    obj.label = res.result[i].code;
                                    this.fbaCodeList.push(obj);
                                }
                            } else {
                                this.$message.warning('获取fbacode列表失败')
                            }
                        })
                }

            },
            initBdFeeTypeList() {
                this.feeTypeList = [];
                getBdFeeTypeList('1').then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].id;
                            obj.label = res.result[i].chineseName;
                            this.feeTypeList.push(obj);
                        }
                    }
                }).catch(err => {
                    this.$message.error(err)
                })
            },

            initpriceExplainList() {
                this.priceExplainList = [];
                // this.priceExplainList = [];
                getPriceExplainList().then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].priceExplainName;
                            obj.label = res.result[i].priceExplainName;
                            obj.content = res.result[i].priceExplainContent;
                            this.priceExplainList.push(obj);
                        }
                    }
                }).catch(err => {
                }).catch(err => {
                    this.$message.error(err)
                })
            },
            initBdPortList() {
                getBdPortList().then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].cnName;
                            obj.label = res.result[i].code + '-' + res.result[i].cnName;
                            this.bdPortList.push(obj);
                        }
                    }
                }).catch(err => {
                    this.$message.error(err)
                })


            },
            // getPdff(){
            //   // 关闭弹窗
            //   this.dialogFormVisible = false;
            //   getPdf();
            // },
            //日期隐藏时分秒显示
            formatterTime(data) {
                return data.substring(0, 10);
            },
            getcontent(val) {
                //根据当前选中的纸便利集合 给内容赋值
                for (var i = 0; i < this.priceExplainList.length; i++) {
                    if (val === this.priceExplainList[i].value) {
                        this.$set(
                            this.form,
                            "priceExplainContent",
                            this.priceExplainList[i].content
                        );
                    }
                }
            },
            //todo 选中的值发生变化，动态改变下拉框集合
            updateBoxlist(val, index) {
                //获取gplist已选择的所有柜型
                let strlist = this.form.gplist.map(i => {
                    return i.gp
                }).join(',');
                //遍历集合
                for (var i = 0; i < this.form.gplist.length; i++) {
                    let oldboxlist = this.boxlist;
                    let list = [];
                    for (var j = 0; j < oldboxlist.length; j++) {
                        if (strlist.indexOf(oldboxlist[j].label) == -1) {
                            list.push(oldboxlist[j]);
                        }


                    }
                    if (index == i) {
                        if (val) {
                            list.push({label: val})
                        }
                    }
                    this.form.gplist[i].boxlist = list;

                }
                //重新计算一次

                //重新
                this.updateGp();
            },
            caculateTotal() {
                /* //计算选择了柜型的价格*数量
                 this.totalUsd = 0;
                 for (var i = 0; i < this.form.gplist.length; i++) {
                     let numVar = this.form.gplist[i].number;
                     let priceVar = this.form.gplist[i].price;
                     let num = numVar ? parseFloat(numVar).toFixed(0) : 0;
                     let price = priceVar ? parseFloat(priceVar).toFixed(3) : 0;
                     if (this.form.gplist[i].gp) {
                         this.totalUsd += (num * price).toFixed(3);
                     }

                 }
                 this.totalOther = 0;
                 //计算选择了的费用的总
                 for (var i = 0; i < this.form.selectPriceList.length; i++) {
                     let numVar = this.form.selectPriceList[i].count;
                     let priceVar = this.form.selectPriceList[i].price;
                     let num = numVar ? parseFloat(numVar).toFixed(0) : 0;
                     let price = priceVar ? parseFloat(priceVar).toFixed(3) : 0;
                     //未选择柜型不参与计算

                     this.totalOther += (num * price).toFixed(3);
                 }*/
                //计算选择了柜型的价格*数量
                this.totalUsd = 0;
                for (var i = 0; i < this.form.gplist.length; i++) {
                    let numVar = this.form.gplist[i].number;
                    let priceVar = this.form.gplist[i].price;
                    let num = numVar ? parseFloat(numVar).toFixed(0) : 0;
                    let price = priceVar ? parseFloat(priceVar).toFixed(3) : 0;
                    if (this.form.gplist[i].gp) {
                        this.totalUsd += num * price;
                    }

                }
                this.totalOther = 0;
                //计算选择了的费用的总
                for (var i = 0; i < this.form.selectPriceList.length; i++) {
                    let numVar = this.form.selectPriceList[i].count;
                    let priceVar = this.form.selectPriceList[i].price;
                    let num = numVar ? parseFloat(numVar).toFixed(0) : 0;
                    let price = priceVar ? parseFloat(priceVar).toFixed(3) : 0;
                    //未选择柜型不参与计算

                    this.totalOther += num * price;
                }

            },
            listToMap(list, name) {
                var map = {};
                for (var index in list) {
                    map[list[index][name]] = list[index];
                }
                return map;
            },
            getAllCount(item) {
                //获取四个柜子数据
                let twoGpStr = item.twoGp;
                let fourGpStr = item.fourGp;
                let fourHqStr = item.fourHq;
                let four5HqStr = item.four5hq;
                let twoGp = twoGpStr ? parseFloat(twoGpStr).toFixed(0) : 0;
                let fourGp = fourGpStr ? parseFloat(fourGpStr).toFixed(0) : 0;
                let fourHq = fourHqStr ? parseFloat(fourHqStr).toFixed(0) : 0;
                let four5Hq = four5HqStr ? parseFloat(four5HqStr).toFixed(0) : 0;
                this.$set(item, 'count', Number(twoGp) + Number(fourGp) + Number(fourHq) + Number(four5Hq));

            },
            //柜型数量发生变化 动态更新费用列表中的数据
            updateTable() {
                //获取当前已选中的所有柜型j的集合
                let strlist = this.form.gplist.map(g => g.gp).join(',');
                let gpmap = this.listToMap(this.form.gplist, 'gp');
                //只要柜子有值并且数量大于0

                //判断是哪一种柜型，给对应的柜型回显
                if (strlist.indexOf("20GP") !== -1) {
                    this.colData[0].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'twoGp', gpmap['20GP'].number)

                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'twoGp', gpmap['20GP'].number)

                    }
                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'twoGp', '');

                    }
                    this.colData[0].isShow = false;
                }
                if (strlist.indexOf("40GP") !== -1) {
                    this.colData[1].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.fourGp = gpmap['40GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourGp', gpmap['40GP'].number)
                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourGp = gpmap['40GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourGp', gpmap['40GP'].number)
                    }

                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.twoGp = gpmap['20GP'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourGp', '');

                    }
                    this.colData[1].isShow = false;
                }
                if (strlist.indexOf("40HQ") !== -1) {
                    this.colData[2].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourHq', gpmap['40HQ'].number)

                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourHq', gpmap['40HQ'].number)

                    }

                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'fourHq', '')

                    }
                    this.colData[2].isShow = false;
                }
                if (strlist.indexOf("45HQ") !== -1) {
                    this.colData[3].isShow = true;
                    for (var i = 0; i < this.pricelist.length; i++) {
                        let obj = this.pricelist[i];
                        // obj.four5hq = gpmap['45HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'four5hq', gpmap['45HQ'].number)
                    }
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'four5hq', gpmap['45HQ'].number)

                    }

                } else {
                    for (var i = 0; i < this.form.selectPriceList.length; i++) {
                        let obj = this.form.selectPriceList[i];
                        // obj.fourHq = gpmap['40HQ'].number;
                        //计算总价 等于价格*数量
                        this.$set(obj, 'four5hq', '');

                    }
                    this.colData[3].isShow = false;
                }

                this.$nextTick(() => {
                    this.caculateTotal();
                })

            },
            updateGp() {
                this.updateTable();


                //重新计算



            },
            addGp() {
                if (this.form.gplist && this.form.gplist.length >= this.boxlist.length) {
                    this.$message.warning("最多添加四种柜型呢亲");
                    return false;
                }
                //判断当前已经选中的费用中 那些柜型已选中
                var list = [];
                let strlist = this.form.gplist.map(i => i.gp).join(',');
                for (var i = 0; i < this.boxlist.length; i++) {
                    if (strlist.indexOf(this.boxlist[i]["label"]) === -1) {
                        list.push(this.boxlist[i]);
                    }
                }
                this.obj1 = {
                    gp: "",
                    number: 0,
                    boxlist: list,
                    price: 0,
                };
                this.form.gplist.push(this.obj1);
                //强制刷新
                this.$forceUpdate();

            },
            initPriceTemplate() {
                if (this.priceFeeTemplate.length <= 0) {
                    getAction(this.url.getPriceTemp)
                        .then((res) => {
                            if (res.success) {
                                this.isNeedList = [];
                                this.priceFeeTemplate = res.result;
                                for (var i = 0; i < res.result.length; i++) {
                                    let templateCode = res.result[i].templateCode;
                                    let isNeed = "0";
                                    this.isNeedList.push({
                                        templateCode: templateCode,
                                        isNeed: isNeed,
                                    });
                                    //获取每一层下面的费用集合
                                    let list = res.result[i].feeTemplateList;

                                    if (list) {
                                        //count: null
                                        // createTime: "2021-05-20 00:41:02"
                                        // cur: ""
                                        // datalevel: "0"
                                        // feeName: "FDA HOLD entry review"
                                        // id: "1395056915065131010"
                                        // price: null
                                        // supId: ""
                                        // templateId: "6fce13a41b114d43bf1c0a39f763c76d"
                                        for (var k = 0; k < list.length; k++) {
                                            let {feeId, price, count, cur, createTime, id, supId, templateId} = list[k];
                                            this.pricelist.push({
                                                feeId,
                                                price,
                                                count,
                                                cur,
                                                templateCode: templateCode,
                                                templateId: templateId,
                                                supId: supId,
                                                id: id,
                                                createTime: createTime,
                                                twoGp: '',
                                                fourGp: '',
                                                fourHq: '',
                                                four5hq: '',
                                            });
                                        }
                                    }
                                }
                            }
                        })
                }

            },
            AddListRow() {
                this.form.selectPriceList.push({
                    feeId: "",
                    twoGp: '',
                    fourGp: '',
                    fourHq: '',
                    four5hq: '',
                    price: '',
                    count: '',
                    cur: '',

                });
                this.updateTable();
            },
            AddOrderNoRow() {
                this.form.osOrderList.push({
                    orderNo: "",
                    isDisabled: false,
                });
            },
            deleteItem(item, index) {
                let label = this.form.gplist[index].gp;
                for (var i = 0; i < this.form.gplist.length; i++) {
                    let box = this.form.gplist[i].boxlist;
                    if (label) {
                        box.push({label: label});
                    }
                }
                this.form.gplist.splice(index, 1);
                this.updateTable();
            },
            //控制价格的显示和隐藏
            //控制价格的显示和隐藏
            selectPrice(index, val) {
                //获取选中的集合
                let obj = this.isNeedList[index];
                //获取模板名称
                let name = obj.templateCode;

                //选择了是 则将对应模板的内容显示
                if (val === "1") {
                    for (var i = 0; i < this.pricelist.length; i++) {
                        if (name === this.pricelist[i].templateCode) {
                            //模板名称相同,则添加到对应的集合
                            this.form.selectPriceList.push(this.pricelist[i]);
                        }
                    }
                } else {
                    //清空
                    //模板名称相同,则删除对应的集合
                    for (var k = 0; k < this.form.selectPriceList.length; k++) {
                        if (name === this.form.selectPriceList[k].templateCode) {
                            this.form.selectPriceList.splice(k, 1);
                        }
                    }
                }

                this.updateTable();
            },
            //点击删除减少一行数据
            handleDelete(index) {
                this.form.selectPriceList.splice(index, 1);
            },
            //绑定单号删除
            handleDeleteOrderNo(index) {
                this.form.osOrderList.splice(index, 1);
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.dialogFormVisible = true;
            },
            async handleEdit(row, e) {
                this.lableType = '1';
                this.initBdFeeTypeList();
                this.form.selectPriceList = [];

                //queryById
                await getAction(this.url.queryById + '?id=' + row.id)
                    .then((res) => {
                        if (res.success) {
                            this.form = res.result;
                            this.form.selectPriceList = res.result.feeTemplateList;
                            this.form.gplist = [];
                            this.pricelist = [];
                            //回显柜子集合数据
                            for (var i = 0; i < this.form.cabientList.length; i++) {
                                let obj = this.form.cabientList[i];
                                this.obj1 = {};
                                this.obj1 = {
                                    gp: obj.cabinetType,
                                    number: obj.quantity,
                                    price: obj.price,
                                    boxlist: this.boxlist
                                };
                                this.form.gplist.push(this.obj1);
                            }
                            this.updateBoxlist();
                            //回显是否选中的费用
                            if (this.form.selectFeeList) {
                                for (var i = 0; i < this.priceFeeTemplate.length; i++) {
                                    let a = this.form.selectFeeList.split(',');
                                    for (var j = 0; j < a.length; j++) {
                                        if (this.priceFeeTemplate[i].id == a[j]) {
                                            this.isNeedList[i].isNeed = '1';
                                            this.selectPrice(i, '1');
                                        }
                                    }
                                }
                            }
                            this.updateTable();
                            this.caculateTotal();
                        }
                    })

                //打印一下封装的数据

                this.form.pageTitle = e.target.innerText;
                // this.initPriceTemplate();
                // this.form.gplist = [],
                // this.form.gpPrice =  0,
                // this.form.priceExplainContent =  "",


                this.getfbacode(this.form.endPortId);
                this.dialogFormVisible = true;
            },
            async handleEditPdf(row, e) {
                this.lableType = '2';
                this.initBdFeeTypeList();
                this.form.selectPriceList = [];

                //queryById
                await getAction(this.url.queryById + '?id=' + row.id)
                    .then((res) => {
                        if (res.success) {
                            this.form = res.result;
                            this.form.selectPriceList = res.result.feeTemplateList;
                            this.form.gplist = [];
                            this.pricelist = [];
                            //回显柜子集合数据
                            for (var i = 0; i < this.form.cabientList.length; i++) {
                                let obj = this.form.cabientList[i];
                                this.obj1 = {};
                                this.obj1 = {
                                    gp: obj.cabinetType,
                                    number: obj.quantity,
                                    price: obj.price,
                                    boxlist: this.boxlist
                                };
                                this.form.gplist.push(this.obj1);
                            }
                            this.updateBoxlist();
                            //回显是否选中的费用
                            if (this.form.selectFeeList) {
                                for (var i = 0; i < this.priceFeeTemplate.length; i++) {
                                    let a = this.form.selectFeeList.split(',');
                                    for (var j = 0; j < a.length; j++) {
                                        if (this.priceFeeTemplate[i].id == a[j]) {
                                            this.isNeedList[i].isNeed = '1';
                                            this.selectPrice(i, '1');
                                        }
                                    }
                                }
                            }
                            this.updateTable();
                            this.caculateTotal();
                        }
                    })

                //打印一下封装的数据

                this.form.pageTitle = e.target.innerText;
                // this.form.gplist = [],
                // this.form.gpPrice =  0,
                // this.form.priceExplainContent =  "",


                this.getfbacode(this.form.endPortId);
                this.dialogFormVisible = true;
            },
            //绑定单号
            handleOrderNoSave() {
                this.form.orderNoList = this.form.osOrderList.map(function (e, i) {
                    return e.orderNo;
                });
                this.$refs['osOrder'].validate(valid => {
                    if (valid) {
                        postAction(this.url.bindingOrderNo, this.form).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormOrderNo = false;
                                this.handleQuery();
                            }
                        });
                    }
                })

            },
            //点击绑定单号
            handleOrderNoDialog(row) {
                this.orderNoList = [];
                for (var name in row) {
                    this.$set(this.form, name, row[name])
                }
                this.form.osOrderList = [];
                if (row.orderNo) {
                    let list = row.orderNo.split(",");
                    list.map(s => {
                        this.orderNoObj = {};
                        this.$set(this.orderNoObj, 'orderNo', s);
                        this.$set(this.orderNoObj, 'isDisabled', true);
                        this.form.osOrderList.push(this.orderNoObj);
                    })
                }
                this.dialogFormOrderNo = true;
            },
            // 重置查询条件方法
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            //重新报价 点击确定
            handleSave() {
                //获取 柜型的数量 和价格 封装进对应的form对象里面

                //遍历gplist集合
                let cabientList = [];
                this.form.gplist.map(g => {
                    let obj = {cabinetType: g.gp, quantity: g.number, price: g.price};
                    cabientList.push(obj);
                });
                this.form.cabientList = cabientList;
                //判断选择的费用
                let selectFeeList = '';
                let selectFeeListAll = [];
                for (var i = 0; i < this.priceFeeTemplate.length; i++) {
                    if (this.isNeedList[i].isNeed == '1') {
                        selectFeeListAll.push(this.priceFeeTemplate[i].id);
                    }
                }
                this.form.selectFeeList = selectFeeListAll.join(',');
                this.form.totalFee = 'USD:' + this.totalUsd + ",CNY:" + this.totalOther;

                if (this.form.selectPriceList) {
                    let list = [];
                    this.form.selectPriceList.forEach(f => {
                        let {count, cur, feeId, price} = f;
                        let obj = {count, cur, feeId, price};
                        list.push(obj);
                    });
                    this.$set(this.form, 'feeTemplateList', list);

                }
                //打印一下封装的数据
                let resultArr = [];
                let formArr = [];
                formArr.push('pdfDom', 'gpform', 'feeform')
                formArr.forEach(item => {
                    resultArr.push(this.checkForm(item))
                })
                let _self = this;
                Promise.all(resultArr).then(function () {
                    postAction(_self.url.editPrice, _self.form).then((res) => {
                        if (res.success) {
                            _self.$message.success(res.message);
                            //关闭弹窗
                            _self.dialogFormVisible = false;
                            _self.handleQuery();
                        }
                    });
                })
            },
            //多个表单校验
            async checkForm(formName) {
                var _self = this;
                var result = await new Promise(function (resolve, reject) {
                    _self.$refs[formName].validate((valid) => {
                        if (valid) {
                            resolve();
                        } else {
                            reject()
                        }
                    })
                })
                return result;
            },
            handleDele(val) {
                this.form = {};
                this.dialogFormVisibleEmail = true;
            },
            // 发送邮件
            sendEmail() {
              /*  if (this.form.emails.includes(",")) {
                    this.form.emails = this.form.emails.split(',');
                }else {
                  this.form.email = this.form.emails;
                  this.form.emails =[];
                }

                if (this.form.usernames.includes(",")) {
                    this.form.usernames = this.form.usernames.split(',');
                }else {
                  this.form.username = this.form.usernames;
                  this.form.usernames =[];
                }*/
                postAction(this.url.sendEmail, this.form)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            //关闭弹窗
                            this.dialogFormVisibleEmail = false;
                            this.handleQuery()
                        }
                    })
            },
            //获取未绑定的单号
            initOrderNo() {
                this.noOrderNoList = [];
                postAction(this.url.getOrderNo, this.form)
                    .then((res) => {
                        if (res.success) {
                            this.noOrderNoList = res.result;
                        }
                    })
            },
        },
        created() {
            this.initPriceTemplate();
            //初始化港口基础数据
            this.initBdPortList();
            //初始化报价说明类型
            this.queryForm.initPriceExplain = true;
            for (var i = 0; i < this.isNeedList.length; i++) {
                this.selectPrice(i, this.isNeedList[i].isNeed);
            }
        },

    };
</script>

<style lang="scss">
    .el-table__header, .el-table__body {
        width: 100% !important;
    }

    .ql-tooltip {
        z-index: 1;
    }

    .ql-editor {
        height: 200px;
    }
    .lable_title{
        font-size: 18px !important;
    }
</style>
