<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row type="flex" justify="start">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="港口中文">
                            <el-input v-model="queryForm.cnName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="港口英文">
                            <el-input v-model="queryForm.enName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家">

                            <el-select
                                    clearable
                                    filterable
                                    v-model="queryForm.countryNum"
                                    @click.native="initCountryList">
                                <el-option
                                        v-for="(item,index) in countrylist"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="港口三字码">
                            <el-input v-model="queryForm.portThree" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="type" label="是否启用">
                            <el-radio-group v-model="queryForm.datalevel" @keyup.enter.native="handleSearch">
                                <el-radio :label='"0"'>是</el-radio>
                                <el-radio :label='"1"'>否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
                           v-if="buttonShowList.add"
                >新增
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button>
                        <el-button type="text" @click="handleDele(scope.row)"
                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'datalevel'">
                        <span v-if="scope.row.datalevel === '0'">是</span>
                        <span v-else>否</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑海运港口':'新增海运港口'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="港口编码" :label-width="formLabelWidth" prop="code">
                    <el-input v-model="form.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="英文名" :label-width="formLabelWidth" prop="en_name">
                    <el-input v-model="form.enName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="中文名" :label-width="formLabelWidth" prop="cn_name">
                    <el-input v-model="form.cnName" autocomplete="off"></el-input>
                </el-form-item>
     <!--           <el-form-item label="所属航线编码" :label-width="formLabelWidth" prop="line_code">
                    <el-input v-model="form.lineCode" autocomplete="off"></el-input>
                </el-form-item>-->
           <!--   <el-form-item label="国家" :label-width="formLabelWidth" prop="line_code">
                    <el-input v-model="form.countryName" autocomplete="off"></el-input>
               </el-form-item>-->
              <el-form-item prop="countryName" :label-width="formLabelWidth" label="国家">
                <el-select
                    clearable
                    filterable
                    v-model="form.countryNum"
                    placeholder="获取国家基础数据"
                    @click.native="initCountryList">
                  <el-option
                      v-for="(item,index) in countrylist"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
      <!--        <el-form-item label="国家" :label-width="formLabelWidth" prop="line_code">
                    <el-input v-model="form.countryName" autocomplete="off"></el-input>
               </el-form-item>-->

               <!-- <el-form-item label="所属国家数字代码" :label-width="formLabelWidth" prop="country_num">
                    <el-input v-model="form.countryNum" autocomplete="off"></el-input>
                </el-form-item>-->
                <el-form-item label="港口三字码" :label-width="formLabelWidth" prop="port_three">
                    <el-input v-model="form.portThree" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item  label="是否启用" :label-width="formLabelWidth" prop="datalevel">
                    <el-radio-group v-model="form.datalevel">
                        <el-radio :label='"0"'>是</el-radio>
                        <el-radio :label='"1"'>否</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="排序" :label-width="formLabelWidth" prop="sort_num">
                  <el-input v-model="form.sortNum" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {deleteAction, getallcountries, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
import {zhengshuPattern} from "@/utils/pattern";
    //import {mobilePattern} from '@/utils/pattern'

    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                    /*    name: [
                            {required: true, message: '请输入进口商名称', trigger: 'change'},
                            {max: 255, message: '长度不能超过255', trigger: 'change'},

                        ],
                        vat: [
                            {required: true, message: '请输入VAT', trigger: 'change'},
                            {max: 50, message: '长度不能超过50', trigger: 'change'},
                        ],
                        tax: [
                            {required: true, message: '请输入税号', trigger: 'change'},
                            {max: 50, message: '长度不能超过50', trigger: 'change'},
                        ],
                        eori: [
                            {required: true, message: '请输入欧洲线', trigger: 'change'},
                            {max: 50, message: '长度不能超过50', trigger: 'change'},
                        ],
                        country: [
                            {required: true, message: '请选择选择国家', trigger: 'change'},
                        ],
                        continent: [
                            {required: true, message: '请选择州/省', trigger: 'change'},
                        ],
                        city: [
                            {required: true, message: '请选择城市', trigger: 'change'},
                        ],
                        address: [
                            {required: true, message: '请输入地址', trigger: 'change'},
                            {max: 255, message: '长度不能超过255', trigger: 'change'},
                        ],
                        contact: [
                            {required: true, message: '请输入联系人', trigger: 'change'},
                            {max: 50, message: '长度不能超过50', trigger: 'change'},
                        ],
                        tel: [
                            {required: true, message: '请输入联系电话', trigger: 'change'},
                            {pattern: mobilePattern, message: '手机号格式有误', trigger: 'change'},
                        ],
                        startTime: [
                            {required: true, message: '请选择时间', trigger: 'change'},
                        ],
                        endTime: [
                            {required: true, message: '请选择时间', trigger: 'change'},
                        ],
                        nature: [
                            {required: true, message: '请选择性质', trigger: 'change'},
                        ],*/

                    sortNum: [
                      {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'},
                    ],
                },
                countrylist:[],
                citylist:[],
                statelist:[],
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'code',
                        label: '港口编码',
                        /*sortable: 'custom',*/

                    },
                    {
                        prop: 'enName',
                        label: '英文名',
                        /*sortable: 'custom',*/

                    },
                    {
                        prop: 'cnName',
                        label: '中文名',
                        /*sortable: 'custom',*/

                    },
                    {
                      prop: 'countryName',
                      label: '国家',
                      /*sortable: 'custom',*/
                    },
       /*             {
                        prop: 'countryNum',
                        label: '所属国家数字代码',
                        /!*sortable: 'custom',*!/
                    },*/
                  /*  {
                        prop: 'lineCode',
                        label: '所属航线编码',
                        /!*sortable: 'custom',*!/
                    },*/
                    {
                        prop: 'portThree',
                        label: '港口三字码',
                        // sortable: 'custom',

                    },
                    {
                        prop: 'datalevel',
                        label: '是否启用',
                        // sortable: 'custom',

                    },
                ],
                url: {
                    list: '/sys/bdPort/list',
                    add: '/sys/bdPort/add',
                    edit: '/sys/bdPort/edit',
                    getById: '/sys/bdPort/queryById',
                    delete: '/sys/bdPort/delete',
                },
            }
        },
        methods: {
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除该海运港口？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },


          initCountryList(){
            if (this.countrylist.length <=0){
              getallcountries().then(res=>{
                if (res.success){
                  for (var i=0;i<res.result.length;i++){
                    let obj = {};
                    obj.value = res.result[i].countryNum;
                    obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                    this.countrylist.push(obj);
                    this.recMap = {};
                    this.recMap = this.listToMap(res.result, "id");

                  }
                }
              })
            }

          },


        }, created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/HyPort','0','新增'),
            'bj':this.$btnIsShow('isystem/BaseData/HyPort','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/HyPort','1','删除'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
