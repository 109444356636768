<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="用车编号">
                <el-input v-model="queryForm.takeNo" placeholder="多个单号需要完整单号" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="订单号">
                <el-input v-model="queryForm.orderNo" type="textarea" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="交货仓库">
                <f-select :dict="'delivery_point'" v-model="queryForm.warehouseIdList" :multiple="true"
                          :isNeed="queryForm.warehouseIdList" @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <!--                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">-->
            <!--                            <el-form-item label="中转仓库">-->
            <!--                                <f-select :dict="'delivery_point'" v-model="queryForm.transfer" :multiple="true"-->
            <!--                                          :isNeed="queryForm.transfer" @keyup.enter.native="handleSearch"></f-select>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="用车状态">
                <f-select :dict="'warehouse_take_order_status'" v-model="queryForm.statusList" :multiple="true"
                          :isNeed="queryForm.statusList" @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="派车编号">
                <el-input v-model="queryForm.truckingNo" @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="供应商">
                <f-select :dict="'supplier_id'" v-model="queryForm.supplierIdList" :multiple="true"
                          :isNeed="queryForm.supplierIdList" @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
              <el-form-item label="提货时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.takeTimeS"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.takeTimeE"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
<!--            类型 1 提货 2 转仓 3 新的转仓 4 退仓-->
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="类型">
               <el-select v-model="queryForm.takeNoTypeList" clearable filterable multiple>
                 <el-option value="1" label="提货"></el-option>
                 <el-option value="2" label="转仓"></el-option>
                 <el-option value="3" label="新转仓"></el-option>
                 <el-option value="4" label="退仓"></el-option>
               </el-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="分摊次数">
               <el-input v-model="queryForm.splitTimes" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                         type="number"></el-input>
              </el-form-item>
            </el-col>
             <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="渠道">
                            <f-select v-model="queryForm.channelIds"
                                      :isNeed="queryForm.channelIds"
                                      placeholder="请选择" :dict="'transportStatus'" :multiple="true">
                            </f-select>
                        </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>


        </el-card>
      </el-form>
      <div class="bbb">
        <el-button
            type="primary"
            size="medium"
            @click="handleDispatch('1',null)"
            class="margin_right_10"
            :disabled="selectedRowKeys.length === 0"
            v-if="buttonShowList.paiB">批量派车
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleDispatch('2',null)"
            class="margin_right_10"
            :disabled="selectedRowKeys.length === 0"
            v-if="buttonShowList.paiC">派车完成
        </el-button>

        <el-button
            type="primary"
            size="medium"
            @click="handleDispatch('4',null)"
            class="margin_right_10"
            :disabled="selectedRowKeys.length === 0"
            v-if="buttonShowList.sdCB">批量手动完成
        </el-button>

        <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadExcelUrl,'仓储部提货数据')"
                   v-if="buttonShowList.dc">导出
        </el-button>

                <el-button
                    type="primary"
                    size="medium"
                    @click="handleSpiltTakeFee"
                    class="margin_right_10"
                    :disabled="selectedRowKeys.length === 0"
                    v-if="buttonShowList.fenTakeFee">分摊费用
                </el-button>
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleDispatch('9',scope.row)"
                       v-if="buttonShowList.bz && scope.row.status !== '0'">
              提货备注
            </el-button>
            <el-button type="text" @click="handleDispatch('7',scope.row)"
                       v-if="buttonShowList.qx && ['1','2','3'].indexOf(scope.row.status) !== -1 ">
              取消
            </el-button>
            <el-button type="text" @click="handleDispatch('1',scope.row)"
                       v-if="buttonShowList.pai && scope.row.status === '1' ">
              派车
            </el-button>
            <el-button type="text" @click="handleDispatch('2',scope.row)"
                       v-if="buttonShowList.completePai && scope.row.status === '2' ">
              完成派车
            </el-button>
            <el-button type="text" @click="handleDispatch('add',scope.row)"
                       v-if="buttonShowList.addP && ['2','3'].indexOf(scope.row.status) !== -1 ">
              加派
            </el-button>
            <el-button type="text" @click="handleDispatch('4',scope.row)"
                       v-if="buttonShowList.sdComplete && scope.row.status === '3' ">
              手动完成
            </el-button>

            <el-button type="text" @click="handleDispatch('10',scope.row)"
                       v-if="buttonShowList.xgTakeFee">
              编辑用车信息
            </el-button>
            <el-button type="text" @click="copyTakeInfo(scope.row)">
              复制调度信息
            </el-button>
           
          </template>

          <template v-else-if="scope.column.property === 'takeNo'">
            <a style="color: #00a0e9" @click.prevent="handleDispatch('11',scope.row)" v-if="scope.row.takeNo.indexOf('ZC')!==-1">{{
                scope.row.takeNo
              }}</a>
            <a style="color: #00a0e9" @click.prevent="handleDispatch('12',scope.row)" v-else-if="scope.row.takeNo.indexOf('TC')!==-1">{{
                scope.row.takeNo
              }}</a>
            <a style="color: #00a0e9" @click.prevent="handleDispatch('8',scope.row)" v-else>{{
                scope.row.takeNo
              }}</a>
          </template>
          <template v-else-if="scope.column.property === 'statusStr'">
            <span :style="getStatusColor(scope.row.statusStr)" style="color: #00b7ee">{{ scope.row.statusStr }}</span>
          </template>

          <template v-else-if="scope.column.property === 'orderNo'">
            <el-collapse v-model="activeNames"  v-if="scope.row.innerDTOS && scope.row.innerDTOS.length > 1" class="orderNo_collapse">
                   <el-collapse-item :title="scope.row.innerDTOS[0].orderNo + scope.row.innerDTOS[0].ctnStr + scope.row.innerDTOS[0].channelName" :name="scope.row.id">
                     <span v-for="(i,d) in scope.row.innerDTOS" :key="d">
                           <a style="color: #00a0e9;" @click.prevent="$intoDocPage(i.orderNo)">{{
                               i.orderNo
                             }}</a>
                          <span style="margin-left: 5px;">
                            {{ i.ctnStr }}
                          </span>
                          <br>
                          <span >{{
                              i.channelName
                             }}</span>
                          <br>
                        </span>
                         <template #title>
                            <a style="color: #00a0e9;">{{
                             scope.row.innerDTOS[0].orderNo
                             }}</a>
                           <span style="margin-left: 5px;">
                            {{  scope.row.innerDTOS[0].ctnStr }}
                          </span>
                           <span style="margin-left: 5px;">{{
                              scope.row.innerDTOS[0].channelName
                             }}</span>
      </template>
  </el-collapse-item>
            </el-collapse>
            <div v-else>
              <span v-for="(i,d) in scope.row.innerDTOS" :key="d">
                           <a style="color: #00a0e9;" @click.prevent="$intoDocPage(i.orderNo)">{{
                               i.orderNo
                             }}</a>
                          <span style="margin-left: 5px;">
                            {{ i.ctnStr }}
                          </span>
                          <br>
                          <span >{{
                              i.channelName
                             }}</span>
                          <br>
                        </span>
            </div>

             
                       
          </template>
          <template v-else-if="scope.column.property === 'takeFeeStr'">
            <span v-html="scope.row.takeFeeStr"></span>
          </template>
          <template v-else-if="scope.column.property === 'address'">
            <span v-if="scope.row.type === '1'">
              公司：<span>{{scope.row.shipToCompany}}</span> <br>
              地址：<span>{{scope.row.shipToAddress}}</span> <br>
              联系人：<span>{{scope.row.shipToContact}}</span> <br>
              电话：<span>{{scope.row.shipToPhone}}</span>
            </span>
            <span v-else>{{scope.row.address}}</span>
          </template>
          <template v-else-if="scope.column.property === 'warehouseName'">
            <span v-if="scope.row.type === '4'">
              公司：<span>{{scope.row.shipToCompany}}</span> <br>
              地址：<span>{{scope.row.shipToAddress}}</span> <br>
              联系人：<span>{{scope.row.shipToContact}}</span> <br>
              电话：<span>{{scope.row.shipToPhone}}</span>
            </span>
            <span v-else>{{scope.row.warehouseName}}</span>
          </template>
           <!-- <template v-else-if="scope.column.property === 'channelName'">
             <div v-for="(item,index) in scope.row.innerDTOS" :key="index">
                           <span >{{
                              item.channelName
                             }}</span>
                          <br>
             </div>
          </template> -->
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <!--        <add-warehouse-receipts :visible.sync="dialogFormVisible" :orderNos="selectedIds" @ok="handleSearch"></add-warehouse-receipts>-->
    <dispatch-module :visible.sync="dispatchVisisble" :takeIds="selectedIds" @ok="handleSearch"></dispatch-module>
    <dispatch-to-complete-module :visible.sync="dispatchToCompleteVisible" :takeIds="selectedIds"
                                 @ok="handleSearch" :title="dispatchToCompleteTitle"></dispatch-to-complete-module>
    <jiapai :visible.sync="jiapaiVisible" :takeIds="selectedIds"
                                 @ok="handleSearch" :title="dispatchToCompleteTitle"></jiapai>
    <edit-driver-module :visible.sync="editDriverVisible" :takeIds="selectedIds" :hiDriverInfo="hiDriverInfo"
                        @ok="closeDriver"></edit-driver-module>
    <copy-take-order-module :visible.sync="copyTakeOrderVisible" :takeIds="copyTakeId"
                            @ok="handleSearch"></copy-take-order-module>
    <edit-take-fee-module :visible.sync="editTakeFeeVisible" :takeIds="selectedIds" :takeFee="selectedTakeFee"
                          @ok="handleSearch"></edit-take-fee-module>
    <edit-take-order-module :visible.sync="editTakeOrderVisible" :takeIds="selectedIds" :hiDriverInfo="hiDriverInfo"
                            @ok="handleSearch"></edit-take-order-module>
    <take-order-detail ref="takeOrderDetail" :visible.sync="takeOrderDetailVisible" :takeIds="selectedIds" @ok="closeTakeDetail"></take-order-detail>
    <zc-order-detail ref="zcOrderDetail" :visible.sync="zcOrderDetailVisible" :takeIds="selectedIds" @ok="closezcDetail" :row='selectedzcRow'></zc-order-detail>
    <tc-order-detail ref="tcOrderDetail" :visible.sync="tcOrderDetailVisible" :takeIds="selectedIds" @ok="closetcDetail"></tc-order-detail>
    <edit-tihuo-remark-dialog :visible.sync="editTihuoRemarkVisible" :takeIds="selectedIds"
                              @ok="handleSearch"></edit-tihuo-remark-dialog>
    <split-take-fee-result-dialog :visible="splitVisible" :split-vo="splitVo" @ok="closeSplit"></split-take-fee-result-dialog>
    <shoudong-complete :visible="shoudongCompleteVisible"  @ok="closeShoudong" :take-ids="selectedIds" :hiDriverInfo="hiDriverInfo"></shoudong-complete>
  </div>
</template>

<script>
// import AddWarehouseReceipts from "@/views/warehouse2.0/modules/AddWarehouseReceipts";
import QueryMixins from "@/mixins/QueryMixins.js";
import {getAction, postAction} from "@/api/manage";
import dispatchModule from "@/views/warehouse2.0/modules/dispatchModule";
import dispatchToCompleteModule from "@/views/warehouse2.0/modules/dispatchToCompleteModule";
import editDriverModule from "@/views/warehouse2.0/modules/editDriverModule";
import copyTakeOrderModule from "@/views/warehouse2.0/modules/copyTakeOrderModule";
import editTakeFeeModule from "@/views/warehouse2.0/modules/editTakeFeeModule";
import editTakeOrderModule from "@/views/warehouse2.0/modules/editTakeOrderModule";
import takeOrderDetail from "@/views/warehouse2.0/modules/takeOrderDetail";
import editTihuoRemarkDialog from "@/views/warehouse2.0/modules/editTihuoRemarkDialog";
import splitTakeFeeResultDialog from "./modules/splitTakeFeeResultDialog";
import zcOrderDetail from "./modules/zcOrderDetail.vue";
import shoudongComplete from "./modules/shoudongComplete.vue";
import tcOrderDetail from "./modules/tcOrderDetail.vue";
import Jiapai from "./modules/jiapai.vue";
export default {
  name: "WarehouseTakeOrder",
  mixins: [QueryMixins],
  components: {
    Jiapai,
    dispatchModule,
    dispatchToCompleteModule,
    editDriverModule,
    copyTakeOrderModule,
    editTakeFeeModule,
    editTakeOrderModule,
    takeOrderDetail,
    editTihuoRemarkDialog,
    splitTakeFeeResultDialog,
    zcOrderDetail,
    shoudongComplete,
    tcOrderDetail
  },
  data() {
    return {
      loading: false,
      isUsePost: true,
      dialogFormVisible: false,
      jiapaiVisible:false,
      formLabelWidth: "120px",
      //表单校验
      visible: false,
      //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
      columns: [
        {
          type: "selection",
        },
        {
          prop: "action",
          label: "操作",
          width: 130
        },
        {
          prop: "typeText",
          label: "类型",
        },
        {
          prop: "takeNo",
          label: "用车编号",
          width: 130
        },
        {
          prop: 'orderNo',
          label: '订单号-件重体-渠道',
          minWidth: 350
        },
  // {
  //         prop: 'channelName',
  //         label: '渠道',
  //         minWidth: 200
  //       },
        {
          prop: "ctnStr",
          label: "总件重体",
          minWidth: 100,
        },
        {
          prop: "remark",
          label: "提货备注",
          minWidth: 220
        },
        {
          prop: "address",
          label: "装车地址",
          minWidth: 200
        },

        {
          prop: "warehouseName",
          label: "送达地址",
          minWidth: 200
        },
        // {
        //     prop: "transfer",
        //     label: "中转仓库",
        // },
        {
          prop: "statusStr",
          label: "用车状态",
        },
          {
          prop: "supplier",
          label: "供应商",
        },
        {
          prop: "takeFee",
          label: "提货费",
        },
         {
          prop: "driverInfo",
          label: "司机信息",
          minWidth: 200
        },
        {
          prop: "truckingNo",
          label: "派车编码",
        },
        {
          prop: "outNo",
          label: "出库编码",
        },

        {
          prop: "takeTime",
          label: "提货时间",
        },
        {
          prop: "arriveAtWarehouseTime",
          label: "到达仓时间",
        },
        {
          prop: "truckingTime",
          label: "派车时间",
        },
        {
          prop: "truckCompleteTime",
          label: "派车完成时间",
        },
        {
          prop: "takeFeeStr",
          label: "提货费分摊",
        },
        {
          prop: "splitTimes",
          label: "分摊次数",
        },
       


      ],
      url: {
        list: "/warehouse/takeDelivery/list",
        batchUpdateUrl: "/warehouse/takeDelivery/batchUpdate",
        downloadExcelUrl: "/file/export/excel/downloadWarehouseTakeDeliveryEntityExcel",
        addOrDelOrderRelationUrl: "/warehouse/takeDelivery/addOrDelOrderRelation",
        update: "/warehouse/takeDelivery/update",
        //判断是否允许修改
        checkCanUpdate: '/warehouse/takeDelivery/checkCanUpdate',
        addTakeFeeByIds: '/warehouse/takeDelivery/addTakeFeeByIds',


      },
      selectedIds: '',
      hiDriverInfo: '',
      selectedTakeFee: 0,
      copyTakeId: '',
      //派车弹框是否显示
      dispatchVisisble: false,
      //派车完成弹框是否显示
      dispatchToCompleteVisible: false,
      //修改司机信息
      editDriverVisible: false,
      //复制提货信息弹框是否显示
      copyTakeOrderVisible: false,
      //手动完成弹框是否显示
      manualCompleteVisible: false,
      //编辑提货费弹框是否显示
      editTakeFeeVisible: false,
      //编辑提货弹框是否显示
      editTakeOrderVisible: false,
      //提货详情弹框是否显示
      takeOrderDetailVisible: false,
      zcOrderDetailVisible: false,
      tcOrderDetailVisible: false,
      //提货备注 是否显示
      editTihuoRemarkVisible: false,
      splitVisible: false,
      splitVo: {},
      shoudongCompleteVisible:false,
      shoudongCompleteIds:'',
      dispatchToCompleteTitle:'派车完成',
      selectedzcRow:{},
      activeNames:"",
    };
  },
  methods: {
    copyTakeInfo(row){
      if (row.takeNo.indexOf("ZC")!==-1) {
        this.$refs['zcOrderDetail'].copyInfo(row.id);
      }else if (row.takeNo.indexOf('TC')!==-1) {
        this.$refs['tcOrderDetail'].copyInfo(row.id);
      }else {
        this.$refs['takeOrderDetail'].copyInfo(row.id);
      }
    },
    closeShoudong(){
      this.shoudongCompleteVisible = false;
      this.handleSearch();
    },
    closeDriver(){
      this.editDriverVisible = false;
      this.handleSearch();
    },
    closeTakeDetail() {
      this.takeOrderDetailVisible = false;
    },
    closetcDetail() {
      this.tcOrderDetailVisible = false;
    },
    closezcDetail() {
      this.zcOrderDetailVisible = false;
    },
    closeSplit() {
      this.splitVisible = false;
    },
    //根据状态 获取颜色
    getStatusColor(status) {
      let str = 'color: ';
      let color = '#571212';
      if (status === '待派车') {
        color = '#00b7ee';
      } else if (status === '派车中') {
        color = '#ee6300';
      } else if (status === '提货中') {
        color = '#4b00ee';
      } else if (status === '已完成') {
        color = '#08ff00';
      }
      return str + color;
    },

    //批量派车
    handleDispatch(type, row) {
      let hasOne = false;
      let hasTwo = false;
      let hasThree = false;
      let hasFour = false;
      let hasZero = false;

      if (!row && this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        this.selectedIds = this.selectedRowKeys.join(',');
        this.selectionRows.forEach((item) => {
          if (item.status === '1') {
            hasOne = true;
          }
          if (item.status === '2') {
            hasTwo = true;
          }
          if (item.status === '3') {
            hasThree = true;
          }
          if (item.status === '4') {
            hasFour = true;
          }
          if (item.status === '0') {
            hasZero = true;
          }
        });

      } else if (row) {
        this.selectedIds = row.id || '';
      }

      if (hasZero) {
        this.$message.warning("已经取消的订单不允许进行批量操作");
        return false;
      }
      if (type === '1' || type === '2' || type === '4' || type === 'add') {
        //如果是 派车 批量派车 派车完成 的操作 判断是否有 单号
        getAction(this.url.checkCanUpdate, {takeIds: this.selectedIds, updateType: '1'})
            .then(res => {
              if (type === '1') {
                if (hasTwo) {
                  this.$message.warning("派车中的订单不允许进行批量派车操作");
                  return false;
                }
                if (hasThree) {
                  this.$message.warning("提货中的订单不允许进行批量派车操作");
                  return false;
                }
                if (hasFour) {
                  this.$message.warning("已完成的订单不允许进行批量派车操作");
                  return false;
                }
                this.dispatchVisisble = true;
                //批量派车
              } else if (type === '2') {
                //完成派车
                if (hasOne) {
                  this.$message.warning("待派车的订单不允许进行派车完成操作");
                  return false;
                }
                if (hasThree) {
                  this.$message.warning("提货中的订单不允许进行派车完成操作");
                  return false;
                }
                if (hasFour) {
                  this.$message.warning("已完成的订单不允许进行派车完成操作");
                  return false;
                }
                this.dispatchToCompleteTitle = '派车完成';
                this.dispatchToCompleteVisible = true;
              } else if (type === 'add') {
                //加派
                if (hasOne) {
                  this.$message.warning("待派车的订单不允许进行加派操作");
                  return false;
                }
                if (hasThree) {
                  this.$message.warning("提货中的订单不允许进行加派操作");
                  return false;
                }
                if (hasFour) {
                  this.$message.warning("已完成的订单不允许进行加派操作");
                  return false;
                }
                this.dispatchToCompleteTitle = '加派';
                this.jiapaiVisible = true;
              }else if (type === '4') {
                //批量手动完成
                if (hasOne) {
                  this.$message.warning("待派车的订单不允许进行手动完成操作");
                  return false;
                }
                if (hasFour) {
                  this.$message.warning("已完成的订单不允许进行手动完成操作");
                  return false;
                }
                if (hasTwo) {
                  this.$message.warning("派车中的订单不允许进行手动完成操作");
                  return false;
                }

                this.shoudongCompleteVisible = true;
                // this.manualCompleteVisible = true;
              }
            })
      }
      if (type === '3') {
        //复制提货信息

        this.copyTakeId = this.selectedRowKeys[0];
        this.copyTakeOrderVisible = true;
      } else if (type === '5') {
        //修改提货费
        this.selectedTakeFee = row.takeFee || 0;
        this.editTakeFeeVisible = true;
      } else if (type === '6') {
        //编辑
        this.editTakeOrderVisible = true;
      } else if (type === '7') {
        //取消
        postAction(this.url.update, {id: row.id, isCancel: 1}).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleSearch();
          }
        });

      } else if (type === '8') {
        //查看详情
        this.takeOrderDetailVisible = true;
      } else if (type === '9') {
        //编辑提货备注
        this.editTihuoRemarkVisible = true;
      } else if (type === '10') {
        //修改司机信息
        this.hiDriverInfo = row.driverInfo || '';
        this.editDriverVisible = true;
      } else if (type === '11') {
        //查看详情
        this.selectedzcRow=row
        this.zcOrderDetailVisible = true;
      }else if (type === '12') {
        //查看详情
        this.tcOrderDetailVisible = true;
      }


      // postAction(this.url.batchUpdateUrl, obj).then((res)=>{
      //     if(res.success){
      //         this.$message.success(res.message)
      //         this.$emit('ok')
      //     }else{
      //         this.$message.error(res.message)
      //     }
      // })

    },
    handleSpiltTakeFee() {
      this.$confirm(`确定分摊费用，将会预览生成的应付和成本，点击确定之后生成费用`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).then(res => {
        postAction(this.url.addTakeFeeByIds, this.selectedRowKeys)
            .then(res => {
              this.splitVo = res.result;
              this.splitVisible = true;
              this.handleQuery();
            })
      })
    },


  },
  computed:{
    buttonShowList() {
      return {
        'xgTakeFee':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','修改提货费'),
        'sdComplete':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','手动完成'),
        'addP':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','加派'),
        'completePai':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','完成派车'),
        'pai':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','派车'),
        'qx':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','取消'),
        'bz':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','1','提货备注'),
        'fenTakeFee':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','0','分摊提货费'),
        'dc':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','0','导出'),
        'sdCB':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','0','批量手动完成'),
        'paiC':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','0','派车完成'),
        'paiB':this.$btnIsShow('warehouse2.0/warehouseTakeOrder','0','批量派车'),
      }
    }
  }
};
</script>


<style lang="scss">
.orderNo_collapse {
  border-top: none !important;
  border-bottom: none !important;
  .el-collapse-item__header {
     border-bottom: none !important;
  }
  // .el-collapse-item__arrow {
  //   display: none !important;
  // } 
}
</style>
<style lang="scss" scoped>
.bbb {
  display: flex;
}


.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
