<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div style="display:flex;">
                <el-card class="box-card">
                    <el-row :gutter="24" class="inputs_row">
                        <el-col :md="4" :sm="24">
                            <el-form-item label="业务员">
                                <el-input
                                    class="textarea_table"
                                    v-model="queryForm.salesman"
                                    @keyup.enter.native="handleSearch"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :md="4" :sm="24">
                            <el-form-item label="客户名">
                                <el-input
                                    class="textarea_table"
                                    v-model="queryForm.customer"
                                    @keyup.enter.native="handleSearch"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="4" :sm="24">
                            <el-form-item label="订单号">
                                <el-input
                                    class="textarea_table"
                                    v-model="queryForm.orderNo"
                                    @keyup.enter.native="handleSearch"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="4">
                            <el-button
                                size="medium"
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button
                                size="medium"
                                type="primary"
                                @click="handleReset"
                                icon="reload"
                                style="margin-left: 8px"
                            >重置
                            </el-button
                            >
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </el-form>
        <el-card class="box-card last_card">
            <div>
                <el-button type="primary" size="medium" @click="exportShipCountFeeNumVoByConditon()">导出</el-button>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="65vh"
                :isRecordTableScroll="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>

<script>
import QueryMixins from "../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import {setUpRouteUtil} from '@/utils/util';

export default {
    name: "Potential",
    mixins: [QueryMixins],
    data() {
        return {
            dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'pay_type','pglx'],
            ids: [],
            row: {},
            form: {},
            formLabelWidth: "100px",
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "orderNo",
                    label: "订单号",
                },
                {
                    prop: "customer",
                    label: "客户名",
                },
                {
                    prop: "salesman",
                    label: "业务员",
                },
                {
                    prop: "doc",
                    label: "客服",
                },
                {
                    prop: "realKg",
                    label: "过磅重",
                },
                {
                    prop: "realCmb",
                    label: "实际体积",
                },
                {
                    prop: "volumeWeight",
                    label: "体积重",
                },
                {
                    prop: "realCtn",
                    label: "实际数量",
                },
                {
                    prop: "countFeeNum",
                    label: "计费数量",
                },
                {
                    prop: "num",
                    label: "海运费数量",
                },
                {
                    prop: "cRemark",
                    label: "备注",
                    minWidth: 100,
                },
            ],
            url: {
                list: "finance/fee/settleCommission/getShipCountFeeNumVoByConditon",
                download:'/file/export/excel/exportShipCountFeeNumVoByConditon',

            },
            isUsePost:true,
        };
    },
    methods: {
        intoDocpage(orderNo) {
            // 对于路由不在左侧菜单中的选中菜单处理
            // setUpRouteUtil(this.$route.path, '/docpage/Edit');
            this.$intoDocPage(orderNo);
            // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
        },

        handleReset() {
            this.queryForm = {};
            this.filterForm = {};
            this.ipagination.currentPage = 1;
            this.queryForm.isWhether='0'
            this.queryForm.profitRecheck='1'
            if (this.$refs['GTable']){
                this.$refs['GTable'].handleReset();
            }
            this.handleQuery();
        },


        exportShipCountFeeNumVoByConditon() {

            let filename = this.getDateStr() + '导出已开船订单异常表'

            postAction(this.url.download, this.queryForm, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if (res.size === 0) {
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        this.visible = false
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象

                    this.visible = false
                    setTimeout(() => {   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        this.handleCheck();
                    }, 3000)
                })
        },
    },
    created() {
        this.queryForm.isWhether='0'
        this.queryForm.profitRecheck='1'
    },
};
</script>

<style lang="scss" scoped>
</style>
