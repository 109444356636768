<template>
  <el-dialog
    :title="row.id ? '编辑船公司' : '新增船公司'"
    :visible="visible"
    width="560px"
    custom-class="c-custom-dialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      size="medium"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item prop="shortname" label="船公司代码">
            <el-input
              v-model="form.shortname"
              placeholder="请输入船公司代码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="englishName" label="船公司英文名">
            <el-input
              v-model="form.englishName"
              placeholder="请输入船公司英文名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="chinaseName" label="船公司中文名">
            <el-input
              v-model="form.chinaseName"
              placeholder="请输入船公司中文名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="abbreviation" label="船公司简称">
            <el-input
              v-model="form.abbreviation"
              placeholder="请输入船公司简称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="officialWebsite" label="官方网站">
            <el-input
              v-model="form.officialWebsite"
              placeholder="请输入官方网站"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="goodsTracking" label="货物跟踪">
            <el-input
              v-model="form.goodsTracking"
              placeholder="请输入货物跟踪"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="introduction" label="船公司简介">
            <el-input
              v-model="form.introduction"
              placeholder="请输入船公司简介"
              type="textarea"
              rows="2"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="companyAddress" label="公司地址">
            <el-input
              v-model="form.companyAddress"
              placeholder="请输入公司地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确定</el-button
      >
      <!--            <el-button type="primary" size="medium" @click="makePinyin">确定2</el-button>-->
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from "@/api/manage";
import pinyin from "js-pinyin";
import { updatedDict } from '@/utils/util';
export default {
  name: "AddRoleDialog",
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      rules: {
        shortname: [
          { required: true, message: "请输入船公司代码", trigger: "change" },
        ],
        companyAddress: [
          { max: 100, message: "公司地址最多填写255个字符！", trigger: "change" },
        ],
        abbreviation: [
          { max: 100, message: "简称最多填写255个字符！", trigger: "change" },
        ],
        officialWebsite: [
          { max: 100, message: "官方网站最多填写255个字符！", trigger: "change" },
        ],
        goodsTracking: [
          { max: 100, message: "货物跟踪最多填写255个字符！", trigger: "change" },
        ],
        introduction: [
          { max: 100, message: "简介最多填写2000个字符！", trigger: "change" },
        ],
        chinaseName: [
          { required: true, message: "请输入船公司中文名", trigger: "change" },
          { max: 100, message: "船公司中文名最多填写255个字符！", trigger: "change" },
        ],
        englishName: [
          { required: true, message: "请输入船公司英文名", trigger: "change" },
          { max: 100, message: "船公司英文名最多填写255个字符！", trigger: "change" },
        ],
      },
      url: {
        add: "/sys/supplierShippCompany/add",
        edit: "/sys/supplierShippCompany/edit",
      },
    };
  },
  watch: {
    visible(val) {
      if (val && this.row.id) {
        const {
          id,
          shortname,
          englishName,
          chinaseName,
          abbreviation,
          officialWebsite,
          goodsTracking,
          introduction,
          companyAddress,
        } = this.row;
        this.form = {
          id,
          shortname,
          englishName,
          chinaseName,
          abbreviation,
          officialWebsite,
          goodsTracking,
          introduction,
          companyAddress,
        };
      } else {
        this.form = {};
      }
    },
  },
  methods: {
    handleSubmit() {
      let url = this.url.add;
      let method = "POST";
      if (this.row.id) {
        url = this.url.edit;
        method = "PUT";
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          httpAction(url, method, this.form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            //更新数据字典缓存
            updatedDict("ship_company");
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    handleClose() {
      this.$emit("update:visible", false);
      // this.$refs.form.resetFields();
    },
    makePinyin(name) {
      let char = "";
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 });
      char = pinyin.getCamelChars(name);
      return char.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
