<template>
  <el-dialog
    title="材积信息"
    width="1200"
    :visible="visible"
    :bodyStyle="bodyStyle"
    style="top: 0px;"
    @ok="handleAdd"
    @cancel="handleCancel"
    cancelText="关闭"
    okText="保存"

  >

    <split-pane :min-percent='20' :default-percent='50' split="vertical">
      <template slot="paneL" >

        <image-detail  ref="imageFolderModel"></image-detail>
      </template>
      <template slot="paneR">
        <volume-folder ref="volModel"></volume-folder>

      </template>
    </split-pane>

  </el-dialog>
</template>

<script>
  import splitPane from 'vue-splitpane'
  import imageDetail from "./ImageDetail";
  import volumeFolder from "./volume";
  export default {
    name: "ImageModal",
    components:{
      splitPane,
      imageDetail,
      volumeFolder
    },
    data () {
      return {
        visible: false,
        id:'',
        bodyStyle:{
          padding: "0",
          height:(window.innerHeight-150)+"px"
        },
      }
    },

    created () {
      this.modalWidth = window.innerWidth-0;

    },
    methods: {

      show (id) {

        this.visible = true;
        this.id=id
        setTimeout(()=>{
          this.$refs.volModel.volumeDetail(id);
        })

        setTimeout(()=>{
          this.$refs.imageFolderModel.warehouseImage(id);
        })
      },

      handleOk(){

      },
      handleAdd(){
       this.$refs.volModel.save(this.id);
       this.handleCancel()
      },
      handleCancel () {
        this.visible = false;
      },

    },


  }
</script>

<style scoped>
</style>
