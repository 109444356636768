<!--快递待发提成-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :md="4" :sm="24">
                        <el-form-item label="订单号">
                            <el-input
                                    class="textarea_table"
                                    v-model="queryForm.orderNo"
                                    @keyup.enter.native="handleSearch"
                                    type="textarea"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="销账时间">
                            <el-date-picker
                                    class="input time"
                                    v-model="queryForm.settleTime"
                                    type="month"
                                    size="small"
                                    default-time="00:00:00"
                                    placeholder="开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                                    @click.stop.native
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="销售组别">
                      <f-select
                          v-model="queryForm.deptIdList"
                          :dict="'sys_depart'"
                          :isNeed="queryForm.deptIdList"
                          :multiple="true"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('快递报表')">导出
                </el-button>
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="saleEdit">批量更新业务提成
                </el-button>
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="docEdit">批量更新客服提成
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'commission'">
                        <span>{{parseDict(scope.row.commission,'commission')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cusUserType'">
                        <span>{{parseDict(scope.row.cusUserType,'user_type1')}}</span>
                    </template>
                  <template v-else-if="scope.column.property === 'deptId'">
                    <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog customClass="customWidth" :title="'更新提成'" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="单号" :label-width="formLabelWidth" prop="orderNo">
                    <el-input
                            type="textarea"
                            v-model="form.orderNo"
                            maxlength="1000"
                            show-word-limit
                            class="padding_right_area"
                            rows="30"
                            placeholder="请输入工作单号，每行输入一个"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="commissionEdit">确 定</el-button>

            </div>

        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "../../../mixins/QueryMixins";
    import {getAction} from "@/api/manage";
    import {setUpRouteUtil} from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default', 'commission', 'user_type1','sys_depart'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                dialogFormVisible: false,
                rules: {
                    orderNo: [{required: true, message: "请输入单号", trigger: "blur"}],
                },
                columns: [
                    {
                        type: "selection",
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: "salesman",
                        label: "业务",
                        minWidth: 200,
                        fixed: 'left'
                    },
                    {
                      prop: 'deptId',
                      label: '销售组别',
                      minWidth: 200,
                      fixed: 'left'
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 200,
                        fixed: 'left'
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 200,
                        fixed: 'left'
                    },
                    {
                        prop: "endContry",
                        label: "目的国",
                        minWidth: 200,
                    },
                    {
                        prop: "houseCode",
                        label: "仓库代码",
                        minWidth: 200,
                    },
                    {
                        prop: "channel",
                        label: "渠道",
                        minWidth: 200,
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "realKg",
                        label: "收费重量",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "shoukaunTime",
                        label: "确认收款日期",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "deliverGoodsTime",
                        label: "发货时间",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "commission",
                        label: "提成性质",
                        minWidth: 200,
                    },
                    {
                        prop: "cusUserType",
                        label: "客户类型",
                        minWidth: 200,
                    },
                ],
                url: {
                    list: "/order/kdOrderReport/kdList",
                    // exportXlsUrl: "/order/kdOrderReport/kdListExportXls",
                    exportXlsUrl: "/file/kd1/dckdExportExcelVO",
                    commissionGrant: "/order/kdOrderReport/commissionGrant",
                },
                 isUsePost:true
            };
        },
        methods: {

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + orderNo);
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.isWhether = '0'
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },

            saleEdit() {
                this.form = {};
                this.$set(this.form, 'type', '1');
                this.$set(this.form, 'orderNo', this.selectedRowOrderNos.join(","));
                this.dialogFormVisible = true;
            },
            docEdit() {
                this.form = {};
                this.$set(this.form, 'type', '2');
                this.$set(this.form, 'orderNo', this.selectedRowOrderNos.join(","));
                this.dialogFormVisible = true;
            },
            commissionEdit() {
                let name = '';
                if (this.form.type == '1') {
                    name = '业务员'
                } else if (this.form.type == '2') {
                    name = '客服'
                } else {
                    return;
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$confirm('确定 ' + name + ' 提成都发放完成吗?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            console.log("fasfdsafa")
                            const params = {
                              orderNos: this.form.orderNo,
                              type: this.form.type
                            }
                            getAction(this.url.commissionGrant, params)
                                .then((res) => {
                                    console.log("fasfdsafa")
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }).catch(() => {

                        })

                    }
                })

            }
        },
        created() {
            this.queryForm.isWhether = '0'
        },
    };
</script>

<style lang="scss" scoped>
</style>
