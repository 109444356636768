<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :span="4">
            <el-form-item label="客户">
              <el-input v-model.trim="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="快递号">
              <el-input v-model.trim="queryForm.orderNo" placeholder="仅支持单个查询" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="ShipmentId">
              <el-input v-model.trim="queryForm.shipmentId" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="节点">
              <f-select v-model="queryForm.node" :isNeed="queryForm.node" dict="ams_logis_node"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" @click="handleOpen('TrackingId')"
          v-if="buttonShowList.pushTrackingId">推送TrackingId
        </el-button>
        <el-button type="primary" size="medium" @click="handleOpen" :disabled="selectedRowKeys.length == 0"
          v-if="buttonShowList.pushNode">推送节点
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleDel(scope.row)"
              v-if="buttonShowList.del">删除</el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog title="推送TrackingId" :visible.sync="pushTrackingIdVisible" center width="30%"
      :close-on-click-modal="false" :before-close="() => {handleClose('TrackingId')}">
      <el-form :model="formTrackingId" ref="formTrackingId" size="medium" label-width="50px">
        <el-form-item prop="orderNos" label="订单号">
          <el-input v-model.trim="formTrackingId.orderNos" type="textarea" placeholder="多个单号英文逗号分隔"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose('TrackingId')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('TrackingId')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="推送节点" :visible.sync="pushVisible" center width="30%" :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form :model="form" ref="form" size="medium" :rules="rules" label-width="50px">
        <el-form-item prop="node" label="节点">
          <f-select v-model="form.node" :isNeed="form.node" dict="ams_logis_node"></f-select>
        </el-form-item>
        <el-form-item prop="logisEtaDate" label="时间">
          <el-date-picker class="f-flex-1" v-model="form.logisEtaDate" type="datetime" size="small" placeholder="时间"
            default-time="23:59:59" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="handleSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from "@/api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";

  export default {
    name: "DispatchPlace",
    mixins: [QueryMixins],
    components: {
    },
    props: {
      salesMouth: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        pushVisible: false,
        pushTrackingIdVisible: false,
        form: {},
        formTrackingId: {},
        rules: {
          node: [
            { required: true, message: '请选择节点', trigger: 'change' },
          ],
          logisEtaDate: [
            { required: true, message: '请选择时间', trigger: 'change' },
          ],
        },
        isUsePost: true,
        columns: [
          {
            type: "selection",
            width: 50,
          },
          {
            prop: "action",
            label: "操作",
            width: 100
          },
          {
            prop: "orderNo",
            label: "快递号",
          },
          {
            prop: "trackingId",
            label: "TrackingId",
          },
          {
            prop: "shipmentId",
            label: "ShipmentId",
          },
          {
            prop: "result",
            label: "状态",
          },
          {
            prop: "failedMsg",
            label: "失败原因",
          },
          {
            prop: "sendEmailResult",
            label: "是否发送邮件",
          },
          {
            prop: "node",
            label: "节点",
          },
          {
            prop: "fileName",
            label: "文件",
          },
          {
            prop: "type",
            label: "格式",
          },
        ],
        url: {
          list: "/express/test/amsNodePushRecord",
          del: '/express/test/delPushErrorNodeDate',
          pushKdTrackingId: '/express/test/pushKdTrackingId',
          delPushErrorIodeDate: '/express/test/delPushErrorIodeDate',
        },
      };
    },
    created() {
    },
    methods: {
      handleDel(row) {
        this.$confirm("是否确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
          .then(() => {
            getAction(this.url.del, row.id).then(res => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            })
          })
      },
      handleOpen(type) {
        if (type == 'TrackingId') {
          this.pushTrackingIdVisible = true;
        } else {
          this.pushVisible = true;
        }
      },
      handleClose(type) {
        if (type == 'TrackingId') {
          this.formTrackingId = {};
          this.pushTrackingIdVisible = false;
        } else {
          this.form = {};
          this.pushVisible = false;
        }
      },
      handleSubmit(type) {
        let params = {}, url = '', form = '';
        if (type == 'TrackingId') {
          params = this.formTrackingId.orderNos ? { orderNos: this.formTrackingId.orderNos.split(",") } : {};
          form = 'formTrackingId';
          url = this.url.pushKdTrackingId;
        } else {
          params = { orderNo: this.selectedRowOrderNosCheck, node: this.form.node, logisEtaDate: this.form.logisEtaDate };
          form = 'form';
          url = this.url.delPushErrorIodeDate;
        }
        console.log(params, 'params')
        this.$refs[form].validate(valid => {
          if (valid) {
            postAction(url, params).then(res => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            })
          }
        })
      },
    },
    computed:{
      buttonShowList() {
        return {
          'pushTrackingId':this.$btnIsShow('isystem/BaseData/ams','0','推送TrackingId'),
          'pushNode':this.$btnIsShow('isystem/BaseData/ams','0','推送节点'),
          'del':this.$btnIsShow('isystem/BaseData/ams','1','删除'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>