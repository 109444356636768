<!--带入仓-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div style="display:flex;">
                <el-card class="box-card">
                  <el-row :gutter="24" class="inputs_row">
                    <el-col :md="4" :sm="24">
                  <el-form-item label="订单号">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                      <el-col :md="4" :sm="24">
                  <el-form-item label="客户">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.customer"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                      <el-col :md="4" :sm="24">
                  <el-form-item label="销售">
                    <el-input
                        class="textarea_table"
                        v-model="queryForm.salesman"
                        @keyup.enter.native="handleSearch"
                        type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                    <el-form-item label="开船时间" label-width="80px">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.etdTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.etdTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置</el-button
                    >
                    <el-button
                        size="medium"
                        @click="
                  exportExcelPlus(
                    url.downloadSalemanUnCommisstionProfitAuditExportXls,
                    '待审核订单明细'
                  )
                "
                    >导出
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    v-if="buttonShowList.plsh"
                    :disabled="selectedRowKeys.length === 0" @click="dialogFormVisibleBatchAudit = true"
                >批量审核</el-button>
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    v-if="buttonShowList.syncProfit"
                    @click="syncOrderProfit"
                >同步订单利润</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="65vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog title="批量审核" :visible.sync="dialogFormVisibleBatchAudit" :close-on-click-modal="false" append-to-body width="50%" height="100%">
            <el-form :model="form" ref="loseAudit" :rules="rules">
                <el-form-item prop="auditReason" label="亏本订单审核原因">
                    <f-select
                            v-model="form.auditReason"
                            :is-need="form.auditReason"
                            :dict="'lose_money_reason'">
                    </f-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisibleBatchAudit = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="batchAuditLoseMoneyOrders()">确 定</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
    import QueryMixins from "../../../mixins/QueryMixins";
    import {getAction, postAction} from '@/api/manage'
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'pay_type','pglx'],
                ids: [],
                row: {},
                form: {},
                dialogFormVisibleBatchAudit: false,
                formLabelWidth: "100px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                    },
                    {
                        prop: "salesman",
                        label: "销售",
                    },
                    {
                        prop: "customer",
                        label: "客户",
                    },
                    {
                        prop: "realCmb",
                        label: "实际体积",
                    },
                    {
                        prop: "countFeeNum",
                        label: "体积重",
                    },
                    {
                        prop: "profit",
                        label: "利润",
                        minWidth: 100,
                    },
                  {
                    prop: "etdTime",
                    label: "开船时间",
                    minWidth: 100,
                  },

                ],
                url: {
                    list: "finance/fee/settleCommission/profitAuditList",
                    syncOrderProfit: "finance/fee/settleCommission/syncOrderProfit",
                    batchAuditLoseMoneyOrders: "finance/fee/settleCommission/batchAuditLoseMoneyOrders",
                    downloadSalemanUnCommisstionProfitAuditExportXls:
                      "/file/export/excel/SalesExcel/downloadSalemanUnComissionProfitAuditExportXls",
                },
              rules:{
                auditReason:[
                  {required:true,message:'请选择原因',trigger:'change'}
                ]
              },
              initData: { isAudit: "0" },
              isUsePost:true
            };
        },
        methods: {
            syncOrderProfit(){
                postAction(this.url.syncOrderProfit,{orderNo:this.queryForm.orderNo}).then(res =>{
                    if(res.success){
                        this.$message.success(res.message)
                    }
                    this.handleQuery();
                })
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                // setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$intoDocPage(orderNo);
                // this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },

            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.queryForm.isWhether='0'
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
            batchAuditLoseMoneyOrders() {
                this.$refs['loseAudit'].validate(valid => {
                  if (valid) {
                    postAction(this.url.batchAuditLoseMoneyOrders + "?auditReason=" + this.form.auditReason, this.selectionRows.map(r => r.orderNo)).then((res) => {
                      this.$message({
                        type: "success",
                        message: res.message,
                      });
                      this.form = {};
                      this.handleSearch();
                    });
                    this.dialogFormVisibleBatchAudit = false
                    this.handleQuery();
                  }
                })

            },
        },
        created() {
            this.queryForm.isWhether='0'
        },
      computed:{
        buttonShowList() {
          return {
            'plsh':this.$btnIsShow('sale/WaitAuditModule','0','批量审核'),
            'syncProfit':this.$btnIsShow('sale/WaitAuditModule','0','同步订单利润'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
