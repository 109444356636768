<template>
  <el-dialog
      title="编辑产品"
      :visible="visible"
      width="840px"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
      :close-on-click-modal="false">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
      <el-row class="inputs_row">
        <el-col :span="11">
          <el-form-item prop="orderNo" label="订单号">
            <el-input v-model="form.orderNo" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="name" label="中文品名">
            <el-input v-model="form.name" maxlength="50" show-word-limit
                      style="padding: 0 45px 0 0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="ename" label="英文品名">
            <el-input v-model="form.ename" maxlength="50" show-word-limit
                      style="padding: 0 45px 0 0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" v-if="form.hasTax&& form.hasTax =='1'">
          <el-form-item prop="unitValue" label="申报单价">
            <el-input v-model="form.unitValue" type="number" @change="getAllPrice(form)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="material" label="材质" :rules="rules.material">
            <el-input v-model="form.material" maxlength="50" show-word-limit
                      style="padding: 0 45px 0 0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="purpose" label="用途" :rules="rules.purpose">
            <el-input v-model="form.purpose" maxlength="250" show-word-limit type="textarea" autosize
                      style="padding: 0 45px 0 0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="hscode" label="税号" :rules="row.shipmentCountry === '美国' && row.country === '美国' ? row.mgHsCode : row.hscode">
            <el-input v-model="form.hscode" maxlength="50" show-word-limit
                      style="padding: 0 45px 0 0" @input="updateGoods33(form.hscode,form.country)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="remark" label="备注">
            <el-input v-model="form.remark" maxlength="500" type="textarea" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="invoiceCheck">确定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {httpAction,getAction, getallcountries} from '@/api/manage'
import {baifenshuPattren, sanweixiaoshu, shiweishuPattern} from "../../../utils/pattern";
import {noZhongwenValidator} from "@/utils/validator";

export default {
  name: 'GoodsDialog',
  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {
      }
    },
    invoiceCheck:{
      type: Boolean,
      default: false
    },
    order:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      picList: [],
      form: {},
      rules: {
        // name: [
        //     {required: true, message: '请输入产品中文名称', trigger: 'change'},
        // ],
        // ename: [
        //     {required: true, message: '请输入产品英文名称', trigger: 'change'},
        // ],
        baifenshu: [
          {pattern: baifenshuPattren, message: '请输入百分数', trigger: 'change'}
        ],
        country: [
          {required: true, message: '请选择国家', trigger: 'change'},
        ],
        hscode: [
          {required: true, message: '请输入产品编码', trigger: 'change'},
        ],
        mgHsCode: [
          {required: true, message: '请输入产品编码', trigger: 'change'},
          {pattern:shiweishuPattern,message: '美国编码只能是十位数字',trigger: 'change'}
        ],
        taxRate: [
          // {required: true, message: '税率须大于0', trigger: 'change'},
          // {pattern: sanweixiaoshu, message: "税率只能由正整数及3位小数组或者0", trigger: 'change'}
        ],
        material: [
          {validator: noZhongwenValidator,  trigger: 'change'},
        ],
        purpose: [
          {validator: noZhongwenValidator, trigger: 'change'},
        ],
        maker: [
          {required: true, message: '请输入生产厂商', trigger: 'change'},
        ],
        makerAdd: [
          {required: true, message: '请输入厂商地址', trigger: 'change'},
        ],
      },
      url: {
        add: '/interface/ttOrder/editMergeGoods',
        edit: '/customer/product/edit',
        getDemandList: '/sys/dict/getDictItems/demand',
        get301binamByHscode: "/interface/ttOrder/get301binamByHscode",

      },
      demandList: [],
      countrylist: [],
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.form = JSON.parse(JSON.stringify(this.row));
        let list = [];
        if (this.row.picUrl) {
          let obj = {};

          obj.url = this.row.picUrl;

          if (this.row.picName) {
            obj.name = this.row.picName;
          }
          list.push(obj);

        }
        this.$set(this, 'picList', list);
      }
      // if (this.form.picUrl) {
      //     let list = [];
      //
      //     this.$set(this.form, 'demandList', this.form.demand.split(','))
      // } else {
      //     this.$set(this.form, 'demandList', []);
      // }
    },
    picList(val) {
      if (val && val.length > 0) {
        if (this.$refs['picUrl']) {
          this.$refs['picUrl'].clearValidate();
        }
      }
    },
  },
  methods: {
    updateGoods33(val,country){
      if (val && country === '美国') {
        getAction(this.url.get301binamByHscode,{hscode:val})
            .then(res=>{
              let obj = res.result;
              if (obj) {
                this.$set(this.form,'addtionalCode',obj['addtionalDutyText']);
                this.$set(this.form,'taxRate',obj['dutyRate']);
              }
            })
      }

    },
    getAllPrice(item) {
      if (!isNaN((parseFloat(item.qty))) && !isNaN((parseFloat(item.unitValue)))) {
        item.totalValue = (parseFloat(item.qty).toFixed(0) * parseFloat(item.unitValue)).toFixed(3);
      } else {
        item.totalValue = 0;
      }
    },
    initCountryList() {
      if (this.countrylist.length <= 0) {
        this.countrylist = [];
        getallcountries().then(res => {
          if (res.success) {
            for (var i = 0; i < res.result.length; i++) {
              let obj = {};
              obj.value = res.result[i].cnName;
              obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
              this.countrylist.push(obj);
            }
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }

    },
    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      // if (this.row.id) {
      //     url = this.url.edit
      //     method = 'PUT'
      // }
      // this.form.demand = this.form.demandList.join(',');
      // this.form.cusId = this.$route.query.id || '';
      //给图片名字 赋值
      if (this.picList && this.picList.length > 0) {
        this.form.picUrl = this.picList[0].url;
        this.form.picName = this.picList[0].name;
      }
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          httpAction(url, method, this.form).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.handleClose()
            this.$emit('ok')
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      // this.$refs.form.resetFields()
    }
  },
  created() {
    if (this.row){
      this.form=JSON.parse(JSON.stringify(this.row));
     }
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}
</style>
