<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="100px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="结算对象">
              <el-input clearable v-model.trim="queryForm.settlement" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="录入时间类型">
              <f-select dict="pay_age_create_time_type" v-model="queryForm.createTimeType"
                :is-need="queryForm.createTimeType"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="30" :sm="15" :md="15" :lg="3">
            <el-form-item label="结算对象类型">
              <f-select dict="sup_type" :is-need="queryForm.supplierType"
                v-model="queryForm.supplierType"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="30" :sm="15" :md="15" :lg="3">
            <el-form-item label="付款方式类型">
              <f-select dict="sup_settle" :is-need="queryForm.settlementType" v-model="queryForm.settlementType"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
            size="medium"
            plain
            @click="exportExcelPlus(url.downloadPayAge, '应付账龄')"
            v-if="buttonShowList.dc"
        >导出
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
              <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'week'">
            <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.containerId)">{{scope.row.week }}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { deleteAction, postAction } from "@/api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";
  import Tempcclate from "../../../isystem/weightMark.vue";
  import {setUpRouteUtil} from "../../../../utils/util";

  export default {
    name: "payAccAging",
    mixins: [QueryMixins],
    components: {
      Tempcclate
    },
    data() {
      return {
        columns: [
          {
            type: "selection",
            width: 50,
          },
          {
            prop: "orderNo",
            label: "工作单号",
          },
          {
            prop: "containerType",
            label: "业务类型",
          },
          {
            prop: "week",
            label: "周数",
          },
          {
            prop: "shipNo",
            label: "船名航次",
          },
          {
            prop: "orderCreateTime",
            label: "下单时间",
          },
          {
            prop: "createTime",
            label: "录入时间",
          },
          {
            prop: "shipmentBoardTime",
            label: "开船时间",
          },
          {
            prop: "settleTime",
            label: "销账时间",
          },
          {
            prop: "settlement",
            label: "结算对象",
          },
          {
            prop: "feename",
            label: "费用名",
          },
          {
            prop: "price",
            label: "价格",
          },
          {
            prop: "moneyType",
            label: "币种",
          },
          {
            prop: "quantity",
            label: "数量",
          },
          {
            prop: "total",
            label: "总计",
          },
          {
            prop: "exchangeRate",
            label: "汇率",
          },
          {
            prop: "exchangeTotal",
            label: "折合人民币",
          },
          {
            prop: "remark",
            label: "备注",
          },
          {
            prop: "createBy",
            label: "录入人",
          },
          {
            prop: "operator",
            label: "操作员",
          },
          {
            prop: "type",
            label: "付款类型",
          },
          {
            prop: "settlementType",
            label: "付款方式",
          },
          {
            prop: "status",
            label: "状态",
          },
        ],
        url: {
          list: "/file/sale2/getPayAgentIPage",
          downloadPayAge: "/file/export/excel/sale2/payAge",
        },
        isUsePost: true,
      };
    },
    methods: {
      infomation(id) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
        this.$router.push("/operation/cabinet/Edit?id=" + id);
        console.log(id);
      },
    },
    computed:{
      buttonShowList() {
        return {
          'dc':this.$btnIsShow('hyFinanceManage/modules/financialStatements/payAccAging', '0', '导出'),
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>