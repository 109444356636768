<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <!-- <el-card class="box-card"> -->
            <el-row>
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="客户名称">-->
                <!--                        <el-input v-model="queryForm.username"></el-input>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="联系电话">-->
                <!--                        <el-input v-model="queryForm.phone"></el-input>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="业务员">-->
                <!--                        <f-select v-model="queryForm.salesmanId" :dict="salesmanDict"></f-select>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label-width="30px">-->
                <!--                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>-->
                <!--                        <el-button plain @click="handleReset">重置</el-button>-->
                <!--                        <el-button type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--                    <el-form-item label="添加人">-->
                <!--                        <f-select v-model="queryForm.createBy" :dict="userDict"></f-select>-->
                <!--                    </el-form-item>-->
                <!--                </el-col>-->
            </el-row>
            <!-- </el-card> -->
        </el-form>
        <el-card class="box-card">
            <!-- <div class="f-p-b-15"></div> -->
            <!--        <div class="c-alert f-m-b-10">-->
            <!--            已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项-->
            <!--            <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>-->
            <!--        </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="79vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <!--                <template v-if="scope.column.property === 'status'">-->
                    <template v-if="scope.column.property === 'processInstanceStatus'">
            <span v-if="scope.row.processInstanceStatus === 'CREATED'"
            >新建</span
            >
                        <span v-if="scope.row.processInstanceStatus === 'RUNNING'"
                        >审批中</span
                        >
                        <span v-if="scope.row.processInstanceStatus === 'SUSPENDED'"
                        >已冻结</span
                        >
                        <span v-if="scope.row.processInstanceStatus === 'COMPLETED'"
                        >审批通过</span
                        >
                        <span v-if="scope.row.processInstanceStatus === 'CANCELLED'"
                        >已取消</span
                        >
                        <span v-if="scope.row.processInstanceStatus === 'DELETED'"
                        >已删除</span
                        >
                    </template>

                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleApproval(scope.row)"
                        >查看流程表单
                        </el-button>
                        <el-button type="text" @click="handleHistory(scope.row)"
                        >历史
                        </el-button
                        >
                        <!--            <el-button type="text" @click="handleDeal(scope.row)"-->
                        <!--              >处理</el-button-->
                        <!--            >-->
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <component :ref="dynamic" :is="dynamic"
                   :processDialogData="processDialogData" @handleDeal="handleDeal"
                   @handleCancel="cancelStartProcess" @comMounted="comMounted"
                   @handleDialogClose="cancelStartProcess"></component>
    </div>
</template>

<script>
    import {getAction, deleteAction} from "@/api/manage";
    /*import Mixins from "@/mixins/Mixins.js";*/
    import QueryMixins from "@/mixins/QueryMixins.js"
    export default {
        name: "Undetermined",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                rules: {
                    instanceName: [
                        {required: true, message: "请输入实例名称", trigger: "change"},
                    ],
                    instanceVariable: [
                        {required: true, message: "请输入实例描述", trigger: "change"},
                    ],
                },
                namelist: [],
                row: {},
                ids: [],
                tableData: [],
                formLabelWidth: "120px",
                visible: false,
                assignVisible: false,
                assignTitle: "",
                salesmanlist: [],
                userlist: [],
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "instanceName",
                        label: "流程名称",
                        sortable: "instanceName",
                    },
                    {
                        prop: "name",
                        label: "任务节点名称",
                        sortable: "name",
                    },
                    {
                        prop: "processInstanceStatus",
                        label: "任务状态",
                        sortable: "processInstanceStatus",
                    },
                    {
                        prop: "assignee",
                        label: "办理人",
                        sortable: "assignee",
                    },
                    {
                        prop: "createdDate",
                        label: "创建时间",
                        sortable: "createdDate",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 300,
                    },
                ],
                url: {
                    list: "/activiti/getFinishedTasks",
                    FlowImgByInstanceId: "/activiti/FlowImgByInstanceId",
                    deal: "/activiti/DealInstanceId",
                },
                dynamicPath: '',  //动态组件
                dynamic: '', //动态组件
                isDynamic: false, //动态组件是否存在
                processDialogData: {},  //弹窗组件的传递数据
            };
        },
        created() {
            this.initList()

        },
        computed: {
            // 动态加载对应的组件
            loaderDynamic() {
                return () => import('./components/' + this.dynamicPath)
            }
        },
        methods: {
            // 动态加载组件，components为组件的名称，所有的组件需要放在isystem/activity/components中
            initDynamic(components) {
                if (this.dynamicPath == components && this.isDynamic) {
                    this.comMounted()
                    return
                }
                this.dynamicPath = components;
                const self = this;
                self.loaderDynamic().then(() => {
                    // 动态加载模板
                    self.dynamic = () => self.loaderDynamic();
                    self.isDynamic = true;
                }).catch((err) => {
                    self.isDynamic = false;
                    this.$message.warning('模板组件不存在');
                    console.log('模板不存在', err)
                    // 模板不存在404时处理
                })
            },
            // 动态组件加载完成
            comMounted() {
                this.$refs[this.dynamic].visible = true;
                // this.selectRow.processInstanceId = this.selectRow.procInstId
                this.selectRow.actionType = "watch"
                this.$refs[this.dynamic].initData(this.selectRow);
            },
            //初始化列表
            initList() {
                getAction(this.url.list).then((res) => {
                    if (res.success) {
                        console.log(res.result)
                        const {records, total} = res.result
                        this.tableData = records || res.result;
                        this.ipagination.total = res.result.total
                    }
                });
            },
            handleSelectionChange(selection) {
                //多选
                let arr = [];
                this.namelist = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.namelist.push(selection[i]['username'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleTableChange({prop, order}) {
                if (order === null) {
                    this.handleResetSorter();
                } else {
                    this.$set(this.isorter, "prop", prop);
                    this.$set(this.isorter, "order", order);
                }
                this.handleSearch();
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1;
                this.ipagination.pageSize = val;
                this.handleQuery();
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val;
                this.handleQuery();
            },
            handleReset() {
                this.queryForm = {};
                /* this.filterForm = {};*/
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleResetFilter() {
                // 清空表格过滤器
                this.$refs.GTable.handlePureClear();
                this.filterForm = {};
            },
            handleApproval(row) {
                console.log(row)
                this.selectRow = row;
                this.activityForm = JSON.parse(JSON.stringify(row));
                this.initDynamic(row.businessKey);

            },
            handleDeal(row) {
                console.log(row);
                // /

                getAction(this.url.deal, row).then((res) => {
                    this.$message({
                        type: "success",
                        message: res.message,
                    });
                    this.initList();
                });
            },
            handleHistory(row) {
                console.log(row);
                let routeData = this.$router.resolve({
                    path:
                        "/ViewsActivitiBpmn?type=history&processInstanceId=" +
                        row.processInstanceId,
                });
                window.open(routeData.href, "_blank");
            },
            // 查看流程
            handleGetProcess(row) {
                this.initDynamic(row.key);
                this.$set(this.processDialogData, 'form', row);
            },
            cancelStartProcess() {
                this.initList();
            },
            //启动实例
            startProcess() {
                console.log(this.form);
                this.$refs.form.validate((valid) => {
                    getAction(this.url.startProcess, this.form).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
            },

            handleDel(row) {
                console.log(row);
                this.$confirm(`确定删除${row.name}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteDeployment, row).then((res) => {
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.initList();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
