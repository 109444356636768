<template>
    <el-dialog
        title="新增用户"
        :visible="visible"
        width="880px"
        top="20px"
        custom-class="c-custom-dialog"
        :fullscreen="fullscreen"
        :before-close="handleClose">
        <!-- <div slot="title" class="f-flex f-row-between f-p-r-30 title">
            <span>新增用户</span>
            <i class="el-icon-full-screen" @click="fullscreen = !fullscreen"></i>
        </div> -->
        <div class="c-slim-scrollbar">
            <customer-form ref="form" :span="span" @ok="handleSubmit"></customer-form>
        </div>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import CustomerForm from './CustomerForm'

export default {
    name: 'AddCustomerDialog',
    components: {
        CustomerForm
    },
    props: {
        visible: {
            type: Boolean
        },
        userName:{
          type :String
        }
    },
    data() {
        return {
            fullscreen: false,
            url: {
                add: '/customer/addCustomer',
            }
        }
    },
    computed: {
        span() {
            return this.fullscreen ? 8 : 12
        }
    },
    methods: {
        handleSubmit() {
            // let url = this.url.add
            // let method = 'POST'
            // if(this.row.id) {
            //     url = this.url.edit
            //     method = 'PUT'
            // }
            // this.$refs.form.validate((valid) => {
            //     if (valid) {
                    // httpAction(url, method, this.form).then((res)=>{
                    //     this.$message({
                    //         type: 'success',
                    //         message: res.message
                    //     })
                    //     this.handleClose()
                    //     this.$emit('ok')
                    // })
            //     }
            // })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.fullscreen = false
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
    i {
        cursor: pointer;
    }
}
::v-deep
    .el-dialog__body {
        padding: 20px 30px 0px 26px;
        height: calc(100% - 130px);
        overflow: auto;
    }
</style>
