<template>
    <div class="main-full-content">
        <el-form class="" label-width="70px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="用户名">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="中文名">
                      <el-input v-model="queryForm.realname" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="英文名">
                            <el-input v-model="queryForm.englishName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="手机号">
                            <el-input v-model="queryForm.phone" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="邮箱">
                      <el-input v-model="queryForm.email" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="角色">
                      <f-select v-model="queryForm.roleStr" :is-need="queryForm.roleStr" :dict="'sys_role_id'" @keyup.enter.native="handleSearch"></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4" class="no_margin">
                    <el-form-item label="部门">
                      <f-select v-model="queryForm.depStr" :is-need="queryForm.depStr" :dict="'sys_depart'" @keyup.enter.native="handleSearch"></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3" class="no_margin">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
<!--                            <el-button plain @click=testExportExcel>测试导出excel</el-button>-->
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15" style="display: flex">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleEdit({})"

                        v-if="buttonShowList.add"
                >新增
                </el-button
                >
                <el-button
                        class="c-theme-error-button"
                        size="medium"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.plsc"
                        @click="handleBatDel"
                >批量删除
                </el-button>
                <el-button
                        class="c-theme-error-button"
                        size="medium"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.sckq"
                        @click="handleBatGenerateCard"
                >生成卡券
                </el-button>
                <el-upload style="margin-left: 10px"
                           action=""
                           :http-request="importGoods"
                           :show-file-list="false"
                           :before-upload="beforeUpload"
                           accept=".xls,.xlsx"
                >
                    <el-button type="primary" size="medium"
                    >导入用户
                    </el-button
                    >
                </el-upload>
                <el-button style="margin-left: 10px"
                           type="primary"
                           size="medium"
                           @click="downloadModel(url.downloadModel, '员工导入模板')"

                >模板下载
                </el-button>
               
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
                <span
                        class="stress f-m-l-12 f-cursor-pointer"
                        @click="handleClearSelection"
                >清空</span
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="62vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status === 1">正常</span>
                        <span class="dot-badge" v-else>冻结</span>
                    </template>
                    <template v-else-if="scope.column.property === 'sex'">
                        <span v-if="scope.row.sex === 1">男</span>
                        <span v-else>女</span>
                    </template>
                    <template v-else-if="scope.column.property === 'roles'">
                        {{ handleGetRoles(scope.row.roles, scope.$index) }}
                    </template>
                    <template v-else-if="scope.column.property === 'isTest'">
                        <span v-if="scope.row.isTest === '1'">测试账号</span>
                        <span v-else>正常</span>
                    </template>

                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleLogin(scope.row)"
                                   v-if="buttonShowList.login && checkIsAdmin(scope.row)"
                        >登录
                        </el-button
                        >
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button
                        >
                        <el-button
                                type="text"
                                @click="handleChangeStatus([scope.row.id], 2)"
                                v-if="buttonShowList.dj && scope.row.status === 1"
                        >
                            冻结
                        </el-button>
                        <el-button
                                type="text"
                                @click="handleChangeStatus([scope.row.id], 1)"

                                v-if="buttonShowList.jd && scope.row.status !==1"

                        >解冻
                        </el-button
                        >
                        <el-button type="text" @click="handleAuth(scope.row)"

                                   v-if="buttonShowList.sq"
                        >授权
                        </el-button
                        >
                        <el-button type="text" @click="handleDel(scope.row)"

                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button
                        >
<!--                        <el-dropdown-->
<!--                                trigger="click"-->
<!--                                @command="(cmd) => handleCommand(cmd, scope.row)"-->
<!--                        >-->
<!--              <span class="el-dropdown-link">-->
<!--                <el-button type="text" class="f-m-l-10"-->

<!--                >更多<i class="el-icon-arrow-down"></i-->
<!--                ></el-button>-->
<!--              </span>-->
<!--                            <el-dropdown-menu slot="dropdown">-->
                                <el-button command="RELATION" @click="handleCommand('RELATION',scope.row)" type="text"
                                                  v-if="buttonShowList.gl"
                                >关联
                                </el-button>
                                <el-button command="REVERSE" @click="handleCommand('REVERSE',scope.row)" type="text"
                                                  v-if="buttonShowList.fxgl"

                                >反向关联
                                </el-button>
                                <el-button command="RESETPWD"
                                           v-if="buttonShowList.reP" @click="handleCommand('RESETPWD',scope.row)" type="text">
                                    重置密码
                                </el-button>
                                 <el-button 
                                  v-if="buttonShowList.zslj"
                          type="text"
                           @click="generateUrl(scope.row)"

                >专属链接
                
                </el-button>
                   <el-button 
                                  v-if="buttonShowList.zsewm"
                          type="text"
                           @click="generateCode(scope.row)"

                >专属二维码
                
                </el-button>
<!--                                <el-dropdown-item command="SHOW" v-if="$btnIsShow('isystem/UserManage','1','按钮权限')">-->
<!--                                    按钮权限-->
<!--                                </el-dropdown-item>-->
                                <!--                <el-dropdown-item command="DISABLED">按钮可点权限</el-dropdown-item>-->

<!--                            </el-dropdown-menu>-->
<!--                        </el-dropdown>-->
                    </template>
                    <template v-else-if="scope.column.property === 'departIds'">
                        <span>{{ parseDict(scope.row.departIds, "sys_depart") }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <add-user-drawer
                :visible.sync="visible"
                :row="row"
                @ok="handleQuery"
        ></add-user-drawer>
        <relation-dialog
                :visible.sync="relateVisible"
                :id="id"
                :username="username"
                :type="type"
        ></relation-dialog>
        <!-- <edit-tree-dialog
                :visible.sync="authVisible"
                title="授权"
                :url="authUrl"
                :id="id"
                idField="userId"
                saveIdField="userId"
        ></edit-tree-dialog> -->
        <edit-power
                :visible="authVisible"
                title="授权"
                :url="authUrl"
                :id="id"
                idField="userId"
                saveIdField="userId"
                @update:visible="authVisible = false"></edit-power>
        <role-btn-permission-dialog
                :visible.sync="showVisible"
                :row="row"
                @okVisbles="okVisbles"
        >
        </role-btn-permission-dialog>


<code-dialog :visible="codeVisible" :codeInfo="codeInfo" @update:visible="(value) => { codeVisible = value; }"/>
    </div>
</template>

<script>
    import {deleteAction, putAction, postAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins.js";
    import AddUserDrawer from "./modules/AddUserDrawer";
    import EditTreeDialog from "./modules/EditTreeDialog";
    import editPower from "./modules/editPower";
    import RelationDialog from "./modules/RelationDialog";
    import RoleBtnPermissionDialog from "./modules/RoleBtnPermissionDialog";
    import store from "@/store";
    import { setPermissionListAll } from '@/utils/util';
    import {updatedDict} from "../../utils/util";
     import CodeDialog from "@/components/Layout/CodeDialog";

    export default {
        name: "UserManage",
        mixins: [QueryMixins],
        components: {
            AddUserDrawer,
            EditTreeDialog,
            editPower,
            RelationDialog,
            RoleBtnPermissionDialog,
            CodeDialog
        },
        data() {
            return {
                dictCodes: ["sys_depart"],
                bdRules: {
                    docId: [
                        {required: true, message: "请选择客服人员", trigger: "change"},
                    ],
                    billmanId: [
                        {required: true, message: "请选择账单人员", trigger: "change"},
                    ],
                },
                rolelist: [],
                relation: {},
                id: "",
                username: "",
                type: "",
                authVisible: false,
                relateVisible: false,
                visible: false,
                row: {},
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 220,
                    },
                    {
                        prop: "username",
                        label: "用户名",
                        sortable: "custom",
                    },
                    {
                        prop: "realname",
                        label: "中文名",
                        sortable: "custom",
                    },
                    {
                        prop: "englishName",
                        label: "英文名",
                        sortable: "custom",
                    },
                    {
                        prop: "sex",
                        label: "性别",
                        sortable: "custom",
                    },
                    {
                        prop: "status",
                        label: "状态",
                        sortable: "custom",
                    },
                    {
                        prop: "phone",
                        label: "手机号",
                    },
                    {
                        prop: "email",
                        label: "邮箱",
                        sortable: "custom",
                    },
                     {
                       prop: "area",
                       label: "区域",
                       sortable: "custom",
                     },
                    {
                        prop: "departIds",
                        label: "部门",
                        sortable: "custom",
                    },
                    {
                        prop: "workNo",
                        label: "邀请码",
                        sortable: "custom",
                    },
                    {
                        prop: "roles",
                        label: "角色",
                    },
                    {
                        prop: "isTest",
                        label: "测试账号",
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                        sortable: "custom",

                    },
                ],
                authUrl: {
                    list: "/sys/permission/list",
                    auth: "/sys/SysUserPermission/queryUserPermission",
                    ok: "/sys/SysUserPermission/saveUserPermission",
                },
                url: {
                    list: "/sys/user/list",
                    deleteBatch: "/sys/user/deleteBatch",
                    delete: "/sys/user/delete",
                    reset: "/sys/user/resetPassword",
                    frozen: "/sys/user/frozenBatch",
                    downloadModel: '/sys/bank/downloadRenzheng',
                    generateSalesmanCard:'/customer/coupon/generateSalesmanCard',
                    //测试导出
                    exportXlsUrl:'/file/export/excel/allSettleDataQueryStream',
                    // exportXlsUrl:'/file/export/excel/allSettleData2',
                    exportTestStuent:'/file/export/excel/getTestStu',
                },
                showVisible: false,
                codeVisible:false,
                codeInfo:{}
            };
        },
        methods: {
            //限制 除了admin之外的账户 不允许有登录功能
            checkIsAdmin(row) {
              let username = row.username;
              if (username === 'admin' || username === 'test' || username === 'sz.ceshi' || username === 'sz.xiongmao') {
                  let localUsername = '';
                  let userStr = localStorage.getItem("USERINFO");
                  if (userStr) {
                      let userObj = JSON.parse(userStr);
                      if (userObj) {
                          localUsername = userObj.username;
                      }
                  }
                  return localUsername === 'admin' || localUsername === 'yang.bingfeng' || localUsername === 'sz.selina';
              }else {
                  return true;
              }
            },
            testExportExcel(){
                let fileName = "测试teststudent";
                this.filterForm={
                    start:0,
                    pageTotal:1000000,
                };
                this.exportExcelPlus(this.url.exportTestStuent,fileName);
                // postAction('/file/export/excel/getTestStu',{}).then(res=>{

                // })
            },
            handleBatGenerateCard() {
                this.$confirm(`确定生成卡券吗？将会生成10张通用券`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.generateSalesmanCard, {salesmanIdList:this.selectedRowKeys}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.success?res.message+"请在系统管理-卡券管理查看":res.message
                        })
                        this.handleSearch()
                    })
                })
            },
            handleLogin(row){



                        //进行密码加密

                        // this.form.password = Base64.encode(this.form.password);
                        //
                        // console.log("前端加密",this.form.password);
                        store.commit("setSidebarList", []);
                        this.$store
                            .dispatch('user/loginWithNewUserName', {username:row.username})
                            .then(() => {
                                // 当前用户可以访问的路由
                                setPermissionListAll().then(res => {
                                    console.log('权限获取成功');
                                    this.$router.push({
                                        path: this.$route.query.redirect || "/"
                                    })
                                    location.reload();
                                }).catch(err => {

                                });
                            }).catch(err=>{
                            // this.form.password = Base64.decode(this.form.password);
                        })


            },
            async importGoods(data) {
                this.fsee = false;
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                await postAction(
                    '/sy' +
                    's/bank/import',
                    formData
                )
                    .then((res) => {
                        this.handleSearch();
                    })
                this.initProduct();
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            handleGetRoles(list, index) {
                let arr = [];
                if (list) {
                    for (let i = 0; i < list.length; i++) {
                        arr.push(list[i]["rolename"]);
                    }
                }
                this.rolelist[index] = arr.join(",");
                return arr.join("，");
            },
            handleCommand(cmd, row) {
                this.id = row.id;
                this.username = row.username;
                if (cmd === "RESETPWD") {
                    this.handleResetPwd(row.id);
                } else if (cmd === "RELATION") {
                    this.type = "RELATION";
                    this.relateVisible = true;
                } else if (cmd === "REVERSE") {
                    this.type = "REVERSE";
                    this.relateVisible = true;
                } else if (cmd === 'SHOW') {
                    this.handleBtnShow(row);
                } else if (cmd === 'DISABLED') {
                    this.handleBtnDisabled(row)
                }
            },

            handleAuth({id}) {
                this.id = id;
                this.authVisible = true;
            },
            handleChangeStatus(ids, status) {
                const msg = status === 2 ? "确定冻结该账户?" : "确定解冻该账户?";
                const type = status === 2 ? "error" : "success";
                this.$confirm(msg, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type,
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(this.url.frozen, {ids: ids.join(","), status}).then(
                            (res) => {
                                this.handleQuery();
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                            }
                        );
                    })
                    .catch(() => {
                    });
            },
            handleResetPwd(id) {
                this.$confirm("确定重置该账户的密码?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        putAction(this.url.reset, {id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                        });
                    })
                    .catch(() => {
                    });
            },
            handleDel(row) {
                this.$confirm("确定删除"+row.username+"?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {
                            id: row.id,
                        }).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            //同时清空sessionstorage的
                          updatedDict('sys_work_binding,doc,docId,operations,channel,channelId,billman_id,scqd_id,ocean_salesman_id,sys_create_by,all_user_id,sys_creator_id')
                            this.handleSearch();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleBatDel() {
                this.$confirm("确定删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteBatch, {
                            ids: this.selectedRowKeys.join(","),
                        }).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            //同时清空sessionstorage的
                            updatedDict('sys_work_binding,doc,docId,operations,channel,channelId,billman_id,scqd_id,ocean_salesman_id,sys_create_by,all_user_id,sys_creator_id')

                            this.handleSearch();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleEdit(row) {
                this.$nextTick(() => {
                    console.log(this.$refs);
                    // this.$refs.form.resetFields();//等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
                });

                for (var name in row) {
                    this.$set(this.row, name, row[name]);
                }
                if (!row || !row.id) {
                    this.$set(this, 'row', {});
                }
                // console.log('编辑之后的row',this.row.username);
                this.visible = true;
            },
            handleBtnShow(row) {
                this.row = row;
                this.row.gg = 'user';
                this.row.bp = 'show';
                this.showVisible = true;
            },
            handleBtnDisabled(row) {
                this.row = row;
                this.row.gg = 'user';
                this.row.bp = 'disabled';
                this.showVisible = true;
            },
            okVisbles() {
                this.showVisible = false;
            },
            generateUrl(row){
                //  let api = "http://192.168.2.8:8088";  //本地
                 let api = "http://120.25.172.133:9800"  //测试环境
                 if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
                    // 线上环境
                    api = 'https://do.do2do.com'
                 }
                let _this = this;
                this.$copyText(`${api}/login/registerPhone?realname=${encodeURI(row.realname)}&workNo=${row.workNo}`).then(() => {
                    _this.$message({
                        message: '复制成功，可以使用ctrl+v快捷键粘贴',
                        type: 'success'
                    });
                }).catch(e => {
                    _this.$message.error('复制失败');
                })

            },
             generateCode(row){
                this.codeInfo={
                    realname:row.realname,
                    workNo:row.workNo
                }
                this.codeVisible=true
            }
        },
      computed:{
        buttonShowList(){
          return {
            'add':this.$btnIsShow('isystem/UserManage','0','新增'),
            'plsc':this.$btnIsShow('isystem/UserManage','0','批量删除'),
            'sckq':this.$btnIsShow('isystem/UserManage','0','生成卡券'),
            'login':this.$btnIsShow('isystem/UserManage','1','登录'),
            'bj':this.$btnIsShow('isystem/UserManage','1','编辑'),
            'dj':this.$btnIsShow('isystem/UserManage','1','冻结'),
            'jd':this.$btnIsShow('isystem/UserManage','1','解冻'),
            'sq':this.$btnIsShow('isystem/UserManage','1','授权'),
            'sc':this.$btnIsShow('isystem/UserManage','1','删除'),
            'gl':this.$btnIsShow('isystem/UserManage','1','关联'),
            'fxgl':this.$btnIsShow('isystem/UserManage','1','反向关联'),
            'reP':this.$btnIsShow('isystem/UserManage','1','重置密码'),
            'zslj':this.$btnIsShow('isystem/UserManage','1','专属链接'),
            'zsewm':this.$btnIsShow('isystem/UserManage','1','专属二维码'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .dot-badge {
        position: relative;

        &::before {
            content: "";
            width: 6px;
            height: 6px;
            left: -10px;
            top: calc(50% - 3px);
            position: absolute;
            background: #ec1c1c;
            border-radius: 50%;
        }
    }
</style>
