<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运地" label-width="120px">
              <f-select v-model="queryForm.departureId" :is-need="queryForm.departureId" :get-dict-url="url.getDepartDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运港" label-width="120px">
              <f-select v-model="queryForm.startPortId" :is-need="queryForm.startPortId" :get-dict-url="url.getPortDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">

        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleEdit({})"
                   v-if="buttonShowList.add">新增
        </el-button>
        <el-button :disabled="selectionRows.length === 0" type="primary" size="medium" icon="el-icon-plus"
          @click="handleBatDel()" v-if="buttonShowList.delB">批量删除
        </el-button>
         <el-upload v-if="buttonShowList.imFile" action="/" :show-file-list="false"
          :http-request="uploadSectionFile" accept=".xls,.xlsx" style="margin:0 10px;display:inline">
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <el-button v-if="buttonShowList.downMd" type="primary" size="medium"
          @click="downloadModel(url.downloadModel, '拖车费设置导入模板')">模板下载
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              v-if="buttonShowList.edit">编辑
            </el-button>
            <el-button type="text" @click="handleChangeCanUse(scope.row,'0')"
              v-if="buttonShowList.dis && scope.row.isCanUse === '1'">禁用
            </el-button>
            <el-button type="text" @click="handleChangeCanUse(scope.row,'1')"
                       v-if="buttonShowList.en && scope.row.isCanUse === '0'">启用
            </el-button>
            <el-button type="text" @click="handleEdit(scope.row, 'log')"
              v-if="buttonShowList.log">日志
            </el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <edit-trailer-fee-set-dialog :visible.sync="addVisible" :type="type" :row="row" @ok="handleSearch"></edit-trailer-fee-set-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditTrailerFeeSetDialog from "./modules/EditTrailerFeeSetDialog.vue";
export default {
  name: "TrailerFeeSet",
  mixins: [QueryMixins],
  components: {
    EditTrailerFeeSetDialog
  },
  data() {
    return {
      loading: false,
      addVisible: false,
      authVisible: false,
      type: '', 
      isUsePost: true,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },


        {
          prop: "departureName",
          label: "起运地",
        },
        {
          prop: "startPortName",
          label: "起运港",
        },
        {
          prop: "cabinetType",
          label: "柜型",
        },
        {
          prop: "feeName",
          label: "费用类别",
        },
        {
          prop: "moneyType",
          label: "币种",
        },
        {
          prop: "price",
          label: "价格",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
        },
      ],
      url: {
        list: "/sys/trailer/fee/set/queryTrailerFeeSetIPage",
        deleteBatch: '/sys/trailer/fee/set/delList',
        downloadModel: '/file/download/model/trailerFeeSet',
        importUrl: '/file/read/excel/importTrailerFeeSet',
        edit: '/sys/trailer/fee/set/editOne',
        getDepartDict:'/sys/bd/departure/getDepartureDictList',
        getPortDict:'/sys/bdPort/getBdPortDictList',
      },
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importUrl, form)
        .then((res) => {
          //这里做上传之后的操作
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          } else {
            this.handleQuery();
          }
        })
    },
    handleDel(id) {
      this.$confirm("确定删除该拖车费设置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, [id]).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {
        });
    },
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => { });
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row, type) {
      this.type = type;
      this.row = row;
      this.addVisible = true;
    },
    handleChangeCanUse(row,isCanUse) {
      let params = JSON.parse(JSON.stringify(row));
      params.isCanUse = isCanUse;
      postAction(this.url.edit,params)
          .then(res=>{
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          })
    }
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('channel/TrailerFeeSet', '0', '新增'),
        'delB':this.$btnIsShow('channel/TrailerFeeSet', '0', '批量删除'),
        'imFile':this.$btnIsShow('channel/TrailerFeeSet', '0', '导入拖车费设置'),
        'downMd':this.$btnIsShow('channel/TrailerFeeSet', '0', '下载拖车费设置导入模板'),
        'edit':this.$btnIsShow('channel/TrailerFeeSet', '1', '编辑'),
        'dis':this.$btnIsShow('channel/TrailerFeeSet', '1', '禁用'),
        'en':this.$btnIsShow('channel/TrailerFeeSet', '1', '启用'),
        'log':this.$btnIsShow('channel/TrailerFeeSet', '1', '查看日志'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
