<template>
  <el-dialog :title="title" :visible="visible" width="80%" top="3vh" custom-class="c-custom-dialog" :destroy-on-close="destroyOnClose"
    :before-close="handleClose">
    <el-checkbox-group v-model="powerChoiceIdList" @change="handlePowerChange">
      <el-table ref="powerTable" class="power_table_box" :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border
        :tree-props="{children: 'children'}" default-expand-all :span-method="arraySpanMethod" :key="tableRefresh" height="76vh"
        v-loading="treaLoading">
        <el-table-column type="" prop="menu" label="菜单" width="180">
          <template slot-scope="scope">
            <el-checkbox v-if="scope.row.menu" :label="scope.row.parentId">
              {{scope.row.menu}}
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="页面" width="180">
          <template slot-scope="scope">
            <el-checkbox v-if="(scope.row.buttonList || scope.row.children) && scope.row.title" :label="scope.row.id"
              @change="handleCheckAllChange(scope.row, scope.row.id, scope.row.parentId)">
              {{scope.row.title}}
            </el-checkbox>
            <!--  @change="clickChangeStatus(scope.row, 'menu')" -->
            <el-checkbox v-else-if="scope.row.title" :label="scope.row.id">
              {{scope.row.title}}
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="buttonList" label="按钮">
          <template slot-scope="scope">
            <el-checkbox v-for="(item, index) in scope.row.buttonList" :label="item.id" :key="index" @change="clickChangeStatus(scope.row)">
              {{item.title}}</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </el-checkbox-group>
    <div style="display: flex; justify-content: space-between;">
      <div>提示：只支持二级菜单之前的，如果有扩展三级菜单需修改后才可使用</div>
      <div style="display: flex; justify-content: flex-end;">
        <el-button class="c-theme-button" size="medium" type="primary" @click="handleSetCheckedNodes">{{isChecked ? '全部' : '取消' }}选中
        </el-button>
        <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import { randomUUID } from '@/utils/util'
export default {
  name: 'EditTreeDialog',
  props: {
    title: {
      type: String,
      default: '请输入标题'
    },
    visible: {
      type: Boolean
    },
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    id: {
      type: String
    },
    idField: {
      type: String,
      default: 'id'
    },
    saveIdField: {
      type: String,
      default: 'id'
    },
    url: {
      type: Object,
      default: () => {
        return {
          list: '/sys/permission/list', // treeData
          auth: '', // 权限
          ok: '' // 确认保存请求的接口
        }
      }
    }
  },
  data() {
    return {
      isChecked: true,
      tableData: [],
      powerSpan: {},  //权限的格数跟显示的数据对应
      allPowerId: {}, //所有的权限id，按照对应的id作为key进行分组
      powerChoiceIdList: [],  //权限选择的id列表
      menuId: '',
      tableRefresh: randomUUID(),  //变化的时候会重新渲染表格
      treaLoading: false,
      tableScrollTop: null, //记录当前表格的滚动距离
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.handleGetAuth();
        this.handleGetTreeData();
        this.isChecked = true;
      }
    },
  },
  mounted() {
  },
  methods: {
    // 合并行
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        let realRowIndex = (rowIndex - this.powerSpan[row.menuId].sum);
        if (realRowIndex % (this.powerSpan[row.menuId].length) === 0) {
          return {
            rowspan: this.powerSpan[row.menuId].length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // 获取所有的菜单数据列表
    handleGetTreeData() {
      this.treaLoading = true;
      getAction(this.url.list).then((res) => {
        this.treaLoading = false;
        this.tableData = res.result;
        this.setPowerData();
        // this.setAllMore();
      }).catch(err => {
        this.treaLoading = false;
      })
    },
    // 设置权限初始数据
    setPowerData() {
      let tableData = [];
      this.allPowerId['all'] = [];
      this.tableData.forEach((item, index) => {
        let length = 0;
        this.allPowerId['all'].push(item.id);
        if (item.children) {
          // 一级菜单的id
          this.allPowerId[item.id] = [];
          this.allPowerId[item.id] = item.children.map(itemChildrenPage => {
            return itemChildrenPage.id;
          });
          this.allPowerId['all'] = this.allPowerId['all'].concat(this.allPowerId[item.id]);
          item.children.forEach((itemChildren, indexChildren) => {
            length++;
            let tableDataItem = {
              title: itemChildren.title,
              id: itemChildren.id,
              parentId: itemChildren.parentId,
              menuId: item.id
            }
            if (indexChildren == 0) {
              tableDataItem.menu = item.title;
              tableDataItem.menuIsIndeterminate = false;
              tableDataItem.menuCheckAll = false;
            }
            if (itemChildren.children && itemChildren.children[0]) {
              tableDataItem.isIndeterminate = false;
              tableDataItem.checkAll = false;
              // 二级菜单的id
              this.allPowerId[itemChildren.id] = [];
              let twoAllPowerId = itemChildren.children.map(itemChildrenPage => {
                return itemChildrenPage.id;
              });
              this.allPowerId[item.id] = this.allPowerId[item.id].concat(twoAllPowerId);
              this.allPowerId[itemChildren.id] = this.allPowerId[itemChildren.id].concat(twoAllPowerId);
              this.allPowerId['all'] = this.allPowerId['all'].concat(twoAllPowerId);
              // 是按钮权限
              if (itemChildren.children[0].menuType == 2) {
                tableDataItem.children = null;
                tableDataItem.buttonList = itemChildren.children;
              } else {
                // 有二级菜单
                itemChildren.children.forEach((itemChildrenTwo, indexChildrenTwo) => {
                  length++;
                  itemChildrenTwo.menuId = item.id;
                  // 是按钮权限
                  if (itemChildrenTwo.children && itemChildrenTwo.children[0] && itemChildrenTwo.children[0].menuType == 2) {
                    // 三级菜单的id+按钮
                    this.allPowerId[itemChildrenTwo.id] = [];
                    itemChildrenTwo.children.forEach((itemChildrenPage, indexChildrenPage) => {
                      this.allPowerId[itemChildrenTwo.id].push(itemChildrenPage.id);
                      this.allPowerId[itemChildren.id].push(itemChildrenPage.id);
                      this.allPowerId[item.id].push(itemChildrenPage.id);
                      this.allPowerId['all'].push(itemChildrenPage.id);
                    })
                    itemChildrenTwo.buttonList = itemChildrenTwo.children;
                    itemChildrenTwo.children = null;
                    itemChildrenTwo.isIndeterminate = false;
                    itemChildrenTwo.checkAll = false;
                    itemChildrenTwo.ancestralId = item.id;
                    // itemChildrenTwo.menu = item.title;
                  } else {
                    // console.log(itemChildrenTwo.children, 'itemChildrenTwo.children')
                  }
                })
                tableDataItem.children = itemChildren.children;
              }
            } else {
            }
            // tableDataItem.dataLength = 1 + ((tableDataItem.children && tableDataItem.children.length) || 0);
            tableData.push(tableDataItem);
          })
        } else {
          length = 1;
          tableData.push({ menu: item.title, menuId: item.id, parentId: item.id});
        }
        this.powerSpan[item.id] = { length: length, sum: index == 0 ? 0 : this.powerSpan[this.tableData[index - 1].id].sum + this.powerSpan[this.tableData[index - 1].id].length };
      })
      this.tableData = tableData;
    },
    // 触发全选操作
    handleCheckAllChange(row, id, parentId, type) {
      if (row.ancestralId || (row.buttonList && row.buttonList.length)) {
        row.checkAll = !row.checkAll;
        if (this.tableData.indexOf(row) == -1) {
          // 在树形结构的子数据中,重新渲染表格数据
          // this.tableRefresh = randomUUID();
        }
        if ((type == 'menu' && row.menuCheckAll) || (type !== 'menu' && row.checkAll)) {
          //触发全选操作
          // parentId ? this.powerChoiceIdList.push(parentId) : ''; //取消关联父级元素
          this.powerChoiceIdList = this.powerChoiceIdList.concat(this.allPowerId[id]);
          this.powerChoiceIdList.push(id);
        } else {
          // 触发取消全选操作
          let allPowerId = this.allPowerId[id];
          allPowerId.push(id);
          this.powerChoiceIdList = this.powerChoiceIdList.filter((x) => !allPowerId.some((item) => x === item));
        }
        // this.clickChangeStatus(row);
        this.powerChoiceIdList = Array.from(new Set(this.powerChoiceIdList));
        return
      }
    },
    // 触发按钮点击操作
    handlePowerChange(val) {
      // console.log(this.powerChoiceIdList, val, '按钮变化');
    },
    // 点击按钮，改变全选的状态值
    clickChangeStatus(row, type) {
      // 自己的id处理
      if (row.id && type !== 'menu') {
        let newList = this.allPowerId[row.id].filter((val) => {
          return this.powerChoiceIdList.indexOf(val) > -1
        })
        if (newList.length == this.allPowerId[row.id].length) {
          row.isIndeterminate = false;
          row.checkAll = true;
          this.powerChoiceIdList.push(row.id);
        } else {
          this.powerChoiceIdList.push(row.id);
          row.isIndeterminate = false;
          row.checkAll = false;
          // newList.length == 0 ? this.powerChoiceIdList = this.powerChoiceIdList.filter((x) => ![row.id].some((item) => x === item)) : '';
        }
        // if (row.ancestralId) {
        //   this.tableScrollTop = this.$refs.powerTable.bodyWrapper.scrollTop;
        //   this.tableRefresh = randomUUID();
        //   this.$nextTick(() => {
        //     setTimeout(() => {
        //       this.$refs.powerTable.bodyWrapper.scrollTop = (this.tableScrollTop) || 0;
        //     }, 0)
        //   });
        // }
      }
      // 父级的id处理
      if (row.parentId && type == 'menu') {
        let newListParent = this.allPowerId[row.parentId].filter((val) => {
          return this.powerChoiceIdList.indexOf(val) > -1
        })
        if (newListParent.length == this.allPowerId[row.parentId].length) {
          this.powerChoiceIdList.push(row.parentId);
          let menuIndex = null;
          try {
            this.tableData.forEach((item, index) => {
              if (item.parentId == row.parentId) {
                menuIndex = index;
                throw Error();         //满足条件，跳出循环
              }
              if (item.id == row.parentId) {
                item.isIndeterminate = false;
                item.checkAll = true;
              }
            })
          } catch (e) {
            this.tableData[menuIndex].menuIsIndeterminate = false;
            this.tableData[menuIndex].menuCheckAll = true;
          }
        } else {
          let menuIndex = null;
          this.powerChoiceIdList.push(row.parentId);
          try {
            this.tableData.forEach((item, index) => {
              if (item.parentId == row.parentId) {
                menuIndex = index;
                throw Error();         //满足条件，跳出循环
              }
              if (item.id == row.parentId) {
                item.isIndeterminate = false;
                item.checkAll = false;
              }
            })
          } catch (e) {
            this.tableData[menuIndex].menuIsIndeterminate = newListParent.length > 0;
            this.tableData[menuIndex].menuCheckAll = false;
          }
          newListParent.length == 0 ? this.powerChoiceIdList = this.powerChoiceIdList.filter((x) => ![row.parentId].some((item) => x === item)) : '';
        }
      }
      // 祖级的id处理 ancestralId
      // if (row.ancestralId) {
      //   let newListAncestral = this.allPowerId[row.ancestralId].filter((val) => {
      //     return this.powerChoiceIdList.indexOf(val) > -1
      //   })
      //   if (newListAncestral.length == this.allPowerId[row.ancestralId].length) {
      //     this.powerChoiceIdList.push(row.ancestralId);
      //     let menuIndex = null;
      //     try {
      //       this.tableData.forEach((item, index) => {
      //         if (item.parentId == row.ancestralId) {
      //           menuIndex = index;
      //           throw Error();         //满足条件，跳出循环
      //         }
      //       })
      //     } catch (e) {
      //       this.tableData[menuIndex].menuIsIndeterminate = false;
      //       this.tableData[menuIndex].menuCheckAll = true;
      //     }
      //   } else {
      //     let menuIndex = null;
      //     this.powerChoiceIdList.push(row.ancestralId);
      //     try {
      //       this.tableData.forEach((item, index) => {
      //         if (item.parentId == row.ancestralId) {
      //           menuIndex = index;
      //           throw Error();         //满足条件，跳出循环
      //         }
      //       })
      //     } catch (e) {
      //       this.tableData[menuIndex].menuIsIndeterminate = newListAncestral.length > 0;
      //       this.tableData[menuIndex].menuCheckAll = false;
      //     }
      //     newListAncestral.length == 0 ? this.powerChoiceIdList = this.powerChoiceIdList.filter((x) => ![row.ancestralId].some((item) => x === item)) : '';
      //   }
      //   this.tableScrollTop = this.$refs.powerTable.bodyWrapper.scrollTop;
      //   this.tableRefresh = randomUUID();
      //   this.$nextTick(() => {
      //     setTimeout(() => {
      //       this.$refs.powerTable.bodyWrapper.scrollTop = (this.tableScrollTop) || 0;
      //     }, 0)
      //   });
      // }
      this.powerChoiceIdList = Array.from(new Set(this.powerChoiceIdList));
    },
    // 点击全部选中和取消全部选中
    handleSetCheckedNodes() {
      this.powerChoiceIdList = this.isChecked ? this.allPowerId['all'] : [];
      this.isChecked = !this.isChecked;
      // this.setAllMore();
    },
    // 保存
    handleSubmit() {
      const perIds = this.powerChoiceIdList.join(',');
      if (!perIds || perIds.length === 0) {
        this.$message.warning("请至少勾选一条数据");
        return false;
      }
      const params = {
        [this.saveIdField]: this.id,
        // permissionIds: this.$refs.tree.getCheckedKeys().join(','),
        permissionIds: perIds,
        lastpermissionIds: this.allPowerId['all'].filter(item => perIds.indexOf(item) === -1).join(',')
      }
      this.$store.commit("setFullscreenLoading", true);
      postAction(this.url.ok, params).then(res => {
        this.$store.commit("setFullscreenLoading", false);
        this.handleClose();
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
      })
    },
    // 获取用户已设置的权限列表
    handleGetAuth() {
      getAction(this.url.auth, { [this.idField]: this.id }).then((res) => {
        this.powerChoiceIdList = res.result;
      })
    },
    // 设置所有数据的Indeterminate和checkAll的值
    setAllMore() {
      this.tableData.forEach(item => {
        // this.clickChangeStatus(item, 'menu');
        if (item.children && item.children.length) {
          item.children.forEach(itemone => {
            itemone.buttonList && itemone.buttonList.length ? this.clickChangeStatus(itemone) : '';
          })
        } else if (item.buttonList && item.buttonList.length) {
          item.buttonList.forEach(itemBut => {
            this.clickChangeStatus(itemBut, 'menu');
          });
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">
.power_table_box {
  .expanded + tr > td {
    padding: 2px 5px !important;
  }
  .el-table__body td {
    text-align: left;
  }
  .cell {
    text-align: left;
  }
  th,
  td {
    padding: 2px 5px !important;
  }
}
</style>
<style lang="scss" scoped>
.btn-wrap {
  text-align: right;
  padding: 10px 30px;
}
.content {
  height: 620px;
  padding: 6px 0;
  overflow: auto;
}
::v-deep {
  .el-dialog__body {
    padding: 0 0 0 20px;
  }
  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #e0efff;
  }
  .custom-tree-node {
    flex: 1;
    display: flex; //这里的display属性不用修改（使树节点末尾的按钮右对齐）
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%; //宽度必须是这个，不能使用px或者min-width或者min-width等，因为外层使用了el-card包裹是可以拉动的，换言之树节点的宽度随时可变
    & span.em-tree-text {
      display: inline-block; //block一样
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
</style>
