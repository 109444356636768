<template>
    <el-dialog
            title="线下投保"
            :visible="visible"
            width="600px"
            append-to-body
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-form-item prop="insuredTime" label="投保时间">
                <el-date-picker
                        v-model="form.insuredTime"
                        type="datetime"
                        placeholder="投保时间"
                        style="width: 100%"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>


            <el-form-item label="保险产品">
<!--                <select-input clearable filterable class="f-full-width"-->
<!--                          :dict="'insure_product'"-->
<!--                          v-model="form.insureProduct"-->
<!--                          :fatherVal="form.insureProduct"-->
<!--                          @change = "changeProduct"-->
<!--                          placeholder="请选择" style="width:100%"-->
<!--                ></select-input>-->
                <select-input
                        :object-data="form" data-key="insureProduct" :get-options-func="getInsuranceProduct"
                        placeholder="请选择" style="width:100%"
                ></select-input>
            </el-form-item>
            <el-form-item prop="shelfType" label="上架保障" >
                    <f-select clearable filterable class="f-full-width"
                              :dict="'shelf_type'"
                              v-model="form.shelfType"
                              :fatherVal="form.shelfType"
                              placeholder="请选择" style="width:100%" :is-need="form.shelfType">
                    </f-select>
            </el-form-item>
            <el-form-item prop="declareTotal" label="投保金额">
                <el-input  v-model="form.declareTotal" placeholder="请输入投保金额" @input="cacRmbPremium"></el-input>
            </el-form-item>
            <el-form-item prop="cur" label="投保币种">
                <f-select
                        v-model="form.cur"
                        :is-need="form.cur"
                        :dict="'bd_cur'"
                >
                </f-select>
            </el-form-item>
            <el-form-item prop="feeRate" label="保险费率" v-if="showFeeRateFlag">
                <el-input  v-model="form.feeRate" placeholder="请输入保险费率" @input="cacRmbPremium"></el-input>
            </el-form-item>
            <el-form-item prop="insuranceCompany" label="保险公司">
                <select-input
                              :object-data="form" data-key="insuranceCompany" :get-options-func="getInsuranceCompany"
                              placeholder="请选择" style="width:100%"
                ></select-input>
            </el-form-item>
            <el-form-item prop="rmbPremium" label="保险费">
                <el-input :disabled="!buttonShowList.xg" v-model="form.rmbPremium"></el-input>
            </el-form-item>
            <el-form-item prop="fileList" label="保单上传">
                <f-upload v-model="form.fileList" :limit="1"></f-upload>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, httpAction} from '@/api/manage'

    export default {
        name: 'AddProblemDialog',
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            },
            userName: {
                type: String
            }
        },
        data() {
            return {
                showFeeRateFlag:true,
                feeRate: 0,
                changeFlag: false,
                form: {},
                options: [],
                rules: {
                    insuredTime: [
                        {required: true, message: '请选择投保时间', trigger: 'change'},
                    ],
                    insureProduct: [
                        {required: true, message: '请选择保险产品', trigger: 'change'},
                    ],
                    shelfType: [
                        {required: true, message: '请选择上架保障', trigger: 'change'},
                    ],
                    declareTotal: [
                        {required: true, message: '请输入投保金额', trigger: 'change'},
                    ],
                    feeRate: [
                        {required: true, message: '请输入保险费率', trigger: 'change'},
                    ],
                    cur: [
                        {required: true, message: '请选择保险币种', trigger: 'change'},
                    ],
                    insuranceCompany: [
                        {required: true, message: '请选择保险公司', trigger: 'change'},
                    ],
                    rmbPremium: [
                        {required: true, message: '请填输入保险费', trigger: 'change'},
                    ]
                },
                url: {
                    dict: '/sys/dict/getDictItems/',
                    offlineInsurance:'/order/insurance/offlineInsurance'
                }
            }
        },
        watch: {
            visible(val) {
                this.form = {fileList: []}
                this.form.id = this.row.id
                this.form.orderNo = this.row.orderNo
                this.form.declareTotal = this.row.declareTotal
                this.form.cur = this.row.cur
            },
            "form.insureProduct": {
                immediate: true,
                handler(newValue,oldValue) {
                    this.changeProduct(newValue)
                },
            },
            // "form.insuranceCompany": {
            //     immediate: true,
            //     handler(newValue,oldValue) {
            //         if(!this.changeFlag){
            //             this.changeCompany(newValue)
            //         }
            //         this.changeFlag = false
            //     },
            // },
        },
        created() {
        },
        methods: {
            getInsuranceCompany(){
                return getAction(this.url.dict + "insure_company").then(res => {
                    return res.result;
                })
            },
            getInsuranceProduct(){
                return getAction(this.url.dict + "insure_product").then(res => {
                    return res.result;
                })
            },
            changeCompany(e){
                this.$set(this.form,"insuranceCompany",e)
                this.$set(this.form,"feeRate",null)
                this.$set(this.form,"insureProduct",null)
            },
            changeProduct(productName){
                this.changeFlag = true
                let rate = null;
                let company = ""
                this.showFeeRateFlag = true
                if("大森林货运险" === productName || "PICC上架险" ===productName){
                    rate = 0.00082
                    company = "中国人民财产保险股份有限公司深圳市分公司"
                    this.showFeeRateFlag = true
                }else if("PICC到仓险" === productName){
                    rate = 0.0002
                    company = "中国人民财产保险股份有限公司深圳市分公司"
                    this.showFeeRateFlag = true
                }else if("大森林邮包险" === productName || "PICC邮包险" === productName){
                    rate = 0.0008
                    company = "中国人民财产保险股份有限公司深圳市分公司"
                    this.showFeeRateFlag = true
                }else if("跨境大货创新类保险服务" === productName){
                    company = "宁波跨境堡科技有限公司"
                    this.showFeeRateFlag = true
                }else if("跨境大货创新类保险服务" === productName){
                    company = "宁波跨境堡科技有限公司"
                    this.showFeeRateFlag = true
                }
                this.$set(this.form,"feeRate",rate)
                this.$set(this.form,"insuranceCompany",company)
                this.cacRmbPremium()
            },
            cacRmbPremium(){
                this.form.rmbPremium = null
                if(this.form.feeRate && this.form.declareTotal){
                    let temp = this.form.declareTotal * this.form.feeRate
                    this.form.rmbPremium =parseFloat(temp.toFixed(5));
                }
            },
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        /*将filelist的id取出来拼成ids给form*/
                        if(!this.form.fileList || this.form.fileList.length == 0){
                            this.$message.error("请上传对应保险单！");
                            return;
                        }
                        if (this.form.fileList) {
                            this.$set(this.form, 'ossIds', this.form.fileList.map(function (e) {
                                return e.id
                            }).join(","));
                            this.form.policyUrl = this.form.fileList[0].url
                            this.form.policyFileName = this.form.fileList[0].name

                        }
                        httpAction(this.url.offlineInsurance, 'POST', this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$emit('ok');
                this.$refs.form.clearValidate()
            }
        },
      computed:{
        buttonShowList(){
          return {
            'xg':this.$btnIsShow('operation/baoxian/NoCommit','1','是否修改线下投保保费'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>
