<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="70%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :before-close="handleClose"
    >
        <el-form
            :model="billForm"
            :rules="rules"
            ref="billForm"
            :inline="true"
            :label-position="'right'"
            label-width="109px"
        >
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="financeRemoveType" label="财务费用科目">
                        <!--                        <el-select v-model="billForm.checkStatus">-->
                        <!--                            <el-option label="待查账" value="0" disabled></el-option>-->

                        <!--                        </el-select>-->
                        <f-select
                            class="full_width"
                            v-model="billForm.financeRemoveType"

                            dict="finance_remove_fee"
                            @change="changeSubFeeWindow"

                            :isNeed="billForm.financeRemoveType"
                        >
                        </f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="checkUnusualInfo" label="备注">
                        <el-input
                            type="text"
                            v-model="remark"
                            style="width: 250px"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                show-summary
                :summary-method="getSummaries"
                :data="billForm.settleList"
                :cell-style="{ 'text-align': 'center' }"
                style="margin-top: 15px"
            >
                <template v-slot="scope">
                    <!--                    <template v-if=" scope.column.property === 'variance' && billForm.financeRemoveType !== '0' && billForm.financeRemoveType !== '1'">-->
                    <!--                        <el-form-item-->
                    <!--                                :prop="'settleList.' + scope.$index + '.variance'"-->
                    <!--                                :rules="[-->

                    <!--                                            { required: true, message: '差异额不能为空', trigger: 'blur' },-->
                    <!--                                        ]"-->
                    <!--                        >-->
                    <!--                            <el-input-->
                    <!--                                    v-model="scope.row.variance"-->
                    <!--                                    @input="showWaitReceiveMoney(scope.row)"-->
                    <!--                                    oninput="value=value.match(/^[-]?\d*\.?\d{0,3}/)"-->
                    <!--                                    @focus="focus($event)"-->
                    <!--                            ></el-input>-->
                    <!--                        </el-form-item>-->
                    <!--                    </template>-->
                    <!--                    <template v-else-if=" scope.column.property === 'variance' && billForm.financeRemoveType === '1'">-->
                    <template v-if=" scope.column.property === 'variance'">
                        <el-form-item
                            :prop="'settleList.' + scope.$index + '.variance'"
                            :rules="[

                                            { required: true, message: '差异额不能为空', trigger: 'blur' },
                                        ]"
                        >
                            <el-input
                                v-model="scope.row.variance"
                                @input="showWaitBalance()"
                                oninput="value=value.match(/^[-]?\d*\.?\d{0,3}/)"
                                @focus="focus($event)"
                            ></el-input>
                        </el-form-item>
                    </template>

                    <template v-else-if="scope.column.property === 'realPay'">
                        <el-form-item
                            :prop="'settleList.' + scope.$index + '.realPay'"
                            :rules="[

                                            { required: true, message: '实际到账不能为空', trigger: 'blur' },
                                        ]"
                        >
                            <el-input
                                v-model="scope.row.realPay"
                                @input="showWaitReceiveMoney(scope.row)"
                                oninput="value=value.match(/^[-]?\d*\.?\d{0,3}/)"
                                @focus="focus($event)"
                            ></el-input>
                        </el-form-item>
                    </template>

                    <template v-else-if="scope.column.property === 'partialWriteOff'">

                        <span v-if="scope.row.partialWriteOff!=='1'">否</span>
                        <span v-if="scope.row.partialWriteOff==='1'">是</span>

                    </template>
                    <template v-else-if="scope.column.property === 'waitPay'">
                        {{ (Number(scope.row.exchangeTotal) - Number(scope.row.payed)).toFixed(3) }}
                    </template>
                </template>
            </f-table>

            <el-row style="margin-top: 25px">
                <el-col :span="23">
                    <el-form-item label="应收合计">
                        <el-input type="text" v-model="receiveTotalAmount" style="width: 250px" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="实际到账金额">
                        <el-input type="text" v-model="salesmanMoney" style="width: 250px" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="实际到账币种">
                        <el-input type="text" v-model="salesmanMoneyCur" style="width: 100px" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="账单合计">
                        <el-input type="text" v-model="waterBillAmount" style="width: 250px" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item label="实际到账合计">
                        <el-input type="text" v-model="realReceiveTotal" style="width: 250px" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="subtractMoneyTotal" label="总差异额">
                        <el-input type="text" v-model="subtractMoneyTotal" style="width: 250px" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="waitReceiveTotal" label="余额">
                        <el-input type="text" v-model="waitReceiveTotal" style="width: 250px" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="waitReceiveTotal" label="提示" v-if="Number(waitReceiveTotal) > 0">
                        <span style="color: darkred">存在余额将视为部分销账！</span>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="BALANCE" label="BALANCE">
                        <el-input type="text" v-model="balance" style="width: 250px" disabled></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
      <el-button
          type="primary"
          size="medium"
          @click="handleSubmit"
          :disabled="ifDis"
      >确定销账</el-button
      >
    </span>
    </el-dialog>
</template>

<script>
import {getAction, postAction} from "@/api/manage";

export default {
    name: "removeDialog",

    data() {
        return {
            // id:'',
            flag: false,
            visible: false,
            loading: false,
            isChecked: true,
            ifDis: false,
            allKeys: [],
            treeData: [],
            defaultProps: {
                children: "children",
                label: "title",
            },
            lang: [{required: true, message: "请选择账单语言", trigger: "change"}],
            billForm: {
                settleList: [],

            },
            url: {
                removeSettles: "/shipping/hySettleReceive/removeSettles",
                removeSubmit: "/shipping/hySettleReceive/removeSubmit",
            },
            rules: {
                financeRemoveType: [
                    {required: true, message: "请选择销账费用科目", trigger: "change"},
                ],
                [`settleList.variance`]: [
                    {
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                        message: "整数或两位小数",
                        trigger: "blur",
                    },
                    {required: true, message: "请输入差异额", trigger: "blur"},
                ],
            },
            title: "销账",
            settleIds: [],
            remark: "",
            receiveTotalAmount: 0,
            waterBillAmount: 0,
            realReceiveTotal: 0,
            salesmanMoney: 0,
            salesmanMoneyCur: '',
            subtractMoneyTotal: 0,
            waitReceiveTotal: 0,
            balance: 0,
            columns: [
                {
                    prop: "orderNo",
                    label: "订单号",
                    width: 200
                },
                {
                    prop: "exchangeTotal",
                    label: "应收金额",
                    width: 100
                },
                {
                    prop: "realMoneyType",
                    label: "应收币种",
                    width: 100
                },
                {
                    prop: "realPay",
                    label: "实际到账金额",
                    width: 120
                },
                // {
                //     prop: "variance",
                //     label: "差异额",
                //     width: 100
                // },
                {
                    prop: "billMoney",
                    label: "账单金额",
                    width: 100
                },
                {
                    prop: "partialWriteOff",
                    label: "是否部分销账",
                    width: 100
                },
                {
                    prop: "payed",
                    label: "已销账金额",
                    width: 100
                },
                {
                    prop: "variance",
                    label: "差异额",
                    show: true,//2022.3.18 取消隐藏差异额的显示操作 此处改为true 恒显示
                    width: 100
                },
                {
                    prop: "waitPay",
                    label: "待收余额",
                    headerType: 'remark',
                    detail: '提示：待收余额将在销账之后更新',
                    width: 100
                },
                {
                    prop: "customer",
                    label: "客户名",
                    width: 159
                },
                {
                    prop: "feename",
                    label: "费用中文",
                    width: 200
                },
                {
                    prop: "remark",
                    label: "订单费用备注",
                    width: 200
                },
            ],
        };
    },
    methods: {
        focus(event) {
            event.currentTarget.select();
            // event.currentTarget.value = ''
        },

        changeSubFeeWindow() {
            console.log(this.billForm.financeRemoveType)
            // this.doSum(this.billForm.settleList)
            //2022.3.18 取消隐藏差异额的显示操作
            // if (this.billForm.financeRemoveType === "0") {
            //     console.log("1-",this.columns.filter((item) => item.label === "差异额")
            //     );
            //     this.columns.filter((item) => item.label === "差异额")[0].show = false;
            //     // this.billForm.settleList.forEach(settle => {
            //     //     settle.waitPay = 0
            //     // })
            // } else {
            //     console.log(this.columns.filter((item) => item.label === "差异额"));
            //     this.columns.filter((item) => item.label === "差异额")[0].show = true;
            // }
            if (this.billForm.financeRemoveType === "0") {
                    this.billForm.settleList.forEach(settle => {
                        settle.realPay = settle.exchangeTotal
                    })
                this.showWaitReceiveMoney()
                this.$refs.billForm.validate()
            }

        },
        showWaitBalance(){
            let subtractMoneyTotal = 0;
            for (let i = 0; i < this.billForm.settleList.length; i++) {
                //计算每行的待收余额
                let settle = this.billForm.settleList[i]
                settle.waitPay = (Number(settle.exchangeTotal) - Number(settle.realPay)- Number(settle.variance)).toFixed(3)
                console.log('settle.waitPay',settle.waitPay)
                if (this.billForm.settleList[i].variance !== undefined && this.billForm.settleList[i].variance !== "") {

                    subtractMoneyTotal = subtractMoneyTotal + Number(this.billForm.settleList[i].variance)
                }
            }
            this.subtractMoneyTotal = subtractMoneyTotal.toFixed(3);

            let waitReceiveTotal = 0;
            let receiveTotalAmount = 0;
            let realReceiveTotal = 0;
            for (let i = 0; i < this.billForm.settleList.length; i++) {
                console.log('waitpay', this.billForm.settleList[i].waitPay);
                // if (this.billForm.settleList[i].waitPay !== undefined && this.billForm.settleList[i].waitPay !== "") {
                //     waitReceiveTotal = Number(waitReceiveTotal) + Number(this.billForm.settleList[i].waitPay).toFixed(3);
                // }
                receiveTotalAmount = receiveTotalAmount + Number(this.billForm.settleList[i].exchangeTotal);
                // realReceiveTotal = realReceiveTotal + Number(this.billForm.settleList[i].billMoney);
                realReceiveTotal = realReceiveTotal + Number(this.billForm.settleList[i].realPay);
                waitReceiveTotal = waitReceiveTotal + Number(this.billForm.settleList[i].waitPay);
            }
            // this.waitReceiveTotal = (Number(receiveTotalAmount)-Number(realReceiveTotal)).toFixed(3);
            this.waitReceiveTotal = waitReceiveTotal.toFixed(3);
            this.realReceiveTotal = realReceiveTotal.toFixed(3);

            this.balance = (Number(this.receiveTotalAmount) - Number(this.realReceiveTotal) - Number(this.subtractMoneyTotal) - Number(this.waitReceiveTotal)).toFixed(3);
        },
        showWaitReceiveMoney(row) {
            let subtractMoneyTotal = 0;
            for (let i = 0; i < this.billForm.settleList.length; i++) {
                console.log(this.billForm.settleList[i].variance);
                //计算每行的待收余额
                let settle = this.billForm.settleList[i]
                settle.variance = (Number(settle.exchangeTotal) - Number(settle.realPay)).toFixed(3)
                if (settle.variance === undefined || settle.variance === "") {
                    settle.variance = 0;
                }
                settle.waitPay = (Number(settle.exchangeTotal) - Number(settle.realPay) - Number(settle.variance)).toFixed(3)


                if (this.billForm.settleList[i].variance !== undefined && this.billForm.settleList[i].variance !== "") {

                    subtractMoneyTotal = subtractMoneyTotal + Number(this.billForm.settleList[i].variance)
                }
            }
            this.subtractMoneyTotal = subtractMoneyTotal.toFixed(3);

            // if (row.variance === undefined || row.variance === "") {
            //     row.waitPay = 0;
            // } else {
            //     row.waitPay = (Number(row.exchangeTotal) - Number(row.billMoney)).toFixed(3);
            // }

            let waitReceiveTotal = 0;
            let receiveTotalAmount = 0;
            let realReceiveTotal = 0;
            for (let i = 0; i < this.billForm.settleList.length; i++) {
                console.log('waitpay', this.billForm.settleList[i].waitPay);
                // if (this.billForm.settleList[i].waitPay !== undefined && this.billForm.settleList[i].waitPay !== "") {
                //     waitReceiveTotal = Number(waitReceiveTotal) + Number(this.billForm.settleList[i].waitPay).toFixed(3);
                // }
                receiveTotalAmount = receiveTotalAmount + Number(this.billForm.settleList[i].exchangeTotal);
                // realReceiveTotal = realReceiveTotal + Number(this.billForm.settleList[i].billMoney);
                realReceiveTotal = realReceiveTotal + Number(this.billForm.settleList[i].realPay);
                waitReceiveTotal = waitReceiveTotal + Number(this.billForm.settleList[i].waitPay);
            }
            // this.waitReceiveTotal = (Number(receiveTotalAmount)-Number(realReceiveTotal)).toFixed(3);
            this.waitReceiveTotal = waitReceiveTotal.toFixed(3);
            this.realReceiveTotal = realReceiveTotal.toFixed(3);
            this.balance = (Number(this.receiveTotalAmount) - Number(this.realReceiveTotal) - Number(this.subtractMoneyTotal) - Number(this.waitReceiveTotal)).toFixed(3);
        },
        handleSubmit() {
            this.$refs.billForm.validate((valid) => {
                if (valid) {

                    let countCur = [];
                    this.billForm.settleList.forEach(item => {
                        countCur.push(item.realMoneyType)
                    });
                    countCur.push(this.salesmanMoneyCur)
                    let curArr = Array.from(new Set(countCur));
                    console.log('curArr->',curArr)
                    if(curArr.length > 1){
                        this.$message.warning("所选费用的折合币种不一致 或 实际到账币种和费用折合币种不一致！")
                        return
                    }

                    if (Number(this.balance) !== 0) {
                        this.$message.warning("balance不为零，不能进行销账");
                        return;
                    }

                    this.ifDis = true;
                    this.enabled();
                    let allRec = 0
                    let allSub = 0

                    this.billForm.settleList.forEach(settle => {
                        allRec = (Number(allRec) + Number(settle.realPay)).toFixed(3)
                        allSub = (Number(allSub) + Number(settle.variance)).toFixed(3)
                        settle.waitPay = settle.variance
                    })

                    if (Number(allRec) !== Number(this.salesmanMoney)) {
                        this.$message.warning("费用的实际到账总额和申请编号的实际到账金额不一致！");
                        return;
                    }

                    let message = '应收金额为：'+ this.receiveTotalAmount + '， 实际到账合计：'+ this.realReceiveTotal + '，总差异额：' +
                        (Number(this.subtractMoneyTotal)).toFixed(3) + '。若余额大于0则将视为部分销账。请确认是否无误？'

                    this.$confirm(message, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        closeOnClickModal: false,
                    }).then(() => {
                        const obj = {
                            id: this.billForm.id,
                            // financeRemoveType: this.billForm.checkStatus,
                            receiveTotalAmount: this.receiveTotalAmount,
                            realReceiveTotal: this.realReceiveTotal,
                            subtractMoneyTotal: this.subtractMoneyTotal,
                            waitReceiveTotal: this.waitReceiveTotal,
                            settleItemList: this.billForm.settleList,
                            financeRemoveType: this.billForm.financeRemoveType,
                        };

                        console.log(obj)
                        postAction(this.url.removeSubmit, obj).then((res) => {
                            console.log(res);
                            if (res.success) {
                                this.visible = false;
                                this.$emit("ok");
                            }
                        });


                    }).catch(() => {

                    })


                }
            });
        },
        handleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.visible = false;
        },
        enabled() {
            setTimeout(() => {
                this.ifDis = false;
            }, 2000);
        },
        edit(row) {
            getAction(this.url.removeSettles, {
                applyNo: row.applyNo,
                id: row.id,
            }).then((res) => {
                console.log(res);
                this.remark = res.result.applyRemark;
                this.billForm.id = row.id;

                this.billForm.settleList = res.result.settleItemList;
                this.billForm.settleList.forEach(i => {
                    i.variance = 0
                    i.realPay = ''
                })
                let settleList = res.result.settleItemList;

                let receiveTotalAmount = 0;
                let waterBillAmount = 0;
                let subtractMoneyTotal = 0;
                let waitReceiveTotal = 0;
                for (let i = 0; i < settleList.length; i++) {
                    //计算每条费用的待收和差异额
                    let some = Number(settleList[i].exchangeTotal) - Number(settleList[i].billMoney)
                    this.$set(this.billForm.settleList[i], 'realPay', settleList[i].exchangeTotal)
                    if (some > 0) {
                        //应收-实收大于0 则是少收，some赋值给待收
                        settleList[i].waitPay = Number(JSON.parse(JSON.stringify(some))).toFixed(3)
                        console.log('waitPay', settleList[i].waitPay)
                        settleList[i].variance = 0
                    } else {
                        //应收-实收等于或小于0，some赋值给差异额
                        // settleList[i].variance = some.toFixed(3) 这样赋值不能自定义输入
                        this.$set(this.billForm.settleList[i], 'variance', some.toFixed(3))
                        settleList[i].waitPay = 0
                        // this.$set(this.billForm,'financeRemoveType','3')
                        // this.billForm.checkStatus='3'
                    }

                    receiveTotalAmount = receiveTotalAmount + Number(settleList[i].exchangeTotal);
                    waterBillAmount = waterBillAmount + Number(settleList[i].billMoney);
                    subtractMoneyTotal = subtractMoneyTotal + Number(settleList[i].variance)
                    waitReceiveTotal = waitReceiveTotal + Number(settleList[i].waitPay);

                }
                this.receiveTotalAmount = receiveTotalAmount.toFixed(3);
                this.waterBillAmount = waterBillAmount.toFixed(3);
                this.subtractMoneyTotal = subtractMoneyTotal.toFixed(3);
                this.waitReceiveTotal = waitReceiveTotal.toFixed(3)
                this.salesmanMoney = res.result.salesmanMoney.toFixed(3)
                this.salesmanMoneyCur = res.result.salesmanMoneyCur
                // this.waitReceiveTotal = (this.receiveTotalAmount - this.realReceiveTotal).toFixed(3)
                console.log(this.waitReceiveTotal)
                this.balance = (Number(this.receiveTotalAmount) - (Number(this.realReceiveTotal) + Number(this.subtractMoneyTotal)
                    + Number(this.waitReceiveTotal))).toFixed(3)

                this.visible = true;
                this.showWaitReceiveMoney()
            });
        },
        doSum(settleList) {
            let receiveTotalAmount = 0;
            let realReceiveTotal = 0;
            let subtractMoneyTotal = 0;
            let waitReceiveTotal = 0;
            for (let i = 0; i < settleList.length; i++) {
                //计算每条费用的待收和差异额
                let some = Number(settleList[i].exchangeTotal) - Number(settleList[i].realPay)
                if (some > 0) {
                    //应收-实收大于0 则是少收，some赋值给待收
                    settleList[i].waitPay = Number(JSON.parse(JSON.stringify(some))).toFixed(3)
                    console.log('waitPay', settleList[i].realPay)
                    settleList[i].variance = 0
                } else {
                    //应收-实收等于或小于0，some赋值给差异额
                    // settleList[i].variance = some.toFixed(3) 这样赋值不能自定义输入
                    this.$set(this.billForm.settleList[i], 'variance', some.toFixed(3))
                    settleList[i].waitPay = 0
                    // this.$set(this.billForm,'financeRemoveType','3')
                    // this.billForm.checkStatus='3'
                }

                receiveTotalAmount = receiveTotalAmount + Number(settleList[i].exchangeTotal);
                realReceiveTotal = realReceiveTotal + Number(settleList[i].realPay);
                subtractMoneyTotal = subtractMoneyTotal + Number(settleList[i].variance)
                waitReceiveTotal = waitReceiveTotal + Number(settleList[i].waitPay);

            }
            this.receiveTotalAmount = receiveTotalAmount.toFixed(3);
            this.realReceiveTotal = realReceiveTotal.toFixed(3);
            this.subtractMoneyTotal = subtractMoneyTotal.toFixed(3);
            this.waitReceiveTotal = waitReceiveTotal.toFixed(3)
            // this.waitReceiveTotal = (this.receiveTotalAmount - this.realReceiveTotal).toFixed(3)
            console.log(this.waitReceiveTotal)
            this.balance = (Number(this.receiveTotalAmount) - (Number(this.realReceiveTotal) + Number(this.subtractMoneyTotal)
                + Number(this.waitReceiveTotal))).toFixed(3)
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            const columnName = ['应收金额','实际到账金额','账单金额','已销账金额','差异额']
            columns.forEach((column, index) => {
                console.log(column)
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if(columnName.includes(column.label)){
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] = sums[index].toFixed(3);
                    } else {
                        sums[index] = '';
                    }
                }

            });

            return sums;
        }
    },
};
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}

.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}
</style>
