<template>
    <el-dialog
            title="装柜中"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">

            <el-form-item prop="loadingIdList" label="分配装柜员" :label-width="formLabelWidth">
                <f-select v-model="form.loadingIdList" :isNeed="form.loadingIdList" :dict="'warehouse_man_id'" :multiple="true"></f-select>
            </el-form-item>

            <el-form-item prop="foxIdList" label="分配叉车员" :label-width="formLabelWidth">
                <f-select v-model="form.foxIdList" :isNeed="form.foxIdList" :dict="'warehouse_man_id'" :multiple="true"></f-select>
            </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {putAction} from '@/api/manage'

    export default {
        name: 'editContainerLoadingDialog',
        props: {
            visible: {
                type: Boolean
            },
            row:{
                type:Object,
                default:()=>{}
            },
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    loadingIdList: [
                        {required: true, message: '请选择装柜人员', trigger: 'change'},
                    ],
                    foxIdList: [
                        {required: true, message: '请选择叉车人员', trigger: 'change'},
                    ],
                },
                url: {
                    //装柜中
                    updateWarehouseOutToLoadingStatus:'/warehouse/stock/updateWarehouseOutToLoadingStatus',
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.row) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'loadingIdList',[]);
                    this.$set(this.form,'foxIdList',[]);
                    this.$set(this.form,'busId',this.row.id);
                    this.$set(this.form,'busNo',this.row.outOrderly);
                }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        putAction(this.url.updateWarehouseOutToLoadingStatus, this.form).then((res) => {
                            //把form 清空
                            this.form = {loadingIdList:[],foxIdList:[]}
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
