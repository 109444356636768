<template>
    <div>
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row style="display: flex; flex-wrap: wrap">
<!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
<!--                        <el-form-item label="国家">-->
<!--                            <f-select-->
<!--                                    v-model="queryForm.countryList"-->
<!--                                    :is-need="queryForm.countryList"-->
<!--                                    :dict="'bd_country'"-->
<!--                                    @keyup.enter.native="handleSearch"-->
<!--                            ></f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="美国海关编码">
                            <el-input
                                    v-model="queryForm.mgHsCode"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="海关编码">
                            <el-input
                                    v-model="queryForm.hsCode"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="关税税率">
                            <el-input
                                    v-model="queryForm.dutyRate"
                                    @keyup.enter.native="handleSearch"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="附加关税">
<!--                            <f-select-->
<!--                                    class="textarea_table"-->
<!--                                    v-model="queryForm.addtionalDutyCode"-->
<!--                                    :is-need="queryForm.addtionalDutyCode"-->
<!--                                    :dict="'bd_country_hs_code'"-->
<!--                                    @keyup.enter.native="handleSearch"-->
<!--                            ></f-select>-->
                            <el-input
                                    v-model="queryForm.addtionalDutyCode" @keyup.enter.native="handleSearch">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="301编码">
                            <f-select
                                    class="textarea_table"
                                    v-model="queryForm.addtionalDutyText"
                                    :is-need="queryForm.addtionalDutyText"
                                    :dict="'hs_code_301_find'"
                                    @keyup.enter.native="handleSearch"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-card>
        </el-form>
        <el-card class="box-card">
            <div class="bbb">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        class="margin_right_10"
                        v-if="buttonShowList.add"
                >新增
                </el-button
                >
                <el-upload
                        class="upload-demo margin_right_10"
                        action=""
                        :http-request="importOperation"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        accept=".xls,.xlsx"
                        v-if="buttonShowList.dr"
                >
                    <el-button type="primary" size="medium"

                    >导入
                    </el-button>
                </el-upload>
                <el-button
                        type="primary"
                        size="medium"
                        @click="downloadModel(url.download, '海关编码模板')"
                        v-if="buttonShowList.dr"
                >海关编码模板
                </el-button>
                <el-button type="primary" @click="handleDelDuo" size="medium" class="c-theme-error-button"
                           v-if="buttonShowList.sc"
                           :disabled="selectionRows.length === 0">批量删除
                </el-button>

            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)" v-if="buttonShowList.bj"
                        >编辑
                        </el-button>
                        <el-button type="text" @click="handleDel(scope.row)" v-if="buttonShowList.sc"
                        >删除
                        </el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑海关编码' : '新增海关编码'"
                v-if="dialogFormVisible"
                @close="dialogFormVisible = false"
                :visible.sync="dialogFormVisible"
                width="30%"
                center
                :close-on-click-modal="false"
                append-to-body
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="美国海关编码"
                        prop="mgHsCode"
                        :label-width="formLabelWidth"
                >
                    <el-input
                            v-model="form.mgHsCode" maxlength="20" show-word-limit

                    ></el-input>
                </el-form-item>
<!--                <el-form-item-->
<!--                        label="海关编码"-->
<!--                        prop="hsCode"-->
<!--                        :label-width="formLabelWidth">-->
<!--                    <el-input-->
<!--                            v-model="form.hsCode"-->
<!--                            maxlength="10"-->
<!--                            show-word-limit-->
<!--                    ></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="计量单位" prop="unitOfQuantity" :label-width="formLabelWidth">
                    <el-input v-model="form.unitOfQuantity" maxlength="30" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="产品描述" prop="customsDescription" :label-width="formLabelWidth">
                    <el-input v-model="form.customsDescription" maxlength="100" show-word-limit
                              type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="关税税率" prop="dutyRate" :label-width="formLabelWidth">
                    <el-input v-model="form.dutyRate" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="监管条件" prop="pga" :label-width="formLabelWidth">
                    <el-input v-model="form.pga" maxlength="150" show-word-limit type="textarea"></el-input>
                </el-form-item>
                <el-form-item
                        label="附加关税"
                        prop="addtionalDutyCode"
                        :label-width="formLabelWidth"
                >
<!--                    <f-select-->
<!--                            :dict="'bd_country_hs_code'"-->
<!--                            v-model="form.addtionalDutyCode"-->
<!--                            :is-need="form.addtionalDutyCode"-->
<!--                            @change="handleGetAddtional"-->
<!--                    ></f-select>-->
                    <el-input
                    v-model="form.addtionalDutyCode"
                    @change="handleGetAddtional"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="附加关税税率"
                        prop="addtionalDutyRate"
                        :label-width="formLabelWidth">
                    <el-input
                            v-model="form.addtionalDutyRate"
                            maxlength = "20"
                            show-word-limit

                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="301编码"
                        prop="addtionalDutyText"
                        :label-width="formLabelWidth">
                    <el-input
                            v-model="form.addtionalDutyText"
                            maxlength = "10"
                            show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveImporter"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { postAction} from "@/api/manage";
import {mgHsCodePattern} from "@/utils/pattern";

export default {
    name: "BdCountryHsCodeModule",
    mixins: [QueryMixins],
    components: {},
    data() {
        return {
            isUsePost:true,
            deliveryList: [],
            row: {},
            form: {},
            dialogFormVisible: false,
            formLabelWidth: "120px",

            //表单校验
            rules: {
                mgHsCode: [
                    {required: true, message: "请输入美国海关编码", trigger: "change"},
                    {pattern:mgHsCodePattern,message: '只能是4位数字.2位数字.4位数字的格式'}
                ],
                // hsCode: [
                //     {required: true, message: "请输入海关编码", trigger: "change"},
                //
                // ],
                dutyRate: [
                    {required: true, message: "请输入税率", trigger: "change"},
                ],
            },
            visible: false,
            columns: [
                {
                    type: 'selection',
                    width: 33
                },
                {
                    prop: "action",
                    label: "操作",
                },
                {
                    prop: "mgHsCode",
                    label: "美国海关编码",
                },
                {
                    prop: "hsCode",
                    label: "海关编码",
                },
                {
                    prop: "unitOfQuantity",
                    label: "计量单位",
                },
                {
                    prop: "customsDescription",
                    label: "产品描述",
                },

                {
                    prop: "dutyRate",
                    label: "关税税率",
                },
                {
                    prop: "pga",
                    label: "监管条件",
                },
                {
                    prop: "addtionalDutyCode",
                    label: "附加关税",
                },
                {
                    prop: "addtionalDutyRate",
                    label: "附加关税税率",
                },
                {
                    prop: "addtionalDutyText",
                    label: "301编码",
                },

                {
                    prop: "createTime",
                    label: "录入时间",
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },

            ],
            url: {
                list: "/sys/bdCountryHsCode/getIPage",
                delDuo: "/sys/bdCountryHsCode/delDuo",
                add: "/sys/bdCountryHsCode/addOrUpdate",
                import: "/file/read/excel/importHsCode",
                download: "/file/read/excel/hsCodeExcel",
            },
            addtionalMap:{},

        };
    },
    created() {
        this.addtionalMap = this.listToMap(this.GLOBAL.dictListMap['bd_country_hs_code'],'value');
    },
    methods: {

        handleGetAddtional(val){

          if (!this.addtionalMap) {
              this.addtionalMap = this.listToMap(this.GLOBAL.dictListMap['bd_country_hs_code'],'value');
          }
          let obj = this.addtionalMap[val];

            if (obj) {
              this.$set(this.form,'addtionalDutyRate',obj['rate'] || '');
              this.$set(this.form,'addtionalDutyText',obj['wenben'] || '');
          }else {
              this.$set(this.form,'addtionalDutyRate','');
              this.$set(this.form,'addtionalDutyText','');
          }
        },
        handleClose() {
            if (this.$refs["form"]) {
                this.$refs["form"].clearValidate();
            }
        },
        //导入操作绑定
        importOperation(data) {
            console.log("上传文件", data);
            const formData = new FormData();
            formData.append("file", data.file);
            postAction(this.url.import, formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    }
                })
        },
        beforeUpload(file) {
            //对文件大小进行限制
            const isLt2M = file.size / 1024 / 1024 < 15;
            if (!isLt2M) {
                this.$message.warning("文件大小不能超过15MB");
            }
            const isFile = file.name.indexOf(".") !== -1;
            if (!isFile) {
                this.$message.warning("该文件名不合法");
            }
            return isLt2M && isFile;
        },
        handleDel(row) {
            let list = [];
            list.push(row.id);
            this.$confirm(`确定删除吗`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                closeOnClickModal: false,
            })
                .then(() => {
                    postAction(this.url.delDuo, list).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
        },
        handleDelDuo() {
            this.$confirm(`确定批量删除吗`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                closeOnClickModal: false,
            })
                .then(() => {
                    postAction(this.url.delDuo, this.selectedRowKeys).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
        },
        handleAdd() {
            this.form = {};
            this.dialogFormVisible = true;
            this.handleClose();
        },
        handleEdit(row) {
            for (var name in row) {
                this.$set(this.form, name, row[name]);
            }
            this.dialogFormVisible = true;
        },
        saveImporter() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    postAction(this.url.add, this.form)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.dialogFormVisible = false;
                                this.handleQuery();
                            } else {
                            }
                        });
                  }
            });
        },
    },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/BdCountryHsCodeModule','0','新增'),
        'dr':this.$btnIsShow('isystem/BaseData/BdCountryHsCodeModule','0','导入'),
        'sc':this.$btnIsShow('isystem/BaseData/BdCountryHsCodeModule','0','删除'),
        'bj':this.$btnIsShow('isystem/BaseData/BdCountryHsCodeModule','0','编辑'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
