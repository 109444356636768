<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <!-- <el-card class="box-card"> -->
            <el-row>
            </el-row>
            <!-- </el-card> -->
        </el-form>
        <el-card class="box-card">
            <!-- <div class="f-p-b-15"></div> -->
            <!--        <div class="c-alert f-m-b-10">-->
            <!--            已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项-->
            <!--            <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>-->
            <!--        </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="79vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <!--                <template v-if="scope.column.property === 'status'">-->
                    <template v-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status === 'CREATED'">新建</span>
                        <span v-if="scope.row.status === 'ASSIGNED'">已分配</span>
                        <span v-if="scope.row.status === 'SUSPENDED'">已冻结</span>
                        <span v-if="scope.row.status === 'COMPLETED'">已完成</span>
                        <span v-if="scope.row.status === 'CANCELLED'">已取消</span>
                        <span v-if="scope.row.status === 'DELETED'">已删除</span>
                    </template>

                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleApproval(scope.row)"
                        >查看流程表单
                        </el-button>
                        <el-button type="text" @click="handleHistory(scope.row)"
                        >历史
                        </el-button
                        >
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <component :ref="dynamic" :is="dynamic"
                   :processDialogData="processDialogData" @handleDeal="handleDeal"
                   @handleCancel="cancelStartProcess" @comMounted="comMounted"
                   @handleDialogClose="cancelStartProcess"
        ></component>


    </div>
</template>

<script>
    import {getAction, deleteAction} from "@/api/manage";
    import Mixins from "@/mixins/Mixins.js";
    import QueryMixins from "@/mixins/QueryMixins.js";

    export default {
        name: "Undetermined",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                rules: {
                    instanceName: [
                        {required: true, message: "请输入实例名称", trigger: "change"},
                    ],
                    instanceVariable: [
                        {required: true, message: "请输入实例描述", trigger: "change"},
                    ],
                },
                namelist: [],
                row: {},
                ids: [],
                tableData: [],
                formLabelWidth: "120px",
                visible: false,
                assignVisible: false,
                diaFormlockActivity: false,
                activityForm: {},
                assignTitle: "",
                salesmanlist: [],
                commentList: [],
                userlist: [],
                dynamicPath: '',  //动态组件
                dynamic: '', //动态组件
                isDynamic: false, //动态组件是否存在
                processDialogData: {},  //弹窗组件的传递数据
                payList: [],
                receivableList: [],
                osContainerSettle1: {},
                selectRow: [],
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "name",
                        label: "流程名称",
                        sortable: "name",

                    },
                    {
                        prop: "startUserId",
                        label: "流程发起人",
                        sortable: "startUserId",
                    },
                    {
                        prop: "currentNodeName",
                        label: "任务节点名称",
                        sortable: "currentNodeName",
                    },
                    {
                        prop: "assignee",
                        label: "当前办理人",
                        sortable: "assignee",
                    },
                    {
                        prop: "startTime",
                        label: "创建时间",
                        sortable: "startTime",
                    },
                    {
                        prop: "endTime",
                        label: "结束时间",
                        sortable: "endTime",
                    },
                    {
                        prop: "duration",
                        label: "流程状态",
                        sortable: "duration",
                        formatter: function (text) {
                           // consoloe.log(text)
                        },
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 300,
                    },
                ],
                url: {
                    list: "/activiti/getCarbonCopyList",
                    FlowImgByInstanceId: "/activiti/FlowImgByInstanceId",
                    deal: "/activiti/operationActiviti/DealInstanceId",
                    lockActivity: '/operation/container/lockActivity',
                    getComment: '/activiti/operationActiviti/getComment'
                },
            };
        },
        created() {
            // this.initList();
        },
        computed: {
            // 动态加载对应的组件
            loaderDynamic() {
                return () => import('./components/' + this.dynamicPath)
            }
        },
        methods: {
            initDynamic(components) {
                if (this.dynamicPath == components && this.isDynamic) {
                    this.comMounted()
                    return
                }
                this.dynamicPath = components;
                const self = this;
                self.loaderDynamic().then(() => {
                    // 动态加载模板
                    self.dynamic = () => self.loaderDynamic();
                    self.isDynamic = true;
                }).catch((err) => {
                    self.isDynamic = false;
                    this.$message.warning('模板组件不存在');
                    console.log('模板不存在', err)
                    // 模板不存在404时处理
                })
            },
            // 动态组件加载完成
            comMounted() {
                this.$refs[this.dynamic].visible = true;
                this.selectRow.processInstanceId = this.selectRow.procInstId
                this.selectRow.actionType = "watch"
                this.$refs[this.dynamic].initData(this.selectRow);
            },
            //初始化列表
            initList() {
                console.log("11")
                getAction(this.url.list).then((res) => {
                    if (res.success) {
                        console.log(res.result)
                        const {records, total} = res.result
                        this.tableData = records || res.result;
                        this.ipagination.total = res.result.total
                    }
                });


            },
            handleSelectionChange(selection) {
                // 多选
                // let arr = [];
                // this.namelist = [];
                // for (let i = 0; i < selection.length; i++) {
                //     arr.push(selection[i]['id'])
                //     this.namelist.push(selection[i]['username'])
                // }
                // this.selectionRows = selection
                // this.selectedRowKeys = [...arr]
            },
            handleTableChange({prop, order}) {
                if (order === null) {
                    this.handleResetSorter();
                } else {
                    this.$set(this.isorter, "prop", prop);
                    this.$set(this.isorter, "order", order);
                }
                this.handleSearch();
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1;
                this.ipagination.pageSize = val;
                this.handleQuery();
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val;
                this.handleQuery();
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            handleResetFilter() {
                // 清空表格过滤器
                this.$refs.GTable.handlePureClear();
                this.filterForm = {};
            },
            handleApproval(row) {
                console.log(row)
                this.selectRow = row;
                this.activityForm = JSON.parse(JSON.stringify(row));
                this.initDynamic(row.processDefinitionKey);

            },
            handleDeal(row) {
                console.log(row)
                console.log(this.activityForm)
                getAction(this.url.deal, this.activityForm).then((res) => {
                    if (res.success) {
                        this.diaFormlockActivity = false;
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.initList();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                        });
                    }
                });
            },
            handleHistory(row) {
                console.log(row);
                let routeData = this.$router.resolve({
                    path:
                        "/ViewsActivitiBpmn?type=history&processInstanceId=" +
                        row.processInstanceId,
                });
                window.open(routeData.href, "_blank");
            },
            cancelStartProcess() {
            },
            //启动实例
            startProcess() {
                console.log(this.form);
                this.$refs.form.validate((valid) => {
                    getAction(this.url.startProcess, this.form).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
            },

            handleDel(row) {
                console.log(row);
                this.$confirm(`确定删除${row.name}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteDeployment, row).then((res) => {
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.initList();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                });
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
