import { render, staticRenderFns } from "./invoiceRateModule.vue?vue&type=template&id=224fad9a&scoped=true&"
import script from "./invoiceRateModule.vue?vue&type=script&lang=js&"
export * from "./invoiceRateModule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "224fad9a",
  null
  
)

export default component.exports