<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="queryForm" :model="queryForm" label-width="86px">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :span="6">
                        <el-form-item label="具体产品">
                            <el-input v-model="queryForm.productName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label-width="20px">
                            <el-button size="medium" type="primary" icon="el-icon-search"  @click="handleSearch"
                            >查询
                            </el-button>
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card>
            <el-button  type="primary" @click="handleAdd" v-if="buttonShowList.add">新增</el-button>
            <!--<div class="f-p-b-15">-->
            <!--<el-button type="primary">导出-->
            <!--</el-button>-->
            <!--<el-button type="primary">新增-->
            <!--</el-button>-->
            <!--<el-button type="danger">批量删除-->
            <!--</el-button>-->
            <!--</div>-->
            <f-table ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor" :columns="columns"
                     :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="61vh">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <!--<el-button type="text" @click="() => {visible = true}">新增-->
                        <!--</el-button>-->
                        <!--<el-button type="text">删除-->
                        <!--</el-button>-->
                        <el-button type="text"  @click="handleEdit(scope.row)" v-if="buttonShowList.bj">编辑
                        </el-button>
                        <el-button type="text"  @click="handleDelete(scope.row)" v-if="buttonShowList.sc">删除
                        </el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                title="清关案例"
                :visible.sync="visible"
                :close-on-click-modal="false"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="中文品名" label-width="100px" prop="productName">
                    <el-input v-model="form.productName"></el-input>
                </el-form-item>
                <el-form-item label="清关案例" label-width="100px" prop="clearanceCases">
                    <el-input v-model="form.clearanceCases"></el-input>
                </el-form-item>
                <el-form-item label="监管部门" label-width="100px" prop="productName">
                <el-input v-model="form.regulatoryAuthoritie"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="visible = false"
                >取 消
                </el-button
                >
                <el-button type="primary" @click="updateProduct">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import QueryMixins from "@/mixins/QueryMixins";
    import {deleteAction,getAction, getallcountries, postAction} from "@/api/manage";

    export default {
        mixins: [QueryMixins],

        props: {
        },
        data() {
            return {
                visible: false,
                isUsePost:true,
                row: {},
                form:{},
                queryForm: {},
                url:{
                    list: "/sys/bdClearCases/getClearCases",
                    updateClearCasesInfo:'/sys/bdClearCases/updateClearCases',
                    saveClearCasesInfo:'/sys/bdClearCases/saveClearCasesInfo',

                },
                tableData: [],
                rules:{
                    productName: [
                        {required: true, message: "请输入中文品名", trigger: "blur"},
                    ],
                    clearanceCases: [
                        {required: true, message: "请输入清关案例", trigger: "blur"},
                    ],
                    regulatoryAuthoritie: [
                        {required: true, message: "请输入监管部门", trigger: "blur"},
                    ]
                },
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },

                    {
                        prop: "action",
                        label: "操作",
                        width: 220,
                    },
                    {
                        prop: "id",
                        label: "案例编号",
                    },
                    {
                        prop: "productName",
                        label: "中文品名",
                    },
                    {
                        prop: "clearanceCases",
                        label: "清关案例",
                    },
                    {
                        prop: "regulatoryAuthoritie",
                        label: "监管部门",
                    },

                ],
            }
        },
        watch: {
            visible(val) {
                if(!val){
                    this.handleQuery()
                }
            },
        },
        created() {
        },
        mounted() {
        },
        methods: {
            handleAdd(){
                this.form = {}
                this.visible = true
            },
            updateProduct() {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        if(this.form.id){
                            postAction(this.url.updateClearCasesInfo, this.form).then(res => {
                                this.visible = false
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.handleQuery();
                            })
                        }else{
                            postAction(this.url.saveClearCasesInfo, this.form).then(res => {
                                this.visible = false
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                this.handleQuery();
                            })
                        }
                    }
                })
            },
            handleDelete(row){
                let productName  = row["productName"]
                this.$confirm(`确定删除中文品名是 ` + productName + ` 的清关案例吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.updateClearCasesInfo, {id: row.id,isDel:'1'}).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleQuery();
                    });
                }).catch(() => {
                });
            },
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.visible = true;
            },
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/BdClearCases','0','新增'),
            'bj':this.$btnIsShow('isystem/BaseData/BdClearCases','0','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/BdClearCases','0','删除'),

          }
        }
      }
    }
</script>
<style lang="scss" scoped></style>