<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="100px" size="medium">

            <el-row>

              <el-col :span="4">
                <el-form-item label="业务员">
                  <f-select
                          v-model="queryForm.salesmanId"
                          :is-need="queryForm.salesmanId"
                          :dict="'sys_user_id'"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单日期" label-width="100px">
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span class="f-p-h-2">~</span>
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="客户名">
                  <el-input
                          v-model="queryForm.username"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="工作单号">
                  <el-input
                      v-model="queryForm.workNo"
                  ></el-input>
                </el-form-item>
              </el-col>
          <el-col :span="4">
            <el-form-item label="付款方式">
              <f-select :dict="'ocean_pay_type'"
                        v-model="queryForm.payType"
                        :isNeed="queryForm.payType"
              >
              </f-select>
            </el-form-item>
          </el-col>
        <el-col :span="4">
          <el-form-item label="业务类型">
            <f-select :dict="'ocean_bussiness_type'"
                      v-model="queryForm.bussinessType"
                      :isNeed="queryForm.bussinessType">
            </f-select>
          </el-form-item>
        </el-col>
              <el-col :span="4">
                <el-form-item label="目的国家">
                  <el-input v-model="queryForm.destAddr"></el-input>
                </el-form-item>
              </el-col>
      </el-row>


    </el-form>
    <el-card class="box-card last_card">


    <div class="f-p-b-15">
      <el-button size="medium" type="primary"  @click="handleQuery"
      >查询</el-button>
        <el-button size="medium" type="primary"  @click="handleAdd"
                   v-if="buttonShowList.add"
                   >新增</el-button>
      <el-button size="medium" type="primary"  @click="handleExportXls" v-if="buttonShowList.dc"
      >导出</el-button>
    </div>

<!--    <div class="c-alert f-m-b-10">-->
<!--      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>-->
<!--    </div>-->
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="58vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)"
                     >编辑</el-button>
          <el-button type="text" @click="handDel(scope.row)"
                     >删除</el-button>
        </template>
        <template v-else-if="scope.column.property === 'payType'">
          <span>{{parseDict(scope.row.payType,'ocean_pay_type')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'bussinessType'">
          <span>{{parseDict(scope.row.bussinessType,'ocean_bussiness_type')}}</span>
        </template>

      </template>
    </f-table>

      <p>{{pay.ss}}</p>
      <p>{{rec.ss}}</p>
      <p>{{profit.ss}}</p>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>


    <el-dialog :title="form.id?'编辑':'新增'" :visible.sync="dialogFormVisible"
               v-if="dialogFormVisible"
               center width="30%" :close-on-click-modal="false" append-to-body>

      <el-form :model="form" :rules="rules" ref="form" size="medium" >
        <el-row>
          <!--<el-col :span="24">
            <el-form-item prop="fileName" label="文件名" label-width="110px">
              <el-input v-model="form.fileName" ></el-input>
            </el-form-item>
          </el-col>-->


          <el-col :span="24">
            <el-form-item  label="客户名" label-width="110px" prop="cusId">

             <f-select :dict="'ocean_customer_id'"
                       v-model="form.cusId"
                       :isNeed="form.cusId"
                       @changet="getUsername"
             ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item  label="业务员" label-width="110px" prop="cusId">

              <f-select :dict="'sys_user_id'"
                        v-model="form.salesmanId"
                        :isNeed="form.salesmanId"

              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item  label="订单日期"  label-width="110px" prop="orderDate">
              <el-date-picker
                      class="f-flex-1"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="form.orderDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="目的国家地区"  label-width="110px" prop="destAddr">
             <el-input v-model="form.destAddr" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
          </el-col>
<!--          业务类型-->
          <el-col>
            <el-form-item label="业务类型"  label-width="110px" >
              <el-select multiple filterabel clearable v-model="form.bussinessTypeList" @click.native="initBussinessTypeList">
                <el-option v-for="(item,index) in bussinessTypeList" :key="index" :value="item.value" :label="item.text"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="业务详情"  label-width="110px" >
              <el-input v-model="form.salesDetail" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item  label="付款方式" label-width="110px">

              <f-select :dict="'ocean_pay_type'"
                        v-model="form.payType"
                        :isNeed="form.payType"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="应收额"  label-width="110px" >
              <el-input v-model="form.recMoney" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="应收币种"  label-width="110px" >
              <f-select
                      v-model="form.recMoneyType"
                      :isNeed="form.recMoneyType"
                      type="number" :dict="'bd_cur'"></f-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="应付额"  label-width="110px" >
              <el-input v-model="form.payMoney" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="应付币种"  label-width="110px" >
              <f-select
                      v-model="form.payMoneyType"
                      :isNeed="form.payMoneyType"
                       :dict="'bd_cur'"></f-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="利润"  label-width="110px" >
              <el-input v-model="form.profit" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="利润币种"  label-width="110px" >
              <f-select
                      v-model="form.profitType"
                      :isNeed="form.profitType"
                       :dict="'bd_cur'"></f-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="工作单号"  label-width="110px" >
              <el-input
                      v-model="form.workNo"
                      maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="包装类型"  label-width="110px" >
              <el-input
                      v-model="form.goodsType"
                      maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="包装数量"  label-width="110px" >
              <el-input
                      v-model="form.goodsNum"
                      type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="商品数"  label-width="110px" >
              <el-input
                      v-model="form.proNum"
                      type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item  label="单位"  label-width="110px" >
              <el-radio-group v-model="form.proQty">
                <el-radio label="Pec">Pec</el-radio>
                <el-radio label="EA">EA</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="saveFile">确 定</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>
  import {deleteAction, getAction, httpAction, postAction} from "@/api/manage";
  import QueryMixins from "../../mixins/QueryMixins";


  export default {
  name: 'OceanCusOrder',
  mixins: [QueryMixins],
  components: {
  },
  data() {
    return {
      ss:'',
      form:{},
      dialogFormVisible:false,
      dictCodes:['ocean_bussiness_type','ocean_pay_type'],
      columns: [
        {
          prop: 'action',
          label: '操作',
        },
        {
          prop: 'orderDate',
          label: '订单日期',
        },
        {
          prop: 'updateTime',
          label: '修改时间',
        },
        {
          prop: 'workNo',
          label: '工作单号',
        },
        {
          prop: 'username',
          label: '客户名',
        },
        {
          prop: 'salesman',
          label: '业务员',
        },
        {
          prop: 'goodsNum',
          label: '货物数量',
        },
        {
          prop: 'goodsType',
          label: '包装类型',
        },
        {
          prop: 'proNum',
          label: '商品数量',
        },
        {
          prop: 'proQty',
          label: '单位',
        },
        {
          prop: 'phone',
          label: '客户联系方式',
        },
        {
          prop: 'bussinessType',
          label: '业务类型',
        },
        {
          prop: 'destAddr',
          label: '目的国家',

        },
        {
          prop: 'salesDetail',
          label: '业务详情',
          overFlow:true,
        },
        {
          prop: 'payMoney',
          label: '应付金额',
        },
        {
          prop: 'payMoneyType',
          label: '应付币种',
        },
        {
          prop: 'recMoney',
          label: '应收金额',
        },
        {
          prop: 'recMoneyType',
          label: '应收币种',
        },
        {
          prop: 'profit',
          label: '利润',
        },
        {
          prop: 'profitType',
          label: '利润币种',
        },
        {
          prop: 'payType',
          label: '付款方式',
        },


      ],
      url: {
        list: '/customer/ocean/cus/order/list',
        addOrUpdateOceanCusOrder: '/customer/ocean/cus/order/addOrUpdateOceanCusOrder',
        delete: '/customer/ocean/cus/order/delete',
        getBussinessTypeList:'/sys/dict/getDictItems/ocean_bussiness_type',
        exportXlsUrl:'/customer/ocean/cus/order/exportXls',
      },
      bussinessTypeList:[],
      //校验规则
      rules:{
        cusId:[{required:true,message:'请选择客户',trigger:'change'}],
        orderDate:[{required:true,message:'请选择订单日期',trigger:'change'}],
        destAddr:[{required:true,message:'请输入目的国家地区',trigger:'blur'}],

      },
      rec:{
        total:'',
        totalgbp: 0,
        // SGD
        totalsgd: 0,
        // USD
        totalusd: 0,
        // NCD
        totalncd: 0,
        // AUD
        totalaud: 0,
        // GBP

        // VND
        totalvnd: 0,
        // NZD
        totalnzd: 0,
        // HKD
        totalhkd: 0,
        // CAD
        totalcad: 0,
        // EUR
        totaleur: 0,
        // INR
        totalinr: 0,
        // CNY
        totalcny: 0,
        // KRW
        totalkrw: 0,
        // NOK
        totalnok: 0,
        // JPY
        totaljpy: 0,
        // NZD
        ss: "",
        // TWD
        totaltwd: 0,
      },
      pay:{
        total:0,
        totalgbp: 0,
        // SGD
        totalsgd: 0,
        // USD
        totalusd: 0,
        // NCD
        totalncd: 0,
        // AUD
        totalaud: 0,
        // GBP

        // VND
        totalvnd: 0,
        // NZD
        totalnzd: 0,
        // HKD
        totalhkd: 0,
        // CAD
        totalcad: 0,
        // EUR
        totaleur: 0,
        // INR
        totalinr: 0,
        // CNY
        totalcny: 0,
        // KRW
        totalkrw: 0,
        // NOK
        totalnok: 0,
        // JPY
        totaljpy: 0,
        // NZD
        ss: "",
        // TWD
        totaltwd: 0,
      },
      profit:{
        total:0,
        totalgbp: 0,
        // SGD
        totalsgd: 0,
        // USD
        totalusd: 0,
        // NCD
        totalncd: 0,
        // AUD
        totalaud: 0,
        // GBP

        // VND
        totalvnd: 0,
        // NZD
        totalnzd: 0,
        // HKD
        totalhkd: 0,
        // CAD
        totalcad: 0,
        // EUR
        totaleur: 0,
        // INR
        totalinr: 0,
        // CNY
        totalcny: 0,
        // KRW
        totalkrw: 0,
        // NOK
        totalnok: 0,
        // JPY
        totaljpy: 0,
        // NZD
        ss: "",
        // TWD
        totaltwd: 0,
      },

    }
  },
  created() {
    this.initBussinessTypeList();
    },
  mounted() {

  },
  methods: {
    initZJ(){
      this.rec={
        total:0,
                totalgbp: 0,
                // SGD
                totalsgd: 0,
                // USD
                totalusd: 0,
                // NCD
                totalncd: 0,
                // AUD
                totalaud: 0,
                // GBP

                // VND
                totalvnd: 0,
                // NZD
                totalnzd: 0,
                // HKD
                totalhkd: 0,
                // CAD
                totalcad: 0,
                // EUR
                totaleur: 0,
                // INR
                totalinr: 0,
                // CNY
                totalcny: 0,
                // KRW
                totalkrw: 0,
                // NOK
                totalnok: 0,
                // JPY
                totaljpy: 0,
                // NZD
                ss: "",
                // TWD
                totaltwd: 0,
      },
      this.pay={
        total:0,
                totalgbp: 0,
                // SGD
                totalsgd: 0,
                // USD
                totalusd: 0,
                // NCD
                totalncd: 0,
                // AUD
                totalaud: 0,
                // GBP

                // VND
                totalvnd: 0,
                // NZD
                totalnzd: 0,
                // HKD
                totalhkd: 0,
                // CAD
                totalcad: 0,
                // EUR
                totaleur: 0,
                // INR
                totalinr: 0,
                // CNY
                totalcny: 0,
                // KRW
                totalkrw: 0,
                // NOK
                totalnok: 0,
                // JPY
                totaljpy: 0,
                // NZD
                ss: "",
                // TWD
                totaltwd: 0,
      },
      this.profit={
        total:0,
                totalgbp: 0,
                // SGD
                totalsgd: 0,
                // USD
                totalusd: 0,
                // NCD
                totalncd: 0,
                // AUD
                totalaud: 0,
                // GBP

                // VND
                totalvnd: 0,
                // NZD
                totalnzd: 0,
                // HKD
                totalhkd: 0,
                // CAD
                totalcad: 0,
                // EUR
                totaleur: 0,
                // INR
                totalinr: 0,
                // CNY
                totalcny: 0,
                // KRW
                totalkrw: 0,
                // NOK
                totalnok: 0,
                // JPY
                totaljpy: 0,
                // NZD
                ss: "",
                // TWD
                totaltwd: 0,
      }
    },
     handleQuery() {
      this.loading = true
      let form = {...this.queryForm, ...this.filterForm, ...this.initData}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')
        } else if (this.enabledCreatetime){
          this.$set(params, 'column', 'createTime')
        }
        //提货中查询
        if (this.queryForm.column === 'distributionTime') {
          this.$set(params, 'column', 'distributionTime')
        }
        //排序
        //提货中查询
        if (this.queryForm.column === 'sort') {
          this.$set(params, 'column', 'sort')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }
       getAction(this.url.list, params).then((res) => {
        console.log(res)
        if (res.success) {
          if (res.result) {
            const {records, total} = res.result
            this.tableData = records || res.result;

            this.ipagination && (this.ipagination.total = total || res.result.length)
            this.getSummaries2();
          } else {

            this.tableData = [];

            this.ipagination = 0;
          }

        }
      }).finally(() => {
        this.loading = false
      })
    },
    initCusAndObj(s,obj,moneyType,payMoney){
      if (s[moneyType] == 'GBP') {
        obj.totalgbp += Number(s[payMoney]);
      }
      if (s[moneyType] == 'SGD') {
        obj.totalsgd += Number(s[payMoney]);
      }
      if (s[moneyType] == 'USD') {
        obj.totalusd += Number(s[payMoney]);
      }
      if (s[moneyType] == 'NCD') {
        obj.totalncd += Number(s[payMoney]);
      }
      if (s[moneyType] == 'AUD') {
        obj.totalaud += Number(s[payMoney]);
      }
      if (s[moneyType] == 'VND') {
        obj.totalvnd += Number(s[payMoney]);
      }
      if (s[moneyType] == 'NZD') {
        obj.totalnzd += Number(s[payMoney]);
      }
      if (s[moneyType] == 'HKD') {
        obj.totalhkd += Number(s[payMoney]);
      }
      if (s[moneyType] == 'CAD') {
        obj.totalcad += Number(s[payMoney]);
      }
      if (s[moneyType] == 'EUR') {
        obj.totaleur += Number(s[payMoney]);
      }
      if (s[moneyType] == 'INR') {
        obj.totalinr += Number(s[payMoney]);
      }
      if (s[moneyType] == 'CNY') {
        obj.totalcny += Number(s[payMoney]);
      }
      if (s[moneyType] == 'KRW') {
        obj.totalkrw += Number(s[payMoney]);
      }
      if (s[moneyType] == 'NOK') {
        obj.totalnok += Number(s[payMoney]);
      }
      if (s[moneyType] == 'JPY') {
        obj.totaljpy += Number(s[payMoney]);
      }
      if (s[moneyType] == 'TWD') {
        obj.totaltwd += Number(s[payMoney]);
      }
    },
    getSummaries2() {
      //遍历集合
      this.initZJ();
      console.log('表格数据',this.tableData);
      if (this.tableData) {
        this.tableData.forEach(s => {
          // this.getAllPrice(s);
          if (s) {
            if (s.realPayRate){
              this.pay.total+= Number(s.realPayRate);
            }
            if (s.realRecRate){
              this.rec.total+= Number(s.realRecRate);
            }
            if (s.realProfitType){
              this.profit.total+= Number(s.realProfitType);
            }
            this.initCusAndObj(s,this.pay,'payMoneyType','payMoney');
            this.initCusAndObj(s,this.rec,'recMoneyType','recMoney');
            this.initCusAndObj(s,this.profit,'profitType','profit');

          }

        })
      }
      console.log('应收',this.rec);
      console.log('应付',this.pay);
      console.log('利润',this.profit);
      this.showSumm();


    },
    showSumm() {
      //应收
      this.rec.ss = "应收: ";
      if (this.rec.totalusd && this.rec.totalusd != 0) {
        this.rec.ss += " USD:" + this.rec.totalusd.toFixed(3);
      }
      if (this.rec.totalcny && this.rec.totalcny != 0) {
        this.rec.ss += " CNY:" + this.rec.totalcny.toFixed(3);
      }
      if (this.rec.totalcad && this.rec.totalcad != 0) {
        this.rec.ss += " CAD:" + this.rec.totalcad.toFixed(3);
      }
      if (this.rec.totalaud && this.rec.totalaud != 0) {
        this.rec.ss += " AUD:" + this.rec.totalaud.toFixed(3);
      }
      if (this.rec.totaleur && this.rec.totaleur != 0) {
        this.rec.ss += " EUR:" + this.rec.totaleur.toFixed(3);
      }
      if (this.rec.totalgbp && this.rec.totalgbp != 0) {
        this.rec.ss += " GBP:" + this.rec.totalgbp.toFixed(3);
      }
      if (this.rec.totalhkd && this.rec.totalhkd != 0) {
        this.rec.ss += " HKD:" + this.rec.totalhkd.toFixed(3);
      }
      if (this.rec.totalinr && this.rec.totalinr != 0) {
        this.rec.ss += " INR:" + this.rec.totalinr.toFixed(3);
      }
      if (this.rec.totaljpy && this.rec.totaljpy != 0) {
        this.rec.ss += " JPY:" + this.rec.totaljpy.toFixed(3);
      }
      if (this.rec.totalkrw && this.rec.totalkrw != 0) {
        this.rec.ss += " KRW:" + this.rec.totalkrw.toFixed(3);
      }
      if (this.rec.totalncd && this.rec.totalncd != 0) {
        this.rec.ss += " NCD:" + this.rec.totalncd.toFixed(3);
      }
      if (this.rec.totalnok && this.rec.totalnok != 0) {
        this.rec.ss += " NOK:" + this.rec.totalnok.toFixed(3);
      }
      if (this.rec.totalnzd && this.rec.totalnzd != 0) {
        this.rec.ss += " NZD:" + this.rec.totalnzd.toFixed(3);
      }
      if (this.rec.totalsgd && this.rec.totalsgd != 0) {
        this.rec.ss += " SGD:" + this.rec.totalsgd.toFixed(3);
      }
      if (this.rec.totaltwd && this.rec.totaltwd != 0) {
        this.rec.ss += " TWD:" + this.rec.totaltwd.toFixed(3);
      }
      if (this.rec.totalvnd && this.rec.totalvnd != 0) {
        this.rec.ss += " VND:" + this.rec.totalvnd.toFixed(3);
      }
      if (this.rec.total && this.rec.total != 0) {
        this.rec.ss += " 总计:CNY" + this.rec.total.toFixed(3);
      }
      //应付
      this.pay.ss = "应付: ";
      if (this.pay.totalusd && this.pay.totalusd != 0) {
        this.pay.ss += " USD:" + this.pay.totalusd.toFixed(3);
      }
      if (this.pay.totalcny && this.pay.totalcny != 0) {
        this.pay.ss += " CNY:" + this.pay.totalcny.toFixed(3);
      }
      if (this.pay.totalcad && this.pay.totalcad != 0) {
        this.pay.ss += " CAD:" + this.pay.totalcad.toFixed(3);
      }
      if (this.pay.totalaud  && this.pay.totalaud != 0) {
        this.pay.ss += " AUD:" + this.pay.totalaud.toFixed(3);
      }
      if (this.pay.totaleur && this.pay.totaleur != 0) {
        this.pay.ss += " EUR:" + this.pay.totaleur.toFixed(3);
      }
      if (this.pay.totalgbp && this.pay.totalgbp != 0) {
        this.pay.ss += " GBP:" + this.pay.totalgbp.toFixed(3);
      }
      if (this.pay.totalhkd && this.pay.totalhkd != 0) {
        this.pay.ss += " HKD:" + this.pay.totalhkd.toFixed(3);
      }
      if (this.pay.totalinr && this.pay.totalinr != 0) {
        this.pay.ss += " INR:" + this.pay.totalinr.toFixed(3);
      }
      if (this.pay.totaljpy && this.pay.totaljpy != 0) {
        this.pay.ss += " JPY:" + this.pay.totaljpy.toFixed(3);
      }
      if (this.pay.totalkrw && this.pay.totalkrw != 0) {
        this.pay.ss += " KRW:" + this.pay.totalkrw.toFixed(3);
      }
      if (this.pay.totalncd && this.pay.totalncd != 0) {
        this.pay.ss += " NCD:" + this.pay.totalncd.toFixed(3);
      }
      if (this.pay.totalnok && this.pay.totalnok != 0) {
        this.pay.ss += " NOK:" + this.pay.totalnok.toFixed(3);
      }
      if (this.pay.totalnzd && this.pay.totalnzd != 0) {
        this.pay.ss += " NZD:" + this.pay.totalnzd.toFixed(3);
      }
      if (this.pay.totalsgd && this.pay.totalsgd != 0) {
        this.pay.ss += " SGD:" + this.pay.totalsgd.toFixed(3);
      }
      if (this.pay.totaltwd && this.pay.totaltwd != 0) {
        this.pay.ss += " TWD:" + this.pay.totaltwd.toFixed(3);
      }
      if (this.pay.totalvnd && this.pay.totalvnd != 0) {
        this.pay.ss += " VND:" + this.pay.totalvnd.toFixed(3);
      }
      if (this.pay.total && this.pay.total != 0) {
        this.pay.ss += " 总计:CNY:" + this.pay.total.toFixed(3);
      }
      //利润
      this.profit.ss = "利润: ";
      if (this.profit.totalusd && this.profit.totalusd != 0) {
        this.profit.ss += " USD:" + this.profit.totalusd.toFixed(3);
      }
      if (this.profit.totalcny && this.profit.totalcny != 0) {
        this.profit.ss += " CNY:" + this.profit.totalcny.toFixed(3);
      }
      if (this.profit.totalcad && this.profit.totalcad != 0) {
        this.profit.ss += " CAD:" + this.profit.totalcad.toFixed(3);
      }
      if (this.profit.totalaud && this.profit.totalaud != 0) {
        this.profit.ss += " AUD:" + this.profit.totalaud.toFixed(3);
      }
      if (this.profit.totaleur && this.profit.totaleur != 0) {
        this.profit.ss += " EUR:" + this.profit.totaleur.toFixed(3);
      }
      if (this.profit.totalgbp && this.profit.totalgbp != 0) {
        this.profit.ss += " GBP:" + this.profit.totalgbp.toFixed(3);
      }
      if (this.profit.totalhkd && this.profit.totalhkd != 0) {
        this.profit.ss += " HKD:" + this.profit.totalhkd.toFixed(3);
      }
      if (this.profit.totalinr && this.profit.totalinr != 0) {
        this.profit.ss += " INR:" + this.profit.totalinr.toFixed(3);
      }
      if (this.profit.totaljpy && this.profit.totaljpy != 0) {
        this.profit.ss += " JPY:" + this.profit.totaljpy.toFixed(3);
      }
      if (this.profit.totalkrw && this.profit.totalkrw != 0) {
        this.profit.ss += " KRW:" + this.profit.totalkrw.toFixed(3);
      }
      if (this.profit.totalncd && this.profit.totalncd != 0) {
        this.profit.ss += " NCD:" + this.profit.totalncd.toFixed(3);
      }
      if (this.profit.totalnok && this.profit.totalnok != 0) {
        this.profit.ss += " NOK:" + this.profit.totalnok.toFixed(3);
      }
      if (this.profit.totalnzd && this.profit.totalnzd != 0) {
        this.profit.ss += " NZD:" + this.profit.totalnzd.toFixed(3);
      }
      if (this.profit.totalsgd && this.profit.totalsgd != 0) {
        this.profit.ss += " SGD:" + this.profit.totalsgd.toFixed(3);
      }
      if (this.profit.totaltwd && this.profit.totaltwd != 0) {
        this.profit.ss += " TWD:" + this.profit.totaltwd.toFixed(3);
      }
      if (this.profit.totalvnd && this.profit.totalvnd != 0) {
        this.profit.ss += " VND:" + this.profit.totalvnd.toFixed(3);
      }
      if (this.profit.total && this.profit.total != 0) {
        this.profit.ss += " 总计:CNY:" + this.profit.total.toFixed(3);
      }

    },
    getUsername(val){
      this.$set(this.form,'username',val);
    },
    initBussinessTypeList(){
      getAction(this.url.getBussinessTypeList).then(res=>{
        this.bussinessTypeList = res.result;
      })
    },
    saveFile: function () {
      this.$refs['form'].validate(valid=>{
        if (valid){
          if (this.form.bussinessTypeList){
            this.form.bussinessType = this.form.bussinessTypeList.join(',');
          }
          postAction(this.url.addOrUpdateOceanCusOrder,this.form)
          .then(res=>{
            this.$message.success(res.message);
            this.dialogFormVisible = false;
            this.handleQuery();
          })
        }
      })
    },
    handleReset(){
      this.queryForm = {};
      this.ipagination.currentPage = 1
      this.handleQuery()
    },



    handleAdd(){
      this.form = {bussinessTypeList:[]};
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      for (var name in row) {
        this.$set(this.form,name,row[name])
      }
      if (row.bussinessType) {
        this.$set(this.form,'bussinessTypeList',row.bussinessType.split(','))
      }else {
        this.$set(this.form,'bussinessTypeList',[])
      }
      this.dialogFormVisible = true;
    },
    handDel({id, username}) {
      this.$confirm(`删除${username}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delete,{id:id})
        .then(res=>{
          this.$message.success(res.message);
          this.handleQuery();
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },





    handleBatDel() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {

      }).catch(err=>{
        this.$message.error(err.message);
      })
    },

  },
    computed:{
      buttonShowList(){
        return {
          'dc':this.$btnIsShow('ocean/OceanCusOrder','0','导出'),
          'add':this.$btnIsShow('ocean/OceanCusOrder','0','新增'),

        }
      }
    }
}
</script>

<style lang="scss" scoped>

</style>
