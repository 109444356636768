<template>
  <el-dialog class="comments_dialog_box" top="10vh" title="问题件评论" width="80%" :visible="visible"
    :close-on-click-modal="false" :destroy-on-close="destroyOnClose" :before-close="handleClose">
    <div v-loading="loading" ref="infoBox">
      <div v-if="JSON.stringify(info) !== '{}'">
        <div class="flex_wrap_between f-m-b-10">
          <div>
            <span class="f-font-14">订单号：<span class="aTagStyle"
                @click.prevent="handleClose(); $intoDocPage(info.orderNo)">{{info.orderNo}}</span></span>
            <span class="f-font-14 f-m-l-20">问题件类型：{{info.problemTypeText}}</span>
          </div>
          <span><i class="el-icon-time f-m-r-10"></i>{{info.createTime}}</span>
        </div>
        <div class="f-m-b-10" style="color: #909399;">{{info.warehouseRemark}}</div>
        <div class="flex_wrap_between f-m-b-10">
          <div>
            <span>客户： <span class="aTagStyle" @click.prevent="$intoCustomerEditPage(info.cusId)">{{info.cusName}}</span></span>
            <span class="f-m-l-40">客服： {{info.docName}}</span>
            <span class="f-m-l-40">录入人： {{info.createBy}}</span>
          </div>
          <el-button size="small" @click="openComments"><i
              class="el-icon-chat-dot-round f-m-r-5"></i>评论({{contentSize}})
          </el-button>
        </div>
      </div>
      <div v-else>暂无数据</div>
    </div>
    <div class="comments_box" :style="{ '--fixedTopPosition': (fixedTopHeight + inputHeight) + 'px'}">
      <div v-show="commentsListsShow">
        <el-card shadow="hover" class="f-m-b-5" v-for="(item, index) in contentList" :key="index">
          <div class="flex_wrap_between f-m-b-5">
            <div>
              <span class="f-font-14 f-m-r-10">{{item.createBy}}</span>
              <span>{{item.createTime}}</span>
            </div>
            <el-button type="text" size="small" @click="handleDel(item.id)"><i class="el-icon-close"></i>
            </el-button>
          </div>
          <div style="color: #909399;">{{item.content}}</div>
          <div v-if="item.accessorys && item.accessorys.length" class="f-m-t-5">附件</div>
          <f-upload v-model="item.accessorys" :disabled="true" :isUseOss="true" :isShowButton="false">
          </f-upload>
        </el-card>
      </div>
    </div>
    <el-form class="flex_start_between no_margin f-m-t-20 comments_input_box" :model="form" :rules="rules" ref="form"
      size="medium">
      <el-form-item class="width_conment" prop="content" label="">
        <el-input v-model="form.content" placeholder="请输入评论内容" :maxlength="500" show-word-limit></el-input>
      </el-form-item>
      <div class="flex_center_start">
        <f-upload ref="fUpload" style="width: 90px" class="f-m-l-10" v-model="form.accessorys" :isUseOss="true"
          buttonTitle="上传附件" @getUploadHeight="handleInputHeight">
        </f-upload>
        <el-button type="primary" @click="handleSubmit">评论</el-button>
      </div>
    </el-form>
    <template #footer>
      <el-button size="small" @click="handleClose">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { postAction, getAction } from "@/api/manage";
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "problemComments",
  props: {
    // 单行数据
    row: {
      type: Object,
      default: () => { },
    },
    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        content: [
          { required: true, message: "请输入评论内容", trigger: "blur" },
        ]
      },
      url: {
        getIssueInfo: '/warehouse/commenter/getIssueInfo',
        add: '/warehouse/commenter/add',
        del: '/warehouse/commenter/del',
      },
      commentsListsShow: false,
      info: {},
      contentSize: 0,
      contentList: [],
      loading: false,
      fixedTopHeight: 0,
      inputHeight: 55
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.row && this.row.id && this.getContentList();
      }
    },
  },
  methods: {
    handleInputHeight(e) {
      console.log(e, 'input元素高度')
      this.$nextTick(() => {
        this.inputHeight = e + 30;
      })
    },
    handleDel(id) {
      this.$store.commit("setFullscreenLoading", true);
      postAction(this.url.del, [id]).then((res) => {
        this.$store.commit("setFullscreenLoading", false);
        this.$message({
          type: 'success',
          message: res.message
        })
        this.getContentList();
      }).catch(err => {
        this.$store.commit("setFullscreenLoading", false);
      })
    },
    // 获取评论列表
    getContentList() {
      if (JSON.stringify(this.info) == '{}') {
        this.loading = true;
      }
      getAction(this.url.getIssueInfo, { busId: this.row.id }).then((res) => {
        this.loading = false;
        // this.info = this.row;
        this.info = res && res.result && res.result.issue;
        this.contentSize = res && res.result && res.result.commenterSize;
        this.contentList = res && res.result && res.result.commenter;
        this.contentList.forEach((item, index) => {
          item.accessorys = JSON.parse(item.accessory) || [];
        })
        this.$nextTick(() => {
          this.fixedTopHeight = this.$refs.infoBox && this.$refs.infoBox.offsetHeight;
        })
      }).catch(err => {
        this.loading = false;
        this.$nextTick(() => {
          this.fixedTopHeight = this.$refs.infoBox && this.$refs.infoBox.offsetHeight;
        })
      })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.commit("setFullscreenLoading", true);
          this.form.busId = this.info.id;
          this.form.busNo = this.info.orderNo;
          postAction(this.url.add, this.form).then((res) => {
            this.$store.commit("setFullscreenLoading", false);
            this.form = {};
            this.$message({
              type: 'success',
              message: res.message
            })
            this.getContentList();
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          })
        }
      });
    },
    // 打开评论历史列表
    openComments() {
      this.commentsListsShow = !this.commentsListsShow;
    },
    handleClose() {
      this.$emit("update:visible", false);
      this.form = {};
    },

  },
};
</script>

<style lang="scss" scoped>
.width_conment {
  width: calc(100% - 116px)
}

.comments_box {
  overflow-y: scroll;
  height: calc(100% - var(--fixedTopPosition));
}

.comments_input_box {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px);
  box-sizing: border-box;
}
</style>
<style lang="scss">
.comments_dialog_box {
  .el-dialog__body {
    overflow-y: scroll;
    height: 70vh;
    position: relative;
  }
}
</style>
